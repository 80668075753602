/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useEffect, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import ChatIcon from "@mui/icons-material/Chat";

import {
  Headline6,
  MainPage,
  Primary,
  Headline5,
  Body1,
  Body2,
  GreyBox,
  Button,
  Subtitle1,
  Subtitle2,
  BrowseListFilter,
  Hexagon,
} from "../../components";
import {
  FileCopyOutlined,
  PublicOutlined,
  AssessmentOutlined,
  LightbulbOtlined,
  EmojiObjects,
} from "@material-ui/icons";
import moment from "moment";
import SummaryAccordion from "../../components/SummaryAccordion";
import {
  Grid,
  Tabs,
  Tab,
  Box,
  Dialog,
  Badge,
  Divider,
} from "@material-ui/core";
import TuneRoundedIcon from "@material-ui/icons/TuneRounded";
import SparkLine from "../../components/SparkLine";
import {
  KeyboardBackspace,
  CallMade,
  CallReceived,
  SortOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import PeformanceChartJs from "../../components/PeformanceChart.js";
import GoogleMapReact from "google-map-react";
import MapMarker from "../../components/MapMarker";
import { useFirestoreConnect } from "react-redux-firebase";

import ProjectCard from "../../components/ProjectCard";
import CompanyCard from "../../components/CompanyCard";
import Filter from "../../components/Filter";
import Sort from "../../components/Sort";
import Content from "./Components/content";
import { withRouter } from "react-router";
import Map from "../../components/Map";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import ContentLoader from "react-content-loader";
import _ from "lodash";
import animationData from "../../assets/ProspectorDesignAssets/lottie2.json";
import { useMediaQuery } from "react-responsive";
import {
  getCommodityData,
  filterProjects,
  setProjectFilters,
  setCompanyFilters,
  filterCompanies,
  filterCompaniesStaging,
  filterProjectsStaging,
  filterReports,
} from "../../store/Actions/AuthActions";
import ViewAllFilter from "../../components/ViewAllFilter";
import InfiniteScroll from "react-infinite-scroll-component";
import Mapbox from "../../components/Mapbox";
const dummy_data = {
  list: [
    {
      name: "Africa",
      type: "region",
    },
    {
      name: "copper",
      type: "mineral",
      symbol: "Cu",
    },
    {
      name: "gold",
      type: "mineral",
      symbol: "Au",
    },

    {
      name: "canada",
      type: "country",
    },
    {
      name: "mexico",
      type: "country",
    },
    {
      name: "lithium",
      type: "mineral",
      symbol: "Li",
    },
    {
      name: "Latin America",
      type: "region",
    },
  ],
  projects: [
    {
      name: "Läntinen Koillismaa Project",
      date: moment(),
      country: "finland",
      description:
        "Document Control Information Technical Report for the Kaukua Deposit, Läntinen Koillismaa Project, Finland Kaukua Deposit",
      ticker: "PMD:CVE -1.49%",
      minerals: ["Pd", "Pt", "Ni", "Au", "Cu"],
      status: 1,
      image: require("../../assets/ProspectorDesignAssets/Climate Smart Mining.png"),
    },
    {
      name: "Selena Property",
      date: moment(),
      country: "united-states",
      description:
        "The Project comprises 471 contiguous Bureau of Land Management (BLM) lode-type claims and 3,700 acres of Federally",
      ticker: "target drilling",
      minerals: ["Pd", "Pt", "Ni", "Au", "Cu"],
      status: 0,
      image: require("../../assets/ProspectorDesignAssets/Commodity Overviews - smaller.png"),
    },
  ],
  companies: [
    {
      ticker: "PMD:CVE",
      name: "Palladium One Mining Inc.",
      match_status: "matched",
      projects: 1,
      minerals: ["Au", "Pd", "Pt", "Ni", "Cu"],
    },
    {
      ticker: "RDG:CVE",
      name: "Ridgeline Minerals Corp.",
      projects: 1,
      match_status: "pending",
      minerals: ["Au"],
    },
  ],
};
const periods = ["1M", "3M", "1Y", "5Y", "ALL"];
const iFrameDetection = window === window.parent ? false : true;
const PrettoSlider = createMuiTheme({
  overrides: {
    MuiSlider: {
      root: {
        color: "#52af77",
        height: 8,
      },
      thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
          boxShadow: "inherit",
        },
      },
      active: {},
      valueLabel: {
        left: "calc(-50% + 4px)",
      },
      track: {
        height: 8,
        borderRadius: 4,
      },
      rail: {
        height: 8,
        borderRadius: 4,
      },
    },
  },
});

const states = ["inferred", "indicated", "Measured", "Probable", "Proven"];

export const Browse = ({
  companies_page,
  map_projects,
  projects_loading,
  filterProjectsStaging,
  setProjectStartDate,
  projects_page_count,
  setCompanyPage,
  setProjectPage,
  setProjectFilters,
  projects_length,
  companies_length,
  setBrowseTab,
  projects,
  history,
  setCompany,
  browse_page_tab,
  setProject,
  filterProjects,
  filterCompanies,
  projects_page,
  setCommodity,
  getCommodityData,
  project_filters,
  company_filters,
  setCompanyFilters,
  filterCompaniesStaging,
  selectBulk_projects,
}) => {
  const [tab, setTab] = useState("projects");
  const dispatch = useDispatch();
  const [selectedList, setselectedList] = useState(null);
  const elements = useSelector((state) => state.authReducer.minerals_list);
  const [value, setValue] = useState("projects");
  const handleChange = (event, newValue) => {
    setValue(newValue);

    setBrowseTab(newValue);
    setTab(newValue);
  };
  useEffect(() => {
    setValue(tab);
    return () => {};
  }, [tab]);
  const [center, setCenter] = useState({
    lat: 35.7465,
    lng: -39.4629,
  });
  const search_companies = ({ loc, newFilters }) => {
    setCompanyPage(0);
    // filterCompanies()
    filterCompaniesStaging();
  };
  const search_projects = ({ loc, newFilters }) => {
    setProjectPage(0);
    filterProjects();
  };
  const setList = (item) => {
    setselectedList(item);
    if (item) {
      if (item.type === "region" || item.type === "country") {
        setLocation(item.name);
      } else {
        setLocation(null);
      }
    } else {
      setLocation(null);
      let newFilters = {
        mineral: null,
        start_after: null,
        searchTerm: null,
        regions: null,
        countries: null,
        stage: null,
        resourceType: null,
        averageGrade_min: 0,
        averageGrade_max: 0,
        oreTonnage_min: 0,
        oreTonnage_max: 0,
        containedMetal_min: 0,
        containedMetal_max: 0,
      };
      if (tab === "projects") {
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
    }
  };
  useEffect(() => {
    setTab(browse_page_tab);
    filterProjects();
    filterCompanies();

    return () => {};
  }, []);
  useEffect(() => {
    selectBulk_projects([]);
    if (history.location.search && history.location.search.length) {
      dynamicLinkSearch();
    }
    return () => {};
  }, [history]);
  const dynamicLinkSearch = () => {
    let search = JSON.parse(
      '{"' +
        history.location.search.replace(/&/g, '","').replace(/=/g, '":"') +
        '"}',
      function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    );
    setBrowseTab(search["?tab"]);
    if (search.hasOwnProperty("filters[region]")) {
      let item = { name: search["filters[region]"], type: "region" };
      if (search["?tab"] === "projects") {
        let newFilters = { ...project_filters };
        newFilters.countries = item.name;

        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        let newFilters = { ...company_filters };
        newFilters.countries = item.name;
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
      setList({ name: search["filters[region]"], type: "region" });
    }
    if (search.hasOwnProperty("filters[mineral]")) {
      let item = {
        name: search["filters[mineral]"],
        type: "mineral",
        symbol: elements.find(
          (item) => item.name.toLowerCase() === search["filters[mineral]"]
        )
          ? elements
              .find(
                (item) => item.name.toLowerCase() === search["filters[mineral]"]
              )
              .chemical_symbol.substring(0, 4)
          : search["filters[mineral]"].substring(0, 3),
      };
      let data = {
        interval: "1d",
        identifier: item.name,
        range: "1y",
      };
      setCommodity(item.name);
      getCommodityData(data);
      if (search["?tab"] === "projects") {
        let newFilters = { ...project_filters };
        newFilters.mineral = item.name;
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        let newFilters = { ...company_filters };
        newFilters.mineral = item.name;
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
      setList({
        name: search["filters[mineral]"],
        type: "mineral",
        symbol: elements.find(
          (item) => item.name.toLowerCase() === search["filters[mineral]"]
        )
          ? elements
              .find(
                (item) => item.name.toLowerCase() === search["filters[mineral]"]
              )
              .chemical_symbol.substring(0, 4)
          : search["filters[mineral]"].substring(0, 3),
      });
    }
    if (search.hasOwnProperty("filters[country]")) {
      let item = { name: search["filters[country]"], type: "country" };
      if (search["?tab"] === "projects") {
        let newFilters = { ...project_filters };
        newFilters.countries = item.name;

        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        let newFilters = { ...company_filters };
        newFilters.countries = item.name;
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
      setList({ name: search["filters[country]"], type: "country" });
    }
  };
  const [markers, setMarkers] = useState([
    {
      name: "USA",
      lat: 38.9072,
      lng: -77.0369,
    },
    {
      name: "Finland",
      lat: 61.9241,
      lng: 25.7482,
    },
  ]);
  // let o = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

  const [loc, setLocation] = useState(null);
  const [zoom, setZoom] = useState(1);
  const pageRef = useRef();
  const [period, setPeriod] = useState("3M");
  const [filterOpen, setFilterOpen] = useState(false);
  const [SortOpen, setSortOpen] = useState(false);
  const [filterType, setType] = useState(null);
  const [mapfullscreen, setFull] = useState(false);
  const handleClose = () => {
    setFilterOpen(false);
  };
  const handleSortClose = () => {
    setSortOpen(false);
  };
  const switchPeriod = (period) => {
    setPeriod(period);
    let range = period.includes("1W")
      ? 7
      : period === "ALL"
      ? 30000
      : period === "1D"
      ? 1
      : period === "1M"
      ? 30
      : period === "3M"
      ? 90
      : period === "6M"
      ? 180
      : period === "2Y"
      ? 730
      : period === "3Y"
      ? 1095
      : period === "5Y"
      ? 1780
      : period === "1Y"
      ? 400
      : 90;
    setProjectStartDate(range);
    setProjectPage(1);

    filterProjects();
  };
  const [scrollTop, setscrollTop] = useState(false);
  const checkScrollPosition = () => {
    if (pageRef.current.scrollTop > 1000) {
      setscrollTop(true);
    } else {
      setscrollTop(false);
    }
  };
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 1000px)",
  });

  const [selectedTab, setSelectedTab] = useState("projects");
  return (
    <MainPage
      id="scrollableDiv"
      onScroll={checkScrollPosition}
      ref={pageRef}
      style={{
        paddingTop: iFrameDetection ? 0 : "85px",
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={filterOpen}
      >
        <Filter handleClose={handleClose} tab={tab} />
      </Dialog>
      <Dialog
        onClose={handleSortClose}
        aria-labelledby="simple-dialog-title"
        open={SortOpen}
      >
        <Sort handleClose={handleSortClose} tab={tab} />
      </Dialog>
      <Grid
        container
        className={` ${
          isTabletOrMobileDevice ? "browse-page" : "browse-page-wide"
        }  grid`}
      >
        <Grid
          item
          xs={iFrameDetection ? 0 : 0}
          md={iFrameDetection ? 0 : mapfullscreen ? 0 : 1}
          style={{ position: "sticky" }}
        >
          <Tabs
            className="sticky-tabs"
            value={value}
            orientation={isTabletOrMobileDevice ? "horizontal" : "vertical"}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
            style={{ textAlign: "left" }}
          >
            <Tab value="projects" icon={<PublicOutlined />} label="Projects" />
            <Tab
              value="companies"
              icon={<AssessmentOutlined />}
              label="Companies"
            />
            <Tab
              value="technical reports"
              icon={<FileCopyOutlined />}
              label="Reports"
            />
            <Tab
              value="Recent News & Filings"
              icon={<EmojiObjects />}
              label="Filings"
            />
            <Tab
              value="drilling results"
              icon={<PrecisionManufacturingOutlinedIcon />}
              label="Drilling"
            />
            <Tab value="Prospector Chat" icon={<ChatIcon />} label="Chat" />
          </Tabs>
        </Grid>
        {browse_page_tab !== "projects" ? (
          <Fragment>
            <Grid
              item
              xs={13}
              md={browse_page_tab === "Prospector Chat" ? 11 : 10}
              lg={browse_page_tab === "Prospector Chat" ? 11 : 8}
              xl={browse_page_tab === "Prospector Chat" ? 11 : 8}
              container
              style={{ position: "relative", display: "block", height: "100%" }}
            >
              <div
                style={{
                  textAlign: "left",
                  width: "100%",
                  marginBottom: "20px",
                  boxSizing: "border-box",
                  height: "100%",
                }}
              >
                <Content
                  projects_loading={projects_loading}
                  companies_page={companies_page}
                  map_projects={map_projects}
                  setProjectStartDate={setProjectStartDate}
                  setCompanyPage={setCompanyPage}
                  setProjectPage={setProjectPage}
                  filterCompanies={filterCompanies}
                  filterProjects={filterProjects}
                  setProjectFilters={setProjectFilters}
                  setCompanyFilters={setCompanyFilters}
                  setBrowseTab={setBrowseTab}
                  projects_length={projects_length}
                  companies_length={companies_length}
                  project_filters={project_filters}
                  company_filters={company_filters}
                  setCommodity={setCommodity}
                  getCommodityData={getCommodityData}
                  setCompany={setCompany}
                  setProject={setProject}
                  history={history}
                  tab={browse_page_tab}
                  setList={setList}
                  setTab={setTab}
                  selectedList={selectedList}
                  filterCompaniesStaging={filterCompaniesStaging}
                />
                {/* <Subtitle1 onClick={() => filterCompaniesStaging()} style={{ margin: '20px 0', textAlign: 'left', cursor: 'pointer' }} color={Primary}>More</Subtitle1> */}

                {browse_page_tab !== "companies" && scrollTop && (
                  <Button
                    onClick={() => pageRef.current.scrollTo(0, 0)}
                    className="scroll-top-button"
                  >
                    Return to top
                  </Button>
                )}
              </div>
              {/* <Content setBrowseTab={setBrowseTab} projects_length={projects_length} companies_length={companies_length} project_filters={project_filters} company_filters={company_filters} setCommodity={setCommodity} getCommodityData={getCommodityData} setCompany={setCompany} setProject={setProject} history={history} tab={browse_page_tab} setList={setList} setTab={setTab} selectedList={selectedList} /> */}
            </Grid>

            <Grid item xs={3}>
              {browse_page_tab === "Prospector Chat" ? null : (
                <Box
                  display={{ xs: "none", lg: "block" }}
                  style={{
                    position: "sticky",
                    width: "inherit",
                    top: 0,
                    padding: "10px",
                  }}
                >
                  <SummaryAccordion />
                </Box>
              )}
            </Grid>
          </Fragment>
        ) : (
          <Fragment>
            <Grid
              item
              xs={mapfullscreen ? 0 : 12}
              md={mapfullscreen ? 0 : 5}
              lg={iFrameDetection ? 6 : mapfullscreen ? 0 : 5}
              style={{
                position: "relative",
                display: mapfullscreen ? "none" : "block",
                transition: "width 0.8s ease",
              }}
            >
              <div style={{ boxSizing: "border-box", height: "100%" }}>
                <Content
                  projects_loading={projects_loading}
                  companies_page={companies_page}
                  map_projects={map_projects}
                  projects_page={projects_page}
                  projects_page_count={projects_page_count}
                  filterCompaniesStaging={filterCompaniesStaging}
                  filterProjectsStaging={filterProjectsStaging}
                  setProjectStartDate={setProjectStartDate}
                  setCompanyPage={setCompanyPage}
                  setProjectPage={setProjectPage}
                  filterCompanies={filterCompanies}
                  filterProjects={filterProjects}
                  setProjectFilters={setProjectFilters}
                  setCompanyFilters={setCompanyFilters}
                  setBrowseTab={setBrowseTab}
                  projects_length={projects_length}
                  companies_length={companies_length}
                  project_filters={project_filters}
                  company_filters={company_filters}
                  setCommodity={setCommodity}
                  getCommodityData={getCommodityData}
                  setCompany={setCompany}
                  setProject={setProject}
                  history={history}
                  tab={browse_page_tab}
                  setList={setList}
                  setTab={setTab}
                  selectedList={selectedList}
                />

                {/* {projects_page - 1 < Math.ceil(map_projects.length / 50) && <Subtitle1 onClick={() => filterProjects()} style={{ margin: '20px 0', textAlign: 'left', cursor: 'pointer' }} color={Primary}>More</Subtitle1>} */}
                {projects.length && scrollTop ? (
                  <Button
                    onClick={() => pageRef.current.scrollTo(0, 0)}
                    className="scroll-top-button"
                  >
                    Return to top
                  </Button>
                ) : null}
              </div>
            </Grid>

            <Grid
              className="calculated-height p-1"
              item
              xs={mapfullscreen ? 12 : 6}
              // xs={12}
              md={mapfullscreen ? 12 : 6}
              lg={mapfullscreen ? 12 : 6}
              style={{ transition: "width 0.8s ease" }}
            >
              {!isTabletOrMobileDevice && (
                <Fragment>
                  <div className="mapfullscreen flex-boy-row">
                    {mapfullscreen ? (
                      <KeyboardArrowRight
                        onClick={() => setFull(false)}
                        style={{ color: Primary, fontSize: 30 }}
                      />
                    ) : (
                      <KeyboardArrowLeft
                        onClick={() => setFull(true)}
                        style={{ color: Primary, fontSize: 30 }}
                      />
                    )}
                  </div>

                  {/* <Map mapfullscreen={mapfullscreen} location={loc} /> */}
                  {mapfullscreen ? (
                    <Mapbox mapfullscreen={mapfullscreen} />
                  ) : (
                    <Mapbox mapfullscreen={mapfullscreen} text="text" />
                  )}
                </Fragment>
              )}
            </Grid>
          </Fragment>
        )}
      </Grid>
    </MainPage>
  );
};

const mapStateToProps = (state) => ({
  companies_search: state.authReducer.companies_search || [],
  projects: state.authReducer.projects_search || [],
  project_filters: state.authReducer.projects_search_filters,
  company_filters: state.authReducer.company_search_filters,
  projects_page: state.authReducer.projects_page,
  companies_page: state.authReducer.companies_page,
  projects_page_count: state.authReducer.projects_page_count,
  projects_length: state.authReducer.filtered_projects_length,
  companies_length: state.authReducer.filtered_companies_length,
  browse_page_tab: state.authReducer.browse_page_tab,
  projects_loading: state.authReducer.projects_loading,
  map_projects: state.authReducer.map_projects || [],
});

const mapDispatchToProps = (dispatch) => ({
  setCompany: (data) => dispatch({ type: "SET_COMPANY", payload: data }),
  setProject: (data) => dispatch({ type: "SET_PROJECT", payload: data }),
  setCommodity: (data) => dispatch({ type: "SET_COMMODITY", payload: data }),
  getCommodityData: (data) => dispatch(getCommodityData(data)),
  setCompanyPage: (data) =>
    dispatch({ type: "SET_COMPANIES_PAGE", payload: data }),
  setProjectPage: (data) =>
    dispatch({ type: "SET_PROJECTS_PAGE", payload: data }),
  filterProjects: (data) => dispatch(filterProjects(data)),
  filterCompanies: (data) => dispatch(filterCompanies(data)),
  setBrowseTab: (data) => dispatch({ type: "SET_BROWSE_TAB", payload: data }),
  setProjectFilters: (data) =>
    dispatch({ type: "SET_PROJECT_FILTERS", payload: data }),
  setCompanyFilters: (data) =>
    dispatch({ type: "SET_COMPANY_FILTERS", payload: data }),
  filterCompaniesStaging: (data) => dispatch(filterCompaniesStaging()),
  filterProjectsStaging: () => dispatch(filterProjectsStaging()),
  setProjectStartDate: (data) =>
    dispatch({ type: "SET_PROJECT_START_DATE", payload: data }),
  selectBulk_projects: (data) =>
    dispatch({ type: "SET_SELECTED_BULK_PROJECTS", payload: data }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Browse);
