import React, { useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts-fix";
import { apiRequest } from "../../../store/Actions/AuthActions";
import { nFormatter } from "../../../configure/helpers";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../assets/ProspectorDesignAssets/lottie2.json";
import ContentLoader from "react-content-loader";
// Resolves charts dependancy
charts(FusionCharts);

const Scatter = ({
  company,
  year,
  mineral,
  classification,
  production_basis,
  type,
}) => {
  const [dataSource, setdataSource] = useState(null);
  useEffect(() => {
    setdataSource(null);
    fetchData();
    return () => {};
  }, [mineral, year, production_basis, company]);

  const fetchData = async () => {
    const data = await apiRequest
      .get(
        `companies/${company.id}/mineral-reserve-resources-quality-analysis?production_basis=${production_basis}&mineral=${mineral}&report_year=${year}&classification=${classification}`
      )
      .catch((err) => console.log(err));
    if (data?.data?.data) {
      setdataSource({
        chart: {
          showYAxisValues: "0",
          showAlternateHGridColor: "0",
          divLineAlpha: "60",
          divLineColor: "#ffffff",
          showDivLineValues: "0",
          showaxislines: "0",
          showXAxisValues: "0",
          xaxisminvalue: "23",
          xaxismaxvalue: "95",
          ynumberprefix: "$",
          yaxisminvalue: "1200",
          xnumbersuffix: "g/t",
          xAxisName: "Tonnage",
          yAxisName: "Grade",
          canvasborderColor: "#B9E1F4",
          canvasborderThicknes: "0.5",
          showBorder: "0",
          canvasbgAlpha: "0",
          canvasbgcolor: "0",
          bgcolor: "#ffffff",
        },

        dataset: [
          {
            anchorbgcolor: "5D62B5",
            anchorSides: "100",
            anchorRadius: "1",
            data: data.data.data
              .filter((item) => item.company_id !== company.id)
              .map((item) => ({
                x: Math.log2(item.x),
                y: Math.log2(item.y),
                toolText: ` ${item.project_name}, ${nFormatter(
                  item.x
                )}/${nFormatter(item.y)} ${item.grade_measurement}`,
              })),
          },
          {
            anchorbgcolor: "#FFAB05",
            anchorSides: "100",
            data: data.data.data
              .filter((item) => item.company_id === company.id)
              .map((item) => ({
                x: Math.log2(item.x),
                y: Math.log2(item.y),
                toolText: ` ${item.project_name}, ${nFormatter(item.x)} @ ${
                  item.y
                } ${item.grade_measurement}`,
              })),
          },
        ],
      });
    }
  };

  return (
    <div style={{ width: "100%", height: "fit-content" }}>
      {dataSource ? (
        <ReactFusioncharts
          type="scatter"
          width="100%"
          height="350"
          dataFormat="JSON"
          dataSource={dataSource}
        />
      ) : (
        <ContentLoader
          viewBox="0 0 600 350"
          style={{ width: "100%", height: "350px" }}
        >
          <rect
            x="20"
            y="8"
            rx="0"
            ry="0"
            style={{ width: "100%", height: "350px" }}
          />
        </ContentLoader>
      )}
    </div>
  );
};

export default Scatter;
