import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { ComponentContainer, Headline5, Headline6 } from "../../../components";
import CompanyBalanceSheet from "./CompanyBalanceSheet";
import CompanyIncomeStatements from "./CompanyIncomeStatements";
import CompanyCashFlowStatements from "./CompanyCashFlowStatements";

export const CompanyFinancialStatements = ({ company }) => {
  const tabs = [
    { name: "Annual", active: true },
    { name: "SemiAnnual", active: false },
    { name: "Quarterly", active: false },
  ];

  const [activeTab, setActiveTab] = useState("Annual");
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };
  return (
    <div>
      <Headline5>Financial Statements</Headline5>

      <ComponentContainer>
        <Headline6>Balance sheet</Headline6>
        <CompanyBalanceSheet company={company} activeTab={activeTab} />
      </ComponentContainer>

      <ComponentContainer>
        <Headline6>Income statements</Headline6>
        <CompanyIncomeStatements company={company} activeTab={activeTab} />
      </ComponentContainer>

      <ComponentContainer>
        <Headline6>Cash flow statements</Headline6>
        <CompanyCashFlowStatements company={company} activeTab={activeTab} />
      </ComponentContainer>
    </div>
  );
};

CompanyFinancialStatements.propTypes = {
  second: PropTypes.third,
};

const mapStateToProps = (state) => ({
  company: state.authReducer.selectedCompany,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyFinancialStatements);
