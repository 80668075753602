/* eslint-disable no-unused-vars */
import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import {
  Subtitle1,
  Headline5,
  Subtitle2,
  Button,
  NotifyDiv,
} from "../../../components";
import { TextField } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route } from "react-router-dom";
import PasswordConfirm from "./PasswordConfirm";
import PasswordResetEmail from "./PasswordResetEmail";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00AC69",
        border: "2px solid",
      },
      "&:hover fieldset": {
        borderColor: "#00AC69",
      },
    },
  },
}));

export const PasswordReset = ({ swapAuthPage }) => {
  const classes = useStyles();
  const [section, setSection] = useState("email");
  return (
    <div className="flex min-h-full">
      <div className="flex flex-1 flex-col w-1/4 justify-center py-12 px-2 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <Switch>
          <Route
            exact
            path={`/auth/password-reset/password-email`}
            render={(props) => <PasswordResetEmail />}
          />
          <Route
            path={`/auth/password-reset/password-confirm`}
            render={(props) => <PasswordConfirm />}
          ></Route>
        </Switch>
      </div>

      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={require("../../../assets/icon/bg.png")}
          alt=""
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
