import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Annotation,
  FormFields,
  FormDesigner,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import axios from "axios";
import { ComponentContainer } from "../../../components";
import ReactPlayer from "react-player/lazy";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import {
  defaultLayoutPlugin,
  ToolbarProps,
} from "@react-pdf-viewer/default-layout";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
export const DashboardModalcontent = ({ content }) => {
  const pdfviewerRef = useRef(null);

  const onRefChange = useCallback((node) => {
    if (node === null) {
      // DOM node referenced by ref has been unmounted
    } else {
      pdfviewerRef.current = node;

      // DOM node referenced by ref has changed and exists
    }
  }, []);

  useEffect(() => {
    loadDocument();
    return () => {};
  }, [content]);
  const [documentBase64, setdocumentBase64] = useState(null);
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const transform = (slot) => ({
    ...slot,
    // These slots will be empty
    Open: () => <></>,
    SwitchTheme: () => <></>,
  });
  const renderToolbar = (Toolbar) => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });
  const loadDocument = async () => {
    const file = await axios
      .get(
        content.content,

        { responseType: "blob" }
      )
      .catch((ex) => {
        console.error(ex);
      });
    if (file) {
      // const buffer = Buffer.from(file.data, 'binary');
      let reader = new window.FileReader();
      reader.readAsDataURL(file.data);
      reader.onloadend = function () {
        let base64data = reader.result;

        //

        if (pdfviewerRef.current) {
          pdfviewerRef.current.load(base64data);
        }
      };

      // setdocumentBase64(buffer.toString('base64'))
      //
    }
  };
  return (
    <ComponentContainer style={{ width: "98%", height: "90vh" }}>
      {content.type === "pdf" ? (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
          <div
            style={{
              border: "1px solid rgba(0, 0, 0, 0.3)",
              height: "100%",
            }}
          >
            <Viewer
              plugins={[defaultLayoutPluginInstance]}
              fileUrl={content.content}
            />
          </div>
        </Worker>
      ) : content.type === "iframe" ? (
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={content.content}
          title="company frame"
          frameBorder="0"
        ></iframe>
      ) : (
        <ReactPlayer width="100%" height="100%" url={content.content} />
      )}
    </ComponentContainer>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardModalcontent);
