/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import AutoComp from "../AutoComp";
import { Subtitle1, Body2, Hexagon, GreyBox } from "../general";
import TuneRoundedIcon from "@material-ui/icons/TuneRounded";
import SparkLine from "../SparkLine";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import {
  createChat,
  updateSelectedChat,
} from "../../store/Actions/AuthActions";
import { elements } from "../../configure/constants";
const dummy_data = {
  lists: [
    {
      name: "Copper Investments",
      number: 3,
    },
    {
      name: "PDAC 2020 Attendees",
      number: 3,
    },
  ],
  following: [
    {
      name: "Canada",
      number: 300,
    },
    {
      name: "Gold",
      number: 175,
    },
    {
      name: "Silver",
      number: 56,
    },
  ],
  matches: [
    {
      ticker: "PMD:CVE",
      name: "Palladium One Mining Inc.",
      match_status: "matched",
      projects: 1,
      minerals: ["Au", "Pd", "Pt", "Ni", "Cu"],
    },
    {
      ticker: "RDG:CVE",
      name: "Ridgeline Minerals Corp.",
      projects: 1,
      match_status: "pending",
      minerals: ["Au"],
    },
  ],
};

export const Prospects = ({
  auth,
  selectedList,
  activeUser,
  list,
  history,
  setValue,
  createChat,
  updateSelectedChat,
}) => {
  let me = auth.uid;

  const prospects = useSelector((state) => state.authReducer.prospects);
  const connectChat = async (item) => {
    await createChat({ userId: item.id, name: item.name || item.email });
    setValue(0);
  };
  return (
    <div>
      <div style={{ width: "100%" }}>
        <div className="flex-boy-row" style={{ marginBottom: "25px" }}>
          <AutoComp />
          <div className="prospects-filter">
            <TuneRoundedIcon />
          </div>
        </div>

        {prospects && prospects.length
          ? prospects.map((match, index) => {
              return (
                <div
                  className="flex-boy-row"
                  style={{
                    marginBottom: "20px",
                    borderBottom: "1px solid #00000061",
                    padding: "5px",
                  }}
                  key={index}
                >
                  <div>
                    <div className="company-ticker">
                      <Subtitle1>{match.name || match.email}</Subtitle1>
                      <Body2>
                        {match.companyData ? match.companyData.name : ""}
                      </Body2>
                    </div>

                    <div className="flex-boy-row">
                      {match.interests && match.interests.minerals
                        ? match.interests.minerals.map((min, index) => {
                            return (
                              <Hexagon key={index} width={28}>
                                {elements.find(
                                  (item) =>
                                    item.name.toLowerCase() ===
                                    min.trim().toLowerCase()
                                )
                                  ? elements
                                      .find(
                                        (item) =>
                                          item.name.toLowerCase() ===
                                          min.trim().toLowerCase()
                                      )
                                      .chemical_symbol.substring(0, 4)
                                  : min.substring(0, 4)}
                              </Hexagon>
                            );
                          })
                        : null}
                    </div>
                  </div>
                  <div>
                    {match.companyData && match.companyData.stockHistory ? (
                      <div className="tiny-spark">
                        <SparkLine
                          color={index === 0 ? "#B00020" : "#00C805"}
                          data={match.companyData.stockHistory}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <GreyBox
                      onClick={() =>
                        match.follow_accepted ? connectChat(match) : null
                      }
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        textTransform: "uppercase",
                        color: match.follow_accepted ? "white" : "#92929D",
                        backgroundColor: match.follow_accepted
                          ? "#00AC69"
                          : "#0000001F",
                        cursor: match.follow_accepted
                          ? "pointer"
                          : "not-allowed",
                      }}
                    >
                      {match.follow_accepted ? "Connect" : "Pending"}
                    </GreyBox>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  selectedList: state.authReducer.selectedList,
  activeUser: state.authReducer.activeUser,
  list: state.authReducer.lists,
});

const mapDispatchToProps = (dispatch) => ({
  updateSelectedChat: (data) => dispatch(updateSelectedChat(data)),
  createChat: (data) => dispatch(createChat(data)),
  setChatSection: (data) =>
    dispatch({ type: "SET_CHAT_SECTION", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Prospects);
