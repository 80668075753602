/* eslint-disable no-unused-vars */
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import { act } from "react-dom/cjs/react-dom-test-utils.production.min";
const initialState = {
  activeUser: null,
  error: null,
  selectedChat: null,
  toastId: null,
  userMeetings: [],
  stripeData: null,
  subscription_Message: "upgrade",
  commodity_stock: [],
  browse_page_tab: "projects",
  selectedCommodity: [],
  project_start_after: 400,
  projects_page_count: 1,
  user_follow_list: [],
  bulk_select_projects: [],
  bulk_select_companies: [],
  loaded_reports: [],
  filings: [],
  filings_loading: [],
  projects_search_filters_saved: {
    mineral: null,
    primary_metal: null,
    start_after: null,
    searchTerm: null,
    exchange: null,
    regions: null,
    countries: null,
    resourceType: null,
    averageGrade_min: 0,
    averageGrade_max: 0,
    oreTonnage_min: 0,
    oreTonnage_max: 0,
    containedMetal_min: 0,
    containedMetal_max: 0,
  },
  projects_search_filters: {
    mineral: null,
    primary_metal: null,
    start_after: null,
    searchTerm: null,
    exchange: null,
    regions: null,
    countries: null,
    resourceType: null,
    averageGrade_min: 0,
    averageGrade_max: 0,
    oreTonnage_min: 0,
    oreTonnage_max: 0,
    containedMetal_min: 0,
    containedMetal_max: 0,
  },
  company_sort_filter: {
    name: "market_cap",
    type: "desc",
  },
  project_sort_filter: {
    name: null,
    type: null,
  },
  report_sort_filter: {
    name: "publication_date",
    type: "desc",
  },
  filings_sort_filter: {
    name: "publication_date",
    type: "desc",
  },
  company_search_filters: {
    mineral: null,

    start_after: null,
    searchTerm: null,
    exchange: ["TSX", "TSXV", "ASX"],
    regions: null,
    countries: null,
    filings_after: 30000,
    resourceType: null,
    averageGrade_min: 0,
    averageGrade_max: 0,
    oreTonnage_min: 0,
    oreTonnage_max: 0,
    containedMetal_min: 0,
    containedMetal_max: 0,
  },
  report_search_filters: {
    minerals: null,
    searchTerm: null,
    // exclude_sections: [],
    // include_sections: [],
    all_of: [],
    // none_of: [],
    // phrase: {
    //   word_count: null,
    //   searchTerm: null,
    //   near: null,
    // },
    exact_phrase: null,
    exchange: null,
    region: null,
    country: null,
    mineral_estimate: null,
    publication_date: null,
    from_date: null,
    to_date: null,
    days: null,
    author: null,
    search_text: null,
  },
  filings_filters: {
    headline: null,
    exchange: null,
    exact_phrase: null,
    all_of: [],
    none_of: [],
    phrase: {
      word_count: null,
      searchTerm: null,
      near: null,
    },
    date_range: null,
    filing_company: null,
    filing_type: null,
  },
  hover_project: null,
  filtered_projects_length: 0,
  filtered_companies_length: 0,
  filtered_reports_length: 0,
  filtered_filings_length: 0,
  selectedCompany: null,
  companies_page: 0,
  projects_page: 0,
  reports_page: 0,
  filings_page: 0,
  rerouteTo: null,
  project_reports_loading: false,
  selectedCompany_social: {
    data: [],
    startPage: null,
  },
  selectedCompany_news: {
    data: [],
    startPage: null,
  },
  selectedProject_social: {
    data: [],
    startPage: null,
  },
  selectedProject_news: {
    data: [],
    startPage: null,
  },
  insights_news: {
    data: [],
    startPage: null,
  },
  selectedCompany_reports: [],
  subscription_popup: false,
  selectedProject: null,
  mapBounds: null,
  location: null,
  projects_loading: false,
  insights_loading: false,
  reports_loading: false,
  companies_loading: false,
  company_loading: false,
  companies_search: [],
  projects_search: [],
  reports_search: [],
  filings_search: [],
  subscription: null,
  recommended_projects: [],
  performance_aggregates: null,
  lading_type: "projects",
  map_projects: [],
  company_follows: [],
  project_follows: [],
  minerals_list: [],
  list: [],
  tags: [],
  selectedTags: [],
  messages: [],
  selectedChatSection: "chat_list",
  selectedListItem: null,
  drillingMinerals: [],
  drillingFilters: {
    mineral: null,
    stage: null,
    deposit_type: null,
    intercept_filter: false,
    intercept_minerals: [],
    countries: null,
  },

  selectedNoteColumns: {
    company: [
      { key: "id", label: "ID", display: false },
      { key: "name", label: "Company Name", display: true },
      { key: "description", label: "Description", display: false },
      { key: "industry", label: "Industry", display: false },
      { key: "yahoo_ticker", label: "Yahoo Ticker", display: false },
      { key: "exchange", label: "Exchange", display: false },
      { key: "ticker", label: "Ticker", display: false },
      { key: "address_1", label: "Address1", display: false },
      { key: "address_2", label: "Address2", display: false },
      { key: "email", label: "Email", display: false },
      { key: "fax", label: "Fax", display: false },
      { key: "phone", label: "Phone", display: false },
      { key: "url", label: "URL", display: false },
      { key: "countries", label: "Countries", display: false },
      { key: "minerals", label: "Metals", display: true },
      { key: "market_cap", label: "Market Cap", display: true },
      { key: "tags", label: "Tags", display: true },
      { key: "projects_count", label: "# of Projects", display: true },
      { key: "country", label: "Country", display: false },
      { key: "date", label: "Date", display: false },
      { key: "div_yield", label: "Div/Yield", display: false },
      { key: "float", label: "Float", display: false },
      { key: "held_by_insider", label: "% Held by Insiders", display: false },
      {
        key: "held_by_institutions",
        label: "% Held by Institutions",
        display: false,
      },
      { key: "market_cap", label: "Market Cap", display: false },
      { key: "open_price", label: "Open Price", display: false },
      { key: "p_e_ration", label: "P/E Ratio", display: false },
      {
        key: "ten_days_average_volume",
        label: "Avg Volume (10 day)",
        display: false,
      },
      {
        key: "three_months_average_volume",
        label: "Avg Volume (3 month)",
        display: false,
      },
      { key: "today_high_price", label: "Today's High", display: false },
      { key: "today_low_price", label: "Today's Low", display: false },
      { key: "year_high_price", label: "52 week High", display: false },
      { key: "year_low_price", label: "52 week Low", display: false },
    ],
    project: [
      { key: "id", label: "ID", display: false },
      { key: "name", label: "Project Name", display: true },
      { key: "minerals", label: "Metals", display: true },
      { key: "primary_mineral", label: "Primary Mineral", display: false },
      { key: "country", label: "Country", display: true },
      { key: "tags", label: "Tags", display: true },
      { key: "mineral_estimate", label: "Mineral Estimate", display: true },
      { key: "report_id", label: "Report ID", display: false },
      { key: "report_name", label: "Reporter", display: false },
      { key: "report_section", label: "Report Section", display: false },
      { key: "report_summary", label: "Report summary", display: false },
      { key: "report_date", label: "Report Date", display: false },
      { key: "stage", label: "Stage", display: false },
      { key: "lat", label: "LAT", display: false },
      { key: "lng", label: "LNG", display: false },
    ],
  },
  signUp: {
    auth_type: "",
    email: "",
    lastName: "",
    firstName: "",
    selectedList: null,
    companies: [],
    prospects: [],
    type: null,
    companyPosition: "",
    companyName: "",
    companyId: null,
    bio: "",
    interestRegions: [],
    interestMinerals: [],
    password: null,
  },
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    // User Login
    case "LOGIN_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        toastId: toast("Logging in...", { autoClose: false }),
      };
    case "SET_MAP_PROJECTS":
      return {
        ...state,
        map_projects: action.payload,
      };
    case "SET_PROJECTS_LOADING":
      return {
        ...state,
        projects_loading: action.payload,
      };
    case "SET_COMPANIES_LOADING":
      return {
        ...state,
        companies_loading: action.payload,
      };
    case "SET_SELECTED_TAGS":
      return {
        ...state,
        selectedTags: action.payload,
      };
    case "SET_MINERALS_LIST":
      return {
        ...state,
        minerals_list: action.payload.data,
      };
    case "SET_INSIGHTS_LOADING":
      return {
        ...state,
        insights_loading: action.payload,
      };
    case "SET_PROJECT_REPORTS_LOADING":
      return {
        ...state,
        project_reports_loading: action.payload,
      };

    case "SET_PROJECTS_PAGE_COUNT":
      return {
        ...state,
        projects_page_count: action.payload,
      };
    case "SET_REPORTS_LOADING":
      return {
        ...state,
        reports_loading: action.payload,
      };

    case "SET_FILINGS_LOADING":
      return {
        ...state,
        filings_loading: action.payload,
      };

    case "SET_DRILLING_FILTERS":
      return {
        ...state,
        drillingFilters: action.payload,
      };

    case "SET_DRILLING_MINERALS":
      return {
        ...state,
        drillingMinerals: action.payload,
      };

    case "SET_USER_FOLLOW_LIST":
      return {
        ...state,
        user_follow_list: action.payload,
      };
    case "SET_COMMODITY_STOCK_DATA":
      return {
        ...state,
        commodity_stock: action.payload,
      };
    case "SET_SUBSCRIPTION_MESSAGE":
      return {
        ...state,
        subscription_Message: action.payload,
      };
    case "SET_PROJECT_START_DATE":
      return {
        ...state,
        project_start_after: action.payload,
      };
    case "SET_LISTS":
      return {
        ...state,
        lists: action.payload,
      };
    case "SET_TAGS":
      return {
        ...state,
        tags: action.payload,
      };
    case "SET_NOTE_COLUMNS":
      return {
        ...state,
        selectedNoteColumns: action.payload,
      };

    case "SET_COMMODITY":
      return {
        ...state,
        selectedCommodity: action.payload,
      };
    case "SET_BROWSE_TAB":
      return {
        ...state,
        browse_page_tab: action.payload,
        bulk_select_projects: [],
      };
    case "SET_PROJECTS_LENGTH":
      return {
        ...state,
        filtered_projects_length: action.payload,
      };
    case "SET_COMPANIES_LENGTH":
      return {
        ...state,
        filtered_companies_length: action.payload,
      };

    case "SET_REPORTS_LENGTH":
      return {
        ...state,
        filtered_reports_length: action.payload,
      };
    case "SET_FILINGS_LENGTH":
      return {
        ...state,
        filtered_filings_length: action.payload,
      };

    case "SET_SUBSCRIPTION_POPUP":
      return {
        ...state,
        subscription_popup: action.payload,
      };
    case "SET_SUBSCRIPTION_STATUS":
      return {
        ...state,
        subscription: action.payload,
      };
    case "SET_COMPANY":
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case "SET_REPORT":
      return {
        ...state,
        selectedReport: action.payload,
      };

    case "SET_FILING":
      return {
        ...state,
        selectedFiling: action.payload,
      };
    case "SET_COMPANY_FILTERS":
      return {
        ...state,
        company_search_filters: action.payload,
      };
    case "RESET_COMPANY_FILTERS":
      return {
        ...state,
        company_search_filters: initialState.company_search_filters,
        companies_page: 1,
      };
    case "SET_REPORTS_FILTERS":
      return {
        ...state,
        report_search_filters: action.payload,
      };
    case "RESET_REPORTS_FILTERS":
      return {
        ...state,
        report_search_filters: initialState.report_search_filters,
        reports_page: 1,
      };
    case "SET_FILINGS_FILTERS":
      return {
        ...state,
        filings_filters: action.payload,
        filings_page: 1,
      };

    case "RESET_FILINGS_FILTERS":
      return {
        ...state,
        filings_filters: initialState.filings_filters,
        filings_page: 1,
      };
    case "SET_COMPANY_SORT_FILTERS":
      return {
        ...state,
        company_sort_filter: action.payload,
        companies_page: 1,
      };

    case "SET_REPORT_SORT_FILTERS":
      return {
        ...state,
        report_sort_filter: action.payload,
        reports_page: 1,
      };
    case "SET_FILINGS_SORT_FILTERS":
      return {
        ...state,
        filings_sort_filter: action.payload,
        filings_page: 1,
      };
    case "SET_HOVER_PROJECT":
      return {
        ...state,
        hover_project: action.payload,
      };
    case "SET_PROJECT_FILTERS":
      return {
        ...state,
        projects_search_filters: action.payload,
      };
    case "RESET_PROJECT_FILTERS":
      return {
        ...state,
        projects_search_filters: initialState.projects_search_filters,
        projects_page: 1,
      };
    case "SET_PROJECT_SORT_FILTERS":
      return {
        ...state,
        project_sort_filter: action.payload,
        projects_page: 1,
      };
    case "SET_PROJECTS_PAGE":
      return {
        ...state,
        projects_page: action.payload,
      };
    case "SET_COMPANIES_PAGE":
      return {
        ...state,
        companies_page: action.payload,
      };
    case "SET_REPORTS_PAGE":
      return {
        ...state,
        reports_page: action.payload,
        reports_length: action.payload,
      };
    case "SET_FILINGS_PAGE":
      return {
        ...state,
        filings_page: action.payload,
      };
    case "SET_COMPANY_NEWS":
      let news_data = { ...state.selectedCompany_news };
      news_data.next = action.payload.next;
      news_data.data = news_data.data.concat(action.payload.data);
      return {
        ...state,
        selectedCompany_news: action.payload,
      };
    case "SET_PROJECT_NEWS":
      let new_data = { ...state.selectedProject_news };
      new_data.next = action.payload.next;
      new_data.data = new_data.data.concat(action.payload.data);
      return {
        ...state,
        selectedProject_news: action.payload,
      };
    case "SET_INSIGHTS_NEWS":
      return {
        ...state,
        insights_news: action.payload,
      };

    case "SET_COMPANY_SOCIAL":
      let social_data = { ...state.selectedCompany_news };
      social_data.next = action.payload.next;
      social_data.data = [
        ...state.selectedCompany_social.data,
        ...action.payload.data,
      ];
      return {
        ...state,
        selectedCompany_social: action.payload,
      };
    case "SET_PROJECT_SOCIAL":
      let soc_data = { ...state.selectedProject_social };
      soc_data.next = action.payload.next;
      soc_data.data = [
        ...state.selectedProject_social.data,
        ...action.payload.data,
      ];
      return {
        ...state,
        selectedProject_social: action.payload,
      };

    case "SET_PROJECT_REPORTS":
      return {
        ...state,
        selectedCompany_reports: action.payload,
      };
    case "SET_SELECTED_BULK_PROJECTS":
      return {
        ...state,
        bulk_select_projects: action.payload,
      };
    case "SET_SELECTED_BULK_COMPANIES":
      return {
        ...state,
        bulk_select_projects: action.payload,
      };
    case "SET_PROJECT":
      return {
        ...state,
        selectedProject: action.payload,
      };
    case "SET_RECOMMENDED_PROJECTS":
      return {
        ...state,
        recommended_projects: action.payload,
      };
    case "LOGIN_SUCCESS": {
      let user = action.user;

      user.subscriptions = [
        { subscription: "has atleat one" },
        ...user.subscriptions,
      ];

      toast.update(state.toastId, {
        theme: "light",
        render: "Login Success",
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });

      return {
        ...state,
        loading: false,
        error: null,
        activeUser: user,
      };
    }
    case "INVITE_REQUEST":
      return {
        ...state,
        toastId: toast("Sending invite", { autoClose: false }),
      };
    case "INVITE_SUCCESS": {
      toast.update(state.toastId, {
        theme: "light",
        render: "Invite sent",
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      return {
        ...state,
      };
    }

    case "SEND_MESSAGE_FAILURE":
      return {
        ...state,
        loading: true,
        error: null,
        toastId: toast(action.message, {
          type: toast.TYPE.ERROR,
          autoClose: false,
        }),
      };
    case "LOGIN_FAILURE":
      toast.update(state.toastId, {
        theme: "light",
        render: action.message,
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
      return {
        ...state,
        loading: false,
        error: action.message,
        activeUser: null,
      };
    case "SELECT_LIST":
      return {
        ...state,
        selectedList: action.payload,
      };
    case "SET_PROJECTS":
      let projects = [...state.projects_search];
      projects =
        action.payload.page === 1
          ? action.payload.projects
          : projects.concat(action.payload.projects);
      return {
        ...state,
        // project: action.payload.projects
        projects_search: projects,
      };

    case "SET_MAP_BOUNDS":
      return {
        ...state,
        mapBounds: action.payload,
      };
    case "SET_PROSPECTS":
      return {
        ...state,
        prospects: action.payload,
      };
    case "SET_MEETINGS":
      return {
        ...state,
        userMeetings: action.payload,
      };
    case "SET_PERFORMANCE_AGGREGATES":
      return {
        ...state,
        performance_aggregates: action.payload,
      };
    case "SET_COMPANIES":
      return {
        ...state,
        companies: action.payload,
      };
    case "SET_REPORTS":
      let reports = [...state.reports_search];
      reports = action.payload.reports;

      return {
        ...state,
        reports_search: reports,
      };
    case "SET_FILINGS":
      let filings = [...state.filings_search];
      filings = action.payload.filings;
      return {
        ...state,
        filings_search: filings,
      };

    case "SET_PROJECT_COMPANIES":
      let companies = [...state.companies_search];

      companies =
        action.payload.page === 1
          ? action.payload.companydata
          : companies.concat(action.payload.companydata);

      return {
        ...state,
        companies_search: _.uniq(companies),
        // companies_search: action.payload
      };
    // select chat
    case "SELECT_CHAT":
      return {
        ...state,
        selectedChat: action.payload,
      };
    case "SET_CHAT_SECTION":
      return {
        ...state,
        selectedChatSection: action.payload,
      };
    // User Signup
    case "SIGNUP_REQUEST":
      return {
        ...state,
        error: null,
        toastId: toast("Signing up...", { autoClose: false }),
      };
    case "SET_MESSAGES":
      return {
        ...state,
        messages: action.payload,
      };
    case "UPDATE_SIGNUP_DETAILS":
      const signup_data = Object.assign(state.signUp, action.payload);
      return {
        ...state,
        signUp: signup_data,
      };
    case "SEND_PASSWORD_RESET_REQUEST":
      return {
        ...state,

        error: null,
        toastId: toast("Requesting Reset...", { autoClose: false }),
      };
    case "SENT_PASSWORD_RESET":
      toast.update(state.toastId, {
        theme: "light",
        render:
          "A password reset link has been sent to your email please confirm to reset your password",
        type: toast.TYPE.SUCCESS,
        autoClose: false,
      });
      return {
        ...state,
      };
    case "SENT_PASSWORD_FAILURE":
      toast.update(state.toastId, {
        theme: "light",
        render: action.message,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
      return {
        ...state,
      };
    case "SIGNUP_SUCCESS":
      const user = action.user;

      toast.update(state.toastId, {
        theme: "light",
        render: "Sign In Success",
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      if (user.permissions && user.permissions.length) {
        user.subscriptions = [
          { subscription: "has atleat one" },
          ...user.subscriptions,
        ];
      }

      return {
        ...state,
        loading: false,
        error: null,
        activeUser: user,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_COMPANY_LOADING":
      return {
        ...state,
        company_loading: action.payload,
      };
    case "SIGNUP_FAILURE":
      toast.update(state.toastId, {
        theme: "light",
        render: action.message,
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
      return {
        ...state,
        error: action.message,
        activeUser: null,
      };
    // User update
    case "UPDATE_USER_REQUEST":
      return {
        ...state,
        error: null,
        toastId: toast("Updating user data", { autoClose: false }),
      };

    case "UPDATE_USER_FAIL":
      toast.update(state.toastId, {
        theme: "light",
        render: action.payload,
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
      return {
        ...state,
        error: action.message,
        activeUser: null,
      };
    case "UPDATE_USER_SUCCESS":
      toast.update(state.toastId, {
        theme: "light",
        render: "User data uptdated successfully",
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      return {
        ...state,
        error: action.message,
        activeUser: action.payload,
      };
    case "UPDATE_USER_PROFILE_REQUEST":
      return {
        ...state,
        error: null,
        toastId: toast("Updating user profile", { autoClose: false }),
      };

    case "UPDATE_USER_PROFILE_SUCCESS":
      toast.update(state.toastId, {
        theme: "light",
        render: "User profile uptdated successfully",
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      return {
        ...state,
        error: action.message,
        activeUser: action.payload,
      };
    case "FETCH_USER_SUBSCRIPTION_SUCCESS":
      toast.update(state.toastId, {
        theme: "light",
        render: "User Billing Fetched",
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      return {
        ...state,
        error: action.message,
        stripeData: action.payload,
      };
    // User Logout
    case "LOGOUT_REQUEST":
      toast("Logout Success", { type: toast.TYPE.SUCCESS, autoClose: 3000 });
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
