/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { TextField, Slider } from "@material-ui/core";
import {
  Headline6,
  MainPage,
  Primary,
  Headline5,
  Body1,
  Body2,
  GreyBox,
  Button,
  Subtitle1,
  Subtitle2,
  BrowseListFilter,
  Hexagon,
  NotifyDiv,
} from "../general";
import CloseIcon from "@material-ui/icons/Close";
import { searchClient } from "../../configure/constants";
import {
  searchAlgoliaCompanies,
  searchAlgoliaProjects,
  filterProjects,
  filterCompanies,
} from "../../store/Actions/AuthActions";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { regions, elements, resourceTypes } from "../../configure/constants";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import axios from "axios";
import _ from "lodash";
import Select from "react-select";
const PrettoSlider = createMuiTheme({
  overrides: {
    MuiSlider: {
      root: {
        color: "#52af77",
        height: 8,
      },
      thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
          boxShadow: "inherit",
        },
      },
      active: {},
      valueLabel: {
        left: "calc(-50% + 4px)",
      },
      track: {
        height: 8,
        borderRadius: 4,
      },
      rail: {
        height: 8,
        borderRadius: 4,
      },
    },
  },
});
export const FilterContent = ({
  selectComodity,
  setCompanyPage,
  projects_length,
  companies_length,
  setProjectPage,
  projects,
  companies,
  searchAlgoliaCompanies,
  auth,
  tab,
  handleClose,
  filterProjects,
  setProjectFilters,
  setCompanyFilters,
  project_filters,
  company_filters,
  filterCompanies,
}) => {
  let me = auth.uid;
  const [value, setValue] = React.useState([20, 37]);
  const [extraMinerals, setMinerals] = useState([]);
  const [extraRegions, setRegions] = useState([]);
  const [showextraRegions, showRegions] = useState(false);
  const [showextraMinerals, showMinerals] = useState(false);

  useEffect(() => {
    getFilterInfo();
    if (tab === "projects") {
      updateFilters(
        project_filters || {
          mineral: null,
          start_after: null,
          searchTerm: null,
          countries: null,
          stage: null,
          resourceType: null,
          averageGrade_min: 0,
          averageGrade_max: 0,
          oreTonnage_min: 0,
          oreTonnage_max: 0,
          containedMetal_min: 0,
          containedMetal_max: 0,
        }
      );
      // search_projects({loc:0,newFilters:filters})
    } else {
      updateFilters(
        company_filters || {
          mineral: null,
          start_after: null,
          searchTerm: null,
          countries: null,
          stage: null,
          resourceType: null,
          averageGrade_min: 0,
          averageGrade_max: 0,
          oreTonnage_min: 0,
          oreTonnage_max: 0,
          containedMetal_min: 0,
          containedMetal_max: 0,
        }
      );
      // search_companies({loc:0,newFilters:filters})
    }
    return () => {};
  }, []);
  const getFilterInfo = async (id) => {
    const countries_data = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/config/countries`
    );
    const minerals_data = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/config/minerals`
    );
    if (countries_data) {
      setRegions(countries_data.data.data.sort());
    }
    if (minerals_data) {
      setMinerals(minerals_data.data.data.sort());
    }
  };
  const [filters, updateFilters] = useState({
    mineral: null,
    start_after: null,
    searchTerm: null,
    countries: null,
    resourceType: null,
    averageGrade_min: 0,
    averageGrade_max: 0,
    oreTonnage_min: 0,
    oreTonnage_max: 0,
    containedMetal_min: 0,
    containedMetal_max: 0,
  });
  const setFilters = (filters) => {
    updateFilters(filters);
  };

  // const companiesIndex = searchClient.initIndex('dev_companies_idx')
  // const projectsIndex = searchClient.initIndex('dev_projects_idx')
  // let company_hit_list = []
  // let project_hit_list = []

  const search_companies = ({ loc, newFilters }) => {
    setCompanyPage(0);
    filterCompanies();
  };
  const search_projects = ({ loc, newFilters }) => {
    setProjectPage(0);
    filterProjects();
  };
  function numFormatter(num) {
    if (num > 999) {
      return (num / 1000000).toFixed(1) + " Million"; // convert to M for number from > 1 million
    } else {
      return num; // if value < 1000, nothing to do
    }
  }

  const updateSlider = (name, val) => {
    let newFilters = { ...filters };
    newFilters[`${name}_min`] = val[0] * 1000000;
    newFilters[`${name}_max`] = val[1] * 1000000;
    setFilters(newFilters);
    if (tab === "projects") {
      setProjectFilters(newFilters);
      search_projects({ loc: 0, newFilters });
    } else {
      setCompanyFilters(newFilters);
      search_companies({ loc: 0, newFilters });
    }
  };
  const updateAverageGrade = (name, val) => {
    let newFilters = { ...filters };
    newFilters[`${name}_min`] = val[0];
    newFilters[`${name}_max`] = val[1];

    setFilters(newFilters);
    if (tab === "projects") {
      setProjectFilters(newFilters);
      search_projects({ loc: 0, newFilters });
    } else {
      setCompanyFilters(newFilters);
      search_companies({ loc: 0, newFilters });
    }
  };
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const resTypes = [
    {
      name: "Indicated Mineral Resource",
      value: 85,
      indicator: "indicated",
    },
    {
      name: "Inferred Mineral Resource",
      value: 84,
      indicator: "inferred",
    },
    {
      name: "Measured Mineral Resource",
      value: 86,
      indicator: "measured",
    },
    {
      name: "Not Mentioned",
      value: 89,
      indicator: "not mentioned",
    },
    {
      name: "Probable Mineral Reserve",
      value: 87,
      indicator: "probable",
    },

    {
      name: "Proven Mineral Reserve",
      value: 88,
      indicator: "proven",
    },
  ];

  const filterMinerals = [
    "cobalt",
    "copper",
    "gold",
    "rare-earth-metals",
    "lithium",
    "silver",
    "nickel",
    "palladium",
  ];
  const updateSingleFilterKey = (name, value) => {
    let newFilters = { ...filters };

    if (JSON.stringify(newFilters[name]) === JSON.stringify(value)) {
      newFilters[name] = null;
      setFilters(newFilters);
      if (tab === "projects") {
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
    } else {
      newFilters[name] = value;
      setFilters(newFilters);
      if (tab === "projects") {
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
    }
  };
  const clearFilters = () => {
    let newFilters = {
      mineral: null,
      start_after: null,
      searchTerm: null,
      countries: null,
      resourceType: null,
      averageGrade_min: 0,
      averageGrade_max: 0,
      oreTonnage_min: 0,
      oreTonnage_max: 0,
      containedMetal_min: 0,
      containedMetal_max: 0,
    };
    if (tab === "projects") {
      setProjectFilters(newFilters);
      search_projects({ loc: 0, newFilters });
      handleClose();
    } else {
      setCompanyFilters(newFilters);
      search_companies({ loc: 0, newFilters });
      handleClose();
    }
  };

  return (
    <NotifyDiv style={{ width: "400px", padding: 0 }}>
      <div
        className="flex-boy-row"
        style={{
          width: "100%",
          justifyContent: "normal",
          padding: "20px",
          boxSizing: "border-box",
          borderBottom: "1px solid grey",
        }}
      >
        <CloseIcon onClick={handleClose} />
        <Headline5 style={{ flex: 1 }} color={Primary}>
          Select List
        </Headline5>
      </div>
      <div
        className="flex-boy-column"
        style={{
          padding: "10px",
          overflowY: "scroll",
          flex: 1,
          justifyContent: "normal",
        }}
      >
        {/* <div className="rangeFilterContainer" style={{ textAlign: 'left' }}>
                    <Subtitle1 style={{ margin: '10px' }}>Mineral Resource Type</Subtitle1>
                    <div className="flex-boy-row" style={{ flexWrap: 'wrap', justifyContent: 'normal' }}>
                        {
                            resTypes.map((key, index) => {
                                return (
                                    <BrowseListFilter className={filters.resourceType && filters.resourceType === key.name ? 'active-fiter-item' : null} onClick={() => updateSingleFilterKey('resourceType', key.name)} key={index}>
                                        <Subtitle2 style={{ margin: "5px", textTransform: 'capitalize' }}>{key.indicator.replace('_', ' ')}</Subtitle2>
                                    </BrowseListFilter>
                                )
                            })

                        }
                    </div>

                </div> */}
        <div className="rangeFilterContainer" style={{ textAlign: "left" }}>
          <Subtitle1 style={{ margin: "10px" }}>
            Regions and Countries
          </Subtitle1>
          <div
            className="flex-boy-row"
            style={{ flexWrap: "wrap", justifyContent: "normal" }}
          >
            {Object.keys(regions)
              .sort()
              .map(function (key, index) {
                return (
                  <BrowseListFilter
                    className={
                      filters.countries === key ? "active-fiter-item" : null
                    }
                    onClick={() =>
                      selectComodity({
                        name: key.replace("_", " "),
                        type: "region",
                      })
                    }
                    key={index}
                  >
                    <img
                      style={{ height: "100%" }}
                      src={regions[key]}
                      alt="region"
                    />
                    <Subtitle2
                      style={{
                        margin: "5px",
                        textTransform: "capitalize",
                        cursor: "pointer",
                      }}
                    >
                      {key.replace("_", " ")}
                    </Subtitle2>
                  </BrowseListFilter>
                );
              })}
            {showextraRegions
              ? extraRegions.map((item, index) => {
                  let flagSource;
                  try {
                    flagSource = require(`../../assets/ProspectorDesignAssets/Flags/${item
                      .toLowerCase()
                      .replace(" ", "-")}.png`);
                  } catch {
                    flagSource = require(`../../assets/ProspectorDesignAssets/Flags/usa.png`);
                  }
                  return (
                    <BrowseListFilter
                      onClick={() =>
                        selectComodity({
                          name: item.replace(" ", "-"),
                          type: "country",
                        })
                      }
                      key={index}
                    >
                      <img
                        style={{ height: "100%" }}
                        src={flagSource}
                        alt="region"
                      />

                      <Subtitle2
                        style={{ margin: "5px", textTransform: "capitalize" }}
                      >
                        {item}
                      </Subtitle2>
                    </BrowseListFilter>
                  );
                })
              : null}
          </div>
          <Fragment></Fragment>
          {/* <Select
                        isMulti
                        defaultValue={filters?.countries?.map(tag => (
                            { value: tag, label: tag }
                        ))}
                        style={{ width: "100%" }}
                        onChange={(e) => selectComodity({ name: e.map(item => item.value), type: "country" })}
                        placeholder={"Search for region or country"}
                        options={extraRegions.filter(item => !filterMinerals.includes(item.toLowerCase())).map((tag, index) => ({ value: tag, label: tag }))}
                    /> */}
          <Subtitle1
            onClick={() => showRegions(!showextraRegions)}
            color={Primary}
            style={{ margin: "10px", cursor: "pointer" }}
          >
            MORE
          </Subtitle1>
        </div>
        <div className="rangeFilterContainer" style={{ textAlign: "left" }}>
          <Subtitle1 style={{ margin: "10px" }}>Minerals</Subtitle1>
          <div
            className="flex-boy-row"
            style={{ flexWrap: "wrap", justifyContent: "normal" }}
          >
            {filterMinerals.sort().map(function (min, index) {
              return (
                <BrowseListFilter
                  onClick={() => selectComodity({ name: min, type: "mineral" })}
                  className={
                    filters.mineral === min ? "active-fiter-item" : null
                  }
                  key={index}
                >
                  <Hexagon width={28}>
                    {elements.find(
                      (item) =>
                        item.name.toLowerCase() === min.trim().toLowerCase()
                    )
                      ? elements
                          .find(
                            (item) =>
                              item.name.toLowerCase() ===
                              min.trim().toLowerCase()
                          )
                          .chemical_symbol.substring(0, 4)
                      : min.substring(0, 4)}
                  </Hexagon>
                  <Subtitle2
                    style={{ margin: "5px", textTransform: "capitalize" }}
                  >
                    {min.replace("_", " ")}
                  </Subtitle2>
                </BrowseListFilter>
              );
            })}

            {showextraMinerals
              ? extraMinerals
                  .filter(
                    (item) => !filterMinerals.includes(item.toLowerCase())
                  )
                  .map(function (min, index) {
                    return (
                      <BrowseListFilter
                        onClick={() =>
                          selectComodity({ name: min, type: "mineral" })
                        }
                        className={
                          filters.mineral === min ? "active-fiter-item" : null
                        }
                        key={index}
                      >
                        <Hexagon width={28}>
                          {elements.find(
                            (item) =>
                              item.name.toLowerCase() ===
                              min.trim().toLowerCase()
                          )
                            ? elements
                                .find(
                                  (item) =>
                                    item.name.toLowerCase() ===
                                    min.trim().toLowerCase()
                                )
                                .chemical_symbol.substring(0, 4)
                            : min.substring(0, 4)}
                        </Hexagon>
                        <Subtitle2
                          style={{ margin: "5px", textTransform: "capitalize" }}
                        >
                          {min.replace("_", " ")}
                        </Subtitle2>
                      </BrowseListFilter>
                    );
                  })
              : null}
          </div>
          {/* <Select
                        isMulti
                        defaultValue={filters?.mineral?.map(tag => (
                            { value: tag, label: tag }
                        ))}
                        style={{ width: "100%" }}
                        onChange={(e) => selectComodity({ name: e.map(item => item.value), type: "mineral" })}
                        placeholder={"Search for mineral"}
                        options={extraMinerals.filter(item => !filterMinerals.includes(item.toLowerCase())).map((tag, index) => ({ value: tag, label: tag }))}
                    /> */}

          <Subtitle1
            onClick={() => showMinerals(!showextraMinerals)}
            color={Primary}
            style={{ margin: "10px", cursor: "pointer" }}
          >
            MORE
          </Subtitle1>
        </div>
      </div>

      {/* <div className="flex-boy-row" style={{ height: '75px', width: '100%', padding: '20px', boxSizing: 'border-box', borderTop: '1px solid grey' }}>
                <Subtitle2 onClick={clearFilters} color={Primary} style={{ cursor: 'pointer' }}>Clear filters</Subtitle2>
                <Button>{tab === 'projects' ? projects_length : companies_length} {tab}</Button>
            </div> */}
    </NotifyDiv>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  project_filters: state.authReducer.projects_search_filters,
  companies_search: state.authReducer.companies_search || [],
  projects: state.authReducer.projects_search || [],
  company_filters: state.authReducer.company_search_filters,
  projects_length: state.authReducer.filtered_projects_length,
  companies_length: state.authReducer.filtered_companies_length,
});

const mapDispatchToProps = (dispatch) => ({
  searchAlgoliaCompanies: (data) => dispatch(searchAlgoliaCompanies(data)),
  filterProjects: (data) => dispatch(filterProjects(data)),
  filterCompanies: (data) => dispatch(filterCompanies(data)),
  setCompanyPage: (data) =>
    dispatch({ type: "SET_COMPANIES_PAGE", payload: data }),
  setProjectPage: (data) =>
    dispatch({ type: "SET_PROJECTS_PAGE", payload: data }),
  setProjectFilters: (data) =>
    dispatch({ type: "SET_PROJECT_FILTERS", payload: data }),
  setCompanyFilters: (data) =>
    dispatch({ type: "SET_COMPANY_FILTERS", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterContent);
