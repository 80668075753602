import React from "react";
import { connect } from "react-redux";
import { MainPage } from "../components";

export const Retool = (props) => {
  return (
    <MainPage>
      <iframe
        src="https://prospectorsnowdev.retool.com/embedded/public/f426c05c-f133-4610-8fb5-924285981a0d"
        width="100%"
        height="100%"
      ></iframe>
    </MainPage>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Retool);
