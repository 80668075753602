/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import {
  Headline6,
  MainPage,
  Primary,
  Headline5,
  Body1,
  Body2,
  GreyBox,
  Button,
  Subtitle1,
  Subtitle2,
  BrowseListFilter,
  Hexagon,
} from "../../../components";
import moment from "moment";
// import { elements } from '../../configure/constants'
import { useMediaQuery } from "react-responsive";
import Linkify from "react-linkify";
import _ from "lodash";

import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { getProjectDetail } from "../../../store/Actions/AuthActions";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const estimate_mapping = {
  Grassroots:
    "Grassroots - No target drilling activity has occurred on the property.",

  "Target Drilling":
    "Target Drilling - There have been drilling campaigns, but there has not been a mineral resource estimate.",

  "Discovery Delineation":
    "Discovery Delineation - There has been a resource estimate published, but there has not been a preliminary economic assessment (PEA) completed.",

  PEA: "PEA - A preliminary economic assessment has been completed.",

  Prefeasibility: "Prefeasibility - A prefeasibility has been completed.",

  "Permitting & Feasibility":
    "Permitting & Feasibility - A feasibility study has been completed and / or permitting is in process for construction of mine.",

  Construction: "Construction - Mine is under construction.",

  Production: "Production - Mine is in production and actively producing.",

  Suspended:
    "Suspended - Mine has been put on Care and Maintenance or has gone through reclamation.",
};
export const ProjectCard = ({
  activeUser,
  project,
  history,
  selectProject,
  hover_project,
  selectComodity,
  getProjectDetail,
  selectBulk_projects,
  bulk_select_projects,
  iscompanytype,
}) => {
  const projectRef = useRef();
  const elements = useSelector((state) => state.authReducer.minerals_list);
  const [trend, setTrend] = useState({
    ratio: "1.4%",
    trend: "up",
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 1224px)",
  });

  useEffect(() => {
    if (hover_project === project.id) {
      projectRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    // calculateTrend()
    return () => {};
  }, [hover_project]);
  const calculateTrend = () => {
    let stock = [...project.stockHistory];
    let sortStock = _.sortBy(stock, function (dateObj) {
      return new Date(dateObj.date);
    });
    let last = sortStock.length ? sortStock[sortStock.length - 1].open : 0;
    let day_before = sortStock.length
      ? sortStock[sortStock.length - 2].open
      : 0;
    let diff = last - day_before;
    let trendState = last - day_before >= 0 ? "up" : "down";
    let ratio = diff !== 0 ? Math.abs(diff / day_before) * 100 : 0;

    setTrend({
      ratio: trendState === "up" ? ratio.toFixed(2) : `-${ratio.toFixed(2)}`,
      trend: trendState,
    });
  };
  const goToDetail = (id) => {
    // selectProject(project)
    // history.push({
    //     pathname: `/project-detail/${project.id}`,
    //     project: project,
    // }); dispatch(fetchReportDetail(id))
    getProjectDetail({ id, history });
  };
  const states = ["Inferred", "Indicated", "Measured", "Probable", "Proven"];

  const select_buulk_project = (checked, id) => {
    let projects = [...bulk_select_projects];
    let idx = projects.indexOf(id);
    if (idx && idx === -1) {
      projects.push(id);
    } else {
      projects.splice(idx, 1);
    }
    selectBulk_projects([...projects]);
  };

  return (
    <div
      ref={projectRef}
      style={{
        backgroundColor: project.id === hover_project ? "#f0f0f0" : "white",
      }}
      className="project-div"
    >
      <div className="flex flex-row w-full">
        <div className="flex items-center h-5 mr-3">
          {!iscompanytype && (
            <input
              id="offers"
              checked={bulk_select_projects.includes(project.id)}
              aria-describedby="offers-description"
              name="offers"
              type="checkbox"
              onChange={(e) =>
                select_buulk_project(e.target.checked, project.id)
              }
              className="focus:ring-transparent h-5 w-5 text-emerald-600 border-gray-300 rounded"
            />
          )}
        </div>
        <div className="flex-1">
          <div className="flex-boy-row align-middle">
            <div className="flex flex-row max-w-[75%]">
              <Link
                to={{
                  pathname: `/project-detail/${project.id}`,
                  project: project,
                }}
              >
                <Headline6 onClick={() => goToDetail(project.id)}>
                  {project.name}
                </Headline6>
              </Link>
            </div>
            <div className="flex-boy-row align-middle">
              <h6 className="mr-4 text-lg font-bold">
                {project.share_percent ? `${project.share_percent}%` : null}{" "}
                {project.ownership_type ? `- ${project.ownership_type}%` : null}
              </h6>
              <div className="flex flex-row align-middle justify-end w-[200px] ">
                <Tooltip title={estimate_mapping[project?.stage]}>
                  <p className="text-sm text-right m-1">{project?.stage}</p>
                </Tooltip>
                {[project?.country].map((flag, index) => {
                  let flagSource;
                  try {
                    flagSource = require(`../../../assets/ProspectorDesignAssets/Flags/${flag
                      .toLowerCase()
                      .replace(" ", "-")}.png`);
                  } catch {
                    flagSource = require(`../../../assets/ProspectorDesignAssets/Flags/usa.png`);
                  }
                  return (
                    <Tooltip title={flag}>
                      <img
                        key="flag"
                        onClick={() => {
                          let com = {
                            name: flag,
                            type: "country",
                          };
                          console.log(com);
                          selectComodity(com);
                        }}
                        className="project-flag"
                        style={{ height: "32px" }}
                        src={flagSource}
                        alt="region"
                      />
                    </Tooltip>
                  );
                })}
              </div>
            </div>
          </div>
          {/* <Subtitle2>{moment(project.createdAt).format('DD MMM YYYY')}</Subtitle2> */}

          <div
            className="flex-boy-row"
            style={{
              marginTop: "5px",
              height: "fit-content",
              justifyContent: "normal",
            }}
          >
            {/* <div
            onClick={() => goToDetail(project.id)}
            // className="project-image flex-boy-row"
            className={`flex-boy-row ${
              project.reportImageURL?.includes("Production")
                ? "placeholder-image"
                : "project-image"
            }`}
            style={{
              backgroundImage:
                "url('../../assets/ProspectorDesignAssets/Climate Smart Mining.png')",
            }}
          >
            <img
              style={{ width: "90px" }}
              src={
                project.reportImageURL ||
                require("../../assets/ProspectorDesignAssets/empty_image.png")
              }
              alt="project"
            />
          </div> */}
            <div
              className="flex-boy-column"
              style={{ height: "fit-content", flex: 1, alignItems: "normal" }}
            >
              <div
                className="flex-boy-row"
                style={{ marginTop: "10px", width: "100%" }}
              >
                {project.investors
                  ? project.investors.map((investor) => {
                      return (
                        <Subtitle1
                          color={
                            investor.market_direction
                              ? investor.market_direction === "down"
                                ? "#B00020"
                                : "#00AC69"
                              : trend.trend !== "up"
                              ? "#B00020"
                              : "#00AC69"
                          }
                          style={{ textTransform: "capitalize" }}
                        >
                          {investor.ticker
                            ? ` ${investor.ticker}.${investor.exchange}`
                            : investor.name}
                        </Subtitle1>
                      );
                    })
                  : null}
              </div>

              <div
                className={`flex flex-row justify-between w-full  flex-wrap`}
              >
                <div className="flex flex-row">
                  {states.map((status, index) => {
                    return (
                      <GreyBox
                        className="text-sm"
                        style={{
                          margin: "2px",
                          width: "42px",

                          backgroundColor:
                            project.mineral_estimate &&
                            project.mineral_estimate
                              ?.toString()
                              .includes(status)
                              ? "#1A9BDB"
                              : "gainsboro",
                          color:
                            project.mineral_estimate &&
                            project.mineral_estimate
                              ?.toString()
                              .includes(status)
                              ? "white"
                              : "#454555",
                          textTransform: "uppercase",
                          textAlign: "center",
                        }}
                        key={index}
                      >
                        {status === "Probable" || status === "Proven"
                          ? status.substring(0, 4)
                          : status.substring(0, 3)}
                      </GreyBox>
                    );
                  })}
                </div>
                <div className="flex-boy-row" style={{ float: "right" }}>
                  {[...new Set(project.minerals)]
                    .filter(
                      (item) =>
                        item.name !== "Not Available" &&
                        item.name !== "Combined"
                    )
                    .map((min, index) => {
                      return (
                        <Tooltip title={min}>
                          <Hexagon
                            key={index}
                            width={28}
                            style={{ fontSize: "0.6rem" }}
                          >
                            {elements.find(
                              (item) =>
                                item.name.toLowerCase() ===
                                min.trim().toLowerCase()
                            )
                              ? elements
                                  .find(
                                    (item) =>
                                      item.name.toLowerCase() ===
                                      min.trim().toLowerCase()
                                  )
                                  .chemical_symbol.substring(0, 4)
                              : min.substring(0, 4)}
                          </Hexagon>
                        </Tooltip>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  hover_project: state.authReducer.hover_project,
  activeUser: state.authReducer.activeUser,
  bulk_select_projects: state.authReducer.bulk_select_projects,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (data) => dispatch({ type: "SET_PROJECT", payload: data }),
  selectBulk_projects: (data) =>
    dispatch({ type: "SET_SELECTED_BULK_PROJECTS", payload: data }),
  getProjectDetail: (data) => dispatch(getProjectDetail(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectCard));
