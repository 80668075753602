/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import {
  Headline6,
  MainPage,
  Primary,
  Headline5,
  Body1,
  Body2,
  GreyBox,
  Button,
  Subtitle1,
  Subtitle2,
  BrowseListFilter,
  Hexagon,
} from "../components";
import moment from "moment";
import SummaryAccordion from "../components/SummaryAccordion";
import {
  Grid,
  Tabs,
  Tab,
  Box,
  Dialog,
  Badge,
  Divider,
} from "@material-ui/core";
import TuneRoundedIcon from "@material-ui/icons/TuneRounded";
import SparkLine from "../components/SparkLine";
import {
  KeyboardBackspace,
  CallMade,
  CallReceived,
  SortOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import PeformanceChartJs from "../components/PeformanceChart.js";
import GoogleMapReact from "google-map-react";
import MapMarker from "../components/MapMarker";
import { useFirestoreConnect } from "react-redux-firebase";

import ProjectCard from "../components/ProjectCard";
import CompanyCard from "../components/CompanyCard";
import Filter from "../components/Filter";
import Sort from "../components/Sort";

import { withRouter } from "react-router";
import Map from "../components/IframeMap";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import ContentLoader from "react-content-loader";
import _ from "lodash";
import animationData from "../assets/ProspectorDesignAssets/lottie2.json";
import {
  getCommodityData,
  filterProjects,
  setProjectFilters,
  setCompanyFilters,
  filterCompanies,
  filterCompaniesStaging,
  filterProjectsStaging,
} from "../store/Actions/AuthActions";
import ViewAllFilter from "../components/ViewAllFilter";
import InfiniteScroll from "react-infinite-scroll-component";
const dummy_data = {
  list: [
    {
      name: "Africa",
      type: "region",
    },
    {
      name: "copper",
      type: "mineral",
      symbol: "cu",
    },
    {
      name: "gold",
      type: "mineral",
      symbol: "Au",
    },

    {
      name: "canada",
      type: "country",
    },
    {
      name: "mexico",
      type: "country",
    },
    {
      name: "lithium",
      type: "mineral",
      symbol: "li",
    },
    {
      name: "Latin America",
      type: "region",
    },
  ],
  projects: [
    {
      name: "Läntinen Koillismaa Project",
      date: moment(),
      country: "finland",
      description:
        "Document Control Information Technical Report for the Kaukua Deposit, Läntinen Koillismaa Project, Finland Kaukua Deposit",
      ticker: "PMD:CVE -1.49%",
      minerals: ["Pd", "Pt", "Ni", "Au", "Cu"],
      status: 1,
      image: require("../assets/ProspectorDesignAssets/Climate Smart Mining.png"),
    },
    {
      name: "Selena Property",
      date: moment(),
      country: "united-states",
      description:
        "The Project comprises 471 contiguous Bureau of Land Management (BLM) lode-type claims and 3,700 acres of Federally",
      ticker: "target drilling",
      minerals: ["Pd", "Pt", "Ni", "Au", "Cu"],
      status: 0,
      image: require("../assets/ProspectorDesignAssets/Commodity Overviews - smaller.png"),
    },
  ],
  companies: [
    {
      ticker: "PMD:CVE",
      name: "Palladium One Mining Inc.",
      match_status: "matched",
      projects: 1,
      minerals: ["Au", "Pd", "Pt", "Ni", "Cu"],
    },
    {
      ticker: "RDG:CVE",
      name: "Ridgeline Minerals Corp.",
      projects: 1,
      match_status: "pending",
      minerals: ["Au"],
    },
  ],
};
const periods = ["1D", "1W", "1M", "3M", "6M", "1Y", "2Y", "3Y", "5Y", "ALL"];
const iFrameDetection = window === window.parent ? false : true;
const PrettoSlider = createMuiTheme({
  overrides: {
    MuiSlider: {
      root: {
        color: "#52af77",
        height: 8,
      },
      thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
          boxShadow: "inherit",
        },
      },
      active: {},
      valueLabel: {
        left: "calc(-50% + 4px)",
      },
      track: {
        height: 8,
        borderRadius: 4,
      },
      rail: {
        height: 8,
        borderRadius: 4,
      },
    },
  },
});

const states = ["inferred", "indicated", "Measured", "Probable", "Proven"];
const Content = ({
  projects_loading,
  companies_page,
  map_projects,
  setProjectStartDate,
  filterProjectsStaging,
  projects_page,
  projects_page_count,
  filterProjects,
  filterCompanies,
  filterCompaniesStaging,
  setCompanyPage,
  setProjectPage,
  setProjectFilters,
  setCompanyFilters,
  history,
  projects_length,
  companies_length,
  setBrowseTab,
  selectedList,
  setTab,
  setList,
  companies_search,
  tab,
  setCompany,
  setProject,
  getCommodityData,
  setCommodity,
  project_filters,
  company_filters,
}) => {
  const elements = useSelector((state) => state.authReducer.minerals_list);

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    let tab = newValue === 0 ? "projects" : "companies";
    setBrowseTab(tab);
    setTab(tab);
  };
  useEffect(() => {
    setValue(tab === "projects" ? 0 : 1);
    return () => {};
  }, [tab]);
  const companies =
    useSelector((state) => state.authReducer.companies_search) || [];
  const projects =
    useSelector((state) => state.authReducer.projects_search) || [];

  const [filterOpen, setFilterOpen] = useState(false);
  const [SortOpen, setSortOpen] = useState(false);

  const [listOpen, setListOpen] = useState(false);
  const [filterType, setType] = useState(null);
  const handleClose = () => {
    setFilterOpen(false);
  };
  const handleSortClose = () => {
    setSortOpen(false);
  };
  const handleListClose = () => {
    setListOpen(false);
  };
  const search_companies = ({ loc, newFilters }) => {
    setCompanyPage(0);
    filterCompanies();
    // filterCompaniesStaging()
  };
  const search_projects = ({ loc, newFilters }) => {
    setProjectPage(0);
    filterProjects();
  };
  const [period, setPeriod] = useState("3M");
  const [median, setMedian] = useState(70);

  const selectComodity = (item) => {
    setListOpen(false);
    if (item.type === "mineral") {
      // let data = {
      //     interval: '1d',
      //     identifier: item.name,
      //     range: '1y'
      // }
      // setCommodity(item.name)
      // getCommodityData(data)
      if (tab === "projects") {
        let newFilters = { ...project_filters };
        newFilters.mineral = [item.name];
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        let newFilters = { ...company_filters };
        newFilters.mineral = [item.name];
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
    } else if (item.type === "region" || item.type === "country") {
      if (tab === "projects") {
        let newFilters = { ...project_filters };
        newFilters.countries = [item.name];
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        let newFilters = { ...company_filters };
        newFilters.countries = [item.name];
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
    }

    setList(item);
  };
  let {
    averageGrade_min,
    averageGrade_max,
    oreTonnage_min,
    oreTonnage_max,
    containedMetal_min,
    containedMetal_max,
    ...rest
  } = company_filters;
  let o = project_filters
    ? Object.entries(project_filters).filter(([_, v]) => v !== 0 && v != null)
    : [1];
  let p = company_filters
    ? Object.entries(rest).filter(([_, v]) => v !== 0 && v != null)
    : [1];

  const switchPeriod = (period) => {
    setPeriod(period);
    let range = period.includes("1W")
      ? 7
      : period === "ALL"
      ? 30000
      : period === "1D"
      ? 1
      : period === "1M"
      ? 30
      : period === "3M"
      ? 90
      : period === "6M"
      ? 180
      : period === "2Y"
      ? 730
      : period === "3Y"
      ? 1095
      : period === "5Y"
      ? 1780
      : period === "1Y"
      ? 400
      : 90;
    setProjectStartDate(range);
    setProjectPage(1);
    filterProjects();
  };
  const next = () => {
    if (tab === "projects") {
      filterProjects();
    } else {
      filterCompaniesStaging();
    }
  };
  const previous = () => {
    if (tab === "projects") {
      setProjectPage(projects_page - 2);
      filterProjects();
    } else {
      setCompanyPage(companies_page - 2);
      filterCompaniesStaging();
    }
  };
  const getFlag = (name) => {
    let flagSource;
    try {
      flagSource = require(`../assets/ProspectorDesignAssets/Flags/${name
        .toLowerCase()
        .replace(" ", "-")}.png`);
    } catch {
      flagSource = require(`../assets/ProspectorDesignAssets/Flags/usa.png`);
    }
    return flagSource;
  };
  const fetchNext = () => {
    // let newFilters = { ...company_filters }
    // search_companies({ loc: 0, newFilters })
    filterCompanies();
  };

  return (
    <Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={filterOpen}
      >
        <Filter handleClose={handleClose} tab={tab} />
      </Dialog>
      <Dialog
        onClose={handleSortClose}
        aria-labelledby="simple-dialog-title"
        open={SortOpen}
      >
        <Sort handleClose={handleSortClose} tab={tab} />
      </Dialog>
      <Dialog
        onClose={handleListClose}
        aria-labelledby="simple-dialog-title"
        open={listOpen}
      >
        <ViewAllFilter
          selectComodity={selectComodity}
          handleClose={handleListClose}
          tab={tab}
        />
      </Dialog>
      <div className="browse-content-head">
        {selectedList ? (
          <Fragment>
            <div
              className="flex-boy-row"
              style={{
                width: "100%",
                padding: "5px",
                marginBottom: "5px",
                justifyContent: "normal",
              }}
            >
              <KeyboardBackspace
                onClick={() => setList(null)}
                style={{ color: Primary }}
              />
              {selectedList.type === "region" ? (
                <img
                  style={{ height: "40px" }}
                  src={require(`../assets/ProspectorDesignAssets/Regions/${selectedList.name.toLowerCase()}.svg`)}
                  alt="region"
                />
              ) : selectedList.type === "country" ? (
                <img
                  style={{ height: "40px" }}
                  src={getFlag(selectedList.name)}
                  alt="region"
                />
              ) : (
                <Hexagon width={30}>{selectedList.symbol}</Hexagon>
              )}
              <Headline5
                color={Primary}
                style={{ margin: "5px", textTransform: "capitalize" }}
              >
                {selectedList.name}
              </Headline5>
            </div>

            <div
              className="flex-boy-row"
              style={{ marginLeft: "40px", marginBottom: "20px" }}
            >
              {selectedList.type === "region" ? (
                <Body1>
                  All {tab} located in {selectedList.name}
                </Body1>
              ) : selectedList.type === "country" ? (
                <Body1>
                  All {tab} located in {selectedList.name}
                </Body1>
              ) : (
                <Body1>
                  All {tab} associated with {selectedList.name}
                </Body1>
              )}
            </div>
            {selectedList.type === "mineral" ? (
              <div style={{ marginLeft: "40px", width: "350px" }}>
                <PeformanceChartJs color={Primary} />
              </div>
            ) : null}
          </Fragment>
        ) : (
          <Fragment>
            <div
              className="flex-boy-row"
              style={{
                width: "100%",
                padding: "5px",
                marginBottom: "10px",
                boxSizing: "border-box",
              }}
            >
              <Headline5>Popular Lists</Headline5>
              <Subtitle2
                style={{ cursor: "pointer" }}
                onClick={() => setFilterOpen(true)}
                color={Primary}
              >
                View all
              </Subtitle2>
            </div>
            <div
              className="flex-boy-row"
              style={{
                flexWrap: "wrap",
                justifyContent: "normal",
                marginBottom: "50px",
              }}
            >
              {dummy_data.list.map((item, index) => {
                return (
                  <BrowseListFilter
                    onClick={() => selectComodity(item)}
                    key={index}
                  >
                    {item.type === "region" ? (
                      <img
                        style={{ height: "100%" }}
                        src={require(`../assets/ProspectorDesignAssets/Regions/${item.name.toLowerCase()}.svg`)}
                        alt="region"
                      />
                    ) : item.type === "country" ? (
                      <img
                        style={{ height: "100%" }}
                        src={require(`../assets/ProspectorDesignAssets/Flags/${item.name.toLowerCase()}.png`)}
                        alt="region"
                      />
                    ) : (
                      <Hexagon width={28}>{item.symbol}</Hexagon>
                    )}
                    <Subtitle2
                      style={{ margin: "5px", textTransform: "capitalize" }}
                    >
                      {item.name}
                    </Subtitle2>
                  </BrowseListFilter>
                );
              })}
            </div>
          </Fragment>
        )}
        <div></div>
      </div>
      <div className="sticky">
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label={<Headline6 color="#00AC69">Projects</Headline6>} />
          <Tab label={<Headline6 color="#00AC69">Companies</Headline6>} />
        </Tabs>

        {/* <div className="flex-boy-row" style={{ padding: "10px", width: "70%" }}>

                    {value === 0 ? periods.map((time, index) => {
                        return (
                            <div key={index} onClick={() => switchPeriod(time)} className="period flex-boy-column" style={{ color: time === period ? 'white' : Primary, backgroundColor: time === period ? Primary : 'white' }}>
                                <Headline6 color={time === period ? 'white' : Primary}>{time}</Headline6>
                            </div>
                        )

                    }) : null}
                </div> */}
        <div
          className="flex-boy-row"
          style={{
            marginTop: "20px",
            padding: "10px",
            marginBottom: "50px",
            boxSizing: "border-box",
            position: "sticky",
            top: 0,
          }}
        >
          <Subtitle2 style={{ fontSize: "20px" }}>
            {value === 0
              ? `${map_projects.length} projects`
              : `${companies_length || companies.length} companies`}
          </Subtitle2>
          <div className="flex-boy-row">
            {value === 0 ? (
              <div className="flex-boy-row" onClick={() => previous()}>
                <KeyboardArrowLeft />
                {/* <Subtitle1 style={{ marginTop: "4px", marginLeft: "5px" }}>Previous</Subtitle1> */}
              </div>
            ) : null}
            {value === 0 ? (
              <Subtitle1
                style={{ textAlign: "left", padding: "10px", marginTop: "4px" }}
              >
                {Math.ceil(map_projects.length / 50) > 0 &&
                Math.ceil(map_projects.length / 50) >= projects_page - 1
                  ? `Page ${
                      projects_page > 1 ? projects_page - 1 : 1
                    } of  ${Math.ceil(map_projects.length / 50)}`
                  : null}
              </Subtitle1>
            ) : null}
            {projects_page_count && value === 0 ? (
              <div className="flex-boy-row" onClick={() => next()}>
                {/* <Subtitle1 style={{ marginTop: "4px", marginLeft: "5px" }}>Next</Subtitle1> */}
                <KeyboardArrowRight />
              </div>
            ) : null}
          </div>

          {value === 1 ? (
            <div className="flex-boy-row">
              <div className="circle-div">
                <Badge
                  style={{ color: "white" }}
                  color="primary"
                  badgeContent={value === 0 ? o.length : p.length}
                  invisible={!(value === 0 ? o.length - 1 : p.length)}
                >
                  <TuneRoundedIcon
                    style={{ color: "grey" }}
                    onClick={() => setFilterOpen(true)}
                  />
                </Badge>
              </div>
              <div className="circle-div">
                <SortOutlined
                  style={{ color: "grey" }}
                  onClick={() => setSortOpen(true)}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="list-section">
        {value === 0 ? (
          projects_loading ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <ContentLoader viewBox="0 0 600 100" height={100} width={600}>
                <rect x="20" y="8" rx="0" ry="0" width="100" height="100" />
                <rect x="20" y="120" rx="0" ry="0" width="100" height="10" />
                <rect x="130" y="8" rx="0" ry="0" width="500" height="15" />
                <rect x="130" y="30" rx="0" ry="0" width="500" height="15" />
                <rect x="130" y="52" rx="0" ry="0" width="300" height="15" />
              </ContentLoader>
              <ContentLoader viewBox="0 0 600 100" height={100} width={600}>
                <rect x="20" y="8" rx="0" ry="0" width="100" height="100" />
                <rect x="20" y="120" rx="0" ry="0" width="100" height="10" />
                <rect x="130" y="8" rx="0" ry="0" width="500" height="15" />
                <rect x="130" y="30" rx="0" ry="0" width="500" height="15" />
                <rect x="130" y="52" rx="0" ry="0" width="300" height="15" />
              </ContentLoader>
              <ContentLoader viewBox="0 0 600 100" height={100} width={600}>
                <rect x="20" y="8" rx="0" ry="0" width="100" height="100" />
                <rect x="20" y="120" rx="0" ry="0" width="100" height="10" />
                <rect x="130" y="8" rx="0" ry="0" width="500" height="15" />
                <rect x="130" y="30" rx="0" ry="0" width="500" height="15" />
                <rect x="130" y="52" rx="0" ry="0" width="300" height="15" />
              </ContentLoader>
            </div>
          ) : projects.length ? (
            projects.map((project, index) => {
              return (
                <ProjectCard
                  selectComodity={selectComodity}
                  setProject={setProject}
                  history={history}
                  key={index}
                  project={project}
                />
              );
            })
          ) : (
            <div>
              <img
                src={require("../assets/ProspectorDesignAssets/empty_image.png")}
                alt="empty"
                style={{ height: "100px", marginTop: "50px" }}
              />
              <Headline6 color="gainsboro">
                No available projects for your filters{" "}
              </Headline6>
            </div>
          )
        ) : companies.length ? (
          <InfiniteScroll
            dataLength={companies.length} //This is important field to render the next data
            next={() => fetchNext()}
            hasMore={true}
            loader={
              <Player
                autoplay
                loop
                src={animationData}
                style={{ height: "150px", width: "150px" }}
              >
                {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
              </Player>
            }
            scrollableTarget="scrollableDiv"
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            // below props only if you need pull down functionality
          >
            {companies
              .filter((item) => item)
              .map((match, index) => {
                return (
                  <CompanyCard
                    setCompany={setCompany}
                    history={history}
                    key={index}
                    match={match}
                  />
                );
              })}
          </InfiniteScroll>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ContentLoader viewBox="0 0 600 100" height={100} width={600}>
              <rect x="20" y="8" rx="0" ry="0" width="100" height="100" />
              <rect x="20" y="120" rx="0" ry="0" width="100" height="10" />
              <rect x="130" y="8" rx="0" ry="0" width="500" height="15" />
              <rect x="130" y="30" rx="0" ry="0" width="500" height="15" />
              <rect x="130" y="52" rx="0" ry="0" width="300" height="15" />
            </ContentLoader>
            <ContentLoader viewBox="0 0 600 100" height={100} width={600}>
              <rect x="20" y="8" rx="0" ry="0" width="100" height="100" />
              <rect x="20" y="120" rx="0" ry="0" width="100" height="10" />
              <rect x="130" y="8" rx="0" ry="0" width="500" height="15" />
              <rect x="130" y="30" rx="0" ry="0" width="500" height="15" />
              <rect x="130" y="52" rx="0" ry="0" width="300" height="15" />
            </ContentLoader>
            <ContentLoader viewBox="0 0 600 100" height={100} width={600}>
              <rect x="20" y="8" rx="0" ry="0" width="100" height="100" />
              <rect x="20" y="120" rx="0" ry="0" width="100" height="10" />
              <rect x="130" y="8" rx="0" ry="0" width="500" height="15" />
              <rect x="130" y="30" rx="0" ry="0" width="500" height="15" />
              <rect x="130" y="52" rx="0" ry="0" width="300" height="15" />
            </ContentLoader>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export const Browse = ({
  companies_page,
  map_projects,
  projects_loading,
  filterProjectsStaging,
  setProjectStartDate,
  projects_page_count,
  setCompanyPage,
  setProjectPage,
  setProjectFilters,
  projects_length,
  companies_length,
  setBrowseTab,
  projects,
  history,
  setCompany,
  browse_page_tab,
  setProject,
  filterProjects,
  filterCompanies,
  projects_page,
  setCommodity,
  getCommodityData,
  project_filters,
  company_filters,
  setCompanyFilters,
  filterCompaniesStaging,
}) => {
  const [tab, setTab] = useState("projects");
  const [selectedList, setselectedList] = useState(null);
  const elements = useSelector((state) => state.authReducer.minerals_list);

  const [center, setCenter] = useState({
    lat: 35.7465,
    lng: -39.4629,
  });
  const search_companies = ({ loc, newFilters }) => {
    setCompanyPage(0);
    // filterCompanies()
    filterCompaniesStaging();
  };
  const search_projects = ({ loc, newFilters }) => {
    setProjectPage(0);
    filterProjects();
  };
  const setList = (item) => {
    setselectedList(item);
    if (item) {
      if (item.type === "region" || item.type === "country") {
        setLocation(item.name);
      } else {
        setLocation(null);
      }
    } else {
      setLocation(null);
      let newFilters = {
        mineral: null,
        start_after: null,
        searchTerm: null,
        regions: null,
        countries: null,
        stage: null,
        resourceType: null,
        averageGrade_min: 0,
        averageGrade_max: 0,
        oreTonnage_min: 0,
        oreTonnage_max: 0,
        containedMetal_min: 0,
        containedMetal_max: 0,
      };
      if (tab === "projects") {
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
    }
  };
  useEffect(() => {
    setTab(browse_page_tab);
    return () => {};
  }, []);
  useEffect(() => {
    if (history.location.search && history.location.search.length) {
      dynamicLinkSearch();
    }
    return () => {};
  }, [history]);
  const dynamicLinkSearch = () => {
    let search = JSON.parse(
      '{"' +
        history.location.search.replace(/&/g, '","').replace(/=/g, '":"') +
        '"}',
      function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    );
    setBrowseTab(search["?tab"]);
    if (search.hasOwnProperty("filters[region]")) {
      let item = { name: search["filters[region]"], type: "region" };
      if (search["?tab"] === "projects") {
        let newFilters = { ...project_filters };
        newFilters.countries = item.name;

        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        let newFilters = { ...company_filters };
        newFilters.countries = item.name;
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
      setList({ name: search["filters[region]"], type: "region" });
    }
    if (search.hasOwnProperty("filters[mineral]")) {
      let item = {
        name: search["filters[mineral]"],
        type: "mineral",
        symbol: elements.find(
          (item) => item.name.toLowerCase() === search["filters[mineral]"]
        )
          ? elements
              .find(
                (item) => item.name.toLowerCase() === search["filters[mineral]"]
              )
              .chemical_symbol.substring(0, 4)
          : search["filters[mineral]"].substring(0, 3),
      };
      let data = {
        interval: "1d",
        identifier: item.name,
        range: "1y",
      };
      setCommodity(item.name);
      getCommodityData(data);
      if (search["?tab"] === "projects") {
        let newFilters = { ...project_filters };
        newFilters.mineral = item.name;
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        let newFilters = { ...company_filters };
        newFilters.mineral = item.name;
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
      setList({
        name: search["filters[mineral]"],
        type: "mineral",
        symbol: elements.find(
          (item) => item.name.toLowerCase() === search["filters[mineral]"]
        )
          ? elements
              .find(
                (item) => item.name.toLowerCase() === search["filters[mineral]"]
              )
              .chemical_symbol.substring(0, 4)
          : search["filters[mineral]"].substring(0, 3),
      });
    }
    if (search.hasOwnProperty("filters[country]")) {
      let item = { name: search["filters[country]"], type: "country" };
      if (search["?tab"] === "projects") {
        let newFilters = { ...project_filters };
        newFilters.countries = item.name;

        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        let newFilters = { ...company_filters };
        newFilters.countries = item.name;
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
      setList({ name: search["filters[country]"], type: "country" });
    }
  };
  const [markers, setMarkers] = useState([
    {
      name: "USA",
      lat: 38.9072,
      lng: -77.0369,
    },
    {
      name: "Finland",
      lat: 61.9241,
      lng: 25.7482,
    },
  ]);
  // let o = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

  const [loc, setLocation] = useState(null);
  const [zoom, setZoom] = useState(1);
  const pageRef = useRef();
  const [period, setPeriod] = useState("3M");
  const [filterOpen, setFilterOpen] = useState(false);
  const [SortOpen, setSortOpen] = useState(false);
  const [filterType, setType] = useState(null);
  const [mapfullscreen, setFull] = useState(true);
  const handleClose = () => {
    setFilterOpen(false);
  };
  const handleSortClose = () => {
    setSortOpen(false);
  };
  const switchPeriod = (period) => {
    setPeriod(period);
    let range = period.includes("1W")
      ? 7
      : period === "ALL"
      ? 30000
      : period === "1D"
      ? 1
      : period === "1M"
      ? 30
      : period === "3M"
      ? 90
      : period === "6M"
      ? 180
      : period === "2Y"
      ? 730
      : period === "3Y"
      ? 1095
      : period === "5Y"
      ? 1780
      : period === "1Y"
      ? 400
      : 90;
    setProjectStartDate(range);
    setProjectPage(1);
    // if (project_filters && !Object.values(_.omit(project_filters, '__jsogObjectId')).some(x => x !== null && x !== '')) {
    //     filterProjects()
    // }
    // filterProjectsStaging()
    // let data = {
    //     interval: range.toLowerCase() === '1d' ? '15m' : '1d',
    //     identifier: "projects",
    //     range: range.toLowerCase()
    // }
    filterProjects();
  };
  const [scrollTop, setscrollTop] = useState(false);
  const checkScrollPosition = () => {
    if (pageRef.current.scrollTop > 1000) {
      setscrollTop(true);
    } else {
      setscrollTop(false);
    }
  };
  return (
    <MainPage
      id="scrollableDiv"
      onScroll={checkScrollPosition}
      ref={pageRef}
      style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}
    >
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={filterOpen}
      >
        <Filter handleClose={handleClose} tab={"projects"} />
      </Dialog>
      <Dialog
        onClose={handleSortClose}
        aria-labelledby="simple-dialog-title"
        open={SortOpen}
      >
        <Sort handleClose={handleSortClose} tab={"projects"} />
      </Dialog>
      <Grid container>
        <Grid
          item
          xs={mapfullscreen ? 12 : 6}
          md={mapfullscreen ? 12 : 6}
          lg={mapfullscreen ? 12 : 6}
          style={{
            position: "relative",
            height: "100%",
            transition: "width 0.8s ease",
          }}
        >
          {!projects_loading ? (
            <div
              className="period-map-parent"
              style={{ left: "50%", top: "25px" }}
            >
              <div className="flex-boy-row">
                <Headline6
                  style={{
                    textAlign: "left",
                    width: "100%",
                    marginLeft: "5px",
                    padding: "10px",
                    textTransform: "uppercase",
                  }}
                >
                  Projects updated in the last
                </Headline6>
                <div className="flex-boy-row">
                  <div className="circle-div">
                    <TuneRoundedIcon
                      style={{ color: "grey" }}
                      onClick={() => setFilterOpen(true)}
                    />
                  </div>
                  {/* <div className="circle-div">
                                    <SortOutlined style={{ color: 'grey' }} onClick={() => setSortOpen(true)} />
                                </div> */}
                </div>
              </div>

              <Divider />
              <div className="flex-boy-row " style={{ padding: "10px" }}>
                {periods.map((time, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => switchPeriod(time)}
                      className="period-map flex-boy-column"
                      style={{
                        color: time === period ? "white" : Primary,
                        backgroundColor: time === period ? Primary : "white",
                      }}
                    >
                      <Headline6 color={time === period ? "white" : Primary}>
                        {time}
                      </Headline6>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}

          {!projects_loading ? (
            <Map mapfullscreen={mapfullscreen} location={loc} />
          ) : (
            <div
              className="flex-boy-column"
              style={{
                height: "100%",
                width: "100%",
                justifyContent: "center",
                overflow: "hidden",
                zIndex: 2000,
                backgroundColor: "white",
              }}
            >
              <Headline5>Loading Projects Map</Headline5>
              {/* <img style={{ height: '30vh' }} src={logoImg} alt="logo" /> */}
              {/* <lottie-player src={"https://lottiefiles.com/share/xvzv1hhl"} background="transparent" speed="1" style="width: 300px; height: 300px;" loop autoplay></lottie-player> */}
              <Player
                autoplay
                loop
                src={animationData}
                style={{ height: "100%", width: "1000px", overflow: "hidden" }}
              >
                {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
              </Player>
            </div>
          )}
        </Grid>
      </Grid>
    </MainPage>
  );
};

const mapStateToProps = (state) => ({
  companies_search: state.authReducer.companies_search || [],
  projects: state.authReducer.projects_search || [],
  project_filters: state.authReducer.projects_search_filters,
  company_filters: state.authReducer.company_search_filters,
  projects_page: state.authReducer.projects_page,
  companies_page: state.authReducer.companies_page,
  projects_page_count: state.authReducer.projects_page_count,
  projects_length: state.authReducer.filtered_projects_length,
  companies_length: state.authReducer.filtered_companies_length,
  browse_page_tab: state.authReducer.browse_page_tab,
  projects_loading: state.authReducer.projects_loading,
  map_projects: state.authReducer.map_projects || [],
});

const mapDispatchToProps = (dispatch) => ({
  setCompany: (data) => dispatch({ type: "SET_COMPANY", payload: data }),
  setProject: (data) => dispatch({ type: "SET_PROJECT", payload: data }),
  setCommodity: (data) => dispatch({ type: "SET_COMMODITY", payload: data }),
  getCommodityData: (data) => dispatch(getCommodityData(data)),
  setCompanyPage: (data) =>
    dispatch({ type: "SET_COMPANIES_PAGE", payload: data }),
  setProjectPage: (data) =>
    dispatch({ type: "SET_PROJECTS_PAGE", payload: data }),
  filterProjects: (data) => dispatch(filterProjects(data)),
  filterCompanies: (data) => dispatch(filterCompanies(data)),
  setBrowseTab: (data) => dispatch({ type: "SET_BROWSE_TAB", payload: data }),
  setProjectFilters: (data) =>
    dispatch({ type: "SET_PROJECT_FILTERS", payload: data }),
  setCompanyFilters: (data) =>
    dispatch({ type: "SET_COMPANY_FILTERS", payload: data }),
  filterCompaniesStaging: (data) => dispatch(filterCompaniesStaging()),
  filterProjectsStaging: () => dispatch(filterProjectsStaging()),
  setProjectStartDate: (data) =>
    dispatch({ type: "SET_PROJECT_START_DATE", payload: data }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Browse);
