/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  Body1,
  Subtitle1,
  Headline6,
  Headline4,
  Subtitle2,
  Hexagon,
  Button,
  NotifyDiv,
  Primary,
  RightMessageBubble,
  LeftMessageBubble,
} from ".";

import {
  Send,
  AttachFile,
  KeyboardBackspace,
  DateRange,
  Cancel,
} from "@material-ui/icons";
import { TextField, Dialog, Popover, Badge, Tooltip } from "@material-ui/core";

import moment from "moment";
import { getProjectDetail } from "../store/Actions/AuthActions";
import { withRouter } from "react-router";
import _ from "lodash";
export const CompanyMapMarker = ({
  type,
  project,
  companyData,
  history,
  getProjectDetail,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const elements = useSelector((state) => state.authReducer.minerals_list);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [trend, setTrend] = useState({
    ratio: "1.4%",
    trend: "up",
  });

  useEffect(() => {
    // calculateTrend()
    return () => {};
  }, []);
  const calculateTrend = () => {
    let stock = [...companyData.stockHistory];
    let sortStock = _.sortBy(stock, function (dateObj) {
      return new Date(dateObj.date);
    });
    let last = sortStock.length ? sortStock[sortStock.length - 1].open : 0;
    let day_before = sortStock.length
      ? sortStock[sortStock.length - 2].open
      : 0;
    let diff = last - day_before;
    let trendState = last - day_before >= 0 ? "up" : "down";
    let ratio = diff !== 0 ? Math.abs(diff / day_before) * 100 : 0;

    setTrend({
      ratio: trendState === "up" ? ratio.toFixed(2) : `-${ratio.toFixed(2)}`,
      trend: trendState,
    });
  };
  const handlepopClose = () => {
    setAnchorEl(null);
    handleClosePop();
  };
  const handleClosePop = () => {
    setAnchorEl(null);
  };
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const goToDetail = () => {
    getProjectDetail({ id: project.id, history });
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className="marker">
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="marker-popover">
          <div
            style={{ padding: "10px", cursor: "pointer" }}
            onClick={() => goToDetail()}
          >
            <div style={{ width: "100%", marginBottom: "10px" }}>
              <Subtitle1 color="grey">{project.name}</Subtitle1>
              {/* <Subtitle2 color="grey">{moment(project.date_timestamp).format('DD MMM YYYY')}</Subtitle2> */}
            </div>
            <Subtitle1 className="article-summary">
              {project.report_summary}
            </Subtitle1>
          </div>
          <div
            onClick={() => goToDetail()}
            className="article-project-data flex-boy-row"
            style={{ justifyContent: "normal", alignItems: "normal" }}
          >
            {/* <img style={{ height: '30px' }} src={require(`../assets/ProspectorDesignAssets/Flags/${project.country.toLowerCase().replace(' ', '-')}.png`)} alt="flag" /> */}
            <div>
              <div className="interest-row">
                {/* <Subtitle1 style={{ fontSize: '0.7em' }}>{project.reportName}</Subtitle1> */}
                {companyData.ticker && (
                  <Subtitle1
                    color={trend.trend !== "up" ? "#B00020" : "#00AC69"}
                    style={{ textTransform: "capitalize" }}
                  >
                    {companyData.ticker}.{companyData.exchange} {trend.ratio}
                  </Subtitle1>
                )}

                {project.investors
                  ? _.orderBy(
                      project.investors.filter((item) => item.ticker),
                      "share_percent"
                    )
                      .splice(0, 3)
                      .map((investor) => {
                        return (
                          <Subtitle1
                            color="#454555"
                            style={{
                              textTransform: "capitalize",
                              marginRight: "5px",
                            }}
                          >
                            {investor.ticker
                              ? ` ${investor.ticker}.${investor.exchange}`
                              : investor.name}
                            ,
                          </Subtitle1>
                        );
                      })
                  : null}
                <div
                  className="flex-boy-row"
                  style={{ justifyContent: "normal" }}
                >
                  {project.minerals
                    .filter(
                      (item) =>
                        item !== "Not Available" &&
                        item !== "Combined" &&
                        item !== "Metric Tonnes" &&
                        item !== ""
                    )
                    .map((min, index) => {
                      return (
                        <Hexagon
                          key={index}
                          width={28}
                          style={{ fontSize: "0.6rem" }}
                        >
                          {elements.find(
                            (item) =>
                              item.name.toLowerCase() === min.toLowerCase()
                          )
                            ? elements
                                .find(
                                  (item) =>
                                    item.name.toLowerCase() ===
                                    min.toLowerCase()
                                )
                                .chemical_symbol.substring(0, 4)
                            : min.substring(0, 4)}
                        </Hexagon>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popover>
      <div onClick={handleClick} className="insights-legend flex-boy-row">
        <img
          src={require(`../assets/ProspectorDesignAssets/${
            type === "Financial Results"
              ? "fi-sr-pulse.svg"
              : type === "Resource Estimate"
              ? "fi-sr-target.svg"
              : type === "Drilling Result"
              ? "drilling.svg"
              : "Prospector_3D Logo_Icon.svg"
          }`)}
          alt=""
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getProjectDetail: (data) => dispatch(getProjectDetail(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompanyMapMarker));
