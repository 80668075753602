/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  Headline6,
  MainPage,
  Primary,
  Headline5,
  Body1,
  Body2,
  GreyBox,
  Button,
  Subtitle1,
  Subtitle2,
  BrowseListFilter,
  Hexagon,
} from "../general";
import moment from "moment";
// import { elements } from '../../configure/constants'
import { useMediaQuery } from "react-responsive";

import _ from "lodash";
import {
  fetchReportDetail,
  getProjectDetail,
  getReportDetail,
} from "../../store/Actions/AuthActions";
import { Tooltip } from "@material-ui/core";
export const ProjectCard = ({
  activeUser,
  report,
  history,
  selectProject,
  hover_report,
  selectComodity,
  getProjectDetail,
  getReportDetail,
}) => {
  const reportRef = useRef();
  const dispatch = useDispatch();
  const elements = useSelector((state) => state.authReducer.minerals_list);
  const [trend, setTrend] = useState({
    ratio: "1.4%",
    trend: "up",
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 1224px)",
  });

  useEffect(() => {
    if (hover_report === report.id) {
      reportRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    // calculateTrend()
    return () => {};
  }, [hover_report]);
  const calculateTrend = () => {
    let stock = [...report.stockHistory];
    let sortStock = _.sortBy(stock, function (dateObj) {
      return new Date(dateObj.date);
    });
    let last = sortStock.length ? sortStock[sortStock.length - 1].open : 0;
    let day_before = sortStock.length
      ? sortStock[sortStock.length - 2].open
      : 0;
    let diff = last - day_before;
    let trendState = last - day_before >= 0 ? "up" : "down";
    let ratio = diff !== 0 ? Math.abs(diff / day_before) * 100 : 0;

    setTrend({
      ratio: trendState === "up" ? ratio.toFixed(2) : `-${ratio.toFixed(2)}`,
      trend: trendState,
    });
  };
  const goToDetail = (id) => {
    getReportDetail({ id: report.id, history });
  };
  const states = ["Inferred", "Indicated", "Measured", "Probable", "Proven"];
  return (
    <div
      ref={reportRef}
      style={{
        backgroundColor: report.id === hover_report ? "#f0f0f0" : "white",
      }}
      className="project-div"
    >
      {/* <Subtitle2>{moment(report.createdAt).format('DD MMM YYYY')}</Subtitle2> */}
      <div
        onClick={() => goToDetail(report)}
        className="flex-boy-row"
        style={{
          marginTop: "5px",
          height: "fit-content",
          justifyContent: "normal",
        }}
      >
        {/* <div onClick={() => goToDetail(report.id)} className="project-image flex-boy-row" style={{ backgroundImage: "url('../../assets/ProspectorDesignAssets/Climate Smart Mining.png')" }}>
                    <img style={{ width: '90px' }} src={report.cover_page || require('../../assets/ProspectorDesignAssets/empty_image.png')} alt="report" />
                </div> */}
        <div
          className="flex-boy-column"
          style={{ height: "fit-content", flex: 1, marginLeft: "5px" }}
        >
          <div className="flex-boy-row" style={{ width: "100%" }}>
            <Headline6>{report.project_name || report.name}</Headline6>
            {[report.country].map((flag, index) => {
              let flagSource;
              try {
                flagSource = require(`../../assets/ProspectorDesignAssets/Flags/${flag
                  .toLowerCase()
                  .replace(" ", "-")}.png`);
              } catch {
                flagSource = require(`../../assets/ProspectorDesignAssets/Flags/usa.png`);
              }
              return (
                <img
                  onClick={() => {
                    let com = {
                      name: flag,
                      type: "country",
                    };

                    selectComodity(com);
                  }}
                  key="flag"
                  className="report-flag"
                  style={{ height: "30px" }}
                  src={flagSource}
                  alt="region"
                />
              );
            })}
          </div>
          <div
            className="flex-boy-row"
            style={{ marginTop: "10px", width: "100%" }}
          >
            <div className="flex-boy-row">
              <Body1
                color={trend.trend !== "up" ? "#B00020" : "#00AC69"}
                style={{ textTransform: "capitalize" }}
              >
                {report.filing_company}
              </Body1>
            </div>

            <div className="flex-boy-row" style={{ float: "right" }}>
              {[...new Set(report.minerals)]
                .filter(
                  (item) =>
                    item.name !== "Not Available" && item.name !== "Combined"
                )
                .map((min, index) => {
                  return (
                    <Hexagon
                      onClick={() => {
                        let com = {
                          name: min.name,
                          type: "mineral",
                          symbol: elements.find(
                            (item) =>
                              item.name.toLowerCase() ===
                              min.trim().toLowerCase()
                          )
                            ? elements
                                .find(
                                  (item) =>
                                    item.name.toLowerCase() ===
                                    min.trim().toLowerCase()
                                )
                                .chemical_symbol.substring(0, 4)
                            : min.substring(0, 4),
                        };

                        selectComodity(com);
                      }}
                      key={index}
                      width={28}
                      style={{ fontSize: "0.6rem" }}
                    >
                      {elements.find(
                        (item) =>
                          item.name.toLowerCase() === min.trim().toLowerCase()
                      )
                        ? elements
                            .find(
                              (item) =>
                                item.name.toLowerCase() ===
                                min.trim().toLowerCase()
                            )
                            .chemical_symbol.substring(0, 4)
                        : min.substring(0, 4)}
                    </Hexagon>
                  );
                })}
            </div>
          </div>
          <div
            className="flex-boy-row"
            style={{ marginTop: "5px", flexWrap: "wrap", width: "100%" }}
          >
            <div className="flex-boy-row">
              {states.map((status, index) => {
                return (
                  <GreyBox
                    className="text-sm"
                    style={{
                      margin: "2px",
                      width: isTabletOrMobileDevice ? "42px" : "80px",

                      fontSize: "14px",
                      backgroundColor:
                        report.mineral_resource_estimate &&
                        report.mineral_resource_estimate
                          .toString()
                          .includes(status)
                          ? "#1A9BDB"
                          : "gainsboro",
                      color:
                        report.mineral_resource_estimate &&
                        report.mineral_resource_estimate
                          .toString()
                          .includes(status)
                          ? "white"
                          : "#454555",
                      textTransform: isTabletOrMobileDevice
                        ? "uppercase"
                        : "capitalize",
                      textAlign: "center",
                    }}
                    key={index}
                  >
                    {isTabletOrMobileDevice ? status.substring(0, 4) : status}
                  </GreyBox>
                );
              })}
            </div>
            <Body1>
              {moment(report.publication_date).format("DD MMM YYYY")}
            </Body1>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  hover_report: state.authReducer.hover_report,
  activeUser: state.authReducer.activeUser,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (data) => dispatch({ type: "SET_PROJECT", payload: data }),
  getProjectDetail: (data) => dispatch(getProjectDetail(data)),
  getReportDetail: (data) => dispatch(getReportDetail(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCard);
