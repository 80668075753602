// App.js
import React, { useEffect, useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import { ro } from "date-fns/locale";
import { filter } from "@syncfusion/ej2-filemanager/src/file-manager/common/operations";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";

export const Intercepts = ({ project }) => {
  const gridRef = useRef();
  const [interceptsData] = useState([
    {
      date: "2023-12-19",
      highlight:
        "1.56 gpt AuEq over 5.00 meters', '0.53 gpt AuEq over 20.00 meters', '1.76 gpt AuEq over 1.00 meter",
      length: "5.00",
      mineral: "AuEq",
      grade: "1.56",
      gradeUOM: "g/t",
      depth: "NA",
    },
    {
      date: "2023-12-19",
      highlight:
        "1.56 gpt AuEq over 5.00 meters', '0.53 gpt AuEq over 20.00 meters', '1.76 gpt AuEq over 1.00 meter",
      length: "20.00",
      mineral: "AuEq",
      grade: "0.53",
      gradeUOM: "g/t",
      depth: "NA",
    },
    {
      date: "2023-12-19",
      highlight:
        "1.56 gpt AuEq over 5.00 meters', '0.53 gpt AuEq over 20.00 meters', '1.76 gpt AuEq over 1.00 meter",
      length: "1.00",
      mineral: "AuEq",
      grade: "1.76",
      gradeUOM: "g/t",
      depth: "NA",
    },
  ]);

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };

  const interceptsColumns = [
    {
      headerName: "Date",
      field: "publication_date",
      filter: "agDateColumnFilter",
      valueFormatter: (params) => moment(params.value).format("YYYY-MMM-DD"),
      filterParams: {
        filterOptions: ["equals", "inRange"],
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    },
    { headerName: "Highlight", field: "highlight_intercept" },
    { headerName: "Length (meters)", field: "length_meters", autoHeight: true },
    { headerName: "Mineral", field: "mineral" },
    { headerName: "Grade", field: "grade_value" },
    { headerName: "Grade UOM", field: "grade_uom" },
    { headerName: "Depth (meters)", field: "depth_meters" },
  ];

  const onExportClick = () => {
    gridRef.current.api.exportDataAsExcel();
  };
  let gridApi;

  useEffect(() => {
    if (project) {
      console.log(project);
      fetchFilteredData();
    }
  }, [project]);

  const [rowData, setRowData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const fetchFilteredData = async () => {
    const response = await axios.get(
      `https://py.prospectormatch.com/api/projects/${project.id}/intercepts`
    );
    setRowData(response.data?.data);
    setFilteredData(response.data?.data);
  };
  useEffect(() => {
    return () => {
      gridRef?.current?.api?.destroy();
    };
  }, []);

  return (
    <div className="p-4">
      {rowData.length > 0 ? (
        <>
          {" "}
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold">Intercepts</h1>
            <button
              onClick={onExportClick}
              type="button"
              className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-emerald-600 shadow-sm ring-1 ring-inset ring-emerald-300 hover:bg-emerald-50"
            >
              Download Data to Excel
            </button>
          </div>
          <div className="ag-theme-custom" style={{ width: "100%" }}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              domLayout="autoHeight"
              columnDefs={interceptsColumns}
              defaultColDef={defaultColDef}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};
const mapStateToProps = (state) => ({
  project: state.authReducer.selectedProject,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Intercepts));
