/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import {
  searchClient,
  elements,
  miningNewsSearchIndex,
  projectsSearchIndex,
  companiesSearchIndex,
} from "../../configure/constants";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  Index,
  Configure,
  connectStateResults,
  connectSearchBox,
  connectCurrentRefinements,
  ClearRefinements,
} from "react-instantsearch-dom";
import {
  Headline6,
  MainPage,
  Primary,
  Headline5,
  Body1,
  Body2,
  GreyBox,
  Button,
  Subtitle1,
  Subtitle2,
  BrowseListFilter,
  Hexagon,
} from "../general";
import {
  getCompanyDetail,
  getProjectDetail,
} from "../../store/Actions/AuthActions";
import { ProjectModel } from "../../models";
import { withRouter } from "react-router-dom";
export const SearchComponent = ({
  add,
  type,
  activeUser,
  history,
  getProjectDetail,
  setSupscriptionMessage,
  selectedProject,
  setProject,
  setCompany,
  getCompanyDetail,
  selectedCompany,
  setSupscriptionPopup,
}) => {
  const [searchState, setSearchState] = React.useState({});
  const subscription = activeUser;
  const CompanyHit = ({ hit }) => {
    return (
      <div
        className="flex-boy-row"
        style={{ width: "100%", cursor: "pointer" }}
      >
        <div className="company-ticker" style={{ marginBottom: "2px" }}>
          <Subtitle1 color="#00AC69">
            {hit.yahoo_ticker || hit.google_tiker || "Private Company"}
          </Subtitle1>
          <Body2>{hit.name}</Body2>
        </div>

        <GreyBox
          onClick={() => add(hit)}
          className="connect-button"
          style={{
            height: "40px",
            fontWeight: "bold",
            fontSize: "0.8rem",
            textTransform: "uppercase",
            color: Primary,
            backgroundColor: Primary,
            marginTop: "20px",
            width: "100px",
            textAlign: "center",
            borderRadius: "5px",
            marginRight: "10px",
          }}
        >
          + ADD to list
        </GreyBox>
      </div>
    );
  };
  const ProjectHit = ({ hit }) => {
    if (hit) {
      hit = new ProjectModel(hit);
      return (
        <div
          className="flex-boy-row"
          style={{ width: "100%", cursor: "pointer" }}
        >
          <div style={{ flex: 1 }}>
            <Subtitle1 color="#00AC69">{hit.reportName}</Subtitle1>
            <Subtitle2>{hit.stage}</Subtitle2>
          </div>

          <div
            className="flex-boy-row"
            style={{
              marginTop: "5px",
              height: "fit-content",
              flex: 1,
              justifyContent: "flex-end",
            }}
          >
            <div>
              <GreyBox
                onClick={() => add(hit)}
                className="connect-button"
                style={{
                  height: "40px",
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  textTransform: "uppercase",
                  color: Primary,
                  backgroundColor: Primary,
                  marginTop: "20px",
                  width: "100px",
                  textAlign: "center",
                  borderRadius: "5px",
                  marginRight: "10px",
                }}
              >
                + ADD to list
              </GreyBox>
            </div>
            {/* <div className="flex-boy-row" style={{marginTop:'5px',justifyContent:'normal',flexWrap:'wrap'}}>
                                      {states.map((status, index) => {
                                         return (
                                             <GreyBox style={{
                                                 margin: "2px",
                                                 width:'72px',
                                                 fontSize:'14px',
                                                 backgroundColor: hit.mineralEstimate.toString().includes(status) ?'#1A9BDB' : 'gainsboro',
                                                 color: hit.mineralEstimate.toString().includes(status)  ? 'white' : '#454555',
                                                 textTransform:'capitalize',
                                                 textAlign:'center'
                                             }} key={index}>{status}</GreyBox>
                                         )
                                     })}
                                     </div> */}
          </div>
          {/* {hit.countries.map((flag, index) => (<img key="flag" style={{ height: '25px' }} src={require(`../../assets/ProspectorDesignAssets/Flags/${flag.toLowerCase().replace(' ', '-')}.png`)} alt="region" />))} */}
        </div>
      );
    }
  };
  const NewsHit = ({ hit }) => {
    if (hit) {
      return (
        <div
          onClick={() => checkSubscription(hit.url)}
          style={{ cursor: "pointer" }}
        >
          <Subtitle1 className="hit-summary">{hit.summary}</Subtitle1>
          <div style={{ width: "100%", marginTop: "5px" }}>
            <Subtitle1 color="grey">{hit.source}</Subtitle1>
            {/* <Subtitle1 color="#00AC69" style={{textTransform:'capitalize'}}>
                          {hit.project.companyData.mic}:{hit.project.companyData.ticker}
                        </Subtitle1> */}
          </div>
        </div>
      );
    }
  };
  const checkSubscription = async (url) => {
    if (subscription) {
      if (subscription) {
        if (
          activeUser &&
          activeUser?.permissions.includes("access_43101_portal")
        ) {
          window.open(url, "_blank");
        } else {
          await setSupscriptionMessage("upgrade");
          setSupscriptionPopup(true);
        }
      } else {
        await setSupscriptionMessage("Login");
        setSupscriptionPopup(true);
      }
      clearSearch();
    }
  };

  const goToProject = async (project) => {
    await getProjectDetail({ id: project.objectID, history });
    clearSearch();
  };
  const goToCompany = async (project) => {
    await getCompanyDetail({ id: project.id, history });
    clearSearch();
  };
  // const checkCompany = () => {
  //   if (selectedCompany) {
  //     history.push({
  //       pathname: '/company-detail',
  //     });
  //     clearSearch()
  //   } else {
  //     setTimeout(() => {
  //       checkCompany()
  //     }, 5000);
  //   }
  // }

  const clearSearch = () => {
    setSearchState({ ...searchState, query: "" });
  };

  const [query, setQuery] = React.useState("");
  const queryHook = (query, search) => {
    setQuery(query);
  };

  // const CustomStateResults = connectStateResults(Results);

  return (
    <div className="instant-search" style={{ marginTop: "20px" }}>
      <InstantSearch
        searchClient={searchClient}
        indexName={companiesSearchIndex}
        searchState={searchState}
        onSearchStateChange={setSearchState}
      >
        <Configure hitsPerPage={5} />
        <SearchBox
          queryHook={queryHook}
          autoFocus={false}
          translations={{
            submitTitle: "Submit your search query.",
            resetTitle: "Clear your search query.",
            placeholder: `Search for ${type}`,
          }}
        />
        <div
          style={{
            margin: "5px",
            backgroundColor:
              searchState && query && query !== ""
                ? "whitesmoke"
                : "transparent",
            border: searchState && query !== "" ? "gainsboro" : "transparent",
          }}
          className="right-panel"
        >
          <Index indexName={companiesSearchIndex}>
            <Headline6 style={{ margin: "5px" }}>Companies</Headline6>
            <Hits hitComponent={CompanyHit} />
          </Index>

          {searchState && query && type === "project" ? (
            <Index indexName={projectsSearchIndex}>
              <Headline6 style={{ margin: "5px" }}>Projects</Headline6>
              <Configure hitsPerPage={5} distinct />
              <Hits hitComponent={ProjectHit} />
            </Index>
          ) : null}
        </div>
      </InstantSearch>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedCompany: state.authReducer.selectedCompany,
  selectedProject: state.authReducer.selectedProject,
  activeUser: state.authReducer.activeUser,
});

const mapDispatchToProps = (dispatch) => ({
  getProjectDetail: (data) => dispatch(getProjectDetail(data)),
  setProject: (data) => dispatch({ type: "SET_PROJECT", payload: data }),
  setCompany: (data) => dispatch({ type: "SET_COMPANY", payload: data }),
  getCompanyDetail: (data) => dispatch(getCompanyDetail(data)),
  setSupscriptionPopup: (data) =>
    dispatch({ type: "SET_SUBSCRIPTION_POPUP", payload: data }),
  setSupscriptionMessage: (data) =>
    dispatch({ type: "SET_SUBSCRIPTION_MESSAGE", payload: data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchComponent));
