/* eslint-disable no-unused-vars */

import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Subtitle1,
  Headline4,
  Headline5,
  Subtitle2,
  Headline6,
  Button,
  NotifyDiv,
  Primary,
  ProspectorDescription,
  Body1,
  Body2,
  ProspectorInterest,
  TailwindButton,
} from "../general";
import { TextField, Popper } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  apiRequest,
  updateSignUpDetails,
  updateUserDetails,
} from "../../store/Actions/AuthActions";
import { searchClient } from "../../configure/constants";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
} from "react-instantsearch-dom";
import Avatar from "react-avatar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginBottom: "20px !important",
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00AC69",
        border: "2px solid",
      },
      "&:hover fieldset": {
        borderColor: "#00AC69",
      },
    },
  },
}));

export const UserData = ({
  activeUser,
  updateUserDetails,
  closeUserDataRequest,
}) => {
  const classes = useStyles();
  const type = "Individual";
  const [companyModal, setCompany_list] = useState(false);
  const [go, setContinue] = useState(true);

  const [errors, seterrors] = useState({
    companyName: null,
    companyPosition: null,
    email: null,
    firstName: null,
    lastName: null,
  });
  useEffect(() => {
    fetchCompanies();

    return () => {};
  }, []);
  const [companies, setCompanies] = useState([]);
  const fetchCompanies = async () => {
    let { data } = await apiRequest.get(`/companies?shallow=true`);

    if (data) {
      setCompanies(data.data);
    }
  };

  const setCompanyName = (id, name) => {
    let new_data = { ...data };
    new_data.company_name = name;
    new_data.current_company_name = name;
    new_data.current_company_id = id;

    setData({ ...new_data });
    if (!Object.values(new_data).some((x) => x === null && x === "")) {
      setContinue(true);
    }
    setCompany_list(false);
  };

  const [data, setData] = useState({
    email: activeUser?.email,
    name: activeUser?.name,
    company_name: activeUser?.companyData ? activeUser?.companyData.name : "",
    current_company_position: activeUser?.current_company_position,
    current_company_id: activeUser?.current_company_id,
    bio: activeUser?.bio || "",
  });

  const change_text = (e) => {
    let new_data = { ...data };
    let new_errors = { ...errors };

    let id = e.target.name;
    if (id === "email") {
      if (!/\S+@\S+\.\S+/.test(e.target.value)) {
        new_errors.email = "Invalid email address";
        seterrors(new_errors);
      } else {
        new_errors.email = null;
        seterrors(new_errors);
      }
    }
    new_data[e.target.name] = e.target.value;

    setData(new_data);

    if (Object.values(new_data).every((x) => x !== null && x !== "")) {
      setContinue(true);
    }
  };

  const validate = async () => {
    let new_data = { ...data };
    let new_errors = { ...errors };
    if (new_data.email === "") {
      new_errors.email = "Please enter Your email address";
    } else if (!/\S+@\S+\.\S+/.test(new_data.email)) {
      new_errors.email = "Invalid email address";
      seterrors(new_errors);
    } else if (new_data.name === "") {
      new_errors.name = "Please enter Your Name ";
      seterrors(new_errors);
    } else {
      // updateSignUpDetails({ ...data })
      await updateUserDetails(_.omitBy(data, (v) => v !== 0 && !v));
      closeUserDataRequest();
    }
  };
  const HitComp = ({ hit: { hit }, setCompanyName }) => (
    <div
      onClick={() => setCompanyName(hit.id, hit.name)}
      style={{ width: "100%", justifyContent: "normal", cursor: "pointer" }}
      className="flex-boy-row"
    >
      <Avatar name={hit.name} round color={Primary} size={25} />
      <Subtitle1 style={{ marginLeft: "20px" }}>
        <Highlight attribute="name" hit={hit} tagName="mark" />
      </Subtitle1>
    </div>
  );
  const companyChange = (e, value) => {
    if (value) {
      setCompanyName(value.id, value.name);
    } else {
      setCompanyName(null, null);
    }
  };
  const PopperMy = function (props) {
    return (
      <Popper {...props} style={{ width: 250 }} placement="bottom-start" />
    );
  };
  return (
    <div className="p-8 rounded w-[400px] h-fit">
      <h4
        className="mb-[20px] text-2xl font-bold text-emerald-600"
        color={Primary}
      >
        Update Profile
      </h4>

      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          id="name"
          error={errors.name}
          helperText={errors.name}
          onChange={change_text}
          name="name"
          color="primary"
          style={{ margin: 5 }}
          label="name"
          placeholder="Enter your First Name"
          variant="outlined"
          defaultValue={data.name}
        />
        <TextField
          id="email"
          fullWidth={true}
          error={errors.email}
          helperText={errors.email}
          onChange={change_text}
          name="email"
          color="primary"
          style={{ margin: 5 }}
          label="Email Address"
          required
          placeholder="Enter your Email Address"
          variant="outlined"
          defaultValue={data.email}
        />
        {/* 
                <Slide direction="up" in={companyModal} mountOnEnter unmountOnExit>
                    <div className="company-list" style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
                        <div className="flex-boy-row" style={{ marginBottom: '10px' }}>
                            <KeyboardBackspaceIcon onClick={() => setCompany_list(false)} />
                            <Subtitle1>
                                Search for your company
                            </Subtitle1>
                            <CancelIcon onClick={() => setCompany_list(false)} />
                        </div>
                        <InstantSearch searchClient={searchClient} indexName="staging_companies">
                            <SearchBox />
                            <Hits hitComponent={hit => <HitComp setCompanyName={setCompanyName} hit={hit} />} />
                        </InstantSearch>
                        <TextField id="position" name="companyPosition" onChange={search_text} color='primary' style={{ margin: 10,width:300 }} label="Position" placeholder="Enter your official title" variant="outlined" />

                    </div>
                </Slide> */}

        <Autocomplete
          id="Companies"
          defaultValue={{
            name: data.company_name,
            id: data.current_company_id,
          }}
          style={{ height: "60px", marginBottom: "20px" }}
          className="company-popper"
          getOptionLabel={(option) => option.name}
          options={companies}
          onChange={(e, value) => companyChange(e, value)}
          renderInput={(params) => (
            <TextField
              {...params}
              className="company-complete"
              defaultValue={data.company_name}
              value={data.company_name}
              id="company"
              name="company_name"
              color="primary"
              style={{ margin: 10, height: "60px" }}
              label="Company"
              placeholder={
                type !== "Institution"
                  ? "Select from our list of mining companies"
                  : "Enter company name"
              }
              variant="outlined"
              onFocus={() =>
                type !== "Institution" ? setCompany_list(true) : null
              }
            />
          )}
        />

        {/* <TextField defaultValue={data.company_name} value={data.company_name} id="company" name="company_name" onChange={change_text} color='primary' style={{ margin: 10 }} label="Company" placeholder={type !== 'Institution' ? "Select from our list of mining companies" : "Enter company name"} variant="outlined" onFocus={() => type !== 'Institution' ? setCompany_list(true) : null} /> */}
        <TextField
          defaultValue={data.current_company_position}
          id="position"
          name="current_company_position"
          onChange={change_text}
          color="primary"
          style={{ margin: 10 }}
          label="Position"
          placeholder="Enter your official title"
          variant="outlined"
        />
        <TextField
          onChange={change_text}
          name="bio"
          multiline
          id="position"
          rows={10}
          color="primary"
          style={{ margin: 10 }}
          label="Profile Description"
          placeholder="Describe your professional experience and interests in mining industry."
          variant="outlined"
          value={data.bio}
        />
      </form>
      {/* <TailwindButton onClick={() => (go ? validate() : null)} text="Update" /> */}
      <button
        type="button"
        onClick={() => (go ? validate() : null)}
        className="inline-flex  justify-center items-center w-full px-4 py-2 border border-transparent text-base text-center font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
      >
        Update
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.authReducer.activeUser,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserDetails: (data) => dispatch(updateUserDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserData);
