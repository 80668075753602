import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  GreyBox,
  Headline5,
  Headline6,
  MainPage,
  Primary,
  Subtitle1,
} from "../../components";
import { format_lomp_data } from "../../configure/helpers";
import { apiRequest, getProjectDetail } from "../../store/Actions/AuthActions";
import GaugeChart from "./components/GaugeChart";
import InputForm from "./components/InputForm";
import WaterfallChart from "./components/WaterfallChart";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import moment from "moment";
import _ from "lodash";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { KeyboardBackspace } from "@material-ui/icons";

export const NpvCalculator = ({ project, history }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    fetchData();

    return () => {};
  }, [project]);

  useEffect(() => {
    if (history.location.state) {
      console.log(history.location.state);
      saveData(history.location.state.npv_data);
    }

    return () => {};
  }, [history]);

  const [saved_npv_data, saveData] = useState(null);
  const [lomp_data, setlomp_data] = useState(null);
  const [lomp_raw_data, setlomp_raw_data] = useState(null);
  const [lomp_dates, setlomp_dates] = useState([]);
  const [selected_date, setselected_date] = useState(null);
  const fetchData = async () => {
    const data = await apiRequest
      .get(`projects/${project?.id}/life-of-mine-statistics`)
      .catch((err) => console.log(err));
    if (data && data.data) {
      let dates = Object.keys(_.groupBy(data.data.data, "as_of_date"));
      setlomp_dates(dates);
      setselected_date(dates[0]);
      setlomp_raw_data(data.data.data);
      setlomp_data(
        format_lomp_data(
          data.data.data.filter((item) => item.as_of_date === dates[0])
        )
      );
    }
  };
  const switch_date = (e) => {
    let date = e.target.value;
    setselected_date(date);
    setlomp_data(
      format_lomp_data(lomp_raw_data.filter((item) => item.as_of_date === date))
    );
  };
  const gotoProject = () => {
    dispatch(getProjectDetail({ id: project.id, history }));
  };
  const pdfExportComponent = React.useRef(null);
  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  return (
    <MainPage>
      <PDFExport
        ref={pdfExportComponent}
        paperSize="auto"
        margin={40}
        fileName={`Report for ${new Date().getFullYear()}`}
        author="Prospector Inc"
      >
        <div className="flex-boy-row">
          <KeyboardBackspace
            style={{ color: Primary }}
            onClick={() => history.goBack()}
          />
          <div className="flex-1">
            <Headline5
              style={{ cursor: "pointer", textAlign: "left", margin: "10px" }}
              onClick={gotoProject}
              color={Primary}
            >
              {project.projectName}
            </Headline5>
          </div>

          <div className="flex-boy-row flex-1" style={{ height: "80px" }}>
            <Headline6 color={Primary}>Report Date</Headline6>
            <div
              className="ml-4"
              style={{
                flex: 1,
                boxSizing: "border-box",
                padding: "10px",
                paddingLeft: "0",
              }}
            >
              {lomp_data && (
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected_date}
                    label="Age"
                    onChange={switch_date}
                  >
                    {lomp_dates.map((item) => (
                      <MenuItem value={item}>
                        {moment(item).format("DD MMM YYYY")}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
          </div>
          <div className="flex-1"></div>
        </div>

        <InputForm
          data={lomp_data}
          isSavedNpv={!!saved_npv_data}
          saved_npv_data={saved_npv_data}
          exportPDFWithComponent={exportPDFWithComponent}
        />
      </PDFExport>
      <Headline5 className="text-left ">Base Case Reported Metrics</Headline5>
      <div className="grid gap-2 grid-cols-12 mt-5">
        <div className="col-span-2">
          <p className="tailwind-subtitle-black">
            Mining and Processing Methods
          </p>
        </div>

        {lomp_data
          ? Object.keys(lomp_data?.MiningProcessingMethods).map((key) => {
              const data = lomp_data.MiningProcessingMethods[key].map(
                (item, index) => (
                  <div className=" col-span-5 flex flex-row justify-between bg-slate-100 text-xs  w-full font-extralight  text-left px-1 py-1 align-middle">
                    <p className="text-left flex-1">
                      {index < 1 && item.metric_category}
                    </p>
                    <p className="text-left flex-1">{item.target_zone}</p>
                    <p className="text-left ">
                      {item.metric.toLocaleString()}{" "}
                      {item.uom !== "text" && item.uom}{" "}
                      {item.mineral !== "Combined" && item.mineral}
                    </p>
                  </div>
                )
              );
              return data;
            })
          : null}
      </div>
      <div className="w-full  py-4">
        <div className="grid gap-2 grid-cols-12">
          <div className="col-span-4 bg-white  h-80">
            <p className="tailwind-subtitle-black">Mining Metrics</p>
            <div className="w-full">
              {lomp_data
                ? Object.keys(lomp_data?.MiningMetrics).map((key) => {
                    const data = lomp_data.MiningMetrics[key].map(
                      (item, index) => (
                        <div className=" flex flex-row justify-between odd:bg-slate-100 text-xs  w-full font-extralight  text-left px-1 py-1 align-middle">
                          <p className="text-left flex-1">
                            {index < 1 && item.metric_category}
                          </p>
                          <p className="text-left flex-1">{item.target_zone}</p>
                          <p className="text-left ">
                            {parseFloat(item.metric).toLocaleString()}{" "}
                            {item.uom !== "text" && item.uom}{" "}
                            {item.mineral !== "Combined" && item.mineral}
                          </p>
                        </div>
                      )
                    );
                    return data;
                  })
                : null}
            </div>
          </div>
          <div className="col-span-4 bg-white  h-80">
            <p className="tailwind-subtitle-black">Processing Metrics</p>
            <div className="w-full">
              {lomp_data
                ? Object.keys(lomp_data?.ProcessingMetrics).map((key) => {
                    const data = lomp_data.ProcessingMetrics[key].map(
                      (item, index) => (
                        <div className=" flex flex-row justify-between odd:bg-slate-100 text-xs  w-full font-extralight  text-left px-1 py-1 align-middle">
                          <p className="text-left flex-1">
                            {index < 1 && item.metric_category}
                          </p>
                          <p className="text-left flex-1">{item.target_zone}</p>
                          <p className="text-left ">
                            {parseFloat(item.metric).toLocaleString()}{" "}
                            {item.uom !== "text" && item.uom}{" "}
                            {item.mineral !== "Combined" && item.mineral}
                          </p>
                        </div>
                      )
                    );
                    return data;
                  })
                : null}
            </div>
          </div>
          <div className="col-span-4 bg-white h-80">
            <div className="w-full">
              <p className="tailwind-subtitle-black">Production Costs</p>
              {lomp_data
                ? Object.keys(lomp_data?.UnitOperatingCosts).map((key) => {
                    const data = lomp_data.UnitOperatingCosts[key].map(
                      (item, index) => (
                        <div className=" flex flex-row justify-between odd:bg-slate-100 text-xs  w-full font-extralight  text-left px-1 py-1 align-middle">
                          <p className="text-left flex-1">
                            {index < 1 && item.metric_category}
                          </p>
                          <p className="text-left flex-1">{item.target_zone}</p>
                          <p className="text-left ">
                            {parseFloat(item.metric).toLocaleString()}{" "}
                            {item.uom !== "text" && item.uom}{" "}
                            {item.mineral !== "Combined" && item.mineral}
                          </p>
                        </div>
                      )
                    );
                    return data;
                  })
                : null}
            </div>
            <div className="w-full">
              <p className="tailwind-subtitle-black">Production Costs</p>
              {lomp_data
                ? Object.keys(lomp_data?.ProductionCosts).map((key) => {
                    const data = lomp_data.ProductionCosts[key].map(
                      (item, index) => (
                        <div className=" flex flex-row justify-between odd:bg-slate-100 text-xs  w-full font-extralight  text-left px-1 py-1 align-middle">
                          <p className="text-left flex-1">
                            {index < 1 && item.metric_category}
                          </p>
                          <p className="text-left flex-1">{item.target_zone}</p>
                          <p className="text-left ">
                            {parseFloat(item.metric).toLocaleString()}{" "}
                            {item.uom !== "text" && item.uom}{" "}
                            {item.mineral !== "Combined" && item.mineral}
                          </p>
                        </div>
                      )
                    );
                    return data;
                  })
                : null}
            </div>
            <div className="w-full">
              <p className="tailwind-subtitle-black">Capital Expenditures</p>
              {lomp_data
                ? Object.keys(lomp_data?.CapitalExpenditures).map((key) => {
                    const data = lomp_data.CapitalExpenditures[key].map(
                      (item, index) => (
                        <div className=" flex flex-row justify-between odd:bg-slate-100 text-xs  w-full font-extralight  text-left px-1 py-1 align-middle">
                          <p className="text-left flex-1">
                            {index < 1 && item.metric_category}
                          </p>
                          {/* <p className='text-left flex-1'>{item.target_zone}</p> */}
                          <p className="text-left">
                            {parseFloat(item.metric).toLocaleString()}{" "}
                            {item.uom !== "text" && item.uom}{" "}
                            {item.mineral !== "Combined" && item.mineral}
                          </p>
                        </div>
                      )
                    );
                    return data;
                  })
                : null}
            </div>
            <div className="w-full">
              <p className="tailwind-subtitle-black">Royalties</p>
              {lomp_data
                ? Object.keys(lomp_data?.Royalties).map((key) => {
                    const data = lomp_data.Royalties[key].map((item, index) => (
                      <div className=" flex flex-row justify-between odd:bg-slate-100 text-xs  w-full font-extralight  text-left px-1 py-1 align-middle">
                        <p className="text-left flex-1">
                          {index < 1 && item.annotations}
                        </p>
                        {/* <p className='text-left flex-1'>{item.target_zone}</p> */}
                        <p className="text-left">
                          {parseFloat(item.metric).toLocaleString()}{" "}
                          {item.uom !== "text" && item.uom}{" "}
                          {item.mineral !== "Combined" && item.mineral}
                        </p>
                      </div>
                    ));
                    return data;
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </MainPage>
  );
};

const mapStateToProps = (state) => ({
  project: state.authReducer.selectedProject,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NpvCalculator);
