/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  Fragment,
} from "react";
import { connect } from "react-redux";
import {
  Body1,
  Subtitle1,
  Headline6,
  Headline4,
  Subtitle2,
  Button,
  NotifyDiv,
  Primary,
  RightMessageBubble,
  LeftMessageBubble,
} from "../../components";

import {
  Send,
  AttachFile,
  KeyboardBackspace,
  DateRange,
  Cancel,
} from "@material-ui/icons";
import { TextField, Dialog, Popover, Badge } from "@material-ui/core";
import { Headline5 } from "../general";
import Profile from "../../assets/ProspectorDesignAssets/Companies/istockphoto-1154642632-612x612.jpg";
import { ReviewMeeting } from "../ReviewMeeting";
import background from "../../assets/ProspectorDesignAssets/back.png";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import {
  sendMessage,
  updateSelectedChat,
} from "../../store/Actions/AuthActions";
import { useFirestoreConnect, useFirebase } from "react-redux-firebase";
import { useDropzone } from "react-dropzone";

import Dropzone from "react-dropzone";
const conversation = [
  {
    sender: "me",
    text: "Hey when are you available to meet? I would like to hear more about your company during PDAC 2021.",
    date: "",
    type: "text",
  },
  {
    sender: "person2",
    text: "We can meet any time tomorrow",
    date: "",
    type: "text",
  },
  {
    sender: "me",
    text: "Ok great. Please send a calendar invite with the details.",
    date: "",
    type: "text",
  },
  {
    sender: "person2",
    text: "Ok great. Please send a calendar invite with the details.",
    date: "",
    type: "invite",
  },
];

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",

  height: "auto",
  width: "100%",
};
export const ChatContent = ({
  auth,
  updateSelectedChat,
  selectedChat,
  texts,
  setActive,
  sendMessage,
}) => {
  const me = auth.uid;

  const firebase = useFirebase();
  const storageRef = firebase.storage().ref();
  const messageField = React.useRef(null);
  const notme =
    selectedChat.type === "dm"
      ? selectedChat.members.filter((item) => item !== me)[0]
      : selectedChat.name;
  const name =
    selectedChat.type === "dm"
      ? selectedChat.memberNames[notme]
      : selectedChat.name;
  const myname = selectedChat.memberNames[me];
  const [message, setMessage] = useState("");
  const [viewMeeting, setRequest] = useState(false);
  const [images, setResource] = useState([]);
  const messagesEndRef = useRef(null);
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: 4,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  useEffect(() => {
    scrollToBottom();

    return () => {};
  }, [texts]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const postMessage = async () => {
    if (files.length) {
      let dummy = [];
      let file_list = files.map(async (file) => {
        await storageRef
          .child("images/")
          .put(file)
          .then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then((url) => {
              dummy.push(url);
            });
          });
      });
      let my_files = await Promise.all(file_list);
      await Promise.all(
        dummy.map((file, index) => {
          let data = {
            chatId: selectedChat.id,
            message: message,
            senderId: me,
            senderName: myname,
            resourceType: "image",
            resource: file,
          };
          sendMessage(data);
        })
      ).then((res) => {
        messageField.current.value = "";
        setFiles([]);
      });
    } else {
      let data = {
        chatId: selectedChat.id,
        message: message,
        senderId: me,
        senderName: myname,
      };
      sendMessage(data);
      messageField.current.value = "";
    }
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };
  useFirestoreConnect([
    {
      collection: "chats",
      doc: selectedChat.id,
      subcollections: [{ collection: "messages" }],
      storeAs: "messages",
      orderBy: ["timestamp", "asc"],
    },
  ]);
  const chats = useSelector((state) => state.firestore.ordered.chats) || [];
  const messages =
    useSelector((state) => state.firestore.ordered.messages) || [];
  const handleClose = () => {
    setRequest(false);
  };
  function onFilesDrop(files) {
    // return firebase.uploadFiles(filesPath, files, filesPath)
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlepopClose = () => {
    setAnchorEl(null);
    handleClosePop();
  };
  const handleClosePop = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const removeFile = (index) => {
    let new_files = [...files];
    new_files.splice(index, 1);

    setFiles(new_files);
  };
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={viewMeeting}
      >
        <ReviewMeeting setRequest={setRequest} />
      </Dialog>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ padding: "10px" }} className="flex-boy-row">
          <KeyboardBackspace
            style={{ color: "#00000061" }}
            onClick={() => handlepopClose()}
          />

          <Cancel
            fontSize="small"
            style={{ color: "#00000061" }}
            onClick={() => handlepopClose()}
          />
        </div>
        <div className="dropzone-popover" style={{ padding: "10px" }}>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
          <div
            className="flex-boy-row"
            style={{ justifyContent: "normal", margin: "10px" }}
          >
            {files.map((file, index) => (
              <div
                style={{
                  height: "100px",
                  width: "100px",
                  margin: "5px",
                  position: "relative",
                }}
                key={file.name}
              >
                <Cancel
                  className="remove-image"
                  fontSize="small"
                  onClick={() => removeFile(index)}
                  style={{ color: "grey" }}
                />
                <img src={file.preview} alt="thum" style={img} />
              </div>
            ))}
          </div>
        </div>
      </Popover>

      <div
        style={{
          background: `url(${background})`,
          height: "calc(100vh - 150px)",
        }}
      >
        <div
          className="chat-container flex-boy-column"
          style={{ background: "#ffffffb3", height: "100%" }}
        >
          <div
            style={{ justifyContent: "normal", width: "100%" }}
            className="flex-boy-row"
          >
            <KeyboardBackspace
              onClick={() => {
                setActive("chat_list");
              }}
              style={{ color: Primary }}
            />
            <div style={{ flex: 1, textAlign: "left", marginLeft: "10px" }}>
              <Headline6 color={Primary}>{name}</Headline6>
              <Subtitle2>Last Seen 10:23 PM EST Feb 21, 2021</Subtitle2>
            </div>
          </div>
          <div className="chat-body">
            {messages.map((message, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    marginBottom: "20px",
                    flexDirection:
                      message.senderId === me ? "row-reverse" : "row",
                  }}
                  className="flex-boy-row"
                >
                  {message.senderId === me ? (
                    <Avatar
                      name={message.senderName}
                      round
                      color="#3A9CCF"
                      size={45}
                    />
                  ) : (
                    <Avatar
                      name={message.senderName}
                      round
                      color={Primary}
                      size={45}
                    />
                  )}
                  {message.senderId === me ? (
                    <RightMessageBubble
                      color={message.type === "invite" ? "white" : null}
                    >
                      {message.resourceType !== "image" &&
                      message.message !== " " ? (
                        <Subtitle2>{message.message}</Subtitle2>
                      ) : message.resourceType === "image" ? (
                        <Fragment>
                          <img
                            style={{ width: "100%" }}
                            src={message.resource}
                            alt="resource"
                          />
                          <Subtitle2>{message.message}</Subtitle2>
                        </Fragment>
                      ) : (
                        <div className="flex-boy-row">
                          <DateRange style={{ color: Primary }} />
                          <div className="flex-boy-column">
                            <Subtitle1>
                              PDAC 2021 - Artemis Gold / KWR Capital
                            </Subtitle1>
                            <Subtitle2> Last Updated 10 May 2019</Subtitle2>
                          </div>
                        </div>
                      )}
                    </RightMessageBubble>
                  ) : (
                    <LeftMessageBubble
                      onClick={() =>
                        message.type === "invite" ? setRequest(true) : null
                      }
                      color={message.type === "invite" ? "white" : null}
                    >
                      {message.resourceType !== "image" &&
                      message.message !== " " ? (
                        <Subtitle2 color="white">{message.message}</Subtitle2>
                      ) : message.resourceType === "image" ? (
                        <img
                          style={{ width: "100%" }}
                          src={message.resource}
                          alt="resource"
                        />
                      ) : (
                        <div className="flex-boy-row">
                          <DateRange style={{ color: Primary }} />
                          <div className="flex-boy-column">
                            <Subtitle1 color={Primary}>
                              PDAC 2021 - Artemis Gold / KWR Capital
                            </Subtitle1>
                            <Subtitle2 color={Primary}>
                              {" "}
                              Last Updated 10 May 2019
                            </Subtitle2>
                          </div>
                        </div>
                      )}
                    </LeftMessageBubble>
                  )}
                </div>
              );
            })}
            <div
              style={{ height: "70px", color: "white" }}
              ref={messagesEndRef}
            />
          </div>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              boxSizing: "border-box",
              padding: "10px",
            }}
            className="flex-boy-row"
          >
            <Badge
              color="error"
              badgeContent={files.length}
              invisible={!files.length}
            >
              <AttachFile style={{ color: Primary }} onClick={handleClick} />
            </Badge>

            <TextField
              inputRef={messageField}
              onKeyPress={(ev) => {
                if (ev.key === "Enter" && message !== "") {
                  // Do code here
                  postMessage();
                  ev.preventDefault();
                }
              }}
              style={{ margin: 10, width: 600 }}
              onChange={(e) => setMessage(e.target.value)}
              variant="outlined"
            />
            <Send
              onClick={() =>
                message !== "" || files.length ? postMessage() : null
              }
              style={{
                color: message !== "" || files.length ? Primary : "grey",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  selectedChat: state.authReducer.selectedChat,
  texts: state.firestore.ordered.messages,
});

const mapDispatchToProps = (dispatch) => ({
  // updateSelectedChat:(data)=> dispatch(updateSelectedChat(data))
  sendMessage: (data) => dispatch(sendMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatContent);
