export const initialState = {
  currentLangCode: "en",
};

export default function i18nReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_LANGUAGE_CODE":
      return {
        ...state,
        currentLangCode: action.payload,
      };
    default:
      return state;
  }
}
