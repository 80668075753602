import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TailwindButtonOutline } from "../../../../../components";
import { apiRequest } from "../../../../../store/Actions/AuthActions";

export const Citations = ({ close, company, mineral }) => {
  const [citations, setcitations] = useState(null);
  const data_types = ["Tonnage", "Grade", "Contained Metal"];
  useEffect(() => {
    fetchCitations();

    return () => {};
  }, [company]);

  const fetchCitations = async () => {
    let data = await apiRequest
      .get(`/companies/${company.id}/annual-production-sources`)
      .catch((e) => {
        console.log(e);
      });
    if (data?.data.data) {
      setcitations(
        data.data.data.filter(
          (item) => item.document_name && item.document_name !== ""
        )
      );
    }
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex pt-4 justify-between">
        <p className="text-xl font-bold"> Production Data Sources</p>
        <svg
          onClick={close}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="whiteSpace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Document
                    </th>
                    <th
                      scope="col"
                      className="whiteSpace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Project
                    </th>

                    <th
                      scope="col"
                      className="whiteSpace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      As of Date
                    </th>
                    <th
                      scope="col"
                      className="whiteSpace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Years
                    </th>

                    <th
                      scope="col"
                      className="whiteSpace-nowrap  px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Metric Name
                    </th>
                    <th
                      scope="col"
                      className="whiteSpace-nowrap  px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Page
                    </th>
                    <th
                      scope="col"
                      className="whiteSpace-nowrap  px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Figure
                    </th>

                    <th
                      scope="col"
                      className="whiteSpace-nowrap  px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    ></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {citations?.map((citation) => (
                    <tr key={citation.id}>
                      <td className=" px-3 py-4 text-sm text-gray-500">
                        {citation.document_name}
                      </td>
                      <td className=" px-3 py-4 text-sm text-gray-500">
                        {citation.project_name}
                      </td>

                      <td className=" px-3 py-4 text-sm text-gray-500">
                        {citation.as_of_date?.toString()}
                      </td>
                      <td className=" px-3 py-4 text-sm text-gray-500">
                        {citation.years?.toString()}
                      </td>

                      <td className=" px-3 py-4 text-sm text-gray-500">
                        {citation.metrics?.toString()}
                      </td>
                      <td className=" px-3 py-4 text-sm text-gray-500">
                        {citation.pages?.toString()}
                      </td>
                      <td className=" px-3 py-4 text-sm text-gray-500">
                        {citation.figures?.toString()}
                      </td>
                      <td className=" px-3 py-4 text-sm text-gray-500">
                        <TailwindButtonOutline
                          text="View"
                          onClick={() => {
                            if (citation.citation_document_id) {
                              window.open(
                                `${process.env.REACT_APP_REDIRECT_DOMAIN}report-detail/${citation.citation_document_id}`,
                                "_blank"
                              );
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Citations);
