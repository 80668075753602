import React, { useState } from "react";
import { connect } from "react-redux";

export const Onboarding = ({ closeUserOnboarding, activeUser }) => {
  const [slider_number, setslider_number] = useState(0);
  const Slide_0 = () => {
    return (
      <div className="w-full text-center mt-2">
        <p className="text-2xl font-bold text-center">
          Hi, {activeUser?.name}!
        </p>
        <p className="text-md w-[50%] m-auto font-medium mt-4 text-slate-500 text-center">
          Welcome to Prospector, your search engine for the mininng industry.
          Here’s a quick guide to help you get started:
        </p>
        <div className="flex flex-row mt-6">
          <div className="rounded flex-1 aspect-square border-solid border border-slate-400 m-2">
            <div className="w-full h-[40px] bg-emerald-500">
              <p className="text-md font-medium  text-white text-center">
                Companies
              </p>
            </div>
          </div>
          <div className="rounded flex-1 aspect-square border-solid border border-slate-400 m-2">
            <div className="w-full h-[40px] bg-emerald-500">
              <p className="text-md font-medium  text-white text-center">
                Projects
              </p>
            </div>
          </div>
          <div className="rounded flex-1 aspect-square border-solid border border-slate-400 m-2">
            <div className="w-full h-[40px] bg-emerald-500">
              <p className="text-md font-medium  text-white text-center">
                Technical Reports
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Slide_1 = () => {
    return (
      <div className="w-full text-center mt-2">
        <p className="text-2xl font-bold text-center">COMPANIES</p>
        <p className="text-md w-[50%] m-auto font-medium mt-4 text-slate-500 text-center">
          With over{" "}
          <span className="text-emerald-500">
            1,500 publicly listed mining companies
          </span>{" "}
          each producing multiple technical reports, Prospector is here to help
          you keep track of it all.
        </p>
        <div className="flex flex-row mt-6">
          <div className="rounded flex-1 aspect-square border-solid border border-slate-400 m-1">
            <div className="w-full h-[40px] bg-emerald-500">
              <p className="text-sm font-meduim  text-white text-center">
                Research by country, stage, and commodity.
              </p>
            </div>
          </div>
          <div className="rounded flex-1 aspect-square border-solid border border-slate-400 m-1">
            <div className="w-full h-[40px] bg-emerald-500">
              <p className="text-sm  font-medium  text-white text-center">
                Compare companies with charts and data.
              </p>
            </div>
          </div>
          <div className="rounded flex-1 aspect-square border-solid border border-slate-400 m-1">
            <div className="w-full h-[40px] bg-emerald-500">
              <p className="text-sm font-meduim  text-white text-center">
                The latest commentary from social and news.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Slide_2 = () => {
    return (
      <div className="w-full text-center mt-2">
        <p className="text-2xl font-bold text-center">PROJECTS</p>
        <p className="text-md w-[50%] m-auto font-medium mt-4 text-slate-500 text-center">
          Quickly search and analyze over{" "}
          <span className="text-emerald-500">
            {" "}
            5,572 global mining projects{" "}
          </span>{" "}
          to efficiently conduct your due diligence.
        </p>
        <div className="flex flex-row mt-6">
          <div className="rounded flex-1 aspect-square border-solid border border-slate-400 m-2">
            <div className="w-full h-[40px] bg-emerald-500">
              <p className="text-sm font-medium  text-white text-center">
                Build your own customizable dashboard.
              </p>
            </div>
          </div>
          <div className="rounded flex-1 aspect-square border-solid border border-slate-400 m-2">
            <div className="w-full h-[40px] bg-emerald-500">
              <p className="text-sm font-medium  text-white text-center">
                Up-to-date project updates and milestones.
              </p>
            </div>
          </div>
          <div className="rounded flex-1 aspect-square border-solid border border-slate-400 m-2">
            <div className="w-full h-[40px] bg-emerald-500">
              <p className="text-sm font-medium  text-white text-center">
                Browse current projects by map or list.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Slide_3 = () => {
    return (
      <div className="w-full text-center mt-2">
        <p className="text-2xl font-bold text-center">TECHNICAL REPORTS</p>
        <p className="text-md w-[50%] m-auto font-medium mt-4 text-slate-500 text-center">
          Mining is cyclical and the demand for metal changes. Prospector makes{" "}
          <span className="text-emerald-500">NI 43-101</span> reports accessible
          in one place so you can see all the technical reports for the right
          metal at the right time.
        </p>
        <div className="flex flex-row mt-6">
          <div className="rounded flex-1 aspect-square border-solid border border-slate-400 m-2">
            <div className="w-full h-[40px] bg-emerald-500">
              <p className="text-sm font-medium  text-white text-center">
                Download full reports or the pages you need.
              </p>
            </div>
          </div>
          <div className="rounded flex-1 aspect-square border-solid border border-slate-400 m-2">
            <div className="w-full h-[40px] bg-emerald-500">
              <p className="text-sm font-medium  text-white text-center">
                Analysis and sentiment by AI insights.
              </p>
            </div>
          </div>
          <div className="rounded flex-1 aspect-square border-solid border border-slate-400 m-2">
            <div className="w-full h-[40px] bg-emerald-500">
              <p className="text-sm font-medium  text-white text-center">
                Reduce technical report search time by 75%.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Slide_4 = () => {
    return (
      <div className="w-full text-center mt-6">
        <p className="text-2xl font-bold text-center">
          READY TO START EXPLORING?
        </p>

        <div className="flex flex-row mt-6 justify-center">
          <div
            onClick={() => closeUserOnboarding()}
            className={`rounded-xl mx-1 h-[30px] px-20  py-6 bg-emerald-500 text-center cursor-pointer flex flex-row items-center justify-center`}
          >
            <p className="font-bold text-xl text-white">ROCK ON!</p>
          </div>
        </div>
      </div>
    );
  };
  const components = {
    0: Slide_0,
    1: Slide_1,
    2: Slide_2,
    3: Slide_3,
    4: Slide_4,
  };

  function Story(props) {
    // Correct! JSX type can be a capitalized variable.
    const SpecificStory = components[slider_number];
    return <SpecificStory />;
  }

  return (
    <div className="rounded p-4 h-[70vh] w-full">
      <p
        onClick={() => closeUserOnboarding()}
        className={`font-medium float-right cursor-pointer text-sm ${
          slider_number !== -1 ? "text-slate-400" : "text-white"
        }`}
      >
        SKIP
      </p>
      <Story />
      <div className="flex flex-row justify-between items-center absolute bottom-4 w-[95%]">
        <p
          onClick={() =>
            slider_number > 0 ? setslider_number(slider_number - 1) : null
          }
          className={`font-medium w-[150px] cursor-pointer text-sm ${
            slider_number > 0 ? "text-slate-400" : "text-white"
          }`}
        >
          BACK
        </p>
        <div className="flex flex-row  w-fit">
          {[...Array(5)].map((item, index) => {
            return (
              <div
                onClick={() => setslider_number(index)}
                className={`rounded mx-1 h-[10px] w-[10px] ${
                  slider_number === index ? "bg-emerald-500" : "bg-slate-200"
                }`}
              ></div>
            );
          })}
        </div>
        <div
          onClick={() =>
            slider_number < 4 ? setslider_number(slider_number + 1) : null
          }
          className={`rounded-xl mx-1 h-[30px] w-[150px] ${
            slider_number < 4 ? "bg-emerald-500" : "bg-white"
          } text-center cursor-pointer flex flex-row items-center justify-center`}
        >
          <p className="font-medium text-sm text-white">CONTINUE</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.authReducer.activeUser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
