import React, { useEffect, useState, ReactElement } from "react";
import { connect } from "react-redux";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import {
  defaultLayoutPlugin,
  ToolbarProps,
} from "@react-pdf-viewer/default-layout";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import jumpToPagePlugin from "./JumptopagePlugin";
import Dropdown from "./Dropdown";

export const PdfViewer = ({ url, page, report, file, loaded_reports }) => {
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const transform = (slot) => ({
    ...slot,
    // These slots will be empty
    Open: () => <></>,
    SwitchTheme: () => <></>,
  });
  const renderToolbar = (Toolbar) => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const jumpToPagePluginInstance = jumpToPagePlugin();
  const { jumpToPage } = jumpToPagePluginInstance;
  const [loaded, setloaded] = useState(false);
  useEffect(() => {
    jumpToPage(page);

    return () => {};
  }, [page]);

  useEffect(() => {
    setloaded(false);

    return () => {};
  }, [report]);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
      <div
        style={{
          border: "1px solid rgba(0, 0, 0, 0.3)",
          height: "750px",
        }}
      >
        {loaded && report.document_sections.length > 0 && (
          <Dropdown show_page={jumpToPage} report={report} />
        )}
        <Viewer
          onDocumentLoad={() => setloaded(true)}
          plugins={[defaultLayoutPluginInstance, jumpToPagePluginInstance]}
          // fileUrl={url}
          fileUrl={
            loaded_reports?.find((item) => item.report_id === report.id)
              ?.blob || url
          }
        />
      </div>
    </Worker>
  );
};

const mapStateToProps = (state) => ({
  loaded_reports: state.reportsReducer.loaded_reports,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PdfViewer);
