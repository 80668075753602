import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Dialog, Divider } from "@material-ui/core";
import { Headline6, Primary, Subtitle1, GreyBox, Headline4 } from "..";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { apiRequest, getProjectDetail } from "../../store/Actions/AuthActions";

import { toast } from "react-toastify";
import { withRouter } from "react-router";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../assets/ProspectorDesignAssets/lottie2.json";
import { Hexagon, Subtitle2 } from "../general";
import { ProjectModel } from "../../models";
import moment from "moment";
export const UserNotes = ({ history, selectedTags }) => {
  const [type, setType] = useState("project");
  const elements = useSelector((state) => state.authReducer.minerals_list);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchnpvs();
    return () => {};
  }, []);
  const handleChange = async (event) => {
    setType(event.target.value);
    const response = await apiRequest
      .get(`/me/notes?type=${event.target.value}`)
      .catch((error) => {});
    if (response) {
      setmyNotes(response.data.data);
    }
  };
  const [isLoading, setisLoading] = useState(true);

  const [notes, setmyNotes] = useState([]);

  const ProjectNote = ({ item }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const [NotesOpen, setNotesOpen] = useState(false);
    const handleClickOpen = (item) => {
      setselectedEntity(item);
      setNotesOpen(true);
    };

    const handleNotesClose = (value) => {
      setNotesOpen(false);
      setAnchorEl(null);
    };
    const handleDeleteClose = () => {
      setConfirmDelete(false);
      setAnchorEl(null);
    };
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selectedEntity, setselectedEntity] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const deleteNote = async () => {
      const toastId = toast("Deleting Npv", {
        type: "info",
        autoClose: false,
      });
      const data = await apiRequest.delete(`/npv/${item.id}`).catch((error) => {
        toast.update(toastId, {
          theme: "light",
          render: error.response.data.message,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      });
      if (data) {
        toast.update(toastId, {
          theme: "light",
          render: "Npv Deleted",
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
        });
        fetchnpvs();
        handleDeleteClose();
        handleClose();
      }
    };
    return (
      <>
        <Dialog
          onClose={handleDeleteClose}
          aria-labelledby="simple-dialog-title"
          open={confirmDelete}
        >
          <div className="hoer-sub" style={{ padding: "20px" }}>
            <Subtitle1>
              Are you sure you want to delete this npv value
            </Subtitle1>

            <div
              className="flex-boy-row"
              style={{ justifyContent: "flex-end", marginTop: "10px" }}
            >
              <GreyBox
                onClick={() => {
                  setConfirmDelete(false);
                  setAnchorEl(null);
                }}
                className="connect-button"
                style={{
                  height: "20px",
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  textTransform: "uppercase",
                  color: Primary,
                  backgroundColor: Primary,
                  width: "75px",
                  textAlign: "center",
                  borderRadius: "5px",
                  marginRight: "10px",
                }}
              >
                Cancel
              </GreyBox>
              <GreyBox
                onClick={() => {
                  deleteNote();
                }}
                style={{
                  height: "20px",
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  textTransform: "uppercase",
                  color: "white",
                  backgroundColor: "#00AC69",
                  cursor: "pointer",
                }}
              >
                {" "}
                Delete
              </GreyBox>
            </div>
          </div>
        </Dialog>

        <div
          className="flex-boy-row w-full overflow-hidden "
          // style={{ marginBottom: "20px", cursor: "pointer" }}
        >
          <Subtitle1
            onClick={() =>
              dispatch(getProjectDetail({ id: item.project?.id, history }))
            }
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              width: "14.28%",
            }}
          >
            {item.name}
            {/* {item.name || `${item.project?.name} ${moment().format("MM-DD-YYYY HH:MM")}`} */}
          </Subtitle1>
          <Subtitle1
            onClick={() =>
              dispatch(getProjectDetail({ id: item.project?.id, history }))
            }
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              width: "14.28%",
            }}
          >
            {item.project?.name}
          </Subtitle1>
          <div className="flex flex-row " style={{ width: "14.28%" }}>
            {item.minerals
              ? [
                  ...new Set(
                    item.minerals.filter(
                      (item) =>
                        item !== "Not Available" &&
                        item !== "Combined" &&
                        item !== "Metric Tonnes"
                    )
                  ),
                ].map((min, index) => {
                  return (
                    <Hexagon
                      key={index}
                      width={28}
                      style={{ fontSize: "0.6rem" }}
                    >
                      {elements.find(
                        (item) =>
                          item.name.toLowerCase() ===
                          min.mineral.trim().toLowerCase()
                      )
                        ? elements
                            .find(
                              (item) =>
                                item.name.toLowerCase() ===
                                min.mineral.trim().toLowerCase()
                            )
                            .chemical_symbol.substring(0, 4)
                        : min.mineral.substring(0, 4)}
                    </Hexagon>
                  );
                })
              : null}
          </div>
          <Subtitle1 style={{ width: "15.28%" }}>
            $
            {parseFloat(item.npv_value).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Subtitle1>
          <Subtitle1 style={{ width: "11.28%" }}>
            {moment(item.created_at).format("DD-MM-YYYY")}
          </Subtitle1>
          <div className="flex-boy-row w-[16.28%]">
            <div
              className="flex-boy-row hover-icon p-[2px] cursor-pointer flex-1"
              onClick={() => goToNpv(item)}
            >
              <VisibilityIcon
                style={{ color: Primary }}
                className="hover-icon"
              />
              <Subtitle2 color={Primary}>View</Subtitle2>
            </div>
            <div
              className="flex-boy-row hover-icon p-[4px] mr-2 cursor-pointer flex-1"
              onClick={() => setConfirmDelete(true)}
            >
              <DeleteOutlineIcon
                style={{ color: Primary }}
                className="hover-icon"
              />
              <Subtitle2 color={Primary}>Delete</Subtitle2>
            </div>
          </div>
        </div>
      </>
    );
  };

  const fetchnpvs = async () => {
    let response = await apiRequest
      .get("/npv")
      .catch((err) => console.log(err));
    if (response && response.data.data) {
      console.log(response.data.data);
      setmyNotes(response.data.data);
      setisLoading(false);
    }
  };
  const goToNpv = async (item) => {
    console.log(item);
    await dispatch({
      type: "SET_PROJECT",
      payload: new ProjectModel(item.project),
    });
    history.push({ pathname: "/npv", state: { npv_data: item } });
  };

  return (
    <div style={{ textAlign: "left", padding: "5px" }}>
      <div
        className=" flex-boy-row"
        style={{ marginBottom: "5px", marginTop: "20px" }}
      >
        <Headline6 style={{ color: "grey", width: "14.28%" }}>
          NPV Name
        </Headline6>
        <Headline6 style={{ color: "grey", width: "14.28%" }}>
          Project Name
        </Headline6>
        <Headline6 style={{ color: "grey", width: "14.28%" }}>Metals</Headline6>
        <Headline6 style={{ color: "grey", width: "15.28%" }}>
          NPV(USD)
        </Headline6>
        <Headline6 style={{ color: "grey", width: "11.28%" }}>
          Date Saved
        </Headline6>
        <MoreVertIcon style={{ color: "white", width: "16.28%" }} />
      </div>

      <Divider />
      <div>
        {notes.length ? (
          notes.map((not) => <ProjectNote item={not} />)
        ) : isLoading ? (
          <Player
            autoplay
            loop
            src={animationData}
            style={{ height: "300px", width: "300px" }}
          >
            {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
          </Player>
        ) : (
          <div
            className="flex-boy-column"
            style={{
              justifyContent: "center",
              width: "100%",
              margin: "20px 0 20px",
            }}
          >
            <Headline4 style={{ marginBottom: "50px" }} color="grey">
              You have no saved Npvs.
            </Headline4>
            <img
              src={require("../../assets/ProspectorDesignAssets/empty_image.png")}
              alt="empty"
              style={{ height: "200px" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedTags: state.authReducer.selectedTags,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserNotes));
