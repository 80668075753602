/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts-fix";
import { apiRequest } from "../../../../../store/Actions/AuthActions";
import _ from "lodash";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../../../assets/ProspectorDesignAssets/lottie2.json";
import ContentLoader from "react-content-loader";
// Resolves charts dependancy
charts(FusionCharts);

const Costcolunm = ({
  project,
  year,
  mineral,
  classification,
  production_basis,
  type,
  metric_name,
  quarter,
  metric_type,
}) => {
  const [dataSource, setdataSource] = useState(null);
  useEffect(() => {
    setdataSource(null);
    fetchData();
    return () => {};
  }, [metric_type, quarter]);

  const fetchData = async () => {
    const data = await apiRequest
      .get(
        `/projects/${project.id}/quarterly-production-quantity?production_basis=${production_basis}&mineral=${mineral}&metric_name=${metric_name}&metric_type=${metric_type}`
      )
      .catch((err) => console.log(err));
    if (data?.data) {
      setdataSource({
        chart: {
          showYAxisValues: "0",
          showAlternateHGridColor: "0",
          divLineAlpha: "60",
          usePlotGradientColor: "0",
          showPlotBorder: "0",
          yAxisName: `Total ${metric_name}`,
          divLineColor: "#ffffff",
          showDivLineValues: "0",
          showaxislines: "0",
          // showXAxisValues: "0",
          xaxisminvalue: "23",
          xaxismaxvalue: "95",
          ynumberprefix: "$",
          yaxisminvalue: "1200",
          xnumbersuffix: "°F",

          showBorder: "0",
          showCanvasBorder: "0",
          canvasbgAlpha: "0",
          canvasbgcolor: "0",
          bgcolor: "#ffffff",
          color: "#000000",
          showValues: "0",
          palettecolors: "#1A9BDB",
        },
        data: _.sortBy(data?.data?.data, "label").map((item) => {
          item.color =
            item.label === `${year} ${quarter}` ? "#FFAB05" : "#1A9BDB";
          return item;
        }),
      });
    }
  };

  return (
    <ReactFusioncharts
      type="column2d"
      width="100%"
      height="350"
      dataFormat="JSON"
      dataSource={dataSource}
    />
  );
};

export default Costcolunm;
