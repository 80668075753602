/**
 * @typedef {Object} ProjectModel
 */
import _ from "lodash";
export class ProjectModel {
  id = "";
  reportImageURL = "";
  reportId = "";
  reportName = "";
  projectName = "";
  reportSection = "";
  hash = "";
  createdAt = "";
  minerals = [];
  mineralEstimate = "";
  reportSummary = "";
  lat = "";
  lng = "";
  objectID = "";
  median = "";
  stockHistory = "";
  status = "";
  countries = [];
  investors = [];
  is_following = null;
  stage = "";
  share_percent = null;
  report_date = "";
  region = "";
  r_r_minerals = [];
  annual_prod_minerals = [];
  quarterly_prod_minerals = [];
  annual_prod_years = [];
  quarterly_prod_years = [];
  mrr_years = [];
  ownership = null;
  constructor(obj) {
    this.id = obj.id;
    this.reportImageURL = obj.report_cover_page || obj.image_url;
    this.reportId = obj.id;
    this.reportName = obj.name;
    this.projectName = obj.name;
    this.r_r_minerals = obj.r_r_minerals;
    this.annual_prod_minerals = obj.annual_prod_minerals;
    this.quarterly_prod_minerals = obj.quarterly_prod_minerals;
    this.reportSection = obj.report_section;
    this.hash = obj.hash;
    this.createdAt = null;
    this.share_percent = obj.share_percent;
    this.ownership = obj.ownership;
    this.minerals = obj.minerals;
    this.mineralEstimate = obj.mineral_estimate;
    this.reportSummary = obj.report_summary;
    this.lat = obj.lat;
    this.lng = obj.lng;
    this.objectID = obj.id;
    this.status = obj.status;
    this.stage = obj.stage;
    this.region = obj.region;
    this.stage_icon = obj.stage_icon;
    this.countries = [obj.country];
    this.investors = _.compact(obj.investors);
    this.is_following = obj.is_following;
    this.report_date = obj.report_date;
    this.annual_prod_years = obj.years?.annual_production_years
      .sort()
      .reverse();
    this.quarterly_prod_years = obj.years?.quarterly_production_years
      .sort()
      .reverse();
    this.mrr_years = obj.mrr_years?.sort().reverse();
  }
}
