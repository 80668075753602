import React, { useState, useEffect } from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";

const CheckoutForm = ({ type, paymentMethodId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  // const loadCardDetails = async() => {
  //     // Get the customer's payment method details from the server
  //     const paymentMethod = await fetchPaymentMethod(paymentMethodId);

  //     // Autofill the card details
  //     elements.getElement(CardElement).update({ value: paymentMethod.card });
  // }
  const AddPaymentMethod = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    setIsLoading(false);

    if (error) {
      setErrorMessage(error.message);
    } else {
      // send the paymentMethod.id to your server to complete the payment
    }
  };
  const handleUpdatePaymentMethod = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    const { error, setupIntent } = await stripe.confirmCardSetup(
      paymentMethodId,
      {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      }
    );

    setIsLoading(false);

    if (error) {
      setErrorMessage(error.message);
    } else {
      // send the setupIntent.payment_method to your server to complete the payment method update
    }
  };
  return (
    <div className="w-[500px] h-[300px] n">
      <form
        className="flex flex-col justify-betwee"
        onSubmit={
          type === "update" ? handleUpdatePaymentMethod : AddPaymentMethod
        }
      >
        <label className="mb-2">
          Card details
          <CardElement />
        </label>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <button
          type="submit"
          disabled={!stripe || isLoading}
          className="inline-flex text-center mt-8 items-center rounded-md border border-transparent bg-emerald-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
        >
          <p className="w-full text-center">
            {" "}
            {isLoading ? "Loading..." : type === "update" ? "Update" : "Add"}
          </p>
        </button>
      </form>
    </div>
  );
};

export default CheckoutForm;
