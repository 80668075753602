import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { connect } from "react-redux";
import axios from "axios";
import { el } from "date-fns/locale";
import { Player } from "@lottiefiles/react-lottie-player";
import { Subtitle1 } from "../../../components";
import animationData from "../../../assets/ProspectorDesignAssets/lottie2.json";
import { set } from "date-fns";

export const CompanyBalanceSheet = ({ company }) => {
  const tabs = [
    { name: "Annual", active: true, endPoint: "af" },
    { name: "SemiAnnual", active: false, endPoint: "saf" },
    { name: "Quarterly", active: false, endPoint: "qf" },
  ];

  const [activeTab, setActiveTab] = useState("Annual");
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data based on activeTab
    console.log(activeTab, company.id);

    fetchData();
  }, [activeTab, company]);

  const fetchData = async () => {
    // Replace with your data fetching logic
    setLoadingData(true);
    setData([]);
    const response = await axios.get(
      `https://py.prospectormatch.com/api/companies/${
        company.id
      }/balance_sheet_${tabs.find((tab) => tab.name === activeTab)?.endPoint}`
    );
    const result = response.data?.data;

    setData(result || []);
    setLoadingData(false);
  };

  useEffect(() => {
    // Extract column headers dynamically from the data
    if (data.length > 0) {
      const headers = Object.keys(data[0]);
      const columns = headers.map((header) => ({
        headerName: header,
        field: header,
        sortable: true,
        filter: true,
      }));
      // if financial_metric is present, make it the first column
      const financial_metric = columns.find(
        (column) => column.headerName === "Financial_Metric"
      );
      if (financial_metric) {
        columns.splice(columns.indexOf(financial_metric), 1);
        columns.unshift(financial_metric);
      }

      setColumnDefs(columns);
      setRowData(data);
    } else {
      setColumnDefs([]);
      setRowData([]);
    }
  }, [data]);
  const [loadingData, setLoadingData] = useState(false);

  const CustomLoadingOverlay = () => (
    <div
      className={`${
        !loadingData ? "hidden" : ""
      } w-[150px] text-center p-4 absolute left-[50%] top-[50%]  loading-player z-10 bg-white translate-x-[-50%] translate-y-[-50%]`}
    >
      <Player
        autoplay
        loop
        src={animationData}
        style={{ height: "100px", width: "100px" }}
      />
      <Subtitle1>Mining the data</Subtitle1>
    </div>
  );

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          defaultValue={activeTab}
          onChange={(event) => {
            console.log(event.target.value);
            setActiveTab(event.target.value);
          }}
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav aria-label="Tabs" className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <p
                key={tab.name}
                onClick={() => setActiveTab(tab.name)}
                className={classNames(
                  activeTab === tab.name
                    ? "border-emerald-500 text-emerald-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                )}
              >
                {tab.name}
              </p>
            ))}
          </nav>
        </div>
      </div>

      <div
        className="ag-theme-quartz relative"
        style={{ height: "500px", width: "100%" }}
      >
        <CustomLoadingOverlay />
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          loading={loadingData}
          loadingOverlayComponent={CustomLoadingOverlay}
          loadingOverlayComponentParams={{
            loadingMessage: "One moment please...",
          }}
        ></AgGridReact>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyBalanceSheet);
