/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "react-avatar";
import { selectList } from "../../store/Actions/AuthActions";
import { useFirestoreConnect } from "react-redux-firebase";
const dummy_companies = [
  {
    name: "Cora",
    image: require("../../assets/ProspectorDesignAssets/Companies/cora_logo.png"),
  },
  {
    name: "Newmont Goldcorp",
    image: require("../../assets/ProspectorDesignAssets/Companies/newmont-goldcorp-squarelogo-1578319326790.png"),
  },
  {
    name: "Redstar",
    image: require("../../assets/ProspectorDesignAssets/Companies/Tudor Gold Corp.jpeg"),
  },
  {
    name: "Unigold Inc",
    image: require("../../assets/ProspectorDesignAssets/Companies/Unigold Inc.png"),
  },
];
const useStyles = makeStyles({
  option: {
    fontSize: 15,
    padding: 5,
    marginBottom: 15,
    fontWeight: "bold",
    "& > span": {
      marginRight: 10,
      fontSize: 18,
      alignItems: "center",
      fontWeight: "bold",
    },
  },
});
export const AutoComp = ({ lists, selectList }) => {
  useFirestoreConnect([{ collection: "lists" }]);
  const classes = useStyles();
  return (
    <Autocomplete
      id="combo-box-demo"
      options={lists || []}
      classes={{
        option: classes.option,
      }}
      getOptionLabel={(option) => option.name}
      style={{ width: 580, height: 40 }}
      onChange={(e, value) => (value ? selectList(value.id) : null)}
      renderOption={(option) => (
        <React.Fragment>
          <Avatar
            name={option.name}
            round
            color="#00AC69"
            size={30}
            style={{ marginRight: "10px" }}
          />
          {option.name}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          style={{ height: 40, margin: 0 }}
          {...params}
          variant="outlined"
        />
      )}
    />
  );
};
const mapStateToProps = (state) => ({
  lists: state.authReducer.lists,
});

const mapDispatchToProps = (dispatch) => ({
  selectList: (data) => dispatch(selectList(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoComp);
