import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import {
  Headline5,
  Headline6,
  NotifyDiv,
  Primary,
  Subtitle1,
  Subtitle2,
  GreyBox,
  Body2,
} from "..";
import AddIcon from "@material-ui/icons/Add";
import { Button, TextField } from "@material-ui/core";
import { apiRequest, getLists } from "../../store/Actions/AuthActions";
import { Popover } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { SearchOutlined } from "@material-ui/icons";
import { toast } from "react-toastify";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";

export const List = ({ entity, type, handleClose, yay }) => {
  const pageRef = useRef();
  const AddEmoji = (e) => {};
  useEffect(() => {
    fetchMyLists();
    fetchIcons();

    return () => {};
  }, []);
  const dispatch = useDispatch();
  const fetchMyLists = async () => {
    const response = await apiRequest
      .get("/me/private-lists")
      .catch((error) => {});
    if (response) {
      setmyLists(
        response.data.data.filter((item) => item.list_entries_type === type)
      );
    }
  };
  const [myLists, setmyLists] = useState([]);
  const fetchIcons = async () => {
    const response = await apiRequest
      .get("/config/emoticons")
      .catch((error) => {});
    if (response) {
      seticons(response.data);
      setFilteredIcons(response.data);
    }
  };

  const [icons, seticons] = useState([]);
  const [filteredIcons, setFilteredIcons] = useState(null);
  const [selectedList, setselectedList] = useState([]);
  const [createMode, setcreateMode] = useState(false);
  const [newListName, setnewListName] = useState(null);
  const [newListIcon, setnewListIcon] = useState(null);
  const namechange = (e) => {
    setnewListName(e.target.value);
  };

  const iconSearch = (e) => {
    let searchTerm = e.target.value;
    let iconlist = [...Object.values(icons)];

    let results = iconlist.flat();

    let search_res = results.filter((item) => item.name.includes(searchTerm));

    setFilteredIcons(_.groupBy(search_res, "category"));
  };

  const createList = async () => {
    let payload = {
      name: newListName,
      icon:
        newListIcon?.id ||
        [...Object.values(icons)].flat().find((item) => item.name === "idea")
          .id,
      entries: [entity.id],
      type,
    };

    const toastId = toast("Creating List", { type: "info", autoClose: false });
    const data = apiRequest.post("me/private-lists", payload).catch((error) => {
      toast.update(toastId, {
        theme: "light",
        render: error.response.data.message,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    });
    if (data) {
      toast.update(toastId, {
        theme: "light",
        render: "List created",
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      let lists = [...selectedList];

      lists.push(data.data);
      setselectedList(lists);
      dispatch(getLists());
      setcreateMode(false);

      // handleClose()

      await fetchMyLists();

      pageRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };
  const updateList = async () => {
    if (selectedList.filter((x) => x !== undefined).length) {
      const toastId = toast("Adding to Lists", {
        type: "info",
        autoClose: false,
      });
      let payload = {
        entries: [entity.id],
        type,
      };

      const data = selectedList.map((item) => {
        return apiRequest.patch(`me/private-lists/${item.id}`, payload);
      });
      const result = await Promise.all(data).catch((error) => {
        toast.update(toastId, {
          theme: "light",
          render: error.response.data.message,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      });
      if (result?.length) {
        toast.update(toastId, {
          theme: "light",
          render: "Item added to lists",
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
        });
        if (yay) {
          yay();
        }
        dispatch(getLists());
        handleClose();
      } else {
        toast.update(toastId, {
          theme: "light",
          render: "unable to add to list",
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      }
    } else {
      handleClose();
    }
  };
  const handleChange = (event, listItem) => {
    let state = event.target.checked;

    let lists = [...selectedList];
    if (state) {
      lists.push(listItem);
      setselectedList(lists);
    } else {
      let index = lists.findIndex((item) => item.id === listItem.id);

      lists.splice(index, 1);
      setselectedList(lists);
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlepopClose = () => {
    setAnchorEl(null);
    handleClosePop();
  };
  const handleClosePop = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <NotifyDiv style={{ width: "450px", height: "600px" }}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="icon-selector">
          {/* <TextField onChange={iconSearch} placeholder="Search for icon" variant="outlined" style={{ width: "100%" }} /> */}
          <div className="search-emoticons">
            <OutlinedInput
              id="outlined-adornment-password"
              placeholder="Search for Icon"
              style={{ width: "100%", marginBottom: "20px" }}
              onChange={iconSearch}
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              }
            />
          </div>

          {filteredIcons
            ? [
                "Prospector",
                "Minerals",
                "Mining and Geology",
                "Animals",
                "Emoji",
                "Flags",
                "Investment and Analysis",
                "Renewable Energy",
                "Maps and Navigation",
              ].map((category) => {
                return (
                  <div>
                    <Subtitle1 style={{ fontSize: 16, marginBottom: "20px" }}>
                      {category}
                    </Subtitle1>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={1}
                    >
                      {filteredIcons[category]?.map((icon) => (
                        <Grid
                          onClick={() => setnewListIcon(icon)}
                          item
                          xs={2}
                          className="icon-svg"
                        >
                          <img
                            style={{ width: "100%" }}
                            src={icon.icon_url}
                            alt="icon"
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                );
              })
            : null}
        </div>
      </Popover>
      <Headline6>
        Add {entity.projectName || entity.name} to Your Lists
      </Headline6>
      <div className="list_body flex-boy-column">
        {!createMode ? (
          <>
            <div
              onClick={() => setcreateMode(true)}
              className="flex-boy-row"
              style={{ justifyContent: "normal", cursor: "pointer" }}
            >
              <Checkbox style={{ color: "transparent" }} />
              <div className="icon-box flex-boy-row">
                <AddIcon style={{ color: Primary, fontSize: 40 }} />
              </div>

              <Subtitle2 style={{ fontSize: 18 }}>Create new</Subtitle2>
            </div>
            {icons && Object.values(icons).length
              ? myLists.map((item) => (
                  <div className="flex-boy-row list_item">
                    <Checkbox
                      defaultChecked={item.list_entries.includes(entity.id)}
                      onChange={(e) => handleChange(e, item)}
                    />
                    <div className="icon-box">
                      <img
                        src={
                          item.icon_url ||
                          [...Object.values(icons)]
                            .flat()
                            .find((item) => item.path === "030-light.svg")
                            .icon_url
                        }
                        alt="icon"
                      />
                    </div>
                    <div>
                      <Subtitle2 style={{ fontSize: 18 }}>
                        {item.name}
                      </Subtitle2>
                      <Subtitle2 style={{ textAlign: "left" }}>
                        {item.items_count} Items
                      </Subtitle2>
                    </div>
                  </div>
                ))
              : null}
            <div ref={pageRef}></div>
          </>
        ) : (
          <div style={{ width: "100%" }}>
            <div
              className="flex-boy-row"
              style={{
                width: "100%",
                padding: "10px",
                boxSizing: "border-box",
              }}
            >
              {newListIcon ? (
                <div onClick={handleClick} className="svg_box">
                  <img
                    src={
                      newListIcon.icon_url ||
                      [...Object.values(icons)]
                        .flat()
                        .find((item) => item.path === "030-light.svg").icon_url
                    }
                    alt="icon"
                  />
                </div>
              ) : (
                <div onClick={handleClick} className="icon-box flex-boy-row">
                  <AddIcon style={{ color: Primary, fontSize: 40 }} />
                </div>
              )}
              <TextField
                onChange={namechange}
                placeholder="List Name"
                variant="outlined"
                style={{ flex: 1 }}
              />
            </div>
            <div
              className="flex-boy-row"
              style={{ justifyContent: "flex-end", marginTop: "20px" }}
            >
              <GreyBox
                onClick={() => setcreateMode(false)}
                className="connect-button"
                style={{
                  height: "40px",
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  textTransform: "uppercase",
                  color: Primary,
                  backgroundColor: Primary,
                  width: "75px",
                  textAlign: "center",
                  borderRadius: "5px",
                  marginRight: "10px",
                }}
              >
                Cancel
              </GreyBox>
              <GreyBox
                onClick={() => {
                  if (newListName?.length) {
                    createList();
                  }
                }}
                style={{
                  height: "40px",
                  fontWeight: "bold",
                  fontSize: "0.8rem",
                  textTransform: "uppercase",
                  color: "white",
                  backgroundColor: "#00AC69",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              >
                {" "}
                Create List
              </GreyBox>
            </div>
          </div>
        )}
      </div>

      <div
        onClick={updateList}
        className="save-changes flex-boy-row"
        style={{
          backgroundColor: selectedList.length ? Primary : "gainsboro",
          color: "white",
          cursor: "pointer",
        }}
      >
        <Headline6 color="white"> Save Changes</Headline6>
      </div>
    </NotifyDiv>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(List);
