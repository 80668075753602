import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { apiRequest, getBillingInfo } from "../../../store/Actions/AuthActions";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import SubscriptionUpdate from "./SubscriptionUpdate";
import AddPaymentMethod from "./AddPaymentMethod";
import CheckoutForm from "./CheckoutForm";
import { useStripe, AddressElement } from "@stripe/react-stripe-js";
import UpdateBillingInformationForm from "./Updatebillinginfo";
import { toast } from "react-toastify";
import { Subtitle1 } from "../../../components";

export const BillingInfo = ({ activeUser, stripeData }) => {
  const dispatch = useDispatch();
  const [subscriptionDialog, setSubscriptionDialog] = useState(false);
  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  useEffect(() => {
    getSubscriptionProducts();

    return () => {};
  }, []);

  const getSubscriptionProducts = async () => {
    const response = await apiRequest
      .get("/subscription-products")
      .catch((e) => console.log(e));
    if (response?.data?.data) {
      console.log(response.data.data);
      setproducts(response.data.data);
    }
  };
  const handleCloseSubscriptionDialog = () => {
    setSubscriptionDialog(false);
  };

  const [paymentDialog, setpaymentDialog] = useState(false);
  const handleClosePaymentDialog = () => {
    setpaymentDialog(false);
  };
  const [products, setproducts] = useState([]);
  const [paymentMethodId, setpaymentMethodId] = useState(null);
  const [pay_type, setpay_type] = useState("add");
  const [addressdialog, setaddressdialog] = useState(false);
  const handleCloseAddressDialog = () => {
    setaddressdialog();
  };
  const handlepaymenAdd = async () => {
    await setpaymentMethodId(null);
    await setpay_type("add");
    setpaymentDialog(true);
  };
  const handlepaymenUpdate = async (id) => {
    await setpaymentMethodId(id);
    await setpay_type("update");
    setpaymentDialog(true);
  };

  const handleClick = async (event) => {
    setaddressdialog(true);
  };
  const updateBillingInfo = async (info) => {
    const toastId = toast("Updating billing info", {
      type: "info",
      autoClose: false,
    });
    let data = await apiRequest
      .patch(`/stripe-customer/${activeUser.id}`, info)
      .catch((e) => {
        toast.update(toastId, {
          theme: "light",
          render: e.response.data.message,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      });
    if (data?.data) {
      toast.update(toastId, {
        theme: "light",
        render: "Billing info successfully updated",
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      handleCloseAddressDialog();
      dispatch(getBillingInfo());
    }
  };
  const handleopenDelete = (search) => {
    setconfirmDelete(true);
  };
  const [confirmDelete, setconfirmDelete] = useState(false);
  const handleDeleteClose = () => {
    setconfirmDelete(false);
  };
  const cancelSubscription = () => {};
  return (
    <div className="w-full p-4">
      <Dialog
        onClose={handleCloseSubscriptionDialog}
        aria-labelledby="simple-dialog-title"
        open={subscriptionDialog}
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">Add a Subscription</DialogTitle>
        <DialogContent>
          <SubscriptionUpdate products={products} />
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={handleClosePaymentDialog}
        aria-labelledby="simple-dialog-title"
        open={paymentDialog}
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">
          {pay_type === "add" ? "Add a" : "Update"} Payment Method
        </DialogTitle>
        <DialogContent>
          <CheckoutForm type={pay_type} paymentMethodId={paymentMethodId} />
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={handleDeleteClose}
        aria-labelledby="simple-dialog-title"
        open={confirmDelete}
      >
        <div className="hoer-sub" style={{ padding: "20px" }}>
          <Subtitle1>
            Are you sure you want to cancel your subscription?
          </Subtitle1>

          <div
            className="flex-boy-row"
            style={{
              height: "75px",
              width: "100%",
              padding: "20px",
              boxSizing: "border-box",

              backgroundColor: "white",
            }}
          >
            <button
              onClick={handleDeleteClose}
              type="button"
              className="inline-flex items-center  flex-1 px-3.5 mx-2 justify-center py-2 border border-emerald-500 text-xs font-bold rounded shadow-sm text-emerald-600 bg-white hover:bg-emerald-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-400"
            >
              Cancel
            </button>
            <button
              onClick={cancelSubscription}
              type="button"
              className="inline-flex items-center justify-center flex-1 mx-2 min-w-[105px] px-3.5 py-2 border border-transparent text-xs font-bold rounded shadow-sm text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
            >
              Confirm
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        onClose={handleCloseAddressDialog}
        aria-labelledby="simple-dialog-title"
        open={addressdialog}
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">Billing details</DialogTitle>
        <DialogContent>
          <UpdateBillingInformationForm
            updateUser={updateBillingInfo}
            customer={stripeData?.customer}
            paymentMethodId={paymentMethodId}
          />
        </DialogContent>
      </Dialog>

      <div className="w-full flex flex-col justify-center">
        <p className="text-emerald-500	 font-semibold">
          {moment().format("dddd, MMMM DD, YYYY")}
        </p>
        <p className=" text-3xl font-semibold">
          Hello {activeUser.name || activeUser.email}!
        </p>
      </div>
      <div className="grid grid-cols-12 gap-2 w-full">
        <div className="col-span-7">
          <div className="w-full rounded border border-slate-200 min-h-[300px] m-4 p-2">
            <p className="text-left text-xl font-semibold">
              Current Subscription
            </p>
            <div className="flex flex-row justify-between w-full mt-4">
              <p className="text-left font-semibold">
                {
                  activeUser.subscriptions.find(
                    (item) => item.status === "active"
                  )?.name
                }
              </p>
              <div className="flex flex-col">
                <button
                  type="button"
                  onClick={() => setSubscriptionDialog(true)}
                  className="inline-flex items-center rounded-md border border-transparent bg-emerald-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                >
                  Update Plan
                </button>
                <button
                  type="button"
                  onClick={() => handleopenDelete()}
                  className="mt-2 inline-flex items-center rounded-md border border-transparent bg-white px-4 py-2 text-base font-medium text-emerald-300 shadow-sm hover:bg-emerald-300  hover:text-white focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                >
                  Cancel Plan
                </button>
              </div>
            </div>
          </div>
          <div className="w-full rounded border border-slate-200 min-h-[300px] m-4 p-2">
            <p className="text-left text-xl font-semibold">Invoices</p>

            {stripeData?.invoices.map((item, index) => {
              return (
                <div
                  key={item.id}
                  className="flex flex-row justify-between m-2 items-center"
                >
                  <p className="mr-3 text-left">
                    {moment.unix(item.created).format("MMM DD, YYYY")}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                    />
                  </svg>
                  <p className="flex-1 text-center">${item.amount_paid}</p>
                  <div className="flex-1">
                    {item.paid ? (
                      <button
                        type="button"
                        className=" inline-flex items-center rounded-md border border-transparent bg-emerald-300 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                      >
                        Paid
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md border border-transparent bg-slate-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
                      >
                        Pending
                      </button>
                    )}
                  </div>
                  <p className="text-left flex-1 ml-2">{item.description}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-span-5">
          <div className="w-full rounded border border-slate-200 min-h-[300px] m-4 p-2">
            <p className="text-left text-xl font-semibold">Payment Method</p>
            {stripeData?.payment_methods.map((item, index) => {
              return (
                <div className="flex flex-row justify-between items-center my-2">
                  <p>
                    {capitalize(item.card_brand)} **** {item.card_last4}
                  </p>
                  <div className="flex flex-row">
                    <p>
                      {" "}
                      Expires {item.card_exp_month}/{item.card_exp_year}{" "}
                    </p>
                    <p
                      className="cursor-pointer"
                      onClick={() => handlepaymenUpdate(item.id)}
                    >
                      {" "}
                      &#x2022;&#x2022;&#x2022;{" "}
                    </p>
                  </div>
                </div>
              );
            })}
            <div className="flex flex-row justify-end">
              <button
                type="button"
                onClick={handlepaymenAdd}
                className="mt-2 inline-flex items-center rounded-md border border-slate-300 bg-white px-4 py-2 text-base font-medium text-emerald-600 shadow-sm hover:bg-emerald-300 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
              >
                + Add payment Method
              </button>
              {/* <AddPaymentMethod/> */}
            </div>
          </div>
          <div className="w-full rounded border border-slate-200 min-h-[300px] m-4 p-2 flex flex-col justify-between">
            <div className="flex flex-row justify-between items-center">
              <p className="text-left text-xl font-semibold">
                Billing information
              </p>
              <button
                onClick={handleClick}
                type="button"
                className="mt-2 inline-flex items-center rounded-md border border-slate-300 bg-white px-4 py-2 text-base font-medium text-emerald-600 shadow-sm hover:bg-emerald-300 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
              >
                Update
              </button>
            </div>

            <div className="flex flex-row w-full justify-between">
              <p>Name</p>
              <p>{stripeData?.customer.name}</p>
            </div>
            <div className="flex flex-row w-full justify-between">
              <p>Email</p>
              <p>{stripeData?.customer.email}</p>
            </div>
            <div className="flex flex-row w-full justify-between">
              <p>Billing Address</p>
              <p>
                {stripeData?.customer?.address?.line1}{" "}
                {stripeData?.customer?.address?.city}{" "}
                {stripeData?.customer?.address?.state}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.authReducer.activeUser,
  stripeData: state.authReducer.stripeData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfo);
