import React, { useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts-fix";
import { apiRequest } from "../../../../../store/Actions/AuthActions";
import _ from "lodash";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../../../assets/ProspectorDesignAssets/lottie2.json";
import ContentLoader from "react-content-loader";
charts(FusionCharts);

const AreaChart = ({
  company,
  year,
  mineral,
  production_basis,
  type,
  color,
  metric_name,
  quarter,
}) => {
  const [dataSource, setdataSource] = useState(null);
  console.log(type);
  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  const fetchData = async () => {
    const data = await apiRequest
      .get(
        `companies/${company.id}/${type}-summation?production_basis=${production_basis}&mineral=${mineral}&metric_name=${metric_name}`
      )
      .catch((err) => console.log(err));
    if (data?.data?.metric) {
      setdataSource({
        chart: {
          showYAxisValues: "0",
          showAlternateHGridColor: "0",
          divLineAlpha: "60",
          usePlotGradientColor: "0",
          showPlotBorder: "0",
          yAxisName: `Total ${metric_name}`,
          divLineColor: "#ffffff",
          showDivLineValues: "0",
          showaxislines: "0",

          showXAxisValues: 1,
          xaxisminvalue: "23",
          xaxismaxvalue: "95",
          ynumberprefix: "$",
          yaxisminvalue: "1200",
          xnumbersuffix: "°F",
          showBorder: "0",
          showCanvasBorder: "0",
          canvasbgAlpha: "0",
          canvasbgcolor: "0",
          bgcolor: "#ffffff",
          plotFillColor: color,
          showValues: "0",
        },
        data: _.sortBy(data?.data?.metric, "label").map((item) => {
          if (item.label === year) {
            return { ...item, anchorBgColor: "#EC457E", anchorRadius: "6" };
          }
          return {
            ...item,
          };
        }),
      });
    }
  };
  return (
    <div>
      {dataSource ? (
        <ReactFusioncharts
          type="area2d"
          width="100%"
          height="350"
          dataFormat="JSON"
          dataSource={dataSource}
        />
      ) : (
        <ContentLoader
          viewBox="0 0 600 350"
          style={{ width: "100%", height: "350px" }}
        >
          <rect
            x="20"
            y="8"
            rx="0"
            ry="0"
            style={{ width: "100%", height: "350px" }}
          />
        </ContentLoader>
      )}
    </div>
  );
};

export default AreaChart;
