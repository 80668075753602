import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts-fix";
import { NumberFormat } from "@syncfusion/ej2-base/src/intl/number-formatter";
import { nFormatter } from "../../../configure/helpers";
import { Primary } from "../../../components";

export const GaugeChart = ({
  base_data,
  chart_data,
  setchartView,
  isBaseCase,
}) => {
  charts(FusionCharts);

  useEffect(() => {
    setData();

    return () => {};
  }, [chart_data, isBaseCase]);

  const setData = () => {
    setNPV(chart_data.NPV || 1);
    if (isBaseCase) {
      const data = {
        chart: {
          caption: `${nFormatter(chart_data.NPV, 1)} USD`,
          captionFontBold: "1",
          captionFontSize: "26",
          lowerlimit: "-50",
          upperlimit: "50",
          showvalue: "0",
          numbersuffix: "%",
          theme: "fusion",
          autoScale: "0",
          showtooltip: "1",
          valueBgAlpha: "0",
          canvasbgAlpha: "0",
          canvasbgcolor: "0",
          borderAlpha: "0",
          bgcolor: "#FFFFFF",
          bgAlpha: "0",
          showGaugeBorder: "0",
          baseFontSize: "0.8em",
          // showShadow: "0",
          gaugeBorderColor: "#FFFFFF",
          gaugeInnerRadius: "150",
          gaugeOuterRadius: "170",
          gaugeBorderAlpha: "0",
          captionFontColor: "#16A3E3",
        },
        colorrange: {
          color: [
            {
              minvalue: "-50",
              maxvalue: "50",
              code: "#fcfcfc",
            },
          ],
        },
        dials: {
          dial: [
            {
              value: "0",
              baseWidth: "50",
              baseRadius: "50",
              bgColor: Primary,
              borderAlpha: "0",
            },
          ],
        },
      };
      setdataSource(data);
    } else {
      let change = (chart_data.NPV / base_data.NPV) * 100 - 100;
      console.log(change);
      const data = {
        chart: {
          caption: `${nFormatter(chart_data.NPV, 1)} USD`,
          captionFontBold: "1",
          captionFontSize: "26",
          lowerlimit: "-50",
          upperlimit: "50",
          showvalue: "0",
          numbersuffix: "%",
          theme: "fusion",

          valueBgAlpha: "0",
          canvasbgAlpha: "0",
          canvasbgcolor: "0",
          borderAlpha: "0",
          bgcolor: "#FFFFFF",
          bgAlpha: "0",
          showGaugeBorder: "0",
          baseFontSize: "0.8em",
          // showShadow: "0",
          gaugeBorderColor: "#FFFFFF",
          gaugeInnerRadius: "150",
          gaugeOuterRadius: "170",
          gaugeBorderAlpha: "0",
          captionFontColor: "#16A3E3",
        },
        colorrange: {
          color: [
            {
              minvalue: "-50",
              maxvalue: "50",
              code: "#fcfcfc",
            },
          ],
        },
        dials: {
          dial: [
            {
              value: 0,
              baseWidth: "50",
              baseRadius: "50",
              bgColor: "#B3E6D2",
              borderAlpha: "0",
            },
            {
              value: change.toFixed(2),
              baseWidth: "50",
              baseRadius: "50",
              bgColor: "#16A3E3",
              borderAlpha: "0",
            },
          ],
        },
      };
      setdataSource(data);
    }
  };
  const [dataSource, setdataSource] = useState();
  const [npv, setNPV] = useState(1);

  return (
    <div className="w-full">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 mr-2 float-right cursor-pointer"
        viewBox="0 0 20 20"
        fill="#04A569"
        onClick={() => setchartView("waterfall")}
      >
        <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
      </svg>
      <div style={{ height: "100%", boxSizing: "border-box" }}>
        <ReactFusioncharts
          type="angulargauge"
          width="100%"
          className="h-full"
          height="90%"
          dataFormat="JSON"
          dataSource={dataSource}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GaugeChart);
