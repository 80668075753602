import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import ReactFC from "react-fusioncharts-fix";
import { getCommodityData } from "../../../store/Actions/AuthActions";
import _ from "lodash";
import moment from "moment";
ReactFC.fcRoot(FusionCharts, TimeSeries);

const jsonify = (res) => res.json();
const dataFetch = fetch(
  "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/data/column-line-combination-data.json"
).then(jsonify);
const schemaFetch = fetch(
  "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/schema/column-line-combination-schema.json"
).then(jsonify);

export const StockChart = ({
  company,
  stockData,
  commodity,
  news_feed,
  history,
}) => {
  useEffect(() => {
    // onFetchData()
    generateChartData();
    return () => {};
  }, [stockData]);
  useEffect(() => {
    selectComodity();
    return () => {};
  }, [company, history]);

  const selectComodity = () => {
    if (company.yahoo_ticker) {
      let data = {
        interval: "1d",
        identifier: company.yahoo_ticker,
        range: "2y",
      };

      getCommodityData(data);
    }
  };
  const generateChartData = () => {
    let data = [
      ...stockData.data?.map((item) => {
        return [moment.unix(item.timestamp).format("DD-MMM-YYYY"), item.close];
      }),
    ];
    let trendState =
      data.length &&
      data.length > 1 &&
      data[data.length - 1].close - data[0].close >= 0
        ? "#00AC6A"
        : "#B00020";

    const schema = [
      {
        name: "Time",
        type: "date",
        format: "%e-%b-%Y",
      },
      {
        name: "Price",
        type: "number",
      },
    ];
    const fusionTable = new FusionCharts.DataStore().createDataTable(
      data,
      schema
    );
    const timeseriesDs = { ...chartdata.timeseriesDs };
    timeseriesDs.dataSource.caption.text = `${company.name} stock performance`;
    timeseriesDs.dataSource.subcaption.text = `since ${moment
      .unix(stockData.data[0]?.timestamp)
      .format("YYYY")}`;
    timeseriesDs.dataSource.data = fusionTable;
    timeseriesDs.dataSource.chart.paletteColors[0] = trendState;
    timeseriesDs.dataSource.datamarker =
      news_feed && news_feed.data.length
        ? news_feed.data.map((item) => {
            return {
              time: item.date,
              type: "pin", //data Marker set to pin
              identifier: "MR",
              timeFormat: "%Y-%-m-%-d",
              tooltext: item.summary,
            };
          })
        : [];
    setchartdata({
      timeseriesDs,
    });
  };

  const [chartdata, setchartdata] = useState({
    timeseriesDs: {
      type: "timeseries",
      renderAt: "container",
      width: "100%",
      height: "600",
      dataSource: {
        chart: {
          paletteColors: ["#00AC6A", "#FF0000"],
          bgColor: "#FF0000",
          labelFont: "Gotham-Bold",
        },
        caption: {
          text: `${company.name} stock performance`,
        },
        subcaption: {
          text: "since 2015",
        },

        yaxis: [
          {
            plot: [
              {
                value: "Price",
                type: "line",
                connectnulldata: true,
              },
            ],
          },
        ],
      },
    },
  });

  const onFetchData = () => {
    Promise.all([dataFetch, schemaFetch]).then((res) => {
      const data = res[0];
      const schema = [
        {
          name: "Time",
          type: "date",
          format: "%e-%b-%Y",
        },
        {
          name: "Price",
          type: "number",
        },
      ];
      const fusionTable = new FusionCharts.DataStore().createDataTable(
        data,
        schema
      );
      const timeseriesDs = { ...chartdata.timeseriesDs };
      timeseriesDs.dataSource.data = fusionTable;
      setchartdata({
        timeseriesDs,
      });
    });
  };

  return (
    <div>
      {chartdata.timeseriesDs.dataSource.data ? (
        <ReactFC {...chartdata.timeseriesDs} />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  company: state.authReducer.selectedCompany,
  stockData: state.authReducer.commodity_stock,
  commodity: state.authReducer.selectedCommodity,
});

const mapDispatchToProps = (dispatch) => ({
  getCommodityData: (data) => dispatch(getCommodityData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockChart);
