import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createTransform } from "redux-persist";

import authReducer from "./authReducer";
import reportsReducer from "./ReportsReducer";
import i18nReducer from "./i18nReducer";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

import { routerReducer } from "react-router-redux";
import JSOG from "jsog";

export const JSOGTransform = createTransform(
  (inboundState, key) => JSOG.encode(inboundState),
  (outboundState, key) => JSOG.decode(outboundState)
);

const rootPersistConfig = {
  key: "prospectorGlobal",
  storage,
  whitelist: ["i18nReducer", "firebaseReducer", "firestoreReducer"],
  blacklist: ["authReducer", "reportsReducer"],
};
const reportsPersistConfig = {
  key: "reportsReducer",
  storage,
  whitelist: ["reportsReducer"],
};
const authPersistConfig = {
  key: "authReducer",
  storage,
  transforms: [JSOGTransform],
  blacklist: [
    // "activeUser",
    "companies_search",
    "projects_search",
    "projects_search_filters",
    "selectedCompany_reports",
    "projects_page",
    "companies_page",
    "commodity_stock",
    "loading",
    "prospects",
    "project_start_after",
    "map_projects",
    "company_sort_filter",
    "company_search_filters",
    "reports_page",
    "report_sort_filter",
    "report_search_filters",
    "project_sort_filter",
    "selectedChatSection",
    "filings_filters",
    "filings_sort_filter",
    "drillinfilters",
  ],
};
const rootReducer = combineReducers({
  i18nReducer,
  authReducer: persistReducer(authPersistConfig, authReducer),
  firebase: firebaseReducer,
  reportsReducer: persistReducer(reportsPersistConfig, reportsReducer),
  firestore: firestoreReducer,
  routing: routerReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export default persistedReducer;
