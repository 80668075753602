/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {
  Subtitle1,
  Headline5,
  Subtitle2,
  Button,
  NotifyDiv,
} from "../../../components";
import { TextField } from "@material-ui/core";
import Logo from "../../../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon.png";
import { makeStyles } from "@material-ui/core/styles";
import { passwordReset } from "../../../store/Actions/AuthActions";
import { withRouter } from "react-router";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00AC69",
        border: "2px solid",
      },
      "&:hover fieldset": {
        borderColor: "#00AC69",
      },
    },
  },
}));

export const PasswordResetEmail = ({ history, passwordReset }) => {
  const classes = useStyles();
  const [go, setContinue] = useState(true);
  const [data, setData] = useState({
    email: "",
  });
  const [errors, seterrors] = useState({
    email: null,
  });
  const change_text = (e) => {
    let new_data = { ...data };
    let new_errors = { ...errors };
    //

    let id = e.target.name;
    if (id === "email") {
      if (!/\S+@\S+\.\S+/.test(e.target.value)) {
        new_errors.email = "Invalid email address";
        seterrors(new_errors);
      } else {
        new_errors.email = null;
        seterrors(new_errors);
      }
      setContinue(true);
    }
    new_data[e.target.name] = e.target.value;
    setData(new_data);
  };

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  function validateEmail() {
    if (!email) {
      setEmailError("Please enter an email address.");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  }

  const validate = () => {
    let new_data = { email };

    new_data.history = history;

    passwordReset(new_data);
  };
  return (
    <div className="h-1/2 flex flex-col justify-between items-center">
      <Headline5 color="#00AC69">
        Enter your email address to reset your password
      </Headline5>
      <img
        className="h-24 w-auto"
        src={require("../../../assets/ProspectorDesignAssets/Prospector_3D Logo_Lockup_Horizontal_with Tagline.png")}
        alt="Your Company"
      />
      <div className="w-full">
        <form
          className="space-y-6"
          autoComplete="off"
          onSubmit={() => (go ? validate() : null)}
        >
          <div className="w-full">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 text-left"
            >
              Email address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                onChange={(event) => setEmail(event.target.value)}
                onBlur={validateEmail}
              />
            </div>
            {emailError && (
              <div>
                <p className="text-sm text-red-600">{emailError}</p>
              </div>
            )}
          </div>
        </form>
      </div>

      <button
        type="submit"
        className="flex w-full justify-center rounded-md border border-transparent bg-emerald-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
      >
        Submit
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  passwordReset: (data) => dispatch(passwordReset(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PasswordResetEmail));
