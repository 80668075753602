/* eslint-disable no-restricted-globals */
import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { getFirebase } from "react-redux-firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import { getFirestore, reduxFirestore } from "redux-firestore";
import firebase from "../services/firebase";
import persistedReducer from "../store/Reducers";
import { createHashHistory } from "history";

const history = createHashHistory();

export const rrfConfig = {
  userProfile: "customers",
  useFirestoreForProfile: true,
};

firebase.auth();
firebase.firestore();
firebase.functions();
firebase.storage();

let Store = null;

export function getStore() {
  return Store;
}

export default function configureStore() {
  const reduxRouterMiddleware = routerMiddleware(history);
  const middleware = [
    thunk.withExtraArgument({ getFirebase, getFirestore }),
    reduxRouterMiddleware,
  ];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  Store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(...middleware),
      reduxFirestore(firebase, rrfConfig)
    )
  );

  const persistor = persistStore(Store);

  return { Store, persistor };
}
