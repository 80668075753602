/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Subtitle1,
  Headline5,
  Subtitle2,
  Button,
  NotifyDiv,
} from "../../../components";
import { TextField } from "@material-ui/core";
import Logo from "../../../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon.png";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route } from "react-router-dom";

import Page0 from "./page0";
import Page1 from "./page1";
import Page2 from "./page2";
import Page3 from "./page3";
import Page4 from "./page4";
import Page5 from "./page5";
import Page6 from "./page6";
import Page7 from "./page7";
import Page8 from "./page8";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00AC69",
        border: "2px solid",
      },
      "&:hover fieldset": {
        borderColor: "#00AC69",
      },
    },
  },
}));
const Components = {
  Page1,
  Page2,
  Page3,
  Page4,
  Page5,
  Page6,
  Page7,
  Page8,
};
export const SignUp = ({ history, activeUser }) => {
  useEffect(() => {
    switchAuth();
  }, [activeUser]);
  const switchAuth = () => {
    if (activeUser) {
      history.push("/browse");
    }
  };
  const classes = useStyles();
  const [page, setPage] = useState("Page3");
  const CustomTag = `${page}`;
  const nextPage = (page) => {
    history.push(`/auth/sign-up/${page.replace("Page", "")}`);
  };
  return (
    <NotifyDiv>
      <Switch>
        <Route
          exact
          path={`/auth/sign-up/`}
          render={(props) => <Page0 {...props} swapAuthPage={nextPage} />}
        />
        <Route
          path={`/auth/sign-up/1`}
          render={(props) => <Page1 {...props} swapAuthPage={nextPage} />}
        />
        <Route
          path={`/auth/sign-up/2`}
          render={(props) => <Page2 {...props} swapAuthPage={nextPage} />}
        />
        <Route
          path={`/auth/sign-up/3`}
          render={(props) => <Page3 {...props} swapAuthPage={nextPage} />}
        />

        <Route
          path={`/auth/sign-up/4`}
          render={(props) => <Page4 {...props} swapAuthPage={nextPage} />}
        />
        <Route
          path={`/auth/sign-up/5`}
          render={(props) => <Page5 {...props} swapAuthPage={nextPage} />}
        />
        <Route
          path={`/auth/sign-up/6`}
          render={(props) => <Page6 {...props} swapAuthPage={nextPage} />}
        />
        <Route
          path={`/auth/sign-up/7`}
          render={(props) => <Page7 {...props} swapAuthPage={nextPage} />}
        />
        <Route
          path={`/auth/sign-up/8`}
          render={(props) => <Page8 {...props} swapAuthPage={nextPage} />}
        />
      </Switch>
    </NotifyDiv>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.authReducer.activeUser,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
