import React, { useEffect } from "react";
import { MainPage } from "../components";

const HubspotUpgradeForm = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://js.hsforms.net/forms/v2.js?pre=1"
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "8333928",
          formId: "de07fc83-ba63-4fb2-9cd7-29f6d22029a7",
           target: '#hubspotForm'
        })
      }
    });
  }, []);

  return (
    <MainPage>
      <div id="hubspotForm" className="p-5"></div>
    </MainPage>
  );

}

export default HubspotUpgradeForm