import { createGlobalStyle } from "styled-components";

export default createGlobalStyle` 
  @font-face {
    font-family: 'GothamBook';
    src: url('./Gotham-Book-Regular.otf');
    font-weight: normal;
    font-style: normal;
  }   
 
`;
