/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Dialog, TextField } from "@material-ui/core";
import {
  Subtitle1,
  Body2,
  Body1,
  Headline4,
  Subtitle2,
  Hexagon,
  GreyBox,
  Button,
  Headline5,
  Headline6,
  Primary,
} from "../general";
import RequestMeeting from "../RequestMeeting";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Fragment } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { getMeetings } from "../../store/Actions/AuthActions";
import ReviewMeeting from "../ReviewMeeting";
const dummy_events = [
  {
    name: "Artemis Gold / KWR Capital",
    event: "PDAC 2021",
    date: moment().format("ddd DD"),
    time: "2:30 PM EST - 3:30 PM EST",
  },
  {
    name: "Palladium One Mining / KWR Capital",
    event: "PDAC 2021",
    date: moment().format("ddd DD"),
    time: "2:30 PM EST - 3:30 PM EST",
  },
  {
    name: "Ridgeline / KWR Capital",
    event: "PDAC 2021",
    date: moment().format("ddd DD"),
    time: "2:30 PM EST - 3:30 PM EST",
  },
  {
    name: "Japan Gold / KWR Capital",
    event: "PDAC 2021",
    date: moment().add(3, "days").format("ddd DD"),
    time: "2:30 PM EST - 3:30 PM EST",
  },
];

export const Meetings = ({ auth, meetings, getMeetings }) => {
  let me = auth.uid;
  // useFirestoreConnect([
  //     { collection: 'meetings', where: ["attendees", "array-contains", me], storeAs: 'invitedMeetings' },

  // ])
  const { ref: materialRef } = usePlacesWidget({
    onPlaceSelected: (place) => console.log(place),
  });
  // const invited = useSelector(state => state.firestore.ordered.invitedMeetings) || []
  const [viewMeeting, setReview] = useState(false);

  const [days, setDays] = useState([]);
  const [selectedMeeting, setMeeting] = useState(null);
  useEffect(() => {
    getMeetings();

    return () => {};
  }, []);
  useEffect(() => {
    days_of_week();
    return () => {};
  }, [meetings]);
  const days_of_week = () => {
    let day_list = [];
    const meets = meetings ? meetings : [];

    if (meets.length) {
      meets.forEach((meet) => {
        const element = moment(meet.start_time).format("ddd DD");

        let day = {
          day: element,
          month_number: moment(meet.start_time).format("M"),
          month: moment(meet.start_time).format("MMMM YYYY"),
          events: meets.filter(
            (item) => moment(item.start_time).format("ddd DD") === element
          ),
          day_of_week: moment(meet.start_time).format("ddd"),
          date_of_week: moment(meet.start_time).format("DD"),
        };
        day_list.push(day);
      });

      setEvents(_.groupBy(day_list, "month_number"));
    }
  };
  const [requestMeeting, setRequest] = useState(false);
  const [event_log, setEvents] = useState(null);
  const handleClose = () => {
    setRequest(false);
  };
  const handleViewClose = () => {
    setReview(false);
  };
  const openMeeting = (event) => {
    setMeeting(event);
    setReview(true);
  };
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={requestMeeting}
      >
        <RequestMeeting setRequest={setRequest} />
      </Dialog>
      <Dialog
        onClose={handleViewClose}
        aria-labelledby="simple-dialog-title"
        open={viewMeeting}
      >
        <ReviewMeeting meeting={selectedMeeting} setRequest={setReview} />
      </Dialog>

      {event_log ? (
        Object.keys(event_log).map((day) => {
          return (
            <Fragment key={day}>
              <Headline5
                style={{
                  textAlign: "left",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                {event_log[day][0].month}
              </Headline5>
              {_.uniqBy(event_log[day], "day").map((month, index) => {
                return (
                  <div
                    style={{
                      height: "fit-content",
                      alignItems: "flex-start",
                      marginBottom: "10px",
                    }}
                    className="flex-boy-row"
                  >
                    <div style={{ textAlign: "center", width: "35px" }}>
                      <Body1 color="#00000061">{month.day_of_week}</Body1>
                      <Body2 style={{ fontSize: "24px", color: "black" }}>
                        {month.date_of_week}
                      </Body2>
                    </div>
                    <div
                      className="flex-boy-column"
                      style={{ flex: 1, alignItems: "normal" }}
                    >
                      {month.events.map((event, index) => {
                        return (
                          <div
                            onClick={() => openMeeting(event)}
                            key={index}
                            style={{
                              textAlign: "left",
                              borderRadius: "7px",
                              flex: 1,
                              margin: "5px",
                              padding: "10px",
                              backgroundColor: index === 0 ? Primary : "white",
                              color: index !== 0 ? Primary : "white",
                              border: `1px solid ${Primary}`,
                            }}
                          >
                            <Subtitle1 color={index !== 0 ? Primary : "white"}>
                              {event.event} - {event.title}
                            </Subtitle1>
                            <Subtitle2 color={index !== 0 ? Primary : "white"}>
                              {moment(event.start_time).format("HH:MM A")}{" "}
                              {event.timezone}-
                              {moment(event.end_time).format("HH:MM A")}{" "}
                              {event.timezone}{" "}
                            </Subtitle2>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </Fragment>
          );
        })
      ) : (
        <div
          className="flex-boy-column"
          style={{
            justifyContent: "center",
            width: "100%",
            margin: "20px 0 20px",
          }}
        >
          <Headline4 style={{ marginBottom: "50px" }} color="grey">
            You have no meetings yet.
          </Headline4>
          <img
            src={require("../../assets/ProspectorDesignAssets/empty_image.png")}
            alt="empty"
            style={{ height: "300px" }}
          />
        </div>
      )}
      <div className="flex-boy-row" style={{ justifyContent: "center" }}>
        {/* <TextField fullWidth id="location" name="location" inputRef={materialRef} /> */}
        <Button
          onClick={() => setRequest(true)}
          style={{
            backgroundColor: "white",
            color: Primary,
            border: `1px solid ${Primary}`,
          }}
        >
          Request New Meeting
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  meetings: state.authReducer.userMeetings,
});

const mapDispatchToProps = (dispatch) => ({
  getMeetings: (data) => dispatch(getMeetings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Meetings);
