import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { RadioGroup } from "@headlessui/react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const SubscriptionUpdate = ({ products, activeUser }) => {
  const [selectedreccurence, setSelectedrecurrence] = useState("yearly");
  const [selectedPlan, setselectedPlan] = useState(null);
  return (
    <div className="p-2 w-[600px]">
      <RadioGroup
        value={selectedreccurence}
        onChange={setSelectedrecurrence}
        className="mt-2"
      >
        <RadioGroup.Label className="sr-only">
          {" "}
          Plans Available{" "}
        </RadioGroup.Label>
        <div className="grid grid-cols-3 gap-3 sm:grid-cols-3">
          {["monthly", "yearly"].map((option) => (
            <RadioGroup.Option
              key={option}
              value={option}
              className={({ active, checked }) =>
                classNames(
                  active ? "ring-2 ring-offset-2 ring-slate-500" : "",
                  checked
                    ? " hover:bg-emerald-600 hover:text-white ring-2 ring-offset-2 ring-slate-500"
                    : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                  "border  border-transparent rounded-md py-2 px-2 flex items-center text-emerald-400 justify-center text-sm font-medium capitalize sm:flex-1"
                )
              }
            >
              <RadioGroup.Label as="span">{option}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      {products.map((item, index) => {
        let current_index = products.findIndex(
          (obj) =>
            obj.name ===
            activeUser.subscriptions.find((item) => item.status === "active")
              ?.name
        );
        return (
          <div className="flex flex-row my-6 justify-between">
            <div>
              <p className="font-bold text-sm">{item.name}-individual</p>
              <p>
                {" "}
                $
                {selectedreccurence === "yearly"
                  ? item.plans.find((plan) => plan.interval === "year")
                      .amount_decimal
                  : item.plans.find((plan) => plan.interval === "month")
                      .amount_decimal}{" "}
                {selectedreccurence === "yearly" ? "per year" : "per month"}{" "}
              </p>
            </div>

            {current_index === index ? (
              <button
                disabled
                type="button"
                className="mt-2  min-w-[120px] inline-flex  text-center items-center rounded-md border border-slate-300 bg-slate-300 px-4 py-2 text-base font-medium text-white shadow-sm "
              >
                <p className="w-full text-center">Current</p>
              </button>
            ) : (
              <button
                onClick={() => setselectedPlan(item)}
                type="button"
                className="mt-2  min-w-[120px] inline-flex text-center items-center rounded-md border border-slate-300 bg-emerald-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-emerald-300 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
              >
                <p className="w-full text-center">
                  {current_index > index ? "Downgrade" : "Upgrade"}
                </p>
              </button>
            )}
          </div>
        );
      })}
      {selectedPlan && (
        <>
          <div className=" flex justify-end flex-row my-8">
            <input
              type="email"
              name="promo_code"
              id="promo_code"
              className="block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
              placeholder="Enter Promo code"
              aria-describedby="promo-code-description"
            />
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <p className="font-bold text-lg ">Updated annual cost</p>
              <p className="font-bold text-lg ">
                {" "}
                $
                {selectedreccurence === "yearly"
                  ? selectedPlan.plans.find((plan) => plan.interval === "year")
                      .amount_decimal
                  : parseFloat(
                      selectedPlan.plans.find(
                        (plan) => plan.interval === "month"
                      ).amount_decimal
                    ) * 12}
              </p>
            </div>
            <div className="flex flex-row justify-between">
              <p className="font-bold text-sm ">Total Due Today</p>
              <p className="font-bold text-sm ">
                {" "}
                $
                {selectedreccurence === "yearly"
                  ? selectedPlan.plans.find((plan) => plan.interval === "year")
                      .amount_decimal
                  : selectedPlan.plans.find((plan) => plan.interval === "month")
                      .amount_decimal}
              </p>
            </div>
          </div>
          <div className="w-full flex flex-row justify-center mt-4">
            <button
              type="button"
              className="mt-2  min-w-[120px] inline-flex text-center items-center rounded-md border border-slate-300 bg-emerald-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-emerald-300 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
            >
              <p className="w-full text-center"> Update Subscription</p>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.authReducer.activeUser,
  stripeData: state.authReducer.stripeData,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionUpdate);
