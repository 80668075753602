/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Body1,
  Body2,
  GreyBox,
  Headline5,
  Headline6,
  Primary,
  Subtitle1,
} from "../../../../components";
import { apiRequest } from "../../../../store/Actions/AuthActions";
import { format_lomp_data, nFormatter } from "../../../../configure/helpers";
import { withRouter } from "react-router-dom";
import NumberFormat from "react-number-format";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import moment from "moment";
import _ from "lodash";
import { Player } from "@lottiefiles/react-lottie-player";
import { Popover } from "@material-ui/core";
import animationData from "../../../../assets/ProspectorDesignAssets/lottie2.json";
export const LifeOfMine = ({ project, history }) => {
  //  dsfdsf
  useEffect(() => {
    fetchData();

    return () => {};
  }, [project]);
  const [lomp_data, setlomp_data] = useState(null);
  const [lomp_raw_data, setlomp_raw_data] = useState(null);
  const [lomp_dates, setlomp_dates] = useState([]);
  const [selected_date, setselected_date] = useState(null);
  const fetchData = async () => {
    const data = await apiRequest
      .get(`projects/${project?.id}/life-of-mine-statistics`)
      .catch((err) => console.log(err));
    if (data && data.data) {
      let dates = Object.keys(_.groupBy(data.data.data, "as_of_date"));
      setlomp_dates(dates);
      setselected_date(dates.reverse()[0]);
      setlomp_raw_data(data.data.data);
      setlomp_data(
        format_lomp_data(
          data.data.data.filter((item) => item.as_of_date === dates[0])
        )
      );
    }
  };
  const switch_date = (e) => {
    let date = e.target.value;
    setselected_date(date);
    setlomp_data(
      format_lomp_data(lomp_raw_data.filter((item) => item.as_of_date === date))
    );
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [annotationData, setAnnotationData] = useState(null);

  const getAnnotation = async (id) => {
    setAnnotationData(null);
    const data = await apiRequest.get(`lomp-statistics/${id}/annotations`);

    if (data?.data.data) {
      setAnnotationData(data.data.data[0]);
    }
  };
  const handleClick = (event, id) => {
    getAnnotation(id);
    // setAnnotationData(id)
    setAnchorEl(event.currentTarget);
    // setspinEnabled(false);
  };

  const handlepopClose = () => {
    setAnchorEl(null);
    handleClosePop();
  };
  const handleClosePop = () => {
    setAnchorEl(null);
    // setspinEnabled(true);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="w-[400px] h-fit p-4">
          {annotationData ? (
            <>
              <Headline6>Metric Details</Headline6>
              <div className="rounded w-full p-2 border-[1px] border-gray-400">
                <div className="flex flex-row justify-between w-full">
                  <Body2>{annotationData.metric_category}</Body2>
                </div>
                <div className="flex flex-row justify-between w-full">
                  <Subtitle1>
                    {annotationData.uom === "text"
                      ? annotationData.metric
                      : parseFloat(
                          annotationData.metric
                        )?.toLocaleString()}{" "}
                    {annotationData.uom !== "text" && annotationData.uom}{" "}
                    {annotationData.mineral}
                  </Subtitle1>
                </div>
              </div>
              <div className="rounded bg-slate-100 p-2 mt-2">
                <div className="py-4 justify-between w-full whitespace-normal break-words">
                  <Body2>Source Document</Body2>
                  <p
                    className="bold text-sm whitespace-normal cursor-pointer"
                    onClick={() => {
                      if (annotationData.source_document_id) {
                        window.open(
                          `${process.env.REACT_APP_REDIRECT_DOMAIN}report-detail/${annotationData.source_document_id}`,
                          "_blank"
                        );
                      }
                    }}
                  >
                    {annotationData.source_document}
                  </p>
                </div>
                <div className="flex flex-row justify-between w-full">
                  <div>
                    <Body1>Section</Body1>
                    <Subtitle1>{annotationData.section}</Subtitle1>
                  </div>
                  <div>
                    <Body1>Figure</Body1>
                    <Subtitle1>{annotationData.figure}</Subtitle1>
                  </div>
                  <div>
                    <Body1>page</Body1>
                    <Subtitle1>{annotationData.page_number}</Subtitle1>
                  </div>
                </div>
                <div className="w-full py-4">
                  <Body2>Annotations</Body2>
                  <Subtitle1>{annotationData.annotations}</Subtitle1>
                </div>
              </div>
            </>
          ) : (
            <Player
              autoplay
              loop
              src={animationData}
              style={{ height: "200px", width: "200px" }}
            >
              {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
            </Player>
          )}
        </div>
      </Popover>
      {lomp_data && lomp_dates?.length && lomp_raw_data ? (
        <>
          <div className="flex-boy-row mt-8">
            <Headline5>Life of Mine</Headline5>
            <div
              className="flex-boy-row"
              style={{ width: "50%", height: "80px" }}
            >
              <div
                style={{
                  flex: 1,
                  boxSizing: "border-box",
                  padding: "10px",
                  paddingLeft: "0",
                }}
              >
                {lomp_data && selected_date && (
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selected_date}
                      label="Age"
                      onChange={switch_date}
                    >
                      {lomp_dates.map((item) => (
                        <MenuItem value={item}>
                          {moment(item).format("DD MMM YYYY")}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
              <div style={{ flex: 1 }}>
                <GreyBox
                  className="connect-button"
                  style={{
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    textTransform: "uppercase",
                    color: Primary,
                    backgroundColor: "#00AC69",
                    height: "48px",
                    padding: 0,
                    marginBottom: "10px",
                  }}
                  onClick={() => history.push("/npv")}
                >
                  Calculator
                </GreyBox>
              </div>
            </div>
          </div>

          <div className="w-full  py-4">
            <div className="grid gap-2 grid-cols-12 mt-5 mb-5">
              <div className="col-span-2">
                <p className="tailwind-subtitle-black">
                  Mining and Processing Methods
                </p>
              </div>

              {lomp_data
                ? Object.keys(lomp_data?.MiningProcessingMethods).map((key) => {
                    const data = lomp_data.MiningProcessingMethods[key].map(
                      (item, index) => (
                        <div className=" col-span-5 flex flex-row justify-between bg-slate-100 text-xs  w-full font-extralight  text-left px-1 py-1 align-middle">
                          <p className="text-left flex-1">
                            {index < 1 && item.metric_category}
                          </p>
                          {/* <p className='text-left flex-1'>{item.target_zone}</p> */}
                          <p
                            onClick={(e) => handleClick(e, item.id)}
                            className="text-left text-blue-500 font-semibold cursor-pointer flex-1"
                          >
                            {item.metric.toLocaleString()}{" "}
                            {item.uom !== "text" && item.uom}{" "}
                            {item.mineral !== "Combined" && item.mineral}
                          </p>
                        </div>
                      )
                    );
                    return data;
                  })
                : null}
            </div>
            <div className="grid gap-2 grid-cols-12">
              <div className="col-span-4 bg-white  ">
                <p className="tailwind-subtitle-black">Mining Metrics</p>
                <div className="w-full">
                  {lomp_data
                    ? Object.keys(lomp_data?.MiningMetrics).map((key) => {
                        const data = lomp_data.MiningMetrics[key].map(
                          (item, index) => (
                            <div className=" flex flex-row justify-between odd:bg-slate-100 text-xs  w-full font-extralight  text-left px-1 py-1 align-middle">
                              <p className="text-left flex-1">
                                {index < 1 && item.metric_category}
                              </p>
                              <p className="text-left flex-1">
                                {item.target_zone}
                              </p>
                              <p
                                onClick={(e) => handleClick(e, item.id)}
                                className="text-left  text-blue-500 font-semibold cursor-pointer"
                              >
                                {parseFloat(item.metric).toLocaleString()}{" "}
                                {item.uom !== "text" && item.uom}{" "}
                                {item.mineral !== "Combined" && item.mineral}
                              </p>
                            </div>
                          )
                        );
                        return data;
                      })
                    : null}
                </div>
              </div>

              <div className="col-span-4 bg-white  ">
                <p className="tailwind-subtitle-black">Processing Metrics</p>
                <div className="w-full">
                  {lomp_data
                    ? Object.keys(lomp_data?.ProcessingMetrics).map((key) => {
                        const data = lomp_data.ProcessingMetrics[key].map(
                          (item, index) => (
                            <div className=" flex flex-row justify-between odd:bg-slate-100 text-xs  w-full font-extralight  text-left px-1 py-1 align-middle">
                              <p className="text-left flex-1">
                                {index < 1 && item.metric_category}
                              </p>
                              <p className="text-left flex-1">
                                {item.target_zone}
                              </p>
                              <p
                                onClick={(e) => handleClick(e, item.id)}
                                className="text-left  text-blue-500 font-semibold cursor-pointer"
                              >
                                {parseFloat(item.metric).toLocaleString()}{" "}
                                {item.uom !== "text" && item.uom}{" "}
                                {item.mineral !== "Combined" && item.mineral}
                              </p>
                            </div>
                          )
                        );
                        return data;
                      })
                    : null}
                </div>
              </div>
              <div className="col-span-4 bg-white ">
                <div className="w-full">
                  <p className="tailwind-subtitle-black">Production Costs</p>
                  {lomp_data
                    ? Object.keys(lomp_data?.UnitOperatingCosts).map((key) => {
                        const data = lomp_data.UnitOperatingCosts[key].map(
                          (item, index) => (
                            <div className=" flex flex-row justify-between odd:bg-slate-100 text-xs  w-full font-extralight  text-left px-1 py-1 align-middle">
                              <p className="text-left flex-1">
                                {index < 1 && item.metric_category}
                              </p>
                              <p className="text-left flex-1">
                                {item.target_zone}
                              </p>
                              <p
                                onClick={(e) => handleClick(e, item.id)}
                                className="text-left text-blue-500 font-semibold cursor-pointer "
                              >
                                {parseFloat(item.metric).toLocaleString()}{" "}
                                {item.uom !== "text" && item.uom}{" "}
                                {item.mineral !== "Combined" && item.mineral}
                              </p>
                            </div>
                          )
                        );
                        return data;
                      })
                    : null}
                </div>

                <div className="w-full">
                  <p className="tailwind-subtitle-black">Production Costs</p>
                  {lomp_data
                    ? Object.keys(lomp_data?.ProductionCosts).map((key) => {
                        const data = lomp_data.ProductionCosts[key].map(
                          (item, index) => (
                            <div className=" flex flex-row justify-between odd:bg-slate-100 text-xs  w-full font-extralight  text-left px-1 py-1 align-middle">
                              <p className="text-left flex-1">
                                {index < 1 && item.metric_category}
                              </p>
                              <p className="text-left flex-1">
                                {item.target_zone}
                              </p>
                              <p
                                onClick={(e) => handleClick(e, item.id)}
                                className="text-left   text-blue-500 font-semibold cursor-pointer"
                              >
                                {parseFloat(item.metric).toLocaleString()}{" "}
                                {item.uom !== "text" && item.uom}{" "}
                                {item.mineral !== "Combined" && item.mineral}
                              </p>
                            </div>
                          )
                        );
                        return data;
                      })
                    : null}
                </div>
                <div className="w-full">
                  <p className="tailwind-subtitle-black">
                    Capital Expenditures
                  </p>
                  {lomp_data
                    ? Object.keys(lomp_data?.CapitalExpenditures).map((key) => {
                        const data = lomp_data.CapitalExpenditures[key].map(
                          (item, index) => (
                            <div className=" flex flex-row justify-between odd:bg-slate-100 text-xs  w-full font-extralight  text-left px-1 py-1 align-middle">
                              <p className="text-left flex-1">
                                {index < 1 && item.metric_category}
                              </p>
                              {/* <p className='text-left flex-1'>{item.target_zone}</p> */}
                              <p
                                onClick={(e) => handleClick(e, item.id)}
                                className="text-left text-blue-500 font-semibold cursor-pointer"
                              >
                                {parseFloat(item.metric).toLocaleString()}{" "}
                                {item.uom !== "text" && item.uom}{" "}
                                {item.mineral !== "Combined" && item.mineral}
                              </p>
                            </div>
                          )
                        );
                        return data;
                      })
                    : null}
                </div>
                <div className="w-full">
                  <p className="tailwind-subtitle-black">Royalties</p>
                  {lomp_data
                    ? Object.keys(lomp_data?.Royalties).map((key) => {
                        const data = lomp_data.Royalties[key].map(
                          (item, index) => (
                            <div className=" flex flex-row justify-between odd:bg-slate-100 text-xs  w-full font-extralight  text-left px-1 py-1 align-middle">
                              <p className="text-left flex-1">
                                {index < 1 && item.annotations}
                              </p>
                              {/* <p className='text-left flex-1'>{item.target_zone}</p> */}
                              <p
                                onClick={(e) => handleClick(e, item.id)}
                                className="text-left text-blue-500 font-semibold cursor-pointer"
                              >
                                {parseFloat(item.metric).toLocaleString()}{" "}
                                {item.uom !== "text" && item.uom}{" "}
                                {item.mineral !== "Combined" && item.mineral}
                              </p>
                            </div>
                          )
                        );
                        return data;
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  project: state.authReducer.selectedProject,
  project_reports_loading: state.authReducer.project_reports_loading,
  reports: state.authReducer.selectedCompany_reports,
  activeUser: state.authReducer.activeUser,
  user_follow_list: state.authReducer.lists,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LifeOfMine));
