import React, { useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts-fix";
import { apiRequest } from "../../../../../store/Actions/AuthActions";
import { nFormatter } from "../../../../../configure/helpers";
import _ from "lodash";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../../../assets/ProspectorDesignAssets/lottie2.json";
import ContentLoader from "react-content-loader";
// Resolves charts dependancy
charts(FusionCharts);

const Colunm = ({
  company,
  year,
  mineral,
  classification,
  production_basis,
  type,
  metric_name,
  metric_type,
}) => {
  const [dataSource, setdataSource] = useState(null);
  useEffect(() => {
    setdataSource(null);
    fetchData();
    return () => {};
  }, []);
  const persentile_calculation = (a, b) => {
    let value = (a / b) * 100;
    console.log(a, b, value);
    return value
      ? `top ${Math.ceil(value)}% of companies`
      : "company has no production data";
  };

  const fetchData = async () => {
    const data = await apiRequest
      .get(
        `/companies-${type}-ranking?production_basis=${production_basis}&mineral=${mineral}&year=${year}&metric_name=${metric_name}`
      )
      .catch((err) => console.log(err));
    if (data?.data?.metric) {
      const percentile = await apiRequest.get(
        `companies/${company.id}/annual-ranking-percentile?production_basis=${production_basis}&metric_name=${metric_name}&mineral=${mineral}&year=${year}`
      );
      if (percentile?.data?.data) {
        setdataSource({
          chart: {
            showYAxisValues: "0",
            showAlternateHGridColor: "0",
            divLineAlpha: "60",
            usePlotGradientColor: "0",
            showPlotBorder: "0",

            divLineColor: "#ffffff",
            showDivLineValues: "0",
            showaxislines: "0",
            showXAxisValues: "0",
            xaxisminvalue: "23",
            xaxismaxvalue: "95",
            ynumberprefix: "$",
            yaxisminvalue: "1200",
            xnumbersuffix: "°F",

            xAxisName: persentile_calculation(
              percentile.data.data.company_total,
              percentile.data.data.companies_total
            ),
            showBorder: "0",
            canvasborderColor: "#B9E1F4",
            canvasborderThicknes: "0.5",
            // showCanvasBorder: "0",
            canvasbgAlpha: "0",
            canvasbgcolor: "0",
            bgcolor: "#ffffff",
            color: "#000000",
            showValues: "0",
            palettecolors: "5d62b5",
          },
          data: _.orderBy(
            data.data.metric.map((item) => ({
              label: item.label,
              value: item.value,
              toolText: `${item.company_name} ${nFormatter(item.value, 1)}`,
              color: item.label === company.id ? "#FFAB05" : "#1A9BDB",
            })),
            "value",
            ["desc", "asc"]
          ),
        });
      } else {
        setdataSource({
          chart: {
            showYAxisValues: "0",
            showAlternateHGridColor: "0",
            divLineAlpha: "60",
            usePlotGradientColor: "0",
            showPlotBorder: "0",

            divLineColor: "#ffffff",
            showDivLineValues: "0",
            showaxislines: "0",
            showXAxisValues: "0",
            xaxisminvalue: "23",
            xaxismaxvalue: "95",
            ynumberprefix: "$",
            yaxisminvalue: "1200",
            xnumbersuffix: "°F",

            xAxisName: "company has no production data",
            showBorder: "0",
            // showCanvasBorder: "0",
            canvasborderColor: "#B9E1F4",
            canvasborderThicknes: "0.5",
            canvasbgAlpha: "0",
            canvasbgcolor: "0",
            bgcolor: "#ffffff",
            color: "#000000",
            showValues: "0",
            palettecolors: "5d62b5",
          },
          data: _.orderBy(
            data.data.metric.map((item) => ({
              label: item.label,
              value: item.value,
              toolText: `${item.company_name} ${nFormatter(item.value, 1)}`,
              color: item.label === company.id ? "#FFAB05" : "#1A9BDB",
            })),
            "value",
            ["desc", "asc"]
          ),
        });
      }
    }
  };

  return (
    <>
      {dataSource ? (
        <ReactFusioncharts
          type="column2d"
          width="100%"
          height="350"
          dataFormat="JSON"
          dataSource={dataSource}
        />
      ) : (
        <ContentLoader
          viewBox="0 0 600 350"
          style={{ width: "100%", height: "350px" }}
        >
          <rect
            x="20"
            y="8"
            rx="0"
            ry="0"
            style={{ width: "100%", height: "350px" }}
          />
        </ContentLoader>
      )}
    </>
  );
};

export default Colunm;
