import React, { useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts-fix";
import { apiRequest } from "../../../../../store/Actions/AuthActions";
import { data } from "autoprefixer";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../../../assets/ProspectorDesignAssets/lottie2.json";
import ContentLoader from "react-content-loader";
// Resolves charts dependancy
charts(FusionCharts);

const ColunmCombined = ({
  project,
  year,
  mineral,
  classification,
  production_basis,
  type,
  metric_name,
  quarter,
}) => {
  const [dataSource, setdataSource] = useState(null);
  useEffect(() => {
    setdataSource(null);
    fetchData();
    return () => {};
  }, []);

  const fetchData = async () => {
    const data = await apiRequest
      .get(
        `projects/${project.id}/quarterly-production-aisc-cashcost?production_basis=${production_basis}&mineral=${mineral}&year=${year}&metric_name=${metric_name}&quarter=${quarter}`
      )
      .catch((err) => console.log(err));
    if (data?.data?.categories) {
      setdataSource({
        chart: {
          xAxisPosition: "Top",

          formatnumberscale: "1",

          theme: "fusion",

          showAlternateHGridColor: "0",
          divLineAlpha: "60",
          usePlotGradientColor: "0",
          showPlotBorder: "0",

          divLineColor: "#ffffff",
          showLegend: "0",
          // showXAxisValues: "0",
          xaxisminvalue: "23",
          xaxismaxvalue: "95",
          ynumberprefix: "$",
          yaxisminvalue: "1200",
          xnumbersuffix: "°F",

          showBorder: "0",
          showCanvasBorder: "0",
          canvasbgAlpha: "0",
          canvasbgcolor: "0",
          bgcolor: "#ffffff",
          color: "#000000",
          showValues: 0,
          palettecolors: "#1A9BDB",
        },
        categories: data.data.categories.categories,
        dataset: data.data.categories.dataset,
      });
    }
  };
  return (
    <>
      {dataSource?.dataset[0].data?.length > 0 &&
      dataSource?.dataset[1]?.data?.length > 0 ? (
        <ReactFusioncharts
          type="mscolumn2d"
          width="100%"
          height="350"
          dataFormat="JSON"
          dataSource={dataSource}
        />
      ) : (
        <ContentLoader
          viewBox="0 0 600 350"
          style={{ width: "100%", height: "350px" }}
        >
          <rect
            x="20"
            y="8"
            rx="0"
            ry="0"
            style={{ width: "100%", height: "350px" }}
          />
        </ContentLoader>
      )}
    </>
  );
};

export default ColunmCombined;
