import moment from "moment";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { getFilingDetail, getReportDetail } from "../store/Actions/AuthActions";
import { Body1, Subtitle1 } from "./general";

export const NewsFiling = ({ type, filing, history }) => {
  const dispatch = useDispatch();
  const colors = {
    "Annual Update": "#F9F26E",
    "Quarterly Update": "#9F62EE",
    "Mineral Resource Update": "#199BDB",
    "Drilling Results": "#00AC69",
    "Technical Report": "#C1C4C4",
    "Press Release": "#343E3D",
  };

  const goToDetail = (id) => {
    if (type === "Technical Report") {
      dispatch(getReportDetail({ id: filing.id, history }));
    } else {
      dispatch(getFilingDetail({ id: filing.id, history }));
    }
  };
  return (
    <div
      className={`rounded w-full border-l-8 min-h-[70px] my-2 shadow-sm border-[1px] p-2 pl-8 cursor-pointer`}
      style={{ borderLeftColor: colors[type] }}
      onClick={goToDetail}
    >
      <div className="flex-boy-row">
        <Subtitle1 color="#C1C4C4">
          {filing.source || filing.filing_company}
        </Subtitle1>
        <Body1 color="#C1C4C4">
          {moment(filing.publication_date).format("DD MMM YYYY")}
        </Body1>
      </div>
      <Subtitle1>{filing.headline || filing.name}</Subtitle1>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewsFiling));
