import React, { useEffect, useState, useCallback } from "react";
import { Grid, Tabs, Tab, Popover, Checkbox } from "@material-ui/core";
import { Headline6, Subtitle1 } from "../../general";
import { SearchOutlined } from "@material-ui/icons";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import "./TableFilter.css";
import _ from "lodash";
import { CSVLink } from "react-csv";
import { apiRequest } from "../../../store/Actions/AuthActions";

export const TableFilter = ({
  filter,
  onClickDownloadPDF,
  onFilterChange,
  csvReport,
  type,
}) => {
  const [anchorElMyTag, setAnchorElMyTag] = useState(null);
  const [anchorElMyList, setAnchorElMyList] = useState(null);
  const [anchorElDownload, setAnchorElDownload] = useState(null);
  const openMyTagPopup = Boolean(anchorElMyTag);
  const openMyListPopup = Boolean(anchorElMyList);
  const openDownloadPopup = Boolean(anchorElDownload);

  const [textSearch, setTextSearch] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedLists, setSelectedLists] = useState([]);

  useEffect(() => {
    apiRequest.get(`${process.env.REACT_APP_ENDPOINT}/me/tags`).then((res) => {
      let tags = [];
      res?.data?.data?.map((tag) => {
        tags.push({
          name: tag.name,
          value: tag.items_count,
          selected: false,
        });
      });
      setSelectedTags(tags);
    });

    apiRequest
      .get(`${process.env.REACT_APP_ENDPOINT}/me/private-lists`)
      .then((res) => {
        let lists = [];
        res?.data?.data?.map((lst) => {
          lists.push({
            name: lst.name,
            selected: false,
          });
        });
        setSelectedLists(lists);
      });
  }, []);

  const handleClickMyTagBtn = (event) => {
    setAnchorElMyTag(event.currentTarget);
  };

  const handleCloseMyTagBtn = () => {
    setAnchorElMyTag(null);
  };

  const handleClickMyListBtn = (event) => {
    setAnchorElMyList(event.currentTarget);
  };

  const handleCloseMyListBtn = () => {
    setAnchorElMyList(null);
  };

  const handleClickDownloadBtn = (event) => {
    setAnchorElDownload(event.currentTarget);
  };

  const handleCloseDownloadBtn = () => {
    setAnchorElDownload(null);
  };

  const onConditionChange = (str, tg, ls) => {
    let tags = [];
    let inputTags = tg || selectedTags;
    let inputLists = ls || selectedLists;
    inputTags.map((t) => {
      if (t.selected) {
        tags.push(t.name);
      }
    });
    let lst = [];
    inputLists.map((t) => {
      if (t.selected) {
        lst.push(t.name);
      }
    });
    let condition = {
      text: str,
      tags: tags,
      lists: lst,
    };
    onFilterChange(condition);
  };

  const onMyTagClick = (event, tagName) => {
    let tag = selectedTags.find((t) => t.name == tagName);
    if (tag.selected) {
      tag.selected = false;
      event.target.style.setProperty("background-color", "white", "important");
    } else {
      tag.selected = true;
      event.target.style.backgroundColor = "#00ac69";
    }

    onConditionChange(textSearch);
  };

  const onMyListClick = (event, listName) => {
    let ls = selectedLists.find((l) => l.name == listName);
    ls.selected = event.target.checked;
    onConditionChange(textSearch);
  };

  const onTextSearch = (event) => {
    let val = event.target.value;
    setTextSearch(val);
    debouncedChangeHandler(val, selectedTags, selectedLists);
  };

  const debouncedChangeHandler = useCallback(
    _.debounce((val, selectedTags, selectedLists) => {
      onConditionChange(val, selectedTags, selectedLists);
    }, 1000),
    []
  );

  const handleClickDownloadPDF = () => {
    onClickDownloadPDF();
  };
  const capitalize = (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
  };
  return (
    <Grid>
      <div
        className="flex-boy-row flex-boy-row-prospect-header"
        style={{ justifyContent: "unset", float: "right" }}
      >
        <div className="flex-boy-row flex-boy-row-left">
          <input
            type="search"
            placeholder={`Search for ${capitalize(type)} by Name`}
            className="prospect-search-by-name"
            onChange={(e) => onTextSearch(e)}
          />
          <div className="prospect-search-icon">
            <SearchOutlined height="10px" />
          </div>
        </div>

        <button
          type="button"
          className={`filter-my-tags-btn ${
            openMyTagPopup && "filter-active-btn"
          }`}
          onClick={handleClickMyTagBtn}
        >
          My Tags
        </button>
        <button
          type="button"
          className={`filter-my-tags-btn filter-my-list-icon ${
            openMyListPopup && "filter-active-btn"
          }`}
          onClick={handleClickMyListBtn}
        >
          My Lists{" "}
        </button>
        {/* <button className="filter-sort-btn" />
        <TuneRoundedIcon
          className="filter-btn"
          style={{ color: "#00000060" }}
          // onClick={() => setFilterOpen(true)}
        /> */}
        {/* <IosShareRoundedIcon
          className={`filter-btn ${openDownloadPopup && "filter-active-btn"}`}
          style={{ color: "#00000060", cursor: "pointer" }}
          onClick={handleClickDownloadBtn}
        /> */}
      </div>
      <Popover
        id="myTagPopover"
        open={openMyTagPopup}
        anchorEl={anchorElMyTag}
        onClose={handleCloseMyTagBtn}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ width: "350px", padding: "10px 10px 10px 10px" }}>
          {selectedTags?.map((tag) => (
            <button
              className={`filter-my-tags-children-btn ${
                tag.selected && "filter-active-btn"
              }`}
              onClick={(e) => {
                onMyTagClick(e, tag.name);
              }}
            >
              {tag.name}({tag.value})
            </button>
          ))}
        </div>
      </Popover>
      <Popover
        id="myListPopover"
        open={openMyListPopup}
        anchorEl={anchorElMyList}
        onClose={handleCloseMyListBtn}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ padding: "10px", cursor: "pointer" }}>
          {selectedLists?.map((l) => (
            <div className="flex-boy-row" style={{ justifyContent: "normal" }}>
              <Checkbox
                style={{ color: "#00ac69" }}
                defaultChecked={l.selected}
                onClick={(e) => onMyListClick(e, l.name)}
              />
              <Subtitle1>{l.name}</Subtitle1>
            </div>
          ))}
        </div>
      </Popover>
      <Popover
        id="downloadPopover"
        open={openDownloadPopup}
        anchorEl={anchorElDownload}
        onClose={handleCloseDownloadBtn}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ width: "160px", padding: "5px 5px 5px 5px" }}>
          <button
            className={`filter-download-pdf-btn`}
            onClick={handleClickDownloadPDF}
          >
            {" "}
            Download to PDF
          </button>
          <button className="filter-download-csv-btn">
            <CSVLink {...csvReport}>Download to CSV</CSVLink>
          </button>
        </div>
      </Popover>
    </Grid>
  );
};
