/* eslint-disable no-unused-vars */
import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import {
  ButtonCircle,
  Body1,
  Subtitle1,
  Headline6,
  Subtitle2,
  Button,
  NotifyDiv,
  Headline5,
  Primary,
} from "./general";
import { TextField, InputAdornment, Popover, Input } from "@material-ui/core";
import moment from "moment";
import Avatar from "react-avatar";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import Autocomplete from "react-google-autocomplete";
import { usePlacesWidget } from "react-google-autocomplete";
import Modal from "@material-ui/core/Modal";
import {
  FormatAlignLeft,
  LocationOnOutlined,
  PeopleOutlineOutlined,
  PublicOutlined,
  AccessTime,
  KeyboardBackspace,
  Cancel,
} from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";
import { sendInvite } from "../store/Actions/AuthActions";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(4),
    width: 250,
  },
}));
export const RequestMeeting = ({
  activeUser,
  setRequest,
  prospects,
  auth,
  sendInvite,
}) => {
  let me = activeUser?.id;
  const inputRef = useRef(null);
  const classes = useStyles();
  const [selectedProspects, setProspects] = useState([]);
  const [popType, setPoptype] = useState(null);
  const [location, setLocation] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [startValue, onChangeStart] = useState(new Date());
  const [endValue, onChangeEnd] = useState(new Date());
  const [newMeeting, setMeeting] = useState({
    title: "",
    description: "A simple meet and greet",
    start_time: startValue,
    end_time: endValue,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    attendeeData: [],
    location: "",
  });
  const handleClick = (event, type) => {
    setPoptype(type);
    setAnchorEl(event.currentTarget);
  };

  const { ref: materialRef } = usePlacesWidget({
    onPlaceSelected: (place) => {
      setLocation(place.formatted_address);
    },
  });

  const handlepopClose = () => {
    setAnchorEl(null);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleInvite = () => {
    const invite = { ...newMeeting };
    invite.attendees = [
      me,
      ...newMeeting.attendeeData.map((item) => item.userId),
    ];
    let data = {
      title: invite.title,
      description: invite.description,
      start_time: moment(invite.start_time).format("YYYY-MM-DD HH:MM:ss"),
      end_time: moment(invite.end_time).format("YYYY-MM-DD HH:MM:ss"),
      invites: [me, ...newMeeting.attendeeData.map((item) => item.userId)],
      location: location,
    };

    sendInvite(data);
    setRequest(false);
  };
  const meetingDetails = (name, value) => {
    const meeting = { ...newMeeting };

    meeting[name] = value;

    setMeeting(meeting);
  };

  const handleselected = async (sku) => {
    const selected = [...selectedProspects];
    let index = selected.findIndex((item) => item.id === sku.id);
    if (index !== -1) {
      selected.splice(index, 1);
    } else {
      selected.push(sku);
    }
    setProspects(selected);
    let people = await selected.map((item) => ({
      name: item.name || item.email,
      status: item.status,
      userId: item.id,
    }));

    meetingDetails("attendeeData", people);
    // meetingDetails('attendee_names', selected.map(item => item.targetCustomer !== me ? item.targetUserData.displayName || item.targetUserData.email : item.requestingUserData.displayName || item.requestingUserData.email).toString())
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleStartDateChange = (date) => {
    onChangeStart(date.target.value);
  };
  const handleEndDateChange = (date) => {
    onChangeEnd(date.target.value);
  };

  return (
    <NotifyDiv style={{ width: "350px", height: "fit-content" }}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "middle",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {popType === "invites" ? (
          <div className="invitee-popover">
            <div style={{ padding: "10px" }} className="flex-boy-row">
              <KeyboardBackspace
                style={{ color: "#00000061" }}
                onClick={() => handlepopClose()}
              />
              <Subtitle1 color="#00000061">Search for People to add</Subtitle1>
              <Cancel
                fontSize="small"
                style={{ color: "#00000061" }}
                onClick={() => handlepopClose()}
              />
            </div>
            <div className="prospect-list">
              {prospects
                ? prospects.map((item, index) => {
                    return (
                      <div
                        className="flex-boy-row"
                        style={{
                          boxSizing: "border-box",
                          padding: "10px",
                          justifyContent: "normal",
                          backgroundColor: selectedProspects.some(
                            (e) => e.id === item.id
                          )
                            ? "gainsboro"
                            : "white",
                          cursor: "pointer",
                        }}
                        onClick={() => handleselected(item)}
                      >
                        <Avatar
                          name={item.name || item.email}
                          round
                          color={Primary}
                          size={50}
                        />
                        <Subtitle1 style={{ marginLeft: "5px" }}>
                          {item.name || item.email}
                        </Subtitle1>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        ) : popType === "time" ? (
          <div
            className="invitee-popover"
            style={{ width: "300px", padding: "10px" }}
          >
            <TextField
              id="datetime-local"
              label="Start time"
              type="datetime-local"
              defaultValue={moment(startValue).format("YYYY-MM-DDTHH:mm")}
              className={classes.textField}
              onChange={handleStartDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="datetime-local"
              label="End time"
              type="datetime-local"
              defaultValue={moment(endValue).format("YYYY-MM-DDTHH:mm")}
              className={classes.textField}
              onChange={handleEndDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        ) : null}
      </Popover>
      <Headline5 color={Primary}>Request Meeting</Headline5>
      <form style={{ width: "100%", margin: "20px 0 20px 0" }}>
        <TextField
          onChange={(e) => meetingDetails("title", e.target.value)}
          fullWidth
          id="meeting_title"
          name="title"
          placeholder="Add Meeting Title"
        />

        <div style={{ marginBottom: "10px", marginTop: "25px" }}>
          <div
            style={{
              justifyContent: "normal",
              marginTop: "10px",
              alignItems: "normal",
            }}
            className="flex-boy-row"
          >
            <AccessTime style={{ color: Primary }} />
            <div
              style={{ flex: 1, alignItems: "normal" }}
              className="flex-boy-column"
              onClick={(e) => handleClick(e, "time")}
            >
              <div style={{ marginBottom: "10px" }} className="flex-boy-row">
                <Subtitle1 style={{ marginLeft: "10px" }}>
                  {" "}
                  {moment(startValue).format("dddd, MMM DD, YYYY")}
                </Subtitle1>
                <Subtitle1 style={{ marginLeft: "10px" }}>
                  {moment(startValue).format("hh:mm A")}
                </Subtitle1>
              </div>
              <div className="flex-boy-row">
                <Subtitle1 style={{ marginLeft: "10px" }}>
                  {moment(endValue).format("dddd, MMM DD, YYYY")}
                </Subtitle1>
                <Subtitle1 style={{ marginLeft: "10px" }}>
                  {moment(endValue).format("hh:mm A")}
                </Subtitle1>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <div
            style={{ justifyContent: "normal", marginTop: "10px" }}
            className="flex-boy-row"
          >
            <PublicOutlined style={{ color: Primary }} />
            <Subtitle1 style={{ marginLeft: "10px" }}>
              {newMeeting.timezone}
            </Subtitle1>
          </div>
        </div>

        <div style={{ marginBottom: "20px", cursor: "pointer" }}>
          <div
            style={{ justifyContent: "normal", marginTop: "10px" }}
            className="flex-boy-row"
          >
            <PeopleOutlineOutlined style={{ color: Primary }} />
            <Subtitle1 style={{ marginLeft: "10px" }} color="#00000061">
              Invite People
            </Subtitle1>
          </div>
          <TextField
            disabled
            fullWidth
            id="meeting_invitees"
            value={newMeeting.attendeeData.map((item) => item.name).toString()}
            name="invitees"
            onClick={(e) => handleClick(e, "invites")}
          />
        </div>

        <div style={{ marginBottom: "20px" }}>
          <div
            style={{ justifyContent: "normal", marginTop: "10px" }}
            className="flex-boy-row"
          >
            <LocationOnOutlined style={{ color: Primary }} />
            <Subtitle1 style={{ marginLeft: "10px" }} color="#00000061">
              Location
            </Subtitle1>
          </div>
          <TextField
            fullWidth
            id="location"
            name="location"
            inputRef={materialRef}
          />
        </div>

        <div style={{ marginBottom: "10px" }}>
          <div
            style={{ justifyContent: "normal", marginTop: "10px" }}
            className="flex-boy-row"
          >
            <FormatAlignLeft style={{ color: Primary }} />
            <Subtitle1 style={{ marginLeft: "10px" }} color="#00000061">
              Description
            </Subtitle1>
          </div>
          <TextField
            fullWidth
            onChange={(e) => meetingDetails("description", e.target.value)}
            multiline
            id="meeting_description"
            name="description"
          />
        </div>
      </form>
      <Button onClick={() => handleInvite()}>Send Invite</Button>
    </NotifyDiv>
  );
};

const mapStateToProps = (state) => ({
  prospects: state.authReducer.prospects,
  auth: state.firebase.auth,
  activeUser: state.authReducer.activeUser,
});

const mapDispatchToProps = (dispatch) => ({
  sendInvite: (data) => dispatch(sendInvite(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestMeeting);
