import React from "react";
import { Grid, Button, TextField, InputAdornment } from "@material-ui/core";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { MuiRail, MuiHandle, MuiTrack, MuiTick } from "./component";
import BarChart from "./BarChart";
import "./RangeSlider.css";
import axios from "axios";

const mineralToShowSlider = [
  "Gold",
  "Silver",
  "Copper",
  "Zinc",
  "Lead",
  "Aluminum",
  "Iron",
  "Platinum",
  "Palladium",
  "Lithium",
];
class RangeSlider extends React.Component {
  constructor(props) {
    super(props);

    const sortedData = props.data.slice().sort((a, b) => a - b);
    const range = [sortedData[0], sortedData[sortedData.length - 1]];
    const mineral = this.props.mineral;
    const index = this.props.index;
    this.onChangeGradeSlider = this.onChangeGradeSlider.bind(this);
    let isDisplay = false;
    mineralToShowSlider.map((i) => {
      if (i === mineral?.name) {
        isDisplay = true;
      }
    });

    this.state = {
      domain: range,
      update: range,
      values: range,
      valuesFetched: false,
      gradeValues: [0, 10],
      tonnageValues: [0, 10],
      inputValues: range,
      mineral,
      grade: {},
      tonnage: {},
      index,
      isDisplay,
    };

    this.getTonnageAndGrade(mineral);
  }
  onChangeGradeSliderInput(index, value) {
    console.log(index, value, this.state.gradeValues);
    let values = [
      index === 0 ? parseFloat(value) : this.state.gradeValues[0],
      index === 1 ? parseFloat(value) : this.state.gradeValues[1],
    ];
    console.log(values);
    this.onChangeGradeSlider(values);
    this.setState({ ...this.state, gradeValues: [...values] }, () =>
      console.log(this.state.gradeValues)
    );

    let resource_measurements = [
      {
        mineral: this.state.mineral?.chemical_symbol,
        grades: [...values],
        tonnages: [this.state.tonnageValues[0], this.state.tonnageValues[1]],
      },
    ];

    this.buildFilterString(resource_measurements);
  }

  onChangeGradeSlider(values) {
    if (!this.isEqual(values, this.state.gradeValues)) {
      this.setState({ ...this.state, gradeValues: values });
    }

    let resource_measurements = [
      {
        mineral: this.state.mineral?.chemical_symbol,
        grades: [values[0], values[1]],
        tonnages: [this.state.tonnageValues[0], this.state.tonnageValues[1]],
      },
    ];

    this.buildFilterString(resource_measurements);
  }

  buildFilterString(resource_measurements) {
    for (var i = 0; i < resource_measurements.length; i++) {
      let params =
        encodeURIComponent(`resource_measurements[${i}][mineral]`) +
        `=${this.state.mineral?.chemical_symbol}`;
      params =
        params +
        `&` +
        encodeURIComponent(`resource_measurements[${i}][grades][0]`) +
        `=${resource_measurements[i].grades[0]}`;
      params =
        params +
        `&` +
        encodeURIComponent(`resource_measurements[${i}][grades][1]`) +
        `=${resource_measurements[i].grades[1]}`;
      params =
        params +
        `&` +
        encodeURIComponent(`resource_measurements[${i}][tonnage][0]`) +
        `=${resource_measurements[i].tonnages[0]}`;
      params =
        params +
        `&` +
        encodeURIComponent(`resource_measurements[${i}][tonnage][1]`) +
        `=${resource_measurements[i].tonnages[1]}`;
      this.props.updateSingleFilterKey("resource_measurements", params);
    }
  }

  onChangeTonnageSliderInput(index, value) {
    console.log(index, value);
    let values = [
      index === 0 ? value : this.state.tonnageValues[0],
      index === 1 ? value : this.state.tonnageValues[1],
    ];
    this.onChangeTonnageSlider(values);

    this.setState({ ...this.state, tonnageValues: values });

    let resource_measurements = [
      {
        mineral: this.state.mineral?.chemical_symbol,
        grades: [this.state.gradeValues[0], this.state.gradeValues[1]],
        tonnages: [
          index === 0 ? value : this.state.tonnageValues[0],
          index === 1 ? value : this.state.tonnageValues[1],
        ],
      },
    ];

    this.buildFilterString(resource_measurements);
  }

  onChangeTonnageSlider(values) {
    console.log(values);
    if (!this.isEqual(values, this.state.tonnageValues)) {
      this.setState({ ...this.state, tonnageValues: values });
    }

    let resource_measurements = [
      {
        mineral: this.state.mineral?.chemical_symbol,
        grades: [this.state.gradeValues[0], this.state.gradeValues[1]],
        tonnages: [values[0], values[1]],
      },
    ];

    this.buildFilterString(resource_measurements);
  }

  getTonnageAndGrade(mineral) {
    axios
      .get(
        `${process.env.REACT_APP_ENDPOINT}/mrr/tonnage-histogram?mineral=${mineral?.chemical_symbol}`
      )
      .then((res) => {
        let bars = [];
        res.data.data.map((d) => bars.push(d.projects_count));
        this.setState({
          ...this.state,
          tonnage: {
            data: res.data,
            bars,
            domain: [res.data.min, res.data.max],
          },
          tonnageValues: [res.data.min, res.data.max],
          valuesFetched: true,
        });
      });
    axios
      .get(
        `${process.env.REACT_APP_ENDPOINT}/mrr/avg-grade-histogram?mineral=${mineral?.chemical_symbol}`
      )
      .then((res) => {
        let bars = [];
        res.data.data.map((d) => bars.push(d.projects_count));
        this.setState({
          ...this.state,
          grade: { data: res.data, bars, domain: [res.data.min, res.data.max] },
          gradeValues: [res.data.min, res.data.max],
        });
      })
      .catch((err) => {
        const sortedData = this.props.data.slice().sort((a, b) => a - b);
        const range = [sortedData[0], sortedData[sortedData.length - 1]];
        const mineral = this.props.mineral;
        const index = this.props.index;
        this.setState({
          domain: range,
          update: range,
          values: range,
          gradeValues: [0, 10],
          tonnageValues: [0, 10],
          inputValues: range,
          mineral,
          grade: {},
          tonnage: {},
          index,
        });
      });
  }

  isEqual(a, b) {
    // If length is not equal
    if (a.length != b.length) return false;
    else {
      // Comparing each element of array
      for (var i = 0; i < a.length; i++) {
        if (!isNaN(a[i]) && a[i] != b[i]) return false;
      }
      return true;
    }
  }

  componentDidUpdate() {
    const mineral = this.props.mineral;
    let isDisplay = false;
    mineralToShowSlider.map((i) => {
      if (i === mineral?.name) {
        isDisplay = true;
      }
    });
    if (this.state.mineral?.name != mineral?.name) {
      this.setState({ ...this.state, mineral, isDisplay });
      this.getTonnageAndGrade(mineral);
    }
  }

  render() {
    const {
      domain,
      values,
      update,
      isDisplay,
      mineral,
      grade,
      gradeValues,
      tonnage,
      tonnageValues,
    } = this.state;

    return (
      isDisplay &&
      this.state.valuesFetched && (
        <Grid
          id="ranger"
          container
          style={{ flexDirection: "row-reverse", width: "100%" }}
        >
          <div>
            <Grid item style={{ display: "flex", marginBottom: "25px" }}>
              <div style={{ marginRight: "0px" }}>
                <div
                  style={{
                    fontFamily: "Gotham-Bold",
                    color: "#00000099",
                    fontSize: "12px",
                    width: "90px",
                    textAlign: "left",
                  }}
                >
                  Min Grade
                </div>
                <div
                  className="min-max-label "
                  style={{
                    marginRight: "10px",

                    paddingTop: "3px",
                  }}
                >
                  <TextField
                    onChange={(e) =>
                      this.onChangeGradeSliderInput(0, e.target.value)
                    }
                    fullWidth
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {grade?.data?.uom}
                        </InputAdornment>
                      ),
                      style: { fontSize: 10 },
                    }}
                    id="meeting_title"
                    name="title"
                    defaultValue={this.state.gradeValues[0]}
                    value={this.state.gradeValues[0]}
                    placeholder="Add Meeting Title"
                  />
                </div>
              </div>
              <div>
                <BarChart
                  data={grade?.bars}
                  // data={this.props.data}
                  highlight={gradeValues}
                  domain={grade?.domain}
                />
                <Slider
                  onSlideStart={(values) => console.log(values)}
                  mode={3}
                  step={1}
                  domain={grade?.domain}
                  rootStyle={{
                    position: "relative",
                    width: "100%",
                  }}
                  // onUpdate={(update) =>
                  //   this.setState({ update, inputValues: update })
                  // }
                  onChange={(values) => this.onChangeGradeSlider(values)}
                  values={this.state.gradeValues}
                >
                  <Rail>
                    {({ getRailProps }) => (
                      <MuiRail getRailProps={getRailProps} />
                    )}
                  </Rail>
                  <Handles>
                    {({ handles, getHandleProps }) => (
                      <div className="slider-handles">
                        {handles.map((handle) => (
                          <MuiHandle
                            key={handle.id}
                            handle={handle}
                            domain={domain}
                            getHandleProps={getHandleProps}
                          />
                        ))}
                      </div>
                    )}
                  </Handles>
                  <Tracks left={false} right={false}>
                    {({ tracks, getTrackProps }) => (
                      <div className="slider-tracks">
                        {tracks.map(({ id, source, target }) => (
                          <MuiTrack
                            key={id}
                            source={source}
                            target={target}
                            getTrackProps={getTrackProps}
                          />
                        ))}
                      </div>
                    )}
                  </Tracks>
                  {/* <Ticks count={5}>
                {({ ticks }) => (
                  <div className="slider-ticks">
                    {ticks.map(tick => (
                      <MuiTick key={tick.id} tick={tick} count={ticks.length} />
                    ))}
                  </div>
                )}
              </Ticks> */}
                </Slider>
              </div>
              <div style={{ marginLeft: "0px" }}>
                <div
                  style={{
                    fontFamily: "Gotham-Bold",
                    color: "#00000099",
                    fontSize: "12px",
                    width: "90px",
                    textAlign: "right",
                  }}
                >
                  Max Grade
                </div>
                <div className="min-max-label" style={{ marginLeft: "10px" }}>
                  <TextField
                    onChange={(e) =>
                      this.onChangeGradeSliderInput(0, e.target.value)
                    }
                    fullWidth
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {grade?.data?.uom}
                        </InputAdornment>
                      ),
                      style: { fontSize: 10 },
                    }}
                    id="meeting_title"
                    name="title"
                    defaultValue={this.state.gradeValues[1]}
                    value={this.state.gradeValues[1]}
                    // placeholder="Add Meeting Title"
                  />
                  {/* {gradeValues ? gradeValues[1] : 0} {grade?.data?.uom} */}
                </div>
              </div>
            </Grid>

            <Grid item style={{ display: "flex", marginBottom: "20px" }}>
              <div style={{ marginRight: "0px" }}>
                <div
                  style={{
                    fontFamily: "Gotham-Bold",
                    color: "#00000099",
                    fontSize: "12px",
                    width: "90px",
                    textAlign: "left",
                  }}
                >
                  Min Tonnage
                </div>
                <div
                  style={{ marginRight: "10px", paddingTop: "3px" }}
                  className="min-max-label"
                >
                  <TextField
                    onChange={(e) =>
                      this.onChangeTonnageSliderInput(0, e.target.value)
                    }
                    fullWidth
                    disabled
                    id="meeting_title"
                    name="title"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {tonnage?.data?.uom}
                        </InputAdornment>
                      ),
                      style: { fontSize: 10 },
                    }}
                    defaultValue={this.state.tonnageValues[0]}
                    value={this.state.tonnageValues[0]}
                    placeholder="Add Meeting Title"
                  />
                  {/* {tonnageValues
                  ? new Intl.NumberFormat("en-US", {
                    notation: "compact",
                  }).format(tonnageValues[0])
                  : 0}{" "} */}
                </div>
              </div>
              <div>
                <BarChart
                  data={tonnage?.bars}
                  // data={this.props.data}
                  highlight={tonnageValues}
                  domain={tonnage?.domain}
                />
                <Slider
                  mode={3}
                  step={1}
                  domain={tonnage.domain}
                  rootStyle={{
                    position: "relative",
                    width: "100%",
                  }}
                  // onUpdate={(update) =>
                  //   this.setState({ update, inputValues: update })
                  // }
                  onChange={(values) => this.onChangeTonnageSlider(values)}
                  values={tonnageValues}
                >
                  <Rail>
                    {({ getRailProps }) => (
                      <MuiRail getRailProps={getRailProps} />
                    )}
                  </Rail>
                  <Handles>
                    {({ handles, getHandleProps }) => (
                      <div className="slider-handles">
                        {handles.map((handle) => (
                          <MuiHandle
                            key={handle.id}
                            handle={handle}
                            domain={domain}
                            getHandleProps={getHandleProps}
                          />
                        ))}
                      </div>
                    )}
                  </Handles>
                  <Tracks left={false} right={false}>
                    {({ tracks, getTrackProps }) => (
                      <div className="slider-tracks">
                        {tracks.map(({ id, source, target }) => (
                          <MuiTrack
                            key={id}
                            source={source}
                            target={target}
                            getTrackProps={getTrackProps}
                          />
                        ))}
                      </div>
                    )}
                  </Tracks>
                  {/* <Ticks count={5}>
                {({ ticks }) => (
                  <div className="slider-ticks">
                    {ticks.map(tick => (
                      <MuiTick key={tick.id} tick={tick} count={ticks.length} />
                    ))}
                  </div>
                )}
              </Ticks> */}
                </Slider>
              </div>
              <div style={{ marginLeft: "0px" }}>
                <div
                  style={{
                    fontFamily: "Gotham-Bold",
                    color: "#00000099",
                    fontSize: "12px",
                    width: "90px",
                    textAlign: "right",
                  }}
                >
                  Max Tonnage
                </div>
                <div className="min-max-label" style={{ marginLeft: "10px" }}>
                  <TextField
                    onChange={(e) =>
                      this.onChangeTonnageSliderInput(1, e.target.value)
                    }
                    fullWidth
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {tonnage?.data?.uom}
                        </InputAdornment>
                      ),
                      style: { fontSize: 10 },
                    }}
                    defaultValue={this.state.tonnageValues[1]}
                    value={this.state.tonnageValues[1]}
                  />

                  {/* {tonnageValues
                  ? new Intl.NumberFormat("en-US", {
                    notation: "compact",
                  }).format(tonnageValues[1])
                  : 10}{" "} */}
                </div>
              </div>
            </Grid>
          </div>
          <div className="chemical-symbol" key={this.props.mineral}>
            {mineral?.chemical_symbol}
          </div>
        </Grid>
      )
    );
  }
}

export default RangeSlider;
