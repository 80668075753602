/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Primary,
  Headline5,
  Button,
  Subtitle1,
  Subtitle2,
  BrowseListFilter,
  NotifyDiv,
} from "../general";
import CloseIcon from "@material-ui/icons/Close";
import {
  searchAlgoliaCompanies,
  filterProjects,
  filterCompanies,
} from "../../store/Actions/AuthActions";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import axios from "axios";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 100,
  },
}));
const continents = [
  "Asia",
  "North America",

  "Latin America",
  "Africa",
  "Antarctica",
  "Europe",
  "Oceania",
];
export const FilterContent = ({
  setCompanyPage,
  projects_length,
  companies_length,
  setProjectPage,
  projects,
  companies,
  searchAlgoliaCompanies,
  auth,
  tab,
  handleClose,
  filterProjects,
  setProjectFilters,
  setCompanyFilters,
  project_filters,
  company_filters,
  filterCompanies,
}) => {
  let me = auth.uid;
  const [value, setValue] = React.useState([20, 37]);
  const [extraMinerals, setMinerals] = useState([]);
  const [extraRegions, setRegions] = useState([]);
  const [showextraRegions, showRegions] = useState(false);
  const [showextraMinerals, showMinerals] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    getFilterInfo();
    if (tab === "projects") {
      let fils = project_filters.mineral?.map((tag) => ({
        value: tag,
        label: tag,
      }));

      updateFilters(
        project_filters || {
          mineral: null,
          start_after: null,
          searchTerm: null,
          regions: null,
          countries: null,
          stage: null,
          resourceType: null,
          averageGrade_min: 0,
          averageGrade_max: 0,
          oreTonnage_min: 0,
          oreTonnage_max: 0,
          containedMetal_min: 0,
          containedMetal_max: 0,
        }
      );
      // search_projects({loc:0,newFilters:filters})
    } else {
      updateFilters(
        company_filters || {
          mineral: null,
          start_after: null,
          searchTerm: null,
          regions: null,
          countries: null,
          stage: null,
          resourceType: null,
          averageGrade_min: 0,
          averageGrade_max: 0,
          oreTonnage_min: 0,
          oreTonnage_max: 0,
          containedMetal_min: 0,
          containedMetal_max: 0,
        }
      );
      // search_companies({loc:0,newFilters:filters})
    }
    return () => {};
  }, []);

  const getFilterInfo = async () => {
    const countries_data = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/config/countries`
    );
    const minerals_data = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/config/minerals`
    );

    if (countries_data) {
      setRegions([...continents, ...countries_data.data.data.sort()]);
    }
    if (minerals_data) {
      setMinerals([...new Set(minerals_data.data.data)]);
    }
  };
  const [filters, updateFilters] = useState({
    mineral: null,
    start_after: null,
    searchTerm: null,
    regions: null,
    countries: null,
    resourceType: null,
    averageGrade_min: 0,
    averageGrade_max: 0,
    oreTonnage_min: 0,
    oreTonnage_max: 0,
    containedMetal_min: 0,
    containedMetal_max: 0,
  });
  const setFilters = (filters) => {
    updateFilters(filters);
  };

  // const companiesIndex = searchClient.initIndex('dev_companies_idx')
  // const projectsIndex = searchClient.initIndex('dev_projects_idx')
  // let company_hit_list = []
  // let project_hit_list = []

  const search_companies = ({ loc, newFilters }) => {
    setCompanyPage(0);
    filterCompanies();
  };
  const search_projects = ({ loc, newFilters }) => {
    setProjectPage(0);
    filterProjects();
  };
  function numFormatter(num) {
    if (num > 999) {
      return (num / 1000000).toFixed(1) + " Million"; // convert to M for number from > 1 million
    } else {
      return num; // if value < 1000, nothing to do
    }
  }

  const updateSlider = (name, val) => {
    let newFilters = { ...filters };
    newFilters[`${name}_min`] = val[0] * 1000000;
    newFilters[`${name}_max`] = val[1] * 1000000;
    setFilters(newFilters);
    if (tab === "projects") {
      setProjectFilters(newFilters);
      search_projects({ loc: 0, newFilters });
    } else {
      setCompanyFilters(newFilters);
      search_companies({ loc: 0, newFilters });
    }
  };
  const updateAverageGrade = (name, val) => {
    let newFilters = { ...filters };
    newFilters[`${name}_min`] = val[0];
    newFilters[`${name}_max`] = val[1];

    setFilters(newFilters);
    if (tab === "projects") {
      setProjectFilters(newFilters);
      search_projects({ loc: 0, newFilters });
    } else {
      setCompanyFilters(newFilters);
      search_companies({ loc: 0, newFilters });
    }
  };
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const resTypes = [
    {
      name: "Indicated Mineral Resource",
      value: 85,
      indicator: "indicated",
    },
    {
      name: "Inferred Mineral Resource",
      value: 84,
      indicator: "inferred",
    },
    {
      name: "Measured Mineral Resource",
      value: 86,
      indicator: "measured",
    },
    {
      name: "Not Mentioned",
      value: 89,
      indicator: "not mentioned",
    },
    {
      name: "Probable Mineral Reserve",
      value: 87,
      indicator: "probable",
    },

    {
      name: "Proven Mineral Reserve",
      value: 88,
      indicator: "proven",
    },
  ];
  const stages = [
    "Discovery Delineation",
    "Grassroots",
    "Production",
    "Target Drilling",
    "Prefeasibility",
    "PEA",
    "Permitting & Feasibility",
    "Construction",
    "Suspended",
    // "Permiting & Feasibility",
    // "Exploration",
  ];

  const filterMinerals = [
    "cobalt",
    "copper",
    "gold",
    "rare earth elements",
    "lithium",
    "silver",
    "nickel",
    "palladium",
  ];
  const updateSingleFilterKey = (name, value) => {
    let newFilters = { ...filters };

    if (JSON.stringify(newFilters[name]) === JSON.stringify(value)) {
      newFilters[name] = null;
      setFilters(newFilters);
      if (tab === "projects") {
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
    } else {
      newFilters[name] = value;
      setFilters(newFilters);
      if (tab === "projects") {
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
    }
  };
  const updateSingleFilterKeynonselect = (name, value) => {
    let newFilters = { ...filters };

    if (newFilters[name] && newFilters[name].length) {
      let index = newFilters[name].indexOf(value);
      if (index !== -1) {
        newFilters[name].splice(index, 1);
        setFilters(newFilters);
        if (tab === "projects") {
          setProjectFilters(newFilters);
          search_projects({ loc: 0, newFilters });
        } else {
          setCompanyFilters(newFilters);
          search_companies({ loc: 0, newFilters });
        }
      } else {
        newFilters[name].push(value);
        setFilters(newFilters);
        if (tab === "projects") {
          setProjectFilters(newFilters);
          search_projects({ loc: 0, newFilters });
        } else {
          setCompanyFilters(newFilters);
          search_companies({ loc: 0, newFilters });
        }
      }
    } else {
      newFilters[name] = [value];
      setFilters(newFilters);
      if (tab === "projects") {
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
    }
  };
  const clearFilters = () => {
    let newFilters = {
      mineral: null,
      start_after: null,
      searchTerm: null,
      countries: null,
      resourceType: null,
      averageGrade_min: 0,
      averageGrade_max: 0,
      oreTonnage_min: 0,
      oreTonnage_max: 0,
      containedMetal_min: 0,
      containedMetal_max: 0,
    };
    if (tab === "projects") {
      setProjectFilters(newFilters);
      search_projects({ loc: 0, newFilters });
      updateFilters(newFilters);
      // handleClose()
    } else {
      setCompanyFilters(newFilters);
      search_companies({ loc: 0, newFilters });
      setFilters(newFilters);

      updateFilters(newFilters);
      // handleClose()
    }
  };

  return (
    <NotifyDiv style={{ width: "400px", padding: 0 }}>
      <div
        className="flex-boy-row"
        style={{
          width: "100%",
          justifyContent: "normal",
          padding: "20px",
          boxSizing: "border-box",
          borderBottom: "1px solid grey",
        }}
      >
        <CloseIcon onClick={handleClose} />
        <Headline5 style={{ flex: 1 }} color={Primary}>
          Apply Filters
        </Headline5>
      </div>
      <div
        className="flex-boy-column"
        style={{
          padding: "10px",
          flex: 1,
          justifyContent: "normal",
          overflowY: "scroll",
        }}
      >
        <div
          className="rangeFilterContainer"
          style={{ textAlign: "left", marginBottom: "20px" }}
        >
          <Subtitle1 style={{ margin: "10px" }}>
            Mineral Resource Type
          </Subtitle1>
          <div
            className="flex-boy-row"
            style={{ flexWrap: "wrap", justifyContent: "normal" }}
          >
            {resTypes.map((key, index) => {
              return (
                <BrowseListFilter
                  className={
                    filters.resourceType &&
                    filters.resourceType.includes(key.name)
                      ? "active-fiter-item"
                      : null
                  }
                  onClick={() =>
                    updateSingleFilterKeynonselect("resourceType", key.name)
                  }
                  key={index}
                >
                  <Subtitle2
                    style={{ margin: "5px", textTransform: "capitalize" }}
                  >
                    {key.indicator.replace("_", " ")}
                  </Subtitle2>
                </BrowseListFilter>
              );
            })}
          </div>
        </div>
        {tab === "projects" ? (
          <div
            className="rangeFilterContainer"
            style={{ textAlign: "left", marginBottom: "20px" }}
          >
            <Subtitle1 style={{ margin: "10px" }}>Stages</Subtitle1>
            <div
              className="flex-boy-row"
              style={{ flexWrap: "wrap", justifyContent: "normal" }}
            >
              {stages.map((key, index) => {
                return (
                  <BrowseListFilter
                    className={
                      filters.stage && filters.stage.includes(key)
                        ? "active-fiter-item"
                        : null
                    }
                    onClick={() => updateSingleFilterKeynonselect("stage", key)}
                    key={index}
                  >
                    <Subtitle2
                      style={{ margin: "5px", textTransform: "capitalize" }}
                    >
                      {key.replace("_", " ")}
                    </Subtitle2>
                  </BrowseListFilter>
                );
              })}
            </div>
          </div>
        ) : null}
        <div
          className="rangeFilterContainer"
          style={{
            textAlign: "left",
            padding: "10px",
            width: "100%",
            boxSizing: "border-box",
            marginBottom: "20px",
          }}
        >
          <Subtitle1 style={{ margin: "10px" }}>
            Regions and Countries
          </Subtitle1>

          <Select
            isMulti
            value={
              filters?.countries
                ?.filter((tag) => tag !== "South america")
                .map((tag) => ({ value: tag, label: tag })) || ""
            }
            style={{ width: "100%" }}
            onChange={(e) =>
              updateSingleFilterKey(
                "countries",
                e.map((item) => item.value)
              )
            }
            placeholder={"Search for region or country"}
            options={extraRegions.map((tag, index) => ({
              value: tag,
              label: tag.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase()
              ),
            }))}
          />
          {/* <Subtitle1 onClick={() => showRegions(!showextraRegions)} color={Primary} style={{ margin: '10px', cursor: 'pointer' }}>MORE</Subtitle1> */}
        </div>
        <div
          className="rangeFilterContainer"
          style={{
            textAlign: "left",
            padding: "10px",
            width: "100%",
            boxSizing: "border-box",
            marginBottom: "20px",
          }}
        >
          <Subtitle1 style={{ margin: "10px" }}>Minerals</Subtitle1>

          <Select
            isMulti
            displayEmpty
            value={
              filters?.mineral?.map((tag) => ({ value: tag, label: tag })) || ""
            }
            style={{ width: "100%" }}
            MenuProps={{ classes: { paper: classes.menuPaper } }}
            onChange={(e) =>
              updateSingleFilterKey(
                "mineral",
                e.map((item) => item.value)
              )
            }
            placeholder={"Search for mineral"}
            options={extraMinerals.map((tag, index) => ({
              value: tag.trim(),
              label: tag,
            }))}
          />
          {/* <Subtitle1 onClick={() => showMinerals(!showextraMinerals)} color={Primary} style={{ margin: '10px', cursor: 'pointer' }}>MORE</Subtitle1> */}
        </div>
      </div>
      <div
        className="flex-boy-row"
        style={{
          height: "75px",
          width: "100%",
          padding: "20px",
          boxSizing: "border-box",
          borderTop: "1px solid grey",
        }}
      >
        <Button
          className="hover-sub"
          onClick={clearFilters}
          color="white"
          style={{ cursor: "pointer", padding: "15px" }}
        >
          Clear filters
        </Button>
        <Button onClick={handleClose}>
          {tab === "projects" ? projects_length : companies_length} {tab}
        </Button>
      </div>
    </NotifyDiv>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  project_filters: state.authReducer.projects_search_filters,
  companies_search: state.authReducer.companies_search || [],
  projects: state.authReducer.projects_search || [],
  company_filters: state.authReducer.company_search_filters,
  projects_length: state.authReducer.filtered_projects_length,
  companies_length: state.authReducer.filtered_companies_length,
});

const mapDispatchToProps = (dispatch) => ({
  searchAlgoliaCompanies: (data) => dispatch(searchAlgoliaCompanies(data)),
  filterProjects: (data) => dispatch(filterProjects(data)),
  filterCompanies: (data) => dispatch(filterCompanies(data)),
  setCompanyPage: (data) =>
    dispatch({ type: "SET_COMPANIES_PAGE", payload: data }),
  setProjectPage: (data) =>
    dispatch({ type: "SET_PROJECTS_PAGE", payload: data }),
  setProjectFilters: (data) =>
    dispatch({ type: "SET_PROJECT_FILTERS", payload: data }),
  setCompanyFilters: (data) =>
    dispatch({ type: "SET_COMPANY_FILTERS", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterContent);
