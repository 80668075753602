/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { Subtitle1, Body2, Hexagon, GreyBox } from "../general";
import SparkLine from "../SparkLine";
import Paper from "@material-ui/core/Paper";
import { getProspects, selectTags } from "../../store/Actions/AuthActions";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import { Primary } from "..";
import { withRouter } from "react-router";
import axios from "axios";
import { set } from "date-fns";
import Select from "react-select";
import { Field, Label } from "@headlessui/react";
import Switch from "@mui/material/Switch";
import Slider from "@mui/material/Slider";

import Button from "@mui/material/Button";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import debounce from "lodash.debounce";
const dummy_data = {
  lists: [
    {
      name: "Copper Investments",
      number: 3,
    },
    {
      name: "PDAC 2020 Attendees",
      number: 3,
    },
  ],
  following: [
    {
      name: "Canada",
      number: 300,
    },
    {
      name: "Gold",
      number: 175,
    },
    {
      name: "Silver",
      number: 56,
    },
  ],
  matches: [
    {
      ticker: "PMD:CVE",
      name: "Palladium One Mining Inc.",
      projects: 1,
      minerals: ["Au", "Pd", "Pt", "Ni", "Cu"],
    },
    {
      ticker: "RDG:CVE",
      name: "Ridgeline Minerals Corp.",
      projects: 1,
      minerals: ["Au"],
    },
  ],
};

const customStyles = {
  control: (base) => ({
    ...base,

    minHeight: "50px",
    maxHeight: "80px",
    overflowY: "auto",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
    };
  },
};

const stages = [
  "All",
  "Discovery Delineation",
  "Grassroots",
  "Production",
  "Target Drilling",
  "Prefeasibility",
  "PEA",
  "Permitting & Feasibility",
  "Construction",
  "Suspended",
  // "Permiting & Feasibility",
  // "Exploration",
];

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export const SummaryAccordion = ({
  lists,
  prospects,
  getProspects,
  me,
  tags,
  selectedTags,
  history,
  selectedStage,
  selectedType,
  handleStageChange,
  handleTypeChange,
  browse_page_tab,
  drilling_filters,
  setDrillingFilters,
  drilling_minerals,
}) => {
  const [expanded, setExpanded] = React.useState([
    "panel1",
    "panel2",
    "panel3",
  ]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (me) {
      getProspects();
    }

    return () => {};
  }, [getProspects, me]);
  useEffect(() => {
    history.listen(removeTags);
    return () => {};
  }, []);
  const removeTags = () => {
    let isProspects = history.location.pathname.includes("prospects");
    if (!isProspects) {
      dispatch({ type: "SET_SELECTED_TAGS", payload: [] });
    }
  };
  const handleChange = (panel) => (event, newExpanded) => {
    const selected = [...expanded];
    let index = selected.indexOf(panel);
    if (index !== -1) {
      selected.splice(index, 1);
    } else {
      selected.push(panel);
    }

    setExpanded(selected);
  };

  useEffect(() => {
    getFilterInfo();
  }, []);

  const [regions, setRegions] = React.useState([]);
  const [minerals, setMinerals] = React.useState([]);

  const getFilterInfo = async () => {
    const countries_data = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/config/countries`
    );
    const minerals_data = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/config/minerals`
    );

    if (countries_data) {
      setRegions(["All", ...countries_data.data.data.sort()]);
    }
    if (minerals_data) {
      setMinerals(["All", ...new Set(minerals_data.data.data)]);
    }
  };

  const handleFieldChange = (value, field) => {
    const filters = { ...drilling_filters };

    if (value === "All") {
      // Remove the filter if "All" is selected
      delete filters[field];
    } else {
      // Set the filter value if a specific value is selected
      filters[field] = value;
    }

    setDrillingFilters(filters);
  };
  const [interceptMinerals, setInterceptMinerals] = useState([
    {
      mineral: "",
      length_min: 0,
      length_max: 100,
      grade_min: 0,
      grade_max: 500,
    },
  ]);

  const mineralsOptions = drilling_minerals.map((mineral) => ({
    value: mineral.mineral,
    label: mineral.mineral,
  }));

  const handleMineralChange = (index, selectedOption) => {
    const mineralData = drilling_minerals.find(
      (mineral) => mineral.mineral === selectedOption.value
    );
    const newMinerals = [...interceptMinerals];
    newMinerals[index] = {
      mineral: selectedOption.value,
      length_min: mineralData?.length?.min || 0,
      length_max: mineralData?.length?.max || 100,
      grade_min: mineralData?.grade?.min || 0,
      grade_max: mineralData?.grade?.max || 500,
    };
    setInterceptMinerals(newMinerals);
    setDrillingFilters({
      ...drilling_filters,
      intercept_minerals: newMinerals,
    });
  };

  const debouncedUpdate = useCallback(
    debounce((updatedInterceptMinerals) => {
      setDrillingFilters({
        ...drilling_filters,
        intercept_minerals: updatedInterceptMinerals,
      });
    }, 300),
    []
  );

  const handleRangeChange = (index, field, values) => {
    const updatedInterceptMinerals = [...interceptMinerals];
    if (field === "length") {
      updatedInterceptMinerals[index].length_min = values[0];
      updatedInterceptMinerals[index].length_max = values[1];
    } else if (field === "grade") {
      updatedInterceptMinerals[index].grade_min = values[0];
      updatedInterceptMinerals[index].grade_max = values[1];
    }
    setInterceptMinerals(updatedInterceptMinerals);
    debouncedUpdate(updatedInterceptMinerals);
  };

  const addMineral = () => {
    setInterceptMinerals([
      ...interceptMinerals,
      {
        mineral: "",
        length_min: 0,
        length_max: 100,
        grade_min: 0,
        grade_max: 500,
      },
    ]);
  };

  const removeMineral = (index) => {
    const newMinerals = interceptMinerals.filter((_, i) => i !== index);
    setInterceptMinerals(newMinerals);
    setDrillingFilters({
      ...drilling_filters,
      intercept_minerals: newMinerals,
    });
  };

  const resetFilters = () => {
    const initialFilters = {
      project_mineral: "",
      stage: "",
      country: "",
      intercept_filter: false,
    };
    setInterceptMinerals([
      {
        mineral: "",
        length_min: 0,
        length_max: 100,
        grade_min: 0,
        grade_max: 500,
      },
    ]);
    setDrillingFilters(initialFilters);
  };

  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  return (
    <div style={{ boxShadow: "none", borderRadius: "5px" }}>
      {browse_page_tab === "drilling results" && (
        <Accordion
          square
          expanded={expanded.includes("panel2")}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography variant="subtitle2">Project Filters</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex flex-col space-y-4 w-full text-left">
              {/* label */}

              <p>Stage</p>
              <Select
                value={{
                  value: drilling_filters.stage || "All",
                  label: drilling_filters.stage || "All",
                }}
                onChange={(e) => handleFieldChange(e.value, "stage")}
                placeholder={"All Stages"}
                options={stages.map((tag) => ({ value: tag, label: tag }))}
              />
              <p>Country</p>
              <Select
                value={{
                  value: drilling_filters.country || "All",
                  label: drilling_filters.country || "All",
                }}
                onChange={(e) => handleFieldChange(e.value, "country")}
                placeholder={"All Countries"}
                options={regions.map((tag) => ({ value: tag, label: tag }))}
              />
              <p>Project Mineral</p>
              <Select
                value={{
                  value: drilling_filters.project_mineral || "All",
                  label: drilling_filters.project_mineral || "All",
                }}
                onChange={(e) => handleFieldChange(e.value, "project_mineral")}
                placeholder={"All Minerals"}
                options={minerals
                  .map((tag) => ({ value: tag.trim(), label: tag.trim() }))
                  .concat({ value: "All", label: "All" })}
              />

              <div className="flex items-center">
                <Switch
                  checked={drilling_filters.intercept_filter}
                  onChange={(e) =>
                    setDrillingFilters({
                      ...drilling_filters,
                      intercept_filter: e.target.checked,
                    })
                  }
                  inputProps={{ "aria-label": "controlled" }}
                >
                  <span
                    aria-hidden="true"
                    className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                  />
                </Switch>
                <span className="font-medium text-gray-900 text-xs ml-4">
                  Show only Intercept Updates
                </span>{" "}
              </div>

              {interceptMinerals.map((mineral, index) => (
                <div key={index} style={{ marginBottom: "20px" }}>
                  <Select
                    options={mineralsOptions}
                    value={
                      mineralsOptions.find(
                        (option) => option.value === mineral.mineral
                      ) || null
                    }
                    onChange={(selectedOption) =>
                      handleMineralChange(index, selectedOption)
                    }
                    styles={{
                      menu: (base) => ({ ...base, zIndex: 9999 }), // Adjust the z-index here
                    }}
                    placeholder="Select Mineral"
                  />
                  {mineral.mineral && (
                    <>
                      <div className="my-4">
                        <label>Length (m)</label>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                          }}
                        >
                          <span>{mineral.length_min}</span>
                          <span>{mineral.length_max}</span>
                        </div>
                        <RangeSlider
                          min={0}
                          max={200}
                          value={[mineral.length_min, mineral.length_max]}
                          onInput={(values) =>
                            handleRangeChange(index, "length", values)
                          }
                          style={{ marginBottom: "10px" }}
                          className="custom-slider"
                        />
                      </div>
                      <div className="my-4">
                        <label>Grade (g/t)</label>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                          }}
                        >
                          <span>{mineral.grade_min}</span>
                          <span>{mineral.grade_max}</span>
                        </div>
                        <RangeSlider
                          min={0}
                          max={600}
                          value={[mineral.grade_min, mineral.grade_max]}
                          onInput={(values) =>
                            handleRangeChange(index, "grade", values)
                          }
                          style={{ marginBottom: "10px" }}
                          className="custom-slider"
                        />
                      </div>
                      <button
                        onClick={() => removeMineral(index)}
                        type="button"
                        className="mt-2 rounded-md bg-red-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-600"
                      >
                        Remove Mineral
                      </button>
                    </>
                  )}
                </div>
              ))}

              <button
                onClick={addMineral}
                type="button"
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-emerald-600 shadow-sm ring-1 ring-inset ring-emerald-300 hover:bg-emerald-50"
              >
                Add intercept mineral
              </button>

              <button
                onClick={resetFilters}
                type="button"
                className="mt-4 rounded-md bg-gray-200 px-3.5 py-2.5 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-300"
              >
                Reset Filters
              </button>
            </div>
          </AccordionDetails>
        </Accordion>
      )}

      {me && browse_page_tab !== "drilling results" && (
        <>
          <Accordion
            square
            expanded={expanded.includes("panel2")}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography variant="subtitle2">Your Lists</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  maxHeight: "40vh",
                  overflowY: "scroll",
                  width: "100%",
                }}
              >
                {lists && lists.length ? (
                  lists.map((item, index) => (
                    <div
                      onClick={() =>
                        history.push(`/connections/lists/${item.id}`)
                      }
                      className="flex-boy-row"
                      style={{
                        flex: 1,
                        justifyContent: "normal",
                        marginBottom: "20px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        style={{ height: "50px" }}
                        alt="Remy Sharp"
                        src={
                          item.icon_url || require("../../assets/030-light.svg")
                        }
                      />

                      <Subtitle1 style={{ marginLeft: "10px" }}>
                        {item.name}
                      </Subtitle1>
                    </div>
                  ))
                ) : (
                  <div
                    className="flex-boy-row"
                    style={{ justifyContent: "center", width: "100%" }}
                  >
                    <img
                      src={require("../../assets/ProspectorDesignAssets/empty_image.png")}
                      alt="empty"
                      style={{ height: "60px" }}
                    />
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            square
            expanded={expanded.includes("panel2")}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography variant="subtitle2">Your Tags</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                className="flex-boy-row"
                style={{
                  justifyContent: "normal",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                {tags && tags.length ? (
                  tags.map((item, index) => (
                    <Chip
                      onClick={() => {
                        dispatch(selectTags(item));
                        history.push("/connections/prospects");
                      }}
                      label={item.name}
                      key={item.id}
                      style={{
                        margin: "5px",
                        background: selectedTags.some(
                          (tag) => item.id === tag.id
                        )
                          ? Primary
                          : "gainsboro",
                        color: selectedTags.some((tag) => item.id === tag.id)
                          ? "white"
                          : "black",
                      }}
                    />
                  ))
                ) : (
                  <div
                    className="flex-boy-row"
                    style={{ justifyContent: "center", width: "100%" }}
                  >
                    <img
                      src={require("../../assets/ProspectorDesignAssets/empty_image.png")}
                      alt="empty"
                      style={{ height: "60px" }}
                    />
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  lists: state.authReducer.lists || [],
  tags: state.authReducer.tags || [],
  me: state.authReducer.activeUser,
  prospects: state.authReducer.prospects,
  selectedTags: state.authReducer.selectedTags,
  browse_page_tab: state.authReducer.browse_page_tab,
  drilling_filters: state.authReducer.drillingFilters,
  drilling_minerals: state.authReducer.drillingMinerals,
});

const mapDispatchToProps = (dispatch) => ({
  getProspects: (data) => dispatch(getProspects(data)),
  setDrillingFilters: (data) =>
    dispatch({ type: "SET_DRILLING_FILTERS", payload: data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SummaryAccordion));
