import React, { useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts-fix";
import { apiRequest } from "../../../store/Actions/AuthActions";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../assets/ProspectorDesignAssets/lottie2.json";
import ContentLoader from "react-content-loader";
import _ from "lodash";
import { data } from "autoprefixer";
import { Tooltip } from "@material-ui/core";
charts(FusionCharts);

const StackChart = ({ data }) => {
  const [dataSource, setdataSource] = useState(null);

  return (
    <div className="w-full h-full ml-1">
      <div className="flex flex-col-reverse w-full h-full justify-start">
        {data.map((item) => {
          return (
            <Tooltip title={item.ownership.annotations || item.name}>
              <div
                style={{ height: `${item.share_percent}%` }}
                className={`align-self-end mb-[2px] flex flex-col justify-center align-middle bg-sky-500 border-top-2 border-white-50 w-full`}
              >
                <p className=" truncate text-white text-center text-sm">
                  {item.name} - {item.share_percent}%
                </p>
              </div>
            </Tooltip>
          );
        })}
        {/* <div className="h-[15%] bg-emerald-300 w-full  border-2 border-white-50"></div> */}
      </div>
    </div>
  );
};

export default StackChart;
