/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {
  Subtitle1,
  Headline5,
  Subtitle2,
  Headline6,
  Button,
  NotifyDiv,
  ProspectorDescription,
  Body1,
  Body2,
} from "../../../components";
import { TextField } from "@material-ui/core";
import Logo from "../../../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon.png";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { updateSignUpDetails } from "../../../store/Actions/AuthActions";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00AC69",
        border: "2px solid",
      },
      "&:hover fieldset": {
        borderColor: "#00AC69",
      },
    },
  },
}));

export const Page3 = ({ swapAuthPage, updateSignUpDetails }) => {
  const classes = useStyles();
  const [selectedProspector, setSelected] = useState(null);
  const validate = () => {
    updateSignUpDetails({ type: selectedProspector });
    swapAuthPage(selectedProspector === "Individual" ? "Page5" : "Page4");
  };
  return (
    <Fragment>
      <Headline6 color="#00AC69">What kind of prospector are you</Headline6>
      <div className="circle-progress">
        <div className="progress">
          <CircularProgress
            size="100%"
            thickness={2}
            variant="determinate"
            value={50}
          />
        </div>

        <img src={Logo} alt="logo" />
      </div>
      <ProspectorDescription
        style={{ textAlign: "left", padding: "5px" }}
        onClick={() => {
          setSelected("Mining");
        }}
        color={selectedProspector === "Mining" ? "#00AC69" : "#cbcbcb"}
        className="flex-boy-row"
      >
        <div
          style={{ textAlign: "left", alignItems: "flex-start" }}
          className="flex-boy-column"
        >
          <Subtitle1 style={{ textAlign: "left" }}>Mining Company</Subtitle1>
          <Body2>
            Your are affiliated with a mining company and want to connect with
            investors.
          </Body2>
        </div>
        <img
          style={{ height: "5vh" }}
          src={require(`../../../assets/ProspectorDesignAssets/usertypes/mining company - ${
            selectedProspector === "Mining" ? "selected" : "unselected"
          }.svg`)}
          alt="logo"
        />
      </ProspectorDescription>
      <ProspectorDescription
        onClick={() => {
          setSelected("Institution");
        }}
        style={{ textAlign: "left", padding: "5px" }}
        color={selectedProspector === "Institution" ? "#00AC69" : "#cbcbcb"}
        className="flex-boy-row"
      >
        <div
          style={{ textAlign: "left", alignItems: "flex-start" }}
          className="flex-boy-column"
        >
          <Subtitle1 style={{ textAlign: "left" }}>
            Institutional User
          </Subtitle1>
          <Body2>
            You are affiliated with an entity that is not a publicly listed
            mining company.
          </Body2>
        </div>
        <img
          style={{ height: "5vh" }}
          src={require(`../../../assets/ProspectorDesignAssets/usertypes/institution - ${
            selectedProspector === "Institution" ? "selected" : "unselected"
          }.svg`)}
          alt="logo"
        />
      </ProspectorDescription>
      <ProspectorDescription
        onClick={() => {
          setSelected("Individual");
        }}
        style={{ textAlign: "left", padding: "5px" }}
        color={selectedProspector === "Individual" ? "#00AC69" : "#cbcbcb"}
        className="flex-boy-row"
      >
        <div
          style={{ textAlign: "left", alignItems: "flex-start" }}
          className="flex-boy-column"
        >
          <Subtitle1 style={{ textAlign: "left" }}>Individual</Subtitle1>
          <Body2>
            You are using Prospector as an Individual and are not affiliated
            with any entity.
          </Body2>
        </div>
        <img
          style={{ height: "5vh" }}
          src={require(`../../../assets/ProspectorDesignAssets/usertypes/Individual - ${
            selectedProspector === "Individual" ? "selected" : "unselected"
          }.svg`)}
          alt="logo"
        />
      </ProspectorDescription>

      <div></div>
      <Button
        onClick={() => (selectedProspector ? validate() : null)}
        style={{
          width: "80%",
          backgroundColor: selectedProspector ? "#00AC69" : "gainsboro",
        }}
      >
        Continue
      </Button>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updateSignUpDetails: (data) => dispatch(updateSignUpDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Page3);
