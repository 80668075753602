import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Headline5, Subtitle1 } from "../../../components";
import GoogleMapReact from "google-map-react";
import CompanyMapMarker from "../../../components/CompanyMapMarker";

import { apiRequest } from "../../../store/Actions/AuthActions";
import ProjectCard from "./ProjectCard";

export const CompanyProjects = ({ company, center, trend }) => {
  const [allProjects, setallProjects] = useState(false);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetchCompanyProjects();
  }, [company]);

  const fetchCompanyProjects = async () => {
    let projects_data = await apiRequest(`/companies/${company.id}/projects`);
    if (projects_data && projects_data.data?.data) {
      setProjects(projects_data.data.data);
    }
  };
  return (
    <>
      <Headline5 style={{ margin: "20px 0 20px 0" }}>Projects</Headline5>
      <div className="chart-map">
        <div className="company-map">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyAjl3X5q6XmuETXG8wNyCUb2kOD0IK9PNE",
            }}
            center={
              center.lat
                ? center
                : {
                    lat: 0.0,
                    lng: 0.0,
                  }
            }
            defaultZoom={2}
            options={{ minZoom: 1 }}
          >
            {projects
              ?.filter(
                (item) =>
                  item.lat && item.lng && item.lat !== 0 && item.lng !== 0
              )
              .map((item, index) => {
                return (
                  <CompanyMapMarker
                    project={item}
                    companyData={company}
                    key={index}
                    lat={item.lat}
                    lng={item.lng}
                  />
                );
              })}
          </GoogleMapReact>
          {/* <CompanyMap markers={projects?.filter(item => item.lat && item.lng && item.lat !== 0 && item.lng !== 0)}/> */}
        </div>
        {/* <List /> */}
      </div>
      {projects && projects.length
        ? allProjects
          ? projects.map((project, index) => {
              project.companyData = company;
              project.stockHistory = company.stockHistory;
              return (
                <ProjectCard
                  history={history}
                  iscompanytype={true}
                  key={index}
                  project={project}
                  color={trend.trend !== "up" ? "#B00020" : "#00AC69"}
                />
              );
            })
          : projects.slice(0, 5).map((project, index) => {
              project.companyData = company;
              project.stockHistory = company.stockHistory;
              return (
                <ProjectCard
                  history={history}
                  iscompanytype={true}
                  key={index}
                  project={project}
                  color={trend.trend !== "up" ? "#B00020" : "#00AC69"}
                />
              );
            })
        : null}
      {projects && projects.length > 4 ? (
        <Subtitle1
          className="hover-underline"
          onClick={() => setallProjects(!allProjects)}
          style={{
            marginTop: "20px",
            marginLeft: "10px",
            cursor: "pointer",
          }}
          color="##B00020"
        >
          View {allProjects ? "Less" : "All"} Projects
        </Subtitle1>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProjects);
