/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Body1,
  Subtitle1,
  Headline6,
  Headline4,
  Subtitle2,
  Button,
  NotifyDiv,
  Primary,
  RightMessageBubble,
  LeftMessageBubble,
} from "../../components";

import {
  Send,
  AttachFile,
  KeyboardBackspace,
  DateRange,
  Cancel,
} from "@material-ui/icons";
import { TextField, Dialog, Popover } from "@material-ui/core";
import { Headline5 } from "../general";
import Profile from "../../assets/ProspectorDesignAssets/Companies/istockphoto-1154642632-612x612.jpg";
import { ReviewMeeting } from "../ReviewMeeting";
import background from "../../assets/ProspectorDesignAssets/back.png";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import {
  createChat,
  updateSelectedChat,
} from "../../store/Actions/AuthActions";
import { useFirestoreConnect } from "react-redux-firebase";
import ChatContent from "./ChatContent";
import moment from "moment";
import _ from "lodash";
const conversation = [
  {
    sender: "me",
    text: "Hey when are you available to meet? I would like to hear more about your company during PDAC 2021.",
    date: "",
    type: "text",
  },
  {
    sender: "person2",
    text: "We can meet any time tomorrow",
    date: "",
    type: "text",
  },
  {
    sender: "me",
    text: "Ok great. Please send a calendar invite with the details.",
    date: "",
    type: "text",
  },
  {
    sender: "person2",
    text: "Ok great. Please send a calendar invite with the details.",
    date: "",
    type: "invite",
  },
];
export const Messages = ({
  auth,
  setChatSection,
  selectedChatSection,
  updateSelectedChat,
  selectedChat,
  createChat,
  customers,
  prospects,
}) => {
  let me = auth.uid;
  // const [activeSection, setActive] = useState('chat_list')
  const [viewMeeting, setRequest] = useState(false);
  const [selectedProspect, setProspect] = useState(null);
  const chats = useSelector((state) => state.firestore.ordered.chats) || [];

  const setChat = (id) => {
    updateSelectedChat(id);
  };

  const handleClose = () => {
    setRequest(false);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlepopClose = () => {
    setAnchorEl(null);
    handleClosePop();
  };
  const handleClosePop = () => {
    setAnchorEl(null);
  };
  const handleInvite = () => {
    setAnchorEl(null);
    handleClosePop();
    createChat(selectedProspect);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={viewMeeting}
      >
        <ReviewMeeting setRequest={setRequest} />
      </Dialog>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="invitee-popover">
          <div style={{ padding: "10px" }} className="flex-boy-row">
            <KeyboardBackspace
              style={{ color: "#00000061" }}
              onClick={() => handlepopClose()}
            />
            <Subtitle1 color="#00000061">Select People to add</Subtitle1>

            <Cancel
              fontSize="small"
              style={{ color: "#00000061" }}
              onClick={() => handlepopClose()}
            />
          </div>
          <div className="prospect-list">
            {prospects
              ? prospects.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex-boy-row"
                      style={{
                        boxSizing: "border-box",
                        padding: "10px",
                        justifyContent: "normal",
                        backgroundColor:
                          selectedProspect &&
                          selectedProspect.userId === item.id
                            ? "gainsboro"
                            : "white",
                      }}
                      onClick={() =>
                        setProspect({
                          userId: item.id,
                          name: item.name || item.email,
                        })
                      }
                    >
                      <Avatar
                        name={item.name || item.email}
                        round
                        color={Primary}
                        size={50}
                      />
                      <Subtitle1 style={{ marginLeft: "5px" }}>
                        {item.name || item.email}
                      </Subtitle1>
                      {selectedProspect &&
                      selectedProspect.userId === item.id ? (
                        <Button onClick={() => handleInvite()}>Create</Button>
                      ) : null}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </Popover>
      {selectedChatSection === "chat_list" ? (
        <div className="flex-boy-column" style={{ alignItems: "normal" }}>
          {chats.length ? (
            _.orderBy(chats, ["timestamp"], ["desc"]).map((chat_item) => {
              let notme =
                chat_item.type === "dm"
                  ? chat_item.members.filter((item) => item !== me)[0]
                  : chat_item.name;
              let name =
                chat_item.type === "dm"
                  ? chat_item.memberNames[notme]
                  : chat_item.name;
              return (
                <div
                  style={{ cursor: "pointer" }}
                  key={chat_item.timestamp}
                  onClick={() => {
                    setChat(chat_item);
                    setChatSection("chat_container");
                  }}
                  className="messageBox"
                >
                  <Avatar name={name} round color={Primary} size={50} />
                  <div
                    className="flex-boy-column"
                    style={{
                      textAlign: "left",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      padding: "5px",
                      flex: 1,
                    }}
                  >
                    <Subtitle1>{name}</Subtitle1>
                    <Subtitle2>{chat_item.lastText}</Subtitle2>
                  </div>
                  <div>
                    <Subtitle2>
                      {moment(
                        chat_item.timestamp
                          ? chat_item.timestamp.seconds * 1000
                          : 3243543535
                      ).fromNow()}
                    </Subtitle2>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className="flex-boy-column"
              style={{
                justifyContent: "center",
                width: "100%",
                margin: "20px 0 20px",
              }}
            >
              <Headline4 style={{ marginBottom: "50px" }} color="grey">
                You have no chats yet.
              </Headline4>
              <img
                src={require("../../assets/ProspectorDesignAssets/empty_image.png")}
                alt="empty"
                style={{ height: "300px" }}
              />
            </div>
          )}

          <Button style={{ alignSelf: "center" }} onClick={handleClick}>
            Create Chat
          </Button>
        </div>
      ) : (
        <ChatContent setActive={setChatSection} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.authReducer.activeUser,
  auth: state.firebase.auth,
  selectedChat: state.authReducer.selectedChat,
  selectedChatSection: state.authReducer.selectedChatSection,
  customers: state.firestore.ordered.customers,
  prospects: state.authReducer.prospects,
});

const mapDispatchToProps = (dispatch) => ({
  updateSelectedChat: (data) => dispatch(updateSelectedChat(data)),
  createChat: (data) => dispatch(createChat(data)),
  setChatSection: (data) =>
    dispatch({ type: "SET_CHAT_SECTION", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
