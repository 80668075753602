import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { getReportDetail } from "../../../store/Actions/AuthActions";
import drillingData from "../Drilling_Results.json";
import DetailCellRenderer from "../../../components/DrillingResultsDetails";

export const DrillingResults = ({
  drillingFilters,
  getReportDetail,
  history,
  setDrillingMinerals,
}) => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    fetchFilteredData();
  }, []);

  useEffect(() => {
    fetchFilteredData();
  }, [drillingFilters]);

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Headline",
        field: "headline",
        sortable: true,
        filter: "agTextColumnFilter",
        cellRenderer: "agGroupCellRenderer",
        autoHeight: true,
      },
      { headerName: "Project", field: "project", sortable: true, filter: true },
      {
        headerName: "Stage",
        field: "phase_of_drilling",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Highlight Intercept",
        field: "highlight_intercept",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Country",
        field: "country",
        sortable: true,
        filter: true,
      },
      // publication_date
      {
        headerName: "Publication Date",
        field: "publication_date",
        sortable: true,
        filter: "agDateColumnFilter",
        filterParams: {
          filterOptions: ["equals", "inRange"],
          buttons: ["apply", "reset"],
          closeOnApply: true,
        },
      },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      floatingFilter: true,
      sortable: true,
    }),
    []
  );

  const onExportClick = () => {
    gridRef.current?.api?.exportDataAsExcel();
  };

  const fetchFilteredData = async () => {
    setDataLoading(true);
    try {
      // Combine grid filters and external filters
      const filterModel = gridRef.current?.api?.getFilterModel();
      console.log("filterModel", filterModel);
      let payload = destructureFilterModel(filterModel, drillingFilters);

      // remove empty values & __jsogObjectId as well as empty arrays or null
      Object.keys(payload).forEach((key) => {
        if (
          payload[key] === "" ||
          key === "__jsogObjectId" ||
          payload[key] === null
        ) {
          delete payload[key];
        }
        if (Array.isArray(payload[key]) && payload[key].length === 0) {
          delete payload[key];
        }
        // deep check for nested objects and remove "__jsogObjectId"
        if (typeof payload[key] === "object") {
          Object.keys(payload[key]).forEach((nestedKey) => {
            if (nestedKey === "__jsogObjectId") {
              delete payload[key][nestedKey];
            }
          });
        }
      });

      console.log({ payload });

      const response =
        Object.keys(payload).length > 0
          ? await axios.post(
              "https://py.prospectormatch.com/api/drilling-results/",
              payload,
              {
                withCredentials: false,
              }
            )
          : await axios({
              method: "POST",
              url: "https://py.prospectormatch.com/api/drilling-results/",
              withCredentials: false,
            });

      setRowData(response.data?.data.documents);
      setDrillingMinerals(response.data?.data.mineral_stats);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setDataLoading(false);
  };

  const destructureFilterModel = (gridFilters, externalFilters) => {
    const filterObject = { ...externalFilters };

    // Incorporate grid filters without overriding external filters
    console.log("gridFilters", gridFilters);
    if (gridFilters) {
      Object.keys(gridFilters).forEach((key) => {
        const filter = gridFilters[key];
        if (filter.filterType === "text") {
          if (filter.type === "contains") {
            filterObject[key] = filter.filter;
          }
          // Add more conditions if needed (e.g., "startsWith", "endsWith", etc.)
        } else if (filter.filterType === "date") {
          if (filter.type === "inRange") {
            filterObject.start_date = filter.dateFrom;
            filterObject.end_date = filter.dateTo;
          } else if (filter.type === "equals") {
            filterObject.start_date = filter.dateFrom;
          }
        } else if (filter.filterType === "set") {
          // Handle set filter type if required
          filterObject[key] = filter.values.join(",");
        } else if (key === "publication_date") {
          if (filter.type === "inRange") {
            filterObject.start_date = filter.dateFrom;
            filterObject.end_date = filter.dateTo;
          } else if (filter.type === "equals") {
            filterObject.start_date = filter.dateFrom;
            filterObject.end_date = filter.dateFrom;
          }
        }
        // Add more filter types as necessary
      });
    }
    return filterObject;
  };

  const onGridReady = useCallback((params) => {
    gridRef.current.api = params.api;
  }, []);

  const onFilterChanged = useCallback(() => {
    fetchFilteredData();
  }, []);

  const goToDetail = useCallback(
    (id) => {
      getReportDetail({ id: id, history });
    },
    [getReportDetail, history]
  );

  const detailCellRendererParams = useMemo(
    () => ({
      detailGridOptions: {
        columnDefs: [
          { headerName: "Length (meters)", field: "length" },
          { headerName: "Mineral", field: "mineral" },
          { headerName: "Grade", field: "grade" },
          { headerName: "Grade UOM", field: "gradeUOM" },
          { headerName: "Depth (meters)", field: "depth" },
        ],
        defaultColDef: {
          flex: 1,
          minWidth: 100,
          resizable: true,
          filter: true,
          sortable: true,
        },
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.details);
      },
    }),
    []
  );

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Drilling Activity</h1>
        <button
          onClick={onExportClick}
          type="button"
          className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-emerald-600 shadow-sm ring-1 ring-inset ring-emerald-300 hover:bg-emerald-50"
        >
          Download Data to Excel
        </button>
      </div>

      <div className="grid grid-cols-3 gap-4 mb-4 ag-theme-custom">
        <div className="col-span-3">
          <div style={{ height: "90vh", width: "100%" }}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              detailCellRenderer={DetailCellRenderer}
              detailCellRendererParams={detailCellRendererParams}
              detailRowHeight={500}
              masterDetail={true}
              onGridReady={onGridReady}
              onFilterChanged={onFilterChanged}
              onRowClicked={(e) => goToDetail(e.data.id)}
              loadingOverlayComponent={() => (
                <div className="loading-overlay">Loading...</div>
              )}
              noRowsOverlayComponent={() => (
                <div className="no-rows-overlay">No rows found</div>
              )}
              loading={dataLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  drillingFilters: state.authReducer.drillingFilters,
});

const mapDispatchToProps = (dispatch) => ({
  getReportDetail: (data) => dispatch(getReportDetail(data)),
  setDrillingMinerals: (data) =>
    dispatch({ type: "SET_DRILLING_MINERALS", payload: data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DrillingResults));
