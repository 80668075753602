/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {
  Subtitle1,
  Headline5,
  Subtitle2,
  Headline6,
  Button,
  NotifyDiv,
  ProspectorDescription,
  Body1,
  Body2,
  ProspectorInterest,
} from "../../../components";
import { TextField } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Logo from "../../../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon.png";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import {
  updateSignUpDetails,
  linkedinLogin,
} from "../../../store/Actions/AuthActions";
const interests = [
  {
    name: "Base Metals",
    minerals: ["Cu", "Zn", "Pb"],
  },
  {
    name: "Critical Metals",
    minerals: ["Co", "REE", "Th"],
  },
  {
    name: "Energy Metals",
    minerals: ["U", "Mn", "Ni"],
  },
  {
    name: "Gems",
    minerals: ["Diamonds", "Emeralds"],
  },
  {
    name: "Industrial Metals",
    minerals: ["Asbestos", "Bauxite"],
  },
  {
    name: "Precious Metals",
    minerals: ["Au", "Ag", "PGE"],
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00AC69",
        border: "2px solid",
      },
      "&:hover fieldset": {
        borderColor: "#00AC69",
      },
    },
  },
}));

export const Page7 = ({
  swapAuthPage,
  updateSignUpDetails,
  linkedinLogin,
  signup,
}) => {
  const classes = useStyles();
  const [selectedInterests, setSelected] = useState([]);
  const select = (region) => {
    let array = [...selectedInterests];
    const exists = array.includes(region);

    if (exists) {
      setSelected(
        array.filter((c) => {
          return c !== region;
        })
      );
    } else {
      const result = array;
      result.push(region);

      setSelected(result);
    }
  };
  const validate = () => {
    updateSignUpDetails({ interestMinerals: selectedInterests });
    if (signup.auth_type === "linkedin") {
      linkedinLogin();
    } else {
      swapAuthPage("Page8");
    }
  };
  const moveAlong = () => {
    if (signup.auth_type === "linkedin") {
      linkedinLogin();
    } else {
      swapAuthPage("Page8");
    }
  };
  return (
    <Fragment>
      <Headline6 color="#00AC69">
        Are there types of projects that are of greatest interest to you?u
      </Headline6>
      <div className="circle-progress">
        <div className="progress">
          <CircularProgress
            size="100%"
            thickness={2}
            variant="determinate"
            value={50}
          />
        </div>

        <img src={Logo} alt="logo" />
      </div>
      <div className="flex-boy-row" style={{ flexWrap: "wrap" }}>
        {interests.map((region) => {
          return (
            <ProspectorInterest
              key={region}
              onClick={() => {
                select(region);
              }}
              style={{ textAlign: "left", padding: "5px", cursor: "pointer" }}
              color={
                selectedInterests.includes(region) ? "#00AC69" : "#00000061"
              }
              className="flex-boy-row"
            >
              <div
                style={{
                  textAlign: "left",
                  textTransform: "capitalize",
                  alignItems: "flex-start",
                }}
                className="flex-boy-column"
              >
                <Subtitle1
                  style={{ textAlign: "left" }}
                  color={
                    selectedInterests.includes(region) ? "#00AC69" : "#00000061"
                  }
                >
                  {region.name}
                </Subtitle1>
                <div className="flex-boy-row">
                  {region.minerals.map((mineral, index) => {
                    return (
                      <Subtitle2 key={index}>
                        {mineral}{" "}
                        {index !== region.minerals.length - 1 ? "," : null}{" "}
                      </Subtitle2>
                    );
                  })}
                </div>
              </div>
              <div style={{ height: "100%" }}>
                {selectedInterests.includes(region) ? (
                  <CheckCircleIcon fontSize="small" />
                ) : null}
              </div>
            </ProspectorInterest>
          );
        })}
      </div>

      <div></div>
      <Button
        onClick={() => (selectedInterests.length ? validate() : null)}
        style={{
          width: "80%",
          backgroundColor: selectedInterests.length ? "#00AC69" : "gainsboro",
        }}
      >
        Continue
      </Button>
      <Subtitle1
        onClick={() => moveAlong()}
        style={{ cursor: "pointer" }}
        color="#00AC69"
      >
        Skip this step
      </Subtitle1>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  signup: state.authReducer.signUp,
});

const mapDispatchToProps = (dispatch) => ({
  updateSignUpDetails: (data) => dispatch(updateSignUpDetails(data)),
  linkedinLogin: () => dispatch(linkedinLogin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Page7);
