/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import GoogleMapReact from "google-map-react";
import MapMarker from "./iframemapMarker";
import { searchClient, projectsSearchIndex } from "../configure/constants";
import {
  searchAlgoliaProjects,
  filterProjects,
} from "../store/Actions/AuthActions";
import Geocode from "react-geocode";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import useSupercluster from "use-supercluster";
import _ from "lodash";
import { Headline6 } from "./general";
const iFrameDetection = window === window.parent ? false : true;
export const Map = ({
  projects,
  mapfullscreen,
  setHoverProject,
  searchAlgoliaProjects,
  auth,
  setMapBounds,
  filterProjects,
  setProjectPage,
  location,
}) => {
  let me = auth.uid;
  Geocode.setApiKey("AIzaSyDbd9W45ccOJu6b42cLGeusSQYAm9C_Nmg");
  const mapRefo = useRef();
  const [mapsRef, setMapsRef] = useState(null);
  const [mapRef, setMapRef] = useState(null);
  // set response language. Defaults to english.
  Geocode.setLanguage("en");
  const [center, setCenter] = useState({
    lat: 12.7832,
    lng: 44.5085,
  });
  const getBoundsForMarkers = (map, maps, markers) => {
    var bounds, marker, _i, _len;
    bounds = new maps.LatLngBounds();
    for (_i = 0, _len = markers.length; _i < _len; _i++) {
      marker = markers[_i];
      bounds.extend(marker.getPosition());
    }
    return bounds;
  };

  // useEffect(() => {
  //     return () => {
  //         // initGeocoder()

  //         const script = document.createElement('script')
  //         script.src = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js'
  //         script.async = true
  //         document.body.appendChild(script)
  //     }
  // }, [])

  useEffect(() => {
    if (location) {
      Geocode.fromAddress(location).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setCenter({ lat, lng });
          setZoom(1.5);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      setCenter({
        lat: 12.7832,
        lng: 44.5085,
      });
    }
    return () => {};
  }, [location]);

  useEffect(() => {
    if (mapRef && mapsRef) {
      setMarkers();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, mapsRef, projects]);
  useEffect(() => {
    if (mapRef && mapsRef) {
      // setMarkers()
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, mapsRef]);
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(1);
  const points = projects.map((project) => ({
    type: "Feature",
    properties: {
      cluster: false,
      id: project.id,
      name: project.name,
      date: project.date,
    },
    geometry: { type: "Point", coordinates: [project.lng, project.lat] },
  }));
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const initGeocoder = (map, maps) => {
    const Geocoder = new maps.Geocoder();
    const googleMapRef = map;
    const googleRef = maps;
    setMapsRef(maps);
    setMapRef(map);
  };
  let markers = [];
  let markerCluster = null;
  const Marker = ({ children }) => children;

  const setMarkers = () => {
    if (mapRef && mapsRef) {
      let googleRef = mapsRef;
      let map = mapRef;

      markers = markers.length
        ? markers.map((marker) => {
            marker.setMap(null);
            marker = null;
            return marker;
          })
        : [];
      if (markerCluster) {
        markerCluster.repaint();
        markerCluster.clearMarkers();
      }
      let markerList = projects.filter(
        (item) => item.lat !== 0 && item.lng !== 0
      );
      const bounds = getBoundsForMarkers(map, mapsRef, markerList);

      markers =
        projects &&
        projects
          .filter((item) => item.lat !== 0 && item.lng !== 0)
          .map((location) => {
            let marker = new googleRef.Marker({
              position: { lat: location.lat, lng: location.lng },
              icon: {
                url: require("../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon.png"),
                optimized: true,
                scaledSize: new googleRef.Size(32, 25),
                // The origin for this image is (0, 0).
                origin: new googleRef.Point(0, 0),
                // The anchor for this image is the base of the flagpole at (0, 32).
                anchor: new googleRef.Point(0, 32),
              },
            });
            googleRef.event.addListener(marker, "click", function () {
              setHoverProject(location.id);
            });
            return marker;
          });

      markerCluster = new MarkerClusterer(map, markers, {
        styles: [
          {
            textColor: "white",
            url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
            height: 50,
            width: 50,
            anchorText: [22, 27],
            anchorIcon: [50, 50],
          },
        ],
        // imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        gridSize: 100,
        minimumClusterSize: 5,
      });
    }
  };
  // const mapChange = ({ center, zoom, bounds, marginBounds }) => {

  //     const boundingBox = [
  //         bounds.nw.lng, // p1Lat
  //         bounds.se.lat,
  //         bounds.se.lng, // p1Lng
  //         bounds.nw.lat // p2Lng
  //     ];
  //     // setBounds({...bounds})
  //     setMapBounds(boundingBox)
  //     setZoom(zoom)
  //     // setProjectPage(0)
  //     // filterProjects()
  //     // search_projects({loc:0,boundingBox})

  // }

  const projectsIndex = searchClient.initIndex(projectsSearchIndex);

  let hit_list = [];

  const search_projects = ({ loc, boundingBox }) => {
    // projectsIndex.search('',{insideBoundingBox: [boundingBox]}).then(({hits}) =>{
    //     searchAlgoliaProjects({hits,me})
    //    })

    projectsIndex
      .search("", { insideBoundingBox: [boundingBox], page: loc })
      .then(({ hits, nbPages, page }) => {
        hit_list = hit_list.concat(hits);

        if (page < nbPages) {
          search_projects({ loc: loc + 1, boundingBox });
        } else {
          if (boundingBox) {
            let list = _.uniqBy(hit_list, "objectID");
            // let list = _.uniqBy(hit_list, 'objectID').filter(item => (parseFloat(item.lat) > bounds.sw.lat && parseFloat(item.lat) < bounds.ne.lat) && (parseFloat(item.lng) > bounds.sw.lng && parseFloat(item.lng) < bounds.ne.lng) )
            // searchAlgoliaProjects({ hits: list, me })
          }

          // searchAlgoliaProjects({hits:list,me})
        }

        // searchAlgoliaProjects({hits,me})
      });
  };

  return (
    <div
      className="map"
      style={{
        position: "fixed",
        height: iFrameDetection ? "100vh" : "calc(100vh - 80px)",
        width: mapfullscreen ? "100%" : iFrameDetection ? "52%" : "51%",
        top: iFrameDetection ? 0 : "80px",
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAjl3X5q6XmuETXG8wNyCUb2kOD0IK9PNE" }}
        center={center}
        defaultZoom={3}
        options={{ minZoom: 1 }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
        yesIWantToUseGoogleMapApiInternals
        // options={{ streetViewControl: true, fullscreenControl: false, minZoomOverride: true }}

        onGoogleApiLoaded={({ map, maps }) => {
          mapRefo.current = map;
          // bounds = getBoundsForMarkers(map,maps,markers)
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;
          if (cluster.properties.cluster) {
            return (
              <Marker key={cluster.id} lat={latitude} lng={longitude}>
                <div
                  className="cluster-marker"
                  style={{
                    width: `${10 + (pointCount / points.length) * 40}px`,
                    height: `${10 + (pointCount / points.length) * 40}px`,
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    );
                    mapRefo.current.setZoom(expansionZoom);
                    mapRefo.current.panTo({ lat: latitude, lng: longitude });
                  }}
                >
                  <Headline6 color="white">{pointCount}</Headline6>
                </div>
              </Marker>
            );
          }
          return (
            <Marker key={cluster.properties.id} lat={latitude} lng={longitude}>
              <MapMarker
                project={{
                  id: cluster.properties.id,
                  name: cluster.properties.name,
                  date: cluster.properties.date,
                }}
              />
            </Marker>
          );
        })}

        {/* {projects.filter(item => item.lat).map((item, index) => {
                    return (
                        item.lat ? <MapMarker key={index} lat={item.lat} lng={item.lng} project={item.id} /> : null
                    )
                })} */}
      </GoogleMapReact>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  projects:
    state.authReducer.map_projects.filter(
      (item) => item.lat !== 0 && item.lng !== 0
    ) || [],
});

const mapDispatchToProps = (dispatch) => ({
  searchAlgoliaProjects: (data) => dispatch(searchAlgoliaProjects(data)),
  setMapBounds: (data) => dispatch({ type: "SET_MAP_BOUNDS", payload: data }),
  filterProjects: (data) => dispatch(filterProjects()),
  setProjectPage: (data) =>
    dispatch({ type: "SET_PROJECTS_PAGE", payload: data }),
  setHoverProject: (data) =>
    dispatch({ type: "SET_HOVER_PROJECT", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Map);
