import React, { useEffect, useState } from "react";
import {
  ComponentContainer,
  Headline5,
  Primary,
  Headline6,
  Subtitle2,
} from "../../../../components";
import Select from "react-select";
import { Exchanges } from "../../../../configure/constants";
import Scatter from "../Scatter";
import Colunm from "../colunm";
import Sankey from "../Sankey";
import { connect, useSelector } from "react-redux";
import Table from "../Table";
import { apiRequest } from "../../../../store/Actions/AuthActions";
import { useInView } from "react-intersection-observer";
import { nFormatter } from "../../../../configure/helpers";
import AreaChart from "../AreaChart";
import { height } from "@mui/system";
import Loading from "../../../../components/Loading";
import { Dialog, Tooltip } from "@material-ui/core";
import Citations from "../Citations";
import Annotations from "../Annotatons";
import CsvDownload from "react-json-to-csv";
import { PDFExport } from "@progress/kendo-react-pdf";

const customStyles = {
  control: (base) => ({
    ...base,
    fontSize: "x-large",

    minHeight: 50,
  }),
};
const mineral_List = [
  {
    name: "Gold",
  },
];

export const MineralDiv = ({
  mineral,
  year,
  project,
  classification,
  production_basis,
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true,
  });
  console.log("is in view=>", inView);
  const Fetchsummary = ({ mineral, classification }) => {
    useEffect(() => {
      getSummary();

      return () => {};
    }, [year, production_basis]);
    const [summary, setsummary] = useState(null);
    const [loading, setloading] = useState(false);
    const getSummary = async () => {
      setloading(true);
      const summary = await apiRequest
        .get(
          `/projects/${project.id}/mineral-reserve-resource-summary?production_basis=${production_basis}&mineral=${mineral}&report_year=${year}&classification=${classification}`
        )
        .catch((err) => console.log(err));
      if (summary?.data) {
        setsummary(summary.data);
        setloading(false);
      }
    };
    const [citations, setCitations] = useState(false);
    const [showAnnotations, setshowAnnotions] = useState(false);
    const showCitations = () => {
      setCitations(true);
    };
    const pdfExportComponent = React.useRef(null);
    const exportPDFWithComponent = () => {
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
      }
    };
    const [tableData, setGridData] = useState(null);
    return (
      <div className="flex-boy-row">
        <Dialog
          onClose={() => setCitations(false)}
          aria-labelledby="simple-dialog-title"
          fullWidth
          maxWidth="xl"
          open={citations}
        >
          <Citations
            project={project}
            mineral={mineral}
            close={() => setCitations(false)}
          />
        </Dialog>
        <Dialog
          onClose={() => setshowAnnotions(false)}
          aria-labelledby="simple-dialog-title"
          fullWidth
          maxWidth="xl"
          open={showAnnotations}
        >
          <Annotations
            project={project}
            mineral={mineral}
            close={() => setCitations(false)}
          />
        </Dialog>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Headline6>
              {nFormatter(
                summary?.data?.project_mineral_reserve[0]?.contained_metal?.toFixed(
                  2
                ),
                1
              )}{" "}
              {
                summary?.data?.project_mineral_reserve[0]
                  ?.contained_metal_measurement
              }
            </Headline6>
            <Subtitle2>
              {summary?.data?.project_mineral_reserve[0]?.min_grade}-
              {summary?.data?.project_mineral_reserve[0]?.max_grade}
              {summary?.data?.project_mineral_reserve[0]?.grade_measurement}
            </Subtitle2>
          </>
        )}
      </div>
    );
  };
  const [showCharts, setshowCharts] = useState(false);
  const showHideCharts = () => {
    setshowCharts(!showCharts);
  };
  const [citations, setCitations] = useState(false);
  const [showAnnotations, setshowAnnotions] = useState(false);
  const showCitations = () => {
    setCitations(true);
  };
  const pdfExportComponent = React.useRef(null);
  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  const [tableData, setGridData] = useState(null);
  return (
    <div ref={ref}>
      <Dialog
        onClose={() => setCitations(false)}
        aria-labelledby="simple-dialog-title"
        fullWidth
        maxWidth="xl"
        open={citations}
      >
        <Citations
          project={project}
          mineral={mineral}
          close={() => setCitations(false)}
        />
      </Dialog>
      <Dialog
        onClose={() => setshowAnnotions(false)}
        aria-labelledby="simple-dialog-title"
        fullWidth
        maxWidth="xl"
        open={showAnnotations}
      >
        <Annotations
          project={project}
          mineral={mineral}
          close={() => setshowAnnotions(false)}
        />
      </Dialog>
      {inView && (
        <>
          {/* <div
            className="flex-boy-row flex-boy-row-resources-and-reserves"
            style={{ justifyContent: "normal", flex: "1" }}
          >
            <div className="chart-div">
              <Fetchsummary mineral={mineral} classification={"Inferred"} />
              <Subtitle2>Inferred Resource</Subtitle2>
            </div>
            <div className="chart-div">
              <Fetchsummary
                mineral={mineral}
                classification={"Measured,Indicated"}
              />
              <Subtitle2>Measured & Indicated Resource</Subtitle2>
            </div>
            <div className="chart-div" style={{ borderRight: "transparent" }}>
              <Fetchsummary
                mineral={mineral}
                classification={"Proven,Probable"}
              />
              <Subtitle2>Proven & Probable Reserve</Subtitle2>
            </div>
          </div> */}
          <PDFExport
            ref={pdfExportComponent}
            paperSize="auto"
            margin={50}
            fileName={`Report for ${project.projectName} ${mineral} Resource and Reserve Statement for ${year}`}
            author="Prospector Inc"
          >
            <div className="flex-boy-row mb-4" style={{ marginTop: "40px" }}>
              <Table
                project={project}
                production_basis={production_basis}
                mineral={mineral}
                year={year}
                setGridData={setGridData}
                classification={"Proven,Probable"}
              />
            </div>
          </PDFExport>
          <div className="w-full flex flex-row justify-end">
            <CsvDownload
              data={tableData}
              filename={`Report for ${project.projectName} for ${mineral}.csv`}
            >
              <Tooltip title="Export CSV">
                <div className="flex flex-row cursor-pointer items-center  bg-slate-100 justify-center h-[50px] w-[50px] m-2 mb-4 rounded border-solid border-2 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.5 3.75a6 6 0 00-5.98 6.496A5.25 5.25 0 006.75 20.25H18a4.5 4.5 0 002.206-8.423 3.75 3.75 0 00-4.133-4.303A6.001 6.001 0 0010.5 3.75zm2.25 6a.75.75 0 00-1.5 0v4.94l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V9.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </Tooltip>
            </CsvDownload>
            <Tooltip title="Print PDF">
              <div
                onClick={exportPDFWithComponent}
                className="flex flex-row cursor-pointer items-center justify-center h-[50px] w-[50px] bg-slate-100 m-2 mb-4 rounded border-solid border-2 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 003 3h.27l-.155 1.705A1.875 1.875 0 007.232 22.5h9.536a1.875 1.875 0 001.867-2.045l-.155-1.705h.27a3 3 0 003-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0018 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM16.5 6.205v-2.83A.375.375 0 0016.125 3h-8.25a.375.375 0 00-.375.375v2.83a49.353 49.353 0 019 0zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 01-.374.409H7.232a.375.375 0 01-.374-.409l.526-5.784a.373.373 0 01.333-.337 41.741 41.741 0 018.566 0zm.967-3.97a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H18a.75.75 0 01-.75-.75V10.5zM15 9.75a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V10.5a.75.75 0 00-.75-.75H15z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </Tooltip>
            <Tooltip title="Show/Hide Charts">
              <div
                className="flex flex-row cursor-pointer items-center justify-center h-[50px] w-[50px] m-2 mb-4 bg-slate-100 rounded border-solid border-2 "
                onClick={showHideCharts}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z" />
                </svg>
              </div>
            </Tooltip>
            <Tooltip title="View Annotations">
              <div
                className="flex flex-row cursor-pointer items-center justify-center h-[50px] w-[50px] m-2 mb-4 bg-slate-100 rounded border-solid border-2 "
                onClick={() => setshowAnnotions(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </Tooltip>
            <Tooltip title="View Citations">
              <div
                className="flex flex-row cursor-pointer items-center justify-center h-[50px] w-[50px] m-2 mb-4 bg-slate-100 rounded border-solid border-2 "
                onClick={showCitations}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path d="M11.625 16.5a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75z" />
                  <path
                    fillRule="evenodd"
                    d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm6 16.5c.66 0 1.277-.19 1.797-.518l1.048 1.048a.75.75 0 001.06-1.06l-1.047-1.048A3.375 3.375 0 1011.625 18z"
                    clipRule="evenodd"
                  />
                  <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
                </svg>
              </div>
            </Tooltip>
          </div>

          <div
            className={`flex flex-row flex-wrap w-full ${
              showCharts ? "h-fit" : "h-0  overflow-hidden"
            }`}
            style={{ alignItems: "normal" }}
          >
            <div
              className="flex-boy-row flex-boy-row-resources-and-reserves"
              style={{ justifyContent: "normal", flex: "1" }}
            >
              <div className="chart-div">
                <Scatter
                  project={project}
                  production_basis={production_basis}
                  mineral={mineral}
                  year={year}
                  classification={"Inferred"}
                />
                <Colunm
                  project={project}
                  production_basis={production_basis}
                  mineral={mineral}
                  year={year}
                  classification={"Inferred"}
                />
                <AreaChart
                  project={project}
                  production_basis={production_basis}
                  mineral={mineral}
                  year={year}
                  classification={"Inferred"}
                />
              </div>
              <div className="chart-div">
                <Scatter
                  project={project}
                  production_basis={production_basis}
                  mineral={mineral}
                  year={year}
                  classification={"Measured,Indicated"}
                />
                <Colunm
                  project={project}
                  production_basis={production_basis}
                  mineral={mineral}
                  year={year}
                  classification={"Measured,Indicated"}
                />
                <AreaChart
                  project={project}
                  production_basis={production_basis}
                  mineral={mineral}
                  year={year}
                  classification={"Measured,Indicated"}
                />
              </div>
              <div className="chart-div" style={{ borderRight: "transparent" }}>
                <Scatter
                  project={project}
                  production_basis={production_basis}
                  mineral={mineral}
                  year={year}
                  classification={"Proven,Probable"}
                />
                <Colunm
                  project={project}
                  production_basis={production_basis}
                  mineral={mineral}
                  year={year}
                  classification={"Proven,Probable"}
                />
                <AreaChart
                  project={project}
                  production_basis={production_basis}
                  mineral={mineral}
                  year={year}
                  classification={"Proven,Probable"}
                />
              </div>
            </div>
            <div className="w-full">
              <Sankey
                project={project}
                production_basis={production_basis}
                mineral={mineral}
                year={year}
                classification={"Proven,Probable"}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MineralDiv);
