import React from "react";
import { connect } from "react-redux";
import { Button, MainPage } from "../components";
import { logOut } from "../store/Actions/AuthActions";

export const Account = ({ logout, activeUser }) => {
  return (
    <MainPage>
      <Button onClick={() => logout()}>LogOut</Button>
    </MainPage>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.authReducer.activeUser,
  auth: state.firebase.auth,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
