/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import {
  Headline6,
  MainPage,
  Primary,
  Headline5,
  Body1,
  Body2,
  GreyBox,
  Button,
  Subtitle1,
  Subtitle2,
  BrowseListFilter,
  Hexagon,
  NotifyDiv,
  TailwindButton,
  TailwindButtonOutline,
} from "../general";
import {
  searchAlgoliaCompanies,
  searchAlgoliaProjects,
  filterProjects,
  filterCompanies,
  filterReports,
} from "../../store/Actions/AuthActions";
import { Divider } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

export const SortCard = ({
  setCompanyPage,
  projects_length,
  companies_length,
  setProjectPage,
  projects,
  companies,
  searchAlgoliaCompanies,
  auth,
  tab,
  handleClose,
  filterProjects,
  setProjectSortFilters,
  setReportSortFilters,
  setCompanySortFilters,
  project_sort_filter,
  company_sort_filter,
  report_sort_filter,
  filterCompanies,
  filterReports,
}) => {
  const { currentSortDirection, setCurrentSortDirection } = useState(
    project_sort_filter.type
  );
  const setSort = (name) => {
    if (tab === "projects") {
      if (
        project_sort_filter.name === name &&
        project_sort_filter.type === "desc"
      ) {
        setProjectSortFilters({ name, type: "asc" });
      } else if (
        project_sort_filter.name === name &&
        project_sort_filter.type === "asc"
      ) {
        setProjectSortFilters({ name, type: "desc" });
      } else {
        setProjectSortFilters({ name, type: "desc" });
      }
    } else if (tab === "technical reports") {
      console.log(name, report_sort_filter);
      if (
        report_sort_filter.name === name &&
        report_sort_filter.type === "desc"
      ) {
        setReportSortFilters({ name, type: "asc" });
      } else if (
        report_sort_filter.name === name &&
        report_sort_filter.type === "asc"
      ) {
        setReportSortFilters({ name, type: "desc" });
      } else {
        setReportSortFilters({ name, type: "desc" });
      }
    } else {
      if (
        company_sort_filter.name === name &&
        company_sort_filter.type === "desc"
      ) {
        setCompanySortFilters({ name, type: "asc" });
      } else if (
        company_sort_filter.name === name &&
        company_sort_filter.type === "asc"
      ) {
        setCompanySortFilters({ name, type: "desc" });
      } else {
        setCompanySortFilters({ name, type: "desc" });
      }
    }
  };
  const updateList = () => {
    if (tab === "projects") {
      filterProjects();
      handleClose();
    } else if (tab === "technical reports") {
      //filterReports();
      handleClose();
    } else {
      filterCompanies();
      handleClose();
    }
  };
  const clearList = () => {
    if (tab === "projects") {
      setProjectSortFilters({ name: null, type: null });
      filterProjects();
      handleClose();
    } else if (tab === "technical reports") {
      setReportSortFilters({ name: null, type: null });
      //filterReports();
      handleClose();
    } else {
      setCompanySortFilters({ name: null, type: null });
      filterCompanies();
      handleClose();
    }
  };
  // const handleSortClick = () => {

  // }
  return (
    <div
      className="flex-boy-column"
      style={{ padding: "10px", height: "400px", width: "400px" }}
    >
      <Headline5 style={{ textTransform: "capitalize" }} color={Primary}>
        Sort {tab} By
      </Headline5>
      {tab === "projects" ? (
        <div style={{ width: "100%" }}>
          <div
            onClick={() => setSort("date")}
            className="flex-boy-row hover-sub"
            style={{
              padding: "20px 5px 20px",
              cursor: "pointer",
              borderRaduis: "5px",
            }}
          >
            <Subtitle1>last Update</Subtitle1>
            {project_sort_filter.name === "date" ? (
              project_sort_filter.type === "desc" ? (
                <ArrowDownwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              ) : (
                <ArrowUpwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              )
            ) : null}
          </div>
          <Divider />
          <div
            onClick={() => setSort("country")}
            className="flex-boy-row hover-sub"
            style={{
              padding: "20px 5px 20px",
              cursor: "pointer",
              borderRadius: "5px",
            }}
          >
            <Subtitle1>Country (A to Z)</Subtitle1>
            {project_sort_filter.name === "country" ? (
              project_sort_filter.type === "desc" ? (
                <ArrowDownwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              ) : (
                <ArrowUpwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              )
            ) : null}
          </div>
          <Divider />
          <div
            onClick={() => setSort("name")}
            className="flex-boy-row hover-sub"
            style={{
              padding: "20px 5px 20px",
              cursor: "pointer",
              borderRaduis: "5px",
            }}
          >
            <Subtitle1>Project Name</Subtitle1>
            {project_sort_filter.name === "name" ? (
              project_sort_filter.type === "desc" ? (
                <ArrowDownwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              ) : (
                <ArrowUpwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              )
            ) : null}
          </div>
          <Divider />
        </div>
      ) : tab === "technical reports" ? (
        <div style={{ width: "100%" }}>
          <div
            onClick={() => setSort("publication_date")}
            className="flex-boy-row hover-sub"
            style={{
              padding: "20px 5px 20px",
              cursor: "pointer",
              borderRaduis: "5px",
            }}
          >
            <Subtitle1>last Update</Subtitle1>
            {report_sort_filter.name === "publication_date" ? (
              report_sort_filter.type === "desc" ? (
                <ArrowDownwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              ) : (
                <ArrowUpwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              )
            ) : null}
          </div>
          <Divider />
          <div
            onClick={() => setSort("company_name")}
            className="flex-boy-row hover-sub"
            style={{
              padding: "20px 5px 20px",
              cursor: "pointer",
              borderRadius: "5px",
            }}
          >
            <Subtitle1>Company Name</Subtitle1>
            {report_sort_filter.name === "company_name" ? (
              report_sort_filter.type === "desc" ? (
                <ArrowDownwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              ) : (
                <ArrowUpwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              )
            ) : null}
          </div>
          <Divider />
          <div
            onClick={() => setSort("project_name")}
            className="flex-boy-row hover-sub"
            style={{
              padding: "20px 5px 20px",
              cursor: "pointer",
              borderRaduis: "5px",
            }}
          >
            <Subtitle1>Project Name</Subtitle1>
            {report_sort_filter.name === "project_name" ? (
              report_sort_filter.type === "desc" ? (
                <ArrowDownwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              ) : (
                <ArrowUpwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              )
            ) : null}
          </div>
          <Divider />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <div
            onClick={() => setSort("market_cap")}
            className="flex-boy-row hover-sub"
            style={{
              padding: "20px 5px 20px",
              cursor: "pointer",
              borderRaduis: "5px",
            }}
          >
            <Subtitle1>Market Capitalization</Subtitle1>
            {company_sort_filter.name === "market_cap" ? (
              company_sort_filter.type === "desc" ? (
                <ArrowDownwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              ) : (
                <ArrowUpwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              )
            ) : null}
          </div>
          <Divider />
          <div
            onClick={() => setSort("market_change")}
            className="flex-boy-row hover-sub"
            style={{
              padding: "20px 5px 20px",
              cursor: "pointer",
              borderRaduis: "5px",
            }}
          >
            <Subtitle1>% Change over past 30 days</Subtitle1>
            {company_sort_filter.name === "market_change" ? (
              company_sort_filter.type === "desc" ? (
                <ArrowDownwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              ) : (
                <ArrowUpwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              )
            ) : null}
          </div>
          <Divider />
          <div
            onClick={() => setSort("projects_count")}
            className="flex-boy-row hover-sub"
            style={{
              padding: "20px 5px 20px",
              cursor: "pointer",
              borderRaduis: "5px",
            }}
          >
            <Subtitle1>Number of Projects</Subtitle1>
            {company_sort_filter.name === "projects_count" ? (
              company_sort_filter.type === "desc" ? (
                <ArrowDownwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              ) : (
                <ArrowUpwardIcon
                  className="arrow-hover"
                  style={{ color: Primary }}
                />
              )
            ) : null}
          </div>
          <Divider />
        </div>
      )}
      <div className="flex flex-row w-full justify-between">
        <TailwindButtonOutline text="clear" onClick={() => clearList()} />

        <TailwindButton text="Update List" onClick={() => updateList()} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  project_sort_filter: state.authReducer.project_sort_filter,
  companies_search: state.authReducer.companies_search || [],
  projects: state.authReducer.projects_search || [],
  company_sort_filter: state.authReducer.company_sort_filter,
  report_sort_filter: state.authReducer.report_sort_filter,
  projects_length: state.authReducer.filtered_projects_length,
  companies_length: state.authReducer.filtered_companies_length,
});

const mapDispatchToProps = (dispatch) => ({
  searchAlgoliaCompanies: (data) => dispatch(searchAlgoliaCompanies(data)),
  filterProjects: (data) => dispatch(filterProjects(data)),
  filterCompanies: (data) => dispatch(filterCompanies(data)),
  filterReports: (data) => dispatch(filterReports(data)),
  setCompanyPage: (data) =>
    dispatch({ type: "SET_COMPANIES_PAGE", payload: data }),
  setProjectPage: (data) =>
    dispatch({ type: "SET_PROJECTS_PAGE", payload: data }),
  setProjectSortFilters: (data) =>
    dispatch({ type: "SET_PROJECT_SORT_FILTERS", payload: data }),
  setCompanySortFilters: (data) =>
    dispatch({ type: "SET_COMPANY_SORT_FILTERS", payload: data }),
  setReportSortFilters: (data) =>
    dispatch({ type: "SET_REPORT_SORT_FILTERS", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SortCard);
