import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { AgGridReact } from "ag-grid-react";
import Select from "react-select";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import moment from "moment";
import axios from "axios";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../assets/ProspectorDesignAssets/lottie2.json";
import { Subtitle1 } from "../../../components";
import SearchComponent from "./SearchComponent";
import { Exchanges, filingTypes, resTypes } from "../../../configure/constants";
import {
  apiRequest,
  getReportDetail,
} from "../../../store/Actions/AuthActions";
import { makeStyles } from "@material-ui/core/styles";
import { LicenseManager } from "ag-grid-enterprise";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import { Primary } from "../../../components";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import { data } from "autoprefixer";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid gainsboro",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 52,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    background: Primary,
    color: "white",
  },
  divider: {
    height: "100%",
  },
}));

const AgGridReportTable = ({ activeUser, getReportDetail, history }) => {
  const gridRef = useRef(null);
  const classes = useStyles();
  const [filters, setFilters] = useState({
    headline: "",
    companies: "",
    authors03: "",
    start_date: "",
    end_date: "",
    all_of_these_words: "",
    exact_phrase: "",
    exchanges: [],
    minerals: [],
    mineral_estimate: [],
    region: [],
    filing_type: [],
    sortModel: [],
  });

  const pageSize = 25;

  const continents = [
    "Asia",
    "North America",
    "Latin America",
    "Africa",
    "Antarctica",
    "Europe",
    "Oceania",
  ];

  const [regions, setRegions] = useState([]);
  const [minerals, setMinerals] = useState([]);

  useEffect(() => {
    const getFilterInfo = async () => {
      const countries_data = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/config/countries`
      );
      const minerals_data = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/config/minerals`
      );

      if (countries_data) {
        setRegions([...continents, ...countries_data.data.data.sort()]);
      }
      if (minerals_data) {
        setMinerals([...new Set(minerals_data.data.data)]);
      }
    };

    getFilterInfo();
  }, []);

  const sortData = (sortModel, data) => {
    const sortPresent = sortModel && sortModel.length > 0;
    if (!sortPresent) {
      return data;
    }
    const resultOfSort = data.slice();
    resultOfSort.sort((a, b) => {
      for (let k = 0; k < sortModel.length; k++) {
        const sortColModel = sortModel[k];
        const valueA = a[sortColModel.colId];
        const valueB = b[sortColModel.colId];
        if (valueA == valueB) continue;
        const sortDirection = sortColModel.sort === "asc" ? 1 : -1;
        return valueA > valueB ? sortDirection : sortDirection * -1;
      }
      return 0;
    });
    return resultOfSort;
  };

  const filterData = (filterModel, data) => {
    const filterPresent = filterModel && Object.keys(filterModel).length > 0;
    if (!filterPresent) {
      return data;
    }
    return data.filter((item) => {
      if (
        filterModel.year &&
        !filterModel.year.values.includes(item.year.toString())
      )
        return false;
      if (
        filterModel.country &&
        !filterModel.country.values.includes(item.country)
      )
        return false;
      return true;
    });
  };

  const sortAndFilter = (allOfTheData, sortModel, filterModel) => {
    return sortData(sortModel, filterData(filterModel, allOfTheData));
  };

  const destructureSortModel = (sortModel) => {
    const sortObject = {};
    sortModel.forEach((sort) => {
      sortObject[`sort_${sort.colId}`] = sort.sort;
    });
    return sortObject;
  };

  const destructureFilterModel = (filterModel) => {
    const filterObject = {};
    Object.keys(filterModel).forEach((key) => {
      const filter = filterModel[key];
      if (key === "document_type") {
        filterObject["filing_type"] = filter.values.join(",");
      } else if (key === "publication_date") {
        if (filter.type === "equals") {
          filterObject["start_at"] = moment(filter.dateFrom).format(
            "YYYY-MM-DD"
          );
        } else if (filter.type === "inRange") {
          filterObject["start_at"] = moment(filter.dateFrom).format(
            "YYYY-MM-DD"
          );
          filterObject["end_at"] = moment(filter.dateTo).format("YYYY-MM-DD");
        }
      } else if (key === "name") {
        filterObject["headline"] = filter.filter;
      } else if (filter.filterType === "set") {
        filterObject[key] = filter.values.join(",");
      } else if (filter.filterType === "text") {
        filterObject[`${key}`] = filter.filter;
      } else if (key === "searchQuery") {
        filterObject["searchQuery"] = filter;
      }
    });
    return filterObject;
  };
  const [dataLoading, setDataLoading] = useState(false);
  const fetchFilteredData = useCallback(
    async (params) => {
      const {
        startRow,
        endRow,
        successCallback,
        failCallback,
        sortModel,
        filterModel,
      } = params;
      const page = Math.floor(startRow / pageSize) + 1;
      if (page === 1) {
        setDataLoading(true);
      }
      try {
        const sortObject = destructureSortModel(sortModel);
        const filterObject = destructureFilterModel(filterModel);

        const response = await apiRequest.get(
          `/search?collection=documents&filing_type=Technical Report`,
          {
            params: { ...sortObject, ...filterObject, page, pageSize },
          }
        );
        const sortedAndFilteredData = sortAndFilter(
          response.data?.data,
          sortModel,
          filterModel
        );
        successCallback(sortedAndFilteredData, response.data?.meta?.total);
        setDataLoading(false);
      } catch (error) {
        failCallback();
        setDataLoading(false);
      }
    },
    [filters, pageSize]
  );

  useEffect(() => {
    dofiltering();
  }, [filters]);
  const dofiltering = async () => {
    if (gridRef.current) {
      console.log(gridRef.current);
      console.log(gridRef.current.api?.getFilterModel());

      // update the grid with the new filters
      gridRef.current.api?.paginationGoToPage(0);
      let filterModel = gridRef.current.api?.getFilterModel();

      console.log(filterModel);
      // remove the filter if it is empty string or array or null
      let new_filers = Object.keys(filters).reduce((acc, key) => {
        if (filters[key] && filters[key].length > 0) {
          acc[key] = filters[key];
        }
        return acc;
      }, {});
      console.log("newfilters", new_filers);
      let structured_filters = Object.keys(new_filers).reduce((acc, key) => {
        if (key === "headline") {
          acc["searchQuery"] = { filter: new_filers[key] };
        } else if (key === "filing_type") {
          acc["document_type"] = { values: new_filers[key] };
        } else if (key === "publication_date") {
          acc["publication_date"] = {
            type: "equals",
            dateFrom: new_filers[key],
          };
        } else {
          acc[key] = { values: new_filers[key] };
        }
        return acc;
      }, {});
      console.log("structured_filters", structured_filters);

      let new_filter = { ...filterModel, ...structured_filters };

      await gridRef.current?.api?.setFilterModel(new_filter);

      console.log("new filter model", gridRef.current.api?.getFilterModel());
    }
  };

  const onExternalFilterChange = useCallback((name, selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilters((prevFilters) => ({ ...prevFilters, [name]: values }));
  }, []);

  const onInputChange = useCallback((e) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const onGridReady = useCallback(
    (params) => {
      params.api.setGridOption("datasource", {
        getRows: fetchFilteredData,
      });
    },
    [fetchFilteredData]
  );

  const onSortChanged = useCallback(() => {
    const sortModel = gridRef.current?.getSortModel();
    setFilters((prevFilters) => ({ ...prevFilters, sortModel }));
  }, []);

  const onFilterChanged = useCallback(() => {
    console.log(gridRef.current.api?.getFilterModel());
    gridRef.current?.api?.paginationGoToPage(0);
  }, []);

  const CustomLoadingOverlay = () => (
    <div className="flex-boy-row">
      <Player
        autoplay
        loop
        src={animationData}
        style={{ height: "100px", width: "100px" }}
      />
      <Subtitle1>Loading</Subtitle1>
    </div>
  );

  const exchangesOptions = Exchanges.map((exchange) => ({
    value: exchange,
    label: exchange,
  }));
  const mineralsOptions = {
    values: minerals,
    filter: (cellValue, filterValue) => {
      if (filterValue.length === 0) return true;
      return filterValue.includes(cellValue);
    },
  };
  const mineralEstimateOptions = resTypes.map((resType) => ({
    value: resType.name,
    label: resType.name,
  }));

  const filingTypeOptions = {
    values: filingTypes,
    filter: (cellValue, filterValue) => {
      if (filterValue.length === 0) return true;
      return filterValue.includes(cellValue);
    },
  };

  const regionFilter = {
    values: regions,
    filter: (cellValue, filterValue) => {
      if (filterValue.length === 0) return true;
      return filterValue.includes(cellValue);
    },
  };

  const autoSizeStrategy = {
    type: "fitGridWidth",
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [searchValue, setSearchValue] = useState("");
  const onFinish = useCallback(() => {
    console.log("onFinish", searchValue);
  }, [searchValue]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        onFinish();
      }
    },
    [onFinish]
  );

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
    setFilters((prevFilters) => ({ ...prevFilters, headline: e.target.value }));
  };

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      floatingFilter: true,
      sortable: false,
    }),
    []
  );

  const goToDetail = useCallback(
    (id) => {
      getReportDetail({ id: id, history });
    },
    [getReportDetail, history]
  );

  const columnDefs = [
    {
      headerName: "Headline",
      field: "name",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
      },

      width: 600,
    },

    {
      headerName: "Project",
      field: "project_name",
      filter: "agTextColumnFilter",

      filterParams: {
        filterOptions: ["contains"],
      },
    },
    // authors
    {
      headerName: "Authors",
      field: "authors",
      filter: "agSetColumnFilter",
      filterParams: {
        values: [],
        filter: "agSetColumnFilter",
      },
    },
    // minerals
    {
      headerName: "Minerals",
      field: "minerals",
      filter: "agSetColumnFilter",
      filterParams: mineralsOptions,
    },
    //  mineral estimate
    {
      headerName: "Mineral Estimate",
      field: "mineral_estimate",
      filter: "agSetColumnFilter",
      filterParams: mineralEstimateOptions,
    },
    {
      headerName: "Exchange",
      field: "exchange",
      filter: "agSetColumnFilter",
      filterParams: exchangesOptions,
      hide: true,
    },
    {
      headerName: "Region",
      field: "region",
      filter: "agSetColumnFilter",
      filterParams: regionFilter,
      hide: true,
    },
    {
      headerName: "Country",
      field: "country",
      filter: "agSetColumnFilter",
      filterParams: {
        values: regions,
        filter: "agSetColumnFilter",
      },
      hide: true,
    },

    {
      headerName: "Filing Type",
      field: "document_type",
      pinned: "right",
      filter: "agSetColumnFilter",
      filterParams: filingTypeOptions,
    },
    {
      headerName: "Publication Date",
      field: "publication_date",
      valueFormatter: (params) =>
        params.value ? moment(params.value).format("DD MMM YYYY") : "",
      filter: "agDateColumnFilter",
      width: 200,
      pinned: "left",
      resizable: false,
      filterParams: {
        filterOptions: ["equals", "inRange"],
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    },
    // searchQuery
    {
      headerName: "Query",
      field: "searchQuery",
      width: 0,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
      },
      hide: true,
    },
  ];
  return (
    <div>
      <div className="flex-boy-row">
        <div
          className="flex-boy-row"
          style={{ flex: 1, position: "relative", height: "70px" }}
        >
          <Paper className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder={"Open text search news and filings..."}
              defaultValue={searchValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <Divider className={classes.divider} orientation="vertical" />
            <div
              style={{
                background: Primary,
                padding: "5px",
                borderTopRightRadius: "5px",
                borderBottomRightRadius: "5px",
              }}
            >
              <IconButton
                onClick={onFinish}
                className={classes.iconButton}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </div>
          </Paper>
        </div>
        {activeUser && (
          <button
            onClick={handleClickOpen}
            type="button"
            className="inline-flex items-center ml-1 min-w-[105px] px-3.5 justify-center py-4 border border-emerald-500 text-xs font-bold rounded shadow-sm text-emerald-600 bg-white hover:bg-emerald-600 hover:text-white focus:outline-none"
          >
            Save Search
          </button>
        )}
      </div>

      <div
        className="ag-theme-custom"
        style={{ height: "60vh", width: "100%" }}
      >
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowModelType="infinite"
          cacheBlockSize={pageSize}
          maxBlocksInCache={10}
          suppressServerSideFullWidthLoadingRow={true}
          loading={dataLoading}
          loadingOverlayComponent={CustomLoadingOverlay}
          loadingOverlayComponentParams={{
            loadingMessage: "One moment please...",
          }}
          noRowsOverlayComponentParams={{
            noRowsMessageFunc: () => "No rows found",
          }}
          onSortChanged={onSortChanged}
          onGridReady={onGridReady}
          onFilterChanged={onFilterChanged}
          onRowClicked={(e) => goToDetail(e.data?.id)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getReportDetail: (data) => dispatch(getReportDetail(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AgGridReportTable));
