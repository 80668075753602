import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import DetailCellRenderer from "../../../components/DrillingResultsDetails";
import axios from "axios";
import { connect } from "react-redux";
import { getReportDetail } from "../../../store/Actions/AuthActions";
import { withRouter } from "react-router";

export const DrillingResults = ({ project, getReportDetail, history }) => {
  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [rowData, setRowData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedStage, setSelectedStage] = useState("All Stages");
  const [selectedType, setSelectedType] = useState("All Types");

  useEffect(() => {
    fetchFilteredData();
  }, [project]);

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Headline",
        field: "headline",
        sortable: true,
        filter: true,
        cellRenderer: "agGroupCellRenderer",
      },

      {
        headerName: "Drilling stage",
        field: "phase_of_drilling",
        sortable: true,
        filter: true,
      },
      // meters drilled
      {
        headerName: "Meters Drilled",
        field: "total_meters_drilled",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Holes Drilled",
        field: "number_of_holes",
        sortable: true,
        filter: true,
      },

      {
        headerName: "Highlight Intercept",
        field: "highlight_intercept",
        sortable: true,
        filter: true,
      },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      floatingFilter: true,
      sortable: true,
    }),
    []
  );

  const detailCellRendererParams = useMemo(
    () => ({
      detailGridOptions: {
        columnDefs: [
          { headerName: "Length (meters)", field: "length" },
          { headerName: "Mineral", field: "mineral" },
          { headerName: "Grade", field: "grade" },
          { headerName: "Grade UOM", field: "gradeUOM" },
          { headerName: "Depth (meters)", field: "depth" },
        ],
        defaultColDef: {
          flex: 1,
          minWidth: 100,
          resizable: true,
          filter: true,
          sortable: true,
        },
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.details);
      },
    }),
    []
  );

  const onExportClick = () => {
    gridRef.current.api.exportDataAsExcel();
  };

  const handleStageChange = (e) => {
    setSelectedStage(e.target.value);
    filterData(e.target.value, selectedType);
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    filterData(selectedStage, e.target.value);
  };

  const filterData = (stage, type) => {
    let filtered = rowData;
    if (stage !== "All Stages") {
      filtered = filtered.filter((row) => row.stage === stage);
    }
    if (type !== "All Types") {
      filtered = filtered.filter((row) => row.project.includes(type));
    }
    setFilteredData(filtered);
  };

  const onGridReady = (params) => {
    gridRef.current.api = params.api;
  };

  const fetchFilteredData = async () => {
    const response = await axios.get(
      `https://py.prospectormatch.com/api/projects/${project.id}/drilling-results`
    );
    setRowData(response.data?.data);
    setFilteredData(response.data?.data);
  };
  const goToDetail = (id) => {
    getReportDetail({ id: id, history });
  };

  useEffect(() => {
    return () => {
      gridRef?.current?.api.destroy();
    };
  }, []);
  return (
    <div className="p-4 ">
      {filteredData.length > 0 ? (
        <>
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold">Drilling Results</h1>
            <button
              onClick={onExportClick}
              type="button"
              className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-emerald-600 shadow-sm ring-1 ring-inset ring-emerald-300 hover:bg-emerald-50"
            >
              Download Data to Excel
            </button>
          </div>

          <div className="grid grid-cols-3 gap-4 mb-4 ag-theme-custom">
            <div className="col-span-3">
              <div style={{ width: "100%" }}>
                <AgGridReact
                  ref={gridRef}
                  rowData={filteredData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  masterDetail={true}
                  domLayout="autoHeight"
                  detailRowHeight={500}
                  detailCellRenderer={DetailCellRenderer}
                  detailCellRendererParams={detailCellRendererParams}
                  onGridReady={onGridReady}
                  onRowClicked={(e) => goToDetail(e.data.document_id)}
                />
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.authReducer.selectedProject,
});
const mapDispatchToProps = (dispatch) => ({
  getReportDetail: (data) => dispatch(getReportDetail(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DrillingResults));
