/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Subtitle1,
  Headline5,
  Subtitle2span,
  Headline6,
  Button,
  NotifyDiv,
} from "../../../components";
import { withRouter } from "react-router";
import { TextField } from "@material-ui/core";
import Logo from "../../../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon.png";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { userSignup, linkedinLogin } from "../../../store/Actions/AuthActions";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00AC69",
        border: "2px solid",
      },
      "&:hover fieldset": {
        borderColor: "#00AC69",
      },
    },
  },
}));

export const Page8 = ({ swapAuthPage, history, userSignup, activeUser }) => {
  const classes = useStyles();
  const [go, setContinue] = useState(false);
  const [data, setData] = useState({
    password: "",
    password_confirm: "",
  });
  const [errors, seterrors] = useState({
    password: null,
    password_confirm: null,
  });
  useEffect(() => {
    switchAuth();
  }, [activeUser]);
  const switchAuth = () => {
    if (activeUser) {
      history.push("/browse");
    }
  };
  const change_text = (e) => {
    let new_data = { ...data };
    let new_errors = { ...errors };
    //

    let id = e.target.name;
    if (id === "password") {
      if (e.target.value.length < 6) {
        new_errors.password = "Password must be atleast 6 characters long";
        seterrors(new_errors);
      } else {
        new_errors.password = null;
        seterrors(new_errors);
      }
      setContinue(true);
    }
    new_data[e.target.name] = e.target.value;
    setData(new_data);
  };

  const validate = () => {
    let new_data = { ...data };
    let new_errors = { ...errors };
    if (new_data.password === "") {
      new_errors.password = "Please enter Your Password";
    } else if (new_data.password.length < 6) {
      new_errors.password = "Password must be atleast 6 characters long";
      seterrors(new_errors);
    } else if (new_data.password_confirm !== new_data.password) {
      new_errors.password_confirm = "Passwords dont match";
      seterrors(new_errors);
    } else {
      userSignup(data.password);
    }
  };
  return (
    <Fragment>
      <Headline6 color="#00AC69">
        Almost done. Please set your Prospector password so we can get started.
      </Headline6>
      <div className="circle-progress">
        <div className="progress">
          <CircularProgress
            size="100%"
            thickness={2}
            variant="determinate"
            value={80}
          />
        </div>

        <img src={Logo} alt="logo" />
      </div>

      <div>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            id="Password"
            error={errors.password}
            helperText={errors.password}
            onChange={change_text}
            name="password"
            color="primary"
            style={{ margin: 10, width: 300 }}
            label="New Password"
            type="Password"
            placeholder="Enter your New Password"
            variant="outlined"
          />
          <TextField
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              },
            }}
            id="Password-confirm"
            error={errors.password_confirm}
            helperText={errors.password_confirm}
            onChange={change_text}
            name="password_confirm"
            style={{ margin: 10, width: 300 }}
            label="Confirm Password"
            type="Password"
            placeholder="Confirm Password"
            variant="outlined"
          />
        </form>
      </div>
      <div className="flex-boy-row" style={{}}>
        <Subtitle2span>
          By signing up, you confirm that you’ve read and accepted our{" "}
          <Subtitle2span color="#00AC69">User Notice</Subtitle2span> and{" "}
          <Subtitle2span color="#00AC69">Privacy Policy.</Subtitle2span>
        </Subtitle2span>
      </div>
      <Button
        onClick={() => (go ? validate() : null)}
        style={{ width: "80%", backgroundColor: go ? "#00AC69" : "gainsboro" }}
      >
        Complete Sign Up
      </Button>
      {/* <Button onClick={()=>history.push('/matches')} style={{width:"80%"}}>Complete Sign Up</Button> */}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  activeUser: state.authReducer.activeUser,
  signup: state.authReducer.signUp,
});

const mapDispatchToProps = (dispatch) => ({
  userSignup: (data) => dispatch(userSignup(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Page8));
