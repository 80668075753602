/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {
  Subtitle1,
  Headline5,
  Subtitle2,
  Headline6,
  Button,
  NotifyDiv,
} from "../../../components";
import { TextField } from "@material-ui/core";
import Logo from "../../../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon.png";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { updateSignUpDetails } from "../../../store/Actions/AuthActions";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00AC69",
        border: "2px solid",
      },
      "&:hover fieldset": {
        borderColor: "#00AC69",
      },
    },
  },
}));

export const Page1 = ({ swapAuthPage, updateSignUpDetails }) => {
  const classes = useStyles();
  const [go, setContinue] = useState(false);
  const [data, setData] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });
  const [errors, seterrors] = useState({
    email: null,
    firstName: null,
    lastName: null,
  });
  const change_text = (e) => {
    let new_data = { ...data };
    let new_errors = { ...errors };
    //

    let id = e.target.name;
    if (id === "email") {
      if (!/\S+@\S+\.\S+/.test(e.target.value)) {
        new_errors.email = "Invalid email address";
        seterrors(new_errors);
      } else {
        new_errors.email = null;
        seterrors(new_errors);
      }
      setContinue(true);
    }
    new_data[e.target.name] = e.target.value;
    setData(new_data);
    Object.values(new_data).every((x) => x === null || x === "");
  };

  const validate = () => {
    let new_data = { ...data };
    let new_errors = { ...errors };
    if (new_data.email === "") {
      new_errors.email = "Please enter Your email address";
    } else if (!/\S+@\S+\.\S+/.test(new_data.email)) {
      new_errors.email = "Invalid email address";
      seterrors(new_errors);
    } else if (new_data.firstName === "") {
      new_errors.firstName = "Please enter Your First Name ";
      seterrors(new_errors);
    } else if (new_data.lastName === "") {
      new_errors.lastName = "Please enter Your Last Name ";
      seterrors(new_errors);
    } else {
      updateSignUpDetails({ ...data });
      swapAuthPage("Page3");
    }
  };
  return (
    <Fragment>
      <Headline6 color="#00AC69">
        We need to gather a little information about you before we get started.
      </Headline6>

      <div>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            id="email"
            error={errors.email}
            helperText={errors.email}
            onChange={change_text}
            name="email"
            color="primary"
            style={{ margin: 10, width: 300 }}
            label="Email Address"
            required
            placeholder="Enter your Email Address"
            variant="outlined"
          />
          <TextField
            id="firstName"
            error={errors.firstName}
            helperText={errors.firstName}
            onChange={change_text}
            name="firstName"
            color="primary"
            style={{ margin: 10, width: 300 }}
            label="First Name"
            placeholder="Enter your First Name"
            variant="outlined"
          />
          <TextField
            id="lastName"
            error={errors.lastName}
            helperText={errors.lastName}
            onChange={change_text}
            name="lastName"
            color="primary"
            style={{ margin: 10, width: 300 }}
            label="Last Name"
            placeholder="Enter your Last Name"
            variant="outlined"
          />
        </form>
      </div>
      <Button
        onClick={() => (go ? validate() : null)}
        style={{ width: "80%", backgroundColor: go ? "#00AC69" : "gainsboro" }}
      >
        Continue
      </Button>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updateSignUpDetails: (data) => dispatch(updateSignUpDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Page1);
