/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import _ from "lodash";
import { Primary } from "../general";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts-fix";
import Widgets from "fusioncharts/fusioncharts.widgets";
import moment from "moment";
import { getCommodityData } from "../../store/Actions/AuthActions";
ReactFC.fcRoot(FusionCharts, Charts, Widgets);

export const Sparkline = ({ color, data, company, stockData }) => {
  const [median, setMedian] = useState(70);
  const [dataSource, setdataSource] = useState({
    chart: {
      numberprefix: "$",
      valueFont: "Gotham-Bold",
      theme: "fusion",
      showclosevalue: "1",
      showopenvalue: "1",
      setadaptiveymin: "1",
      showHighLowValue: "0",
      showOpenValue: "0",
      showCloseValue: "0",
    },
    dataset: [
      {
        data: [],
      },
    ],
  });

  const [chartConfigs, setChartConfigs] = useState({
    type: "sparkline",
    width: "100%",
    height: 50,
    dataFormat: "json",
    dataSource: dataSource,
  });

  const [dummy_data, setData] = useState({
    labels: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    datasets: [
      {
        label: "Stock",
        fill: false,
        lineTension: 0,
        backgroundColor: "#FFFFFF",
        borderColor: color ? color : "#B00020",
        borderWidth: 2,
        data: [65, 59, 76, 69, 80, 81, 56],
      },
    ],
  });

  useEffect(() => {
    if (stockData) {
      generateChartData();
    }

    return () => {};
  }, [stockData]);

  const generateChartData = () => {
    let history =
      stockData?.data?.filter((item) => item.open === 0)?.length > 9
        ? _.sortBy(stockData.data, function (dateObj) {
            return new Date(dateObj.startDateTime);
          })
        : _.sortBy(stockData.data, function (dateObj) {
            return new Date(dateObj.startDateTime);
          })?.filter((item) => item.open > 0);

    if (history && history.length) {
      console.log(history);
      const new_data = { ...dataSource };
      const config_data = { ...chartConfigs };
      const median = history.length
        ? history.reduce((a, b) => a + (b.high + b.low) / 2, 0) / history.length
        : "none";
      let trendState =
        history.length &&
        history.length > 1 &&
        history[history.length - 1].open - history[history.length - 2].open >= 0
          ? Primary
          : "#B00020";
      new_data.chart.lineColor = trendState;
      new_data.dataset[0].data = history.map((item) => {
        return {
          value: item.open,
          tooltext: `${moment(item.startDateTime).format("DD MMM YYYY")}  ${
            item.open
          } ${
            company?.exchange?.includes("TSX") ||
            company?.exchange?.includes("CSE")
              ? "CAD"
              : company?.exchange?.includes("ASX") ||
                company?.exchange?.includes("ASE")
              ? "AUD"
              : "USD"
          }`,
        };
      });

      config_data.dataSource = new_data;

      setdataSource(new_data);
      setChartConfigs(config_data);
      setMedian(median);
      setData(new_data);
    }
  };

  const options = {
    title: {
      display: false,
    },

    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          display: false,
        },
      ],
      xAxes: [
        {
          display: false,
        },
      ],
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    annotation: {
      annotations: [
        {
          drawTime: "afterDatasetsDraw",
          borderColor: "#00000061",
          borderDash: [2, 2],
          borderWidth: 2,
          mode: "horizontal",
          type: "line",
          value: median,
          scaleID: "y-axis-0",
        },
      ],
    },

    maintainAspectRation: false,
  };

  return (
    <>
      {dataSource.dataset[0].data.length ? <ReactFC {...chartConfigs} /> : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  stockData: state.authReducer.commodity_stock,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Sparkline);
