/* eslint-disable no-unused-vars */
import { toast } from "react-toastify";
import _ from "lodash";

const initialState = {
  loaded_reports: [],
};

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    // User Login
    case "SET_LOADED_REPORTS":
      return {
        ...state,
        loaded_reports: action.payload,
      };
    default:
      return state;
  }
}
