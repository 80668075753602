import React, { useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts-fix";
import { apiRequest } from "../../../store/Actions/AuthActions";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../assets/ProspectorDesignAssets/lottie2.json";
import ContentLoader from "react-content-loader";
import _ from "lodash";
charts(FusionCharts);

const AreaChart = ({
  project,
  year,
  mineral,
  classification,
  production_basis,
  type,
  color,
}) => {
  const [dataSource, setdataSource] = useState(null);
  useEffect(() => {
    setdataSource(null);
    fetchData();
    return () => {};
  }, [production_basis, year, mineral]);

  const fetchData = async () => {
    const data = await apiRequest
      .get(
        `projects/${project.id}/mrr-annual-growth?production_basis=${production_basis}&mineral=${mineral}&classification=${classification}`
      )
      .catch((err) => console.log(err));
    if (data?.data?.data) {
      setdataSource({
        chart: {
          showYAxisValues: "0",
          showAlternateHGridColor: "0",
          divLineAlpha: "60",
          usePlotGradientColor: "0",
          showPlotBorder: "0",

          divLineColor: "#ffffff",
          showDivLineValues: "0",
          showaxislines: "0",
          showXAxisValues: "0",
          xaxisminvalue: "23",
          xaxismaxvalue: "95",
          ynumberprefix: "$",
          yaxisminvalue: "1200",
          xnumbersuffix: "°F",
          xAxisName: `Annual ${classification} resource growth`,
          plotBorderColor: "#7776cf",
          plotBorderThickness: "0.5",
          anchorHoverColor: "#7776cf",
          anchorRadius: "2px",
          showBorder: "0",
          showCanvasBorder: "0",
          canvasbgAlpha: "0",
          canvasbgcolor: "0",
          bgcolor: "#ffffff",
          plotFillColor: color,
          showValues: "0",
          palettecolors: "#B0DBA8,#B9DDF1",
        },
        categories: [
          {
            category: _.uniq(data.data.data.map((item) => item.label))
              .sort()
              .map((obj) => ({ label: obj })),
          },
        ],
        dataset: Object.keys(_.groupBy(data.data.data, "classification")).map(
          (item) => {
            let obj = {
              seriesname: item,
              data: _.orderBy(
                data.data.data.filter((idx) => idx.classification === item),
                "label"
              ).map((val) => ({ value: val.value })),
            };
            return obj;
          }
        ),
      });
    }
  };
  return (
    <div>
      {dataSource ? (
        <ReactFusioncharts
          type="msarea"
          width="100%"
          height="350"
          dataFormat="JSON"
          dataSource={dataSource}
        />
      ) : (
        <ContentLoader
          viewBox="0 0 600 350"
          style={{ width: "100%", height: "350px" }}
        >
          <rect
            x="20"
            y="8"
            rx="0"
            ry="0"
            style={{ width: "100%", height: "350px" }}
          />
        </ContentLoader>
      )}
    </div>
  );
};

export default AreaChart;
