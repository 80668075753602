/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { MainPage } from "../../components";
import AuthImage from "../../assets/ProspectorDesignAssets/Mining Futurist@2x.png";
import ProspectorTagline from "../../assets/ProspectorDesignAssets/Prospector_3D Logo_Lockup_Horizontal_with Tagline.png";
import Login from "./Login";
import SignUp from "./SignUp/index.js";
import PasswordReset from "./PasswordReset/index";
import { Switch, Route } from "react-router-dom";
import { Grid, Box } from "@material-ui/core";
import background from "../../assets/ProspectorDesignAssets/back.png";
import {
  userLogin,
  linkedinLogin,
  appleLogin,
  loginWithNLPortal,
} from "../../store/Actions/AuthActions";
import NewsignUp from "./NewsignUp";
import DayTrial from "./NewsignUp/DayTrial";

export const Authentication = ({ loginWithNLPortal }) => {
  return (
    <MainPage marginTop="0px" paddingTop="0px" style={{ overflow: "hidden" }}>
      {/* <Grid container style={{ height: "100%" }}>
        <Grid item xs={0} lg={6}>
          <Box
            display={{ xs: "none", lg: "block" }}
            className="flex-boy-column logostuff"
            style={{
              overflow: "hidden",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              padding: "20px",
              boxSizing: "border-box",
            }}
          >
            <img style={{ width: "25vw" }} src={ProspectorTagline} alt="auth" />
            <img style={{ width: "50vw" }} src={AuthImage} alt="auth" />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div
            className="loginstuff"
            style={{
              flex: 1,
              height: "100%",
              overflowY: "hidden",
              justifyContent: "center",
              boxSizing: "border-box",
              background: `url(${background}),#00AC69`,
            }}
          >
            <div
              className="flex-boy-column"
              style={{
                flex: 1,
                height: "100%",
                overflowY: "hidden",
                justifyContent: "center",
                padding: "20px",
                boxSizing: "border-box",
                background: "#00AC69B3",
              }}
            > */}
      <Switch>
        <Route path="/auth/login" component={Login} />
        <Route path="/auth/sign-in" component={NewsignUp} />

        <Route path="/auth/sign-up" component={DayTrial} />
        <Route path="/auth/password-reset" component={PasswordReset} />
      </Switch>
      {/* {
                    currentAuthPage === "Login" ? <Login swapAuthPage={swapAuthPage}/> : currentAuthPage === "Signup" ? <SignUp swapAuthPage={swapAuthPage}/> : <PasswordReset swapAuthPage={swapAuthPage}/>
                } */}
      {/* </div>
          </div>
        </Grid>
      </Grid> */}
    </MainPage>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  userLogin: (data) => dispatch(userLogin(data)),
  linkedinLogin: () => dispatch(linkedinLogin()),
  appleLogin: () => dispatch(appleLogin()),
  loginWithNLPortal: () => dispatch(loginWithNLPortal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
