import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Headline6,
  Headline5,
  TailwindButtonOutline,
} from "../../../components";

const people = [
  {
    id: 1,
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },
  {
    id: 2,
    name: "Mary Boston",
    title: "Back-end Developer",
    email: "Mary.Boston@example.com",
    role: "Member",
  },
];
import { CheckIcon, ThumbUpIcon, UserIcon } from "@heroicons/react/solid";
import { Divider } from "@material-ui/core";
import { apiRequest } from "../../../store/Actions/AuthActions";

const timeline = [
  {
    id: 1,
    content: "Applied to",
    target: "Front End Developer",
    href: "#",
    date: "Sep 20",
    datetime: "2020-09-20",
    icon: CheckIcon,
    iconBackground: "bg-emerald-500",
  },
  {
    id: 2,
    content: "Advanced to phone screening by",
    target: "Bethany Blake",
    href: "#",
    date: "Sep 22",
    datetime: "2020-09-22",
    icon: CheckIcon,
    iconBackground: "bg-emerald-500",
  },
  {
    id: 3,
    content: "Completed phone screening with",
    target: "Martha Gardner",
    href: "#",
    date: "Sep 28",
    datetime: "2020-09-28",
    icon: CheckIcon,
    iconBackground: "bg-emerald-500",
  },
  {
    id: 4,
    content: "Advanced to interview by",
    target: "Bethany Blake",
    href: "#",
    date: "Sep 30",
    datetime: "2020-09-30",
    icon: CheckIcon,
    iconBackground: "bg-emerald-500",
  },
  {
    id: 5,
    content: "Completed interview with",
    target: "Katherine Snyder",
    href: "#",
    date: "Oct 4",
    datetime: "2020-10-04",
    icon: CheckIcon,
    iconBackground: "bg-emerald-500",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export const BoardMembers = ({ members }) => {
  const [memberInView, setmemberInView] = useState(1);
  const switchMember = (id) => {
    if (id === memberInView) {
      setmemberInView(null);
    } else {
      setmemberInView(id);
    }
  };

  return (
    <div className="w-full">
      {members && (
        <>
          <Headline5>Board Members and Executives</Headline5>

          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Title
                          </th>

                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Role
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 "
                          >
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {members?.map((person) => (
                          <>
                            <tr key={person.email}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                                {person.name}
                              </td>
                              <td className=" px-3 py-4 text-sm text-gray-500">
                                {person.title}
                              </td>

                              <td className=" px-3 py-4 text-sm text-gray-500">
                                {person.job_functions.toString()}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium ">
                                <TailwindButtonOutline
                                  onClick={() => switchMember(person.id)}
                                  text={
                                    memberInView === person.id
                                      ? "View Less"
                                      : "View More"
                                  }
                                />
                              </td>
                            </tr>

                            <td
                              colSpan={4}
                              className="border-transparent divide-y-0"
                              style={{
                                border:
                                  memberInView !== person.id
                                    ? "transparent"
                                    : "gainsboro",
                              }}
                            >
                              {memberInView === person.id && <Divider />}
                              <div
                                className={`${
                                  memberInView !== person.id
                                    ? "max-h-0"
                                    : "max-h-[600px] xl:max-h-[600px] overflow-y-scroll border-t-slate-300 border-solid border-1"
                                } overflow-hidden w-full transition-[max-height] duration-700 ease-in-out grid grid-cols-12 pl-6 pr-3 gap-4 `}
                              >
                                <div className="col-span-3">
                                  <div className="w-full flex  my-3 flex-row justify-between">
                                    <div className="flex-1">
                                      <p className="text-sm font-light">
                                        {person.total_compensation
                                          ? `$ ${person.total_compensation?.toLocaleString()}`
                                          : "Unavailable"}
                                      </p>
                                      <p className="text-sm font-semibold">
                                        Total Compensation
                                      </p>
                                    </div>
                                    <div className="flex-1">
                                      <p className="text-sm font-light">
                                        {" "}
                                        {person.salary
                                          ? `$ ${person.salary?.toLocaleString()}`
                                          : "Unavailable"}
                                      </p>
                                      <p className="text-sm font-semibold">
                                        Salary
                                      </p>
                                    </div>
                                  </div>
                                  <div className="w-full flex  my-3 flex-row justify-between">
                                    <div className="flex-1">
                                      <p className="text-sm font-light">
                                        {person.age}
                                      </p>
                                      <p className="text-sm font-semibold">
                                        Age
                                      </p>
                                    </div>
                                    <div className="flex-1">
                                      <p className="text-sm font-light">
                                        {person.gender}
                                      </p>
                                      <p className="text-sm font-semibold">
                                        Gender
                                      </p>
                                    </div>
                                  </div>
                                  <div className="w-full flex  my-3 flex-row justify-between">
                                    <div className="flex-1">
                                      <p className="text-sm font-light">
                                        {person.highest_degree}
                                      </p>
                                      <p className="text-sm font-semibold">
                                        Highest Degree
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-span-9 pl-6 pr-5">
                                  <p className="break-all text-sm font-light">
                                    {person.biography}
                                  </p>
                                  <div className="flow-root my-4">
                                    <ul role="list" className="-mb-8">
                                      {person.job_history?.map(
                                        (event, index) => (
                                          <li>
                                            <div className="relative pb-8">
                                              {index !==
                                              person.job_history.length - 1 ? (
                                                <span
                                                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                                  aria-hidden="true"
                                                />
                                              ) : null}
                                              <div className="relative flex space-x-3">
                                                <div>
                                                  <span
                                                    className={classNames(
                                                      "bg-emerald-500",
                                                      "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                                                    )}
                                                  >
                                                    <CheckIcon
                                                      className="h-5 w-5 text-white"
                                                      aria-hidden="true"
                                                    />
                                                  </span>
                                                </div>
                                                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                  <div className="flex flex-row">
                                                    <p className="text-sm text-gray-500">
                                                      {event.job_function_name}-
                                                    </p>
                                                    <p
                                                      onClick={() =>
                                                        window.open(
                                                          event.company_url,
                                                          "_blank"
                                                        )
                                                      }
                                                      className={classNames(
                                                        `${
                                                          event.company_url
                                                            ? "underline cursor-pointer"
                                                            : ""
                                                        }`,
                                                        "text-sm text-gray-500"
                                                      )}
                                                    >
                                                      {event.company_name}
                                                    </p>
                                                  </div>
                                                  <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                                    <time
                                                      dateTime={
                                                        event.start_date
                                                      }
                                                    >
                                                      {event.start_date}
                                                    </time>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BoardMembers);
