/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { connect } from "react-redux";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import SearchComponent from "../searchComponent";
import {
  Headline4,
  ButtonCircle,
  Navigation,
  Logo,
  Search,
  NavList,
  Primary,
  StyledLink,
  LogoutLink,
  StyledLink2,
  NavItem,
  Subtitle1,
  Subtitle2,
  Headline6,
  NavButton,
  NavButton2,
} from "../../components";
import logoImg from "../../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon1.png";
import { loginWithNLPortal } from "../../store/Actions/AuthActions";
import axios from "axios";
import { withRouter } from "react-router-dom";

export const NonLoggedNav = ({ loginWithNLPortal, history }) => {
  const [viewDrop, setView] = useState(false);
  const [viewDrop2, setView2] = useState(false);
  return (
    <Navigation>
      <Logo onClick={() => history.push("/browse")}>
        <img
          onClick={() => history.push("/browse")}
          style={{ width: "100%" }}
          src={logoImg}
          alt="logo"
        />
      </Logo>
      <Search>
        <SearchComponent />
      </Search>

      <NavList>
        <NavItem
          onMouseOver={() => setView(true)}
          onMouseOut={() => setView(false)}
          style={{ position: "relative" }}
        >
          <StyledLink2
            onClick={() =>
              window.open("https://www.prospectorportal.com/press", "_blank")
            }
          >
            Product
            <KeyboardArrowDown
              className="rotate"
              style={{
                transform: viewDrop ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.2s ease",
              }}
            />
          </StyledLink2>
          <div
            className="nav-dropdown"
            style={{
              opacity: viewDrop ? 1 : 0,
              transition: "transform 0.4s ease-out, opacity 0.1s ease-out",
              visibility: viewDrop ? "visible" : "hidden",
            }}
          >
            <ul
              className="unifiednav__container unifiednav__container_sub-nav"
              data-depth="0"
              data-auto="sub-pages"
            >
              {" "}
              <li className="unifiednav__item-wrap" data-auto="more-pages">
                {" "}
                <a
                  target="_blank"
                  href="https://www.prospectorportal.com/search-mining-reports"
                  className="unifiednav__item"
                >
                  {" "}
                  <span
                    className="nav-item-text "
                    data-link-text="
           Search Mining Reports
          "
                    data-auto="page-text-style"
                  >
                    Search Mining Reports
                    <span className="icon icon-angle-right"></span>
                  </span>
                </a>
              </li>
              <li className="unifiednav__item-wrap" data-auto="more-pages">
                {" "}
                <a
                  href="https://webapp.prospectormatch.com/#/"
                  className="unifiednav__item"
                >
                  {" "}
                  <span
                    className="nav-item-text "
                    data-link-text="
           Report Map
          "
                    data-auto="page-text-style"
                  >
                    Browse projects and companies
                    <span className="icon icon-angle-right"></span>
                  </span>
                </a>
              </li>
              {/* <li className="unifiednav__item-wrap" data-auto="more-pages">
                {" "}
                <a href="/report-map" className="unifiednav__item">
                  {" "}
                  <span
                    className="nav-item-text "
                    data-link-text="
           Report Map
          "
                    data-auto="page-text-style">
                    Research portal<span className="icon icon-angle-right"></span>
                  </span>
                </a>
              </li> */}
              <li className="unifiednav__item-wrap" data-auto="more-pages">
                {" "}
                <a href="https://www.43-101.com" className="unifiednav__item">
                  {" "}
                  <span
                    className="nav-item-text "
                    data-link-text="Report Map
          "
                    data-auto="page-text-style"
                  >
                    Report Map<span className="icon icon-angle-right"></span>
                  </span>
                </a>
              </li>
              {/* <li className="unifiednav__item-wrap" data-auto="more-pages">
                {" "}
                <a target="_blank" href="https://www.prospectorportal.com/ESG" className="unifiednav__item">
                  {" "}
                  <span
                    className="nav-item-text "
                    data-link-text="
           ESG
          "
                    data-auto="page-text-style">
                    ESG<span className="icon icon-angle-right"></span>
                  </span>
                </a>
              </li> */}
              <li className="unifiednav__item-wrap" data-auto="more-pages">
                {" "}
                <a
                  target="_blank"
                  href="https://www.prospectorportal.com/plans/offerings"
                  className="unifiednav__item"
                >
                  {" "}
                  <span
                    className="nav-item-text "
                    data-link-text="
         Plans/Solutions

        "
                    data-auto="page-text-style"
                  >
                    Plans/Solutions
                    <span className="icon icon-angle-right"></span>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </NavItem>
        {/* <NavItem>
                    <StyledLink2
                        onClick={() => window.open('https://www.prospectorportal.com/nugget', "_blank")}
                    >
                        The Nugget
                    </StyledLink2>
                </NavItem>
                <NavItem>
                    <StyledLink2
                        onClick={() => window.open('https://www.prospectorportal.com/on-the-rocks', "_blank")}
                        activeStyle={{
                            fontWeight: "bold",
                            color: Primary
                        }}
                    >
                        On The Rocks
                    </StyledLink2>
                </NavItem> */}
        <NavItem
          onMouseOver={() => setView2(true)}
          onMouseOut={() => setView2(false)}
          style={{ position: "relative" }}
        >
          <StyledLink2
            onClick={() =>
              window.open("https://www.prospectorportal.com/press", "_blank")
            }
          >
            Resources
            <KeyboardArrowDown
              className="rotate"
              style={{
                transform: viewDrop2 ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.2s ease",
              }}
            />
          </StyledLink2>
          {/* <StyledLink2
                        onClick={() => window.open('https://www.prospectorportal.com/press', "_blank")}
                    >
                        Press Releases
                    </StyledLink2> */}
          <div
            className="nav-dropdown"
            style={{
              opacity: viewDrop2 ? 1 : 0,
              transition: "transform 0.4s ease-out, opacity 0.1s ease-out",
              visibility: viewDrop2 ? "visible" : "hidden",
            }}
          >
            <ul
              className="unifiednav__container unifiednav__container_sub-nav"
              data-depth="0"
              data-auto="sub-pages"
            >
              {" "}
              <li className="unifiednav__item-wrap" data-auto="more-pages">
                {" "}
                <a
                  target="_blank"
                  href="https://www.prospectorportal.com/press"
                  className="unifiednav__item"
                >
                  {" "}
                  <span
                    className="nav-item-text "
                    data-link-text="
           Search Mining Reports
          "
                    data-auto="page-text-style"
                  >
                    Press Releases
                    <span className="icon icon-angle-right"></span>
                  </span>
                </a>
              </li>
              <li className="unifiednav__item-wrap" data-auto="more-pages">
                {" "}
                <a
                  target="_blank"
                  href="https://www.prospectorportal.com/nugget"
                  className="unifiednav__item"
                >
                  {" "}
                  <span
                    className="nav-item-text "
                    data-link-text="
           Report Map
          "
                    data-auto="page-text-style"
                  >
                    The nugget<span className="icon icon-angle-right"></span>
                  </span>
                </a>
              </li>
              <li className="unifiednav__item-wrap" data-auto="more-pages">
                {" "}
                <a
                  target="_blank"
                  href="https://www.prospectorportal.com/on-the-rocks"
                  className="unifiednav__item"
                >
                  {" "}
                  <span
                    className="nav-item-text "
                    data-link-text="
           ESG
          "
                    data-auto="page-text-style"
                  >
                    On the Rocks<span className="icon icon-angle-right"></span>
                  </span>
                </a>
              </li>
              {/* <li className="unifiednav__item-wrap" data-auto="more-pages"> <a target="_blank" href="https://www.prospectorportal.com/plans/offerings" className="unifiednav__item" > <span className="nav-item-text " data-link-text="
         Plans/Solutions

        " data-auto="page-text-style">Plans/Solutions<span className="icon icon-angle-right"></span>

                            </span>
                            </a>
                            </li> */}
            </ul>
          </div>
        </NavItem>

        {/* <NavButton
          onClick={() =>
            window.open(
              "https://www.prospectorportal.com/plans/offerings",
              "_blank"
            )
          }
        >
          <span>Start Free Trial</span>
        </NavButton> */}
        <NavButton style={{ width: "fit-content", padding: "5px" }}>
          <span to="#" onClick={loginWithNLPortal}>
            LOG IN OR START TRIAL
          </span>
        </NavButton>
      </NavList>
    </Navigation>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  loginWithNLPortal: (data) => dispatch(loginWithNLPortal(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NonLoggedNav));
