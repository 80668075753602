/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import logoImg from "../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon.png";
import { TextField } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import background from "../assets/ProspectorDesignAssets/back.png";

export const Primary = "#00AC69";
export const Secondary = "#1A9BDB";

// dummy companies
const dummy_companies = [
  {
    name: "Cora",
    image: require("../assets/ProspectorDesignAssets/Companies/cora_logo.png"),
  },
  {
    name: "Newmont Goldcorp",
    image: require("../assets/ProspectorDesignAssets/Companies/newmont-goldcorp-squarelogo-1578319326790.png"),
  },
  {
    name: "Redstar",
    image: require("../assets/ProspectorDesignAssets/Companies/redstar.png"),
  },
  {
    name: "Unigold Inc",
    image: require("../assets/ProspectorDesignAssets/Companies/Unigold Inc.png"),
  },
];

export const TailwindButton = ({ text, onClick }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className="inline-flex items-center justify-center m-1  min-w-[100px] px-3 py-2 border border-transparent text-xs font-bold rounded shadow-sm text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-400"
    >
      {text}
    </button>
  );
};

export const TailwindButtonOutline = ({ text, onClick }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className="inline-flex items-center  min-w-[100px] px-3 justify-center py-2 border border-emerald-500 text-xs font-bold rounded shadow-sm text-emerald-600 bg-white hover:bg-emerald-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-400"
    >
      {text}
    </button>
  );
};

export const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
};
export const ChevronUpDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
      />
    </svg>
  );
};

export const Headline1 = styled.h1`
  font: normal normal 96px/112px Gotham-Bold;
  letter-spacing: 0px;
  color: ${(props) => props.color || "#000000DE"};
  opacity: 1;
  margin: 0px;
`;
export const Headline2 = styled.h2`
  font: normal normal 60px/72px Gotham-Bold;
  letter-spacing: -0.48px;
  color: ${(props) => props.color || "#000000DE"};
  opacity: 1;
  margin: 0px;
`;
export const Headline3 = styled.h3`
  font: normal normal 48px/56px Gotham-Bold;
  letter-spacing: 0px;
  color: ${(props) => props.color || "#000000DE"};
  opacity: 1;
  margin: 0px;
`;
export const Headline4 = styled.h4`
  font: normal normal 34px/36px Gotham-Bold;
  letter-spacing: 0px;
  color: ${(props) => props.color || "#000000DE"};
  opacity: 1;
  margin: 0px;
`;
export const Headline5 = styled.h5`
  font: normal normal 24px/24px Gotham-Bold;
  letter-spacing: 0.18px;
  color: ${(props) => props.color || "#000000DE"};
  opacity: 1;
  margin: 0px;
`;

export const Headline6 = styled.h6`
  font: normal normal 18px/24px Gotham-Bold;
  letter-spacing: 0.18px;
  margin-left: 0;
  color: ${(props) => props.color || "#000000DE"};
  opacity: 1;
  margin: 0px; ;
`;
export const Subtitle1 = styled.p`
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  letter-spacing: 0.11px;
  color: ${(props) => props.color || "#000000DE"};
  opacity: 1;
  margin: 0px;
`;
export const Subtitle2 = styled.p`
  font-size: 12px;

  letter-spacing: 0.1px;
  font-family: "Gotham-Book";
  color: ${(props) => props.color || "#000000DE"};
  opacity: 1;
  margin: 0px; ;
`;
export const Subtitle2span = styled.span`
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.1px;
  font-family: "Gotham-Book";

  color: ${(props) => props.color || "#92929D"};
  opacity: 1;
  margin: 0px; ;
`;

export const Body1 = styled.p`
  font: normal normal normal 14px/24px Gotham-Book;
  letter-spacing: 0.5px;
  color: ${(props) => props.color || "#000000eb"};
  opacity: 1;
  margin: 0px;
`;
export const Body2 = styled.p`
  font: normal normal normal 12px/16px Gotham-Book;
  letter-spacing: 0.25px;
  color: ${(props) => props.color || "#000000eb"};
  opacity: 1;
  margin: 0px;
`;
export const Body3 = styled.p`
  font: normal normal normal 12px Gotham-Book;
  letter-spacing: 0.25px;
  color: ${(props) => props.color || "#000000eb"};
  opacity: 1;
  margin: 0px;
`;

export const Button = styled.div`
  text-align: center;
  font: normal normal bold 12px/12px Gotham-Bold;
  letter-spacing: 1.25px;
  color: ${(props) => props.color || "white"};
  text-transform: uppercase;
  opacity: 1;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.backgroundColor || Primary} 0% 0% no-repeat
    padding-box;
  width: fit-content;
  padding: 10px;
  margin: 20px;
  cursor: pointer;
  &:hover {
    box-shadow: -2px 3px 16px -4px rgba(0, 0, 0, 0.48);
  }
`;
export const NotifyDiv = styled.div`
  // width:25vw;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center !important;
  height: 90vh;
  box-sizing: border-box;
  padding: 20px;
  background-color: white;
  position: relative;
  border-radius: 5px;
  box-shadow: -2px 3px 22px -4px rgba(0, 0, 0, 0.48);
`;

export const ButtonCircle = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  background: ${(props) => props.inputColor || Primary};
  color: white;
  opacity: 1;
  border-radius: 50%;
`;
export const Navigation = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 80px;
  background: var(--light-🌕-on-primary-high-emphasis-ffffff) 0% 0% no-repeat
    padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  z-index: 1000;
`;
export const MainPage = styled.div`
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  // margin-top:${(props) => props.marginTop || "80px"};
  padding: ${(props) => props.marginTop || "10px"};
  padding-top: ${(props) => props.paddingTop || "85px"};
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  box-sizing: border-box;
  scroll-padding-top: 85px;
`;
export const Logo = styled.div`
  width: 66px;
  height: 50px;

  opacity: 1;
`;
export const Search = styled.div`
  flex: 1;
  display: flex;

  height: 100%;
  box-sizing: border-box;
  padding-left: 10px;
`;
export const NavList = styled.div`
box-sizing: border-box;
padding: 10px;
display: flex;
flex-direction: row;
justifyContent: 'space-between',
alignItems: 'center';
`;
export const NavItem = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  color: "gainsboro";
  font-weight: bold;
  font-size: 16px;
  color: "gray !important";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const StyledLink2 = styled.span`
  cursor: pointer;
  background-color: white;

  color: #463939;
  height: 40px;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 700 !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  text-align: center;
  margin-right: 10px;

  &:hover {
    text-decoration: none;
    color: #00ac69;
  }
`;
export const StyledLink = styled(NavLink)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
export const LogoutLink = styled(NavLink)`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: white;
  }
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const SocialsLink = styled.div`
  color: #4680c4;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: normal;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
  text-decoration: underline;
  font: normal normal normal 14px Gotham;
  letter-spacing: 0.06px;
  color: #296dbbdd;
  opacity: 1;
  &:hover {
    text-decoration: none;
  }
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const MatchCard = styled.div`
  // width: 35vw;
  max-width: 500px;
  min-width: 500px;
  max-height: 600px;
  min-height: 600px;
  // height:35vw;
  margin: 20px;
  background: var(--light-🌕-on-primary-high-emphasis-ffffff) 0% 0% no-repeat
    padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  position: absolute;
  transform: rotate(${(props) => props.rotate}deg);
  z-index: ${(props) => props.zIndex};
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  &.mycard-enter {
    opacity: 0;
    transform: rotate(40deg) translateY(-80px);
  }
  &.mycard-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
`;

export const Hexagon = styled.div`
  position: relative;

  width: ${(props) => props.width + "px" || "20px"};
  height: ${(props) => (props.width / 1.732).toFixed(2) + "px" || "11.55px"};
  background-color: ${(props) => props.color || "#00AC69"};
  margin: ${(props) => (props.width / 3.462).toFixed(2) + "px" || "11.55px"} 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: ${(props) => (props.width / 2.5).toFixed(2) + "px" || "12px"};
  // &:hover {
  //   -webkit-filter: drop-shadow(5px 3px 5px rgba(102, 102, 102, 0.3));
  //   filter: drop-shadow(5px 3px 5px rgba(102, 102, 102, 0.3));
  // }
  &:before {
    content: "";
    left: 0;
    position: absolute;
    width: 0;
    border-left: ${(props) => (props.width / 2).toFixed(2) + "px" || "10px"}
      solid transparent;
    border-right: ${(props) => (props.width / 2).toFixed(2) + "px" || "10px"}
      solid transparent;
    bottom: 100%;
    border-bottom: ${(props) =>
        (props.width / 3.462).toFixed(2) + "px" || "11.55px"}
      solid ${(props) => props.color || "#00AC69"};
  }
  &:after {
    top: 100%;
    width: 0;
    border-top: ${(props) =>
        (props.width / 3.462).toFixed(2) + "px" || "11.55px"}
      solid ${(props) => props.color || "#00AC69"};
    content: "";
    position: absolute;
    width: 0;
    left: 0;
    border-left: ${(props) => (props.width / 2).toFixed(2) + "px" || "10px"}
      solid transparent;
    border-right: ${(props) => (props.width / 2).toFixed(2) + "px" || "10px"}
      solid transparent;
  }
`;
export const ButtonDiv = styled.div`
  background: #ffffff00 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
`;
export const GreyBox = styled.div`
  background-color: #0000001f;
  border-radius: 4px;

  opacity: 1;
  color: #000000eb;
  padding: 5px;
  height: 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // &:hover {
  //   box-shadow: -2px 3px 16px -4px rgba(0, 0, 0, 0.48);
  // }
`;
export const ProspectorDescription = styled.div`
  height: 60px;
  width: 100%;
  border: 2px solid ${(props) => props.color};
  background: ${(props) => props.color}5C;
  cursor: "pointer";
  border-radius: 5px;
`;

export const ProspectorInterest = styled.div`
  height: 60px;
  width: 45%;
  border: 2px solid ${(props) => props.color};
  color: ${(props) => props.color};
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
`;
export const RightMessageBubble = styled.div`
  width: fit-content;
  max-width: 45%;
  text-align: left;
  background-color: #f1f5f7;
  box-shadow: 0px 2px 5px #00000029;
  border-radius: 7px;
  border-top-right-radius: 0;
  margin-bottom: 20px;
  padding: 5px;
  margin: 10px;
  box-sizing: border-box;
  cursor: pointer;
`;
export const LeftMessageBubble = styled.div`
  width: fit-content;
  max-width: 45%;
  text-align: left;
  cursor: pointer;
  background-color: ${(props) => props.color || Primary};
  border-radius: 7px;
  border-top-left-radius: 0;
  box-shadow: 0px 2px 5px #00000029;
  margin: 10px;
  margin-bottom: 20px;
  border: 1px solid ${Primary};
  padding: 5px;
  box-sizing: border-box;
`;
export const BrowseListFilter = styled.div`
  display: flex;
  height: 35px;
  padding: 5px;
  box-sizing: border-box;
  margin: 5px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 23px;
  border: 1px solid #00000033;
  cursor: pointer;
  &:hover {
    background-color: gainsboro;
  }
`;
export const ChartBarDiv = styled.div`
  width: 60%;
  color: ${(props) => props.color || "#D5D5D5"};
  height: ${(props) => props.height || "10px"};
  background-color: ${(props) => props.color || "#D5D5D5"};
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;
export const NavButton = styled.div`
  background-color: white;
  border-width: 2px;
  color: #1a9bdb;
  height: 40px;
  border: 2px solid #1a9bdb;
  border-style: solid;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: flex;
  width: 200px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 700 !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  text-align: center;
  margin-right: 25px;
  cursor: pointer;
  &:hover {
    background-color: #1a9bdb;
    color: white;
  }
`;
export const NavButton2 = styled.div`
  background-color: white;

  color: #1a9bdb;
  height: 42px;

  cursor: pointer;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: flex;
  width: 100px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 700 !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  text-align: center;
  &:hover {
    background-color: #1a9bdb;
    color: white;
  }
`;
export const ComponentContainer = styled.div`
  border-radius: 5px;
  padding: 20px;
  border: 1px solid gainsboro;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "fit-content"};
  box-sizing: border-box;
  margin: 10px;
  text-align: left;
`;
