import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Player } from "@lottiefiles/react-lottie-player";
import {
  Headline6,
  Subtitle1,
  TailwindButton,
  TailwindButtonOutline,
} from "../../../components";
import TuneRoundedIcon from "@material-ui/icons/TuneRounded";
import { SortOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import ContentLoader from "react-content-loader";
import { Exchanges, Period_range } from "../../../configure/constants";
import _ from "lodash";
import animationData from "../../../assets/ProspectorDesignAssets/lottie2.json";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchComponent from "./SearchComponent";
import ReportCard from "../../../components/ReportCard";
import Dropdownfilter from "../../../components/Dropdownfilter";
import NewsFiling from "../../../components/NewsFiling";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { apiRequest, filterFilings } from "../../../store/Actions/AuthActions";
import { object } from "prop-types";
import { Tooltip } from "@material-ui/core";
import { useDispatch } from "react-redux";
import AsyncSelect from "react-select/async";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { Dialog } from "@material-ui/core";
import Dropdown from "../../SavedSearches/Dropdown";

const filingTypes = [
  "Annual Update",
  "Quarterly Update",
  "Mineral Resource Update",
  "Drilling Results",
  "Technical Report",
  "Press Release",
];
const periods = ["1M", "3M", "1Y", "5Y"];

const colors = {
  "Annual Update": "#F9F26E",
  "Quarterly Update": "#9F62EE",
  "Mineral Resource Update": "#199BDB",
  "Drilling Results": "#00AC69",
  "Technical Report": "#C1C4C4",
  "Press Release": "#343E3D",
};
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
    width: "100%",
    borderColor: "transparent",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    flex: 1,
  },
}));
const customStyles = {
  container: () => ({
    flex: 1,
    // margin: "5px",
    width: "400px !important",
    position: "relative",
    boxSizing: "border-box",
  }),
  control: (base) => ({
    ...base,
    fontSize: "x-large",
    borderColor: "tansparent !important",
    minHeight: 50,
  }),
};
export const NewsContent = ({
  activeUser,
  filings,
  filings_loading,
  filings_filters,
  setFilingFilters,

  tab,
  filings_length,
  // handleClickOpen,
  setSortOpen,
  setsearchMode,
  fetchNext,
}) => {
  useEffect(() => {
    searchCompanies();
    dispatch(filterFilings());

    return () => {};
  }, []);

  const [companies, setcompanies] = useState([]);
  const dispatch = useDispatch();
  const searchCompanies = async () => {
    let data = await apiRequest.get(`/companies?shallow=true`);
    if (data && data.data && data.data.data) {
      setcompanies([...data.data.data]);
    }
  };

  const searchCompaniesText = (value) => {
    setTimeout(async () => {
      let data = await apiRequest.get(
        `/search?collection=companies&searchTerm=${value}`
      );
      if (data && data.data && data.data.data) {
        setcompanies([...data.data.data]);
      }
    }, 300);
  };

  const searchText = async (value) => {
    console.log(value);

    let { data } = await apiRequest.get(
      `/search?collection=companies&searchTerm=${value}`
    );

    if (data) {
      return data.data.map((tag, index) => ({
        value: tag.name.trim(),
        label: tag.name,
      }));
    }
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(searchText(inputValue));
      }, 1000);
    });

  // const updateSingleFilterKey = (name, value) => {
  //   let newFilters = { ...filings_filters };

  //   if (JSON.stringify(newFilters[name]) === JSON.stringify(value)) {
  //     newFilters[name] = null;

  //     setFilingFilters(newFilters);
  //     dispatch(filterFilings());
  //     // search_filings({ loc: 0, newFilters });
  //   } else {
  //     newFilters[name] = value;

  //     setFilingFilters(newFilters);
  //     dispatch(filterFilings());
  //     // search_filings({ loc: 0, newFilters });
  //   }
  // };
  const resetFilters = () => {
    let newFilters = {
      headline: null,
      exchange: null,
      exact_phrase: null,
      all_of: [],
      none_of: [],
      phrase: {
        word_count: null,
        searchTerm: null,
        near: null,
      },
      date_range: null,
      filing_company: null,
      filing_type: null,
    };

    setFilingFilters(newFilters);
    dispatch(filterFilings());
  };
  const [saveopen, setOpen] = useState(false);
  const [searchName, setsearchName] = useState(null);
  const [frequency, setFrequency] = useState(null);

  const saveSearch = async () => {
    let payload = {
      name: searchName,
      search_index: "documents",
      query_filters: filings_filters,
      searchTerm: filings_filters.searchTerm || "reports",
      alert_frequency: frequency.toLowerCase(),
    };
    const toastId = toast("Saving Search", {
      type: "info",
      autoClose: false,
    });
    let data = await apiRequest
      .post("/saved-searches", payload)
      .catch((err) => {
        toast.update(toastId, {
          theme: "light",
          render: err.response.data.message,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      });

    if (data && data.data) {
      toast.update(toastId, {
        theme: "light",
        render: "Search Saved",
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      handleCloseSave();
    }
  };
  const pageRef = useRef();
  const [scrollTop, setscrollTop] = useState(false);
  const checkScrollPosition = () => {
    if (pageRef.current.scrollTop > 1000) {
      setscrollTop(true);
    } else {
      setscrollTop(false);
    }
  };
  const handleSearchChange = (value) => {
    console.log(value);
    setFrequency(value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseSave = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="sticky">
        <div style={{ left: "75%", marginTop: "20px" }}>
          <div className="flex-boy-row">
            <Headline6
              style={{
                textAlign: "left",
                width: "100%",
                marginLeft: "5px",
                padding: "10px",
                textTransform: "uppercase",
              }}
            >
              {tab}
            </Headline6>
            <div className="flex-boy-row"></div>
          </div>

          <div className="flex-boy-row mb-2">
            <div
              className="flex-boy-row "
              style={{ flex: 1, position: "relative", height: "70px" }}
            >
              <SearchComponent
                type="news"
                placeholder={"Open text search news and filings"}
              />
            </div>
            {/* <Tooltip title="Print PDF">
              <div className="circle-div">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="grey"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"
                  />
                </svg>
                
              </div>
            </Tooltip> */}
            {activeUser && (
              <button
                onClick={handleClickOpen}
                type="button"
                className="inline-flex items-center  ml-1 min-w-[105px] px-3.5 justify-center py-4 border border-emerald-500 text-xs font-bold rounded shadow-sm text-emerald-600 bg-white hover:bg-emerald-600 hover:text-white focus:outline-none "
              >
                Save Search
              </button>
            )}

            <Dialog open={saveopen} onClose={handleCloseSave}>
              <DialogTitle>Save Search</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please add a title and alert frequency for your search.
                </DialogContentText>

                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Search Title"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setsearchName(e.target.value)}
                />

                <div className="pt-10 pb-5 h-fit">
                  <Subtitle1>Set Frequency</Subtitle1>
                  <Dropdown handleSearchChange={handleSearchChange} />
                </div>

                <div className="py-5 h-fit min-h-[300px]">
                  <Headline6>Recipient Email Address</Headline6>
                  <p className="mt-2 ">{activeUser?.email}</p>
                </div>
              </DialogContent>
              <DialogActions>
                <div className="w-full flex flex-row justify-between">
                  <TailwindButtonOutline
                    text="Cancel"
                    onClick={handleCloseSave}
                  >
                    Cancel
                  </TailwindButtonOutline>
                  <TailwindButton text="Save" onClick={saveSearch}>
                    Save
                  </TailwindButton>
                </div>
              </DialogActions>
            </Dialog>

            <div className="circle-div">
              <TuneRoundedIcon
                style={{ color: "grey" }}
                onClick={() => setsearchMode(true)}
              />
            </div>
            <div className="circle-div">
              <SortOutlined
                style={{ color: "grey" }}
                onClick={() => setSortOpen(true)}
              />
            </div>
          </div>
        </div>
        <div className="flex-boy-row pr-1">
          {Object.keys(colors).map((item) => {
            return (
              <div className="flex flex-row items-center">
                <div
                  className={`rounded-full h-6 w-6 mr-1`}
                  style={{ backgroundColor: colors[item] }}
                ></div>
                <Subtitle1 className="font-bold">{item}</Subtitle1>
              </div>
            );
          })}
        </div>
        <div
          className="flex-boy-row mt-5"
          style={{ width: "100%", flexWrap: "wrap" }}
        >
          <div className="flex-boy-row" style={{ padding: "10px" }}>
            {filings_loading ? (
              <div className="flex-boy-row">
                <Player
                  autoplay
                  loop
                  src={animationData}
                  style={{ height: "100px", width: "100px" }}
                >
                  {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                </Player>

                <Subtitle1>Loading</Subtitle1>
              </div>
            ) : (
              <Subtitle1 style={{ fontSize: "20px" }}>
                {filings_length?.toLocaleString() ||
                  filings.length?.toLocaleString()}{" "}
                Filings
              </Subtitle1>
            )}
          </div>
          <div className="flex-boy-row news-filter">
            {/* <Autocomplete
              style={{ width: "150px", marginBottom: "20px" }}
              options={[...companies]}
              getOptionLabel={(option) => option.name}
              id="select-on-focus"
              selectOnFocus
              onChange={(e) =>{console.log(e.target.value)}
                // updateSingleFilterKey(
                //   "filing_company",
                //   e.map((item) => item.value)
                // )

              }
              onInputChange={(e) => searchCompaniesText(e.target.value)}
              renderInput={(params) => (
                <TextField {...params} placeholder=""  margin="normal" />
              )}
            /> */}
            {/* <AsyncSelect
              isMulti
              // styles={customStyles}
              placeholder={"Filing Company"}
              cacheOptions
              loadOptions={promiseOptions}
              // defaultOptions

              onChange={(e) =>
                updateSingleFilterKey(
                  "filing_company",
                  e.map((item) => item.value)
                )
              }
            /> */}
            <Dropdownfilter filter="exchange" options={Exchanges} tab={tab} />

            <Dropdownfilter
              filter="filing_type"
              options={filingTypes.map((item) => item)}
              tab={tab}
            />
            {/* <Dropdownfilter
              filter="start_after"
              options={periods.map((item) => item)}
              tab={tab}
            /> */}
            <TailwindButton
              onClick={() => {
                resetFilters();
              }}
              text={"Clear Filters"}
            />
          </div>
        </div>
      </div>
      {filings.length ? (
        <InfiniteScroll
          ref={pageRef}
          dataLength={filings.length} //This is important field to render the next data
          next={() => {
            console.log("fetching");
            // dispatch(filterFilings());
          }}
          hasMore={filings.length < filings_length}
          loader={
            <Player
              autoplay
              loop
              src={animationData}
              style={{ height: "150px", width: "150px" }}
            >
              {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
            </Player>
          }
          scrollableTarget="scrollableDiv"
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it All</b>
            </p>
          }
        >
          {filings
            .filter((item) => item)
            .map((filing, index) => {
              return <NewsFiling type={filing.document_type} filing={filing} />;
            })}
        </InfiniteScroll>
      ) : filings_loading ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <ContentLoader viewBox="0 0 600 100" height={100} width={600}>
            <rect x="20" y="8" rx="0" ry="0" width="100" height="100" />
            <rect x="20" y="120" rx="0" ry="0" width="100" height="10" />
            <rect x="130" y="8" rx="0" ry="0" width="500" height="15" />
            <rect x="130" y="30" rx="0" ry="0" width="500" height="15" />
            <rect x="130" y="52" rx="0" ry="0" width="300" height="15" />
          </ContentLoader>
          <ContentLoader viewBox="0 0 600 100" height={100} width={600}>
            <rect x="20" y="8" rx="0" ry="0" width="100" height="100" />
            <rect x="20" y="120" rx="0" ry="0" width="100" height="10" />
            <rect x="130" y="8" rx="0" ry="0" width="500" height="15" />
            <rect x="130" y="30" rx="0" ry="0" width="500" height="15" />
            <rect x="130" y="52" rx="0" ry="0" width="300" height="15" />
          </ContentLoader>
          <ContentLoader viewBox="0 0 600 100" height={100} width={600}>
            <rect x="20" y="8" rx="0" ry="0" width="100" height="100" />
            <rect x="20" y="120" rx="0" ry="0" width="100" height="10" />
            <rect x="130" y="8" rx="0" ry="0" width="500" height="15" />
            <rect x="130" y="30" rx="0" ry="0" width="500" height="15" />
            <rect x="130" y="52" rx="0" ry="0" width="300" height="15" />
          </ContentLoader>
        </div>
      ) : (
        <div className="flex-boy-column">
          <img
            src={require("../../../assets/ProspectorDesignAssets/empty_image.png")}
            alt="empty"
            style={{ height: "100px", marginTop: "50px" }}
          />
          <Headline6 color="gainsboro">
            No available filings for your filters{" "}
          </Headline6>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  filings: state.authReducer.filings_search,
  filings_filters: state.authReducer.filings_filters,
  filings_loading: state.authReducer.filings_loading,
  filings_length: state.authReducer.filtered_filings_length,
  activeUser: state.authReducer.activeUser,
});

const mapDispatchToProps = (dispatch) => ({
  setFilingFilters: (data) =>
    dispatch({ type: "SET_FILINGS_FILTERS", payload: data }),
  filterFilings: (data) => dispatch(filterFilings(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewsContent);
