import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Chip, Dialog, Divider } from "@material-ui/core";
import {
  Body1,
  Headline4,
  Headline5,
  Headline6,
  Primary,
  Subtitle1,
  GreyBox,
} from "..";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from "@material-ui/core/Popover";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ListIcon from "@material-ui/icons/List";
import { Notes } from "../Notes";
import Avatar from "@material-ui/core/Avatar";
import { toast } from "react-toastify";
import {
  apiRequest,
  getLists,
  getProjectDetail,
  getCompanyDetail,
} from "../../store/Actions/AuthActions";
import { KeyboardBackspaceSharp, CloseOutlined } from "@material-ui/icons";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import animationData from "../../assets/ProspectorDesignAssets/lottie2.json";
import _ from "lodash";
import { SearchComponent } from "./SearchComponent";
import { withRouter } from "react-router";
const project = {
  date: "2021-10-19T00:00:00.000000Z",
  id: 1110000000478,
  lat: "50.01",
  lng: "-79.74",
  name: "Detour Lake",
  stage: "Production",
};
export const ListPage = ({ setListView, setAnchorEl, history, lists }) => {
  const [type, setType] = useState("projects");
  const [createMode, setcreateMode] = useState(false);
  const dispatch = useDispatch();
  const [selectedList, setselectedList] = useState(null);
  const handleChange = (event) => {
    setType(event.target.value);
  };

  useEffect(() => {
    switchList();
    history.listen(switchList);
  }, []);
  const switchList = () => {
    if (history.location.pathname.includes("/connections/lists/")) {
      let id = history.location.pathname.replace("/connections/lists/", "");

      setselectedList(lists.find((item) => item.id === parseFloat(id)));
      console.log(id);
      getListEntries();
    }
    return () => {};
  };
  const [entries, setentries] = useState([]);

  const getListEntries = async () => {
    let id = history.location.pathname.replace("/connections/lists/", "");
    if (id !== " ") {
      console.log(id);
      const response = await apiRequest
        .get(`me/private-lists/${id}/entries`)
        .catch((error) => {
          console.log(id);
        });
      if (response) {
        setselectedList(lists.find((item) => item.id === parseFloat(id)));
        setentries(response.data.data);
      }
    }
  };
  const removeItem = async (item) => {
    let payload = [item.id];
    const toastId = toast("Deleting from list", {
      type: "info",
      autoClose: false,
    });
    const data = await apiRequest
      .delete(
        `me/private-lists/${selectedList.id}/entries?entries[]=${payload}`,
        [payload]
      )
      .catch((error) => {
        toast.update(toastId, {
          theme: "light",
          render: error.response.data.message,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      });
    if (data && data) {
      toast.update(toastId, {
        theme: "light",
        render: "Item Deleted",
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      getListEntries();
      dispatch(getLists());
    }
  };
  const add = async (entity) => {
    let payload = {
      entries: [entity.id],
      type: selectedList.list_entries_type,
    };
    const toastId = toast("Adding to Lists", {
      type: "info",
      autoClose: false,
    });

    const data = await apiRequest
      .patch(`me/private-lists/${selectedList.id}`, payload)
      .catch((error) => {
        toast.update(toastId, {
          theme: "light",
          render: error.response.data.message,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      });

    if (data && data.data) {
      toast.update(toastId, {
        theme: "light",
        render: `${selectedList.list_entries_type} added`,
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      getListEntries();
      setcreateMode(false);
      dispatch(getLists());
    }
  };
  const ProjectNote = ({ item }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const [NotesOpen, setNotesOpen] = useState(false);
    const handleClickOpen = () => {
      setNotesOpen(true);
    };

    const handleNotesClose = (value) => {
      setNotesOpen(false);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <div className="projectNote">
        {selectedList ? (
          <>
            <Dialog
              fullWidth={true}
              maxWidth={"md"}
              onClose={handleClose}
              aria-labelledby="simple-dialog-title"
              open={NotesOpen}
            >
              <Notes
                handleClose={handleNotesClose}
                type={"project"}
                entity={project}
              />
            </Dialog>
            {selectedList.list_entries_type === "project" ? (
              <div
                className="notes-body flex-boy-row"
                style={{ paddingBottom: "20px", paddingTop: "20px" }}
              >
                <Subtitle1
                  onClick={() => {
                    dispatch(getProjectDetail({ id: item.id, history }));
                  }}
                  style={{ flex: 1 }}
                >
                  {item.name}
                </Subtitle1>
                <Subtitle1
                  onClick={() => {
                    dispatch(getProjectDetail({ id: item.id, history }));
                  }}
                  style={{ flex: 1 }}
                >
                  {item.primary_mineral}
                </Subtitle1>
                <Subtitle1
                  onClick={() => {
                    dispatch(getProjectDetail({ id: item.id, history }));
                  }}
                  style={{ flex: 1 }}
                >
                  {item.stage}
                </Subtitle1>
                <Subtitle1
                  onClick={() => {
                    dispatch(getProjectDetail({ id: item.id, history }));
                  }}
                  style={{ flex: 1 }}
                >
                  {item.country}
                </Subtitle1>
                <CloseOutlined onClick={handleClick} />
              </div>
            ) : (
              <div
                className="notes-body flex-boy-row"
                style={{ paddingBottom: "20px", paddingTop: "20px" }}
              >
                <Subtitle1
                  onClick={() => {
                    dispatch(getCompanyDetail({ id: item.id, history }));
                  }}
                  style={{ flex: 1 }}
                >
                  {item.name}
                </Subtitle1>
                <Subtitle1
                  onClick={() => {
                    dispatch(getCompanyDetail({ id: item.id, history }));
                  }}
                  style={{ flex: 1 }}
                >
                  {item.ticker ? `${item.ticker}: ${item.exchange}` : null}
                </Subtitle1>
                <Subtitle1
                  onClick={() => {
                    dispatch(getCompanyDetail({ id: item.id, history }));
                  }}
                  style={{ flex: 1 }}
                >
                  {item.stock_history?.marketCap}
                </Subtitle1>
                <Subtitle1
                  onClick={() => {
                    dispatch(getCompanyDetail({ id: item.id, history }));
                  }}
                  style={{ flex: 1 }}
                >
                  {item.projects_count}
                </Subtitle1>
                <CloseOutlined onClick={handleClick} />
              </div>
            )}

            <Divider />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div style={{ padding: "20px" }}>
                <Subtitle1>
                  Are you sure you want to remove{" "}
                  {selectedList.list_entries_type} from the list?
                </Subtitle1>
                <div
                  className="flex-boy-row"
                  style={{ justifyContent: "flex-end", marginTop: "20px" }}
                >
                  <GreyBox
                    onClick={() => setAnchorEl(null)}
                    className="connect-button"
                    style={{
                      height: "30px",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      textTransform: "uppercase",
                      color: Primary,
                      backgroundColor: Primary,
                      width: "75px",
                      textAlign: "center",
                      borderRadius: "5px",
                      marginRight: "10px",
                    }}
                  >
                    Cancel
                  </GreyBox>
                  <GreyBox
                    onClick={() => removeItem(item)}
                    style={{
                      height: "30px",
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      textTransform: "uppercase",
                      color: "white",
                      backgroundColor: Primary,
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    Remove
                  </GreyBox>
                </div>
              </div>
            </Popover>
          </>
        ) : null}
      </div>
    );
  };

  return (
    <div style={{ textAlign: "left", padding: "30px" }}>
      {selectedList ? (
        <>
          <div
            className="flex-boy-row note-header py-5 bg-white w-full"
            style={{ flex: 1, justifyContent: "normal", top: -5 }}
          >
            <KeyboardBackspaceSharp
              onClick={() => {
                history.goBack();
              }}
              style={{ fontSize: 40, color: Primary }}
            />
            <Avatar
              alt="Remy Sharp"
              src={
                selectedList.icon_url || require("../../assets/030-light.svg")
              }
            />

            <Headline4>{selectedList.name}</Headline4>
          </div>
          {selectedList.list_entries_type === "project" ? (
            <div
              className="note-header flex-boy-row bg-white"
              style={{ marginBottom: "5px", top: "70px" }}
            >
              <Headline6 style={{ flex: 1, color: "grey" }}>Project</Headline6>
              <Headline6 style={{ flex: 1, color: "grey" }}>
                Primary Metal
              </Headline6>
              <Headline6 style={{ flex: 1, color: "grey" }}>Stage</Headline6>
              <Headline6 style={{ flex: 1, color: "grey" }}>Country</Headline6>
              <MoreVertIcon style={{ color: "white" }} />
            </div>
          ) : (
            <div
              className="note-header flex-boy-row bg-white"
              style={{ marginBottom: "5px", top: "70px" }}
            >
              <Headline6 style={{ flex: 1, color: "grey" }}>Company</Headline6>
              <Headline6 style={{ flex: 1, color: "grey" }}>Ticker</Headline6>
              <Headline6 style={{ flex: 1, color: "grey" }}>
                Market Cap
              </Headline6>
              <Headline6 style={{ flex: 1, color: "grey" }}>Projects</Headline6>
              <MoreVertIcon style={{ color: "white" }} />
            </div>
          )}
          <Divider />
        </>
      ) : null}
      <div>
        {entries.length ? (
          _.uniqBy(entries, "id").map((item) => (
            <ProjectNote key={item.id} item={item} />
          ))
        ) : (
          <Player
            autoplay
            loop
            src={animationData}
            style={{ height: "300px", width: "300px" }}
          >
            {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
          </Player>
        )}
      </div>
      {selectedList ? (
        !createMode ? (
          <GreyBox
            onClick={() => setcreateMode(true)}
            className="connect-button"
            style={{
              height: "40px",
              fontWeight: "bold",
              fontSize: "0.8rem",
              textTransform: "uppercase",
              color: Primary,
              backgroundColor: Primary,
              marginTop: "20px",
              width: "200px",
              textAlign: "center",
              borderRadius: "5px",
              marginRight: "10px",
            }}
          >
            + ADD {selectedList.list_entries_type}
          </GreyBox>
        ) : (
          <div className="search-component">
            <SearchComponent
              add={add}
              selectedList={selectedList}
              type={selectedList.list_entries_type}
            />
          </div>
        )
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  lists: state.authReducer.lists,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListPage));
