/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { manageSub } from "../store/Actions/AuthActions";
import {
  Headline6,
  MainPage,
  Primary,
  Headline5,
  Body1,
  Body2,
  GreyBox,
  Button,
  Subtitle1,
  Subtitle2,
  BrowseListFilter,
  Hexagon,
  ChartBarDiv,
} from "./general";

export const Subscriptioncontent = ({ subscription_Message, manageSub }) => {
  return (
    <div className="subscription-content">
      <div
        className="subscription"
        style={{ filter: "none", background: "none", height: "100%" }}
      >
        <div className="sub-box">
          <Subtitle1 color="white">
            {" "}
            Please {subscription_Message} to view this content{" "}
          </Subtitle1>
          <Button
            style={{ textTransform: "uppercase" }}
            onClick={() => {
              manageSub();
            }}
          >
            {subscription_Message}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  subscription: state.authReducer.activeUser?.permissions,
  subscription_Message: state.authReducer.subscription_Message,
});

const mapDispatchToProps = (dispatch) => ({
  manageSub: (data) => dispatch(manageSub(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscriptioncontent);
