/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { MainPage } from "../components";
import { Grid, Paper, Tabs, Tab, Box } from "@material-ui/core";
import {
  Body1,
  Subtitle1,
  Headline6,
  Headline4,
  Subtitle2,
  Button,
  NotifyDiv,
} from "../components";
import SummaryAccordion from "../components/SummaryAccordion";
import Messages from "../components/Messages";
import Prospects from "../components/Prospects";
import Meetings from "../components/Meetings";
import SavedSearches from "../content/SavedSearches/index";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import {
  getProspects,
  getCompanies,
  matchUsers,
  getPeople,
} from "../store/Actions/AuthActions";
import UserNotes from "../components/UserNotes";
import UserLists from "../components/List/UserLists";
import PrivateRoute from "../components/PrivateRoute";
import ListPage from "../components/List/ListPage";
import { Switch } from "react-router-dom";
import SavedNpv from "../components/SavedNpv";
import { useMediaQuery } from "react-responsive";
export const Connections = ({
  activeUser,
  auth,
  selectedList,
  list,
  getProspects,
  selectedListItem,
  history,
}) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 1000px)",
  });
  let me = auth.uid;
  let requestType =
    activeUser?.type && activeUser?.type !== "Individual"
      ? "targetUser"
      : "requestedBy";
  useEffect(() => {
    getProspects({ listId: selectedList, me });

    return () => {};
  }, [selectedList]);

  useEffect(() => {
    switchTabs();
    history.listen(switchTabs);
    return () => {};
  }, [history]);
  const switchTabs = () => {
    if (history.location.pathname.includes("prospects")) {
      setValue(0);
    } else if (history.location.pathname.includes("npvs")) {
      setValue(2);
    } else if (history.location.pathname.includes("lists")) {
      setValue(1);
    }
    //  else {
    //   setValue(2)
    //   history.push("/connections/prospects")
    // }
  };

  useFirestoreConnect([
    { collection: "chats", where: ["members", "array-contains", me] },
    {
      collection: "matches",
      where: [
        [requestType, "==", me],
        ["status", "!=", "rejected"],
        ["listId", "==", selectedList || list],
      ],
    },
  ]);

  const chats = useSelector((state) => state.firestore.ordered.chats);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      history.push("/connections/prospects");
    } else if (
      newValue === 2 &&
      activeUser?.permissions?.includes("view_resource_reserve_charts")
    ) {
      history.push("/connections/npvs");
    } else if (
      newValue === 3 ||
      (!activeUser?.permissions?.includes("view_resource_reserve_charts") &&
        newValue === 2)
    ) {
      history.push("/connections/saved-searches");
    }
    // else if (newValue === 1) {
    //   history.push("/connections/meetings")
    // } else if (newValue === 0) {
    //   history.push("/connections/messages")
    // }
    else {
      history.push("/connections/lists");
    }
  };
  return (
    <MainPage style={{ paddingLeft: 0, paddingRight: 0 }}>
      <div
        className={`grid ${
          isTabletOrMobileDevice ? "connections-page " : "connections-page-wide"
        } grid-cols-12 gap-0 w-full`}
      >
        <div className="col-span-12 lg:col-span-1 sm:col-span-12 xs:col-span-12">
          <Tabs
            className="sticky-tabs"
            value={value}
            orientation={isTabletOrMobileDevice ? "horizontal" : "vertical"}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              }
              label="Prospects"
            />
            <Tab
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                  />
                </svg>
              }
              label="Lists"
            />
            {activeUser?.permissions?.includes(
              "view_resource_reserve_charts"
            ) && (
              <Tab
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                    />
                  </svg>
                }
                label="Saved NPV"
              />
            )}
            <Tab
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              }
              label="Saved Searches"
            />
          </Tabs>
        </div>
        <div className="col-span-12 lg:col-span-11 sm:col-span-12 xs:col-span-12 md:col-span-12">
          <Switch>
            <PrivateRoute path="/connections/prospects" component={UserNotes} />
            <PrivateRoute path="/connections/npvs" component={SavedNpv} />
            <PrivateRoute
              path="/connections/saved-searches"
              component={SavedSearches}
            />
            <PrivateRoute exact path="/connections" component={UserNotes} />
            <PrivateRoute
              exact
              path="/connections/lists"
              component={UserLists}
            />
            <PrivateRoute path="/connections/lists/:id" component={ListPage} />
          </Switch>
        </div>
      </div>
    </MainPage>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.authReducer.activeUser,
  auth: state.firebase.auth,
  selectedList: state.authReducer.selectedList,
  list: state.authReducer.lists,
});

const mapDispatchToProps = (dispatch) => ({
  getProspects: (data) => dispatch(getProspects(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Connections);
