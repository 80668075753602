/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import { Headline6, Primary, Body1, Subtitle2, Body2 } from "../general";
import {
  KeyboardBackspace,
  CallMade,
  CallReceived,
  SortOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";

import _ from "lodash";
import { getCommodityData } from "../../store/Actions/AuthActions";
import moment from "moment";
const periods = ["1M", "3M", "1Y", "MAX"];

export const Sparkline = ({
  color,
  data,
  getCommodityData,
  stockData,
  commodity,
  company,
}) => {
  const [period, setPeriod] = useState("1Y");
  const [median, setMedian] = useState(70);
  const [colour, setColour] = useState(company ? color : "#B00020");
  const stock = stockData ? stockData.data : [];
  const [dummy_data, setData] = useState({
    labels: [
      65, 59, 76, 69, 80, 81, 56, 65, 59, 76, 39, 80, 81, 56, 65, 59, 76, 69,
      80, 81, 56,
    ],
    datasets: [
      {
        label: "Stock",
        fill: false,
        lineTension: 0,
        backgroundColor: "#FFFFFF",
        borderColor: colour,
        borderWidth: 2,
        data: [
          65, 59, 76, 69, 80, 81, 49, 65, 30, 76, 39, 80, 81, 56, 65, 59, 76,
          69, 80, 81, 56,
        ],
      },
    ],
  });
  const [trend, setTrend] = useState({
    ratio: "1.4%",
    trend: "up",
    diff: 0,
  });
  const switchPeriod = (period) => {
    setPeriod(period);
    let range = period.includes("1W")
      ? "5d"
      : period === "MAX"
      ? period
      : period.replace("M", "mo");
    let data = {
      interval: range.toLowerCase() === "1d" ? "15m" : "1d",
      identifier: commodity,
      range: range.toLowerCase(),
    };
    getCommodityData(data);
  };

  useEffect(() => {
    if (data) {
      generateChartData();
    }

    return () => {};
  }, [stock]);

  useEffect(() => {
    if (stockData && stockData.data) {
      generateChartData();
    }
    return () => {};
  }, [stockData]);

  const generateChartData = () => {
    let history = _.sortBy(company ? data : stockData.data, function (dateObj) {
      return new Date(company ? dateObj.date : dateObj.timestamp);
    });

    const new_data = { ...dummy_data };
    const median = history.length
      ? history.reduce((a, b) => a + (b.high + b.low) / 2, 0) / history.length
      : "none";
    let trendState =
      history.length &&
      history.length > 1 &&
      history[history.length - 1].close - history[0].close >= 0
        ? Primary
        : "#B00020";
    new_data.datasets[0].data = history.map((item) =>
      company ? item.last : item.close
    );
    new_data.datasets[0].borderColor = trendState;
    new_data.labels = history.map(
      (item) =>
        moment(item.date).format("DD MM YYY HH:MM a") ||
        moment(item.timestamp).format("DD-MM-YYYY HH:MM a")
    );
    setColour(trendState);

    setMedian(median);
    setData(new_data);
    let diff =
      history.length && history.length > 1
        ? history[history.length - 1].close - history[0].close
        : 0;

    let ratio =
      history.length && history.length > 1
        ? Math.abs(diff / history[0].close) * 100
        : 0;
    setTrend({
      ratio: trendState === Primary ? ratio.toFixed(2) : `-${ratio.toFixed(2)}`,
      trend: trendState,
      diff,
    });
  };
  const options = {
    title: {
      display: false,
    },

    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          display: false,
        },
      ],
      xAxes: [
        {
          display: false,
        },
      ],
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    annotation: {
      annotations: [
        {
          drawTime: "afterDatasetsDraw",
          borderColor: "#00000061",
          borderDash: [2, 2],
          borderWidth: 2,
          mode: "horizontal",
          type: "line",
          value: median,
          scaleID: "y-axis-0",
        },
      ],
    },

    maintainAspectRation: false,
  };
  return (
    <div className="peformance-chart-box chart-container">
      {stockData && stockData.data && stockData.data.length ? (
        <div
          className="flex-boy-row"
          style={{ width: "100%", justifyContent: "flex-start" }}
        >
          <Subtitle2
            style={{ fontSize: "1em", margin: "5px", textAlign: "left" }}
          >
            {stockData && stockData.data && stockData.data.length
              ? stockData.data[stockData.data.length - 1].close.toFixed(2)
              : null}{" "}
            {stockData && stockData.meta ? stockData.meta.currency : null}
          </Subtitle2>
          {trend.trend !== Primary ? (
            <CallReceived style={{ color: "#B00020" }} />
          ) : (
            <CallMade style={{ color: Primary }} />
          )}
          <Subtitle2
            style={{ fontSize: "1em" }}
            color={dummy_data.datasets[0].borderColor}
          >
            {trend.diff.toFixed(2)} ({trend.ratio}%){" "}
          </Subtitle2>
          <Subtitle2
            color="#00000061"
            style={{ fontSize: "1em", margin: "5px" }}
          >
            {period === "1W"
              ? "Past week"
              : period === "1M"
              ? "Past month"
              : period === "3M"
              ? "Past three months"
              : period === "1Y"
              ? "Past year"
              : "Since initial listing"}
          </Subtitle2>
        </div>
      ) : null}
      {stockData ? (
        <Fragment>
          <Line data={dummy_data} options={options} />
          <div className="flex-boy-row">
            {!company
              ? periods.map((time, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => switchPeriod(time)}
                      className="period flex-boy-column"
                      style={{
                        color: time === period ? "white" : colour,
                        backgroundColor: time === period ? colour : "white",
                      }}
                    >
                      <Headline6 color={time === period ? "white" : colour}>
                        {time}
                      </Headline6>
                    </div>
                  );
                })
              : null}
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  stockData: state.authReducer.commodity_stock,
  commodity: state.authReducer.selectedCommodity,
});

const mapDispatchToProps = (dispatch) => ({
  getCommodityData: (data) => dispatch(getCommodityData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sparkline);
