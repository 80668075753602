/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  ButtonCircle,
  Body1,
  Subtitle1,
  Headline6,
  Subtitle2,
  Button,
  NotifyDiv,
  Headline5,
  Primary,
} from "./general";
import { TextField, InputAdornment, Popover } from "@material-ui/core";
import moment from "moment";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {
  FormatAlignLeft,
  LocationOnOutlined,
  PeopleOutlineOutlined,
  PublicOutlined,
  AccessTime,
  KeyboardBackspace,
  Cancel,
} from "@material-ui/icons";
export const ReviewMeeting = ({ setRequest, meeting }) => {
  const [newMeeting, setMeeting] = useState({
    title: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlepopClose = () => {
    setAnchorEl(null);
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleInvite = () => {
    setRequest(false);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <NotifyDiv style={{ height: "fit-content" }}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="invitee-popover">
          <div style={{ padding: "10px" }} className="flex-boy-row">
            <KeyboardBackspace
              style={{ color: "#00000061" }}
              onClick={() => handlepopClose()}
            />
            <Subtitle1 color="#00000061">Search for People to add</Subtitle1>
            <Cancel
              fontSize="small"
              style={{ color: "#00000061" }}
              onClick={() => handlepopClose()}
            />
          </div>
        </div>
      </Popover>
      <Headline5 color={Primary}>{meeting.title}</Headline5>
      <form style={{ width: "100%", flex: 1 }}>
        <div style={{ marginBottom: "10px", marginTop: "25px" }}>
          <div
            style={{
              justifyContent: "normal",
              marginTop: "10px",
              alignItems: "normal",
            }}
            className="flex-boy-row"
          >
            <AccessTime style={{ color: Primary }} />
            <div
              style={{ flex: 1, alignItems: "normal" }}
              className="flex-boy-column"
            >
              <div style={{ marginBottom: "10px" }} className="flex-boy-row">
                <Subtitle1 style={{ marginLeft: "10px" }}>
                  {" "}
                  {moment(meeting.start_time).format("dddd, MMM DD, YYYY")}
                </Subtitle1>
                <Subtitle1 style={{ marginLeft: "10px" }}>
                  {moment(meeting.start_time).format("HH:MM A")}
                </Subtitle1>
              </div>
              <div className="flex-boy-row">
                <Subtitle1 style={{ marginLeft: "10px" }}>
                  {" "}
                  {moment(meeting.end_time).format("dddd, MMM DD, YYYY")}
                </Subtitle1>
                <Subtitle1 style={{ marginLeft: "10px" }}>
                  {moment(meeting.end_time).format("HH:MM A")}
                </Subtitle1>
              </div>
            </div>
          </div>
        </div>
        {/* <div style={{ marginBottom: "10px" }}>
          <div style={{ justifyContent: 'normal', marginTop: '10px' }} className="flex-boy-row">
            <PublicOutlined style={{ color: Primary }} />
            <Subtitle1 style={{ marginLeft: "10px" }}>Eastern Standard Time</Subtitle1>
          </div>

        </div> */}

        <div
          style={{
            marginBottom: "10px",
            padding: "10px",
            borderBottom: "1px solid #00000061",
          }}
        >
          <div
            style={{
              justifyContent: "normal",
              marginTop: "10px",
              textAlign: "left",
            }}
            className="flex-boy-row"
          >
            <PeopleOutlineOutlined style={{ color: Primary }} />

            <Subtitle1 style={{ marginLeft: "10px" }} color="#00000061">
              {meeting.attendees.map((item) => `${item.name},`)}
            </Subtitle1>
          </div>
        </div>

        <div
          style={{
            marginBottom: "10px",
            padding: "10px",
            borderBottom: "1px solid #00000061",
          }}
        >
          <div
            style={{
              justifyContent: "normal",
              marginTop: "10px",
              textAlign: "left",
            }}
            className="flex-boy-row"
          >
            <LocationOnOutlined style={{ color: Primary }} />
            <Subtitle1 style={{ marginLeft: "10px" }} color="#00000061">
              {meeting.location}
            </Subtitle1>
          </div>
        </div>

        <div
          style={{
            marginBottom: "10px",
            padding: "10px",
            borderBottom: "1px solid #00000061",
          }}
        >
          <div
            style={{
              justifyContent: "normal",
              marginTop: "10px",
              textAlign: "left",
            }}
            className="flex-boy-row"
          >
            <FormatAlignLeft style={{ color: Primary }} />
            <Subtitle1 style={{ marginLeft: "10px" }} color="#00000061">
              {meeting.description}
            </Subtitle1>
          </div>
        </div>
      </form>
      <div className="flex-boy-row">
        <Button
          style={{
            color: Primary,
            backgroundColor: "white",
            border: `1px solid ${Primary} `,
          }}
          onClick={() => handleInvite()}
        >
          Accept
        </Button>
        <Button onClick={() => handleInvite()}>Decline</Button>
      </div>
    </NotifyDiv>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewMeeting);
