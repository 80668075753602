import React, { useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import PowerCharts from "fusioncharts/fusioncharts.powercharts";
import ReactFusioncharts from "react-fusioncharts-fix";
import { apiRequest } from "../../../store/Actions/AuthActions";
import _ from "lodash";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../assets/ProspectorDesignAssets/lottie2.json";
import ContentLoader from "react-content-loader";
ReactFusioncharts.fcRoot(FusionCharts, charts, PowerCharts);
charts(FusionCharts);

const Sankey = ({ company, year, mineral, production_basis }) => {
  const [dataSource, setdataSource] = useState(null);
  useEffect(() => {
    setdataSource(null);
    fetchData();
    return () => {};
  }, []);

  const fetchData = async () => {
    const data = await apiRequest
      .get(
        `companies/${company.id}/mineral-reserve-resources-sankey-analysis?production_basis=${production_basis}&mineral=${mineral}&report_year=${year}`
      )
      .catch((err) => console.log(err));
    if (data?.data?.data) {
      setdataSource({
        chart: {
          theme: "fusion",
          orientation: "horizontal",
          linkalpha: 30,
          linkhoveralpha: 60,
          nodelabelposition: "start",
          canvasborderColor: "#B9E1F4",
          canvasborderThicknes: "0.5",
          showBorder: "0",
          bgcolor: "#FFFFFF",
          canvasbgAlpha: "0",
          canvasbgcolor: "0",
          paletteColors: "#142735,#B8BEC2,#9C8D64",
          rotateNodeLabels: "1",
          nodeLabelPosition: "inside",
          inheritLabelColorFromNode: "0",
          nodeLabelFontColor: "#FFFFFF",
          showlegend: "0",
        },

        nodes: _.sortBy(
          data.data.data.nodes.map((item) => {
            item.color =
              item.label === "Inferred"
                ? "#84BDD9"
                : item.label === "Measured and Indicated"
                ? "#51ACD9"
                : item.label === "Proven and Probable"
                ? "#1A9BDB"
                : "#142735";

            item.number =
              item.label === "Inferred"
                ? 1
                : item.label === "Measured and Indicated"
                ? 2
                : item.label === "Proven and Probable"
                ? 3
                : 3;
            return item;
          }),
          "number"
        ),
        links: _.sortBy(
          data.data.data.links.map((item) => {
            item.number =
              item.to === "Inferred"
                ? 1
                : item.to === "Measured and Indicated"
                ? 2
                : item.to === "Proven and Probable"
                ? 3
                : 3;
            return item;
          }),
          "number"
        ),
      });
    }
  };
  return (
    <div style={{ width: "100%", height: "550px", marginTop: "50px" }}>
      <ReactFusioncharts
        type="sankey"
        width="100%"
        height="550"
        dataFormat="JSON"
        dataSource={dataSource}
      />
    </div>
  );
};

export default Sankey;
