import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import InputLabel from "@material-ui/core/InputLabel";

import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { BorderColor } from "@material-ui/icons";
import {
  filterCompanies,
  filterFilings,
  filterProjects,
  filterReports,
} from "../store/Actions/AuthActions";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
    width: "100%",
    borderColor: "transparent",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    flex: 1,
  },
}));

export const Dropdownfilter = ({
  tab,
  filter,
  value,
  options,
  report_filters,
  news_filters,
  project_filters,
  company_filters,
  setCompanyFilters,
  setProjectFilters,
  setFilingFilters,
  setfilingpage,
  setReportFilters,
  setReportPage,
  setCompanyPage,
  setProjectPage,
  filings_filters,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedValue, setSelectedValue] = React.useState(`All ${filter}`);
  useEffect(() => {
    default_value();
    return () => {};
  }, [
    filter,
    tab,
    filings_filters,
    report_filters,
    project_filters,
    company_filters,
  ]);

  const default_value = () => {
    let filter_type =
      filter === "minerals" && tab !== "technical reports"
        ? "mineral"
        : filter === "mineral resource levels"
        ? tab === "technical reports"
          ? "mineral_estimate"
          : "resourceType"
        : filter === "countries" && tab === "technical reports"
        ? "count"
        : filter;

    if (tab === "projects") {
      setSelectedValue(project_filters[filter_type]?.[0] || `All ${filter}`);
      updateFilters(
        project_filters || {
          mineral: null,
          start_after: null,
          searchTerm: null,
          regions: null,
          exchanges: null,
          countries: null,
          stage: null,
          resourceType: null,
          averageGrade_min: 0,
          averageGrade_max: 0,
          oreTonnage_min: 0,
          oreTonnage_max: 0,
          containedMetal_min: 0,
          containedMetal_max: 0,
        }
      );

      // search_projects({loc:0,newFilters:filters})
    } else if (tab === "Recent News & Filings") {
      setSelectedValue(filings_filters[filter_type]?.[0] || `All ${filter}`);
      updateFilters(
        filings_filters || {
          headline: null,
          exchange: null,
          exact_phrase: null,
          all_of: [],
          none_of: [],
          phrase: {
            word_count: null,
            searchTerm: null,
            near: null,
          },
          date_range: null,
          filing_company: null,
          filing_type: null,
        }
      );
    } else if (tab === "companies") {
      setSelectedValue(company_filters[filter_type]?.[0] || `All ${filter}`);
      updateFilters(
        company_filters || {
          mineral: null,
          start_after: null,
          searchTerm: null,
          regions: null,
          exchanges: null,
          countries: null,
          stage: null,
          resourceType: null,
          averageGrade_min: 0,
          averageGrade_max: 0,
          oreTonnage_min: 0,
          oreTonnage_max: 0,
          containedMetal_min: 0,
          containedMetal_max: 0,
        }
      );
      // search_companies({loc:0,newFilters:filters})
    } else {
      setSelectedValue(
        filter_type === "start_after"
          ? report_filters[filter_type]
          : report_filters[filter_type]?.[0] || `All ${filter}`
      );
      updateFilters(
        report_filters || {
          minerals: null,
          searchTerm: null,
          exclude_sections: [],
          include_sections: [],
          all_of: [],
          none_of: [],
          exact_phrase: null,
          phrase: {
            word_count: null,
            searchTerm: null,
            near: null,
          },
          exchange: null,
          regions: null,
          country: null,
          mineral_estimate: null,
          publication_date: null,
          from_date: null,
          to_date: null,
          days: null,
          author: null,
          search_text: null,
        }
      );
      // search_companies({loc:0,newFilters:filters})
    }
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    let filter_type =
      filter === "minerals" && tab !== "technical reports"
        ? "mineral"
        : filter === "mineral resource levels"
        ? tab === "technical reports"
          ? "mineral_estimate"
          : "resourceType"
        : filter === "countries" && tab === "technical reports"
        ? "country"
        : filter;
    updateSingleFilterKey(
      filter_type,
      event.target.value === `All ${filter}` ? [] : [event.target.value]
    );
  };

  // filter mumbo jumbo
  const [filters, updateFilters] = useState({
    mineral: null,
    start_after: null,
    searchTerm: null,
    exchange: null,
    regions: null,
    countries: null,
    resourceType: null,
    averageGrade_min: 0,
    averageGrade_max: 0,
    oreTonnage_min: 0,
    oreTonnage_max: 0,
    containedMetal_min: 0,
    containedMetal_max: 0,
  });
  const setFilters = (filters) => {
    updateFilters(filters);
  };

  const search_companies = ({ loc, newFilters }) => {
    setCompanyPage(0);
    dispatch(filterCompanies());
  };
  const search_projects = ({ loc, newFilters }) => {
    setProjectPage(0);
    dispatch(filterProjects());
  };
  const search_reports = ({ loc, newFilters }) => {
    setReportPage(0);
    // dispatch(//filterReports());
  };
  const search_filings = () => {
    setfilingpage(0);
    dispatch(filterFilings());
  };

  const updateSingleFilterKey = (name, value) => {
    let newFilters =
      tab === "projects"
        ? { ...project_filters }
        : tab === "technical reports"
        ? { ...report_filters }
        : tab === "Recent News & Filings"
        ? { ...news_filters }
        : { ...company_filters };
    console.log(newFilters);
    if (JSON.stringify(newFilters[name]) === JSON.stringify(value)) {
      newFilters[name] = null;
      setFilters(newFilters);
      if (tab === "projects") {
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else if (tab === "technical reports") {
        setReportFilters(newFilters);
        search_reports({ loc: 0, newFilters });
      } else if (tab === "Recent News & Filings") {
        setFilingFilters(newFilters);
        search_filings({ loc: 0, newFilters });
      } else {
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
    } else {
      newFilters[name] = value;
      console.log(newFilters);
      setFilters(newFilters);
      if (tab === "projects") {
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else if (tab === "technical reports") {
        setReportFilters(newFilters);
        search_reports({ loc: 0, newFilters });
      } else if (tab === "Recent News & Filings") {
        setFilingFilters(newFilters);
        search_filings({ loc: 0, newFilters });
      } else {
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
    }
  };
  const updateSingleFilterKeynonselect = (name, value) => {
    let newFilters = { ...filters };

    if (newFilters[name] && newFilters[name].length) {
      let index = newFilters[name].indexOf(value);
      if (index !== -1) {
        newFilters[name].splice(index, 1);
        setFilters(newFilters);
        if (tab === "projects") {
          setProjectFilters(newFilters);
          search_projects({ loc: 0, newFilters });
        } else if (tab === "technical reports") {
          setReportFilters(newFilters);
          search_reports({ loc: 0, newFilters });
        } else {
          setCompanyFilters(newFilters);
          search_companies({ loc: 0, newFilters });
        }
      } else {
        newFilters[name].push(value);
        setFilters(newFilters);
        if (tab === "projects") {
          setProjectFilters(newFilters);
          search_projects({ loc: 0, newFilters });
        } else if (tab === "technical reports") {
          setReportFilters(newFilters);
          search_reports({ loc: 0, newFilters });
        } else if (tab === "Recent News & Filings") {
          setFilingFilters(newFilters);
          search_filings({ loc: 0, newFilters });
        } else {
          setCompanyFilters(newFilters);
          search_companies({ loc: 0, newFilters });
        }
      }
    } else {
      newFilters[name] = [value];
      setFilters(newFilters);
      if (tab === "projects") {
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else if (tab === "Recent News & Filings") {
        setFilingFilters(newFilters);
        search_filings({ loc: 0, newFilters });
      } else {
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
    }
  };

  return (
    <div className={`${classes.root} notched`}>
      <FormControl variant="outlined" className={classes.formControl}>
        {/* <InputLabel id="demo-simple-select-helper-label">{filter}</InputLabel> */}
        {filter !== "start_after" ? (
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={selectedValue}
            onChange={handleChange}
          >
            <MenuItem value={`All ${filter}`}>
              All{" "}
              {filter === "filing_type"
                ? "Filing Type"
                : filter === "mineral_estimate"
                ? "Mineral Estimate"
                : filter}
            </MenuItem>
            {options.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        ) : (
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={selectedValue || "1M"}
            onChange={handleChange}
          >
            {options.map((item) => (
              <MenuItem value={item}>Last {item}</MenuItem>
            ))}
            <MenuItem value={"ALL"}>ALL</MenuItem>
          </Select>
        )}
      </FormControl>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  project_filters: state.authReducer.projects_search_filters,
  report_filters: state.authReducer.report_search_filters,
  company_filters: state.authReducer.company_search_filters,
  news_filters: state.authReducer.filings_filters,
  filings_filters: state.authReducer.filings_filters,
});

const mapDispatchToProps = (dispatch) => ({
  setProjectFilters: (data) =>
    dispatch({ type: "SET_PROJECT_FILTERS", payload: data }),
  setCompanyFilters: (data) =>
    dispatch({ type: "SET_COMPANY_FILTERS", payload: data }),
  setReportFilters: (data) =>
    dispatch({ type: "SET_REPORTS_FILTERS", payload: data }),
  setCompanyPage: (data) =>
    dispatch({ type: "SET_COMPANIES_PAGE", payload: data }),
  setProjectPage: (data) =>
    dispatch({ type: "SET_PROJECTS_PAGE", payload: data }),
  setReportPage: (data) =>
    dispatch({ type: "SET_REPORTS_PAGE", payload: data }),
  setFilingFilters: (data) =>
    dispatch({ type: "SET_FILINGS_FILTERS", payload: data }),
  setfilingpage: (data) => dispatch({ type: "SET_FILING_PAGE", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dropdownfilter);
