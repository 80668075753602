import { Annotation } from "chart.js";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { apiRequest } from "../../../../../store/Actions/AuthActions";

export const Annotations = ({ close, company, mineral }) => {
  const [citations, setcitations] = useState(null);
  const data_types = ["Tonnage", "Grade", "Contained Metal"];

  useEffect(() => {
    fetchAnnotations();

    return () => {};
  }, [company]);

  const fetchAnnotations = async () => {
    let data = await apiRequest
      .get(`/companies/${company.id}/quarterly-production-annotations`)
      .catch((e) => {
        console.log(e);
      });
    if (data?.data.data) {
      setcitations(
        data.data.data.filter(
          (item) => item.annotation && item.annotation !== ""
        )
      );
    }
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex pt-4 justify-between">
        <p className="text-xl font-bold"> Production Data Annotations</p>
        <svg
          onClick={close}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4  break-all sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="max-w-full w-full divide-y whitespace-normal table-fixed break-all  divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Annotiation
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Project
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Years
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Metric Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Metric Type
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Minerals
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {citations?.map((citation) => (
                    <tr key={citation.id}>
                      <td className="w-1/4 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {citation.annotation}
                      </td>
                      <td className=" px-3 py-4 text-sm text-gray-500">
                        {citation.company_name}
                      </td>

                      <td className=" px-3 py-4 text-sm text-gray-500">
                        {citation.report_years}
                      </td>
                      <td className=" px-3 py-4 text-sm text-gray-500">
                        {citation.metrics.toString()}
                      </td>
                      <td className=" px-3 py-4 text-sm text-gray-500">NA</td>
                      <td className=" px-3 py-4 text-sm text-gray-500">
                        {citation.minerals?.toString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Annotations);
