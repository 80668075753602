/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  Headline6,
  MainPage,
  Primary,
  Headline5,
  Body1,
  Body2,
  GreyBox,
  Button,
  Subtitle1,
  Subtitle2,
  BrowseListFilter,
  Hexagon,
  TailwindButton,
  TailwindButtonOutline,
} from "../general";
import Logo from "../../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon1.png";
import SparkLine from "../SparkLine";
import _ from "lodash";
import {
  companyConnect,
  getCompanyDetail,
  loginWithNLPortal,
  manageSub,
  matchUsers,
} from "../../store/Actions/AuthActions";
import { Dialog } from "@material-ui/core";
import { List } from "../List";
export const CompanyCard = ({
  activeUser,
  match,
  matchUsers,
  history,
  setCompany,
  companyConnect,
  getCompanyDetail,
  user_follow_list,
  manageSub,
}) => {
  const elements = useSelector((state) => state.authReducer.minerals_list);

  const [trend, setTrend] = useState({
    ratio: "1.4%",
    trend: "up",
  });

  useEffect(() => {
    calculateTrend();
    //
    return () => {};
  }, []);

  const calculateTrend = () => {
    let stock = match.stockHistory ? [...match.stockHistory] : [];
    let sortStock = _.sortBy(stock, function (dateObj) {
      return new Date(dateObj.date);
    });
    let diff =
      stock.length && stock.length > 1
        ? sortStock[sortStock.length - 1].open -
          sortStock[sortStock.length - 2].open
        : 0;
    let trendState =
      stock.length && stock.length > 1
        ? sortStock[sortStock.length - 1].open -
            sortStock[sortStock.length - 2].open >=
          0
          ? "up"
          : "down"
        : "up";
    let ratio =
      stock.length && stock.length > 1
        ? Math.abs(diff / sortStock[sortStock.length - 2].open) * 100
        : 0;

    setTrend({
      ratio: trendState === "up" ? ratio.toFixed(2) : `-${ratio.toFixed(2)}`,
      trend: trendState,
    });
  };
  const goToDetail = (id) => {
    getCompanyDetail({ id, history });
  };
  const [ListOpen, setListOpen] = useState(false);
  const handleListOpen = () => {
    setListOpen(true);
  };

  const handleListClose = (value) => {
    setListOpen(false);
  };
  return (
    <div
      className="flex-boy-row"
      style={{
        width: "100%",

        marginBottom: "20px",
        borderBottom: "1px solid #00000061",
        padding: "5px",

        cursor: "pointer",
        boxSizing: "border-box",
      }}
    >
      <div className="company-logo  h-[60px] w-[60px] flex flex-col justify-center m-2">
        <img
          src={
            match.logo?.replace(
              "https://prospector-public-assets.s3.amazonaws.com/https%3A//",
              "https://"
            ) || Logo
          }
          alt="company logo"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = Logo;
          }}
        />
      </div>
      <Dialog
        onClose={handleListClose}
        aria-labelledby="simple-dialog-title"
        open={ListOpen}
      >
        <List handleClose={handleListClose} type={"company"} entity={match} />
      </Dialog>
      <div style={{ flex: 1 }}>
        <div onClick={() => goToDetail(match.id)} className="company-ticker">
          <Subtitle1 color={trend.trend !== "up" ? "#B00020" : "#00AC69"}>
            {match.ticker || match.name}.{match.exchange}
          </Subtitle1>
          <Body2>{match.name}</Body2>
        </div>
        <div
          className="flex-boy-row"
          style={{ justifyContent: "normal", flexWrap: "wrap" }}
        >
          <div className="rounded bg-slate-300 p-2 mr-2">
            <p className="text-sm">
              {match.projects_count}{" "}
              {match.projects_count === 1 ? "Project" : "Projects"}
            </p>
          </div>
          <div className="flex flex-row"></div>

          {match.minerals
            ? [...new Set(match.minerals)]
                .filter(
                  (item) =>
                    item !== "Not Available" &&
                    item !== "Combined" &&
                    item !== "Metric Tonnes"
                )
                .splice(0, 10)
                .map((min, index) => {
                  return (
                    <Hexagon
                      onClick={() => {
                        let com = {
                          name: min,
                          type: "mineral",
                          symbol: elements.find((item) => item.name === min)
                            ? elements
                                .find((item) => item.name === min)
                                .chemical_symbol.substring(0, 4)
                            : min.substring(0, 4),
                        };
                      }}
                      key={index}
                      width={28}
                      style={{ fontSize: "0.6rem" }}
                    >
                      {elements.find(
                        (item) => item.name.toLowerCase() === min.toLowerCase()
                      )
                        ? elements
                            .find(
                              (item) =>
                                item.name.toLowerCase() === min.toLowerCase()
                            )
                            .chemical_symbol.substring(0, 4)
                        : min.substring(0, 4)}
                    </Hexagon>
                  );
                })
            : null}
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <div className="flex h-full align-middle m-4">
          {/* <SparkLine
            // data={match.stockHistory}
            company={match}
            // color={trend.trend !== "up" ? "#B00020" : "#00AC69"}
          /> */}
          <Subtitle1>{match.market_cap}</Subtitle1>
        </div>
      </div>
      <div>
        {activeUser && activeUser?.permissions.includes("follow_resources") ? (
          // (!user_follow_list.includes(match.id) ?
          // <GreyBox onClick={() => matchUsers(match.id)} className="connect-button" style={{ fontWeight: 'bold', fontSize: '0.8rem', textTransform: 'uppercase', color: Primary, backgroundColor: "#00AC69", width: '75px' }}>Follow</GreyBox> :
          <TailwindButton
            onClick={() => setListOpen(true)}
            className="connect-button"
            style={{
              fontWeight: "bold",
              fontSize: "0.8rem",
              textTransform: "uppercase",
              color: Primary,
              backgroundColor: "#00AC69",
              width: "75px",
            }}
            text={
              user_follow_list
                ?.map((item) => item.list_entries)
                .flat()
                .includes(match.id)
                ? "Following"
                : "Follow"
            }
          />
        ) : (
          <TailwindButtonOutline
            onClick={() =>
              activeUser
                ? history.push("/upgrade")
                : history.push("/auth/login")
            }
            text="Connect"
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.authReducer.activeUser,
  user_follow_list: state.authReducer.lists,
});

const mapDispatchToProps = (dispatch) => ({
  matchUsers: (data) => dispatch(matchUsers(data)),
  companyConnect: (data) => dispatch(companyConnect(data)),
  setCompany: (data) => dispatch({ type: "SET_COMPANY", payload: data }),
  getCompanyDetail: (data) => dispatch(getCompanyDetail(data)),
  manageSub: () => dispatch(manageSub()),
  loginWithNLPortal: () => dispatch(loginWithNLPortal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCard);
