import React, { useEffect, useState } from "react";
import {
  ComponentContainer,
  Headline5,
  Primary,
  Headline6,
  Subtitle2,
} from "../../../../../components";
import Select from "react-select";
import { Exchanges } from "../../../../../configure/constants";
import AreaChart from "./AreaChart";
import Colunm from "./colunm";
import Costcolunm from "../../Costcolumn";
import ColunmCombined from "./ColunmCombined";

import { connect, useSelector } from "react-redux";
import Sankey from "./Sankey";
import { nFormatter } from "../../../../../configure/helpers";
import { apiRequest } from "../../../../../store/Actions/AuthActions";
import MineralDiv from "./MineralDivCosts";
import MineralDivProduction from "./MineralDivProduction";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../../../assets/ProspectorDesignAssets/lottie2.json";
import ContentLoader from "react-content-loader";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import CsvDownload from "react-json-to-csv";
import { Tooltip } from "@material-ui/core";
import { fontSize } from "@syncfusion/ej2-richtexteditor/src/rich-text-editor/models/items";
const customStyles = {
  control: (base) => ({
    ...base,
    fontSize: "x-large",

    minHeight: 50,
  }),
};
const mineral_List = [
  {
    name: "Gold",
  },
];
const CompanyProduction = ({ company, mineral }) => {
  const years_list = () => {
    var max = new Date().getFullYear();
    var min = max - 9;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  };

  const production_basis_list = [
    { label: "Attributional basis", value: "attributional_basis" },
    { label: "100% basis", value: "full_basis" },
  ];
  const [production_basis, setproduction_basis] = useState("full_basis");

  const metric_type_list = [
    { label: "Co-Product", value: "Co-product" },
    { label: "By-product", value: "By-product" },
  ];

  const [metric_type, setmetric_type] = useState("By-product");
  const elements = useSelector((state) => state.authReducer.minerals_list);
  const [prod_year, setProdyear] = useState(company.annual_prod_years[0]);
  const [cost_year, setCostyear] = useState(company.annual_prod_years[0]);
  const [showprodCharts, setshowProdCharts] = useState(false);
  const [showcostCharts, setshowCostCharts] = useState(false);
  const showHideCharts = () => {
    setshowProdCharts(!showprodCharts);
  };
  const showHideCostCharts = () => {
    setshowCostCharts(!showcostCharts);
  };

  const pdfExportComponent = React.useRef(null);
  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  const pdfExportComponentCost = React.useRef(null);
  const exportPDFWithComponentCost = () => {
    if (pdfExportComponentCost.current) {
      pdfExportComponentCost.current.save();
    }
  };

  return (
    <>
      <ComponentContainer>
        <div style={{ width: "100%", height: "fit-content" }}>
          <div className="flex flex-row flex-wrap w-full justify-between items-center">
            <Headline5 className=" lg:w-1/4 md:w-full">
              5.1 Annual Production{" "}
            </Headline5>

            <div className="flex-boy-row lg:w-1/4 md:w-full">
              {/* <div
                onClick={exportPDFWithComponent}
                className="flex flex-row cursor-pointer items-center justify-center h-[50px] w-[50px] m-2 mb-4 rounded border-solid border-2 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                  />
                </svg>
              </div> */}
              <Tooltip
                placement="top"
                title={
                  <div>
                    100% basis is based on the total amount for the project
                    regardless of company’s share.<br></br> Attributional basis
                    is based on the current ownership that the company holds in
                    the specific project
                  </div>
                }
              >
                <div
                  style={{
                    flex: 1,
                    boxSizing: "border-box",
                    padding: "10px",
                    paddingLeft: "0",
                    height: "80px",
                  }}
                >
                  <Select
                    displayEmpty
                    styles={customStyles}
                    onChange={(e) => setproduction_basis(e.value)}
                    value={{
                      value: production_basis,
                      label: production_basis_list.find(
                        (item) => item.value === production_basis
                      ).label,
                    }}
                    placeholder={"Attributional basis"}
                    options={production_basis_list.map((tag, index) => ({
                      value: tag.value,
                      label: tag.label,
                    }))}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
          <PDFExport
            ref={pdfExportComponent}
            paperSize="auto"
            margin={40}
            fileName={`Report for ${company.name} for ${mineral}`}
            author="Prospector Inc"
          >
            <MineralDivProduction
              company={company}
              key={mineral}
              year={prod_year}
              showCharts={showprodCharts}
              mineral={mineral}
              type={"quarterly-production"}
              production_basis={production_basis}
              metric_name={"Cash costs"}
            />
          </PDFExport>
        </div>
      </ComponentContainer>
      <ComponentContainer>
        <div style={{ width: "100%", height: "fit-content" }}>
          <div className="flex flex-row flex-wrap w-full justify-between items-center">
            <Headline5 className=" lg:w-1/4 md:w-full">
              5.2 Annual Production Costs
            </Headline5>

            <div className="flex-boy-row lg:w-1/4 md:w-full">
              <div
                style={{
                  flex: 1,
                  boxSizing: "border-box",
                  padding: "10px",
                  paddingLeft: "0",
                  height: "80px",
                }}
              >
                <Select
                  displayEmpty
                  styles={customStyles}
                  onChange={(e) => setmetric_type(e.value)}
                  value={{
                    value: metric_type,
                    label: metric_type_list.find(
                      (item) => item.value === metric_type
                    ).label,
                  }}
                  placeholder={"By-product"}
                  options={metric_type_list.map((tag, index) => ({
                    value: tag.value,
                    label: tag.label,
                  }))}
                />
              </div>

              {/* <div
                onClick={exportPDFWithComponentCost}
                className="flex flex-row cursor-pointer items-center justify-center h-[50px] w-[50px] m-2 mb-4 rounded border-solid border-2 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                  />
                </svg>
              </div> */}
            </div>
          </div>
          <PDFExport
            ref={pdfExportComponentCost}
            paperSize="auto"
            margin={40}
            fileName={`Report for ${company.name} for ${mineral}`}
            author="Prospector Inc"
          >
            <MineralDiv
              key={mineral}
              company={company}
              year={cost_year}
              mineral={mineral}
              showCharts={showcostCharts}
              type={"annual-production"}
              production_basis={"full_basis"}
              metric_name={"Cash costs"}
              metric_type={metric_type}
            />
          </PDFExport>
        </div>
      </ComponentContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  company: state.authReducer.selectedCompany,
  project_reports_loading: state.authReducer.project_reports_loading,
  reports: state.authReducer.selectedCompany_reports,
  activeUser: state.authReducer.activeUser,
  user_follow_list: state.authReducer.lists,
});

const mapDispatchToProps = (dispatch) => ({
  setSupscriptionPopup: (data) =>
    dispatch({ type: "SET_SUBSCRIPTION_POPUP", payload: data }),
  setSupscriptionMessage: (data) =>
    dispatch({ type: "SET_SUBSCRIPTION_MESSAGE", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProduction);
