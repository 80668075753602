import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import {
  apiRequest,
  appleLogin,
  linkedinLogin,
  loginWithNLPortal,
  updateUserDetails,
  userLogin,
  userSignup,
} from "../../../store/Actions/AuthActions";
import AsyncSelect from "react-select/async";

import _ from "lodash";
import SavedSearches from "../../SavedSearches";
import { toast } from "react-toastify";
import { interest_mapping } from "../../../configure/constants";

const customStyles = {
  // container: () => ({
  //    textAlign:'left'
  // }),
  // control: (base) => ({
  //     ...base,
  //   textAlign: 'left'
  // }),
};
export const index = ({
  history,
  updateUserDetails,
  userLogin,
  activeUser,
  linkedinLogin,
  appleLogin,
  loginWithNLPortal,
}) => {
  const [currentTab, setCurrentTab] = useState(2);
  const { loginWithRedirect } = useAuth0();
  const { signup } = useAuth0();

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [email, setEmail] = useState("");
  function validateEmail() {
    if (!email) {
      setEmailError("Please enter an email address.");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  }
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  const [emailError, setEmailError] = useState("");

  const handlePassSubmit = (event) => {
    event.preventDefault();
    if (password && email && passwordConfirm && password === passwordConfirm) {
      console.log(`pass: ${password}, Email: ${email}`);
      setPassword("");
      setEmail("");
      setPasswordError("");
      setEmailError("");

      const redirectUri = `${window.location.origin}/#/auth/sign-up`;
      setCurrentTab(2);
      loginWithRedirect({
        screen_hint: "signup",
        login_hint: email,
        email,
        password,
        appState: {
          callback: loginWithNLPortal(),
        },
        redirectUri,
      });
      //    setCurrentTab(2)
    } else {
      if (!password) {
        setPasswordError("Please enter your password.");
      }
      if (!email) {
        setEmailError("Please enter a valid email address.");
      }
      if (!passwordConfirm) {
        setPasswordConfirmError("Please confirm your password.");
      }
      if (passwordConfirm !== password) {
        setPasswordConfirmError("Confirm password does not match Password!");
      }
    }
  };

  const handleAfterLogin = async () => {
    console.log("🎸 User has logged in");
    // Call your function here
  };
  function validatePassword() {
    if (!password) {
      setPasswordError("Please enter your password.");
    } else {
      setPasswordError("");
    }
  }
  function validatePasswordConfirm() {
    if (!passwordConfirm) {
      setPasswordConfirmError("Please confirm your password.");
    } else if (passwordConfirm !== password) {
      setPasswordConfirmError("Confirm password does not match Password!");
    } else {
      setPasswordConfirmError("");
    }
  }
  const handleUserDetails = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    let data = {};
    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }
    updateUser(data);
  };

  const [Interests, setInterests] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [searchTerm, setsearchTerm] = useState(null);
  const fetchCompanies = async () => {
    let { data } = await apiRequest.get(`/companies?shallow=true`);

    if (data) {
      setCompanies(data.data);
    }
  };

  function handleInterestChange(event) {
    const { value } = event.target;
    const isChecked = event.target.checked;

    if (isChecked) {
      setInterests([...Interests, value]);
    } else {
      setInterests(Interests.filter((Interest) => Interest !== value));
    }
  }

  const searchText = async (value) => {
    console.log(value);

    let { data } = await apiRequest.get(
      `/search?collection=companies&searchTerm=${value}`
    );

    if (data) {
      setCompanies(data.data);
      return data.data.map((tag, index) => ({
        value: tag.id,
        label: tag.name.trim(),
      }));
    }
  };
  const handleInputchange = (value) => {
    setsearchTerm(value);
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(searchText(inputValue));
      }, 1000);
    });

  const [selected_company, setselected_company] = useState(null);

  const updateUser = async (data) => {
    let new_data = {
      ...data,
      company_name: selected_company?.label || "",
      current_company_position: data.position,
      current_company_id: selected_company?.value,
    };
    new_data.name = `${data["first-name"]} ${data["last-name"]}`;

    await updateUserDetails(_.omitBy(new_data, (v) => v !== 0 && !v));
    await saveSearches();
  };

  async function saveSearches() {
    const toastId = toast("Saving Searches", {
      type: "info",
      autoClose: false,
    });
    try {
      const promises = Interests.map(async (interest) => {
        const payload = {
          name: interest,
          search_index: "projects",
          query_filters: interest_mapping[`${interest}`],

          alert_frequency: "weekly",
        };
        await apiRequest.post("/saved-searches", payload);
        // console.log(payload)
      });
      await Promise.all(promises);
      toast.update(toastId, {
        theme: "light",
        render: "All searches saved successfully",
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      history.push("/browse");
    } catch (err) {
      toast.update(toastId, {
        theme: "light",
        render: err.response.data.message,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
      });
    }
  }

  return (
    <div className="flex min-h-full">
      <div className="flex flex-1 flex-col justify-center py-12 px-2 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        {currentTab === 1 && (
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-24 w-auto"
                src={require("../../../assets/ProspectorDesignAssets/Prospector_3D Logo_Lockup_Horizontal_with Tagline.png")}
                alt="Your Company"
              />
              <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                Start your 14-day free trial
              </h2>
              <p className="mt-2 text-sm text-gray-600">
                Or{" "}
                <a
                  href="#"
                  className="font-medium text-emerald-600 hover:text-emerald-500"
                  onClick={(event) => {
                    event.preventDefault();
                    history.push("/auth/sign-in");
                  }}
                >
                  Sign in to your account
                </a>
              </p>
            </div>

            <div className="mt-8">
              <div>
                <div>
                  <p className="text-sm font-medium text-gray-700">
                    Sign up with
                  </p>

                  <div className="mt-1 grid grid-cols-3 gap-3">
                    <div>
                      <a
                        href="#"
                        className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                        onClick={(event) => {
                          event.preventDefault();
                          // loginWithRedirect({
                          //   connection: 'google-oauth2'
                          // })
                          loginWithNLPortal({ history });
                        }}
                      >
                        <span className="sr-only">Sign in with Linkedin</span>
                        <img
                          className="relative inset-0 h-[20px] w-[40px] object-contain"
                          src={require("../../../assets/icon/linkedin.png")}
                          alt=""
                        />
                      </a>
                    </div>

                    <div>
                      <a
                        href="#"
                        className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                        onClick={(event) => {
                          event.preventDefault();
                          // loginWithRedirect({
                          //   connection: 'google-oauth2'
                          // })
                          loginWithNLPortal({ history });
                        }}
                      >
                        <span className="sr-only">Sign in with Twitter</span>
                        <img
                          className="relative inset-0 h-[20px] w-[40px] object-contain"
                          src={require("../../../assets/icon/google.png")}
                          alt=""
                        />
                      </a>
                    </div>

                    <div>
                      <a
                        href="#"
                        className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                        onClick={(event) => {
                          event.preventDefault();
                          // loginWithRedirect({
                          //   connection: 'google-oauth2'
                          // })
                          loginWithNLPortal({ history });
                        }}
                      >
                        <span className="sr-only">Sign in with Apple</span>
                        <img
                          className="relative inset-0 h-[20px] w-[40px] object-contain"
                          src={require("../../../assets/icon/apple.png")}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="relative mt-6">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="bg-white px-2 text-gray-500">
                      Or continue with
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <form className="space-y-6" onSubmit={handlePassSubmit}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 text-left"
                    >
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                        onChange={(event) => setEmail(event.target.value)}
                        onBlur={validateEmail}
                      />
                    </div>
                    {emailError && (
                      <div>
                        <p className="text-sm text-red-600">{emailError}</p>
                      </div>
                    )}
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700 text-left"
                    >
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                        onChange={(event) => setPassword(event.target.value)}
                        onBlur={validatePassword}
                      />
                    </div>
                    {passwordError && (
                      <div>
                        <p className="text-sm text-red-600">{passwordError}</p>
                      </div>
                    )}
                  </div>
                  <div className="space-y-1">
                    <label
                      htmlFor="passwordConfirm"
                      className="block text-sm font-medium text-gray-700 text-left"
                    >
                      Confirm Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="passwordConfirm"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                        onChange={(event) =>
                          setPasswordConfirm(event.target.value)
                        }
                        onBlur={validatePasswordConfirm}
                      />
                    </div>
                    {passwordConfirmError && (
                      <div>
                        <p className="text-sm text-red-600">
                          {passwordConfirmError}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-gray-900"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="text-sm">
                      <a
                        href="#"
                        className="font-medium text-emerald-600 hover:text-emerald-500"
                        onClick={(event) => {
                          event.preventDefault();
                          history.push("/auth/password-reset/password-email");
                        }}
                      >
                        Forgot your password?
                      </a>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-emerald-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                    >
                      Sign in
                    </button>
                  </div>
                </form>
                <div className="text-sm mt-2">
                  <p
                    href="#"
                    className="font-medium text-black hover:text-emerald-500"
                  >
                    By using Prospector you are agreeing to our{" "}
                    <a className="underline underline-offset-1">
                      terms of service
                    </a>{" "}
                    and{" "}
                    <a className="underline underline-offset-1">
                      privacy policy
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentTab === 2 && (
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-24 w-auto"
                src={require("../../../assets/ProspectorDesignAssets/Prospector_3D Logo_Lockup_Horizontal_with Tagline.png")}
                alt="Your Company"
              />
            </div>
            <p className=" text-left text-lg font-semibold">
              We just need a little more information before we can setup your
              account
            </p>
            <form onSubmit={handleUserDetails} className="space-y-6">
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label
                    htmlFor="first-name"
                    className="block text-left  text-sm font-medium text-gray-700"
                  >
                    First name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      required
                      autoComplete="given-name"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="last-name"
                    className="block text-left  text-sm font-medium text-gray-700"
                  >
                    Last name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      required
                      autoComplete="family-name"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="sm:col-span-12">
                  <label
                    htmlFor="Company"
                    className="block text-left  text-sm font-medium text-gray-700"
                  >
                    Company
                  </label>

                  <div className="text-left mt-1">
                    <AsyncSelect
                      styles={customStyles}
                      placeholder={"Company Search"}
                      cacheOptions
                      loadOptions={promiseOptions}
                      // defaultOptions
                      onInputChange={handleInputchange}
                      onChange={(e) => setselected_company(e)}
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="position"
                    className="block text-sm text-left  font-medium text-gray-700"
                  >
                    Position or Title
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="position"
                      id="position"
                      required
                      autoComplete="family-name"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="promotional_code"
                    className="block text-sm text-left  font-medium text-gray-700"
                  >
                    Promotional Code
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="promotional_code"
                      id="promotional_code"
                      autoComplete="family-name"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="w-full sm:col-span-12 text-left text-lg ">
                  <p>What are your Interests?</p>
                </div>

                <div className="flex h-5 items-center sm:col-span-6">
                  <input
                    id="Pre-Resource Projects"
                    value="Pre-Resource Projects"
                    type="checkbox"
                    checked={Interests.includes("Pre-Resource Projects")}
                    onChange={handleInterestChange}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="push-everything"
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    Pre-Resource Projects
                  </label>
                </div>
                <div className="flex h-5 items-center sm:col-span-6">
                  <input
                    id="Projects with Resources"
                    value="Projects with Resources"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    checked={Interests.includes("Projects with Resources")}
                    onChange={handleInterestChange}
                  />
                  <label
                    htmlFor="push-everything"
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    Resource Projects
                  </label>
                </div>
                {/* <div className="flex h-5 items-center sm:col-span-6">
                  <input
                    id="Producing Projects"
                    value="Producing Projects"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    checked={Interests.includes("Producing Projects")}
                    onChange={handleInterestChange}
                  />
                  <label
                    htmlFor="push-everything"
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    Producing Projects
                  </label>
                </div> */}
                <div className="flex h-5 items-center sm:col-span-6">
                  <input
                    id="Precious Metals"
                    value="Precious Metals Projects"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    checked={Interests.includes("Precious Metals Projects")}
                    onChange={handleInterestChange}
                  />
                  <label
                    htmlFor="push-everything"
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    Precious Metals
                  </label>
                </div>
                <div className="flex h-5 items-center sm:col-span-6">
                  <input
                    id="Base Metals"
                    value="Base Metals"
                    checked={Interests.includes("Base Metals")}
                    onChange={handleInterestChange}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="push-everything"
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    Base Metals
                  </label>
                </div>

                <div className="flex h-5 items-center">
                  <input
                    id="Battery Metals"
                    value="Battery Metals"
                    checked={Interests.includes("Battery Metals")}
                    onChange={handleInterestChange}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="push-everything"
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    Battery Metals
                  </label>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-emerald-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                >
                  Finish Account Setup
                </button>
              </div>
            </form>
          </div>
        )}
      </div>

      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={require("../../../assets/icon/bg.png")}
          alt=""
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  activeUser: state.authReducer.activeUser,
});

const mapDispatchToProps = (dispatch) => ({
  userLogin: (data) => dispatch(userLogin(data)),
  linkedinLogin: () => dispatch(linkedinLogin()),
  appleLogin: () => dispatch(appleLogin()),
  loginWithNLPortal: (data) => dispatch(loginWithNLPortal(data)),
  userSignup: (data) => dispatch(userSignup(data)),
  updateUserDetails: (data) => dispatch(updateUserDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
