import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import {
  appleLogin,
  linkedinLogin,
  loginWithNLPortal,
  userLogin,
} from "../../../store/Actions/AuthActions";

export const index = ({
  history,
  userLogin,
  activeUser,
  linkedinLogin,
  appleLogin,
  loginWithNLPortal,
}) => {
  const [currentTab, setCurrentTab] = useState(1);
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    switchAuth();
  }, [activeUser]);
  const switchAuth = () => {
    if (activeUser) {
      history.push("/browse");
    }
  };

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  function validateEmail() {
    if (!email) {
      setEmailError("Please enter an email address.");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  }
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password && email) {
      console.log(`pass: ${password}, Email: ${email}`);
      setPassword("");
      setEmail("");
      setPasswordError("");
      setEmailError("");
      userLogin({ password, email });
    } else {
      if (!password) {
        setPasswordError("Please enter your password.");
      }
      if (!email) {
        setEmailError("Please enter a valid email address.");
      }
    }
  };

  function validatePassword() {
    if (!password) {
      setPasswordError("Please enter your password.");
    } else {
      setPasswordError("");
    }
  }

  const signin = () => {
    history.push("/auth/sign-up");
    loginWithNLPortal({ history });
  };
  return (
    <div className="flex min-h-full">
      <div className="flex flex-1 flex-col justify-center py-12 px-2 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        {currentTab === 1 && (
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-24 w-auto"
                src={require("../../../assets/ProspectorDesignAssets/Prospector_3D Logo_Lockup_Horizontal_with Tagline.png")}
                alt="Your Company"
              />
              <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
                Sign in to your account
              </h2>
              {/* <p className="mt-2 text-sm text-gray-600">
                Or{" "}
                <a
                  href="#"
                  className="font-medium text-emerald-600 hover:text-emerald-500"
                  onClick={(event) => {
                    event.preventDefault();
                    history.push("/auth/sign-up");
                  }}
                >
                  start your 14-day free trial
                </a>
              </p> */}
            </div>

            <div className="mt-8">
              {/* <div>
                <div>
                  <p className="text-sm font-medium text-gray-700">
                    Sign in with
                  </p>

                  <div className="mt-1 grid grid-cols-3 gap-3">
                    <div>
                      <a
                        href="#"
                        className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                        onClick={(event) => {
                          event.preventDefault();
                          // loginWithRedirect({
                          //   connection: 'google-oauth2'
                          // })
                          loginWithNLPortal({ history });
                        }}
                      >
                        <span className="sr-only">Sign in with Linkedin</span>
                        <img
                          className="relative inset-0 h-[20px] w-[40px] object-contain"
                          src={require("../../../assets/icon/linkedin.png")}
                          alt=""
                        />
                      </a>
                    </div>

                    <div>
                      <a
                        href="#"
                        className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                        onClick={(event) => {
                          event.preventDefault();
                          // loginWithRedirect({
                          //   connection: 'google-oauth2'
                          // })
                          loginWithNLPortal({ history });
                        }}
                      >
                        <span className="sr-only">Sign in with Twitter</span>
                        <img
                          className="relative inset-0 h-[20px] w-[40px] object-contain"
                          src={require("../../../assets/icon/google.png")}
                          alt=""
                        />
                      </a>
                    </div>

                    <div>
                      <a
                        href="#"
                        className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                        onClick={(event) => {
                          event.preventDefault();
                          // loginWithRedirect({
                          //   connection: 'google-oauth2'
                          // })
                          loginWithNLPortal({ history });
                        }}
                      >
                        <span className="sr-only">Sign in with Apple</span>
                        <img
                          className="relative inset-0 h-[20px] w-[40px] object-contain"
                          src={require("../../../assets/icon/apple.png")}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="relative mt-6">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    
                  </div>
                </div>
              </div> */}

              <div className="mt-6">
                <form className="space-y-6" onSubmit={handleSubmit}>
                  {/* <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 text-left"
                    >
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                        onChange={(event) => setEmail(event.target.value)}
                        onBlur={validateEmail}
                      />
                    </div>
                    {emailError && (
                      <div>
                        <p className="text-sm text-red-600">{emailError}</p>
                      </div>
                    )}
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700 text-left"
                    >
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                        onChange={(event) => setPassword(event.target.value)}
                        onBlur={validatePassword}
                      />
                    </div>
                    {passwordError && (
                      <div>
                        <p className="text-sm text-red-600">{passwordError}</p>
                      </div>
                    )}
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-gray-900"
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="text-sm">
                      <a
                        href="#"
                        className="font-medium text-emerald-600 hover:text-emerald-500"
                        onClick={(event) => {
                          event.preventDefault();
                          history.push("/auth/password-reset/password-email");
                        }}
                      >
                        Forgot your password?
                      </a>
                    </div>
                  </div> */}

                  <div className="flex flex-row justify-between">
                    <button
                      onClick={() => loginWithNLPortal({ history })}
                      className="flex w-full justify-center rounded-md border border-transparent m-1 bg-emerald-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                    >
                      Sign in
                    </button>
                    <button
                      onClick={signin}
                      className="flex w-full justify-center rounded-md  m-1 bg-white py-2 px-4 text-sm font-medium text-emerald-500 shadow-sm border border-emerald-400 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                    >
                      Sign up
                    </button>
                  </div>
                </form>

                <div className="text-sm mt-2">
                  <p className="font-medium text-black ">
                    By using Prospector you are agreeing to our{" "}
                    <a
                      href="https://www.prospectorportal.com/terms-of-service"
                      className="underline underline-offset-1 hover:text-emerald-500 cursor-pointer"
                    >
                      terms of service
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.prospectorportal.com/privacy-policy"
                      className="underline underline-offset-1 hover:text-emerald-500 cursor-pointer"
                    >
                      privacy policy
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={require("../../../assets/icon/bg.png")}
          alt=""
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  activeUser: state.authReducer.activeUser,
});

const mapDispatchToProps = (dispatch) => ({
  userLogin: (data) => dispatch(userLogin(data)),
  linkedinLogin: () => dispatch(linkedinLogin()),
  appleLogin: () => dispatch(appleLogin()),
  loginWithNLPortal: (data) => dispatch(loginWithNLPortal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
