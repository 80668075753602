// /* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable no-use-before-define */
// /* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  Primary,
  Headline5,
  Button,
  Subtitle1,
  Subtitle2,
  BrowseListFilter,
  NotifyDiv,
  Headline6,
  TailwindButton,
  TailwindButtonOutline,
} from "../../../../components/general";
import CreatableSelect from "react-select/creatable";
import CloseIcon from "@material-ui/icons/Close";
import {
  searchAlgoliaCompanies,
  filterProjects,
  filterCompanies,
  setProjectStartDate,
  filterReports,
} from "../../../../store/Actions/AuthActions";
import {
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
  Popover,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Exchanges } from "../../../../configure/constants";
import Select from "react-select";
import axios from "axios";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { apiRequest } from "../../../../store/Actions/AuthActions";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../../assets/ProspectorDesignAssets/lottie2.json";
import { DateRange, DateRangePicker } from "react-date-range";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";

import AsyncSelect from "react-select/async";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    // minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 100,
  },
}));
const counts = ["10", "20", "30", "40", "50", "100"];
const continents = [
  "Asia",
  "North America",

  "Latin America",
  "Africa",
  "Antarctica",
  "Europe",
  "Oceania",
];
const customStyles = {
  container: () => ({
    flex: 1,
    margin: "5px",

    position: "relative",
    boxSizing: "border-box",
  }),
  control: (base) => ({
    ...base,

    minHeight: 50,
    fontSize: "1rem !important",
  }),
  placeholder: (base) => ({
    ...base,
    color: "#a7a3a3",
  }),
  menu: (base) => ({
    ...base,
    fontSize: "12px !important",
  }),
};
const customStyles2 = {
  container: () => ({
    width: "200px !important",
    margin: "5px",
    position: "relative",
    boxSizing: "border-box",
  }),
  control: (base) => ({
    ...base,

    fontSize: "1rem !important",
    minHeight: 50,
  }),
  placeholder: (base) => ({
    ...base,
    color: "#a7a3a3",
  }),
  menu: (base) => ({
    ...base,
    fontSize: "12px !important",
  }),
};
const periods = ["1M", "3M", "1Y", "5Y", "ALL"];
export const FilterContent = ({
  reports_loading,
  reports_length,
  setProjectStartDate,
  setReportPage,
  filterReports,
  setReportFilters,
  setCompanyPage,
  projects_length,
  map_projects,
  companies_length,
  setProjectPage,
  searchAlgoliaCompanies,
  auth,
  tab,
  handleClose,
  filterProjects,
  setProjectFilters,
  setCompanyFilters,
  project_filters,
  company_filters,
  filterCompanies,
  report_filters,
}) => {
  let me = auth.uid;
  const [value, setValue] = React.useState([20, 37]);
  const [extraMinerals, setMinerals] = useState([]);
  const [extraRegions, setRegions] = useState([]);
  const [showextraRegions, showRegions] = useState(false);
  const [showextraMinerals, showMinerals] = useState(false);
  const [showdateRange, setshowdateRange] = useState(true);
  const classes = useStyles();
  useEffect(() => {
    getFilterInfo();
    fetchCompanies();
    updateFilters(
      report_filters || {
        minerals: null,
        start_after: null,
        searchTerm: null,
        regions: null,
        countries: null,
        stage: null,
        resourceType: null,
        averageGrade_min: 0,
        averageGrade_max: 0,
        oreTonnage_min: 0,
        oreTonnage_max: 0,
        containedMetal_min: 0,
        containedMetal_max: 0,
      }
    );

    return () => {};
  }, []);

  const getFilterInfo = async () => {
    const countries_data = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/config/countries`
    );
    const minerals_data = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/config/minerals`
    );

    if (countries_data) {
      setRegions([...continents, ...countries_data.data.data.sort()]);
    }
    if (minerals_data) {
      setMinerals([...new Set(minerals_data.data.data)]);
    }
  };
  const [filters, updateFilters] = useState({
    mineral: null,
    start_after: null,
    searchTerm: null,
    regions: null,
    countries: null,
    resourceType: null,
    averageGrade_min: 0,
    averageGrade_max: 0,
    oreTonnage_min: 0,
    oreTonnage_max: 0,
    containedMetal_min: 0,
    containedMetal_max: 0,
  });
  const setFilters = (filters) => {
    updateFilters(filters);
  };

  const search_companies = ({ loc, newFilters }) => {
    setCompanyPage(0);
    filterCompanies();
  };
  const search_projects = ({ loc, newFilters }) => {
    setProjectPage(0);
    filterProjects();
  };
  const search_reports = ({ loc, newFilters }) => {
    setReportPage(0);
    //filterReports();
  };
  function numFormatter(num) {
    if (num > 999) {
      return (num / 1000000).toFixed(1) + " Million"; // convert to M for number from > 1 million
    } else {
      return num; // if value < 1000, nothing to do
    }
  }

  const updateSlider = (name, val) => {
    let newFilters = { ...filters };
    newFilters[`${name}_min`] = val[0] * 1000000;
    newFilters[`${name}_max`] = val[1] * 1000000;
    setFilters(newFilters);
    if (tab === "projects") {
      setProjectFilters(newFilters);
      search_projects({ loc: 0, newFilters });
    } else {
      setCompanyFilters(newFilters);
      search_companies({ loc: 0, newFilters });
    }
  };
  const updateAverageGrade = (name, val) => {
    let newFilters = { ...filters };
    newFilters[`${name}_min`] = val[0];
    newFilters[`${name}_max`] = val[1];

    setFilters(newFilters);
    if (tab === "projects") {
      setProjectFilters(newFilters);
      search_projects({ loc: 0, newFilters });
    } else {
      setCompanyFilters(newFilters);
      search_companies({ loc: 0, newFilters });
    }
  };
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const resTypes = [
    {
      name: "Indicated Mineral Resource",
      value: 85,
      indicator: "indicated",
    },
    {
      name: "Inferred Mineral Resource",
      value: 84,
      indicator: "inferred",
    },
    {
      name: "Measured Mineral Resource",
      value: 86,
      indicator: "measured",
    },
    {
      name: "Not Mentioned",
      value: 89,
      indicator: "not mentioned",
    },
    {
      name: "Probable Mineral Reserve",
      value: 87,
      indicator: "probable",
    },

    {
      name: "Proven Mineral Reserve",
      value: 88,
      indicator: "proven",
    },
  ];
  const stages = [
    "Discovery Delineation",
    "Grassroots",
    "Production",
    "Target Drilling",
    "Prefeasibility",
    "PEA",
    "Permitting & Feasibility",
    "Construction",
    "Suspended",
  ];
  const range_map = {
    "1W": 7,
    "1M": 30,
    "3M": 90,
    "1y": 400,
    "5Y": 1780,
    ALL: 30000,
  };
  const sections = [
    "Summary",
    "Introduction",
    "Reliance on Other Experts",
    "Property Description and Location",
    "Accessibility, Climate, Local Resources, Infrastructure and Physiography",
    "History",
    "Geological Setting and Mineralization",
    "Deposit Types",
    "Exploration",
    "Drilling",
    "Sample Preparation, Analyses and Security",
    "Data Verification",
    "Mineral Processing and Metallurgical Testing",
    "Mineral Resource Estimates",
    "Mineral Reserve Estimates",
    "Mining Methods",
    "Recovery Methods",
    "Project Infrastructure",
    "Market Studies and Contracts",
    "Environmental Studies, Permitting and Social or Community Impact",
    "Capital and Operating Costs",
    "Economic Analysis",
    "Adjacent Properties",
    "Other Relevant Data and Information",
    "Interpretation and Conclusions",
    "Recommendations",
  ];

  const section_List = {
    section01: "01. Summary",
    section02: "02. Introduction",
    section03: "03. Reliance on Other Experts",
    section04: "04. Property Description and Location",
    section05:
      "05. Accessibility, Climate, Local Resources, Infrastructure and Physiography",
    section06: "06. History",
    section07: "07. Geological Setting and Mineralization",
    section08: "08. Deposit Types",
    section09: "09. Exploration",
    section10: "10. Drilling",
    section11: "11. Sample Preparation, Analyses and Security",
    section12: "12. Data Verification",
    section13: "13. Mineral Processing and Metallurgical Testing",
    section14: "14. Mineral Resource Estimates",
    section15: "15. Mineral Reserve Estimates",
    section16: "16. Mining Methods",
    section17: "17. Recovery Methods",
    section18: "18. Project Infrastructure",
    section19: "19. Market Studies and Contracts",
    section20:
      "20. Environmental Studies, Permitting and Social or Community Impact",
    section21: "21. Capital and Operating Costs",
    section22: "22. Economic Analysis",
    section23: "23. Adjacent Properties",
    section24: "24. Other Relevant Data and Information",
    section25: "25. Interpretation and Conclusions",
    section26: "26. Recommendations",
  };

  const filterMinerals = [
    "cobalt",
    "copper",
    "gold",
    "rare earth elements",
    "lithium",
    "silver",
    "nickel",
    "palladium",
  ];
  const updateSingleFilterKey = (name, value) => {
    let newFilters = { ...filters };

    if (JSON.stringify(newFilters[name]) === JSON.stringify(value)) {
      newFilters[name] = null;
      setFilters(newFilters);
      setReportFilters(newFilters);
      search_reports({ loc: 0, newFilters });
    } else {
      newFilters[name] = value;

      setFilters(newFilters);
      setReportFilters(newFilters);
      search_reports({ loc: 0, newFilters });
    }
  };
  const updateObjectFilterKey = (parent, name, value) => {
    let newFilters = { ...filters };
    console.log(newFilters, parent, name, value);
    if (JSON.stringify(newFilters[name]) === JSON.stringify(value)) {
      newFilters[parent][name] = null;
      setFilters(newFilters);
      setReportFilters(newFilters);
      search_reports({ loc: 0, newFilters });
      // search_filings({ loc: 0, newFilters });
    } else {
      newFilters[parent][name] = value;

      setFilters(newFilters);
      setReportFilters(newFilters);
      search_reports({ loc: 0, newFilters });
      // search_filings({ loc: 0, newFilters });
    }
  };
  const updateSingleFilterKeynonselect = (name, value) => {
    let newFilters = { ...filters };

    if (newFilters[name] && newFilters[name].length) {
      let index = newFilters[name].indexOf(value);
      if (index !== -1) {
        newFilters[name].splice(index, 1);
        setFilters(newFilters);
        if (tab === "projects") {
          setProjectFilters(newFilters);
          search_projects({ loc: 0, newFilters });
        } else {
          setCompanyFilters(newFilters);
          search_companies({ loc: 0, newFilters });
        }
      } else {
        newFilters[name].push(value);
        setFilters(newFilters);
        if (tab === "projects") {
          setProjectFilters(newFilters);
          search_projects({ loc: 0, newFilters });
        } else {
          setCompanyFilters(newFilters);
          search_companies({ loc: 0, newFilters });
        }
      }
    } else {
      newFilters[name] = [value];
      setFilters(newFilters);
      if (tab === "projects") {
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
    }
  };
  const clearFilters = () => {
    let newFilters = { ...filters };

    Object.keys(newFilters).forEach((i) => (newFilters[i] = null));

    setReportFilters(newFilters);

    updateFilters(newFilters);
    search_reports({ loc: 0, newFilters });
    setshowdateRange(true);
    // handleClose()
  };
  const date = useRef(null);
  const switchPeriod = (period) => {
    let range = period.includes("1W")
      ? 7
      : period === "ALL"
      ? 30000
      : period === "1D"
      ? 1
      : period === "1M"
      ? 30
      : period === "3M"
      ? 90
      : period === "6M"
      ? 180
      : period === "2Y"
      ? 730
      : period === "3Y"
      ? 1095
      : period === "5Y"
      ? 1780
      : period === "1Y"
      ? 400
      : 90;
    updateSingleFilterKey("days", range);
  };
  const change_text = (e) => {};
  const projectChange = (e, value) => {};
  const [companies, setCompanies] = useState([]);

  const fetchCompanies = async () => {
    let { data } = await apiRequest.get(`/companies?shallow=true`);

    if (data) {
      setCompanies(data.data);
    }
  };
  const onChange = (props) => {
    console.log(date.current, props.startDate);
    if (props.startDate) {
      const stateDate = moment(props.startDate).format("DD/MM/YYYY");
      const endDate = moment(props.endDate).format("DD/MM/YYYY");
      updateSingleFilterKey("date_range", [stateDate, endDate]);
    } else {
      updateSingleFilterKey("date_range", null);
    }
  };

  const searchText = async (value) => {
    console.log(value);

    let { data } = await apiRequest.get(
      `/search?collection=companies&searchTerm=${value}`
    );

    if (data) {
      setCompanies(data.data);
      return data.data.map((tag, index) => ({
        value: tag.name.trim(),
        label: tag.name,
      }));
    }
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(searchText(inputValue));
      }, 1000);
    });

  const [anchorEl, setAnchorEl] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const inputRef = useRef();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosepop = () => {
    setAnchorEl(null);
  };

  const handleSelect = (ranges) => {
    setDateRange(ranges.selection);
    if (
      ranges.selection.startDate.getTime() !==
      ranges.selection.endDate.getTime()
    ) {
      const stateDate = moment(ranges.selection.startDate).format("YYYY-MM-DD");
      const endDate = moment(ranges.selection.endDate).format("YYYY-MM-DD");

      updateSingleFilterKey("date_range", [stateDate, endDate]);
      handleClosepop();
      setAnchorEl(null);
      console.log(open, anchorEl);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const clearDateRange = () => {
    updateSingleFilterKey("date_range", null);
  };

  return (
    <div
      className="flex-boy-column h-[calc(100vh_-_80px)] md:h-[calc(100vh_-_160px)]"
      style={{ width: "100%" }}
    >
      <div
        className="flex-boy-row"
        style={{
          width: "100%",
          justifyContent: "normal",
          padding: "20px",
          boxSizing: "border-box",
          textAlign: "left",
        }}
      >
        <Headline5 style={{ flex: 1 }}>Advanced Report search</Headline5>
      </div>
      <div
        className="flex-boy-column"
        style={{ flex: 1, justifyContent: "normal", width: "100%" }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "left",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <div className="flex-boy-row">
            <div
              className="py-2"
              style={{
                flex: 1,
                boxSizing: "border-box",

                minHeight: "80px",
              }}
            >
              <Select
                isMulti
                displayEmpty
                value={
                  filters?.minerals?.map((tag) => ({
                    value: tag,
                    label: tag,
                  })) || ""
                }
                styles={customStyles}
                // MenuProps={{ classes: { paper: classes.menuPaper } }}
                onChange={(e) =>
                  updateSingleFilterKey(
                    "minerals",
                    e.map((item) => item.value)
                  )
                }
                placeholder={"All minerals"}
                options={extraMinerals.map((tag, index) => ({
                  value: tag.trim(),
                  label: tag,
                }))}
              />
            </div>
            <div
              className="py-2"
              style={{
                flex: 1,
                boxSizing: "border-box",

                minHeight: "80px",
              }}
            >
              <Select
                isMulti
                displayEmpty
                value={
                  filters?.mineral_estimate?.map((tag) => ({
                    value: tag,
                    label: tag,
                  })) || ""
                }
                styles={customStyles}
                // MenuProps={{ classes: { paper: classes.menuPaper } }}
                onChange={(e) =>
                  updateSingleFilterKey(
                    "mineral_estimate",
                    e.map((item) => item.value)
                  )
                }
                placeholder={"All Mineral Estimate Levels"}
                options={resTypes.map((tag, index) => ({
                  value: tag.name.trim(),
                  label: tag.name,
                }))}
              />
            </div>
            <div
              className="py-2"
              style={{
                flex: 1,
                boxSizing: "border-box",

                minHeight: "80px",
              }}
            >
              <Select
                isMulti
                value={
                  filters?.region
                    ?.filter((tag) => tag !== "South america")
                    .map((tag) => ({ value: tag, label: tag })) || ""
                }
                styles={customStyles}
                onChange={(e) =>
                  updateSingleFilterKey(
                    "region",
                    e.map((item) => item.value)
                  )
                }
                placeholder={"All countries and Regions"}
                options={extraRegions.map((tag, index) => ({
                  value: tag,
                  label: tag.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                  ),
                }))}
              />
            </div>
          </div>
        </div>

        <div
          className="px-5 mb-4"
          style={{
            width: "100%",
            textAlign: "left",

            boxSizing: "border-box",
          }}
        >
          <div className="flex-boy-row">
            {/* <div
              className="py-2"
              style={{
                flex: 1,
                boxSizing: "border-box",

                minHeight: "80px",
              }}
            >
              <Select
                isMulti
                value={
                  filters?.include_sections?.map((tag) => ({
                    value: section_List[tag],
                    label: section_List[tag],
                  })) || ""
                }
                styles={customStyles}
                onChange={(e) =>
                  updateSingleFilterKey(
                    "include_sections",
                    e.map((item) =>
                      Object.keys(section_List).find(
                        (key) => section_List[key] === item.value
                      )
                    )
                  )
                }
                placeholder={"Include sections"}
                options={Object.values(section_List).map((tag, index) => ({
                  value: tag,
                  label: tag.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                  ),
                }))}
              />
            </div> */}
            {/* <div
              className="py-2"
              style={{
                flex: 1,
                boxSizing: "border-box",

                minHeight: "80px",
              }}
            >
              <Select
                isMulti
                value={
                  filters?.exclude_sections?.map((tag) => ({
                    value: section_List[tag],
                    label: section_List[tag],
                  })) || ""
                }
                styles={customStyles}
                onChange={(e) =>
                  updateSingleFilterKey(
                    "exclude_sections",
                    e.map((item) =>
                      Object.keys(section_List).find(
                        (key) => section_List[key] === item.value
                      )
                    )
                  )
                }
                placeholder={"Exclude sections"}
                options={Object.values(section_List).map((tag, index) => ({
                  value: tag,
                  label: tag.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                  ),
                }))}
              />
            </div> */}
            <div
              style={{
                flex: 1,
                boxSizing: "border-box",
                padding: "10px",
                minHeight: "80px",
                // paddingTop: "15px",
              }}
            >
              <TextField
                value={
                  filters?.date_range
                    ? `${filters?.date_range?.[0]} / ${filters.date_range?.[1]}`
                    : ""
                }
                // onChange={(e) => updateSingleFilterKey("headline", e.target.value)}
                inputRef={inputRef}
                onClick={handleClick}
                id="outlined-basic"
                fullWidth
                label="Date Range"
                variant="outlined"
                placeholder="Date range"
                InputProps={{
                  endAdornment: filters?.date_range && (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDateRange({
                            startDate: new Date(),
                            endDate: new Date(),
                            key: "selection",
                          });
                          clearDateRange();
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosepop}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <DateRange ranges={[dateRange]} onChange={handleSelect} />
              </Popover>
            </div>
          </div>
        </div>
        <div
          className="px-6 mb-4 flex-boy-row"
          style={{
            width: "100%",
            textAlign: "left",

            boxSizing: "border-box",
          }}
        >
          <AsyncSelect
            isMulti
            styles={customStyles}
            placeholder={"Filing Company"}
            onChange={(e) =>
              updateSingleFilterKey(
                "filing_company",
                e.map((item) => item.value)
              )
            }
            cacheOptions
            loadOptions={promiseOptions}
            // defaultOptions
          />
        </div>
        <div
          className="px-6 mb-4 flex-boy-row"
          style={{
            width: "100%",
            textAlign: "left",

            boxSizing: "border-box",
          }}
        >
          <TextField
            value={filters?.author || ""}
            onChange={(e) => updateSingleFilterKey("author", e.target.value)}
            id="outlined-basic"
            fullWidth
            label=""
            variant="outlined"
            placeholder="Search by Qualified Author"
          />
        </div>

        <div
          className="px-5 mb-4 flex-boy-row"
          style={{
            width: "100%",
            textAlign: "left",

            boxSizing: "border-box",
          }}
        >
          <Subtitle1 className="w-[15%] pl-1 ">Report title</Subtitle1>
          <div className="flex-1 m-1">
            <TextField
              value={filters?.headline || ""}
              onChange={(e) =>
                updateSingleFilterKey("headline", e.target.value)
              }
              id="outlined-basic"
              fullWidth
              label=""
              variant="outlined"
              placeholder="Search by words in title"
            />
          </div>
        </div>
        {/* <div
          style={{
            width: "100%",
            textAlign: "left",
            padding: "20px",
            paddingRight: "30px",
            boxSizing: "border-box",
          }}
        >
          <TextField
            value={filters?.search_text || ""}
            onChange={(e) =>
              updateSingleFilterKey("search_text", e.target.value)
            }
            id="outlined-basic"
            fullWidth
            label=""
            variant="outlined"
            placeholder="Open Text Search of Reports"
          />
        </div> */}

        <div
          className="px-5 mb-4 flex-boy-row"
          style={{
            width: "100%",
            textAlign: "left",

            boxSizing: "border-box",
          }}
        >
          <Subtitle1 className="w-[15%] pl-1">All these Words</Subtitle1>
          <CreatableSelect
            displayEmpty
            isMulti
            value={
              filters?.all_of?.map((tag) => ({
                value: tag,
                label: tag,
              })) || ""
            }
            styles={customStyles}
            // MenuProps={{ classes: { paper: classes.menuPaper } }}
            onChange={(e) =>
              updateSingleFilterKey(
                "all_of",
                e.map((item) => item.value)
              )
            }
            placeholder={"All of these words"}
          />
          {/* <Select
            isMulti
            value={
              filters?.all_of?.map((tag) => ({
                value: section_List[tag],
                label: section_List[tag],
              })) || ""
            }
            styles={customStyles2}
            onChange={(e) =>
              updateSingleFilterKey(
                "all_words_sections",
                e.map((item) =>
                  Object.keys(section_List).find(
                    (key) => section_List[key] === item.value
                  )
                )
              )
            }
            placeholder={"sections"}
            options={Object.values(section_List).map((tag, index) => ({
              value: tag,
              label: tag.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase()
              ),
            }))}
          /> */}
        </div>
        <div
          className="px-5 mb-4 flex-boy-row"
          style={{
            width: "100%",
            textAlign: "left",

            boxSizing: "border-box",
          }}
        >
          <Subtitle1 className="w-[15%] pl-1">This exact phrase</Subtitle1>
          <div className="flex-1 m-1">
            <TextField
              value={filters?.exact_phrase || ""}
              onChange={(e) =>
                updateSingleFilterKey("exact_phrase", e.target.value)
              }
              id="outlined-basic"
              fullWidth
              label=""
              variant="outlined"
              placeholder="Search by exact phrase"
            />
          </div>
          {/* <Select
            isMulti
            style={{ width: "100px" }}
            value={
              filters?.all_words_sections?.map((tag) => ({
                value: section_List[tag],
                label: section_List[tag],
              })) || ""
            }
            styles={customStyles2}
            onChange={(e) =>
              updateSingleFilterKey(
                "all_words_sections",
                e.map((item) =>
                  Object.keys(section_List).find(
                    (key) => section_List[key] === item.value
                  )
                )
              )
            }
            placeholder={"sections"}
            options={Object.values(section_List).map((tag, index) => ({
              value: tag,
              label: tag.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase()
              ),
            }))}
          /> */}
        </div>
        {/* <div
          className="px-5 mb-4 flex-boy-row"
          style={{
            width: "100%",
            textAlign: "left",

            boxSizing: "border-box",
          }}
        >
          <Subtitle1 className="w-[15%] pl-1">None of these words</Subtitle1>
          <CreatableSelect
            style={{ flex: 1 }}
            displayEmpty
            isMulti
            value={
              filters?.none_of?.map((tag) => ({
                value: tag,
                label: tag,
              })) || ""
            }
            styles={customStyles}
            // MenuProps={{ classes: { paper: classes.menuPaper } }}
            onChange={(e) =>
              updateSingleFilterKey(
                "none_of",
                e.map((item) => item.value)
              )
            }
            placeholder={"None of these words"}
          />
          <Select
            isMulti
            value={
              filters?.none_of_sections?.map((tag) => ({
                value: section_List[tag],
                label: section_List[tag],
              })) || ""
            }
            styles={customStyles2}
            onChange={(e) =>
              updateSingleFilterKey(
                "none_of_sections",
                e.map((item) =>
                  Object.keys(section_List).find(
                    (key) => section_List[key] === item.value
                  )
                )
              )
            }
            placeholder={"sections"}
            options={Object.values(section_List).map((tag, index) => ({
              value: tag,
              label: tag.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase()
              ),
            }))}
          />
        </div> */}

        {/* <div
          className="px-5 mb-4 flex-boy-row"
          style={{
            width: "100%",
            textAlign: "left",

            boxSizing: "border-box",
          }}
        >
          <div
            // className="flex-boy-row"
            style={{
              width: "100%",
              textAlign: "left",

              paddingRight: "30px",
              boxSizing: "border-box",
            }}
          >
            <Subtitle1 style={{ marginBottom: "10px" }}>This phrase</Subtitle1>
            <TextField
              value={filters?.phrase?.searchTerm || ""}
              onChange={(e) =>
                updateObjectFilterKey("phrase", "searchTerm", e.target.value)
              }
              id="outlined-basic"
              fullWidth
              label=""
              variant="outlined"
              placeholder="Search this phrase"
            />
          </div>
          <div
            // className="flex-boy-row"
            style={{
              width: "100%",
              textAlign: "left",
              padding: "10px",
              paddingRight: "30px",
              boxSizing: "border-box",
            }}
          >
            <Subtitle1 style={{ marginBottom: "10px" }}>
              {" "}
              Near This phrase
            </Subtitle1>
            <TextField
              value={filters?.phrase?.near || ""}
              onChange={(e) =>
                updateObjectFilterKey("phrase", "near", e.target.value)
              }
              id="outlined-basic"
              fullWidth
              label=""
              variant="outlined"
              placeholder="Search near this phrase"
            />
          </div>

          <div
            className="flex-boy-row"
            style={{
              // width: "300px",
              textAlign: "left",
              paddingTop: "20px",
              boxSizing: "border-box",
            }}
          >
            <CreatableSelect
              displayEmpty
              value={
                filters?.phrase?.word_count
                  ? {
                      value: filters?.phrase?.word_count.trim(),
                      label: `${filters?.phrase?.word_count} words`,
                    }
                  : ""
              }
              styles={customStyles2}
              // MenuProps={{ classes: { paper: classes.menuPaper } }}
              onChange={(e) => {
                console.log(e);
                updateObjectFilterKey("phrase", "word_count", e.value);
              }}
              placeholder={"Word Count"}
              options={counts.map((tag, index) => ({
                value: tag.trim(),
                label: `${tag} words`,
              }))}
            />

            <Select
              isMulti
              value={
                filters?.this_phrase_sections?.map((tag) => ({
                  value: section_List[tag],
                  label: section_List[tag],
                })) || ""
              }
              styles={customStyles2}
              onChange={(e) =>
                updateSingleFilterKey(
                  "this_phrase_sections",
                  e.map((item) =>
                    Object.keys(section_List).find(
                      (key) => section_List[key] === item.value
                    )
                  )
                )
              }
              placeholder={"sections"}
              options={Object.values(section_List).map((tag, index) => ({
                value: tag,
                label: tag.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase()
                ),
              }))}
            />
          </div>
        </div> */}
      </div>
      <div
        className="flex-boy-row"
        style={{
          height: "75px",
          width: "100%",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        <TailwindButtonOutline onClick={clearFilters} text="Clear Filters" />
        {reports_loading ? (
          <div
            className="flex-boy-row"
            style={{ marginRight: "40px", marginBottom: "30px" }}
          >
            <Player
              autoplay
              loop
              src={animationData}
              style={{ height: "100px", width: "100px" }}
            >
              {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
            </Player>

            <Subtitle1>Loading</Subtitle1>
          </div>
        ) : (
          <TailwindButton
            onClick={handleClose}
            text={`Show ${reports_length} reports`}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  project_filters: state.authReducer.projects_search_filters,
  companies_search: state.authReducer.companies_search || [],
  projects: state.authReducer.projects_search || [],
  map_projects:
    state.authReducer.map_projects.filter(
      (item) => item.lat !== 0 && item.lng !== 0
    ) || [],
  company_filters: state.authReducer.company_search_filters,
  projects_length: state.authReducer.filtered_projects_length,
  companies_length: state.authReducer.filtered_companies_length,
  report_filters: state.authReducer.report_search_filters,
  reports_length: state.authReducer.filtered_reports_length,
  reports_loading: state.authReducer.reports_loading,
});

const mapDispatchToProps = (dispatch) => ({
  setProjectStartDate: (data) =>
    dispatch({ type: "SET_PROJECT_START_DATE", payload: data }),
  searchAlgoliaCompanies: (data) => dispatch(searchAlgoliaCompanies(data)),
  filterProjects: (data) => dispatch(filterProjects(data)),
  filterReports: (data) => dispatch(filterReports(data)),
  filterCompanies: (data) => dispatch(filterCompanies(data)),
  setCompanyPage: (data) =>
    dispatch({ type: "SET_COMPANIES_PAGE", payload: data }),
  setProjectPage: (data) =>
    dispatch({ type: "SET_PROJECTS_PAGE", payload: data }),
  setReportPage: (data) =>
    dispatch({ type: "SET_REPORTS_PAGE", payload: data }),
  setProjectFilters: (data) =>
    dispatch({ type: "SET_PROJECT_FILTERS", payload: data }),
  setCompanyFilters: (data) =>
    dispatch({ type: "SET_COMPANY_FILTERS", payload: data }),
  setReportFilters: (data) =>
    dispatch({ type: "SET_REPORTS_FILTERS", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterContent);

// old stuff
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
// import React, { useState, useEffect, useRef } from "react";
// import { connect } from "react-redux";
// import {
//   Primary,
//   Headline5,
//   Button,
//   Subtitle1,
//   Subtitle2,
//   BrowseListFilter,
//   NotifyDiv,
//   Headline6,
//   TailwindButton,
//   TailwindButtonOutline,
// } from "../../../../components/general";
// import CloseIcon from "@material-ui/icons/Close";
// import {
//   searchAlgoliaCompanies,
//   filterProjects,
//   filterCompanies,
//   setProjectStartDate,
//   filterReports,
// } from "../../../../store/Actions/AuthActions";
// import {
//   FormControl,
//   TextField,
//   Checkbox,
//   FormControlLabel,
// } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import { Exchanges } from "../../../../configure/constants";
// import Select from "react-select";
// import axios from "axios";
// import _ from "lodash";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import moment from "moment";
// import { apiRequest } from "../../../../store/Actions/AuthActions";
// import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
// import { Player } from "@lottiefiles/react-lottie-player";
// import animationData from "../../../../assets/ProspectorDesignAssets/lottie2.json";
// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
//   menuPaper: {
//     maxHeight: 100,
//   },
// }));
// const continents = [
//   "Asia",
//   "North America",

//   "Latin America",
//   "Africa",
//   "Antarctica",
//   "Europe",
//   "Oceania",
// ];
// const customStyles = {
//   control: (base) => ({
//     ...base,

//     minHeight: 50,
//   }),
// };
// const periods = ["1M", "3M", "1Y", "5Y", "ALL"];
// export const FilterContent = ({
//   reports_loading,
//   reports_length,
//   setProjectStartDate,
//   setReportPage,
//   filterReports,
//   setReportFilters,
//   setCompanyPage,
//   projects_length,
//   map_projects,
//   companies_length,
//   setProjectPage,
//   searchAlgoliaCompanies,
//   auth,
//   tab,
//   handleClose,
//   filterProjects,
//   setProjectFilters,
//   setCompanyFilters,
//   project_filters,
//   company_filters,
//   filterCompanies,
//   report_filters,
// }) => {
//   let me = auth.uid;
//   const [value, setValue] = React.useState([20, 37]);
//   const [extraMinerals, setMinerals] = useState([]);
//   const [extraRegions, setRegions] = useState([]);
//   const [showextraRegions, showRegions] = useState(false);
//   const [showextraMinerals, showMinerals] = useState(false);
//   const [showdateRange, setshowdateRange] = useState(true);
//   const classes = useStyles();
//   useEffect(() => {
//     getFilterInfo();
//     fetchCompanies();
//     updateFilters(
//       report_filters || {
//         minerals: null,
//         start_after: null,
//         searchTerm: null,
//         regions: null,
//         countries: null,
//         stage: null,
//         resourceType: null,
//         averageGrade_min: 0,
//         averageGrade_max: 0,
//         oreTonnage_min: 0,
//         oreTonnage_max: 0,
//         containedMetal_min: 0,
//         containedMetal_max: 0,
//       }
//     );

//     return () => {};
//   }, []);

//   const getFilterInfo = async () => {
//     const countries_data = await axios.get(
//       `${process.env.REACT_APP_ENDPOINT}/config/countries`
//     );
//     const minerals_data = await axios.get(
//       `${process.env.REACT_APP_ENDPOINT}/config/minerals`
//     );

//     if (countries_data) {
//       setRegions([...countries_data.data.data.sort()]);
//     }
//     if (minerals_data) {
//       setMinerals([...new Set(minerals_data.data.data)]);
//     }
//   };
//   const [filters, updateFilters] = useState({
//     mineral: null,
//     start_after: null,
//     searchTerm: null,
//     regions: null,
//     countries: null,
//     resourceType: null,
//     averageGrade_min: 0,
//     averageGrade_max: 0,
//     oreTonnage_min: 0,
//     oreTonnage_max: 0,
//     containedMetal_min: 0,
//     containedMetal_max: 0,
//   });
//   const setFilters = (filters) => {
//     updateFilters(filters);
//   };

//   const search_companies = ({ loc, newFilters }) => {
//     setCompanyPage(0);
//     filterCompanies();
//   };
//   const search_projects = ({ loc, newFilters }) => {
//     setProjectPage(0);
//     filterProjects();
//   };
//   const search_reports = ({ loc, newFilters }) => {
//     setReportPage(0);
//     //filterReports();
//   };
//   function numFormatter(num) {
//     if (num > 999) {
//       return (num / 1000000).toFixed(1) + " Million"; // convert to M for number from > 1 million
//     } else {
//       return num; // if value < 1000, nothing to do
//     }
//   }

//   const updateSlider = (name, val) => {
//     let newFilters = { ...filters };
//     newFilters[`${name}_min`] = val[0] * 1000000;
//     newFilters[`${name}_max`] = val[1] * 1000000;
//     setFilters(newFilters);
//     if (tab === "projects") {
//       setProjectFilters(newFilters);
//       search_projects({ loc: 0, newFilters });
//     } else {
//       setCompanyFilters(newFilters);
//       search_companies({ loc: 0, newFilters });
//     }
//   };
//   const updateAverageGrade = (name, val) => {
//     let newFilters = { ...filters };
//     newFilters[`${name}_min`] = val[0];
//     newFilters[`${name}_max`] = val[1];

//     setFilters(newFilters);
//     if (tab === "projects") {
//       setProjectFilters(newFilters);
//       search_projects({ loc: 0, newFilters });
//     } else {
//       setCompanyFilters(newFilters);
//       search_companies({ loc: 0, newFilters });
//     }
//   };
//   const capitalize = (s) => {
//     if (typeof s !== "string") return "";
//     return s.charAt(0).toUpperCase() + s.slice(1);
//   };

//   const resTypes = [
//     {
//       name: "Indicated Mineral Resource",
//       value: 85,
//       indicator: "indicated",
//     },
//     {
//       name: "Inferred Mineral Resource",
//       value: 84,
//       indicator: "inferred",
//     },
//     {
//       name: "Measured Mineral Resource",
//       value: 86,
//       indicator: "measured",
//     },
//     {
//       name: "Not Mentioned",
//       value: 89,
//       indicator: "not mentioned",
//     },
//     {
//       name: "Probable Mineral Reserve",
//       value: 87,
//       indicator: "probable",
//     },

//     {
//       name: "Proven Mineral Reserve",
//       value: 88,
//       indicator: "proven",
//     },
//   ];
//   const stages = [
//     "Discovery Delineation",
//     "Grassroots",
//     "Production",
//     "Target Drilling",
//     "Prefeasibility",
//     "PEA",
//     "Permitting & Feasibility",
//     "Construction",
//     "Suspended",
//   ];
//   const range_map = {
//     "1W": 7,
//     "1M": 30,
//     "3M": 90,
//     "1y": 400,
//     "5Y": 1780,
//     ALL: 30000,
//   };
//   const sections = [
//     "Summary",
//     "Introduction",
//     "Reliance on Other Experts",
//     "Property Description and Location",
//     "Accessibility, Climate, Local Resources, Infrastructure and Physiography",
//     "History",
//     "Geological Setting and Mineralization",
//     "Deposit Types",
//     "Exploration",
//     "Drilling",
//     "Sample Preparation, Analyses and Security",
//     "Data Verification",
//     "Mineral Processing and Metallurgical Testing",
//     "Mineral Resource Estimates",
//     "Mineral Reserve Estimates",
//     "Mining Methods",
//     "Recovery Methods",
//     "Project Infrastructure",
//     "Market Studies and Contracts",
//     "Environmental Studies, Permitting and Social or Community Impact",
//     "Capital and Operating Costs",
//     "Economic Analysis",
//     "Adjacent Properties",
//     "Other Relevant Data and Information",
//     "Interpretation and Conclusions",
//     "Recommendations",
//   ];

//   const section_List = {
//     section01: "01. Summary",
//     section02: "02. Introduction",
//     section03: "03. Reliance on Other Experts",
//     section04: "04. Property Description and Location",
//     section05:
//       "05. Accessibility, Climate, Local Resources, Infrastructure and Physiography",
//     section06: "06. History",
//     section07: "07. Geological Setting and Mineralization",
//     section08: "08. Deposit Types",
//     section09: "09. Exploration",
//     section10: "10. Drilling",
//     section11: "11. Sample Preparation, Analyses and Security",
//     section12: "12. Data Verification",
//     section13: "13. Mineral Processing and Metallurgical Testing",
//     section14: "14. Mineral Resource Estimates",
//     section15: "15. Mineral Reserve Estimates",
//     section16: "16. Mining Methods",
//     section17: "17. Recovery Methods",
//     section18: "18. Project Infrastructure",
//     section19: "19. Market Studies and Contracts",
//     section20:
//       "20. Environmental Studies, Permitting and Social or Community Impact",
//     section21: "21. Capital and Operating Costs",
//     section22: "22. Economic Analysis",
//     section23: "23. Adjacent Properties",
//     section24: "24. Other Relevant Data and Information",
//     section25: "25. Interpretation and Conclusions",
//     section26: "26. Recommendations",
//   };

//   const filterMinerals = [
//     "cobalt",
//     "copper",
//     "gold",
//     "rare earth elements",
//     "lithium",
//     "silver",
//     "nickel",
//     "palladium",
//   ];
//   const updateSingleFilterKey = (name, value) => {
//     let newFilters = { ...filters };

//     if (JSON.stringify(newFilters[name]) === JSON.stringify(value)) {
//       newFilters[name] = null;
//       setFilters(newFilters);
//       setReportFilters(newFilters);
//       search_reports({ loc: 0, newFilters });
//     } else {
//       newFilters[name] = value;

//       setFilters(newFilters);
//       setReportFilters(newFilters);
//       search_reports({ loc: 0, newFilters });
//     }
//   };
//   const updateSingleFilterKeynonselect = (name, value) => {
//     let newFilters = { ...filters };

//     if (newFilters[name] && newFilters[name].length) {
//       let index = newFilters[name].indexOf(value);
//       if (index !== -1) {
//         newFilters[name].splice(index, 1);
//         setFilters(newFilters);
//         if (tab === "projects") {
//           setProjectFilters(newFilters);
//           search_projects({ loc: 0, newFilters });
//         } else {
//           setCompanyFilters(newFilters);
//           search_companies({ loc: 0, newFilters });
//         }
//       } else {
//         newFilters[name].push(value);
//         setFilters(newFilters);
//         if (tab === "projects") {
//           setProjectFilters(newFilters);
//           search_projects({ loc: 0, newFilters });
//         } else {
//           setCompanyFilters(newFilters);
//           search_companies({ loc: 0, newFilters });
//         }
//       }
//     } else {
//       newFilters[name] = [value];
//       setFilters(newFilters);
//       if (tab === "projects") {
//         setProjectFilters(newFilters);
//         search_projects({ loc: 0, newFilters });
//       } else {
//         setCompanyFilters(newFilters);
//         search_companies({ loc: 0, newFilters });
//       }
//     }
//   };
//   const clearFilters = () => {
//     date.current.element.value = "";
//     let newFilters = { ...filters };

//     Object.keys(newFilters).forEach((i) => (newFilters[i] = null));

//     setReportFilters(newFilters);

//     updateFilters(newFilters);
//     search_reports({ loc: 0, newFilters });
//     setshowdateRange(true);
//     // handleClose()
//   };
//   const date = useRef(null);
//   const switchPeriod = (period) => {
//     let range = period.includes("1W")
//       ? 7
//       : period === "ALL"
//       ? 30000
//       : period === "1D"
//       ? 1
//       : period === "1M"
//       ? 30
//       : period === "3M"
//       ? 90
//       : period === "6M"
//       ? 180
//       : period === "2Y"
//       ? 730
//       : period === "3Y"
//       ? 1095
//       : period === "5Y"
//       ? 1780
//       : period === "1Y"
//       ? 400
//       : 90;
//     updateSingleFilterKey("days", range);
//   };
//   const change_text = (e) => {};
//   const projectChange = (e, value) => {};
//   const [companies, setCompanies] = useState([]);

//   const fetchCompanies = async () => {
//     let { data } = await apiRequest.get(`/companies?shallow=true`);

//     if (data) {
//       setCompanies(data.data);
//     }
//   };
//   const onChange = (props) => {
//     console.log(date.current);

//     const stateDate = moment(props.startDate).format("DD/MM/YYYY");
//     const endDate = moment(props.endDate).format("DD/MM/YYYY");

//     updateSingleFilterKey("date_range", [stateDate, endDate]);
//   };

//   return (
//     <div
//       className="flex-boy-column h-[calc(100vh_-_80px)] md:h-[calc(100vh_-_160px)]"
//       style={{ width: "100%" }}
//     >
//       <div
//         className="flex-boy-row"
//         style={{
//           width: "100%",
//           justifyContent: "normal",
//           padding: "20px",
//           boxSizing: "border-box",
//           textAlign: "left",
//         }}
//       >
//         <Headline5 style={{ flex: 1 }}>Advanced Report search</Headline5>
//       </div>

//       <div
//         className="flex-boy-column"
//         style={{ flex: 1, justifyContent: "normal", width: "100%" }}
//       >
//         <div
//           style={{
//             width: "100%",
//             textAlign: "left",
//             padding: "20px",
//             paddingRight: "30px",
//             boxSizing: "border-box",
//           }}
//         >
//           <TextField
//             value={filters?.searchTerm || ""}
//             onChange={(e) =>
//               updateSingleFilterKey("searchTerm", e.target.value)
//             }
//             id="outlined-basic"
//             fullWidth
//             label=""
//             variant="outlined"
//             placeholder="Search by words in title"
//           />
//         </div>
//         <div
//           style={{
//             width: "100%",
//             textAlign: "left",
//             padding: "20px",
//             paddingRight: "30px",
//             boxSizing: "border-box",
//           }}
//         >
//           <TextField
//             value={filters?.search_text || ""}
//             onChange={(e) =>
//               updateSingleFilterKey("search_text", e.target.value)
//             }
//             id="outlined-basic"
//             fullWidth
//             label=""
//             variant="outlined"
//             placeholder="Open Text Search of Reports"
//           />
//         </div>
//         <div
//           style={{
//             width: "100%",
//             textAlign: "left",
//             padding: "20px",
//             paddingRight: "30px",
//             boxSizing: "border-box",
//           }}
//         >
//           <TextField
//             value={filters?.author || ""}
//             onChange={(e) => updateSingleFilterKey("author", e.target.value)}
//             id="outlined-basic"
//             fullWidth
//             label=""
//             variant="outlined"
//             placeholder="Search by Qualified Author"
//           />
//         </div>
//         <div
//           style={{
//             width: "100%",
//             boxSizing: "border-box",
//             padding: "20px",
//             paddingRight: "30px",
//             minHeight: "80px",
//           }}
//         >
//           <Select
//             displayEmpty
//             isMulti
//             value={
//               filters?.filing_company?.map((tag) => ({
//                 value: tag,
//                 label: tag,
//               })) || ""
//             }
//             styles={customStyles}
//             // MenuProps={{ classes: { paper: classes.menuPaper } }}
//             onChange={(e) =>
//               updateSingleFilterKey(
//                 "filing_company",
//                 e.map((item) => item.value)
//               )
//             }
//             placeholder={"Filing Company"}
//             options={companies.map((tag, index) => ({
//               value: tag.name.trim(),
//               label: tag.name,
//             }))}
//           />
//         </div>

//         <div
//           style={{
//             width: "100%",
//             textAlign: "left",
//             padding: "20px",
//             boxSizing: "border-box",
//           }}
//         >
//           <div className="flex-boy-row">
//             <div
//               style={{
//                 flex: 1,
//                 boxSizing: "border-box",
//                 padding: "10px",
//                 paddingLeft: "0",
//                 minHeight: "80px",
//               }}
//             >
//               <Select
//                 isMulti
//                 displayEmpty
//                 value={
//                   filters?.minerals?.map((tag) => ({
//                     value: tag,
//                     label: tag,
//                   })) || ""
//                 }
//                 styles={customStyles}
//                 // MenuProps={{ classes: { paper: classes.menuPaper } }}
//                 onChange={(e) =>
//                   updateSingleFilterKey(
//                     "minerals",
//                     e.map((item) => item.value)
//                   )
//                 }
//                 placeholder={"All minerals"}
//                 options={extraMinerals.map((tag, index) => ({
//                   value: tag.trim(),
//                   label: tag,
//                 }))}
//               />
//             </div>
//             <div
//               style={{
//                 flex: 1,
//                 boxSizing: "border-box",
//                 padding: "10px",
//                 minHeight: "80px",
//               }}
//             >
//               <Select
//                 isMulti
//                 displayEmpty
//                 value={
//                   filters?.mineral_resource_estimate?.map((tag) => ({
//                     value: tag,
//                     label: tag,
//                   })) || ""
//                 }
//                 styles={customStyles}
//                 // MenuProps={{ classes: { paper: classes.menuPaper } }}
//                 onChange={(e) =>
//                   updateSingleFilterKey(
//                     "mineral_resource_estimate",
//                     e.map((item) => item.value)
//                   )
//                 }
//                 placeholder={"All Mineral Estimate Levels"}
//                 options={resTypes.map((tag, index) => ({
//                   value: tag.name.trim(),
//                   label: tag.name,
//                 }))}
//               />
//             </div>
//             <div
//               style={{
//                 flex: 1,
//                 boxSizing: "border-box",
//                 padding: "10px",
//                 paddingLeft: "0",
//                 minHeight: "80px",
//               }}
//             >
//               <Select
//                 isMulti
//                 value={
//                   filters?.country
//                     ?.filter((tag) => tag !== "South america")
//                     .map((tag) => ({ value: tag, label: tag })) || ""
//                 }
//                 styles={customStyles}
//                 onChange={(e) =>
//                   updateSingleFilterKey(
//                     "country",
//                     e.map((item) => item.value)
//                   )
//                 }
//                 placeholder={"All regions & countries"}
//                 options={extraRegions.map((tag, index) => ({
//                   value: tag,
//                   label: tag.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
//                     letter.toUpperCase()
//                   ),
//                 }))}
//               />
//             </div>
//           </div>
//         </div>

//         <div
//           style={{
//             width: "100%",
//             textAlign: "left",
//             padding: "20px",
//             boxSizing: "border-box",
//           }}
//         >
//           <div className="flex-boy-row">
//             <div
//               style={{
//                 flex: 1,
//                 boxSizing: "border-box",
//                 padding: "10px",
//                 paddingLeft: "0",
//                 minHeight: "80px",
//               }}
//             >
//               <Select
//                 isMulti
//                 value={
//                   filters?.sections?.map((tag) => ({
//                     value: section_List[tag],
//                     label: section_List[tag],
//                   })) || ""
//                 }
//                 styles={customStyles}
//                 onChange={(e) =>
//                   updateSingleFilterKey(
//                     "sections",
//                     e.map((item) =>
//                       Object.keys(section_List).find(
//                         (key) => section_List[key] === item.value
//                       )
//                     )
//                   )
//                 }
//                 placeholder={"Report sections"}
//                 options={Object.values(section_List).map((tag, index) => ({
//                   value: tag,
//                   label: tag.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
//                     letter.toUpperCase()
//                   ),
//                 }))}
//               />
//             </div>
//             <div
//               style={{
//                 flex: 1,
//                 boxSizing: "border-box",
//                 padding: "10px",
//                 paddingLeft: "0",
//                 minHeight: "80px",
//               }}
//             >
//               <Select
//                 displayEmpty
//                 value={
//                   filters?.days
//                     ? {
//                         value: Object.keys(range_map).find(
//                           (key) => range_map[key] === filters.days
//                         ),
//                         label: Object.keys(range_map).find(
//                           (key) => range_map[key] === filters.days
//                         ),
//                       }
//                     : ""
//                 }
//                 styles={customStyles}
//                 // MenuProps={{ classes: { paper: classes.menuPaper } }}
//                 onChange={(e) => switchPeriod(e.value)}
//                 placeholder={"Last Updated"}
//                 options={periods.map((tag, index) => ({
//                   value: tag.trim(),
//                   label: tag,
//                 }))}
//               />
//             </div>

//             <div
//               style={{
//                 flex: 1,
//                 boxSizing: "border-box",
//                 padding: "10px",
//                 minHeight: "80px",
//               }}
//             >
//               <div className="control-pane">
//                 <div className="control-section">
//                   <div className="daterangepicker-control-section">
//                     {showdateRange && (
//                       <DateRangePickerComponent
//                         delayUpdate={true}
//                         ref={date}
//                         id="daterangepicker"
//                         value={filters.dateRange}
//                         // startDate={filters.dateRange ? filters.dateRange[0] : ""}
//                         // endDate={filters.dateRange ? filters.dateRange[1] : ""}
//                         change={onChange}
//                         placeholder=" Daterange"
//                       ></DateRangePickerComponent>
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div
//           style={{
//             width: "100%",
//             textAlign: "left",
//             padding: "20px",
//             boxSizing: "border-box",
//           }}
//         ></div>
//         <div
//           className="rangeFilterContainer"
//           style={{ textAlign: "left", marginBottom: "20px" }}
//         ></div>
//       </div>

//       <div
//         className="flex-boy-row"
//         style={{
//           height: "75px",
//           width: "100%",
//           padding: "20px",
//           boxSizing: "border-box",
//         }}
//       >
//         <TailwindButtonOutline onClick={clearFilters} text="Clear Filters" />
//         {reports_loading ? (
//           <div
//             className="flex-boy-row"
//             style={{ marginRight: "40px", marginBottom: "30px" }}
//           >
//             <Player
//               autoplay
//               loop
//               src={animationData}
//               style={{ height: "100px", width: "100px" }}
//             >
//               {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
//             </Player>

//             <Subtitle1>Loading</Subtitle1>
//           </div>
//         ) : (
//           <TailwindButton
//             onClick={handleClose}
//             text={`Show ${reports_length} reports`}
//           />
//         )}
//       </div>
//     </div>
//   );
// };

// const mapStateToProps = (state) => ({
//   auth: state.firebase.auth,
//   project_filters: state.authReducer.projects_search_filters,
//   companies_search: state.authReducer.companies_search || [],
//   projects: state.authReducer.projects_search || [],
//   map_projects:
//     state.authReducer.map_projects.filter(
//       (item) => item.lat !== 0 && item.lng !== 0
//     ) || [],
//   company_filters: state.authReducer.company_search_filters,
//   projects_length: state.authReducer.filtered_projects_length,
//   companies_length: state.authReducer.filtered_companies_length,
//   report_filters: state.authReducer.report_search_filters,
//   reports_length: state.authReducer.filtered_reports_length,
//   reports_loading: state.authReducer.reports_loading,
// });

// const mapDispatchToProps = (dispatch) => ({
//   setProjectStartDate: (data) =>
//     dispatch({ type: "SET_PROJECT_START_DATE", payload: data }),
//   searchAlgoliaCompanies: (data) => dispatch(searchAlgoliaCompanies(data)),
//   filterProjects: (data) => dispatch(filterProjects(data)),
//   filterReports: (data) => dispatch(filterReports(data)),
//   filterCompanies: (data) => dispatch(filterCompanies(data)),
//   setCompanyPage: (data) =>
//     dispatch({ type: "SET_COMPANIES_PAGE", payload: data }),
//   setProjectPage: (data) =>
//     dispatch({ type: "SET_PROJECTS_PAGE", payload: data }),
//   setReportPage: (data) =>
//     dispatch({ type: "SET_REPORTS_PAGE", payload: data }),
//   setProjectFilters: (data) =>
//     dispatch({ type: "SET_PROJECT_FILTERS", payload: data }),
//   setCompanyFilters: (data) =>
//     dispatch({ type: "SET_COMPANY_FILTERS", payload: data }),
//   setReportFilters: (data) =>
//     dispatch({ type: "SET_REPORTS_FILTERS", payload: data }),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(FilterContent);
