import React, { useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import PowerCharts from "fusioncharts/fusioncharts.powercharts";
import ReactFusioncharts from "react-fusioncharts-fix";
import { apiRequest } from "../../../../../store/Actions/AuthActions";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../../../assets/ProspectorDesignAssets/lottie2.json";
import ContentLoader from "react-content-loader";
ReactFusioncharts.fcRoot(FusionCharts, charts, PowerCharts);
charts(FusionCharts);

const Sankey = ({
  company,
  year,
  mineral,
  production_basis,
  metric_name,
  quarter,
}) => {
  const [dataSource, setdataSource] = useState(null);
  useEffect(() => {
    setdataSource(null);
    fetchData();
    return () => {};
  }, []);

  const fetchData = async () => {
    const data = await apiRequest
      .get(
        `companies/${company.id}/projects-quarterly-production?production_basis=${production_basis}&mineral=${mineral}&report_year=${year}&metric_name=${metric_name}&quarter=${quarter}`
      )
      .catch((err) => console.log(err));
    if (data?.data?.data) {
      setdataSource({
        chart: {
          theme: "fusion",
          orientation: "horizontal",
          linkalpha: 30,
          linkhoveralpha: 60,
          nodelabelposition: "start",
          canvasborderColor: "#B9E1F4",
          canvasborderThicknes: "0.5",
          showBorder: "0",
          bgcolor: "#FFFFFF",
          canvasbgAlpha: "0",
          canvasbgcolor: "0",
          paletteColors: "#142735,#B8BEC2,#9C8D64",
          rotateNodeLabels: "1",
          nodeLabelPosition: "inside",
          inheritLabelColorFromNode: "0",
          nodeLabelFontColor: "#FFFFFF",
          showlegend: "0",
        },

        nodes: data.data.data.nodes.filter((item) =>
          data.data.data.links.find(
            (obj) => obj.from === item.label || obj.to === item.label
          )
        ),
        links: data.data.data.links,
      });
    }
  };
  return (
    <div style={{ width: "100%", height: "30em", marginTop: "50px" }}>
      <ReactFusioncharts
        type="sankey"
        width="100%"
        height="550"
        dataFormat="JSON"
        dataSource={dataSource}
      />
    </div>
  );
};

export default Sankey;
