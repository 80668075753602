import { ReportOff } from "@material-ui/icons";
import React from "react";
import { connect } from "react-redux";
import CompaniesSearch from "./CompaniesSearch";
import FillingsSearch from "./FillingsSearch.js";
import ProjectsSearch from "./ProjectsSearch";
import ReportsSearch from "./ReportsSearch";

export const AdvancedSearch = ({ tab, setsearchMode }) => {
  const handleClose = () => {
    setsearchMode(false);
  };
  return (
    <>
      {tab === "projects" ? (
        <ProjectsSearch tab={tab} handleClose={handleClose} />
      ) : tab === "companies" ? (
        <CompaniesSearch tab={tab} handleClose={handleClose} />
      ) : tab === "technical reports" ? (
        <ReportsSearch tab={tab} handleClose={handleClose} />
      ) : (
        <FillingsSearch tab={tab} handleClose={handleClose} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSearch);
