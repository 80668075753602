/* This example requires Tailwind CSS v2.0+ */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Dropdown = ({ report, show_page, handleSearchChange, value }) => {
  const frequencies = ["Daily", "Weekly", "Bi-Weekly", "Monthly"];
  const [selectedFrequency, setSelectedFrequency] = useState(value);
  const onchange = (value) => {
    setSelectedFrequency(value);
    handleSearchChange(value);
  };
  return (
    // <Listbox value={selectedFrequency} onChange={setSelectedFrequency}>
    //   <Listbox.Label>Subscription Frequency:</Listbox.Label>
    //   <Listbox.Button>{selectedFrequency}</Listbox.Button>
    //   <Listbox.Options>
    //     {frequencies.map((person) => (
    //       <Listbox.Option key={person} value={person}>
    //         {person}
    //       </Listbox.Option>
    //     ))}
    //   </Listbox.Options>
    // </Listbox>
    <div className="text-left w-[100%] z-10">
      <Listbox
        className="z-10 w-full"
        value={selectedFrequency}
        onChange={onchange}
      >
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-emerald-500 py-2 pl-3 pr-10 text-left text-white shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-emerald-300 sm:text-sm">
            <span className="block truncate">
              {selectedFrequency
                ? selectedFrequency
                : "Subscription Frequency:"}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon className="h-5 w-5 text-white" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="z-10 max-w-[300px] absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {frequencies.map((person, personIdx) => (
                <Listbox.Option
                  key={person}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active
                        ? "bg-emerald-100 text-emerald-900"
                        : "text-gray-900"
                    }`
                  }
                  value={person}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {person}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-emerald-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
