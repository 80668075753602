import React from "react";
import { connect } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "./CheckoutForm";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_live_51HNK6nJY2VsK2dNdtQ1IAI2H3NLTZB6vyc4eP7JPJ92Zhfhe9pR2X2JustXf0elrxU0HoxNst5YmdO6Y2Ch8Pc9I00iXLB4yfV"
);

export const AddPaymentMethod = (props) => {
  return <CheckoutForm />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentMethod);
