/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import {
  Headline4,
  Headline5,
  ButtonCircle,
  Navigation,
  Logo,
  Search,
  NavList,
  Primary,
  StyledLink,
  NavItem,
  Subtitle1,
  Subtitle2,
  Headline6,
} from "./components";
import Authentication from "./content/Authentication";
import { browserHistory } from "react-router";
import Popover from "@material-ui/core/Popover";
import logoImg from "./assets/ProspectorDesignAssets/Prospector_3D Logo_Icon1.png";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { TextField, Modal, Dialog } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  Edit,
  SettingsOutlined,
  AssignmentIndOutlined,
  PublicOutlined,
  HelpOutlineOutlined,
  ExitToAppOutlined,
  Cancel,
} from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import { ToastContainer } from "react-toastify";
import { firestoreConnect, getFirebase } from "react-redux-firebase";
import PrivateRoute from "./components/PrivateRoute";
import Account from "./content/Account";
import Nugget from "./content/Nugget";
import Matches from "./content/Matches";
import Browse from "./content/BrowseSection";
import Avatar from "react-avatar";
import CompanyDetail from "./content/CompanyPage";
import ProjectDetail from "./content/ProjectPage";
import Connections from "./content/Connections";
import { withRouter } from "react-router";
import { useFirestoreConnect } from "react-redux-firebase";
import {
  searchCompanies,
  searchProjects,
  filterProjects,
  filterCompanies,
  getProjectDetail,
  logOut,
  manageSub,
  filterCompaniesStaging,
  filterProjectsStaging,
  getLists,
  checkUser,
  getUserFollowsList,
  resolveAuthSession,
  getBillingInfo,
  getTags,
  getAllMinerals,
  filterReports,
} from "./store/Actions/AuthActions";

import SearchComponent from "./components/searchComponent";
import IframeSearchComponent from "./components/IframeSearchComponent";
import Subscriptioncontent from "./components/Subscriptioncontent";
import NonLoggedNav from "./components/NonloggedNav";
import UserPrefernces from "./components/UserData/UserPrefernces";
import UserData from "./components/UserData/userData";
import ProfileImage from "./components/UserData/ProfileImage";
import animationData from "../src/assets/ProspectorDesignAssets/lottie2.json";
import ListPage from "./components/List/ListPage";
import BrowsemapIframe from "./content/BrowsemapIframe";
import ReportDetail from "./content/ReportPage";
import FilingDetail from "./content/FillingPage";
import NpvCalculator from "./content/NpvCalculator";
import Onboarding from "./content/Onboarding";
import Mapbox from "./components/Mapbox";
import HubspotUpgradeForm from "./content/Upgrage";
import Retool from "./content/Retool";
import Subscription from "./content/Subscription";
import MapboxDrawing from "./components/Mapbox/MapboxDrawing";
import ChatPage from "./content/BrowseSection/Components/ChatPage";

const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#00AC69",
      contrastText: "#fff",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#1A9BDB",
      contrastText: "#fff",
    },
  },
});
const iFrameDetection = window === window.parent ? false : true;
export const Main = ({
  authenticated,
  checkUser,
  getLists,
  getTags,
  filterCompaniesStaging,
  loading,
  manageSub,
  logout,
  activeUser,
  getUserFollowsList,
  setSupscriptionPopup,
  subscription_popup,
  filterCompanies,
  getProjectDetail,
  customers,
  auth,
  history,
  selectedList,
  searchCompanies,
  searchProjects,
  filterProjects,
  filterProjectsStaging,
  getAllMinerals,
  getBillingInfo,
}) => {
  const location = useLocation();
  let me = auth.uid;
  const [accDrop, setAccDrop] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  useFirestoreConnect([{ collection: "customers" }]);
  const onFocus = () => {
    checkUser();
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {};
  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  });
  //there's a bug right here with fetching
  useEffect(() => {
    getAllMinerals();

    return () => {};
  }, []);

  useEffect(() => {
    console.log("activeUser has changes", activeUser);
    // getAllMinerals();
    // filterCompanies();

    resolveAuthSession();
    if (activeUser) {
      // getUserFollowsList()
      getBillingInfo();
      getLists();
      getTags();
    }

    return () => {};
  }, [activeUser]);

  const handlepopClose = () => {
    setAnchorEl(null);
    handleClosePop();
  };
  const handleClosePop = () => {
    setAnchorEl(null);
  };
  const handleClose = () => {
    setSupscriptionPopup(false);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [preferences, setRequest] = useState(false);
  const [userData, setUserDataRequest] = useState(false);
  const [userProfile, setUserProfileRequest] = useState(false);
  const [userOnboarding, setUserOnboarding] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [event_log, setEvents] = useState(null);
  const [preview, setPreview] = useState();
  const handleCloseUser = () => {
    setRequest(false);
  };
  const handleCloseUserData = () => {
    setUserDataRequest(false);
  };
  const handleCloseUserProfile = () => {
    setUserProfileRequest(false);
  };
  const handleCloseUserOnboarding = () => {
    setUserOnboarding(false);
  };
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };
  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        onClose={handleCloseUser}
        aria-labelledby="simple-dialog-title"
        open={preferences}
      >
        <UserPrefernces handleCloseUser={handleCloseUser} />
      </Dialog>
      <Dialog
        onClose={handleCloseUserData}
        aria-labelledby="simple-dialog-title"
        open={userData}
      >
        <UserData closeUserDataRequest={handleCloseUserData} />
      </Dialog>
      <Dialog
        onClose={handleCloseUserProfile}
        aria-labelledby="simple-dialog-title"
        open={userProfile}
      >
        <ProfileImage closeUserProfile={handleCloseUserProfile} />
      </Dialog>

      <Dialog
        onClose={handleCloseUserProfile}
        aria-labelledby="simple-dialog-title"
        fullWidth
        maxWidth="md"
        open={userOnboarding}
      >
        <Onboarding closeUserOnboarding={handleCloseUserOnboarding} />
      </Dialog>
      <div>
        <Modal
          open={subscription_popup}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Subscriptioncontent />
        </Modal>
        {!iFrameDetection &&
        !location.pathname.includes("/auth/") &&
        !location.pathname.includes("/search") &&
        !activeUser ? (
          <NonLoggedNav />
        ) : null}
        {!iFrameDetection &&
        !location.pathname.includes("/auth/") &&
        !location.pathname.includes("/search") &&
        activeUser ? (
          <Navigation className="main-header">
            <Logo>
              <img
                style={{ width: "100%" }}
                src={logoImg}
                onClick={(e) => history.push("/browse")}
                alt="logo"
              />
            </Logo>
            <Search>
              <SearchComponent history={history} />
            </Search>

            <NavList>
              <NavItem>
                {/* <StyledLink
                  to="/matches"
                  activeStyle={{
                    fontWeight: "bold",
                    color: Primary,
                  }}
                >
                  Matches
                </StyledLink> */}
              </NavItem>
              <NavItem>
                <StyledLink
                  to="/browse"
                  activeStyle={{
                    fontWeight: "bold",
                    color: Primary,
                  }}
                >
                  Browse
                </StyledLink>
              </NavItem>
              {/* <NavItem>
                            <StyledLink
                                to="/nugget"
                                activeStyle={{
                                    fontWeight: "bold",
                                    color: Primary
                                }}
                            >
                                Insights
                            </StyledLink>
                        </NavItem> */}
              <NavItem>
                <StyledLink
                  to="/connections"
                  activeStyle={{
                    fontWeight: "bold",
                    color: Primary,
                  }}
                >
                  My Prospector
                </StyledLink>
              </NavItem>
              <NavItem>
                <span
                  style={{
                    color: "#00000099",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() => setAccDrop(!accDrop)}
                >
                  Account
                </span>
              </NavItem>
            </NavList>
          </Navigation>
        ) : null}
        {accDrop && activeUser ? (
          <div className="account-body">
            <Cancel
              fontSize="small"
              style={{
                color: "#00000061",
                position: "absolute",
                right: "5%",
                cursor: "pointer",
                zIndex: 99,
              }}
              onClick={() => setAccDrop(!accDrop)}
            />

            <span className="inline-block relative">
              {activeUser?.profile_url ? (
                <img
                  className="rounded-full h-[100px]"
                  src={activeUser?.profile_url}
                  alt="profile"
                />
              ) : preview ? (
                <img
                  className="rounded-full h-[100px]"
                  src={preview}
                  alt="profile"
                />
              ) : (
                <Avatar
                  name={activeUser?.name || activeUser?.email}
                  round
                  size={100}
                />
              )}

              <div
                onClick={() => {
                  setUserProfileRequest(true);
                }}
                className="absolute bottom-0  p-2 right-0 block h-fit w-fit rounded-full ring-2 ring-white bg-emerald-600"
              >
                <Edit style={{ color: "white", fontSize: 25 }} />
              </div>
            </span>

            <h4 className="mb-[20px] text-xl font-bold text-black-600">
              {activeUser?.name || activeUser?.email}
            </h4>

            <div
              className="flex-boy-column"
              style={{
                alignItems: "flex-start",
                boxSizing: "border-box",
                padding: "10px",
                color: "#00000099",
              }}
            >
              <div
                className="flex-boy-row"
                style={{
                  marginLeft: "10px",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
              >
                <SettingsOutlined />
                <div
                  onClick={() => {
                    setRequest(true);
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  <Headline6 color="#00000099" style={{ textAlign: "left" }}>
                    Manage Preferences
                  </Headline6>
                  <Subtitle2 color="#00000099" style={{ textAlign: "left" }}>
                    Update your list
                  </Subtitle2>
                </div>
              </div>
              <div
                className="flex-boy-row"
                style={{
                  marginLeft: "10px",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
              >
                <AssignmentIndOutlined />
                <div
                  onClick={() => {
                    setUserDataRequest(true);
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  <Headline6 color="#00000099" style={{ textAlign: "left" }}>
                    Update Profile
                  </Headline6>
                  <Subtitle2 color="#00000099" style={{ textAlign: "left" }}>
                    Change your personal details
                  </Subtitle2>
                </div>
              </div>
              <div
                onClick={() => manageSub()}
                className="flex-boy-row"
                style={{
                  marginLeft: "10px",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
              >
                <PublicOutlined />
                <div style={{ marginLeft: "10px" }}>
                  <Headline6 color="#00000099" style={{ textAlign: "left" }}>
                    Manage Subscription
                  </Headline6>
                  <Subtitle2 color="#00000099" style={{ textAlign: "left" }}>
                    Prospector
                  </Subtitle2>
                </div>
              </div>
              <div
                onClick={() =>
                  window.open(
                    "https://prospector.atlassian.net/servicedesk/customer/portal/1",
                    "_blank"
                  )
                }
                className="flex-boy-row"
                style={{
                  marginLeft: "10px",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
              >
                <HelpOutlineOutlined />
                <div style={{ marginLeft: "10px" }}>
                  <Headline6 color="#00000099" style={{ textAlign: "left" }}>
                    Get Help
                  </Headline6>
                  <Subtitle2 color="#00000099" style={{ textAlign: "left" }}>
                    Contact our service desk
                  </Subtitle2>
                </div>
              </div>
              <div
                className="flex-boy-row"
                style={{
                  marginLeft: "10px",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
              >
                <ExitToAppOutlined />
                <div
                  onClick={() => {
                    logout({ history });
                    setAccDrop(false);
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  <Headline6 color="#00000099" style={{ textAlign: "left" }}>
                    Logout
                  </Headline6>
                  <Subtitle2 color="#00000099" style={{ textAlign: "left" }}>
                    Sign out of this account
                  </Subtitle2>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {loading ? (
          <div
            className="flex-boy-column "
            style={{
              height: "100vh",
              width: "100vw",
              justifyContent: "center",
              overflow: "hidden",
              zIndex: 2000,
              position: "fixed",
              backgroundColor: "white",
            }}
          >
            <Headline5 style={{ marginTop: "100px" }}>
              Please hold on while we mine the data
            </Headline5>
            {/* <img style={{ height: '30vh' }} src={logoImg} alt="logo" /> */}
            {/* <lottie-player src={"https://lottiefiles.com/share/xvzv1hhl"} background="transparent" speed="1" style="width: 300px; height: 300px;" loop autoplay></lottie-player> */}
            <Player
              autoplay
              loop
              src={animationData}
              style={{ height: "300px", width: "300px" }}
            >
              {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
            </Player>
          </div>
        ) : null}

        <Switch>
          <Route path="/auth" component={Authentication} />
          <Route path="/search" component={IframeSearchComponent} />
          <PrivateRoute exact path="/" component={Browse} />
          <PrivateRoute exact path="/matches" component={Matches} />
          <PrivateRoute path="/browse" component={Browse} />
          <PrivateRoute path="/map" component={BrowsemapIframe} />
          <PrivateRoute path="/retool" component={Retool} />

          <PrivateRoute path="/connections" component={Connections} />

          <PrivateRoute path="/account" component={Subscription} />
          <PrivateRoute
            exact
            path="/company-detail/:id"
            component={CompanyDetail}
          />
          <PrivateRoute path="/project-detail/:id" component={ProjectDetail} />
          <PrivateRoute path="/report-detail/:id" component={ReportDetail} />
          <PrivateRoute path="/filing-detail/:id" component={FilingDetail} />
          <PrivateRoute path="/upgrade" component={HubspotUpgradeForm} />
          <PrivateRoute path="/npv" component={NpvCalculator} />
          <PrivateRoute path="/map-draw" component={MapboxDrawing} />
          <Route path="/nugget" component={ChatPage} />
        </Switch>
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  loading: state.authReducer.loading,
  activeUser: state.authReducer.activeUser,
  companies: state.firestore.ordered.customers,
  selectedList: state.authReducer.selectedList,
  subscription_popup: state.authReducer.subscription_popup,
});

const mapDispatchToProps = (dispatch) => ({
  searchCompanies: (data) => dispatch(searchCompanies(data)),
  searchProjects: (data) => dispatch(searchProjects(data)),
  filterProjects: (data) => dispatch(filterProjects(data)),
  filterCompanies: (data) => dispatch(filterCompanies(data)),
  getAllMinerals: (data) => dispatch(getAllMinerals()),
  filterCompaniesStaging: (data) => dispatch(filterCompaniesStaging(data)),
  filterProjectsStaging: (data) => dispatch(filterProjectsStaging()),
  logout: (data) => dispatch(logOut(data)),
  setSupscriptionPopup: (data) =>
    dispatch({ type: "SET_SUBSCRIPTION_POPUP", payload: data }),
  manageSub: () => dispatch(manageSub()),
  getLists: () => dispatch(getLists()),
  getTags: () => dispatch(getTags()),
  checkUser: () => dispatch(checkUser()),
  getBillingInfo: () => dispatch(getBillingInfo()),
  getUserFollowsList: () => dispatch(getUserFollowsList()),
  resolveAuthSession: () => dispatch(resolveAuthSession()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: "customers" }, { collection: "lists" }])
)(withRouter(Main));
