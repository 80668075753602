import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { Headline2, Headline6, Subtitle1, Button, Primary, Body2 } from "..";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
// import Select from '@material-ui/core/Select';
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import CreatableSelect from "react-select/creatable";
import { apiRequest, getTags } from "../../store/Actions/AuthActions";
import { toast } from "react-toastify";
const options = [];

const useStyles = makeStyles((theme) => ({
  formControl: {},
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export const Notes = ({ type, entity, handleClose, fetchNotes, history }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [personName, setPersonName] = React.useState([]);
  const [names, setnames] = React.useState([]);
  const [selectedTags, setselectedTags] = useState(
    entity?.tags?.map((tag) => ({ value: tag, label: tag }))
  );
  const [tagsError, settagsError] = useState(false);
  const handleChange = (event) => {
    setselectedTags(event);
  };
  useEffect(() => {
    getmyTags();
    return () => {};
  }, [entity, history]);
  const getmyTags = async (id) => {
    const tags = await apiRequest
      .get(`${process.env.REACT_APP_ENDPOINT}/me/tags`)
      .catch((err) => console.log(err));

    if (tags && tags.data) {
      setnames(
        tags.data.data.map((item) => ({
          ...item,
          value: item?.name,
          label: item?.name,
        }))
      );
    }
  };
  const handleTagChange = (event) => {};
  const gridInstance = useRef(null);
  const onRefChange = useCallback((node) => {
    if (node === null) {
      // DOM node referenced by ref has been unmounted
    } else {
      gridInstance.current = node;

      // DOM node referenced by ref has changed and exists
    }
  }, []);
  const updatevalue = async () => {
    if (selectedTags.length) {
      settagsError(false);
      let payload = {
        body: gridInstance.current.value,
        tags: selectedTags.map((item) => item.value),
        resource_type: type,
        resource_id: entity.object?.id || entity.id,
      };

      const toastId = toast("Updating  Note", {
        type: "info",
        autoClose: false,
      });
      const data = await apiRequest
        .post(`me/notes`, payload)
        .catch((error) => {
          toast.update(toastId, {
            theme: "light",
            render: error.response.data.message,
            type: toast.TYPE.ERROR,
            autoClose: 5000,
          });
        });
      if (data && data.data) {
        toast.update(toastId, {
          theme: "light",
          render: "Note updated",
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
        });
        handleClose();
        dispatch(getTags());
        if (fetchNotes) {
          fetchNotes();
        }
      }
    } else {
      settagsError(true);
    }
  };
  const getvalue = async () => {
    if (selectedTags.length) {
      settagsError(false);
      let payload = {
        body: gridInstance.current.value,
        tags: selectedTags.map((item) => item.value),
        resource_type: type,
        resource_id:
          type === "report" ? entity.attributes.report_id : entity.id,
      };

      const toastId = toast("Creating Note", {
        type: "info",
        autoClose: false,
      });
      const data = await apiRequest.post("me/notes", payload).catch((error) => {
        toast.update(toastId, {
          theme: "light",
          render: error.response.data.message,
          type: toast.TYPE.ERROR,

          autoClose: 5000,
        });
      });
      if (data && data.data) {
        toast.update(toastId, {
          theme: "light",
          render: "Note created",
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
        });
        dispatch(getTags());
        handleClose();
      }
    } else {
      settagsError(true);
    }
  };

  return (
    <div className="notes-dialog">
      <div
        className="flex-boy-row"
        style={{ justifyContent: "normal", marginBottom: "30px" }}
      >
        <Headline6>Notes: </Headline6>
        <Subtitle1> &nbsp;{entity?.projectName || entity?.name}</Subtitle1>
      </div>
      <div className="body" style={{ marginBottom: "40px" }}>
        <RichTextEditorComponent
          insertImageSettings={{ saveFormat: "Base64" }}
          ref={(grid) => onRefChange(grid)}
        >
          <div dangerouslySetInnerHTML={{ __html: entity.body }} />
          <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
        </RichTextEditorComponent>

        <FormControl
          className={classes.formControl}
          style={{ width: "100%", marginTop: "30px", minHeight: "300px" }}
        >
          <Subtitle1 style={{ marginTop: "10px", marginBottom: "10px" }}>
            Tags:{" "}
            {tagsError ? (
              <Body2 color="crimson">Please select atleast one tag!</Body2>
            ) : null}
          </Subtitle1>
          <CreatableSelect
            isMulti
            defaultValue={entity?.tags?.map((tag) => ({
              value: tag,
              label: tag,
            }))}
            onChange={handleChange}
            options={names}
          />
        </FormControl>
      </div>

      <div className="footer flex-boy-row">
        <Button
          onClick={handleClose}
          style={{
            margin: "20px",
            flex: 1,
            color: Primary,
            backgroundColor: "white",
            border: "1px solid #00AC69",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={entity.body ? updatevalue : getvalue}
          style={{ margin: "20px", flex: 1 }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
