/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {
  Subtitle1,
  Headline5,
  Subtitle2,
  Button,
  NotifyDiv,
} from "../../../components";
import { TextField } from "@material-ui/core";
import Logo from "../../../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00AC69",
        border: "2px solid",
      },
      "&:hover fieldset": {
        borderColor: "#00AC69",
      },
    },
  },
}));

export const PasswordConfirm = (props) => {
  const classes = useStyles();
  const [section, setSection] = useState("email");
  return (
    <div className="h-1/2 flex flex-col justify-between items-center">
      <Headline5 color="#00AC69">
        Please setup your new password to login.
      </Headline5>
      <img
        className="h-24 w-auto"
        src={require("../../../assets/ProspectorDesignAssets/Prospector_3D Logo_Lockup_Horizontal_with Tagline.png")}
        alt="Your Company"
      />
      <div>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            id="Password"
            color="primary"
            style={{ margin: 10, width: 300 }}
            label="New Password"
            type="Password"
            placeholder="Enter your New Password"
            variant="outlined"
          />
          <TextField
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              },
            }}
            id="Password-confirm"
            style={{ margin: 10, width: 300 }}
            label="Confirm Password"
            type="Password"
            placeholder="Confirm Password"
            variant="outlined"
          />
        </form>
      </div>
      <Button style={{ width: "80%" }}>Login</Button>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordConfirm);
