/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  Subtitle1,
  Headline5,
  Subtitle2,
  Headline6,
  Button,
  NotifyDiv,
} from "../../../components";
import { TextField } from "@material-ui/core";
import Logo from "../../../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon.png";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import {
  linkedinLogin,
  updateSignUpDetails,
} from "../../../store/Actions/AuthActions";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00AC69",
        border: "2px solid",
      },
      "&:hover fieldset": {
        borderColor: "#00AC69",
      },
    },
  },
}));

export const Page0 = ({
  swapAuthPage,
  history,
  updateSignUpDetails,
  linkedinLogin,
}) => {
  const classes = useStyles();
  const moveOn = (type) => {
    let data = {
      type,
    };
    updateSignUpDetails({ auth_type: type });
    if (type === "linkedin") {
      // swapAuthPage("Page1")
      linkedinLogin();
    } else {
      swapAuthPage("Page1");
    }
  };
  return (
    <>
      <Headline5 color="#00AC69">Welcome To Prospector!</Headline5>
      <img style={{ height: "22vh" }} src={Logo} alt="logo" />
      <div className="flex-boy-column" style={{ width: "100%" }}>
        <Button
          onClick={() => moveOn("email")}
          style={{ width: "80%", margin: "5px" }}
        >
          Register with email
        </Button>
        <Subtitle1 style={{ margin: "10px" }} color="#00AC69">
          Or
        </Subtitle1>
        <Button
          onClick={() => moveOn("linkedin")}
          style={{
            margin: "5px",
            width: "80%",
            backgroundColor: "white",
            color: "#00000099",
            border: "1px solid black",
          }}
        >
          Continue with LinkedIN
        </Button>
      </div>

      <div
        style={{ width: "100%", cursor: "pointer", justifyContent: "center" }}
        className="flex-boy-row"
      >
        <Subtitle1 color="#00AC69" onClick={() => history.push("/browse")}>
          Already have an account? Login
        </Subtitle1>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updateSignUpDetails: (data) => dispatch(updateSignUpDetails(data)),
  linkedinLogin: () => dispatch(linkedinLogin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Page0);
