import React from "react";
import { connect } from "react-redux";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../assets/ProspectorDesignAssets/lottie2.json";
export const Loading = (props) => {
  return (
    <div className=" flex flex-row rounded items-center  mt-1 bg-white  py-2 px-2 w-[3/4]">
      <Player
        autoplay
        loop
        src={animationData}
        style={{ height: "40px", width: "40px" }}
      >
        {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
      </Player>
      <p>Loading...</p>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
