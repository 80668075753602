import React from "react";
import { Bar } from "react-chartjs-2";

class BarChart extends React.Component {
  render() {
    const { data, highlight, domain } = this.props;

    // calculate frequency of data
    var counts = {};
    // for (var i = 0; i < data?.length; i++)
    //   counts[data[i]] = counts[data[i]] + 1 || 1;
    // console.log('PHONGTEST1', counts);
    // generate data
    // const barDataValues = [];
    // for (let i = 0; i < domain[1]; i++) {
    //   barDataValues.push(counts[i] || 0);
    // }
    const barDataValues = data;
    // if (barDataValues && barDataValues.length < 100) {
    //   for (let i = barDataValues.length; i < 100; i++) {
    //     barDataValues.push(0);
    //   }
    // }
    let rangeOfStep = domain
      ? (domain[1] - domain[0]) / barDataValues?.length
      : 1;
    let startIndex = Math.floor(highlight[0] / rangeOfStep);
    let endIndex = Math.floor(highlight[1] / rangeOfStep);

    // console.log('PHONGTEST2', highlight);
    const barData = {
      labels: barDataValues?.map((val, i) => i),
      datasets: [
        {
          backgroundColor: barDataValues?.map((val, i) =>
            i >= startIndex && i <= endIndex ? "#B3E6D2" : "#0000001E"
          ),
          borderColor: "rgb(255, 99, 132)",
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          data: barDataValues?.length
            ? barDataValues.map((item) => {
                return Math.log2(item);
              })
            : [],
        },
      ],
    };

    const options = {
      responsive: true,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            display: false,
          },
        ],
        yAxes: [
          {
            display: false,
            ticks: {
              min: 0,
            },
          },
        ],
      },
    };
    return <Bar data={barData} options={options} />;
  }
}

export default BarChart;
