import React, { useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import PowerCharts from "fusioncharts/fusioncharts.powercharts";
import ReactFusioncharts from "react-fusioncharts-fix";
import { apiRequest } from "../../../../../store/Actions/AuthActions";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../../../assets/ProspectorDesignAssets/lottie2.json";
import ContentLoader from "react-content-loader";
ReactFusioncharts.fcRoot(FusionCharts, charts, PowerCharts);
charts(FusionCharts);

const Sankey = ({ company, year, mineral, production_basis, metric_name }) => {
  const [dataSource, setdataSource] = useState(null);
  useEffect(() => {
    setdataSource(null);
    fetchData();
    return () => {};
  }, []);

  const fetchData = async () => {
    const data = await apiRequest
      .get(
        `companies/${company.id}/projects-annual-production?production_basis=${production_basis}&mineral=Au&report_year=${year}&metric_name=${metric_name}`
      )
      .catch((err) => console.log(err));
    if (
      data?.data?.data &&
      data.data.data.links.length > 0 &&
      data.data.data.nodes.length > 0
    ) {
      setdataSource({
        chart: {
          theme: "fusion",
          orientation: "horizontal",
          linkalpha: 30,
          linkhoveralpha: 60,
          nodelabelposition: "start",
          canvasborderColor: "#B9E1F4",
          canvasborderThicknes: "0.5",
          showBorder: "0",
          bgcolor: "#FFFFFF",
          canvasbgAlpha: "0",
          canvasbgcolor: "0",
          paletteColors: "#142735,#B8BEC2,#9C8D64",
          rotateNodeLabels: "1",
          nodeLabelPosition: "inside",
          inheritLabelColorFromNode: "0",
          nodeLabelFontColor: "#FFFFFF",
          showlegend: "0",
        },

        nodes: data.data.data.nodes.map((item) => {
          return { ...item, label: item.label.toString() };
        }),
        links: data.data.data.links.map((item) => {
          return { ...item, to: item.to.toString() };
        }),
      });
    }
  };
  return (
    <div style={{ width: "100%", height: "550px", marginTop: "50px" }}>
      <ReactFusioncharts
        type="sankey"
        width="100%"
        height="550"
        dataFormat="JSON"
        dataSource={dataSource}
      />
    </div>
  );
};

export default Sankey;
