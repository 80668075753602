/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import {
  Primary,
  Headline5,
  Button,
  Subtitle1,
  Subtitle2,
  BrowseListFilter,
  NotifyDiv,
  Headline6,
  TailwindButton,
  TailwindButtonOutline,
} from "../../../../components/general";
import CloseIcon from "@material-ui/icons/Close";
import {
  searchAlgoliaCompanies,
  filterProjects,
  filterCompanies,
  setProjectStartDate,
} from "../../../../store/Actions/AuthActions";
import {
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Exchanges } from "../../../../configure/constants";
import Select from "react-select";
import axios from "axios";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import animationData from "../../../../assets/ProspectorDesignAssets/lottie2.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { Period_range } from "../../../../configure/constants";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 100,
  },
}));
const continents = [
  "Asia",
  "North America",

  "Latin America",
  "Africa",
  "Antarctica",
  "Europe",
  "Oceania",
];
const customStyles = {
  control: (base) => ({
    ...base,
    fontSize: "x-large",

    minHeight: 50,
  }),
};
const periods = ["1M", "3M", "1Y", "5Y", "ALL"];
export const FilterContent = ({
  setProjectStartDate,
  setCompanyPage,
  projects_length,
  map_projects,
  companies_length,
  setProjectPage,
  projects,
  companies,
  auth,
  tab,
  handleClose,
  setProjectFilters,
  setCompanyFilters,
  project_filters,
  company_filters,
  report_filters,
  filterCompanies,
  companies_loading,
}) => {
  let me = auth.uid;
  const [value, setValue] = React.useState([20, 37]);
  const [extraMinerals, setMinerals] = useState([]);
  const [extraRegions, setRegions] = useState([]);
  const [showextraRegions, showRegions] = useState(false);
  const [showextraMinerals, showMinerals] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    getFilterInfo();
    setPeriod(
      Object.keys(Period_range).find(
        (key) => Period_range[key] === company_filters.filings_after
      )
    );
    if (tab === "projects") {
      let fils = project_filters.mineral?.map((tag) => ({
        value: tag,
        label: tag,
      }));

      updateFilters(
        project_filters || {
          mineral: null,
          start_after: null,
          searchTerm: null,
          regions: null,
          exchnges: null,
          countries: null,
          stage: null,
          resourceType: null,
          averageGrade_min: 0,
          averageGrade_max: 0,
          oreTonnage_min: 0,
          oreTonnage_max: 0,
          containedMetal_min: 0,
          containedMetal_max: 0,
        }
      );
      // search_projects({loc:0,newFilters:filters})
    } else if (tab === "companies") {
      updateFilters(
        company_filters || {
          mineral: null,
          start_after: null,
          searchTerm: null,
          regions: null,
          exchanges: null,
          countries: null,
          stage: null,
          resourceType: null,
          averageGrade_min: 0,
          averageGrade_max: 0,
          oreTonnage_min: 0,
          oreTonnage_max: 0,
          containedMetal_min: 0,
          containedMetal_max: 0,
        }
      );
      // search_companies({loc:0,newFilters:filters})
    } else {
      updateFilters(
        report_filters || {
          mineral: null,
          start_after: null,
          searchTerm: null,
          regions: null,
          exchanges: null,
          countries: null,
          stage: null,
          resourceType: null,
          averageGrade_min: 0,
          averageGrade_max: 0,
          oreTonnage_min: 0,
          oreTonnage_max: 0,
          containedMetal_min: 0,
          containedMetal_max: 0,
        }
      );
      // search_companies({loc:0,newFilters:filters})
    }
    return () => {};
  }, []);

  const getFilterInfo = async () => {
    const countries_data = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/config/countries`
    );
    const minerals_data = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/config/minerals`
    );

    if (countries_data) {
      setRegions([...continents, ...countries_data.data.data.sort()]);
    }
    if (minerals_data) {
      setMinerals([...new Set(minerals_data.data.data)]);
    }
  };
  const [filters, updateFilters] = useState({
    mineral: null,
    start_after: null,
    searchTerm: null,
    regions: null,
    countries: null,
    resourceType: null,
    averageGrade_min: 0,
    averageGrade_max: 0,
    oreTonnage_min: 0,
    oreTonnage_max: 0,
    containedMetal_min: 0,
    containedMetal_max: 0,
  });
  const setFilters = (filters) => {
    updateFilters(filters);
  };

  // const companiesIndex = searchClient.initIndex('dev_companies_idx')
  // const projectsIndex = searchClient.initIndex('dev_projects_idx')
  // let company_hit_list = []
  // let project_hit_list = []

  function numFormatter(num) {
    if (num > 999) {
      return (num / 1000000).toFixed(1) + " Million"; // convert to M for number from > 1 million
    } else {
      return num; // if value < 1000, nothing to do
    }
  }

  const updateSlider = (name, val) => {
    let newFilters = { ...filters };
    newFilters[`${name}_min`] = val[0] * 1000000;
    newFilters[`${name}_max`] = val[1] * 1000000;
    setFilters(newFilters);
    if (tab === "projects") {
      setProjectFilters(newFilters);
      search_projects({ loc: 0, newFilters });
    } else {
      setCompanyFilters(newFilters);
      search_companies({ loc: 0, newFilters });
    }
  };
  const updateAverageGrade = (name, val) => {
    let newFilters = { ...filters };
    newFilters[`${name}_min`] = val[0];
    newFilters[`${name}_max`] = val[1];

    setFilters(newFilters);
    if (tab === "projects") {
      setProjectFilters(newFilters);
      search_projects({ loc: 0, newFilters });
    } else {
      setCompanyFilters(newFilters);
      search_companies({ loc: 0, newFilters });
    }
  };
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const resTypes = [
    {
      name: "Indicated Mineral Resource",
      value: 85,
      indicator: "indicated",
    },
    {
      name: "Inferred Mineral Resource",
      value: 84,
      indicator: "inferred",
    },
    {
      name: "Measured Mineral Resource",
      value: 86,
      indicator: "measured",
    },
    {
      name: "Not Mentioned",
      value: 89,
      indicator: "not mentioned",
    },
    {
      name: "Probable Mineral Reserve",
      value: 87,
      indicator: "probable",
    },

    {
      name: "Proven Mineral Reserve",
      value: 88,
      indicator: "proven",
    },
  ];
  const stages = [
    "Discovery Delineation",
    "Grassroots",
    "Production",
    "Target Drilling",
    "Prefeasibility",
    "PEA",
    "Permitting & Feasibility",
    "Construction",
    "Suspended",
  ];

  const filterMinerals = [
    "cobalt",
    "copper",
    "gold",
    "rare earth elements",
    "lithium",
    "silver",
    "nickel",
    "palladium",
  ];
  const search_companies = ({ loc, newFilters }) => {
    setCompanyPage(0);

    filterCompanies();
  };
  const search_projects = ({ loc, newFilters }) => {
    setProjectPage(0);
    filterProjects();
  };

  const debouncedChangeHandler = useCallback(
    _.debounce(function () {
      setCompanyPage(0);
      filterCompanies();
    }, 1000),
    []
  );

  const updateSingleFilterKey = (name, value) => {
    let newFilters = { ...filters };

    if (JSON.stringify(newFilters[name]) === JSON.stringify(value)) {
      newFilters[name] = null;
      setFilters(newFilters);
      if (tab === "projects") {
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        setCompanyFilters(newFilters);
        if (name === "searchTerm") {
          debouncedChangeHandler();
        } else {
          search_companies({ loc: 0, newFilters });
        }
      }
    } else {
      newFilters[name] = value;
      setFilters(newFilters);
      if (tab === "projects") {
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        setCompanyFilters(newFilters);
        if (name === "searchTerm") {
          debouncedChangeHandler();
        } else {
          search_companies({ loc: 0, newFilters });
        }
      }
    }
  };
  const updateSingleFilterKeynonselect = (name, value) => {
    let newFilters = { ...filters };

    if (newFilters[name] && newFilters[name].length) {
      let index = newFilters[name].indexOf(value);
      if (index !== -1) {
        newFilters[name].splice(index, 1);
        setFilters(newFilters);
        if (tab === "projects") {
          setProjectFilters(newFilters);
          search_projects({ loc: 0, newFilters });
        } else {
          setCompanyFilters(newFilters);
          search_companies({ loc: 0, newFilters });
        }
      } else {
        newFilters[name].push(value);
        setFilters(newFilters);
        if (tab === "projects") {
          setProjectFilters(newFilters);
          search_projects({ loc: 0, newFilters });
        } else {
          setCompanyFilters(newFilters);
          search_companies({ loc: 0, newFilters });
        }
      }
    } else {
      newFilters[name] = [value];
      setFilters(newFilters);
      if (tab === "projects") {
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
    }
  };
  const clearFilters = () => {
    let newFilters = {
      mineral: null,
      start_after: null,
      searchTerm: null,
      countries: null,
      resourceType: null,
      averageGrade_min: 0,
      averageGrade_max: 0,
      oreTonnage_min: 0,
      oreTonnage_max: 0,
      containedMetal_min: 0,
      containedMetal_max: 0,
    };
    if (tab === "projects") {
      setProjectFilters(newFilters);
      search_projects({ loc: 0, newFilters });
      updateFilters(newFilters);
      // handleClose()
    } else {
      setCompanyFilters(newFilters);
      search_companies({ loc: 0, newFilters });
      setFilters(newFilters);

      updateFilters(newFilters);
      // handleClose()
    }
  };
  const [period, setPeriod] = useState("ALL");
  const switchPeriod = (period) => {
    setPeriod(period);
    let range = period.includes("1W")
      ? 7
      : period === "ALL"
      ? 30000
      : period === "1D"
      ? 1
      : period === "1M"
      ? 30
      : period === "3M"
      ? 90
      : period === "6M"
      ? 180
      : period === "2Y"
      ? 730
      : period === "3Y"
      ? 1095
      : period === "5Y"
      ? 1780
      : period === "1Y"
      ? 400
      : 90;
    updateSingleFilterKey("filings_after", range);
  };
  const change_text = (e) => {};
  const projectChange = (e, value) => {};

  return (
    <div
      className="flex-boy-column h-[calc(100vh_-_80px)] md:h-[calc(100vh_-_160px)]"
      style={{ width: "100%" }}
    >
      <div
        className="flex-boy-row"
        style={{
          width: "100%",
          justifyContent: "normal",
          padding: "20px",
          boxSizing: "border-box",
          textAlign: "left",
        }}
      >
        <Headline5 style={{ flex: 1 }}>Advanced Companies search</Headline5>
      </div>
      <div
        className="flex-boy-column "
        style={{
          padding: "10px",
          flex: 1,
          justifyContent: "normal",
          overflowY: "scroll",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "left",
            padding: "20px",
            paddingRight: "30px",
            boxSizing: "border-box",
          }}
        >
          <div className="flex-boy-row company-page-wrapper align-items-top">
            <div
              style={{
                flex: 1,
                boxSizing: "border-box",
                padding: "10px",
                paddingLeft: "0",
                // height: "80px",
              }}
            >
              <Headline6 style={{ marginBottom: "20px" }}>
                Company Name Search
              </Headline6>
              {/* <Select
                                displayEmpty
                                styles={customStyles}
                                onChange={(e) => 
                                placeholder={"Search By Company Name or ticker"}
                                options={map_projects.map((tag, index) => ({ value: tag.name.trim(), label: tag.name }))}
                            /> */}
              <TextField
                id="outlined-basic"
                fullWidth
                label=""
                value={filters?.searchTerm || ""}
                variant="outlined"
                onChange={(e) =>
                  updateSingleFilterKey("searchTerm", e.target.value)
                }
                placeholder="Search by words in title"
              />
            </div>
            <div
              style={{
                flex: 1,
                boxSizing: "border-box",
                padding: "10px",
                height: "80px",
              }}
            >
              <Headline6 style={{ marginBottom: "20px" }}>
                Technical Report Filed in Last
              </Headline6>
              <div className="flex-boy-row">
                {periods.map((time, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => switchPeriod(time)}
                      className="period-map flex-boy-column"
                      style={{
                        color: time === period ? "white" : Primary,
                        backgroundColor: time === period ? Primary : "white",
                      }}
                    >
                      <Headline6 color={time === period ? "white" : Primary}>
                        {time}
                      </Headline6>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex-boy-row company-page-wrapper"
          style={{
            width: "100%",
            textAlign: "left",
            padding: "20px",
            paddingTop: "0",
            boxSizing: "border-box",
            marginTop: "60px",
          }}
        >
          <div style={{ flex: 1, textAlign: "left" }}>
            <Headline6 style={{ marginBottom: "20px" }}>
              Exchanges & Regions
            </Headline6>
            <div
              style={{
                flex: 1,
                boxSizing: "border-box",
                padding: "10px",
                paddingLeft: 0,
                height: "80px",
                width: "100%",
              }}
            >
              <Select
                isMulti
                displayEmpty
                value={
                  filters?.exchange?.map((tag) => ({
                    value: tag,
                    label: tag,
                  })) || ""
                }
                styles={customStyles}
                // MenuProps={{ classes: { paper: classes.menuPaper } }}
                onChange={(e) =>
                  updateSingleFilterKey(
                    "exchange",
                    e.map((item) => item.value)
                  )
                }
                placeholder={"All Exchanges"}
                options={Exchanges.map((tag, index) => ({
                  value: tag.trim(),
                  label: tag,
                }))}
              />
            </div>
            <div
              style={{
                flex: 1,
                boxSizing: "border-box",
                padding: "10px",
                paddingLeft: 0,
                height: "80px",
                width: "100%",
              }}
            >
              <Select
                isMulti
                value={
                  filters?.countries
                    ?.filter((tag) => tag !== "South america")
                    .map((tag) => ({ value: tag, label: tag })) || ""
                }
                styles={customStyles}
                onChange={(e) =>
                  updateSingleFilterKey(
                    "countries",
                    e.map((item) => item.value)
                  )
                }
                placeholder={"Search for region or country"}
                options={extraRegions.map((tag, index) => ({
                  value: tag,
                  label: tag.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                  ),
                }))}
              />
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <Headline6 style={{ marginBottom: "20px" }}>Minerals</Headline6>
            <div
              style={{
                flex: 1,
                boxSizing: "border-box",
                padding: "10px 10px 10px 0",
                height: "80px",
              }}
            >
              <Select
                isMulti
                displayEmpty
                value={
                  filters?.mineral?.map((tag) => ({
                    value: tag,
                    label: tag,
                  })) || ""
                }
                styles={customStyles}
                // MenuProps={{ classes: { paper: classes.menuPaper } }}
                onChange={(e) =>
                  updateSingleFilterKey(
                    "mineral",
                    e.map((item) => item.value)
                  )
                }
                placeholder={"Search for mineral"}
                options={extraMinerals.map((tag, index) => ({
                  value: tag.trim(),
                  label: tag,
                }))}
              />
            </div>
            <div
              style={{
                flex: 1,
                boxSizing: "border-box",
                padding: "10px 10px 10px 0",
                height: "80px",
              }}
            >
              <Select
                isMulti
                displayEmpty
                value={
                  filters?.resourceType?.map((tag) => ({
                    value: tag,
                    label: tag,
                  })) || ""
                }
                styles={customStyles}
                // MenuProps={{ classes: { paper: classes.menuPaper } }}
                onChange={(e) =>
                  updateSingleFilterKey(
                    "resourceType",
                    e.map((item) => item.value)
                  )
                }
                placeholder={"All Mineral Estimate Levels"}
                options={resTypes.map((tag, index) => ({
                  value: tag.name.trim(),
                  label: tag.name,
                }))}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex-boy-row"
        style={{
          height: "75px",
          width: "100%",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        <TailwindButtonOutline onClick={clearFilters} text="Clear Filters" />

        {companies_loading ? (
          <div
            className="flex-boy-row"
            style={{ marginRight: "40px", marginBottom: "30px" }}
          >
            <Player
              autoplay
              loop
              src={animationData}
              style={{ height: "100px", width: "100px" }}
            >
              {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
            </Player>

            <Subtitle1>Loading</Subtitle1>
          </div>
        ) : (
          <TailwindButton
            onClick={handleClose}
            text={`Show ${companies_length} companies`}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  project_filters: state.authReducer.projects_search_filters,
  companies_search: state.authReducer.companies_search || [],
  projects: state.authReducer.projects_search || [],
  map_projects:
    state.authReducer.map_projects.filter(
      (item) => item.lat !== 0 && item.lng !== 0
    ) || [],
  company_filters: state.authReducer.company_search_filters,
  projects_length: state.authReducer.filtered_projects_length,
  companies_length: state.authReducer.filtered_companies_length,
  companies_loading: state.authReducer.companies_loading,
});

const mapDispatchToProps = (dispatch) => ({
  setProjectStartDate: (data) =>
    dispatch({ type: "SET_PROJECT_START_DATE", payload: data }),
  searchAlgoliaCompanies: (data) => dispatch(searchAlgoliaCompanies(data)),
  filterProjects: (data) => dispatch(filterProjects(data)),
  filterCompanies: (data) => dispatch(filterCompanies(data)),
  setCompanyPage: (data) =>
    dispatch({ type: "SET_COMPANIES_PAGE", payload: data }),
  setProjectPage: (data) =>
    dispatch({ type: "SET_PROJECTS_PAGE", payload: data }),
  setProjectFilters: (data) =>
    dispatch({ type: "SET_PROJECT_FILTERS", payload: data }),
  setCompanyFilters: (data) =>
    dispatch({ type: "SET_COMPANY_FILTERS", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterContent);
