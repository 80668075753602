import { Dialog } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Subtitle1 } from "../../components";
import { apiRequest } from "../../store/Actions/AuthActions";
import Dropdown from "./Dropdown";
import SearchComponent from "./SearchComponent";

export const index = ({
  project_filters,
  setBrowseTab,
  setReportFilters,
  history,
}) => {
  const [searches, setsearches] = useState([]);
  const [searchesOpen, setsearchesOpen] = useState(false);
  const [selectedSearch, setselectedsearch] = useState(null);
  useEffect(() => {
    fetchSearches();

    return () => {};
  }, []);
  const fetchSearches = async () => {
    console.log(JSON.stringify(project_filters));
    let data = await apiRequest
      .get("/saved-searches")
      .catch((err) => console.log(err));
    if (data && data.data) {
      setsearches([...data.data.data]);
    }
  };
  const handleCloseSearches = () => {
    setsearchesOpen(false);
    fetchSearches();
  };
  const handleopenSearch = (search) => {
    setselectedsearch(search);
    setsearchesOpen(true);
  };
  const handleopenDelete = (search) => {
    setselectedsearch(search);
    setconfirmDelete(true);
  };
  const [confirmDelete, setconfirmDelete] = useState(false);
  const handleDeleteClose = () => {
    setconfirmDelete(false);
  };
  const deletesearch = async () => {
    const toastId = toast("Deleting Search", {
      type: "info",
      autoClose: false,
    });
    let data = await apiRequest
      .delete(`/saved-searches/${selectedSearch.id}`)
      .catch((err) => {
        toast.update(toastId, {
          theme: "light",
          render: err.response.data.message,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      });

    if (data) {
      console.log(data);
      toast.update(toastId, {
        theme: "light",
        render: "Search deleted",
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      fetchSearches();
      setconfirmDelete(false);
    }
  };

  const viewSearch = (search) => {
    setselectedsearch(search);
    setReportFilters(selectedSearch.query_filters);
    setBrowseTab("technical reports");
    history.push("/browse");
  };
  return (
    <div className="w-full p-2">
      <Dialog
        onClose={handleCloseSearches}
        aria-labelledby="simple-dialog-title"
        fullWidth
        maxWidth="lg"
        open={searchesOpen}
      >
        <SearchComponent
          search={selectedSearch}
          handleCloseSearches={handleCloseSearches}
        />
      </Dialog>
      <Dialog
        onClose={handleDeleteClose}
        aria-labelledby="simple-dialog-title"
        open={confirmDelete}
      >
        <div className="hoer-sub" style={{ padding: "20px" }}>
          <Subtitle1>Are you sure you want to delete this search</Subtitle1>

          <div
            className="flex-boy-row"
            style={{
              height: "75px",
              width: "100%",
              padding: "20px",
              boxSizing: "border-box",

              backgroundColor: "white",
            }}
          >
            <button
              onClick={handleCloseSearches}
              type="button"
              className="inline-flex items-center  flex-1 px-3.5 mx-2 justify-center py-2 border border-emerald-500 text-xs font-bold rounded shadow-sm text-emerald-600 bg-white hover:bg-emerald-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-400"
            >
              CANCEL
            </button>
            <button
              onClick={deletesearch}
              type="button"
              className="inline-flex items-center justify-center flex-1 mx-2 min-w-[105px] px-3.5 py-2 border border-transparent text-xs font-bold rounded shadow-sm text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-400"
            >
              Delete
            </button>
          </div>
        </div>
      </Dialog>

      <div className="w-full flex flex-row justify-between items-end">
        <p className="text-2xl font-bold">Saved Searches</p>
        {/* <Dropdown /> */}
      </div>

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Date Created
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Last Updated
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {searches.map((search, personIdx) => (
                    <tr
                      key={search.id}
                      className={personIdx % 2 === 0 ? undefined : "bg-gray-50"}
                    >
                      <td
                        onClick={() => viewSearch(search)}
                        className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-left font-medium text-gray-900 sm:pl-6 cursor-pointer"
                      >
                        {search.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-left text-gray-500">
                        {moment(search.created_at).format("DD-MM-YYYY")}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-left text-gray-500">
                        {moment(search.created_at).format("DD-MM-YYYY")}
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 items-center flex flex-row text-right text-sm justify-end font-medium sm:pr-6">
                        <span
                          href="#"
                          className="text-emerald-600 flex flex-row  mx-2 items-center hover:text-emerald-900 cursor-pointer"
                          onClick={() => handleopenSearch(search)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                          Edit<span className="sr-only">, {search.name}</span>
                        </span>
                        <span
                          href="#"
                          className="text-emerald-600 flex flex-row mx-2 items-center cursor-pointer hover:text-emerald-900"
                          onClick={() => handleopenDelete(search)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                          Delete<span className="sr-only">, {search.name}</span>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  project_filters: state.authReducer.projects_search_filters,
});

const mapDispatchToProps = (dispatch) => ({
  setProjectFilters: (data) =>
    dispatch({ type: "SET_PROJECT_FILTERS", payload: data }),
  setCompanyFilters: (data) =>
    dispatch({ type: "SET_COMPANY_FILTERS", payload: data }),
  setReportFilters: (data) =>
    dispatch({ type: "SET_REPORTS_FILTERS", payload: data }),
  setBrowseTab: (data) => dispatch({ type: "SET_BROWSE_TAB", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
