import React, { useEffect, useState, useRef, createRef } from "react";
import { METRIC_GROUPS } from "./constants";
import _ from "lodash";
export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    // { value: 1e15, symbol: "P" },
    // { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : Math.abs(num) === 0
    ? ""
    : num;
};

export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}

export const format_lomp_data = (data) => {
  const dataGroups = {};

  Object.keys(METRIC_GROUPS).forEach((key) => {
    dataGroups[key] = data.filter((something) =>
      METRIC_GROUPS[key].includes(something.metric_category)
    );

    dataGroups[key] = _.groupBy(dataGroups[key], "metric_category");
  });
  return dataGroups;
};
export const sum = (values) => values.reduce((prev, curr) => prev + curr, 0);
export const totalCalculator = (grade, contained, tonnage) => {
  let totalValue;
  if (grade === "g/t") {
    totalValue = (sum(contained) / sum(tonnage)) * 31.1035;
  } else if (grade === "mg/L") {
    totalValue = (sum(contained) / sum(tonnage)) * 1000000;
  } else if (grade === "%") {
    totalValue = (sum(contained) / sum(tonnage)) * 100;
  } else if (grade === "c/t") {
    totalValue = (sum(contained) / sum(tonnage)) * 1;
  }
  console.log(totalValue);
  return isNaN(totalValue) ? 0 : parseFloat(totalValue);
};

export const checkNumber = (x) => {
  let regexPattern = /^-?[0-9]+$/;

  // check if the passed number is integer or float
  let result = regexPattern.test(x);

  if (result) {
    console.log(`${x} is an integer.`);
  } else {
    console.log(`${x} is a float value.`);
  }
  return result;
};
