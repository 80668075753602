/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { MainPage } from "../components";
import { Grid, Box } from "@material-ui/core";
import InvestorMatchCard from "../components/InvestorMatchCard";
import CompanyMatchCard from "../components/CompanyMatchCard";
import SummaryAccordion from "../components/SummaryAccordion";
import AutoComp from "../components/AutoComp";
import SparkLine from "../components/SparkLine";
import { Dialog } from "@material-ui/core";
import Logo from "../assets/ProspectorDesignAssets/43-101_Authors-removebg-preview.png";

import MatchImage from "../assets/ProspectorDesignAssets/Companies/Tudor Gold Corp.jpeg";

import {
  Body1,
  Subtitle1,
  Headline6,
  Headline4,
  Subtitle2,
  Button,
  NotifyDiv,
  activeUser,
} from "../components";
import EmptyMatchList from "../components/EmptyMatchList";
import {
  getProspects,
  getCompanies,
  matchUsers,
  deleteMatches,
  manageSub,
} from "../store/Actions/AuthActions";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
export const Matches = ({
  manageSub,
  deleteMatches,
  getProspects,
  companies,
  all_list_companies,
  selectedList,
  getCompanies,
  list,
  matchUsers,
  auth,
  getPeople,
  activeUser,
  history,
}) => {
  let me = auth.uid;
  const [cards, setCards] = useState([1, 2, 3, 4, 5, 6]);
  const [match, setMatch] = useState(false);
  let requestType =
    activeUser?.type && activeUser?.type === "Mining"
      ? "targetUser"
      : "requestedBy";
  const subscription = activeUser;
  const [poppedcompanies, setPopped] = useState([]);
  useEffect(() => {
    getProspects({ listId: selectedList, me });

    if (selectedList) {
      getCompanies({ listId: selectedList });
    } else {
      getCompanies();
    }

    return () => {};
  }, [selectedList]);
  const pop = (type, company) => {
    // const new_cards = [...cards]
    // new_cards.pop()
    // if (new_cards.length % 2 === 1) {
    //     setMatch(true)
    // }
    // setCards(new_cards)
    let popped = [...poppedcompanies];

    if (type === "rejected") {
      // matchUsers(company)
      // getCompanies()
    } else {
      matchUsers(company);
    }
    popped.push(company);
    setPopped(popped);
  };

  const viewMore = () => {
    //
    // setCards([1,2,3,4,5,6])
    if (selectedList) {
      getCompanies({ listId: selectedList });
    } else {
      getCompanies();
    }
  };
  return (
    <MainPage style={{ overflowX: "hidden" }}>
      <Dialog aria-labelledby="simple-dialog-title" open={match}>
        <NotifyDiv>
          <div>
            <img
              style={{
                height: "12vh",
              }}
              src={Logo}
              alt="logo"
            />
            <Headline4 color="#00AC69">You've Matched!</Headline4>
          </div>
          <img
            style={{
              height: "20vh",
            }}
            src={MatchImage}
            alt="logo"
          />
          <Headline6>Would you like to connect with Tudor gold now? </Headline6>
          <div>
            <Button onClick={() => setMatch(false)}>Send Message</Button>
            <Subtitle1 color="#00AC69">View Company Details</Subtitle1>
          </div>
        </NotifyDiv>
      </Dialog>
      {!subscription ? (
        <div
          className="subscription-status"
          style={{ position: "absolute", height: "100%" }}
        >
          <div className="bg-img2"></div>
          <div className="sub-box">
            <Subtitle1 color="white">
              {" "}
              Please Login to view this content{" "}
            </Subtitle1>
            <Button onClick={() => manageSub()}>Subscribe</Button>
          </div>
        </div>
      ) : activeUser &&
        subscription &&
        activeUser?.permissions.includes("follow_resources") ? (
        <Grid container spacing={1}>
          <Grid item lg={1}>
            {/* <Box display={{ xs: "none", lg: "block" }}>
                <Button onClick={ ()=>deleteMatches(me)}>Delete All</Button>
            </Box> */}
          </Grid>
          <Grid item xs={12} lg={6} xl={6} container justify="center">
            {/* <AutoComp /> */}
            {/* {cards.map((card, index) => {
                        return (
                            <InvestorMatchCard rotate={index === cards.length-1 ? 0 : index % 2 === 0 ? 2 : -2} zIndex={index+1}/>
                        )
                    })} */}
            {companies.filter((item) => !poppedcompanies.includes(item.id))
              .length ? (
              companies
                .filter((item) => !poppedcompanies.includes(item.id))
                .map((card, index) =>
                  card.companyData ? (
                    <CompanyMatchCard
                      person={card}
                      pop={pop}
                      key={index}
                      rotate={
                        index ===
                        companies.filter(
                          (item) => !poppedcompanies.includes(item.id)
                        ).length -
                          1
                          ? 0
                          : index % 2 === 0
                          ? 2
                          : -2
                      }
                      zIndex={index + 1}
                    />
                  ) : (
                    <InvestorMatchCard
                      company={card}
                      key={index}
                      pop={pop}
                      rotate={
                        index ===
                        companies.filter(
                          (item) => !poppedcompanies.includes(item.id)
                        ).length -
                          1
                          ? 0
                          : index % 2 === 0
                          ? 2
                          : -2
                      }
                      zIndex={index + 1}
                    />
                  )
                )
            ) : (
              <EmptyMatchList viewMore={viewMore} />
            )}
          </Grid>

          <Grid item xs={3}>
            <Box
              display={{ xs: "none", lg: "block" }}
              style={{ position: "sticky", width: "inherit", top: 0 }}
            >
              <SummaryAccordion />
            </Box>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      ) : (
        <div
          className="subscription-status"
          style={{ position: "absolute", height: "100%" }}
        >
          <div className="bg-img2"></div>
          <div className="sub-box">
            <Subtitle1 color="white">
              {" "}
              Please {activeUser ? "" : "Login"}{" "}
              {activeUser &&
              subscription &&
              !activeUser?.permissions.includes("follow_resources")
                ? "upgrade subscription"
                : "Login"}{" "}
              to view this content{" "}
            </Subtitle1>
            <Button
              onClick={() =>
                activeUser && !subscription
                  ? manageSub()
                  : activeUser &&
                    subscription &&
                    !activeUser?.permissions.includes("follow_resources")
                  ? manageSub()
                  : history.push("/auth/login")
              }
            >
              {" "}
              {activeUser && !subscription ? "Login" : "Manage Subscription"}
            </Button>
          </div>
        </div>
      )}
    </MainPage>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.authReducer.activeUser,
  companies: state.authReducer.companies || [],
  selectedList: state.authReducer.selectedList,
  list: state.authReducer.lists,
  auth: state.firebase.auth,
  all_list_companies: state.firestore.ordered.customers,
});

const mapDispatchToProps = (dispatch) => ({
  getProspects: (data) => dispatch(getProspects(data)),
  getCompanies: (data) => dispatch(getCompanies(data)),
  manageSub: () => dispatch(manageSub()),
  matchUsers: (data) => dispatch(matchUsers(data)),
  deleteMatches: (data) => dispatch(deleteMatches(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Matches);
