/* eslint-disable no-unused-vars */

import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {
  Subtitle1,
  Headline4,
  Headline5,
  Subtitle2,
  Headline6,
  Primary,
  Button,
  NotifyDiv,
  ProspectorDescription,
  Body1,
  Body2,
  ProspectorInterest,
  TailwindButton,
} from "../general";
import { TextField } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { makeStyles } from "@material-ui/core/styles";

import {
  updateSignUpDetails,
  updateUserDetails,
} from "../../store/Actions/AuthActions";

const interests = [
  {
    name: "Base Metals",
    minerals: ["Cu", "Zn", "Pb"],
  },
  {
    name: "Critical Metals",
    minerals: ["Co", "REE", "Th"],
  },
  {
    name: "Energy Metals",
    minerals: ["U", "Mn", "Ni"],
  },
  {
    name: "Gems",
    minerals: ["Diamonds", "Emeralds"],
  },
  {
    name: "Industrial Metals",
    minerals: ["Asbestos", "Bauxite"],
  },
  {
    name: "Precious Metals",
    minerals: ["Au", "Ag", "PGE"],
  },
];

const regionalinterests = {
  countries: ["canada", "united-states"],
  regions: ["asia", "australia", "latin america", "north america", "Africa"],
  minerals: ["Au", "Ag", "Cu"],
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00AC69",
        border: "2px solid",
      },
      "&:hover fieldset": {
        borderColor: "#00AC69",
      },
    },
  },
}));

export const UserPrefernces = ({
  activeUser,
  updateUserDetails,
  handleCloseUser,
}) => {
  const [selectedInterests, setSelected] = useState(
    activeUser?.interests && activeUser?.interests.minerals
      ? interests.filter((region) =>
          region.minerals.every((val) =>
            activeUser?.interests.minerals.includes(val)
          )
        )
      : []
  );
  const [go, setContinue] = useState(false);
  const select = (region) => {
    let array = [...selectedInterests];
    const exists = array.includes(region);

    if (exists) {
      setSelected(
        array.filter((c) => {
          return c !== region;
        })
      );
    } else {
      const result = array;
      result.push(region);

      setSelected(result);
    }
  };
  const [selectedRegionInterests, setSelectedRegions] = useState(
    activeUser?.interests && activeUser?.interests.regions
      ? activeUser?.interests.regions
      : []
  );

  const selectRegion = (region) => {
    let array = [...selectedRegionInterests];
    const exists = array.includes(region);

    if (exists) {
      setSelectedRegions(
        array.filter((c) => {
          return c !== region;
        })
      );
    } else {
      const result = array;
      result.push(region);

      setSelectedRegions(result);
    }
  };

  const validate = async () => {
    console.log("do sumn");
    let new_data = { ...selectedInterests };
    // updateSignUpDetails({ ...data })
    let minerals = selectedInterests.map((item) => item.minerals).flat();
    let payload = {
      interests: {
        minerals: minerals,
        regions: selectedRegionInterests.length
          ? selectedRegionInterests
          : ["canada", "USA"],
      },
    };
    await updateUserDetails(payload);
    handleCloseUser();
  };

  return (
    <NotifyDiv>
      <Headline5 color={Primary}>User Preferences</Headline5>
      <Headline6>Mineral Preferences</Headline6>
      <div className="flex-boy-row" style={{ flexWrap: "wrap" }}>
        {interests.map((region) => {
          return (
            <ProspectorInterest
              key={region}
              onClick={() => {
                select(region);
              }}
              style={{ textAlign: "left", padding: "5px", cursor: "pointer" }}
              color={
                selectedInterests.includes(region) ? "#00AC69" : "#00000061"
              }
              className="flex-boy-row"
            >
              <div
                style={{
                  textAlign: "left",
                  textTransform: "capitalize",
                  alignItems: "flex-start",
                }}
                className="flex-boy-column"
              >
                <Subtitle1
                  style={{ textAlign: "left" }}
                  color={
                    selectedInterests.includes(region) ? "#00AC69" : "#00000061"
                  }
                >
                  {region.name}
                </Subtitle1>
                <div className="flex-boy-row">
                  {region.minerals.map((mineral, index) => {
                    return (
                      <Subtitle2 key={index}>
                        {mineral}{" "}
                        {index !== region.minerals.length - 1 ? "," : null}{" "}
                      </Subtitle2>
                    );
                  })}
                </div>
              </div>
              <div style={{ height: "100%" }}>
                {selectedInterests.includes(region) ? (
                  <CheckCircleIcon fontSize="small" />
                ) : null}
              </div>
            </ProspectorInterest>
          );
        })}
      </div>
      <Headline6>Regional Preferences</Headline6>
      <div className="flex-boy-row" style={{ flexWrap: "wrap" }}>
        {regionalinterests.regions.map((region) => {
          return (
            <ProspectorInterest
              key="region"
              onClick={() => {
                selectRegion(region);
              }}
              style={{
                textAlign: "left",
                padding: "5px",
                alignItems: "flex-start",
              }}
              color={
                selectedRegionInterests.includes(region)
                  ? "#00AC69"
                  : "#00000061"
              }
              className="flex-boy-row"
            >
              <div
                style={{
                  textAlign: "left",
                  height: "100%",
                  textTransform: "capitalize",
                }}
                className="flex-boy-row"
              >
                <img
                  style={{ height: "5vh" }}
                  src={require(`../../assets/ProspectorDesignAssets/Regions/${region.toLowerCase()} with padding.png`)}
                  alt="flag"
                />

                <Subtitle1 style={{ textAlign: "left" }}>{region}</Subtitle1>
              </div>
              {selectedRegionInterests.includes(region) ? (
                <CheckCircleIcon fontSize="small" />
              ) : null}
            </ProspectorInterest>
          );
        })}
        <div className="flex-boy-row">
          <TailwindButton onClick={() => validate()} text="Update" />
        </div>
      </div>
    </NotifyDiv>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.authReducer.activeUser,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserDetails: (data) => dispatch(updateUserDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPrefernces);
