import React, { useState, useEffect, useRef } from "react";
import NumberFormat from "react-number-format";
import { connect, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { apiRequest } from "../../../store/Actions/AuthActions";
import Button from "@material-ui/core/Button";
import {
  GreyBox,
  Headline6,
  TailwindButton,
  TailwindButtonOutline,
} from "../../../components";
import WaterfallChart from "./WaterfallChart";
import { GaugeChart } from "./GaugeChart";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { Dialog, InputAdornment } from "@material-ui/core";
import { toast } from "react-toastify";
import moment from "moment";
import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { metal_prices } from "../../../configure/constants";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      // margin: theme.spacing(1),
      // transform: "scale(0.9)",
      // transformOrigin: "top left",
      fontSize: "0.6em !important",
      width: "100%",
      height: "50px",
      marginRight: "5px !important",
    },
    "& .MuiTypography-body1": {
      fontSize: "10px",
    },
  },
  resize: {
    fontSize: 12,
  },
}));

const defaultValues = {
  mine_life: 11,
  discount_rate: 10,
  royalties: 1,
  metals: "All Metals",
  tax_rate: 25,
  capital_expenditures: 10000000000,
  mining_costs: 4.42,
  processing_costs: 4.52,
  g_a_costs: 4.52,
  mining_costs_type: "USD/t mined",
  processing_costs_type: "USD/t mined",
  g_a_costs_type: "USD/t mined",
  mined_ore: 2198559000,
  processed_ore: 2198559000,
};
const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      thousandSeparator={true}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      // isNumericString
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const InputForm = ({
  data,
  saved_npv_data,
  exportPDFWithComponent,
  project,
}) => {
  const classes = useStyles();
  const elements = useSelector((state) => state.authReducer.minerals_list);
  const [formValues, setFormValues] = useState(null);
  const [metalsnewCase, setmetalsnewCase] = useState();
  const [productionMinerals, setoldmetals] = useState([]);
  const [isBaseCase, setisBaseCase] = useState(true);
  const [chart_data, setchart_data] = useState(null);
  const [searchName, setsearchName] = useState(
    saved_npv_data?.name ? saved_npv_data?.name : null
  );

  useEffect(() => {
    if (data) {
      set_default_values();
      fetchMetalDetails();
    }

    return () => {};
  }, [data]);

  useEffect(() => {
    if (metalsnewCase && formValues) {
      recalculateValues();
    }

    return () => {};
  }, [metalsnewCase, formValues]);
  useEffect(() => {
    if (metalsnewCase && formValues && metalsnewCase.length) {
      setbaseValues();
    }

    return () => {};
  }, [metalsnewCase]);

  function toString(o) {
    if (o) {
      Object.keys(o).forEach((k) => {
        if (typeof o[k] === "object") {
          return toString(o[k]);
        }

        o[k] = "" + o[k];
      });

      return o;
    }
  }

  const save_npv_data = () => {
    let mining_metrics = data.MiningMetrics;
    let npv_data = {
      name:
        searchName ||
        `${project.reportName} ${moment().format("MM-DD-YYYY HH:MM")}`,
      lomp_id: mining_metrics["Mining LOM - Mine Life"]?.[0]?.lomp_id,
      ga_costs: formValues.g_a_costs,
      ga_costs_type: formValues.g_a_costs_type,
      npv_value: chart_data.NPV || saved_npv_data.npv_value,
      as_of_date:
        mining_metrics["Mining LOM - Mine Life"]?.[0]?.as_of_date ||
        moment().format("YYYY-MM-DD"),

      ...formValues,
    };

    console.log(npv_data);

    let new_dat = toString({ ...npv_data });
    let mins = [...metalsnewCase];
    new_dat.minerals = mins.map((item) =>
      _.omit(
        {
          ...item,
          production: item.production || item.metric,
          price: item.price.toString(),
        },
        "metric"
      )
    );
    console.log(mins);
    let o = Object.fromEntries(
      Object.entries(new_dat).filter(([_, v]) => v != null)
    );
    if (!saved_npv_data) {
      const toastId = toast("Saving data", { type: "info", autoClose: false });

      const response = apiRequest
        .post(`/npv`, o)
        .then((res) => {
          toast.update(toastId, {
            theme: "light",
            render: "NPV data successfully saved",
            type: toast.TYPE.SUCCESS,
            autoClose: 5000,
          });
          handleCloseSave();
        })
        .catch((error) => {
          toast.update(toastId, {
            theme: "light",
            render: error.response.data.message,
            type: toast.TYPE.ERROR,
            autoClose: 5000,
          });
        });

      if (response) {
        console.log("done");
      }
    } else {
      const toastId = toast("Updating data", {
        type: "info",
        autoClose: false,
      });
      o.name =
        searchName ||
        `${project.reportName} ${moment().format("MM-DD-YYYY HH:MM")}`;
      const response = apiRequest
        .patch(`/npv/${saved_npv_data.id}`, o)
        .then((res) => {
          toast.update(toastId, {
            theme: "light",
            render: "NPV data successfully updated",
            type: toast.TYPE.SUCCESS,
            autoClose: 5000,
          });
          handleCloseSave();
        })
        .catch((error) => {
          toast.update(toastId, {
            theme: "light",
            render: error.response.data.message,
            type: toast.TYPE.ERROR,
            autoClose: 5000,
          });
        });

      if (response) {
        console.log("done");
      }
    }
  };

  const fetchMetalDetails = async () => {
    if (saved_npv_data) {
      setoldmetals(saved_npv_data.minerals);
      setmetalsnewCase(saved_npv_data.minerals);
    } else {
      get_old_data();
      let metals = await Promise.all(
        _.uniq(
          data?.ProcessingMetrics["Processing LOM - Total Production"].map(
            async (item) => {
              let price = await apiRequest
                .get(
                  `/metal-prices?minerals=${
                    elements.find((min) => item.mineral === min.chemical_symbol)
                      .chemical_symbol
                  }&interval=d&range=5&range_period=d`
                )
                .catch((e) => {
                  console.log(e);
                  item.price =
                    metal_prices[
                      elements.find(
                        (min) => item.mineral === min.chemical_symbol
                      ).chemical_symbol
                    ];
                });
              // let price = await apiRequest.get(
              //   `/stock-history?identifier=${
              //     elements.find((min) => item.mineral === min.chemical_symbol)
              //       .name
              //   }&interval=1d&range=5d`
              // );
              if (price && price.data) {
                console.log(price.data, price.data.data[0]?.result?.values[0]);
                item.price =
                  price.data.data[0]?.result?.values[0]?.toFixed(2) ||
                  item.price;
                return {
                  price:
                    item.price ||
                    metal_prices[
                      elements.find(
                        (min) => item.mineral === min.chemical_symbol
                      ).chemical_symbol
                    ] ||
                    0,
                  mineral: item.mineral,
                  uom: item.uom,
                  production: item.metric,
                };
              }
              return {
                price:
                  item.price ||
                  metal_prices[
                    elements.find((min) => item.mineral === min.chemical_symbol)
                      .chemical_symbol
                  ] ||
                  0,
                mineral: item.mineral,
                uom: item.uom,
                production: item.metric || "0",
              };
            }
          )
        )
      );
      console.log(metals);
      // await setoldmetals(metals);
      await setmetalsnewCase(metals);
      // recalculateValues();
    }
  };

  const get_old_data = async () => {
    if (saved_npv_data) {
      setoldmetals(saved_npv_data.minerals);
    } else {
      let metals = await Promise.all(
        _.uniq(
          data?.ProcessingMetrics["Processing LOM - Total Production"].map(
            async (item) => {
              let price = await apiRequest
                .get(
                  `/stock-history?identifier=${
                    elements.find((min) => item.mineral === min.chemical_symbol)
                      .name
                  }&interval=1d&range=5d`
                )
                .catch((e) => {
                  console.log(e);
                  item.price =
                    metal_prices[
                      elements.find(
                        (min) => item.mineral === min.chemical_symbol
                      ).chemical_symbol
                    ];
                });

              if (price && price.data) {
                item.price = price.data.data[0].close.toFixed(2);
                return {
                  price: item.price,
                  mineral: item.mineral,
                  uom: item.uom,
                  production: item.metric,
                };
              }
              return {
                price:
                  item.price ||
                  metal_prices[
                    elements.find((min) => item.mineral === min.chemical_symbol)
                      .chemical_symbol
                  ] ||
                  0,
                mineral: item.mineral,
                uom: item.uom,
                production: item.metric || "0",
              };
            }
          )
        )
      );

      await setoldmetals(metals);

      // recalculateValues();
    }
  };
  const [baseData, setBaseData] = useState();

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    await setFormValues({
      ...formValues,
      [name]: value,
    });
    setisBaseCase(false);
  };
  const set_default_values = async () => {
    if (saved_npv_data) {
      let data_to_load = saved_npv_data;
      data_to_load.g_a_costs = saved_npv_data.ga_costs;
      data_to_load.g_a_costs_type = saved_npv_data.ga_costs_type;
      setFormValues(saved_npv_data);
    } else {
      let cost = data.UnitOperatingCosts;
      let royalties = data.Royalties;
      let mining_metrics = data.MiningMetrics;
      let processing_metrics = data.ProcessingMetrics;

      let response = await apiRequest.get(
        `lomp/${mining_metrics["Mining LOM - Mine Life"]?.[0]?.lomp_id}/aggregated_lomp`
      );
      if (response && response.data) {
        let CAPEX =
          parseFloat(response.data.data[0].capex_sustaining_capex) +
          parseFloat(response.data.data[0].capex_total_capex) +
          parseFloat(response.data.data[0].capex_initial_capex) +
          parseFloat(response.data.data[0].capex_expansion_capex) +
          parseFloat(response.data.data[0].capex_reclamation_and_closure_capex);
        let defaultValues = {
          ...response.data.data[0],
          capital_expenditures: CAPEX,
          mining_costs_type:
            cost["Cash Flow - Unit Operating Costs (Mining)"]?.[0]?.uom.replace(
              "$(USD)",
              "USD"
            ) || "USD/t mined",
          processing_costs_type:
            cost[
              "Cash Flow - Unit Operating Costs (Processing)"
            ]?.[0]?.uom.replace("$(USD)", "USD") || "USD/t mined",
          g_a_costs_type:
            cost["Cash Flow - Unit Operating Costs (G&A)"]?.[0]?.uom.replace(
              "$(USD)",
              "USD"
            ) || "USD/t mined",
          discount_rate: 10,
          royalties: parseFloat(royalties["Royalties"]?.[0]?.metric || 0),
          tax_rate: 25,
        };
        setFormValues(defaultValues);
      }
    }
  };

  const handleMetalInputChange = async (min, e, index) => {
    const { name, value } = e.target;
    // console.log(name, value, min)
    // let new_item = {
    //     price: item.price,
    //     mineral: item.mineral,
    //     uom: item.uom,
    //     metric: item.metric
    // }
    let minerals = [...metalsnewCase];
    let foundIndex = index;
    if (name === "metric") {
      minerals[foundIndex]["production"] = value;
      minerals[foundIndex][name] = value;
    }
    minerals[foundIndex][name] = value;

    await setmetalsnewCase(minerals);
    setisBaseCase(false);
    recalculateValues();
  };

  const recalculateValues = () => {
    let {
      mine_life,
      discount_rate,
      capex_total_capex,
      capex_sustaining_capex,
      capex_initial_capex,
      capex_expansion_capex,
      capex_reclamation_and_closure_capex,
      capital_expenditures,
      royalties,
    } = formValues;
    let npv_data = {};
    const total_mining_cost =
      formValues.mining_costs_type === "USD/t mined"
        ? formValues.mining_costs * formValues.mined_ore
        : formValues.mining_costs * formValues.processed_ore;
    const total_processing_cost =
      formValues.processing_costs_type === "USD/t mined"
        ? formValues.processing_costs * formValues.mined_ore
        : formValues.processing_costs * formValues.processed_ore;
    const total_g_a_cost =
      formValues.g_a_costs_type === "USD/t mined"
        ? formValues.g_a_costs * formValues.mined_ore
        : formValues.g_a_costs * formValues.processed_ore;

    let metal_sales = metalsnewCase
      ?.map((i) => parseFloat(i.production || i.metric) * parseFloat(i.price))
      ?.reduce((a, b) => a + b);

    let OPEX =
      parseFloat(total_mining_cost) +
      parseFloat(total_processing_cost) +
      parseFloat(total_g_a_cost);
    let CAPEX = parseFloat(capital_expenditures);
    let gross_profit =
      parseFloat(metal_sales) - parseFloat(CAPEX) - parseFloat(OPEX);
    let taxes =
      parseFloat(gross_profit) * parseFloat(formValues.tax_rate / 100);
    let net_profit = parseFloat(gross_profit) - parseFloat(taxes);
    let new_royalties = parseFloat(net_profit) * parseFloat(royalties / 100);
    const denom = parseFloat(
      (1 + parseFloat(discount_rate) / 100) ** mine_life
    );

    let avg = (parseFloat(net_profit) - parseFloat(new_royalties)) / mine_life;
    let NPV = calculateNPV(mine_life, discount_rate, avg);
    console.log("💀", NPV);

    npv_data = {
      CAPEX,
      NPV,
      taxes,
      OPEX,
      gross_profit,
      net_profit,
      metal_sales,
      royalties: new_royalties,
    };

    setchart_data(npv_data);
  };

  function calculateNPV(n, discount_rate, avg) {
    const increments = Array.from(
      { length: n },
      (_, i) => avg / Math.pow(1 + discount_rate / 100, i + 1)
    );
    const npv = increments.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return npv;
  }
  // set form fields
  const setbaseValues = () => {
    let {
      mine_life,
      discount_rate,
      capex_total_capex,
      capex_sustaining_capex,
      capex_initial_capex,
      capex_expansion_capex,
      capex_reclamation_and_closure_capex,
      royalties,
    } = formValues;
    let npv_data = {};
    const total_mining_cost =
      formValues.mining_costs_type === "USD/t mined"
        ? formValues.mining_costs * formValues.mined_ore
        : formValues.mining_costs * formValues.processed_ore;
    const total_processing_cost =
      formValues.processing_costs_type === "USD/t mined"
        ? formValues.processing_costs * formValues.mined_ore
        : formValues.processing_costs * formValues.processed_ore;
    const total_g_a_cost =
      formValues.g_a_costs_type === "USD/t mined"
        ? formValues.g_a_costs * formValues.mined_ore
        : formValues.g_a_costs * formValues.processed_ore;

    let metal_sales = productionMinerals?.map(
      (i) => parseFloat(i.production || i.metric) * parseFloat(i.price)
    ).length
      ? productionMinerals
          ?.map(
            (i) => parseFloat(i.production || i.metric) * parseFloat(i.price)
          )
          ?.reduce((a, b) => a + b)
      : 0;

    let OPEX =
      parseFloat(total_mining_cost) +
      parseFloat(total_processing_cost) +
      parseFloat(total_g_a_cost);
    let CAPEX = saved_npv_data
      ? saved_npv_data.capital_expenditures
      : parseFloat(capex_sustaining_capex) +
        parseFloat(capex_total_capex) +
        parseFloat(capex_initial_capex) +
        parseFloat(capex_expansion_capex) +
        parseFloat(capex_reclamation_and_closure_capex);
    let gross_profit =
      parseFloat(metal_sales) - parseFloat(CAPEX) - parseFloat(OPEX);
    let taxes =
      parseFloat(gross_profit) * parseFloat(formValues.tax_rate / 100);
    let net_profit = parseFloat(gross_profit) - parseFloat(taxes);
    let new_royalties = parseFloat(net_profit) * parseFloat(royalties / 100);
    const denom = parseFloat(
      (1 + parseFloat(discount_rate) / 100) ** mine_life
    );
    console.log((parseFloat(net_profit) - parseFloat(new_royalties)) / denom);

    let avg = (parseFloat(net_profit) - parseFloat(new_royalties)) / mine_life;
    let NPV = calculateNPV(mine_life, discount_rate, avg);
    console.log("💀", NPV);
    npv_data = {
      CAPEX,
      NPV,
      taxes,
      OPEX,
      gross_profit,
      net_profit,
      metal_sales,
      royalties: new_royalties,
    };

    setBaseData(npv_data);
  };

  // reset values
  const reset = () => {
    console.log(productionMinerals);
    set_default_values();
    setisBaseCase(true);
    setmetalsnewCase(productionMinerals);
    recalculateValues();
  };
  const [chartView, setchartView] = useState("gauge");
  const [saveopen, setOpen] = useState(false);
  const handleCloseSave = () => {
    setOpen(false);
  };
  return (
    <div className="w-full px-1 py-2">
      <Dialog open={saveopen} onClose={handleCloseSave}>
        <DialogTitle>{saved_npv_data ? "Update " : "Save "} NPV</DialogTitle>
        <DialogContent>
          <DialogContentText>Please add a Name to Your NPV</DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="NPV name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={searchName}
            onChange={(e) => setsearchName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <div className="w-full flex flex-row justify-between">
            <TailwindButtonOutline text="Cancel" onClick={handleCloseSave}>
              Cancel
            </TailwindButtonOutline>
            <TailwindButton text="Save" onClick={save_npv_data}>
              Save
            </TailwindButton>
          </div>
        </DialogActions>
      </Dialog>
      <div className="grid gap-2 grid-cols-3 calculator-wrapper">
        <div className="col-span-1 md:col-span-3 lg:hidden flex md:flex-row pt-6 h-full">
          <button
            type="button"
            onClick={() => setOpen(true)}
            className="inline-flex  items-center px-2 py-1 m-1 border border-transparent text-xs font-bold rounded shadow-sm text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-400"
          >
            &nbsp;Save&nbsp;
          </button>

          <div className="py-1"></div>
          <button
            type="button"
            onClick={reset}
            className="inline-flex  items-center px-2 py-1 m-1 border border-transparent text-xs font-bold rounded shadow-sm text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-400"
          >
            Reset
          </button>
          <div className="py-1"></div>

          <button
            type="button"
            onClick={exportPDFWithComponent}
            className="inline-flex items-center px-4 py-1 m-1 border border-transparent text-xs font-bold rounded shadow-sm text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-400"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="white"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
              />
            </svg>
          </button>
          <button
            type="button"
            onClick={exportPDFWithComponent}
            className="inline-flex items-center px-4 py-1 m-1 border border-transparent text-xs font-bold rounded shadow-sm text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-400"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="white"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
              />
            </svg>
          </button>
        </div>
        <div className="col-span-2  md:col-span-12 lg:col-span-2 bg-white shadow-md rounded-sm min-h-[380px] h-fit m-h-80">
          {formValues && (
            <form className={classes.root} noValidate autoComplete="off">
              <div className="full-width grid gap-2 grid-cols-12 px-1 py-2">
                <div className="col-span-3 lg:col-span-3 md:col-span-4 h-full">
                  <p className="tailwind-subtitle-grey mb-2">
                    General Assumptions
                  </p>

                  <div className="flex flex-row  py-1">
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                        style: { fontSize: 10 },
                      }}
                      size="small"
                      name="mine_life"
                      // className='mr-1'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Years</InputAdornment>
                        ),
                        style: { fontSize: 10 },
                      }}
                      label="Mine Life"
                      type="number"
                      variant="outlined"
                      value={formValues.mine_life}
                      onChange={handleInputChange}
                    />

                    <TextField
                      InputLabelProps={{
                        shrink: true,
                        style: { fontSize: 10 },
                      }}
                      size="small"
                      name="discount_rate"
                      // className='ml-1'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        style: { fontSize: 10 },
                      }}
                      label="Discount Rate"
                      type="number"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      variant="outlined"
                      value={formValues.discount_rate}
                      onChange={handleInputChange}
                    />
                  </div>
                  <p className="tailwind-subtitle-grey my-1">
                    Royalties and Taxes
                  </p>
                  <div className="flex flex-row py-1">
                    <div className="w-full">
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: 10 },
                        }}
                        size="small"
                        name="royalties"
                        // className='mr-1'
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                          style: { fontSize: 10 },
                        }}
                        label="Royalties"
                        type="number"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        variant="outlined"
                        value={formValues.royalties}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row py-1">
                    <div className="w-full">
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: 10 },
                        }}
                        size="small"
                        name="tax_rate"
                        // className='mr-1'
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                          style: { fontSize: 10 },
                        }}
                        label="Tax Rate"
                        type="number"
                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                        variant="outlined"
                        value={formValues.tax_rate}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <p className="tailwind-subtitle-grey my-2">
                    Capital Expenditures
                  </p>
                  <div className="flex flex-row ">
                    <div>
                      <TextField
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: 10 },
                        }}
                        size="small"
                        name="capital_expenditures"
                        // className='mr-1'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          style: { fontSize: 10 },
                          inputComponent: NumberFormatCustom,
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        label="Capital Expenditures"
                        // type="number" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        variant="outlined"
                        value={formValues.capital_expenditures}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-5 h-full">
                  <p className="tailwind-subtitle-grey mb-2">
                    Mineral Production & Price Assumptions
                  </p>
                  {metalsnewCase?.map((min, index) => {
                    return (
                      <div key={index} className="flex flex-row  py-1">
                        <div className="w-[50%] pr-1">
                          <TextField
                            InputLabelProps={{
                              shrink: true,
                              style: { fontSize: 10 },
                            }}
                            InputProps={{ style: { fontSize: 10 } }}
                            size="small"
                            name={min.mineral}
                            disabled
                            label="Mineral"
                            type="text"
                            variant="outlined"
                            value={min.mineral}
                            onChange={(e) =>
                              handleMetalInputChange(min.mineral, e, index)
                            }
                          />
                        </div>

                        <TextField
                          InputLabelProps={{
                            shrink: true,
                            style: { fontSize: 10 },
                          }}
                          size="small"
                          name="metric"
                          label="LOM Production"
                          // type="number" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                          variant="outlined"
                          value={min.production}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {min.uom}
                              </InputAdornment>
                            ),
                            style: { fontSize: 10 },
                            inputComponent: NumberFormatCustom,
                          }}
                          onChange={(e) =>
                            handleMetalInputChange(min.mineral, e, index)
                          }
                        />
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                            style: { fontSize: 10 },
                          }}
                          size="small"
                          name={`price`}
                          // className='ml-1'
                          label="Price"
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          variant="outlined"
                          value={min.price}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                USD/{min.uom}
                              </InputAdornment>
                            ),
                            style: { fontSize: 10 },
                          }}
                          onChange={(e) =>
                            handleMetalInputChange(min.mineral, e, index)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="col-span-3 h-full">
                  <p className="tailwind-subtitle-grey mb-2">Operating Costs</p>
                  <div className="flex flex-row py-1 ">
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                        style: { fontSize: 10 },
                      }}
                      InputProps={{ style: { fontSize: 10 } }}
                      size="small"
                      name="mining_costs"
                      // className='mr-1'

                      label="Mining Costs"
                      type="number"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      variant="outlined"
                      value={formValues.mining_costs}
                      onChange={handleInputChange}
                    />

                    <TextField
                      InputLabelProps={{
                        shrink: true,
                        style: { fontSize: 10 },
                      }}
                      InputProps={{ style: { fontSize: 10 } }}
                      size="small"
                      select
                      name="mining_costs_type"
                      variant="outlined"
                      value={formValues.mining_costs_type}
                      onChange={handleInputChange}
                    >
                      <MenuItem key="USD/t mined" value="USD/t mined">
                        USD/t mined
                      </MenuItem>
                      <MenuItem key="USD/t milled" value="USD/t milled">
                        USD/t milled
                      </MenuItem>
                    </TextField>
                  </div>
                  <div className="flex flex-row py-1">
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                        style: { fontSize: 10 },
                      }}
                      InputProps={{ style: { fontSize: 10 } }}
                      size="small"
                      name="processing_costs"
                      // className='mr-1'

                      label="Processing Costs"
                      type="number"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      variant="outlined"
                      value={formValues.processing_costs}
                      onChange={handleInputChange}
                    />

                    <TextField
                      InputLabelProps={{
                        shrink: true,
                        style: { fontSize: 10 },
                      }}
                      InputProps={{ style: { fontSize: 10 } }}
                      size="small"
                      select
                      name="processing_costs_type"
                      variant="outlined"
                      value={formValues.processing_costs_type}
                      onChange={handleInputChange}
                    >
                      <MenuItem key="USD/t mined" value="USD/t mined">
                        USD/t mined
                      </MenuItem>
                      <MenuItem key="USD/t milled" value="USD/t milled">
                        USD/t milled
                      </MenuItem>
                    </TextField>
                  </div>
                  <div className="flex flex-row  py-1">
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                        style: { fontSize: 10 },
                      }}
                      InputProps={{ style: { fontSize: 10 } }}
                      size="small"
                      name="g_a_costs"
                      // className='mr-1'

                      label="G & A Costs"
                      type="number"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      variant="outlined"
                      value={formValues.g_a_costs}
                      onChange={handleInputChange}
                    />

                    <TextField
                      InputLabelProps={{
                        shrink: true,
                        style: { fontSize: 10 },
                      }}
                      InputProps={{ style: { fontSize: 10 } }}
                      size="small"
                      select
                      className="your-label-class"
                      name="g_a_costs_type"
                      variant="outlined"
                      value={formValues.g_a_costs_type}
                      onChange={handleInputChange}

                      // inputProps={{ style: { fontSize: 12 } }}
                    >
                      <MenuItem key="USD/t mined" value="USD/t mined">
                        USD/t mined
                      </MenuItem>
                      <MenuItem key="USD/t milled" value="USD/t milled">
                        USD/t milled
                      </MenuItem>
                    </TextField>
                  </div>
                  <div className="py-1 ">
                    {/* <NumberFormat

                      value={formValues.mined_ore}
                      name={"mined_ore"}
                      suffix="t"
                      customInput={TextField}


                      type="text"
                      thousandSeparator={true}
                      onValueChange={({ value: v }) => handleInputChange({ target: { name, value: v } })}
                    /> */}

                    <TextField
                      InputLabelProps={{
                        shrink: true,
                        style: { fontSize: 10 },
                      }}
                      size="small"
                      name="mined_ore"
                      // className='mr-1'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">t</InputAdornment>
                        ),
                        style: { fontSize: 10 },
                        inputComponent: NumberFormatCustom,
                      }}
                      label="Mined ore"
                      // type="number" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      variant="outlined"
                      value={formValues.mined_ore}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="py-1">
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                        style: { fontSize: 10 },
                      }}
                      size="small"
                      name="processed_ore"
                      // className='mr-1'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ fontSize: 10 }}
                          >
                            t
                          </InputAdornment>
                        ),
                        style: { fontSize: 10 },
                        inputComponent: NumberFormatCustom,
                      }}
                      label="Processed Ore"
                      // type="number" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      variant="outlined"
                      value={formValues.processed_ore}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-span-1 md:hidden lg:block lg:col-span-1 flex md:flex-row pt-6 h-full">
                  <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="inline-flex  items-center px-2 py-1 m-1 border border-transparent text-xs font-bold rounded shadow-sm text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-400"
                  >
                    &nbsp;Save&nbsp;
                  </button>

                  <div className="py-1"></div>
                  <button
                    type="button"
                    onClick={reset}
                    className="inline-flex  items-center px-2 py-1 m-1 border border-transparent text-xs font-bold rounded shadow-sm text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-400"
                  >
                    Reset
                  </button>
                  <div className="py-1"></div>

                  <button
                    type="button"
                    onClick={exportPDFWithComponent}
                    className="inline-flex items-center px-4 py-1 m-1 border border-transparent text-xs font-bold rounded shadow-sm text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-400"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                      />
                    </svg>
                  </button>
                  <button
                    type="button"
                    onClick={exportPDFWithComponent}
                    className="inline-flex items-center px-4 py-1 m-1 border border-transparent text-xs font-bold rounded shadow-sm text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-400"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
        <div className=" media-chart col-span-1 md:col-span-12 lg:col-span-1 bg-white shadow-md h-[380px] md:flex md:flex-row  md:justify-center  rounded-sm m-h-full">
          {chart_data && (
            <>
              {chartView === "waterfall" ? (
                <WaterfallChart
                  chart_data={chart_data}
                  isBaseCase={isBaseCase}
                  setchartView={setchartView}
                  base_data={baseData}
                />
              ) : (
                <GaugeChart
                  isBaseCase={isBaseCase}
                  setchartView={setchartView}
                  chart_data={chart_data}
                  base_data={baseData}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.authReducer.selectedProject,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InputForm);
