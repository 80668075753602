/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import RangeSlider from "./RangeSlider";
import { connect } from "react-redux";

import {
  Primary,
  Headline5,
  Button,
  Subtitle1,
  Subtitle2,
  BrowseListFilter,
  NotifyDiv,
  Headline6,
  TailwindButton,
  TailwindButtonOutline,
} from "../../../../components/general";
import CloseIcon from "@material-ui/icons/Close";
import {
  searchAlgoliaCompanies,
  filterProjects,
  filterCompanies,
  setProjectStartDate,
  apiRequest,
} from "../../../../store/Actions/AuthActions";
import {
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Exchanges, Period_range } from "../../../../configure/constants";
import Select from "react-select";
import axios from "axios";
import _ from "lodash";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../../assets/ProspectorDesignAssets/lottie2.json";

import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 100,
  },
}));
const continents = [
  "Asia",
  "North America",

  "Latin America",
  "Africa",
  "Antarctica",
  "Europe",
  "Oceania",
];
const prices = [];
for (let i = 0; i < 500; i++) {
  prices.push(Math.floor(Math.random() * 80) + 1);
}
const customStyles = {
  control: (base) => ({
    ...base,
    fontSize: "x-large",

    minHeight: "50px",
    maxHeight: "80px",
    overflowY: "auto",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
    };
  },
};
const periods = ["1M", "3M", "1Y", "5Y", "ALL"];

export const FilterContent = ({
  setProjectStartDate,
  setCompanyPage,
  projects_length,
  map_projects,
  companies_length,
  projects_loading,
  setProjectPage,
  projects,
  companies,
  searchAlgoliaCompanies,
  auth,
  tab,
  handleClose,
  filterProjects,
  setProjectFilters,
  setCompanyFilters,
  project_filters,
  company_filters,
  filterCompanies,
  project_start_after,
}) => {
  let me = auth.uid;
  const [value, setValue] = React.useState([20, 37]);
  const [extraMinerals, setMinerals] = useState([]);
  const [extraRegions, setRegions] = useState([]);
  const [mineralsObj, setMineralsObj] = useState([]);
  const [regionsObj, setRegionsObj] = useState([]);
  const [showextraRegions, showRegions] = useState(false);
  const [showextraMinerals, showMinerals] = useState(false);
  const classes = useStyles();
  const [selectedMineral, setSelectedMineral] = useState([]);

  useEffect(() => {
    getFilterInfo();
    setPeriod(
      Object.keys(Period_range).find(
        (key) => Period_range[key] === project_start_after
      )
    );
    if (tab === "projects") {
      let fils = project_filters.mineral?.map((tag) => ({
        value: tag,
        label: tag,
      }));

      updateFilters(
        project_filters || {
          mineral: null,
          primary_metal: null,
          start_after: null,
          searchTerm: null,
          regions: null,
          countries: null,
          stage: null,
          resourceType: null,
          averageGrade_min: 0,
          averageGrade_max: 0,
          oreTonnage_min: 0,
          oreTonnage_max: 0,
          containedMetal_min: 0,
          containedMetal_max: 0,
          resource_measurements: null,
        }
      );
      // search_projects({loc:0,newFilters:filters})
    } else {
      updateFilters(
        company_filters || {
          mineral: null,
          primary_metal: null,
          start_after: null,
          searchTerm: null,
          regions: null,
          countries: null,
          stage: null,
          resourceType: null,
          averageGrade_min: 0,
          averageGrade_max: 0,
          oreTonnage_min: 0,
          oreTonnage_max: 0,
          containedMetal_min: 0,
          containedMetal_max: 0,
          resource_measurements: null,
        }
      );
      // search_companies({loc:0,newFilters:filters})
    }
    return () => {};
  }, []);

  const getFilterInfo = async () => {
    const countries_data = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/countries`
    );
    const minerals_data = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/minerals`
    );
    if (minerals_data) {
      console.log("wtf");
      setMinerals([
        ...new Set(minerals_data.data.data.map((item) => item.name)),
      ]);
    }
    if (countries_data) {
      setRegions([
        ...continents,
        ...countries_data.data.data.map((item) => item.name).sort(),
      ]);
      // var ctry = countries_data.data?.data.map(function (item) {
      //   return item["name"];
      // });
      // setRegions([...continents, ...ctry.sort()]);
      setMineralsObj(countries_data.data?.data);
    }
    if (minerals_data) {
      var min = minerals_data.data?.data.map(function (item) {
        return item["name"];
      });
      setMinerals([...new Set(min)]);
      setMineralsObj(minerals_data.data?.data);
    }
  };
  const [filters, updateFilters] = useState({
    mineral: null,
    primary_metal: null,
    start_after: null,
    searchTerm: null,
    regions: null,
    countries: null,
    resourceType: null,
    averageGrade_min: 0,
    averageGrade_max: 0,
    oreTonnage_min: 0,
    oreTonnage_max: 0,
    containedMetal_min: 0,
    containedMetal_max: 0,
    resource_measurements: null,
  });
  const setFilters = (filters) => {
    updateFilters(filters);
  };

  // const companiesIndex = searchClient.initIndex('dev_companies_idx')
  // const projectsIndex = searchClient.initIndex('dev_projects_idx')
  // let company_hit_list = []
  // let project_hit_list = []

  const search_companies = ({ loc, newFilters }) => {
    setCompanyPage(0);
    filterCompanies();
  };
  const search_projects = () => {
    setProjectPage(0);
    filterProjects();
  };
  const debouncedChangeHandler = useCallback(
    _.debounce(function () {
      setProjectPage(0);
      filterProjects();
    }, 1000),
    []
  );
  function numFormatter(num) {
    if (num > 999) {
      return (num / 1000000).toFixed(1) + " Million"; // convert to M for number from > 1 million
    } else {
      return num; // if value < 1000, nothing to do
    }
  }

  const updateSlider = (name, val) => {
    let newFilters = { ...filters };
    newFilters[`${name}_min`] = val[0] * 1000000;
    newFilters[`${name}_max`] = val[1] * 1000000;
    setFilters(newFilters);
    if (tab === "projects") {
      setProjectFilters(newFilters);
      search_projects();
    } else {
      setCompanyFilters(newFilters);
      search_companies();
    }
  };
  const updateAverageGrade = (name, val) => {
    let newFilters = { ...filters };
    newFilters[`${name}_min`] = val[0];
    newFilters[`${name}_max`] = val[1];

    setFilters(newFilters);
    if (tab === "projects") {
      setProjectFilters(newFilters);
      search_projects();
    } else {
      setCompanyFilters(newFilters);
      search_companies();
    }
  };
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const resTypes = [
    {
      name: "Indicated Mineral Resource",
      value: 85,
      indicator: "indicated",
    },
    {
      name: "Inferred Mineral Resource",
      value: 84,
      indicator: "inferred",
    },
    {
      name: "Measured Mineral Resource",
      value: 86,
      indicator: "measured",
    },
    {
      name: "Not Mentioned",
      value: 89,
      indicator: "not mentioned",
    },
    {
      name: "Probable Mineral Reserve",
      value: 87,
      indicator: "probable",
    },

    {
      name: "Proven Mineral Reserve",
      value: 88,
      indicator: "proven",
    },
  ];
  const stages = [
    "Grassroots",
    "Target Drilling",
    "Discovery Delineation",
    "PEA",
    "Prefeasibility",
    "Permitting & Feasibility",
    "Construction",
    "Production",
    "Suspended",
  ];

  const filterMinerals = [
    "cobalt",
    "copper",
    "gold",
    "rare earth elements",
    "lithium",
    "silver",
    "nickel",
    "palladium",
  ];
  const updateSingleFilterKey = (name, value) => {
    let newFilters = { ...filters };

    if (JSON.stringify(newFilters[name]) === JSON.stringify(value)) {
      newFilters[name] = null;
      setFilters(newFilters);
      setProjectFilters(newFilters);
      if (name === "searchTerm") {
        debouncedChangeHandler();
      } else {
        search_projects();
      }
    } else {
      newFilters[name] = value;
      setFilters(newFilters);
      setProjectFilters(newFilters);
      if (name === "searchTerm") {
        debouncedChangeHandler();
      } else {
        search_projects();
      }
    }

    if (name === "mineral") {
      showMinerals(false);
      let minerals = [];
      mineralsObj?.map((item) => {
        if (Array.isArray(value)) {
          value?.map((v) => {
            if (item?.name === v) {
              minerals.push(item);
            }
          });
        }
      });
      setSelectedMineral(minerals);
    }
  };
  const updateSingleFilterKeynonselect = (name, value) => {
    let newFilters = { ...filters };

    if (newFilters[name] && newFilters[name].length) {
      let index = newFilters[name].indexOf(value);
      if (index !== -1) {
        newFilters[name].splice(index, 1);
        setFilters(newFilters);
        if (tab === "projects") {
          setProjectFilters(newFilters);
          search_projects();
        } else {
          setCompanyFilters(newFilters);
          search_companies();
        }
      } else {
        newFilters[name].push(value);
        setFilters(newFilters);
        if (tab === "projects") {
          setProjectFilters(newFilters);
          search_projects();
        } else {
          setCompanyFilters(newFilters);
          search_companies();
        }
      }
    } else {
      newFilters[name] = [value];
      setFilters(newFilters);
      if (tab === "projects") {
        setProjectFilters(newFilters);
        search_projects();
      } else {
        setCompanyFilters(newFilters);
        search_companies();
      }
    }
  };
  const clearFilters = () => {
    let newFilters = {
      mineral: null,
      primary_metal: null,
      start_after: null,
      searchTerm: null,
      countries: null,
      exchange: null,
      resourceType: null,
      averageGrade_min: 0,
      averageGrade_max: 0,
      oreTonnage_min: 0,
      oreTonnage_max: 0,
      containedMetal_min: 0,
      containedMetal_max: 0,
    };
    if (tab === "projects") {
      setProjectFilters(newFilters);
      search_projects();
      updateFilters(newFilters);
      // handleClose()
    } else {
      setCompanyFilters(newFilters);
      search_companies();
      setFilters(newFilters);

      updateFilters(newFilters);
      // handleClose()
    }
  };
  const [period, setPeriod] = useState("3M");
  const switchPeriod = (period) => {
    setPeriod(period);
    let range = period.includes("1W")
      ? 7
      : period === "ALL"
      ? 30000
      : period === "1D"
      ? 1
      : period === "1M"
      ? 30
      : period === "3M"
      ? 90
      : period === "6M"
      ? 180
      : period === "2Y"
      ? 730
      : period === "3Y"
      ? 1095
      : period === "5Y"
      ? 1780
      : period === "1Y"
      ? 400
      : 90;
    setProjectStartDate(range);
    setProjectPage(1);
    filterProjects();
  };
  const change_text = (e) => {};
  const projectChange = (e, value) => {};

  return (
    <div
      className="flex-boy-column h-[calc(100vh_-_80px)] md:h-[calc(100vh_-_160px)]"
      style={{ width: "100%" }}
    >
      <div
        className="flex-boy-row"
        style={{
          width: "100%",
          justifyContent: "normal",
          padding: "20px",
          boxSizing: "border-box",
          textAlign: "left",
        }}
      >
        <Headline5 style={{ flex: 1 }}>Advanced project search</Headline5>
      </div>
      <div
        className="flex-boy-column"
        style={{
          padding: "10px",
          marginBottom: "75px",
          flex: 1,
          justifyContent: "normal",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "left",
            padding: "20px",
            paddingRight: "30px",
            boxSizing: "border-box",
          }}
        >
          <TextField
            id="outlined-basic"
            fullWidth
            label=""
            variant="outlined"
            onChange={(e) =>
              updateSingleFilterKey("searchTerm", e.target.value)
            }
            placeholder="Search by project name"
          />
        </div>
        <div
          className="flex-boy-row flex-boy-row-exchanges"
          style={{
            width: "100%",
            textAlign: "left",
            padding: "20px",
            paddingTop: "0",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              flex: 1,
              boxSizing: "border-box",
              padding: "10px",
              paddingLeft: "0",
              height: "80px",
              zIndex: 11,
            }}
          >
            <Select
              isMulti
              displayEmpty
              value={
                filters?.exchange
                  ?.filter((tag) => tag !== "South america")
                  .map((tag) => ({ value: tag, label: tag })) || ""
              }
              styles={customStyles}
              // MenuProps={{ classes: { paper: classes.menuPaper } }}
              onChange={(e) =>
                updateSingleFilterKey(
                  "exchange",
                  e.map((item) => item.value)
                )
              }
              placeholder={"All Exchanges"}
              options={Exchanges.map((tag, index) => ({
                value: tag.trim(),
                label: tag,
              }))}
            />
          </div>
          <div
            style={{
              flex: 1,
              boxSizing: "border-box",
              padding: "10px",
              paddingLeft: "0",
              height: "80px",
              zIndex: 11,
            }}
          >
            <Select
              isMulti
              value={
                filters?.countries
                  ?.filter((tag) => tag !== "South america")
                  .map((tag) => ({ value: tag, label: tag })) || ""
              }
              styles={customStyles}
              onChange={(e) =>
                updateSingleFilterKey(
                  "countries",
                  e.map((item) => item.value)
                )
              }
              placeholder={"Search for region or country"}
              options={extraRegions.map((tag, index) => ({
                value: tag,
                label: tag.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase()
                ),
              }))}
            />
          </div>
        </div>

        <div
          style={{
            width: "100%",
            textAlign: "left",
            padding: "20px",
            boxSizing: "border-box",
            zIndex: 10,
          }}
        >
          <div
            style={{
              flex: 1,
              boxSizing: "border-box",
              padding: "10px 10px 10px 0",
              paddingLeft: "0",
              height: "80px",
              zIndex: 10,
            }}
          >
            <Select
              isMulti
              displayEmpty
              value={
                filters?.primary_metal?.map((tag) => ({
                  value: tag,
                  label: tag,
                })) || ""
                // {
                //   value: selectedMineral?.chemical_symbol,
                //   label: selectedMineral?.name,
                // }
              }
              styles={customStyles}
              // MenuProps={{ classes: { paper: classes.menuPaper } }}
              onChange={(e) =>
                updateSingleFilterKey(
                  "primary_metal",
                  e.map((item) => item.label)
                  // e.label
                )
              }
              placeholder={"Search for Primary Metal"}
              options={mineralsObj?.map((m, index) => ({
                value: m?.chemical_symbol?.trim(),
                label: m?.name?.trim(),
              }))}
            />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "left",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <Headline6>Minerals</Headline6>

          <div className="flex-boy-row flex-boy-row-minerals">
            <div
              style={{
                flex: 1,
                boxSizing: "border-box",
                padding: "10px 10px 10px 0",
                paddingLeft: "0",
                height: "80px",
                zIndex: 9,
              }}
            >
              <Select
                isMulti
                displayEmpty
                value={
                  filters?.mineral?.map((tag) => ({
                    value: tag,
                    label: tag,
                  })) || ""
                  // {
                  //   value: selectedMineral?.chemical_symbol,
                  //   label: selectedMineral?.name,
                  // }
                }
                styles={customStyles}
                // MenuProps={{ classes: { paper: classes.menuPaper } }}
                onChange={(e) =>
                  updateSingleFilterKey(
                    "mineral",
                    e.map((item) => item.label)
                    // e.label
                  )
                }
                placeholder={"Search for mineral"}
                options={mineralsObj?.map((m, index) => ({
                  value: m?.chemical_symbol?.trim(),
                  label: m?.name?.trim(),
                }))}
              />
            </div>
            <div
              style={{
                flex: 1,
                boxSizing: "border-box",
                padding: "10px 10px 10px 0",
                height: "80px",
                zIndex: 9,
              }}
            >
              <Select
                isMulti
                displayEmpty
                value={
                  filters?.resourceType?.map((tag) => ({
                    value: tag,
                    label: tag,
                  })) || ""
                }
                styles={customStyles}
                // MenuProps={{ classes: { paper: classes.menuPaper } }}
                onChange={(e) =>
                  updateSingleFilterKey(
                    "resourceType",
                    e.map((item) => item.value)
                  )
                }
                placeholder={"All Mineral Estimate Levels"}
                options={resTypes.map((tag, index) => ({
                  value: tag.name.trim(),
                  label: tag.name,
                }))}
              />
            </div>
          </div>
        </div>

        {/* <div className="self-end">
          {filters?.mineral?.length > 0
            ? selectedMineral?.map((m, i) => (
                <RangeSlider
                  data={prices}
                  mineral={m}
                  updateSingleFilterKey={updateSingleFilterKey}
                  index={i}
                />
              ))
            : ""}
        </div> */}

        <div
          style={{
            width: "100%",
            textAlign: "left",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <Headline6 style={{ marginBottom: "10px" }}>
            Last Update on Project
          </Headline6>
          <div className="flex-boy-row">
            {periods.map((time, index) => {
              return (
                <div
                  key={index}
                  onClick={() => switchPeriod(time)}
                  className="period-map flex-boy-column"
                  style={{
                    color: time === period ? "white" : Primary,
                    backgroundColor: time === period ? Primary : "white",
                  }}
                >
                  <Headline6 color={time === period ? "white" : Primary}>
                    {time}
                  </Headline6>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="rangeFilterContainer"
          style={{ textAlign: "left", marginBottom: "20px" }}
        >
          <Headline6 style={{ margin: "10px" }}>Project Stage</Headline6>
          <div
            className="flex-boy-row flex-boy-row-project-stage"
            style={{ flexWrap: "wrap", justifyContent: "normal" }}
          >
            {stages.map((key, index) => {
              return (
                <div
                  className={`${
                    filters.stage && filters.stage.includes(key)
                      ? "active-fiter-item"
                      : null
                  } stage-card flex-boy-row`}
                  onClick={() => updateSingleFilterKeynonselect("stage", key)}
                  key={index}
                >
                  <img
                    src={require(`../../../../assets/stages/${key}.png`)}
                    alt="stage"
                  />
                  <Subtitle2
                    style={{ margin: "5px", textTransform: "capitalize" }}
                  >
                    {key.replace("_", " ")}
                  </Subtitle2>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className="flex-boy-row"
        style={{
          height: "75px",
          width: "100%",
          padding: "20px",
          boxSizing: "border-box",
          position: "absolute",
          bottom: "20px",
          backgroundColor: "white",
        }}
      >
        <TailwindButtonOutline onClick={clearFilters} text="Clear Filters" />

        {projects_loading ? (
          <div
            className="flex-boy-row"
            style={{ marginRight: "40px", marginBottom: "30px" }}
          >
            <Player
              autoplay
              loop
              src={animationData}
              style={{ height: "100px", width: "100px" }}
            >
              {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
            </Player>

            <Subtitle1>Loading</Subtitle1>
          </div>
        ) : (
          <TailwindButton
            onClick={handleClose}
            text={`Show ${projects_length} projects`}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  project_filters: state.authReducer.projects_search_filters,
  projects_loading: state.authReducer.projects_loading,
  companies_search: state.authReducer.companies_search || [],
  projects: state.authReducer.projects_search || [],
  map_projects:
    state.authReducer.map_projects.filter(
      (item) => item.lat !== 0 && item.lng !== 0
    ) || [],
  company_filters: state.authReducer.company_search_filters,
  projects_length: state.authReducer.filtered_projects_length,
  companies_length: state.authReducer.filtered_companies_length,
  project_start_after: state.authReducer.project_start_after,
});

const mapDispatchToProps = (dispatch) => ({
  setProjectStartDate: (data) =>
    dispatch({ type: "SET_PROJECT_START_DATE", payload: data }),
  searchAlgoliaCompanies: (data) => dispatch(searchAlgoliaCompanies(data)),
  filterProjects: (data) => dispatch(filterProjects(data)),
  filterCompanies: (data) => dispatch(filterCompanies(data)),
  setCompanyPage: (data) =>
    dispatch({ type: "SET_COMPANIES_PAGE", payload: data }),
  setProjectPage: (data) =>
    dispatch({ type: "SET_PROJECTS_PAGE", payload: data }),
  setProjectFilters: (data) =>
    dispatch({ type: "SET_PROJECT_FILTERS", payload: data }),
  setCompanyFilters: (data) =>
    dispatch({ type: "SET_COMPANY_FILTERS", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterContent);
