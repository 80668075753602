/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { HashRouter, BrowserRouter as Router } from "react-router-dom";
import { createHashHistory } from "history";
import { syncHistoryWithStore } from "react-router-redux";
import Logo from "./assets/ProspectorDesignAssets/Prospector_3D Logo_Icon.png";
import configureStore from "./configure/configureStore";
import { requestFirebaseNotificationPermission } from "./configure/configureStore";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";

import firebase from "firebase/app";
import { getFirebase } from "react-redux-firebase";
import "firebase/auth";
import "firebase/firestore";
import { createFirestoreInstance } from "redux-firestore";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import animationData from "../src/assets/ProspectorDesignAssets/lottie2.json";
import "./ag-grid-theme-builder.css";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

const iFrameDetection = window === window.parent ? false : true;
function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth) && !iFrameDetection)
    return (
      <div
        className="flex-boy-row "
        style={{
          height: "100vh",
          width: "100vw",
          justifyContent: "center",
          overflow: "hidden",
          zIndex: 2000,
          position: "fixed",
          backgroundColor: "white",
        }}
      >
        {/* <img style={{ height: '30vh' }} src={logoImg} alt="logo" /> */}
        {/* <lottie-player src={"https://lottiefiles.com/share/xvzv1hhl"} background="transparent" speed="1" style="width: 300px; height: 300px;" loop autoplay></lottie-player> */}
        <Player
          autoplay
          loop
          src={animationData}
          style={{ height: "300px", width: "300px" }}
        >
          {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
        </Player>
      </div>
    );
  return children;
}

const browserhistory = createHashHistory();

const { Store, persistor } = configureStore(browserhistory);
const history = syncHistoryWithStore(browserhistory, Store);

export const rrfConfig = {
  userProfile: "customers",
  useFirestoreForProfile: true,
};
const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: Store.dispatch,
  createFirestoreInstance, //since we are using Firestore
};

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("../public/firebase-messaging-sw.js")
    .then(function (registration) {})
    .catch(function (err) {});
}
const options = {
  // passing the client secret obtained from the server
  // clientSecret: '{{CLIENT_SECRET}}',
};

ReactDOM.render(
  <Provider store={Store}>
    <PersistGate persistor={persistor}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <HashRouter mem={history} history={history}>
          <AuthIsLoaded>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <App />
            </MuiPickersUtilsProvider>
          </AuthIsLoaded>
        </HashRouter>
      </ReactReduxFirebaseProvider>
    </PersistGate>
  </Provider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
