import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Map, { Marker, NavigationControl } from "react-map-gl";
import {
  filterProjects,
  searchAlgoliaProjects,
} from "../../store/Actions/AuthActions";
import useSupercluster from "use-supercluster";
import MapMarker from "../MapMarker";
import { easeCubic } from "d3-ease";
import { Body1 } from "../general";
import mapboxgl from "mapbox-gl";
import Geocode from "react-geocode";
import axios from "axios";
import isEqual from "lodash/isEqual";
const iFrameDetection = window === window.parent ? false : true;

const useDeepCompareEffect = (callback, dependencies) => {
  const currentDependenciesRef = useRef();

  if (!isEqual(currentDependenciesRef.current, dependencies)) {
    callback();
  }

  currentDependenciesRef.current = dependencies;
};

export const index = ({ mapfullscreen, projects, project_filters }) => {
  mapboxgl.workerClass =
    require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

  const pageRef = useRef();
  Geocode.setApiKey("AIzaSyDbd9W45ccOJu6b42cLGeusSQYAm9C_Nmg");
  const [viewState, setViewState] = useState({
    width: "100%",
    height: "100%",
    longitude: -122.4,
    latitude: 37.8,
    zoom: 2.1,
  });

  const people = projects ? projects : [];
  const mapRef = useRef();
  const points = people?.map((project) => ({
    type: "Feature",
    properties: { cluster: false, ...project },
    geometry: {
      type: "Point",
      coordinates: [parseFloat(project.lng), parseFloat(project.lat)],
    },
  }));

  // load and prepare data
  // get map bounds
  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;
  // get clusters
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: viewState.zoom,
    options: { radius: 50, maxZoom: 20, minZoom: 0 },
  });
  useEffect(() => {
    spinGlobe();
    return () => {};
  }, []);

  const getCoords = async (term) => {
    const place = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${
        term === "Africa" ? "DRC" : term === "Latin America" ? "paraguay" : term
      }.json?access_token=pk.eyJ1IjoiamdvZGJvdXQxOTg2IiwiYSI6ImNsMnFkYTQ0NDBjenkzZHFhdmo0YmNubHoifQ.tme27pqRr2UnaVnYAabLWg&tyepes=country,region`
    );
    if (place) {
      console.log(place.data?.features[0].geometry.coordinates);
      mapRef.current?.flyTo({
        zoom:
          term === "Africa" || term === "Latin America"
            ? 2
            : mapfullscreen
            ? 2.1
            : 3.5,
        center: place.data?.features[0].geometry.coordinates,
        duration: 2000,
      });
    }
  };
  const fly = () => {
    console.log("flying");
    setTimeout(() => {
      setspinEnabled(false);
      if (projects.length && clusters && mapRef.current) {
        console.log(clusters[0]);
        if (project_filters.countries?.length) {
          getCoords(project_filters.countries?.[0]);
        } else {
          setspinEnabled(true);
        }

        // setViewState({ ...viewState, latitude: clusters.length ? clusters[0].geometry.coordinates[1] : projects[0].lat, longitude: clusters.length ? clusters[0].geometry.coordinates[1] : projects[0].lng });
      } else {
        if (!projects.length && project_filters.countries?.length) {
          getCoords(project_filters.countries?.[0]);
        }
      }
    }, 1000);
  };

  useDeepCompareEffect(() => {
    fly();

    // fly()
    return () => {};
  }, [projects, mapfullscreen]);
  const [spinEnabled, setspinEnabled] = useState(true);
  const [userInteracting, setuseruserInteracting] = useState(false);
  const secondsPerRevolution = 60;
  // Above zoom level 5, do not rotate.
  const maxSpinZoom = 5;
  // Rotate at intermediate speeds between zoom levels 3 and 5.
  const slowSpinZoom = 3;

  const onMapLoad = React.useCallback(() => {
    mapRef.current.on("style.load", () => {
      mapRef.current.setFog({}); // Set the default atmosphere style
    });
  }, []);

  function spinGlobe() {
    const zoom = mapRef.current?.getZoom();
    if (spinEnabled && !userInteracting && zoom < maxSpinZoom) {
      let distancePerSecond = 360 / secondsPerRevolution;
      if (zoom > slowSpinZoom) {
        // Slow spinning at higher zooms
        const zoomDif = (maxSpinZoom - zoom) / (maxSpinZoom - slowSpinZoom);
        distancePerSecond *= zoomDif;
      }

      const center = mapRef.current?.getCenter();
      center.lng -= distancePerSecond;
      // Smoothly animate the map over one second.
      // When this animation is complete, it calls a 'moveend' event.
      mapRef.current?.easeTo({ center, duration: 1000, easing: (n) => n });
    }
  }

  return (
    <div
      className="map p-4"
      style={{
        // position: "fixed",
        height: iFrameDetection ? "100vh" : "calc(100vh - 80px)",
        width: mapfullscreen ? "100%" : iFrameDetection ? "52%" : "51%",
        // width: "100%",
        top: iFrameDetection ? 0 : "80px",
      }}
    >
      <Map
        {...viewState}
        projection={"globe"}
        mapboxAccessToken="pk.eyJ1IjoiamdvZGJvdXQxOTg2IiwiYSI6ImNsMnFkYTQ0NDBjenkzZHFhdmo0YmNubHoifQ.tme27pqRr2UnaVnYAabLWg"
        // initialViewState={{
        //     longitude: -122.4,
        //     latitude: 37.8,
        //     zoom: 2.01
        // }}
        onMove={(newviewState) => {
          setViewState({ ...newviewState.viewState });
        }}
        ref={mapRef}
        style={{ width: "100%", height: "100%" }}
        mapStyle="mapbox://styles/jgodbout1986/cl65bon1s002014n3c1bn3lo2"
        onRender={(event) => event.target.resize()}
        onLoad={onMapLoad}
        onMouseDown={() => {
          console.log("hold");
          setuseruserInteracting(true);
        }}
        onMouseUp={() => {
          console.log("go on");
          setuseruserInteracting(false);
          spinGlobe();
        }}
        onZoomStart={() => setspinEnabled(false)}
        onZoomEnd={() => {
          console.log("go on");
          setspinEnabled(true);
          // spinGlobe()
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;

          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                latitude={latitude}
                longitude={longitude}
              >
                <div
                  className="cluster-marker"
                  style={{
                    width: `${40 + (pointCount / points?.length) * 20}px`,
                    height: `${40 + (pointCount / points?.length) * 20}px`,
                  }}
                  onClick={() => {
                    console.log("zoomed");
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      30
                    );
                    setspinEnabled(false);
                    mapRef.current?.flyTo({
                      zoom: expansionZoom,
                      center: [longitude, latitude],
                      duration: 2000,
                    });
                    // setspinEnabled(true)
                    // setviewState({
                    //     ...viewState,
                    //     latitude,
                    //     longitude,

                    //     transitionDuration: 2500,
                    //     transitionInterpolator: new FlyToInterpolator(), transitionEasing: easeCubic
                    // });
                  }}
                >
                  {pointCount}
                </div>
              </Marker>
            );
          }

          return (
            <Marker
              key={`project-${cluster.properties.id}`}
              latitude={latitude}
              longitude={longitude}
              // rotationAlignment='horizon'
            >
              <div className="h-5 w-8">
                <MapMarker
                  setspinEnabled={setspinEnabled}
                  project={cluster.properties}
                />
              </div>
            </Marker>
          );
        })}
        <NavigationControl />
      </Map>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  projects:
    state.authReducer.map_projects.filter(
      (item) => item.lat !== 0 && item.lng !== 0
    ) || [],
  projects_loading: state.authReducer.projects_loading,
  project_filters: state.authReducer.projects_search_filters,
});

const mapDispatchToProps = (dispatch) => ({
  searchAlgoliaProjects: (data) => dispatch(searchAlgoliaProjects(data)),
  setMapBounds: (data) => dispatch({ type: "SET_MAP_BOUNDS", payload: data }),
  filterProjects: (data) => dispatch(filterProjects()),
  setProjectPage: (data) =>
    dispatch({ type: "SET_PROJECTS_PAGE", payload: data }),
  setHoverProject: (data) =>
    dispatch({ type: "SET_HOVER_PROJECT", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
