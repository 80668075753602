import React from "react";
import { connect } from "react-redux";
import { MainPage } from "../../components";
import BillingInfo from "./Components/BillingInfo";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_live_51HNK6nJY2VsK2dNdtQ1IAI2H3NLTZB6vyc4eP7JPJ92Zhfhe9pR2X2JustXf0elrxU0HoxNst5YmdO6Y2Ch8Pc9I00iXLB4yfV"
);

const options = {
  // passing the client secret obtained in step 3
  // clientSecret: '{{CLIENT_SECRET}}',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};
export const index = (props) => {
  return (
    <MainPage id="account">
      <Elements stripe={stripePromise} options={options}>
        <BillingInfo />
      </Elements>
    </MainPage>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.authReducer.activeUser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(index);
