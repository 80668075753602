/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {
  Subtitle1,
  Headline5,
  Subtitle2,
  Headline6,
  Button,
  NotifyDiv,
  Primary,
} from "../../../components";
import { TextField } from "@material-ui/core";
import Logo from "../../../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon.png";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CancelIcon from "@material-ui/icons/Cancel";
import { updateSignUpDetails } from "../../../store/Actions/AuthActions";
import { searchClient } from "../../../configure/constants";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
} from "react-instantsearch-dom";
import Avatar from "react-avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00AC69",
        border: "2px solid",
      },
      "&:hover fieldset": {
        borderColor: "#00AC69",
      },
    },
  },
}));

const HitComp = ({ hit: { hit }, setCompanyName }) => (
  <div
    onClick={() => setCompanyName(hit.id, hit.companyName)}
    style={{ width: "100%", justifyContent: "normal", cursor: "pointer" }}
    className="flex-boy-row"
  >
    <Avatar name={hit.companyName} round color={Primary} size={25} />
    <Subtitle1 style={{ marginLeft: "20px" }}>
      <Highlight attribute="companyName" hit={hit} tagName="mark" />
    </Subtitle1>
  </div>
);

export const Page4 = ({ swapAuthPage, updateSignUpDetails, signUp }) => {
  const classes = useStyles();
  const { type } = signUp;
  const [companyModal, setCompany_list] = useState(false);
  const [go, setContinue] = useState(false);
  const [data, setData] = useState({
    companyName: "",
    companyPosition: "",
    companyId: null,
  });
  const [errors, seterrors] = useState({
    companyName: null,
    companyPosition: null,
  });
  const change_text = (e) => {
    let new_data = { ...data };
    new_data[e.target.name] = e.target.value;
    if (e.target.name === "companyPosition") {
      setContinue(true);
    }
    setData({ ...new_data });
  };
  const setCompanyName = (id, name) => {
    let new_data = { ...data };
    new_data.companyName = name;
    new_data.companyId = id;
    setData({ ...new_data });
    setCompany_list(false);
  };

  const validate = () => {
    let new_data = { ...data };
    let new_errors = { ...errors };
    if (new_data.name === "") {
      new_errors.firstName = "Please select you company";
      seterrors(new_errors);
    } else if (new_data.position === "") {
      new_errors.lastName = "Please enter Your Position at the company";
      seterrors(new_errors);
    } else {
      updateSignUpDetails({ ...data });
      swapAuthPage("Page5");
    }
  };
  const companiesIndex = searchClient.initIndex("dev_companies_idx");
  // const search_text = (e) => {
  //    let val = e.target.value

  //    companiesIndex.search('',{filters:`minerals.name:cu AND nlProjectData.countries:canada`}).then(({hits}) =>
  // }

  return (
    <Fragment>
      <Slide direction="up" in={companyModal} mountOnEnter unmountOnExit>
        <div
          className="company-list"
          style={{ overflowY: "scroll", overflowX: "hidden" }}
        >
          <div className="flex-boy-row" style={{ marginBottom: "10px" }}>
            <KeyboardBackspaceIcon onClick={() => setCompany_list(false)} />
            <Subtitle1>Search for your company</Subtitle1>
            <CancelIcon onClick={() => setCompany_list(false)} />
          </div>
          <InstantSearch
            searchClient={searchClient}
            indexName="dev_companies_idx"
          >
            <SearchBox />
            <Hits
              hitComponent={(hit) => (
                <HitComp setCompanyName={setCompanyName} hit={hit} />
              )}
            />
          </InstantSearch>
          {/* <TextField id="position" name="companyPosition" onChange={search_text} color='primary' style={{ margin: 10,width:300 }} label="Position" placeholder="Enter your official title" variant="outlined" /> */}
        </div>
      </Slide>

      <Headline6 color="#00AC69">
        What company do you work for and what is your title?
      </Headline6>
      <div className="circle-progress">
        <div className="progress">
          <CircularProgress
            size="100%"
            thickness={2}
            variant="determinate"
            value={50}
          />
        </div>

        <img src={Logo} alt="logo" />
      </div>

      <div>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            defaultValue={data.companyName}
            value={data.companyName}
            id="company"
            name="companyName"
            onChange={change_text}
            color="primary"
            style={{ margin: 10, width: 300 }}
            label="Company"
            placeholder={
              type !== "Institution"
                ? "Select from our list of mining companies"
                : "Enter company name"
            }
            variant="outlined"
            onFocus={() =>
              type !== "Institution" ? setCompany_list(true) : null
            }
          />
          <TextField
            id="position"
            name="companyPosition"
            onChange={change_text}
            color="primary"
            style={{ margin: 10, width: 300 }}
            label="Position"
            placeholder="Enter your official title"
            variant="outlined"
          />
        </form>
      </div>
      <Button
        onClick={() => (go ? validate() : null)}
        style={{ width: "80%", backgroundColor: go ? "#00AC69" : "gainsboro" }}
      >
        Continue
      </Button>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  signUp: state.authReducer.signUp,
});

const mapDispatchToProps = (dispatch) => ({
  updateSignUpDetails: (data) => dispatch(updateSignUpDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Page4);
