import React, { useEffect, useState } from "react";
import {
  ComponentContainer,
  Headline5,
  Primary,
  Headline6,
  Subtitle2,
} from "../../../../components";
import Select from "react-select";
import { elements, Exchanges } from "../../../../configure/constants";
import Scatter from "../Scatter";
import Colunm from "../colunm";
import Sankey from "../Sankey";
import { connect, useSelector } from "react-redux";
import MineralResourceDiv from "./MineralResourceDiv";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import CsvDownload from "react-json-to-csv";
import { apiRequest } from "../../../../store/Actions/AuthActions";
import { Tooltip } from "@mui/material";

const customStyles = {
  control: (base) => ({
    ...base,
    fontSize: "x-large",

    minHeight: 50,
  }),
};
const mineral_List = [
  {
    name: "Gold",
  },
];
const ProjectResources = ({ project }) => {
  const years_list = () => {
    var max = new Date().getFullYear();
    var min = max - 9;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  };
  const production_basis_list = [
    { label: "Attributional basis", value: "attributional_basis" },
    { label: "100% basis", value: "full_basis" },
  ];
  const [year, setyear] = useState(project.mrr_years?.[0] || 2020);

  const [production_basis, setproduction_basis] = useState("full_basis");
  const elements = useSelector((state) => state.authReducer.minerals_list);
  useEffect(() => {
    setselected_mineral(project.r_r_minerals?.[0]);

    return () => {};
  }, [project]);
  const [selected_mineral, setselected_mineral] = useState(
    project.r_r_minerals?.[0]
  );
  const pdfExportComponent = React.useRef(null);
  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const [tableData, settableData] = useState(null);

  useEffect(() => {
    fetchData();

    return () => {};
  }, [selected_mineral, project]);
  const fetchData = async () => {
    const data = await apiRequest
      .get(
        `projects/${project.id}/target-zone-mineral-reserve-grid?production_basis=${production_basis}&mineral=${selected_mineral}&report_year=${year}`
      )
      .catch((err) => console.log(err));
    if (data?.data?.data) {
      let projects = data.data.data;
      console.log(projects);
      settableData(data.data.data[0]);
    }
  };

  return (
    <div style={{ width: "100%", height: "fit-content" }}>
      <div className="flex flex-row flex-wrap w-full justify-between items-center">
        <Headline5 className=" lg:w-1/4 md:w-full">
          Resources And Reserves
        </Headline5>

        <div className="flex-boy-row lg:w-1/4 md:w-full">
          {/* <Tooltip
            placement="top"
            title={
              <div>
                100% basis is based on the total amount for the project
                regardless of company’s share.<br></br> Attributional basis is
                based on the current ownership that the company holds in the
                specific project
              </div>
            }
          >
            <div
              style={{
                flex: 1,
                boxSizing: "border-box",
                padding: "10px",
                paddingLeft: "0",
                height: "80px",
              }}
            >
              <Select
                displayEmpty
                styles={customStyles}
                onChange={(e) => setproduction_basis(e.value)}
                value={{
                  value: production_basis,
                  label: production_basis_list.find(
                    (item) => item.value === production_basis
                  ).label,
                }}
                placeholder={"Attributional basis"}
                options={production_basis_list.map((tag, index) => ({
                  value: tag.value,
                  label: tag.label,
                }))}
              />
            </div>
          </Tooltip> */}
          <div
            style={{
              flex: 1,
              boxSizing: "border-box",
              padding: "10px",
              paddingLeft: "0",
              height: "80px",
            }}
          >
            <Select
              displayEmpty
              styles={customStyles}
              onChange={(e) => setyear(e.value)}
              placeholder={"year"}
              value={{ value: year, label: year }}
              options={
                project?.mrr_years?.length
                  ? project.mrr_years.map((tag, index) => ({
                      value: tag,
                      label: tag,
                    }))
                  : years_list().map((tag, index) => ({
                      value: tag,
                      label: tag,
                    }))
              }
            />
          </div>
        </div>
      </div>
      <PDFExport
        ref={pdfExportComponent}
        paperSize="auto"
        margin={40}
        fileName={`Report for ${project.projectName} for ${selected_mineral}`}
        author="Prospector Inc"
      >
        <div className="flex flex-row  min-w-[50%]">
          {/* {project &&
            project.r_r_minerals.map((min) => {
              let mineral = elements.find(
                (item) => item.name.toLowerCase() === min.trim().toLowerCase()
              )
                ? elements
                    .find(
                      (item) =>
                        item.name.toLowerCase() === min.trim().toLowerCase()
                    )
                    .chemical_symbol.substring(0, 3)
                : min.substring(0, 3);

              return (
                <div
                  className={`mineral-div ${
                    selected_mineral === min ? "bg-emerald-500" : "bg-slate-200"
                  } cursor-pointer flex-boy-row h-[50px] w-[50px] mr-[20px] font-bold rounded`}
                  onClick={() => setselected_mineral(min)}
                >
                  <Headline5
                    style={{ textAlign: "center", flex: "1" }}
                    color={selected_mineral === min ? "white" : "grey"}
                  >
                    {mineral}{" "}
                  </Headline5>
                </div>
              );
            })} */}
        </div>
        <MineralResourceDiv
          project={project}
          year={year}
          mineral={selected_mineral}
          production_basis={production_basis}
        />
      </PDFExport>
    </div>
  );
};
const mapStateToProps = (state) => ({
  project: state.authReducer.selectedProject,
  project_reports_loading: state.authReducer.project_reports_loading,
  reports: state.authReducer.selectedCompany_reports,
  activeUser: state.authReducer.activeUser,
  user_follow_list: state.authReducer.lists,
});

const mapDispatchToProps = (dispatch) => ({
  setSupscriptionPopup: (data) =>
    dispatch({ type: "SET_SUBSCRIPTION_POPUP", payload: data }),
  setSupscriptionMessage: (data) =>
    dispatch({ type: "SET_SUBSCRIPTION_MESSAGE", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectResources);
