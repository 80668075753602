import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { AgGridReact } from "ag-grid-react";
import Select from "react-select";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import moment from "moment";
import axios from "axios";
import { Player } from "@lottiefiles/react-lottie-player";

import { makeStyles } from "@material-ui/core/styles";
import { LicenseManager } from "ag-grid-enterprise";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";

import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import { data } from "autoprefixer";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Primary, Subtitle1 } from "../../../components";
import animationData from "../../../assets/ProspectorDesignAssets/lottie2.json";
import {
  apiRequest,
  getReportDetail,
} from "../../../store/Actions/AuthActions";
import { Exchanges, filingTypes, resTypes } from "../../../configure/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid gainsboro",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 52,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    background: Primary,
    color: "white",
  },
  divider: {
    height: "100%",
  },
}));

const ProjectReportTable = ({
  activeUser,
  getReportDetail,
  history,
  project,
}) => {
  const gridRef = useRef(null);
  const classes = useStyles();
  const [filters, setFilters] = useState({
    headline_search: "",
    companies: "",
    authors03: "",
    start_date: "",
    end_date: "",
    all_of_these_words: "",
    exact_phrase: "",
    exchanges: [],
    minerals: [],
    mineral_estimate: [],
    region: [],
    filing_type: [],
    sortModel: [],
  });

  const pageSize = 25;

  const continents = [
    "Asia",
    "North America",
    "Latin America",
    "Africa",
    "Antarctica",
    "Europe",
    "Oceania",
  ];

  const [regions, setRegions] = useState([]);
  const [minerals, setMinerals] = useState([]);
  useEffect(() => {
    const getFilterInfo = async () => {
      const countries_data = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/config/countries`
      );
      const minerals_data = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/config/minerals`
      );

      if (countries_data) {
        setRegions([...continents, ...countries_data.data.data.sort()]);
      }
      if (minerals_data) {
        setMinerals([...new Set(minerals_data.data.data)]);
      }
    };

    getFilterInfo();
  }, []);

  const sortData = (sortModel, data) => {
    const sortPresent = sortModel && sortModel.length > 0;
    if (!sortPresent) {
      return data;
    }
    const resultOfSort = data.slice();
    resultOfSort.sort((a, b) => {
      for (let k = 0; k < sortModel.length; k++) {
        const sortColModel = sortModel[k];
        const valueA = a[sortColModel.colId];
        const valueB = b[sortColModel.colId];
        if (valueA == valueB) continue;
        const sortDirection = sortColModel.sort === "asc" ? 1 : -1;
        return valueA > valueB ? sortDirection : sortDirection * -1;
      }
      return 0;
    });
    return resultOfSort;
  };

  const filterData = (filterModel, data) => {
    const filterPresent = filterModel && Object.keys(filterModel).length > 0;
    if (!filterPresent) {
      return data;
    }
    return data.filter((item) => {
      if (
        filterModel.year &&
        !filterModel.year.values.includes(item.year.toString())
      )
        return false;
      if (
        filterModel.country &&
        !filterModel.country.values.includes(item.country)
      )
        return false;
      return true;
    });
  };

  const sortAndFilter = (allOfTheData, sortModel, filterModel) => {
    return sortData(sortModel, filterData(filterModel, allOfTheData));
  };

  const destructureSortModel = (sortModel) => {
    const sortObject = {};
    sortModel.forEach((sort) => {
      sortObject[`sort_${sort.colId}`] = sort.sort;
    });
    return sortObject;
  };

  const destructureFilterModel = (filterModel) => {
    const filterObject = {};
    Object.keys(filterModel).forEach((key) => {
      const filter = filterModel[key];
      if (key === "document_type") {
        filterObject["filing_type"] = filter.values.join(",");
      } else if (key === "publication_date") {
        if (filter.type === "equals") {
          filterObject["start_at"] = moment(filter.dateFrom).format(
            "YYYY-MM-DD"
          );
        } else if (filter.type === "inRange") {
          filterObject["start_at"] = moment(filter.dateFrom).format(
            "YYYY-MM-DD"
          );
          filterObject["end_at"] = moment(filter.dateTo).format("YYYY-MM-DD");
        }
      } else if (key === "name") {
        filterObject["headline"] = filter.filter;
      } else if (filter.filterType === "set") {
        filterObject[key] = filter.values.join(",");
      } else if (filter.filterType === "text") {
        filterObject[`${key}`] = filter.filter;
      }
    });
    return filterObject;
  };

  const [dataLoading, setDataLoading] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const fetchFilteredData = async () => {
    setDataLoading(true);
    try {
      const response = await apiRequest.get(
        `https://py.prospectormatch.com/api/projects/${project.id}/filings`
      );
      setRowData(response.data?.data);
      setFilteredData(response.data?.data);
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);
    }
  };

  // useEffect(() => {
  //   console.log("filters", filters, gridRef.current);
  //   if (gridRef.current) {
  //     gridRef.current.api?.setGridOption("datasource", {
  //       getRows: fetchFilteredData,
  //     });
  //   }
  // }, [filters]);

  const onExternalFilterChange = (name, selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFilters({ ...filters, [name]: values });
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onGridReady = (params) => {
    gridRef.current = params.api;
  };

  const onSortChanged = () => {
    const sortModel = gridRef.current.api?.getSortModel();
    setFilters((prevFilters) => ({ ...prevFilters, sortModel }));
  };

  const onFilterChanged = () => {
    gridRef.current.api?.paginationGoToPage(0);
  };

  const CustomLoadingOverlay = () => (
    <div className="flex-boy-row">
      <Player
        autoplay
        loop
        src={animationData}
        style={{ height: "100px", width: "100px" }}
      />
      <Subtitle1>Loading</Subtitle1>
    </div>
  );

  const exchangesOptions = Exchanges.map((exchange) => ({
    value: exchange,
    label: exchange,
  }));
  const mineralsOptions = {
    values: minerals,
    filter: (cellValue, filterValue) => {
      if (filterValue.length === 0) return true;
      return filterValue.includes(cellValue);
    },
  };
  const mineralEstimateOptions = resTypes.map((resType) => ({
    value: resType.name,
    label: resType.name,
  }));

  const filingTypeOptions = {
    values: filingTypes,
    filter: (cellValue, filterValue) => {
      if (filterValue.length === 0) return true;
      return filterValue.includes(cellValue);
    },
  };

  const regionFilter = {
    values: regions,
    filter: (cellValue, filterValue) => {
      if (filterValue.length === 0) return true;
      return filterValue.includes(cellValue);
    },
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Headline",
        field: "name",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
        },

        width: 600,
      },

      {
        headerName: "Project Name",
        field: "project_name",
        filter: "agTextColumnFilter",
        hide: true,
        filterParams: {
          filterOptions: ["contains"],
        },
      },

      {
        headerName: "Filing Type",
        field: "document_type",
        pinned: "right",
        filter: "agSetColumnFilter",
        filterParams: filingTypeOptions,
        width: 300,
      },
      {
        headerName: "Publication Date",
        field: "publication_date",
        valueFormatter: (params) =>
          params.value ? moment(params.value).format("DD MMM YYYY") : "",
        filter: "agDateColumnFilter",
        width: 200,
        pinned: "left",
        resizable: false,
        filterParams: {
          filterOptions: ["equals", "inRange"],
          buttons: ["apply", "reset"],
          closeOnApply: true,
        },
      },
    ],
    []
  );

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onFinish();
    }
  };

  const onFinish = () => {
    console.log("onFinish", searchValue);
  };

  const [searchValue, setSearchValue] = useState("");
  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
    setFilters({ ...filters, headline_search: e.target.value });
  };

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      floatingFilter: true,
      sortable: false,
    }),
    []
  );

  useEffect(() => {
    fetchFilteredData();
  }, [project]);

  const goToDetail = (id) => {
    getReportDetail({ id: id, history });
  };

  return (
    <div
      className="ag-theme-custom mt-6"
      style={{ height: "60vh", width: "100%" }}
    >
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowData={filteredData}
        loading={dataLoading}
        loadingOverlayComponent={CustomLoadingOverlay}
        loadingOverlayComponentParams={{
          loadingMessage: "One moment please...",
        }}
        noRowsOverlayComponentParams={{
          noRowsMessageFunc: () => "No rows found",
        }}
        // onSortChanged={onSortChanged}
        onGridReady={onGridReady}
        // onFilterChanged={onFilterChanged}
        onRowClicked={(e) => goToDetail(e.data?.id)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.authReducer.selectedProject,
});

const mapDispatchToProps = (dispatch) => ({
  getReportDetail: (data) => dispatch(getReportDetail(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectReportTable));
