/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from "react";
import { connect, useSelector } from "react-redux";
import {
  MainPage,
  Headline6,
  Headline5,
  Body1,
  Primary,
  Subtitle1,
  Subtitle2,
  GreyBox,
  Button,
  ComponentContainer,
  Body2,
  Hexagon,
} from "../../components";
import { Grid, Select, MenuItem, Divider } from "@material-ui/core";
import parse from "html-react-parser";
import { KeyboardBackspace, KeyboardBackspaceSharp } from "@material-ui/icons";
import GoogleMapReact from "google-map-react";
import MapMarker from "../../components/MapMarker";

import ContentLoader from "react-content-loader";
import _ from "lodash";
import Pagination from "@material-ui/lab/Pagination";

import { useLocation, Link } from "react-router-dom";
import {
  getProjectReports,
  getProjectNews,
  getProjectSocial,
  companyConnect,
  getProjectDetail,
  matchUsers,
  manageSub,
  apiRequest,
  getCompanyDetail,
  getReportDetail,
  getFilingDetail,
} from "../../store/Actions/AuthActions";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Annotation,
  FormFields,
  FormDesigner,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import { useFirestoreConnect } from "react-redux-firebase";
import ProjectCard from "../../components/ProjectCard";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import Notes from "../../components/Notes";
import List from "../../components/List";
import EditNotes from "../../components/UserNotes/EditNotes";
import { Sparkline } from "../../components/SparkLine";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import PdfViewer from "./components/PdfViewer";
import Dropdown from "./components/Dropdown";
import { Popover, Transition } from "@headlessui/react";
import ReactGA from "react-ga4";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const ReportDetail = ({
  getFilingDetail,

  project_reports_loading,
  setSupscriptionMessage,
  matchUsers,
  setSupscriptionPopup,
  activeUser,
  user_follow_list,
  history,
  report,
  getCompanyDetail,
  getProjectNews,
  getProjectSocial,
  companyConnect,
  getProjectDetail,
  reports,
  getProjectReports,
  loaded_reports,
  setLoadedReports,
}) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 1224px)",
  });
  const [allProjects, setallProjects] = useState(false);
  const pdfviewerRef = useRef(null);
  const TablesRef = useRef(null);
  const FiguresRef = useRef(null);
  const ProjectsRef = useRef(null);
  const CompaniesRef = useRef(null);
  const location = useLocation();
  const onRefChange = useCallback((node) => {
    if (node === null) {
      // DOM node referenced by ref has been unmounted
    } else {
      pdfviewerRef.current = node;

      // DOM node referenced by ref has changed and exists
    }
  }, []);

  function useOnScreen(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

    useEffect(() => {
      if (ref.current) {
        observer.observe(ref.current);
      }

      // Remove the observer as soon as the component is unmounted
      return () => {
        observer.disconnect();
      };
    }, []);

    return isIntersecting;
  }

  useEffect(() => {
    if (
      report &&
      !loaded_reports.find((item) => item.report_id === report.id)
    ) {
      loadDocument();
    }

    return () => {};
  }, []);

  useEffect(() => {
    loadDocument();
    return () => {};
  }, [report]);

  // useEffect(() => {
  //
  //     let id = location.pathname.replace('/report-detail/', '')
  //
  //     getReportDetail({ id, history })
  //
  // }, [location])

  const [documentBase64, setdocumentBase64] = useState(null);
  const isHTML = (str) => {
    const fragment = document.createRange().createContextualFragment(str);

    // remove all non text nodes from fragment
    fragment
      .querySelectorAll("*")
      .forEach((el) => el.parentNode.removeChild(el));

    // if there is textContent, then not a pure HTML
    return !(fragment.textContent || "").trim();
  };

  const [htmltext, sethtmltext] = useState(null);
  const loadDocument = async () => {
    if (report && report.url) {
      console.log("loading");
      const file = await axios
        .get(report.url, { responseType: "blob" })
        .catch((ex) => {
          console.error(ex);
        });
      if (file) {
        console.log("🤣", file.data.type);
        const text = await new Response(file.data).text();

        console.log("🤣", text);
        if (isHTML(text)) {
          sethtmltext(text);
        } else {
          sethtmltext(null);
          console.log("wtf");
        }

        // const buffer = Buffer.from(file.data, 'binary');

        let reader = new window.FileReader();
        reader.readAsDataURL(file.data);
        reader.onloadend = function () {
          let base64data = reader.result;
          let documents = [...loaded_reports];
          let new_report = {
            report_id: report.id,
            blob: base64data,
          };
          documents.push(new_report);
          setLoadedReports(documents);

          if (pdfviewerRef.current) {
            pdfviewerRef.current.load(base64data);
          }
        };

        // setdocumentBase64(buffer.toString('base64'))
        //
      }
    }
  };
  const elements = useSelector((state) => state.authReducer.minerals_list);

  const [trend, setTrend] = useState({
    ratio: "1.4%",
    trend: "up",
  });

  const subscription = activeUser;
  const checkSubscription = async (url) => {
    if (subscription) {
      if (
        activeUser &&
        activeUser?.permissions.includes("access_43101_portal")
      ) {
        window.open(url, "_blank");
      } else {
        await setSupscriptionMessage("upgrade");
        setSupscriptionPopup(true);
      }
    } else {
      await setSupscriptionMessage("Login");
      setSupscriptionPopup(true);
    }
  };

  const [NotesOpen, setNotesOpen] = useState(false);
  const handleClickOpen = () => {
    setNotesOpen(true);
  };

  const handleClose = (value) => {
    setNotesOpen(false);
    checkForNotes();
  };
  const [ListOpen, setListOpen] = useState(false);
  const handleListOpen = () => {
    setListOpen(true);
  };

  const handleListClose = (value) => {
    setListOpen(false);
  };
  const [existingNote, setexistingNote] = useState(null);
  const checkForNotes = async () => {
    const response = await apiRequest
      .get(`/reports/${report.report_id}/notes`)
      .catch((error) => {});
    if (response && response.data.data) {
      setexistingNote(response.data.data);
    }
  };
  useEffect(() => {
    let id = location.pathname.replace("/filing-detail/", "");
    ReactGA.event({
      category: "category",
      action: "filing",
    });
    ReactGA.gtag("config", "G-JB0KC590Z4", {
      filing_id: id,
      // tech_report_name: report?.name,
      client: activeUser?.email,
    });
    getFilingDetail({ id, history });
  }, [location]);

  useEffect(() => {
    checkForTables(tablesPagination.currentPage);
    checkForFigures(figuresPagination.currentPage);
    return () => {};
  }, [report]);
  const [tables, setTables] = useState([]);
  const [figures, setFigures] = useState([]);
  const [figuresPagination, setFiguresPagination] = useState({
    totalPages: 2,
    currentPage: 1,
  });
  const [tablesPagination, setTablesPagination] = useState({
    totalPages: 2,
    currentPage: 1,
  });
  const checkForTables = async (page) => {
    const response = await apiRequest
      .get(`/nl-tech-reports/${report.report_id}/tables?page=${page}`)
      .catch((error) => {});
    if (response && response.data.data) {
      let page_data = { ...tablesPagination };
      page_data.totalPages = response.data.last_page;
      setTablesPagination(page_data);

      setTables(response.data.data);
    }
  };
  const checkForFigures = async (page) => {
    const response_figures = await apiRequest
      .get(`/nl-tech-reports/${report.report_id}/figures?page=${page}`)
      .catch((error) => {});
    if (response_figures && response_figures.data.data) {
      setFigures(response_figures.data.data);
      let page_data = { ...figuresPagination };
      page_data.totalPages = response_figures.data.last_page;
      setFiguresPagination(page_data);
    }
  };

  const handlespageChange = async (type, value) => {
    if (type === "tables") {
      let page_data = { ...tablesPagination };
      page_data.currentPage = parseInt(value);
      await setTablesPagination(page_data);
      checkForTables(value);
    } else {
      let page_data = { ...figuresPagination };
      page_data.currentPage = parseInt(value);
      await setFiguresPagination(page_data);
      checkForFigures(value);
    }
  };
  const states = ["Inferred", "Indicated", "Measured", "Probable", "Proven"];
  const downlodDocument = () => {
    window.open(report.document_url, "_blank");
  };
  const pdfRef = useRef(null);
  const [page, setpage] = useState(1);
  const show_page = (page) => {
    pdfRef.current.scrollIntoView({ behavior: "smooth" });
    setpage(page);
  };

  useEffect(() => {
    ReactGA.event({
      category: report?.name,
      action: "filling_viewed",
    });
    ReactGA.gtag("config", "G-JB0KC590Z4", {
      tech_report_id: report?.id,
      tech_report_name: report?.name,
      client: activeUser?.email,
    });
  }, [report]);

  const [visibleItersection, setvisibleItersection] = useState("pdfisVisible");
  const render_stuff = () => {
    let i = [
      {
        value: Math.abs(pdfRef.current?.getBoundingClientRect().top),
        name: "pdfisVisible",
      },
      {
        value: Math.abs(TablesRef.current?.getBoundingClientRect().top),
        name: "tablesisVisible",
      },
      {
        value: Math.abs(FiguresRef.current?.getBoundingClientRect().top),
        name: "figuresisVisible",
      },
      {
        value: Math.abs(ProjectsRef.current?.getBoundingClientRect().top),
        name: "projectsisVisible",
      },
      {
        value: Math.abs(CompaniesRef.current?.getBoundingClientRect().top),
        name: "companiesisVisble",
      },
    ];

    setvisibleItersection(_.sortBy(i, "value")[0].name);
  };

  return (
    <MainPage onScroll={render_stuff}>
      <div ref={pdfRef}></div>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={NotesOpen}
      >
        {existingNote ? (
          <EditNotes
            handleClose={handleClose}
            type={"report"}
            entity={existingNote}
          />
        ) : (
          <Notes handleClose={handleClose} type={"report"} entity={report} />
        )}
      </Dialog>

      <Grid container spacing={2}>
        {report && (
          <>
            {!isTabletOrMobileDevice ? (
              <Grid item xs={3}>
                <div
                  style={{
                    padding: "10px",
                    position: "sticky",
                    top: 0,
                    height: "100vh",
                    overflowY: "scroll",
                  }}
                >
                  <div className="report-summary flex-boy-column">
                    <div className="header flex-boy-row p-2">
                      <KeyboardBackspaceSharp
                        onClick={() => {
                          history.goBack();
                        }}
                        style={{ fontSize: 20, color: "white" }}
                      />
                      <Subtitle1
                        color="white"
                        style={{ textAlign: "center", width: "100%" }}
                      >
                        {report.project_name} {report.document_type}
                      </Subtitle1>
                    </div>
                    <div className="body flex-boy-column" style={{ flex: 1 }}>
                      <div style={{ marginBottom: "10px" }}>
                        <Subtitle1>Filing Type</Subtitle1>
                        <Body2>{report.document_type}</Body2>
                      </div>

                      <div style={{ marginBottom: "10px" }}>
                        <Subtitle1>Filing Date</Subtitle1>
                        <Body2>
                          {moment(report.publication_date).format("YYYY-MM-DD")}
                        </Body2>
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <Subtitle1>Source</Subtitle1>
                        <Body2>{report.source}</Body2>
                      </div>

                      <div style={{ marginBottom: "10px" }}>
                        <Subtitle1>Filing Company</Subtitle1>
                        <Body2>{report.filing_company}</Body2>
                      </div>
                    </div>
                  </div>

                  <Divider />

                  <div className="page-anchors">
                    <Subtitle1
                      color={visibleItersection === "pdfisVisible" && Primary}
                      style={{ marginBottom: "20px" }}
                      onClick={(e) =>
                        pdfRef.current?.scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      1. Document view
                    </Subtitle1>

                    {report?.projects?.length > 0 && (
                      <Subtitle1
                        style={{ marginBottom: "20px" }}
                        color={
                          visibleItersection === "projectsisVisible" && Primary
                        }
                        onClick={(e) =>
                          ProjectsRef.current?.scrollIntoView({
                            behavior: "smooth",
                          })
                        }
                      >
                        2. Linked Projects
                      </Subtitle1>
                    )}

                    {report?.companies?.length > 0 && (
                      <Subtitle1
                        style={{ marginBottom: "20px" }}
                        color={
                          visibleItersection === "companiesisVisble" && Primary
                        }
                        onClick={(e) =>
                          CompaniesRef.current?.scrollIntoView({
                            behavior: "smooth",
                          })
                        }
                      >
                        3. Linked Companies
                      </Subtitle1>
                    )}
                  </div>
                  <div
                    className="flex-boy-row"
                    style={{ marginTop: "20px", padding: "20px" }}
                  >
                    {/*<GreyBox onClick={() => setNotesOpen(true)} style={{ width: "100px", cursor: "pointer", fontWeight: 'bold', fontSize: '0.8rem', textTransform: 'uppercase', color: "white", backgroundColor: "#00AC69", marginRight: "10px" }}> + {existingNote ? "Edit" : "Add"} Notes</GreyBox>*/}
                    {report.document_url ? (
                      <GreyBox
                        onClick={() => downlodDocument(true)}
                        style={{
                          width: "100px",
                          cursor: "pointer",
                          fontWeight: "bold",
                          fontSize: "0.8rem",
                          textTransform: "uppercase",
                          color: "white",
                          backgroundColor: "#00AC69",
                          marginRight: "10px",
                        }}
                      >
                        {" "}
                        Download
                      </GreyBox>
                    ) : null}
                  </div>
                </div>
              </Grid>
            ) : (
              <Popover
                className="relative m-2 p-4 z-10 "
                style={{ padding: "10px", position: "sticky", top: 0 }}
              >
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",

                        "group p-2 bg-white rounded-md shadow-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                      )}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 left-full transform -translate-x-1/10 mt-3 px-2 w-screen max-w-xs sm:px-0">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-2 py-2 sm:gap-8 ">
                            <div
                              style={{
                                padding: "10px",
                                position: "sticky",
                                top: 0,
                              }}
                            >
                              <div className="report-summary flex-boy-column">
                                <div className="header flex-boy-row p-2">
                                  <KeyboardBackspaceSharp
                                    onClick={() => {
                                      history.goBack();
                                    }}
                                    style={{ fontSize: 20, color: "white" }}
                                  />
                                  <Subtitle1
                                    color="white"
                                    style={{
                                      textAlign: "center",
                                      width: "100%",
                                    }}
                                  >
                                    {report.project_name} {report.document_type}
                                  </Subtitle1>
                                </div>
                                <div
                                  className="body flex-boy-column"
                                  style={{ flex: 1 }}
                                >
                                  <div style={{ marginBottom: "10px" }}>
                                    <Subtitle1>Filing Type</Subtitle1>
                                    <Body2>{report.filing_type}</Body2>
                                  </div>

                                  <div style={{ marginBottom: "10px" }}>
                                    <Subtitle1>Filing Date</Subtitle1>
                                    <Body2>
                                      {moment(report.publication_date).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </Body2>
                                  </div>
                                  <div style={{ marginBottom: "10px" }}>
                                    <Subtitle1>Source</Subtitle1>
                                    <Body2>{report.source}</Body2>
                                  </div>

                                  <div style={{ marginBottom: "10px" }}>
                                    <Subtitle1>Filing Company</Subtitle1>
                                    <Body2>{report.company_name}</Body2>
                                  </div>
                                </div>
                              </div>

                              <Divider />

                              <div className="page-anchors">
                                <Subtitle1
                                  color={
                                    visibleItersection === "pdfisVisible" &&
                                    Primary
                                  }
                                  style={{ marginBottom: "20px" }}
                                  onClick={(e) =>
                                    pdfRef.current?.scrollIntoView({
                                      behavior: "smooth",
                                    })
                                  }
                                >
                                  1. Document view
                                </Subtitle1>

                                {report?.projects?.length > 0 && (
                                  <Subtitle1
                                    style={{ marginBottom: "20px" }}
                                    color={
                                      visibleItersection ===
                                        "projectsisVisible" && Primary
                                    }
                                    onClick={(e) =>
                                      ProjectsRef.current?.scrollIntoView({
                                        behavior: "smooth",
                                      })
                                    }
                                  >
                                    2. Linked Projects
                                  </Subtitle1>
                                )}

                                {report?.companies?.length > 0 && (
                                  <Subtitle1
                                    style={{ marginBottom: "20px" }}
                                    color={
                                      visibleItersection ===
                                        "companiesisVisble" && Primary
                                    }
                                    onClick={(e) =>
                                      CompaniesRef.current?.scrollIntoView({
                                        behavior: "smooth",
                                      })
                                    }
                                  >
                                    3. Linked Companies
                                  </Subtitle1>
                                )}
                              </div>
                              <div
                                className="flex-boy-row"
                                style={{ marginTop: "20px", padding: "20px" }}
                              >
                                {/*<GreyBox onClick={() => setNotesOpen(true)} style={{ width: "100px", cursor: "pointer", fontWeight: 'bold', fontSize: '0.8rem', textTransform: 'uppercase', color: "white", backgroundColor: "#00AC69", marginRight: "10px" }}> + {existingNote ? "Edit" : "Add"} Notes</GreyBox>*/}
                                {report.document_url ? (
                                  <GreyBox
                                    onClick={() => downlodDocument(true)}
                                    style={{
                                      width: "100px",
                                      cursor: "pointer",
                                      fontWeight: "bold",
                                      fontSize: "0.8rem",
                                      textTransform: "uppercase",
                                      color: "white",
                                      backgroundColor: "#00AC69",
                                      marginRight: "10px",
                                    }}
                                  >
                                    {" "}
                                    Download
                                  </GreyBox>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            )}
          </>
        )}
        {/*<Grid item lg={1}>*/}
        {/*    <KeyboardBackspace style={{ color: Primary, marginTop:'20px' }} onClick={() => history.goBack()} />*/}

        {/*</Grid>*/}
        {report ? (
          <>
            <Grid
              item
              xs={12}
              lg={9}
              xl={9}
              container
              style={{ paddingRight: "2em" }}
            >
              <div style={{ textAlign: "left", width: "100%" }}>
                {report.url && (
                  <ComponentContainer
                    ref={pdfRef}
                    width={"100%"}
                    style={{ position: "relative" }}
                  >
                    {/* <PdfViewerComponent
                      // documentPath={documentBase64}
                      enableNavigation={true}
                      ref={(viewer) => onRefChange(viewer)}
                      id="container"
                      serviceUrl={process.env.REACT_APP_PDF_VIEWER}
                      style={{ height: "800px" }}
                    >
                      <Inject
                        services={[
                          Toolbar,
                          Magnification,
                          Navigation,
                          BookmarkView,
                          ThumbnailView,
                          Print,
                          TextSelection,
                          TextSearch,
                        ]}
                      />
                    </PdfViewerComponent> */}
                    {!htmltext && (
                      <PdfViewer page={page} url={report.url} report={report} />
                    )}
                    {htmltext && parse(htmltext)}
                  </ComponentContainer>
                )}

                {report?.projects?.length > 0 && (
                  <ComponentContainer width={"100%"} ref={ProjectsRef}>
                    <Headline5 style={{ margin: "20px 0 20px 0" }}>
                      Linked Projects
                    </Headline5>
                    <div className="chart-map">
                      <div className="company-map">
                        <GoogleMapReact
                          bootstrapURLKeys={{
                            key: "AIzaSyAjl3X5q6XmuETXG8wNyCUb2kOD0IK9PNE",
                          }}
                          center={{
                            lat: 0.0,
                            lng: 0.0,
                          }}
                          defaultZoom={1}
                          options={{ minZoom: 1 }}
                        >
                          {report.projects
                            .filter(
                              (item) =>
                                item.lat &&
                                item.lng &&
                                item.lat !== 0 &&
                                item.lng !== 0
                            )
                            .map((item, index) => {
                              return (
                                <MapMarker
                                  project={item}
                                  companyData={report}
                                  key={index}
                                  lat={item.lat}
                                  lng={item.lng}
                                />
                              );
                            })}
                        </GoogleMapReact>
                      </div>
                    </div>
                    {report.projects && report.projects.length
                      ? allProjects
                        ? report.projects.map((project, index) => {
                            return (
                              <ProjectCard
                                history={history}
                                key={index}
                                iscompanytype={true}
                                project={project}
                                color={
                                  trend.trend !== "up" ? "#B00020" : "#00AC69"
                                }
                              />
                            );
                          })
                        : report.projects.slice(0, 5).map((project, index) => {
                            return (
                              <ProjectCard
                                iscompanytype={true}
                                history={history}
                                key={index}
                                project={project}
                                color={
                                  trend.trend !== "up" ? "#B00020" : "#00AC69"
                                }
                              />
                            );
                          })
                      : null}
                    {report.projects && report.projects.length > 5 ? (
                      <Subtitle1
                        onClick={() => setallProjects(!allProjects)}
                        style={{
                          marginTop: "20px",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        color="##B00020"
                      >
                        View All Projects
                      </Subtitle1>
                    ) : null}
                  </ComponentContainer>
                )}
                {report?.companies?.length > 0 && (
                  <ComponentContainer ref={CompaniesRef}>
                    <Headline5 style={{ marginBottom: "20px" }}>
                      Linked Companies and organizations
                    </Headline5>
                    {report.companies.map((investor) => {
                      return (
                        <div style={{ width: "100%", padding: "5px" }}>
                          <Divider />

                          <div
                            className="flex-boy-row "
                            style={{
                              width: "100%",
                              marginBottom: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <div>
                              <div className="company-ticker">
                                <Subtitle1
                                  onClick={() =>
                                    getCompanyDetail({
                                      id: investor.id,
                                      history,
                                    })
                                  }
                                >
                                  {investor.name}{" "}
                                  {investor.ticker
                                    ? `(${investor.ticker}.${investor.exchange})`
                                    : "(Private or State Owned)"}
                                </Subtitle1>
                              </div>
                            </div>

                            <div className="tiny-spark" key={investor.id}>
                              {investor.stockHistory &&
                              investor.stockHistory.length ? (
                                <Sparkline
                                  data={investor.stockHistory}
                                  color={
                                    trend.trend !== "up" ? "#B00020" : "#00AC69"
                                  }
                                />
                              ) : null}
                            </div>
                          </div>
                          <Body1 style={{ marginBottom: "20px" }}>
                            {investor.description}
                          </Body1>

                          <div
                            className="flex-boy-row"
                            style={{ marginBottom: "20px" }}
                          >
                            <div
                              className="flex-boy-row"
                              style={{
                                justifyContent: "normal",
                                flexWrap: "wrap",
                              }}
                            >
                              {[...new Set(investor.minerals)]
                                .filter(
                                  (item) =>
                                    item !== "Not" &&
                                    item !== "Combined" &&
                                    item !== "Metric Tonnes"
                                )
                                .map((min, index) => {
                                  return (
                                    <Hexagon
                                      key={index}
                                      width={28}
                                      style={{ fontSize: "0.6rem" }}
                                    >
                                      {elements.find(
                                        (item) =>
                                          item.name.toLowerCase() ===
                                          min.trim().toLowerCase()
                                      )
                                        ? elements
                                            .find(
                                              (item) =>
                                                item.name.toLowerCase() ===
                                                min.trim().toLowerCase()
                                            )
                                            .chemical_symbol.substring(0, 4)
                                        : min.substring(0, 4)}
                                    </Hexagon>
                                  );
                                })}
                            </div>
                            <div style={{ textAlign: "left" }}>
                              <GreyBox
                                style={{
                                  width: "100px",
                                  marginLeft: "100px",
                                  fontSize: "14px",
                                }}
                              >
                                {investor.projects_count} Projects
                              </GreyBox>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </ComponentContainer>
                )}
              </div>
            </Grid>
          </>
        ) : null}
      </Grid>
    </MainPage>
  );
};

const mapStateToProps = (state) => ({
  report: state.authReducer.selectedFiling,
  activeUser: state.authReducer.activeUser,
  user_follow_list: state.authReducer.lists,
  loaded_reports: state.reportsReducer.loaded_reports,
});

const mapDispatchToProps = (dispatch) => ({
  getProjectReports: (data) => dispatch(getProjectReports(data)),
  getProjectSocial: (data) => dispatch(getProjectSocial(data)),
  getProjectNews: (data) => dispatch(getProjectNews(data)),
  companyConnect: (data) => dispatch(companyConnect(data)),
  getProjectDetail: (data) => dispatch(getProjectDetail(data)),
  getCompanyDetail: (data) => dispatch(getCompanyDetail(data)),
  matchUsers: (data) => dispatch(matchUsers(data)),
  getFilingDetail: (data) => dispatch(getFilingDetail(data)),
  setSupscriptionPopup: (data) =>
    dispatch({ type: "SET_SUBSCRIPTION_POPUP", payload: data }),
  setSupscriptionMessage: (data) =>
    dispatch({ type: "SET_SUBSCRIPTION_MESSAGE", payload: data }),
  setLoadedReports: (data) =>
    dispatch({ type: "SET_LOADED_REPORTS", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail);
