import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import {
  Headline2,
  Headline6,
  Subtitle1,
  Button,
  Primary,
  Body2,
} from "../index";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
// import Select from '@material-ui/core/Select';
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import CreatableSelect from "react-select/creatable";
import { apiRequest, getTags } from "../../store/Actions/AuthActions";
import { toast } from "react-toastify";
import { Tune } from "@material-ui/icons";
import { TailwindButton, TailwindButtonOutline } from "../general";
const options = [];

const useStyles = makeStyles((theme) => ({
  formControl: {},
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export const Notes = ({ type, handleClose, bulk_select_projects }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [personName, setPersonName] = React.useState([]);
  const [names, setnames] = React.useState([]);
  const [selectedTags, setselectedTags] = useState([]);
  const [tagsError, settagsError] = useState(false);
  const handleChange = (event) => {
    setselectedTags(event);
  };
  useEffect(() => {
    getmyTags();
    return () => {};
  }, []);
  const getmyTags = async (id) => {
    const tags = await apiRequest
      .get(`${process.env.REACT_APP_ENDPOINT}/me/tags`)
      .catch((err) => console.log(err));

    if (tags && tags.data) {
      setnames(
        tags.data.data.map((item) => ({
          ...item,
          value: item.name,
          label: item.name,
        }))
      );
    }
  };

  const handleTagChange = (event) => {};
  const gridInstance = useRef(null);
  const onRefChange = useCallback((node) => {
    if (node === null) {
      // DOM node referenced by ref has been unmounted
    } else {
      gridInstance.current = node;

      // DOM node referenced by ref has changed and exists
    }
  }, []);

  const getvalue = async () => {
    if (selectedTags.length) {
      settagsError(false);
      let payload = {
        resource_type: "project",
        entries: [...bulk_select_projects],
        tags: selectedTags.map((item) => item.value),
      };

      const toastId = toast("Adding Tags", {
        type: "info",
        autoClose: false,
      });
      const data = await apiRequest
        .patch("tags/bulk-assign", payload)
        .catch((error) => {
          toast.update(toastId, {
            theme: "light",
            render: error.response.data.message,
            type: toast.TYPE.ERROR,
            autoClose: 5000,
          });
        });
      if (data && data.data) {
        toast.update(toastId, {
          theme: "light",
          render: "Tags Added",
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
        });
        dispatch(getTags());
        handleClose();
      }
    } else {
      settagsError(true);
    }
  };

  return (
    <div
      className="notes-dialog w-[600px] tags"
      style={{ height: bulk_select_projects.length ? "600px" : "200px" }}
    >
      <div
        className="flex-boy-row"
        style={{ justifyContent: "normal", marginBottom: "30px" }}
      >
        <Headline6>
          {" "}
          {bulk_select_projects.length
            ? `Apply Tags to ${bulk_select_projects.length} Projects`
            : "Please select atleast one project"}{" "}
        </Headline6>
      </div>
      {bulk_select_projects.length > 0 && (
        <>
          <div className="body">
            <FormControl
              className="hover-sub without-ring"
              style={{ width: "100%", marginTop: "30px" }}
            >
              <CreatableSelect
                className="without-ring"
                isMulti
                onChange={handleChange}
                options={names}
              />
            </FormControl>
            {tagsError ? (
              <Body2 style={{ marginTop: "10px" }} color="crimson">
                Please select atleast one tag!
              </Body2>
            ) : null}
          </div>
        </>
      )}
      <div
        className={`footer flex flex-row p-4 ${
          bulk_select_projects.length ? "justify-between" : "justify-center"
        }`}
      >
        <TailwindButtonOutline text="Cancel" onClick={handleClose} />

        {bulk_select_projects.length > 0 && (
          <TailwindButton onClick={getvalue} text="Save"></TailwindButton>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  bulk_select_projects: state.authReducer.bulk_select_projects,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
