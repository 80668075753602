import React, { useState, Fragment, useEffect, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import {
  Headline6,
  MainPage,
  Primary,
  Headline5,
  Body1,
  Body2,
  GreyBox,
  Button,
  Subtitle1,
  Subtitle2,
  BrowseListFilter,
  Hexagon,
  TailwindButtonOutline,
  TailwindButton,
} from "../../../components";
import moment from "moment";
import SummaryAccordion from "../../../components/SummaryAccordion";
import {
  Grid,
  Tabs,
  Tab,
  Box,
  Dialog,
  Badge,
  Divider,
  TextField,
} from "@material-ui/core";
import TuneRoundedIcon from "@material-ui/icons/TuneRounded";
import SparkLine from "../../../components/SparkLine";
import {
  KeyboardBackspace,
  CallMade,
  CallReceived,
  SortOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import PeformanceChartJs from "../../../components/PeformanceChart.js";
import GoogleMapReact from "google-map-react";
import MapMarker from "../../../components/MapMarker";
import { useFirestoreConnect } from "react-redux-firebase";

import ProjectCard from "../../../components/ProjectCard";
import CompanyCard from "../../../components/CompanyCard";
import Filter from "../../../components/Filter";
import Sort from "../../../components/Sort";

import { withRouter } from "react-router";
import Map from "../../../components/Map";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import ContentLoader from "react-content-loader";
import _ from "lodash";
import animationData from "../../../assets/ProspectorDesignAssets/lottie2.json";
import {
  getCommodityData,
  filterProjects,
  setProjectFilters,
  setCompanyFilters,
  filterCompanies,
  filterCompaniesStaging,
  filterProjectsStaging,
  filterReports,
  apiRequest,
} from "../../../store/Actions/AuthActions";
import ViewAllFilter from "../../../components/ViewAllFilter";
import InfiniteScroll from "react-infinite-scroll-component";
import TechnicalReports from "./TechnicalReports";
import SearchComponent from "./SearchComponent";
import ProjectsSearch from "./SearchComponents/ProjectsSearch";
import { AdvancedSearch } from "./SearchComponents/Index";
import ReportCard from "../../../components/ReportCard";
import Dropdownfilter from "../../../components/Dropdownfilter";

import axios from "axios";
import { Exchanges, Period_range } from "../../../configure/constants";
import BulkList from "../../../components/List/BulkList";
import Tags from "../../../components/Tags";
import ExportProjects from "../../../components/ExportProjects";
import Notification from "../../../components/Notification";
import NotificationCofirmation from "../../../components/NotificatonConfirmation";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import Select from "react-select";
import NewsContent from "./NewsContent";
import FilingsSort from "../../../components/Sort/FilingsSort";
import Dropdown from "../../SavedSearches/Dropdown";
import { AgAbstractField } from "ag-grid-community";
import ReportsGrid from "./ReportsGrid.js";
import FillingsGrid from "./FillingsGrid.js";
import DrillingResults from "./DrillingResults.js";
import ChatPage from "./ChatPage.js";

const customStyles = {
  control: (base) => ({
    ...base,
    fontSize: "x-large",

    minHeight: "50px",
    maxHeight: "80px",
    overflowY: "auto",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
    };
  },
};

const continents = [
  "Asia",
  "North America",

  "Latin America",
  "Africa",
  "Antarctica",
  "Europe",
  "Oceania",
];
const resTypes = [
  {
    name: "Indicated Mineral Resource",
    value: 85,
    indicator: "indicated",
  },
  {
    name: "Inferred Mineral Resource",
    value: 84,
    indicator: "inferred",
  },
  {
    name: "Measured Mineral Resource",
    value: 86,
    indicator: "measured",
  },
  {
    name: "Not Mentioned",
    value: 89,
    indicator: "not mentioned",
  },
  {
    name: "Probable Mineral Reserve",
    value: 87,
    indicator: "probable",
  },

  {
    name: "Proven Mineral Reserve",
    value: 88,
    indicator: "proven",
  },
];
const stages = [
  "Discovery Delineation",
  "Grassroots",
  "Production",
  "Target Drilling",
  "Prefeasibility",
  "PEA",
  "Permitting & Feasibility",
  "Construction",
  "Suspended",
];

const periods = ["1M", "3M", "1Y", "5Y", "ALL"];
const iFrameDetection = window === window.parent ? false : true;
const PrettoSlider = createMuiTheme({
  overrides: {
    MuiSlider: {
      root: {
        color: "#52af77",
        height: 8,
      },
      thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
          boxShadow: "inherit",
        },
      },
      active: {},
      valueLabel: {
        left: "calc(-50% + 4px)",
      },
      track: {
        height: 8,
        borderRadius: 4,
      },
      rail: {
        height: 8,
        borderRadius: 4,
      },
    },
  },
});

const states = ["inferred", "indicated", "Measured", "Probable", "Proven"];
export const Content = ({
  reports_loading,
  companies_loading,
  reports_length,
  report_filters,
  project_start_after,
  projects_loading,
  companies_page,
  map_projects,
  setProjectStartDate,
  reports,
  filterProjectsStaging,
  projects_page,
  projects_page_count,
  filterProjects,
  filterCompanies,
  filterCompaniesStaging,
  setCompanyPage,
  setProjectPage,
  setProjectFilters,
  setCompanyFilters,
  history,
  projects_length,
  companies_length,
  setBrowseTab,
  selectedList,
  setTab,
  setList,
  companies_search,
  tab,
  setCompany,
  setProject,
  getCommodityData,
  setCommodity,
  project_filters,
  company_filters,
  selectBulk_projects,
  bulk_select_projects,
  activeUser,
  resetCompanyFilters,
  resetProjectFilters,
  resetReportFilters,
}) => {
  const elements = useSelector((state) => state.authReducer.minerals_list);
  const dispatch = useDispatch();
  const [searchMode, setsearchMode] = useState(false);
  const [value, setValue] = useState("projects");
  const [filingSortOpen, setfilingSortOpen] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    let tab = newValue === 0 ? "projects" : "companies";
    setBrowseTab(tab);
    setTab(tab);
  };
  useEffect(() => {
    setValue(tab);
    setsearchMode(false);
    return () => {};
  }, [tab]);
  const companies =
    useSelector((state) => state.authReducer.companies_search) || [];
  const projects =
    useSelector((state) => state.authReducer.projects_search) || [];

  const [extraMinerals, setMinerals] = useState([]);
  const [extraRegions, setRegions] = useState([]);
  useEffect(() => {
    getFilterInfo();

    return () => {};
  }, []);

  useEffect(() => {
    setPeriod(
      Object.keys(Period_range).find(
        (key) => Period_range[key] === project_start_after
      )
    );

    return () => {};
  }, [project_start_after]);

  const getFilterInfo = async () => {
    const countries_data = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/config/countries`
    );
    const minerals_data = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/config/minerals`
    );

    if (countries_data) {
      setRegions([...continents, ...countries_data.data.data.sort()]);
    }
    if (minerals_data) {
      setMinerals([...new Set(minerals_data.data.data)]);
    }
  };
  const [filterOpen, setFilterOpen] = useState(false);
  const [BulkListOpen, setBulkListOpen] = useState(false);
  const [ExportOpen, setExportOpen] = useState(false);
  const [TagsOpen, setTagsOpen] = useState(false);
  const [SortOpen, setSortOpen] = useState(false);

  const [listOpen, setListOpen] = useState(false);
  const [filterType, setType] = useState(null);
  const handleClose = () => {
    setsearchMode(false);
  };
  const handleExportClose = () => {
    setExportOpen(false);
  };
  const handleSortClose = () => {
    setSortOpen(false);
  };
  const handleListClose = () => {
    setListOpen(false);
  };
  const handleBulkListClose = () => {
    setBulkListOpen(false);
  };
  const handleTagsClose = () => {
    setTagsOpen(false);
  };
  const search_companies = ({ loc, newFilters }) => {
    setCompanyPage(0);
    filterCompanies();
    // filterCompaniesStaging()
  };
  const search_projects = ({ loc, newFilters }) => {
    setProjectPage(0);
    filterProjects();
  };
  const [period, setPeriod] = useState("3M");
  const [median, setMedian] = useState(70);

  const selectComodity = (item) => {
    setListOpen(false);
    if (item.type === "mineral") {
      // let data = {
      //     interval: '1d',
      //     identifier: item.name,
      //     range: '1y'
      // }
      // setCommodity(item.name)
      // getCommodityData(data)
      if (tab === "projects") {
        let newFilters = { ...project_filters };
        newFilters.mineral = [item.name];
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        let newFilters = { ...company_filters };
        newFilters.mineral = [item.name];
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
    } else if (item.type === "region" || item.type === "country") {
      if (tab === "projects") {
        let newFilters = { ...project_filters };
        newFilters.countries = [item.name];
        setProjectFilters(newFilters);
        search_projects({ loc: 0, newFilters });
      } else {
        let newFilters = { ...company_filters };
        newFilters.countries = [item.name];
        setCompanyFilters(newFilters);
        search_companies({ loc: 0, newFilters });
      }
    }

    setList(item);
  };
  let {
    averageGrade_min,
    averageGrade_max,
    oreTonnage_min,
    oreTonnage_max,
    containedMetal_min,
    containedMetal_max,
    ...rest
  } = company_filters;
  let o = project_filters
    ? Object.entries(project_filters).filter(([_, v]) => v !== 0 && v != null)
    : [1];
  let p = company_filters
    ? Object.entries(rest).filter(([_, v]) => v !== 0 && v != null)
    : [1];

  const switchPeriod = (period) => {
    setPeriod(period);
    let range = period.includes("1W")
      ? 7
      : period === "ALL"
      ? 30000
      : period === "1D"
      ? 1
      : period === "1M"
      ? 30
      : period === "3M"
      ? 90
      : period === "6M"
      ? 180
      : period === "2Y"
      ? 730
      : period === "3Y"
      ? 1095
      : period === "5Y"
      ? 1780
      : period === "1Y"
      ? 400
      : 90;
    setProjectStartDate(range);
    setProjectPage(1);
    filterProjects();
  };
  const next = () => {
    if (tab === "projects") {
      filterProjects();
    } else {
      filterCompaniesStaging();
    }
  };
  const previous = () => {
    if (tab === "projects") {
      setProjectPage(projects_page - 2);
      filterProjects();
    } else {
      setCompanyPage(companies_page - 2);
      filterCompaniesStaging();
    }
  };
  const getFlag = (name) => {
    let flagSource;
    try {
      flagSource = require(`../../../assets/ProspectorDesignAssets/Flags/${name
        .toLowerCase()
        .replace(" ", "-")}.png`);
    } catch {
      flagSource = require(`../../../assets/ProspectorDesignAssets/Flags/usa.png`);
    }
    return flagSource;
  };
  const fetchNext = () => {
    // let newFilters = { ...company_filters }
    // search_companies({ loc: 0, newFilters })
    if (tab === "companies") {
      filterCompanies();
    } else if (tab === "technical reports") {
      // dispatch(//filterReports());
    } else if (tab === "Recent News & Filings") {
      // dispatch(//filterReports());
    } else {
      filterProjects();
    }
  };
  const [allChecked, setallChecked] = useState(false);
  const select_bulk_projects = (checked) => {
    console.log(checked);
    setallChecked(checked);
    let list_projects = [...projects.map((item) => item.id)];

    if (checked) {
      selectBulk_projects([...list_projects]);
    } else {
      selectBulk_projects([]);
    }
  };

  useEffect(() => {
    if (bulk_select_projects.length > 25) {
      console.log("weue!");
      setShow(true);
      let list_projects = [...bulk_select_projects];
      selectBulk_projects(list_projects.splice(0, 25));
    } else {
      setTimeout(() => {
        setShow(false);
      }, 5000);
    }

    return () => {};
  }, [bulk_select_projects]);

  const [show, setShow] = useState(false);
  const [confirmshow, setConfirmShow] = useState(true);
  const [projectsJson, setprojectsJson] = useState(null);
  useEffect(() => {
    fetchList();
    return () => {};
  }, [bulk_select_projects]);
  const fetchList = () => {
    let projects_json = [...bulk_select_projects].map((item) => {
      let new_obj = projects.find((project) => project.id === item);
      let objHeaders = {
        "Project Name": new_obj.projectName,
        Minerals: new_obj.minerals?.toString(),
        Stage: new_obj.stage,
        "Mineral Estimate": new_obj.mineralEstimate?.toString(),
        Country: new_obj.countries?.toString(),
      };
      return objHeaders;
    });
    setprojectsJson(projects_json);
  };

  // save search
  const [saveopen, setOpen] = useState(false);
  const [searchName, setsearchName] = useState(null);
  const frequencies = ["Daily", "Weekly", "Bi-Weekly", "Monthly"];
  const [frequency, setFrequency] = useState(null);

  const saveSearch = async () => {
    let payload = {
      name: searchName,
      search_index: "documents",
      query_filters: report_filters,
      searchTerm: report_filters.searchTerm || "reports",
      alert_frequency: frequency.toLowerCase(),
    };
    const toastId = toast("Saving Search", {
      type: "info",
      autoClose: false,
    });
    let data = await apiRequest
      .post("/saved-searches", payload)
      .catch((err) => {
        toast.update(toastId, {
          theme: "light",
          render: err.response.data.message,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      });

    if (data && data.data) {
      toast.update(toastId, {
        theme: "light",
        render: "Search Saved",
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      handleCloseSave();
    }
  };
  const handleSearchChange = (value) => {
    console.log(value);
    setFrequency(value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseSave = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <Dialog open={saveopen} onClose={handleCloseSave}>
        <DialogTitle>Save Search</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please add a title and alert frequency for your search.
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Search Title"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setsearchName(e.target.value)}
          />

          <div className="pt-10 pb-5 h-fit">
            <Subtitle1>Set Frequency</Subtitle1>
            <Dropdown handleSearchChange={handleSearchChange} />
          </div>

          <div className="py-5 h-fit min-h-[300px]">
            <Headline6>Recipient Email Address</Headline6>
            <p className="mt-2 ">{activeUser?.email}</p>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="w-full flex flex-row justify-between">
            <TailwindButtonOutline text="Cancel" onClick={handleCloseSave}>
              Cancel
            </TailwindButtonOutline>
            <TailwindButton text="Save" onClick={saveSearch}>
              Save
            </TailwindButton>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={filterOpen}
      >
        <Filter handleClose={handleClose} tab={tab} />
      </Dialog>
      <Dialog
        onClose={handleSortClose}
        aria-labelledby="simple-dialog-title"
        open={SortOpen}
      >
        <Sort handleClose={handleSortClose} tab={tab} />
      </Dialog>
      <Dialog
        onClose={() => setfilingSortOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={filingSortOpen}
      >
        <FilingsSort handleClose={() => setfilingSortOpen(false)} tab={tab} />
      </Dialog>
      <Dialog
        onClose={handleListClose}
        aria-labelledby="simple-dialog-title"
        open={listOpen}
      >
        <ViewAllFilter
          selectComodity={selectComodity}
          handleClose={handleListClose}
          tab={tab}
        />
      </Dialog>
      <Dialog
        onClose={handleBulkListClose}
        aria-labelledby="simple-dialog-title"
        open={BulkListOpen}
      >
        <BulkList handleClose={handleBulkListClose} type={"project"} />
      </Dialog>
      <Dialog
        onClose={handleListClose}
        aria-labelledby="simple-dialog-title"
        open={TagsOpen}
      >
        <Tags handleClose={handleTagsClose} type={"project"} />
      </Dialog>
      {/* <Dialog
        onClose={handleExportClose}
        aria-labelledby="simple-dialog-title"
        open={ExportOpen}
        maxWidth="xs"
      >
        <ExportProjects handleClose={handleExportClose} type={"project"} />
      </Dialog> */}
      {searchMode ? (
        <AdvancedSearch tab={tab} setsearchMode={setsearchMode} />
      ) : (
        <div className="list-section">
          {value === "projects" ? (
            <>
              <div className="sticky">
                <div style={{ left: "75%", marginTop: "20px" }}>
                  <div className="flex-boy-row">
                    <Headline6
                      style={{
                        textAlign: "left",
                        width: "100%",
                        marginLeft: "5px",
                        padding: "10px",
                        textTransform: "uppercase",
                      }}
                    >
                      Projects with updates in last
                    </Headline6>
                    <div className="flex-boy-row"></div>
                  </div>

                  <div className="flex-boy-row py-1">
                    {periods.map((time, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => switchPeriod(time)}
                          className="period-map flex-boy-column"
                          style={{
                            color: time === period ? "white" : Primary,
                            backgroundColor:
                              time === period ? Primary : "white",
                          }}
                        >
                          <Headline6
                            color={time === period ? "white" : Primary}
                          >
                            {time}
                          </Headline6>
                        </div>
                      );
                    })}
                    <div className="flex-boy-row">
                      <div className="circle-div">
                        <TuneRoundedIcon
                          style={{ color: "grey" }}
                          onClick={() => setsearchMode(true)}
                        />
                      </div>
                      <div className="circle-div">
                        <SortOutlined
                          style={{ color: "grey" }}
                          onClick={() => setSortOpen(true)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex-boy-row">
                  <Subtitle1 style={{ fontSize: "20px", padding: "5px" }}>
                    {map_projects.length?.toLocaleString()} Projects
                  </Subtitle1>

                  <div className="flex-boy-row">
                    <Dropdownfilter
                      filter="minerals"
                      options={extraMinerals}
                      tab={tab}
                    />
                    <Dropdownfilter
                      filter="countries"
                      options={extraRegions}
                      tab={tab}
                    />
                    <TailwindButton
                      onClick={() => {
                        resetProjectFilters();
                        filterProjects();
                      }}
                      text={"Clear Filters"}
                    />
                  </div>
                </div>
                <Divider />
                <div className="flex flex-row align-middle p-2 justify-between items-center w-[80%]">
                  <div className="flex items-center h-5 mr-3">
                    <input
                      id="offers"
                      aria-describedby="offers-description"
                      name="offers"
                      type="checkbox"
                      checked={allChecked}
                      onChange={(e) => select_bulk_projects(e.target.checked)}
                      className="focus:ring-transparent h-5 w-5 text-emerald-600 border-gray-300 rounded"
                    />
                  </div>
                  <div className="flex-1 flex flex-row justify-between">
                    <p
                      onClick={() => setExportOpen(true)}
                      className="text-slate-400 font-semibold hover:text-emerald-500 cursor-pointer"
                    >
                      Export to CSV
                    </p>
                    <p
                      onClick={() => setBulkListOpen(true)}
                      className="text-slate-400 font-semibold hover:text-emerald-500 cursor-pointer"
                    >
                      Add to List
                    </p>
                    <p
                      onClick={() => setTagsOpen(true)}
                      className="text-slate-400 font-semibold hover:text-emerald-500 cursor-pointer"
                    >
                      Add Tags
                    </p>
                  </div>
                  <div className="ml-20">
                    {bulk_select_projects.length > 0 && (
                      <TailwindButtonOutline
                        onClick={() => select_bulk_projects(false)}
                        text="Deselect all"
                      />
                    )}
                  </div>
                </div>
              </div>
              <Divider />

              <Notification
                show={show}
                setShow={setShow}
                type="danger"
                title="Couldn't add project!"
                message="Please select a max of 25 projects to export"
              />
              <NotificationCofirmation
                show={ExportOpen}
                close={handleExportClose}
              />
              {projects.length ? (
                <InfiniteScroll
                  dataLength={projects.length} //This is important field to render the next data
                  next={() => fetchNext()}
                  hasMore={projects.length < projects_length}
                  loader={
                    <Player
                      autoplay
                      loop
                      src={animationData}
                      style={{ height: "150px", width: "150px" }}
                    >
                      {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                    </Player>
                  }
                  scrollableTarget="scrollableDiv"
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  // below props only if you need pull down functionality
                >
                  {projects.map((project, index) => {
                    return (
                      <ProjectCard
                        selectComodity={selectComodity}
                        setProject={setProject}
                        history={history}
                        key={index}
                        project={project}
                      />
                    );
                  })}
                </InfiniteScroll>
              ) : projects_loading ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <ContentLoader viewBox="0 0 600 100" height={100} width={600}>
                    <rect x="20" y="8" rx="0" ry="0" width="100" height="100" />
                    <rect
                      x="20"
                      y="120"
                      rx="0"
                      ry="0"
                      width="100"
                      height="10"
                    />
                    <rect x="130" y="8" rx="0" ry="0" width="500" height="15" />
                    <rect
                      x="130"
                      y="30"
                      rx="0"
                      ry="0"
                      width="500"
                      height="15"
                    />
                    <rect
                      x="130"
                      y="52"
                      rx="0"
                      ry="0"
                      width="300"
                      height="15"
                    />
                  </ContentLoader>
                  <ContentLoader viewBox="0 0 600 100" height={100} width={600}>
                    <rect x="20" y="8" rx="0" ry="0" width="100" height="100" />
                    <rect
                      x="20"
                      y="120"
                      rx="0"
                      ry="0"
                      width="100"
                      height="10"
                    />
                    <rect x="130" y="8" rx="0" ry="0" width="500" height="15" />
                    <rect
                      x="130"
                      y="30"
                      rx="0"
                      ry="0"
                      width="500"
                      height="15"
                    />
                    <rect
                      x="130"
                      y="52"
                      rx="0"
                      ry="0"
                      width="300"
                      height="15"
                    />
                  </ContentLoader>
                  <ContentLoader viewBox="0 0 600 100" height={100} width={600}>
                    <rect x="20" y="8" rx="0" ry="0" width="100" height="100" />
                    <rect
                      x="20"
                      y="120"
                      rx="0"
                      ry="0"
                      width="100"
                      height="10"
                    />
                    <rect x="130" y="8" rx="0" ry="0" width="500" height="15" />
                    <rect
                      x="130"
                      y="30"
                      rx="0"
                      ry="0"
                      width="500"
                      height="15"
                    />
                    <rect
                      x="130"
                      y="52"
                      rx="0"
                      ry="0"
                      width="300"
                      height="15"
                    />
                  </ContentLoader>
                </div>
              ) : (
                <div>
                  <img
                    src={require("../../../assets/ProspectorDesignAssets/empty_image.png")}
                    alt="empty"
                    style={{ height: "100px", marginTop: "50px" }}
                  />
                  <Headline6 color="gainsboro">
                    No available projects for your filters{" "}
                  </Headline6>
                </div>
              )}
            </>
          ) : value === "companies" ? (
            <>
              <div className="sticky">
                <div style={{ left: "75%", marginTop: "20px" }}>
                  <div className="flex-boy-row">
                    <Headline6
                      style={{
                        textAlign: "left",
                        width: "100%",
                        marginLeft: "5px",
                        padding: "10px",
                        textTransform: "uppercase",
                      }}
                    >
                      {tab}
                    </Headline6>
                    <div className="flex-boy-row"></div>
                  </div>

                  <div className="flex-boy-row  py-2">
                    <div
                      className="flex-boy-row "
                      style={{ flex: 1, position: "relative", height: "70px" }}
                    >
                      <SearchComponent
                        type="company"
                        placeholder={"Search For companies by Name"}
                      />
                      {/* <TextField variant='outlined' fullWidth placeholder='Search For companies by Name' /> */}
                    </div>
                    <div className="circle-div">
                      <TuneRoundedIcon
                        style={{ color: "grey" }}
                        onClick={() => setsearchMode(true)}
                      />
                    </div>
                    <div className="circle-div">
                      <SortOutlined
                        style={{ color: "grey" }}
                        onClick={() => setSortOpen(true)}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="flex-boy-row flex-boy-row-company"
                  style={{ width: "100%", flexWrap: "wrap" }}
                >
                  <div
                    className="flex-boy-row"
                    style={{ padding: "10px", flex: 1 }}
                  >
                    {companies_loading ? (
                      <div className="flex-boy-row">
                        <Player
                          autoplay
                          loop
                          src={animationData}
                          style={{ height: "100px", width: "100px" }}
                        >
                          {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                        </Player>

                        <Subtitle1>Loading</Subtitle1>
                      </div>
                    ) : (
                      <Subtitle1 style={{ fontSize: "20px" }}>
                        {companies_length.toLocaleString() ||
                          companies.length?.toLocaleString()}{" "}
                        Companies
                      </Subtitle1>
                    )}
                  </div>
                  <div className="flex-boy-row flex-boy-row-company">
                    <Dropdownfilter
                      filter="exchange"
                      options={Exchanges}
                      tab={tab}
                    />
                    <Dropdownfilter
                      filter="minerals"
                      options={extraMinerals}
                      tab={tab}
                    />
                    <Dropdownfilter
                      filter="countries"
                      options={extraRegions}
                      tab={tab}
                    />
                    <Dropdownfilter
                      filter="mineral resource levels"
                      options={resTypes.map((item) => item.name)}
                      tab={tab}
                    />
                    <TailwindButton
                      onClick={() => {
                        resetCompanyFilters();
                        filterCompanies();
                      }}
                      text={"Clear Filters"}
                    />
                  </div>
                </div>
              </div>
              {companies.length ? (
                <InfiniteScroll
                  dataLength={companies.length} //This is important field to render the next data
                  next={() => fetchNext()}
                  hasMore={companies.length < companies_length}
                  loader={
                    <Player
                      autoplay
                      loop
                      src={animationData}
                      style={{ height: "150px", width: "150px" }}
                    >
                      {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                    </Player>
                  }
                  scrollableTarget="scrollableDiv"
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  // below props only if you need pull down functionality
                >
                  {companies
                    .filter((item) => item)
                    .map((match, index) => {
                      return (
                        <CompanyCard
                          setCompany={setCompany}
                          history={history}
                          key={index}
                          match={match}
                        />
                      );
                    })}
                </InfiniteScroll>
              ) : companies_loading ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <ContentLoader viewBox="0 0 600 100" height={100} width={600}>
                    <rect x="20" y="8" rx="0" ry="0" width="100" height="100" />
                    <rect
                      x="20"
                      y="120"
                      rx="0"
                      ry="0"
                      width="100"
                      height="10"
                    />
                    <rect x="130" y="8" rx="0" ry="0" width="500" height="15" />
                    <rect
                      x="130"
                      y="30"
                      rx="0"
                      ry="0"
                      width="500"
                      height="15"
                    />
                    <rect
                      x="130"
                      y="52"
                      rx="0"
                      ry="0"
                      width="300"
                      height="15"
                    />
                  </ContentLoader>
                  <ContentLoader viewBox="0 0 600 100" height={100} width={600}>
                    <rect x="20" y="8" rx="0" ry="0" width="100" height="100" />
                    <rect
                      x="20"
                      y="120"
                      rx="0"
                      ry="0"
                      width="100"
                      height="10"
                    />
                    <rect x="130" y="8" rx="0" ry="0" width="500" height="15" />
                    <rect
                      x="130"
                      y="30"
                      rx="0"
                      ry="0"
                      width="500"
                      height="15"
                    />
                    <rect
                      x="130"
                      y="52"
                      rx="0"
                      ry="0"
                      width="300"
                      height="15"
                    />
                  </ContentLoader>
                  <ContentLoader viewBox="0 0 600 100" height={100} width={600}>
                    <rect x="20" y="8" rx="0" ry="0" width="100" height="100" />
                    <rect
                      x="20"
                      y="120"
                      rx="0"
                      ry="0"
                      width="100"
                      height="10"
                    />
                    <rect x="130" y="8" rx="0" ry="0" width="500" height="15" />
                    <rect
                      x="130"
                      y="30"
                      rx="0"
                      ry="0"
                      width="500"
                      height="15"
                    />
                    <rect
                      x="130"
                      y="52"
                      rx="0"
                      ry="0"
                      width="300"
                      height="15"
                    />
                  </ContentLoader>
                </div>
              ) : (
                <div className="flex-boy-column">
                  <img
                    src={require("../../../assets/ProspectorDesignAssets/empty_image.png")}
                    alt="empty"
                    style={{ height: "100px", marginTop: "50px" }}
                  />
                  <Headline6 color="gainsboro">
                    No available companies for your filters{" "}
                  </Headline6>
                </div>
              )}
            </>
          ) : value === "technical reports" ? (
            <ReportsGrid />
          ) : value === "drilling results" ? (
            <DrillingResults pro />
          ) : value === "Prospector Chat" ? (
            <ChatPage />
          ) : (
            <FillingsGrid />
          )}
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.authReducer.activeUser,
  reports: state.authReducer.reports_search,
  recent_news: state.authReducer.recent_news,
  recent_news_loading: state.authReducer.recent_news_loading,
  reports_length: state.authReducer.filtered_reports_length,
  companies_loading: state.authReducer.companies_loading,
  reports_loading: state.authReducer.reports_loading,
  project_start_after: state.authReducer.project_start_after,
  bulk_select_projects: state.authReducer.bulk_select_projects,
  report_filters: state.authReducer.report_search_filters,
});

const mapDispatchToProps = (dispatch) => ({
  selectBulk_projects: (data) =>
    dispatch({ type: "SET_SELECTED_BULK_PROJECTS", payload: data }),
  resetProjectFilters: (data) => dispatch({ type: "RESET_PROJECT_FILTERS" }),
  resetCompanyFilters: (data) => dispatch({ type: "RESET_COMPANY_FILTERS" }),
  resetReportFilters: (data) => dispatch({ type: "RESET_REPORTS_FILTERS" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
