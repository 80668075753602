/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import {
  MatchCard,
  Hexagon,
  ButtonCircle,
  Body1,
  Subtitle1,
  Headline6,
  Subtitle2,
  Button,
  NotifyDiv,
} from "./general";
import Logo from "../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon.png";

export const EmptyMatchList = ({ viewMore }) => {
  return (
    <NotifyDiv style={{ height: "70vh", boxShadow: "none" }}>
      <div>
        <Headline6>Wow! You've already reviewed all prospects!</Headline6>
        <Body1>looks like you are all out ?</Body1>
      </div>
      <img
        style={{
          height: "10vh",
        }}
        src={Logo}
        alt="logo"
      />
      <div></div>
      <div></div>
      <Button onClick={() => viewMore()}>View More</Button>
    </NotifyDiv>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EmptyMatchList);
