import React, { Fragment, useState, useEffect } from "react";

import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import CsvDownload from "react-json-to-csv";
import { connect } from "react-redux";

export const NotificatonConfirmation = ({
  stype,
  message,
  show,
  title,
  close,
  confirm,
  projects,
  bulk_select_projects,
}) => {
  const [projectsJson, setprojectsJson] = useState(null);
  useEffect(() => {
    fetchList();
    return () => {};
  }, [bulk_select_projects]);
  const fetchList = () => {
    let projects_json = [...bulk_select_projects].map((item) => {
      let new_obj = projects.find((project) => project.id === item);
      let objHeaders = {
        "Project Name": new_obj.projectName,
        Minerals: new_obj.minerals?.toString(),
        Stage: new_obj.stage,
        "Mineral Estimate": new_obj.mineralEstimate?.toString(),
        Country: new_obj.countries?.toString(),
      };
      return objHeaders;
    });
    setprojectsJson(projects_json);
  };
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
        style={{ zIndex: 1000 }}
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end pt-20">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-500 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-500 sm:translate-x-0"
            leave="transition ease-in duration-500"
            leaveFrom="opacity-500"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0 pt-0.5">
                    <InformationCircleIcon
                      className="h-6 w-6 text-blue-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1">
                    <p className="text-sm font-medium text-gray-900 text-left">
                      Export Projects.
                    </p>
                    <p className="mt-1 text-sm text-gray-500 text-left">
                      {bulk_select_projects.length
                        ? `Would you like to Download CSV of ${
                            bulk_select_projects.length > 25
                              ? bulk_select_projects.slice(0, 25).length
                              : bulk_select_projects.length
                          } select Projects `
                        : "Please select atleast one Project"}
                    </p>
                    <div className="mt-4 flex">
                      {projectsJson && projectsJson.length > 0 && (
                        <CsvDownload
                          data={projectsJson}
                          filename="Prospector Projects Export.csv"
                        >
                          {" "}
                          <button
                            onClick={() => {
                              close();
                            }}
                            type="button"
                            className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                          >
                            Confirm
                          </button>
                        </CsvDownload>
                      )}
                      <button
                        type="button"
                        onClick={() => {
                          close();
                        }}
                        className="ml-3 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                      >
                        Decline
                      </button>
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                      onClick={() => {
                        close();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  bulk_select_projects: state.authReducer.bulk_select_projects,
  projects: state.authReducer.projects_search || [],
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificatonConfirmation);
