/* This example requires Tailwind CSS v2.0+ */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Subtitle1 } from "../../../components";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Dropdown = ({ report, show_page }) => {
  return (
    <Menu
      as="div"
      className="absolute inline-block left-[60%] top-[22px] z-10 text-left"
    >
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  focus:ring-offset-2 focus:ring-offset-gray-100">
          Sections
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-fit min-w-80  h-80 overflow-y-scroll divide-y divide-gray-100  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {report.document_sections.map((section) => {
            return (
              <Menu.Item onClick={() => show_page(section.page_start - 1)}>
                <p className="text-sm p-2 cursor-pointer whitespace-nowrap capitalize">
                  {section.section}
                </p>
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
