import React, { useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts-fix";
import { apiRequest } from "../../../store/Actions/AuthActions";
import { nFormatter } from "../../../configure/helpers";
import _ from "lodash";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../assets/ProspectorDesignAssets/lottie2.json";
import ContentLoader from "react-content-loader";
// Resolves charts dependancy
charts(FusionCharts);

const Colunm = ({
  company,
  year,
  mineral,
  classification,
  production_basis,
  type,
}) => {
  const [dataSource, setdataSource] = useState(null);
  const persentile_calculation = (a, b) => {
    let value = (a / b) * 100;
    console.log(a, b, value);
    return value
      ? `top ${Math.ceil(value)}% of companies`
      : "company has no production data";
  };
  useEffect(() => {
    fetchData();
    return () => {};
  }, [mineral, year, production_basis, company]);

  const fetchData = async () => {
    const data = await apiRequest
      .get(
        `companies/${company.id}/mineral-reserve-resources-quantity-analysis?production_basis=${production_basis}&mineral=${mineral}&report_year=${year}&classification=${classification}`
      )
      .catch((err) => console.log(err));
    if (data?.data?.data) {
      const percentile = await apiRequest.get(
        `companies/${company.id}/mineral-reserve-resources-summary?production_basis=${production_basis}&classification=${classification}&mineral=${mineral}&report_year=${year}`
      );
      setdataSource({
        chart: {
          showYAxisValues: "0",
          showAlternateHGridColor: "0",
          divLineAlpha: "60",
          usePlotGradientColor: "0",
          showPlotBorder: "0",
          YAxisName: " ",
          divLineColor: "#ffffff",
          showDivLineValues: "0",
          showaxislines: "0",
          showXAxisValues: "0",

          ynumberprefix: "$",

          xAxisName: persentile_calculation(
            percentile.data.company_mineral_resource_reserve[0]
              ?.contained_metal,
            percentile.data.company_mineral_resource_reserve[1]
              ?.companies_contained_metal
          ),
          showBorder: "0",
          // showCanvasBorder: "0",
          canvasborderColor: "#B9E1F4",
          canvasborderThicknes: "0.5",
          canvasbgAlpha: "0",
          canvasbgcolor: "0",
          bgcolor: "#ffffff",
          color: "#000000",
          showValues: "0",
          palettecolors: "5d62b5",
        },
        data: _.orderBy(
          data.data.data.map((item) => ({
            label: item.label,
            value: item.value,
            toolText: `${item.company_name} ${nFormatter(item.value, 1)}`,
            color: item.label === company.id ? "#FFAB05" : "#1A9BDB",
          })),
          "value",
          ["desc", "asc"]
        ),
      });
    }
  };

  return (
    <>
      {dataSource ? (
        <ReactFusioncharts
          type="column2d"
          width="100%"
          height="350"
          dataFormat="JSON"
          dataSource={dataSource}
        />
      ) : (
        <ContentLoader
          viewBox="0 0 600 350"
          style={{ width: "100%", height: "350px" }}
        >
          <rect
            x="20"
            y="8"
            rx="0"
            ry="0"
            style={{ width: "100%", height: "350px" }}
          />
        </ContentLoader>
      )}
    </>
  );
};

export default Colunm;
