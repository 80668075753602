/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";
import Avatar from "react-avatar";
import {
  Subtitle1,
  Headline4,
  Headline5,
  Subtitle2,
  Headline6,
  Primary,
  Button,
  NotifyDiv,
  ProspectorDescription,
  Body1,
  Body2,
  ProspectorInterest,
  TailwindButton,
  TailwindButtonOutline,
} from "../general";
import {
  Edit,
  SettingsOutlined,
  AssignmentIndOutlined,
  PublicOutlined,
  HelpOutlineOutlined,
  ExitToAppOutlined,
  Cancel,
} from "@material-ui/icons";
import { updateUserProfile } from "../../store/Actions/AuthActions";

export const ProfileImage = ({
  activeUser,
  updateUserProfile,
  closeUserProfile,
}) => {
  const [selectedFile, setSelectedFile] = useState();
  const [event_log, setEvents] = useState(null);
  const [preview, setPreview] = useState();
  const inputFile = useRef(null);
  const [go, setContinue] = useState(false);
  const validate = () => {
    updateUserProfile(selectedFile);
  };
  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  return (
    <div className="profile-update">
      {activeUser?.profileUrl ? (
        <img src={preview || activeUser?.profileUrl} alt="profile" />
      ) : preview ? (
        <img src={preview} alt="profile" />
      ) : (
        <Avatar name={activeUser?.name || activeUser?.email} round size={150} />
      )}
      <div className="edit" onClick={onButtonClick}>
        <Edit style={{ color: "white", fontSize: 25 }} />
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={onSelectFile}
        />
      </div>
      <Edit style={{ color: "white", fontSize: 25 }} />
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={onSelectFile}
      />

      <div className="flex justify-between w-[200px]">
        <TailwindButtonOutline
          onClick={() => closeUserProfile()}
          text="Cancel"
        />

        <TailwindButton
          onClick={() => (selectedFile ? validate() : null)}
          text="Update"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.authReducer.activeUser,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserProfile: (data) => dispatch(updateUserProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileImage);
