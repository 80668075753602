/* eslint-disable no-unused-vars */
import React from "react";
import { connect, useSelector } from "react-redux";
import {
  searchClient,
  miningNewsSearchIndex,
  projectsSearchIndex,
  companiesSearchIndex,
} from "../../configure/constants";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  Index,
  Configure,
  connectStateResults,
  connectSearchBox,
  connectCurrentRefinements,
  ClearRefinements,
} from "react-instantsearch-dom";
import {
  Headline6,
  MainPage,
  Primary,
  Headline5,
  Body1,
  Body2,
  GreyBox,
  Button,
  Subtitle1,
  Subtitle2,
  BrowseListFilter,
  Hexagon,
} from "../general";
import {
  getCompanyDetail,
  getProjectDetail,
} from "../../store/Actions/AuthActions";
import { ProjectModel } from "../../models";
import { withRouter } from "react-router-dom";
export const SearchComponent = ({
  activeUser,
  history,
  getProjectDetail,
  setSupscriptionMessage,
  selectedProject,
  setProject,
  setCompany,
  getCompanyDetail,
  selectedCompany,
  setSupscriptionPopup,
}) => {
  const [searchState, setSearchState] = React.useState({});
  const elements = useSelector((state) => state.authReducer.minerals_list);
  const subscription = activeUser;
  const CompanyHit = ({ hit }) => {
    return (
      <div
        onClick={() => goToCompany(hit)}
        className="flex-boy-row"
        style={{ width: "100%", cursor: "pointer" }}
      >
        <div className="company-ticker" style={{ marginBottom: "2px" }}>
          <Subtitle1 color="#00AC69">
            {hit.yahoo_ticker || hit.google_tiker}
          </Subtitle1>
          <Body2>{hit.name}</Body2>
        </div>
        <div
          className="flex-boy-row"
          style={{ justifyContent: "normal", flexWrap: "wrap" }}
        >
          {hit.minerals
            ? hit.minerals
                .filter(
                  (item) =>
                    item.name !== "Not Available" &&
                    item.name !== "Combined" &&
                    item.name !== "Metric Tonnes"
                )
                .map((min, index) => {
                  return (
                    <Hexagon
                      key={index}
                      width={28}
                      style={{ fontSize: "0.6rem" }}
                    >
                      {elements.find(
                        (item) => item.name.toLowerCase() === min.toLowerCase()
                      )
                        ? elements
                            .find(
                              (item) =>
                                item.name.toLowerCase() === min.toLowerCase()
                            )
                            .chemical_symbol.substring(0, 4)
                        : min.substring(0, 4)}
                    </Hexagon>
                  );
                })
            : null}
        </div>
      </div>
    );
  };
  const ProjectHit = ({ hit }) => {
    if (hit) {
      hit = new ProjectModel(hit);
      return (
        <div
          onClick={() => goToProject(hit)}
          className="flex-boy-row"
          style={{ width: "100%", cursor: "pointer" }}
        >
          <div style={{ flex: 1 }}>
            <Headline6>{hit.reportName}</Headline6>
            <Subtitle2>{hit.stage}</Subtitle2>
          </div>

          <div
            className="flex-boy-row"
            style={{
              marginTop: "5px",
              height: "fit-content",
              flex: 1,
              justifyContent: "flex-end",
            }}
          >
            <div
              className="hit-image flex-boy-row"
              style={{
                backgroundImage:
                  "url('../../assets/ProspectorDesignAssets/Climate Smart Mining.png')",
              }}
            >
              <img
                style={{ width: "30px" }}
                src={
                  hit.reportImageURL ||
                  require("../../assets/ProspectorDesignAssets/Climate Smart Mining.png")
                }
                alt="hit"
              />
            </div>

            <div>
              {hit.investors
                ? hit.investors.map((investor) => {
                    return (
                      <Subtitle1
                        color="#00AC69"
                        style={{ textTransform: "capitalize" }}
                      >
                        {investor.ticker
                          ? ` ${investor.ticker}.${investor.exchange}`
                          : investor.name}
                      </Subtitle1>
                    );
                  })
                : null}

              <div
                className="flex-boy-row"
                style={{ justifyContent: "normal" }}
              >
                {hit.minerals
                  .filter(
                    (item) =>
                      item.name !== "Not Available" && item.name !== "Combined"
                  )
                  .map((min, index) => {
                    return (
                      <Hexagon
                        key={index}
                        width={20}
                        style={{ fontSize: "0.6rem" }}
                      >
                        {elements.find(
                          (item) =>
                            item.name.toLowerCase() === min.toLowerCase()
                        )
                          ? elements
                              .find(
                                (item) =>
                                  item.name.toLowerCase() === min.toLowerCase()
                              )
                              .chemical_symbol.substring(0, 4)
                          : min.substring(0, 4)}
                      </Hexagon>
                    );
                  })}
              </div>
            </div>
            {/* <div className="flex-boy-row" style={{marginTop:'5px',justifyContent:'normal',flexWrap:'wrap'}}>
                                      {states.map((status, index) => {
                                         return (
                                             <GreyBox style={{
                                                 margin: "2px",
                                                 width:'72px',
                                                 fontSize:'14px',
                                                 backgroundColor: hit.mineralEstimate.toString().includes(status) ?'#1A9BDB' : 'gainsboro',
                                                 color: hit.mineralEstimate.toString().includes(status)  ? 'white' : '#454555',
                                                 textTransform:'capitalize',
                                                 textAlign:'center'
                                             }} key={index}>{status}</GreyBox>
                                         )
                                     })}
                                     </div> */}
          </div>
          {/* {hit.countries.map((flag, index) => (<img key="flag" style={{ height: '25px' }} src={require(`../../assets/ProspectorDesignAssets/Flags/${flag.toLowerCase().replace(' ', '-')}.png`)} alt="region" />))} */}
        </div>
      );
    }
  };
  const NewsHit = ({ hit }) => {
    if (hit) {
      return (
        <div
          onClick={() => checkSubscription(hit.url)}
          style={{ cursor: "pointer" }}
        >
          <Subtitle1 className="hit-summary">{hit.summary}</Subtitle1>
          <div style={{ width: "100%", marginTop: "5px" }}>
            <Subtitle1 color="grey">{hit.source}</Subtitle1>
            {/* <Subtitle1 color="#00AC69" style={{textTransform:'capitalize'}}>
                          {hit.project.companyData.mic}:{hit.project.companyData.ticker}
                        </Subtitle1> */}
          </div>
        </div>
      );
    }
  };
  const checkSubscription = async (url) => {
    if (subscription) {
      if (subscription) {
        if (
          activeUser &&
          activeUser?.permissions.includes("access_43101_portal")
        ) {
          window.open(url, "_blank");
        } else {
          await setSupscriptionMessage("upgrade");
          setSupscriptionPopup(true);
        }
      } else {
        await setSupscriptionMessage("Login");
        setSupscriptionPopup(true);
      }
      clearSearch();
    }
  };

  const goToProject = async (project) => {
    await getProjectDetail({ id: project.objectID, history });
    clearSearch();
  };
  const goToCompany = async (project) => {
    await getCompanyDetail({ id: project.id, history });
    clearSearch();
  };
  // const checkCompany = () => {
  //   if (selectedCompany) {
  //     history.push({
  //       pathname: '/company-detail',
  //     });
  //     clearSearch()
  //   } else {
  //     setTimeout(() => {
  //       checkCompany()
  //     }, 5000);
  //   }
  // }

  const clearSearch = () => {
    setSearchState({ ...searchState, query: "" });
  };

  // const CustomStateResults = connectStateResults(Results);

  return (
    <div className="instant-search">
      <InstantSearch
        searchClient={searchClient}
        indexName={companiesSearchIndex}
        searchState={searchState}
        onSearchStateChange={setSearchState}
      >
        <Configure hitsPerPage={5} />
        <SearchBox
          translations={{
            submitTitle: "Submit your search query.",
            resetTitle: "Clear your search query.",
            placeholder: "Search for projects, companies and news here...",
          }}
        />
        <div
          style={{
            margin: "5px",
            backgroundColor:
              searchState && searchState.query && searchState.query !== ""
                ? "whitesmoke"
                : "transparent",
            border:
              searchState && searchState.query !== ""
                ? "gainsboro"
                : "transparent",
          }}
          className="right-panel"
        >
          {searchState && searchState.query ? (
            <Index indexName={companiesSearchIndex}>
              <Headline6 style={{ margin: "5px" }}>Companies</Headline6>
              <Hits hitComponent={CompanyHit} />
            </Index>
          ) : null}
          {searchState && searchState.query ? (
            <Index indexName={projectsSearchIndex}>
              <Headline6 style={{ margin: "5px" }}>Projects</Headline6>
              <Configure hitsPerPage={5} distinct />
              <Hits hitComponent={ProjectHit} />
            </Index>
          ) : null}
          {searchState && searchState.query ? (
            <Index indexName={miningNewsSearchIndex}>
              <Headline6 style={{ margin: "5px" }}>Mining News</Headline6>
              <Configure hitsPerPage={5} />
              <Hits hitComponent={NewsHit} />
            </Index>
          ) : null}
        </div>
      </InstantSearch>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedCompany: state.authReducer.selectedCompany,
  selectedProject: state.authReducer.selectedProject,
  activeUser: state.authReducer.activeUser,
});

const mapDispatchToProps = (dispatch) => ({
  getProjectDetail: (data) => dispatch(getProjectDetail(data)),
  setProject: (data) => dispatch({ type: "SET_PROJECT", payload: data }),
  setCompany: (data) => dispatch({ type: "SET_COMPANY", payload: data }),
  getCompanyDetail: (data) => dispatch(getCompanyDetail(data)),
  setSupscriptionPopup: (data) =>
    dispatch({ type: "SET_SUBSCRIPTION_POPUP", payload: data }),
  setSupscriptionMessage: (data) =>
    dispatch({ type: "SET_SUBSCRIPTION_MESSAGE", payload: data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchComponent));
