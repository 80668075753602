import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as CPS from "./ChatPage.styles";
import { FaSpinner, FaCheck, FaTimes } from "react-icons/fa";

const API_HOSTNAME = "https://chat.mineprospector.com/api";
// const API_HOSTNAME = "http://localhost:8000";



export const ChatPage = () => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isCreatingNewChat, setIsCreatingNewChat] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [isFetchingConversations, setIsFetchingConversations] = useState(false);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [deletingConversationId, setDeletingConversationId] = useState(null);
  const [isDeletingConversation, setIsDeletingConversation] = useState(false);
  const [isFetchingMessages, setIsFetchingMessages] = useState(false);
  const chatMessagesRef = useRef(null);
  const fileInputRef = useRef(null);

  const [editingConversationId, setEditingConversationId] = useState(null);
  const [editingConversationName, setEditingConversationName] = useState("");
  const [isUpdatingName, setIsUpdatingName] = useState(false);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };

  const predefinedPrompts = [
    { title: "Mergers and Acquisitions", icon: "🤝" },
    { title: "Latest Drill Results & Highlights", icon: "🎯" },
    { title: "Latest Technical Reports", icon: "📊" },
    { title: "Production results and updates", icon: "🚜" },
  ];

  const handleSendMessage = (text) => {
    if (text.trim() === "") return;
    const allMessages = [...messages, { content: text, role: "user" }];
    setMessages(allMessages);
    setInputText("");
    setIsLoading(true);

    sendMessage(currentConversationId, text);
  };

  const sendMessage = async (conversation_id, content) => {
    try {
      const resp = await fetch(`${API_HOSTNAME}/chat/message`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          conversation_id: conversation_id,
          content: content,
          attachments: [],
          timestamp: 0,
          filters: {
            minerals: ["All"],
            countries: ["All"],
            stages: ["All"],
          },
        }),
      });
      if (!resp.ok) {
        throw new Error(`HTTP error! status: ${resp.status}`);
      }

      // Set up the stream reader
      const reader = resp.body.getReader();
      const decoder = new TextDecoder();
      let streamComplete = false;
      let isFirstChunk = true;
      let accumulatedContent = "";

      while (!streamComplete) {
        const { done, value } = await reader.read();
        if (done) {
          streamComplete = true;
          continue;
        }

      const chunk = decoder.decode(value);

      accumulatedContent += chunk;

      // Update the message content
      if (isFirstChunk) {
        setMessages((currentMessages) => [
          ...currentMessages,
          {
            content: accumulatedContent,
            role: "assistant",
          },
        ]);
        isFirstChunk = false;
        setIsLoading(false);
      } else {
        setMessages((currentMessages) => {
          const updatedMessages = [...currentMessages];
          updatedMessages[updatedMessages.length - 1] = {
            content: accumulatedContent,
            role: "assistant",
          };
          return updatedMessages;
        });
        }
      }

      // setIsLoading(false);
    } catch (error) {
      console.error("Error sending message:", error);
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage(inputText);
    }
  };

  // TODO: Add file upload functionality
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setMessages([
        ...messages,
        { content: `File uploaded: ${file.name}`, role: "user" },
      ]);
    }
  };

  const chatStartNew = async () => {
    try {
      setCurrentConversationId(null);
      setIsCreatingNewChat(true);
      const resp = await fetch(`${API_HOSTNAME}/chat/start_new`, {
        method: "POST",
        headers: headers,
        credentials: "include",
      });
      if (!resp.ok) {
        throw new Error(`HTTP error! status: ${resp.status}`);
      }
      const data = await resp.json();

      setConversationHistory((conversationHistory) => [
        data,
        ...conversationHistory,
      ]);
      setCurrentConversationId(data.conversation_id);
      setMessages([]);
    } catch (error) {
      console.error("Error starting chat:", error);
    } finally {
      setIsCreatingNewChat(false);
    }
  };

  const chatStartExisting = async (conversation_id) => {
    setCurrentConversationId(conversation_id);
    try {
      setIsFetchingMessages(true);
      const resp = await fetch(`${API_HOSTNAME}/chat/start_existing`, {
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify({
          conversation_id: conversation_id,
        }),
      });
      if (!resp.ok) {
        throw new Error(`HTTP error! status: ${resp.status}`);
      }
      const data = await resp.json();
    } catch (error) {
      console.error("Error starting chat:", error);
    }
  };

  const fetchUserConversations = async () => {
    try {
      setIsFetchingConversations(true);
      const response = await fetch(
        `${API_HOSTNAME}/chat/user_conversations`,
        {
          method: "GET",
          headers: headers,
          credentials: "include", // This is important for CORS requests
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setConversationHistory(data);
    } catch (error) {
      console.error("Error fetching conversation history:", error);
    } finally {
      setIsFetchingConversations(false);
      displayedConversationsNew();
    }
  };

  const triggerFileUpload = () => {
    fileInputRef.current.click();
  };

  const newConversation = () => {
    chatStartNew();
  };

  const viewConversation = async (conversation_id) => {
    await chatStartExisting(conversation_id);
    try {
      const resp = await fetch(
        `${API_HOSTNAME}/chat/messages?conversation_id=${conversation_id}`,
        {
          method: "GET",
          headers: headers,
          credentials: "include", // This is important for CORS requests
        }
      );
      if (!resp.ok) {
        throw new Error(`HTTP error! status: ${resp.status}`);
      }
      const data = await resp.json();
      setMessages(data.messages);
      setCurrentConversationId(conversation_id);
    } catch (error) {
      console.error("Error fetching conversation history:", error);
      setMessages([]);
    } finally {
      setIsFetchingMessages(false);
    }
  };

  const startEditingConversation = (conversationId, currentName) => {
    setEditingConversationId(conversationId);
    setEditingConversationName(currentName);
  };

  const saveConversationName = async (conversationId) => {
    setIsUpdatingName(true);
    try {
      const oldName =
        conversationHistory.find(
          (conv) => conv.conversation_id === conversationId
        )?.conversation_name || "";
      setConversationHistory((prevHistory) =>
        prevHistory.map((conv) =>
          conv.conversation_id === conversationId
            ? { ...conv, conversation_name: editingConversationName }
            : conv
        )
      );

      const resp = await fetch(`${API_HOSTNAME}/chat/conversation_name`, {
        method: "POST",
        headers: headers,
        credentials: "include",
        body: JSON.stringify({
          conversation_id: conversationId,
          conversation_name: editingConversationName,
        }),
      });
      if (!resp.ok) {
        setConversationHistory((prevHistory) =>
          prevHistory.map((conv) =>
            conv.conversation_id === conversationId
              ? { ...conv, conversation_name: oldName }
              : conv
          )
        );
        throw new Error(`HTTP error! status: ${resp.status}`);
      }
      const data = await resp.json();
    } catch (error) {
      console.error("Error updating conversation name:", error);
    } finally {
      setIsUpdatingName(false);
      // fetchUserConversations();
      setEditingConversationId(null);
      setEditingConversationName("");
    }
  };

  const cancelDelete = () => {
    setDeletingConversationId(null);
  };

  const proceedWithDelete = async () => {
    if (deletingConversationId) {
      try {
        setIsDeletingConversation(true);
        const resp = await fetch(
          `${API_HOSTNAME}/chat/delete_chat?conversation_id=${deletingConversationId}`,
          {
            method: "DELETE",
            headers: headers,
            credentials: "include",
          }
        );
        if (!resp.ok) {
          throw new Error(`HTTP error! status: ${resp.status}`);
        }
        setConversationHistory((prevHistory) =>
          prevHistory.filter(
            (conv) => conv.conversation_id !== deletingConversationId
          )
        );
        if (currentConversationId === deletingConversationId) {
          setCurrentConversationId(null);
          setMessages([]);
        }
      } catch (error) {
        console.error("Error deleting conversation:", error);
        fetchUserConversations();
      } finally {
        setIsDeletingConversation(false);
      }
      setDeletingConversationId(null);
    }
  };

  const cancelEditing = () => {
    setEditingConversationId(null);
    setEditingConversationName("");
  };

  // const displayedConversations = searchTerm
  //   ? conversationHistory.filter((conv) =>
  //       conv.conversation_name.toLowerCase().includes(searchTerm.toLowerCase())
  //     )
  //   : conversationHistory;

  const getDateString = (dateString) => {
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  };

  const displayedConversationsNew = () => {
    const filtered = searchTerm
      ? conversationHistory.filter((conv) =>
          conv.conversation_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      : conversationHistory;
    const today = getDateString(new Date().toISOString());
    const yesterday = getDateString(
      new Date(today - 24 * 60 * 60 * 1000).toISOString()
    );
    const thirtyDaysAgo = getDateString(
      new Date(today - 30 * 24 * 60 * 60 * 1000).toISOString()
    );
    const groupedConversations = filtered.reduce((acc, conv) => {
      const date = getDateString(conv.conversation_created_at);

      let dateGroup;
      switch (true) {
        case date === today:
          dateGroup = "today";
          break;
        case date === yesterday:
          dateGroup = "yesterday";
          break;
        case date > thirtyDaysAgo:
          dateGroup = "lastMonth";
          break;
        default:
          dateGroup = new Date(conv.conversation_created_at).toLocaleDateString(
            "en-US",
            {
              month: "long",
              year: "numeric",
            }
          );
      }
      if (!acc[dateGroup]) {
        acc[dateGroup] = [];
      }
      acc[dateGroup].push(conv);
      return acc;
    }, {});

    return groupedConversations;
  };

  const displayedConversations = displayedConversationsNew();

  const DateGroups = {
    today: "Today",
    yesterday: "Yesterday",
    lastMonth: "Last 30 Days",
    [new Date().toLocaleDateString("en-US", {
      month: "long",
      year: "numeric",
    })]: (date) => {
      return date;
    },
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      if (chatMessagesRef.current) {
        chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
      } 
    });
  }, [messages, isLoading, currentConversationId]);

  useEffect(() => {
    fetchUserConversations();
  }, []);

  return (
    <CPS.ChatPageContainer>
      <CPS.Sidebar>
        <CPS.SearchBar
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <CPS.HistoryContainer>
          {isFetchingConversations && !isDeletingConversation ? (
            <CPS.LoadingContainer>
              <CPS.LoadingWheel />
            </CPS.LoadingContainer>
          ) : (
            Object.keys(displayedConversations).map((dateGroup) => (
              <CPS.DateHeader key={dateGroup}>
                {" "}
                {DateGroups[dateGroup] || dateGroup}
                {displayedConversations[dateGroup]?.map((conversation) => (
                  <CPS.HistorySection key={conversation.conversation_id}>
                    {editingConversationId === conversation.conversation_id ? (
                      <CPS.EditNameForm
                        onSubmit={(e) => {
                          e.preventDefault();
                          saveConversationName(conversation.conversation_id);
                        }}
                      >
                        <CPS.EditNameInput
                          value={editingConversationName}
                          onChange={(e) =>
                            setEditingConversationName(e.target.value)
                          }
                          autoFocus
                        />
                        <CPS.EditNameButton
                          type="button"
                          onClick={cancelEditing}
                          disabled={isUpdatingName}
                          title="Cancel"
                        >
                          <FaTimes />
                        </CPS.EditNameButton>
                        <CPS.EditNameButton
                          type="submit"
                          disabled={isUpdatingName}
                          title="Save"
                        >
                          {isUpdatingName ? (
                            <FaSpinner className="spinner" />
                          ) : (
                            <FaCheck />
                          )}
                        </CPS.EditNameButton>
                      </CPS.EditNameForm>
                    ) : (
                      <CPS.HistoryItem
                        onClick={() =>
                          viewConversation(conversation.conversation_id)
                        }
                        isSelected={
                          currentConversationId === conversation.conversation_id
                        }
                      >
                        <CPS.ChatIcon>💬</CPS.ChatIcon>
                        <CPS.ConversationName
                          data-full-text={conversation.conversation_name}
                        >
                          {conversation.conversation_name}
                        </CPS.ConversationName>
                        <CPS.EditButton
                          onClick={(e) => {
                            e.stopPropagation();
                            startEditingConversation(
                              conversation.conversation_id,
                              conversation.conversation_name
                            );
                          }}
                        >
                          ✏️
                        </CPS.EditButton>
                        <CPS.DeleteButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeletingConversationId(
                              conversation.conversation_id
                            );
                          }}
                        >
                          🗑️
                        </CPS.DeleteButton>
                      </CPS.HistoryItem>
                    )}
                  </CPS.HistorySection>
                ))}
              </CPS.DateHeader>
            ))
          )}
        </CPS.HistoryContainer>
        <CPS.NewChatButton onClick={newConversation}>
          New Chat
        </CPS.NewChatButton>
      </CPS.Sidebar>
      <CPS.ChatArea>
        {currentConversationId ? (
          <>
            {isFetchingMessages ? (
              <CPS.LoadingContainer>
                <CPS.LoadingWheel />
                <CPS.LoadingText>Loading messages...</CPS.LoadingText>
              </CPS.LoadingContainer>
            ) : (
              <>
                <CPS.ChatMessages ref={chatMessagesRef}>
                  {messages.map((msg, index) => (
                    <CPS.Message key={index} role={msg.role}>
                      {msg.content}
                    </CPS.Message>
                  ))}
                  {isLoading && <CPS.Ellipsis>...</CPS.Ellipsis>}
                </CPS.ChatMessages>
                <CPS.BottomSection>
                  <CPS.PromptArea>
                    {predefinedPrompts.map((prompt, index) => (
                      <CPS.PromptButton
                        key={index}
                        onClick={() => handleSendMessage(prompt.title)}
                      >
                        <CPS.PromptIcon>{prompt.icon}</CPS.PromptIcon>
                        {prompt.title}
                      </CPS.PromptButton>
                    ))}
                  </CPS.PromptArea>
                  <CPS.InputArea>
                    <CPS.AttachmentButton onClick={triggerFileUpload}>
                      📎
                    </CPS.AttachmentButton>
                    <CPS.HiddenFileInput
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileUpload}
                    />
                    <CPS.Input
                      value={inputText}
                      onChange={(e) => setInputText(e.target.value)}
                      onKeyUp={handleKeyPress}
                      placeholder="Type your message here..."
                    />
                    <CPS.SendButton
                      onClick={() => handleSendMessage(inputText)}
                    >
                      ➤
                    </CPS.SendButton>
                  </CPS.InputArea>
                </CPS.BottomSection>
              </>
            )}
          </>
        ) : isCreatingNewChat ? (
          <CPS.LoadingContainer>
            <CPS.LoadingWheel />
            <CPS.LoadingText>Creating new chat...</CPS.LoadingText>
          </CPS.LoadingContainer>
        ) : (
          <CPS.CenteredContainer>
            <CPS.NewChatButton onClick={newConversation}>
              Start New Chat
            </CPS.NewChatButton>
          </CPS.CenteredContainer>
        )}
      </CPS.ChatArea>
      {deletingConversationId && (
        <CPS.DeleteConfirmationPopup>
          <p>Are you sure you want to delete this conversation?</p>
          {isDeletingConversation ? (
            <CPS.CenteredContainer>
              <CPS.LoadingWheel />
            </CPS.CenteredContainer>
          ) : (
            <CPS.CenteredContainer>
              <CPS.DeleteConfirmButton onClick={proceedWithDelete}>
                Yes, delete
              </CPS.DeleteConfirmButton>
              <CPS.DeleteCancelButton onClick={cancelDelete}>
                Cancel
              </CPS.DeleteCancelButton>
            </CPS.CenteredContainer>
          )}
        </CPS.DeleteConfirmationPopup>
      )}
    </CPS.ChatPageContainer>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChatPage);
