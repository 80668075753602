/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  MatchCard,
  Hexagon,
  ButtonCircle,
  Body2,
  Subtitle1,
  Headline6,
  Subtitle2,
} from "../general";
import { Check, Close } from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog";

import List from "../List";
import Profile from "../../assets/ProspectorDesignAssets/Companies/Tudor Gold Corp.jpeg";
import SparkLine from "../SparkLine";
import _ from "lodash";
import Avatar from "react-avatar";
import {
  companyConnect,
  getCompanyDetail,
  matchUsers,
} from "../../store/Actions/AuthActions";
import { withRouter } from "react-router";
import Transition from "react-transition-group/Transition";
import { CSSTransition } from "react-transition-group";
const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
};

export const CompanyMatchCard = ({
  rotate,
  zIndex,
  pop,
  person,
  activeUser,
  match,
  matchUsers,
  history,
  setCompany,
  companyConnect,
  getCompanyDetail,
}) => {
  const company = person.companyData;
  const elements = useSelector((state) => state.authReducer.minerals_list);
  const [inProp, setInProp] = useState(true);
  const [classname, setClass] = useState("mycard");
  const [trend, setTrend] = useState({
    ratio: "1.4%",
    trend: "up",
  });
  useEffect(() => {
    const timer = setTimeout(() => {
      setInProp(true);
    }, 1000 / zIndex);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (company.stock_history) {
      calculateTrend();
    }

    return () => {};
  }, []);

  const calculateTrend = () => {
    let stock =
      company.stock_history && company.stock_history.data
        ? [...company.stock_history.data]
        : [];
    let sortStock = _.sortBy(stock, function (dateObj) {
      return new Date(dateObj.date);
    });
    let diff =
      stock.length > 1
        ? sortStock[sortStock.length - 1].open -
          sortStock[sortStock.length - 2].open
        : 0;
    let trendState =
      stock.length > 1
        ? sortStock[sortStock.length - 1].open -
            sortStock[sortStock.length - 2].open >=
          0
          ? "up"
          : "down"
        : "up";
    let ratio =
      stock.length > 1
        ? Math.abs(diff / sortStock[sortStock.length - 2].open) * 100
        : 0;

    setTrend({
      ratio: trendState === "up" ? ratio.toFixed(2) : `-${ratio.toFixed(2)}`,
      trend: trendState,
    });
  };
  const yay = () => {
    setClass("mycardLeft");
    setTimeout(() => {
      setInProp(false);
      setTimeout(() => {
        pop("liked", company.id);
      }, 500);
    }, 200);
  };

  const nope = () => {
    setClass("mycardRight");
    setTimeout(() => {
      setInProp(false);
    }, 200);
    setTimeout(() => {
      pop("rejected", company.id);
    }, 500);
  };
  const goToDetail = (id) => {
    getCompanyDetail({ id, history });
  };
  const [ListOpen, setListOpen] = useState(false);
  const handleListOpen = () => {
    setListOpen(true);
  };

  const handleListClose = (value) => {
    setListOpen(false);
  };
  const dummy_company = {
    name: "Miranda Werstiuk",
    description:
      "Artemis Gold Inc., a gold development company, focuses on the identification, acquisition, and development of gold properties. The company's asset portfolio includes a 39% strategic stake in Velocity Minerals Ltd. that focuses on acquiring, exploring, and evaluating mineral resource properties in Bulgaria. It also holds interest in the Blackwater Gold Project located in central British Columbia. The company was incorporated in 2019 and is headquartered in Vancouver, Canada",
    company: "OCIM Precious Metals",
    position: "Director Corporate Development",
    interests: {
      countries: ["canada", "united-states"],
      regions: ["asia", "australia", "latin america", "north america"],
      minerals: ["Au", "Ag", "Cu"],
    },
  };
  return (
    <CSSTransition
      in={inProp}
      timeout={300}
      classNames={classname || "mycard"}
      unmountOnExit
    >
      <MatchCard rotate={rotate} zIndex={zIndex}>
        {/* <Hexagon width={30}>
                <span>AU</span>
            </Hexagon> */}
        <Dialog
          onClose={handleListClose}
          aria-labelledby="simple-dialog-title"
          open={ListOpen}
        >
          <List
            handleClose={handleListClose}
            type={"company"}
            entity={company}
            yay={yay}
          />
        </Dialog>
        <div
          onClick={() => goToDetail(company.id)}
          className="card-body-company-match"
        >
          <div className="card-body-header">
            <div className="card-header-image">
              <Avatar name={company.name} round size={120} />
            </div>
            <div className="card-body-header-details">
              <div className="tiny-spark">
                {company.stock_history ? (
                  <SparkLine data={company.stock_history.data} />
                ) : null}
              </div>
            </div>
          </div>
          <div className="card-body-company-summary">
            <div className="company-ticker">
              <Subtitle1 color={trend.trend !== "up" ? "#B00020" : "#00AC69"}>
                {company.ticker
                  ? ` ${company.ticker}.${company.exchange}`
                  : "Private"}{" "}
                {company.stock_history && company.stock_history.meta
                  ? company.stock_history.meta.currency
                  : null}
              </Subtitle1>
              <Body2>{company.name}</Body2>
            </div>
            <div className="company-ticker">
              <Subtitle1>
                {company.stock_history && company.stock_history.marketCap
                  ? company.stock_history.marketCap
                  : null}
              </Subtitle1>
              <Body2>Market Cap</Body2>
            </div>
            <div className="company-ticker">
              <Body2>{company.projects_count} projects</Body2>
            </div>
          </div>
          <div className="card-body-interests">
            <div className="interest-list">
              {/* <div className="interest-row">
                                    {dummy_company.interests.countries.map((country, index) => {
                                        return (
                                            
                                        )
                                    })}
                                </div> */}
              <div className="interest-row" style={{ marginBottom: "5px" }}>
                {company.countries
                  ? company.countries.map((flag, index) => {
                      let flagSource;
                      try {
                        flagSource = require(`../../assets/ProspectorDesignAssets/Flags/${flag
                          .toLowerCase()
                          .replace(" ", "-")}.png`);
                      } catch {
                        flagSource = require(`../../assets/ProspectorDesignAssets/Flags/usa.png`);
                      }
                      return (
                        <img
                          key="flag"
                          className="project-flag"
                          style={{ height: "25px", marginLeft: "3px" }}
                          src={flagSource}
                          alt="region"
                        />
                      );
                    })
                  : null}
              </div>
              <div className="interest-row">
                {company.minerals
                  ? [
                      ...new Set(
                        company.minerals.filter(
                          (item) =>
                            item !== "Not Available" &&
                            item !== "Combined" &&
                            item !== "Metric Tonnes"
                        )
                      ),
                    ].map((min, index) => {
                      return (
                        <Hexagon
                          key={index}
                          width={28}
                          style={{ fontSize: "0.6rem" }}
                        >
                          {elements.find(
                            (item) =>
                              item.name.toLowerCase() ===
                              min.trim().toLowerCase()
                          )
                            ? elements
                                .find(
                                  (item) =>
                                    item.name.toLowerCase() ===
                                    min.trim().toLowerCase()
                                )
                                .chemical_symbol.substring(0, 4)
                            : min.substring(0, 4)}
                        </Hexagon>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>

          <div className="card-body-description">
            <p>{company.description}</p>
          </div>
        </div>
        <div className="card-actions">
          <ButtonCircle inputColor="#CF6679" onClick={() => nope()}>
            <Close style={{ fontSize: 40 }} />
          </ButtonCircle>
          <ButtonCircle
            inputColor="#00C8057A"
            onClick={() => setListOpen(true)}
          >
            <Check style={{ fontSize: 40 }} />
          </ButtonCircle>
        </div>
      </MatchCard>
    </CSSTransition>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  matchUsers: (data) => dispatch(matchUsers(data)),
  companyConnect: (data) => dispatch(companyConnect(data)),
  setCompany: (data) => dispatch({ type: "SET_COMPANY", payload: data }),
  getCompanyDetail: (data) => dispatch(getCompanyDetail(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompanyMatchCard));
