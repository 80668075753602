import { items } from "fusioncharts";
import React, { useEffect, useState, Fragment } from "react";
import { connect, useSelector } from "react-redux";
import { nFormatter, totalCalculator } from "../../../configure/helpers";
import { apiRequest } from "../../../store/Actions/AuthActions";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import Select from "react-select";
import { Listbox, Transition } from "@headlessui/react";
import {
  Body1,
  Body2,
  CheckIcon,
  ChevronUpDownIcon,
  Headline6,
  Subtitle1,
} from "../../../components";
import _ from "lodash";
import { Popover } from "@material-ui/core";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../assets/ProspectorDesignAssets/lottie2.json";
const customStyles = {
  control: (base) => ({
    ...base,
    fontSize: "x-large",

    minHeight: 50,
  }),
};

const classifications = [
  "Proven",
  "Probable",
  "Measured",
  "Indicated",
  "Inferred",
];
const data_types = ["Tonnage", "Grade", "Contained Metal"];
const Table = ({
  company,
  year,
  mineral,
  classification,
  production_basis,
  setGridData,
  type,
}) => {
  const elements = useSelector((state) => state.authReducer.minerals_list);
  const [tableData, settableData] = useState([]);
  const pdfExportComponent = React.useRef(null);
  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  useEffect(() => {
    fetchData();
    return () => {};
  }, [company, mineral, year, production_basis]);
  useEffect(() => {
    setselected_mineral(company.r_r_minerals?.[0]);

    return () => {};
  }, [company]);
  const [selected_mineral, setselected_mineral] = useState();

  useEffect(() => {
    fetchData();
    // getSummary()
    return () => {};
  }, [selected_mineral]);

  const getSummary = async () => {
    const summary = await apiRequest
      .get(
        `/companies/${company.id}/mineral-reserve-resource-summary?production_basis=${production_basis}&mineral=${mineral}&report_year=${year}`
      )
      .catch((err) => console.log(err));
    if (summary?.data) {
      console.log("🥵", summary.data);
    }
  };

  const fetchData = async () => {
    if (selected_mineral) {
      const data = await apiRequest
        .get(
          `/companies/${company.id}/projects-mineral-reserve-resources-analysis?mineral=${selected_mineral}&report_year=${year}&production-basis=${production_basis}`
        )
        .catch((err) => console.log(err));
      if (data?.data?.data) {
        let companies = data.data.data;
        console.log(data.data.data);

        settableData(data.data.data);
        setGridData(data.data.data);
      }
    }
  };

  function flatten(arr) {
    return arr.reduce(function (flat, toFlatten) {
      return flat.concat(
        Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
      );
    }, []);
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [annotationData, setAnnotationData] = useState(null);

  const getAnnotation = async (id) => {
    setAnnotationData(id);
    // setAnnotationData(null);
    // const data = await apiRequest.get(`/projects-mineral-reserve-resources-analysis/${id}`);

    // if (data?.data.data) {
    //   setAnnotationData(data.data.data);
    // }
  };
  const handleClick = (event, id) => {
    getAnnotation(id);
    // setAnnotationData(id)
    setAnchorEl(event.currentTarget);
    // setspinEnabled(false);
  };
  const [trend, setTrend] = useState({
    ratio: "1.4%",
    trend: "up",
  });

  const handlepopClose = () => {
    setAnchorEl(null);
    handleClosePop();
  };
  const handleClosePop = () => {
    setAnchorEl(null);
    // setspinEnabled(true);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="w-[400px] h-fit p-4">
          {annotationData ? (
            <>
              <Headline6>Metric Details</Headline6>
              <div className="rounded w-full p-2 border-[1px] border-gray-400">
                <div className="flex flex-row justify-between w-full">
                  <Body2>{annotationData.report_year}</Body2>
                  <Body2>{annotationData.classification}</Body2>
                </div>
                <div className="flex flex-row justify-between w-full">
                  <Subtitle1>{annotationData.data_type}</Subtitle1>
                  <Subtitle1>
                    {nFormatter(annotationData.amount?.toFixed(2), 2)}{" "}
                    {annotationData.measurement} {mineral}
                  </Subtitle1>
                </div>
              </div>
              <div className="rounded bg-slate-100 p-2 mt-2">
                <div className="py-4 justify-between w-full whitespace-normal break-words">
                  <Body2>Source Document</Body2>
                  <p
                    className="bold text-sm whitespace-normal cursor-pointer"
                    onClick={() => {
                      if (annotationData.citation_document_id) {
                        window.open(
                          `${process.env.REACT_APP_REDIRECT_DOMAIN}report-detail/${annotationData.citation_document_id}`,
                          "_blank"
                        );
                      }
                    }}
                  >
                    {annotationData.citation_document_name ||
                      annotationData.project_name ||
                      annotationData.company_name}
                  </p>
                </div>
                <div className="flex flex-row justify-between w-full">
                  <div>
                    <Body1>Section</Body1>
                    <Subtitle1>{annotationData.section}</Subtitle1>
                  </div>
                  <div>
                    <Body1>Figure</Body1>
                    <Subtitle1>{annotationData.figure}</Subtitle1>
                  </div>
                  <div>
                    <Body1>page</Body1>
                    <Subtitle1>{annotationData.page}</Subtitle1>
                  </div>
                </div>
                <div className="w-full py-4">
                  <Body2>Annotations</Body2>
                  <Subtitle1>{annotationData.annotation}</Subtitle1>
                </div>
              </div>
            </>
          ) : (
            <Player
              autoplay
              loop
              src={animationData}
              style={{ height: "200px", width: "200px" }}
            >
              {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
            </Player>
          )}
        </div>
      </Popover>
      <div className="px-4 sm:px-6 lg:px-4 w-full">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900"></h1>
          </div>
          {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-iemerald-500 focus:ring-offset-2 sm:w-auto"
              onClick={exportPDFWithComponent}
            >
              Export
            </button>
          </div> */}
        </div>

        <div className="flex flex-col max-h-[500px] ">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="table-fixed  min-w-full divide-y divide-black-300">
                  <thead className="bg-slate-100">
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 left-0 border-b border-black-300 z-20 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter "
                      >
                        <Select
                          displayEmpty
                          menuPortalTarget={document.body}
                          styles={customStyles}
                          onChange={(e) => setselected_mineral(e.value)}
                          placeholder={"mineral"}
                          value={{
                            value: selected_mineral,
                            label: `${selected_mineral} - ${
                              elements.find(
                                (item) =>
                                  item.chemical_symbol?.toLowerCase() ===
                                  selected_mineral?.trim().toLowerCase()
                              )
                                ? elements.find(
                                    (item) =>
                                      item.chemical_symbol.toLowerCase() ===
                                      selected_mineral?.trim().toLowerCase()
                                  ).name
                                : selected_mineral
                            }`,
                          }}
                          options={company.r_r_minerals.map((min) => {
                            let mineral_label = elements.find(
                              (item) =>
                                item.chemical_symbol.toLowerCase() ===
                                min.trim().toLowerCase()
                            )
                              ? elements.find(
                                  (item) =>
                                    item.chemical_symbol.toLowerCase() ===
                                    min.trim().toLowerCase()
                                ).name
                              : min;

                            return {
                              value: min,
                              label: `${min} - ${mineral_label}`,
                            };
                          })}
                        />
                      </th>
                      {classifications.map((classification, index) => {
                        return (
                          <th
                            scope="col"
                            className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 pl-3 backdrop-blur backdrop-filter  whitespace-nowrap   py-3.5  text-center text-sm font-semibold text-gray-900"
                          >
                            <p className="bold text-center text-sm">
                              {classification}
                            </p>
                            <div className="flex  ">
                              {data_types.map((type, index) => (
                                <p className="bold ml-1 text-right text-xs flex-1 pr-2">
                                  {tableData[Object.keys(tableData)[0]]
                                    ?.find((item) => item.data_type === type)
                                    ?.measurement?.toLocaleString()}{" "}
                                </p>
                              ))}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {Object.keys(tableData).map((item, index) => (
                      <tr
                        key={item}
                        className={
                          index % 2 === 0 ? undefined : "bg-emerald-50"
                        }
                      >
                        <td
                          className={`whitespace-nowrap align-center px-2 sticky left-0 ${
                            index % 2 === 0 ? "bg-white" : "bg-emerald-50"
                          } shadow-2xl text-sm font-medium text-gray-900`}
                        >
                          <div className="flex justify-between">
                            {`${item}`}
                            <p className="ml-2">
                              {tableData[item][0].net_interest}%
                            </p>
                          </div>
                        </td>

                        {classifications.map((classification, index) => {
                          return (
                            <td className="whitespace-nowrap  px-1  py-3.5  text-xs text-gray-500">
                              <div className="flex justify-between">
                                {data_types.map((type, index) => {
                                  return (
                                    <p
                                      onClick={(e) => {
                                        handleClick(
                                          e,
                                          tableData[item].find(
                                            (item) =>
                                              item.classification ===
                                                classification &&
                                              item.data_type === type
                                          )
                                        );
                                      }}
                                      className="bold text-right ml-2 text-black-400   pr-2 flex-1 cursor-pointer "
                                    >
                                      {" "}
                                      {production_basis === "full_basis"
                                        ? nFormatter(
                                            tableData[item]
                                              .find(
                                                (item) =>
                                                  item.classification ===
                                                    classification &&
                                                  item.data_type === type
                                              )
                                              ?.amount?.toFixed(2),
                                            2
                                          )
                                        : nFormatter(
                                            tableData[item]
                                              .find(
                                                (item) =>
                                                  item.classification ===
                                                    classification &&
                                                  item.data_type === type
                                              )
                                              ?.attributional_basis?.toFixed(2),
                                            2
                                          )}
                                      {
                                        tableData[item].find(
                                          (item) =>
                                            item.year === classification &&
                                            item.metric_name === type
                                        )?.metric_uom
                                      }{" "}
                                    </p>
                                  );
                                })}
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="bg-slate-100 py-5">
                    <tr>
                      <td className=" sticky left-0  bg-slate-100">
                        <Headline6>Total</Headline6>
                      </td>
                      {classifications.map((classification, index) => {
                        return (
                          <td className="whitespace-nowrap  px-1  py-3.5  text-sm text-gray-500">
                            <div className="flex justify-between ">
                              {tableData &&
                                data_types.map((type, index) => {
                                  return (
                                    <p className="bold text-xs ml-2  text-right text-black-400   pr-2 flex-1 ">
                                      {" "}
                                      {type === "Grade"
                                        ? nFormatter(
                                            totalCalculator(
                                              tableData[
                                                Object.keys(tableData)[0]
                                              ]?.find(
                                                (item) =>
                                                  item.data_type === "Grade"
                                              )?.measurement,
                                              flatten(Object.values(tableData))
                                                .filter(
                                                  (item) =>
                                                    item.classification ===
                                                      classification &&
                                                    item.data_type ===
                                                      "Contained Metal"
                                                )
                                                .map((item) =>
                                                  production_basis ===
                                                  "full_basis"
                                                    ? item.amount
                                                    : item.attributional_basis
                                                ),
                                              flatten(Object.values(tableData))
                                                .filter(
                                                  (item) =>
                                                    item.classification ===
                                                      classification &&
                                                    item.data_type === "Tonnage"
                                                )
                                                .map((item) =>
                                                  production_basis ===
                                                  "full_basis"
                                                    ? item.amount
                                                    : item.attributional_basis
                                                )
                                            )?.toFixed(2),
                                            2
                                          )
                                        : nFormatter(
                                            flatten(Object.values(tableData))
                                              .filter(
                                                (item) =>
                                                  item.classification ===
                                                    classification &&
                                                  item.data_type === type
                                              )
                                              .reduce(function (acc, obj) {
                                                return production_basis ===
                                                  "full_basis"
                                                  ? acc + obj?.amount
                                                  : acc +
                                                      obj?.attributional_basis;
                                              }, 0),

                                            // tableData[item]
                                            //   .find(
                                            //     (item) =>
                                            //       item.classification ===
                                            //       classification &&
                                            //       item.data_type === type
                                            //   )
                                            //   ?.amount ?

                                            2
                                          )}{" "}
                                    </p>
                                  );
                                })}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
