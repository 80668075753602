import React from "react";
import { AgGridReact } from "ag-grid-react";

const DetailCellRenderer = ({ data }) => {
  const colDefs = [
    { headerName: "Length (meters)", field: "length_meters", autoHeight: true },
    { headerName: "Mineral", field: "mineral" },
    { headerName: "Grade", field: "grade_value" },
    { headerName: "Grade UOM", field: "grade_uom" },
    { headerName: "Depth (meters)", field: "depth_meters" },
  ];

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    filter: true,
    sortable: true,
  };

  return (
    <div className="full-width-panel p-4 h-fit">
      <div className="full-width-details">
        <div className="full-width-detail w-full text-wrap py-2">
          <p>{data.highlights}</p>
        </div>
      </div>
      <div
        className="ag-theme-custom"
        style={{ height: "300px", width: "100%" }}
      >
        <AgGridReact
          className="ag-theme-alpine"
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          rowData={data.intercepts}
        />
      </div>
    </div>
  );
};

export default DetailCellRenderer;
