/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import Logo from "../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon1.png";
import {
  getProjectDetail,
  loginWithNLProjectPortal,
} from "../store/Actions/AuthActions";
import {
  Body1,
  Subtitle1,
  Headline6,
  Headline4,
  Subtitle2,
  Hexagon,
  Button,
  NotifyDiv,
  Primary,
  RightMessageBubble,
  LeftMessageBubble,
} from ".";

import {
  Send,
  AttachFile,
  KeyboardBackspace,
  DateRange,
  Cancel,
} from "@material-ui/icons";
import { TextField, Dialog, Popover, Badge, Tooltip } from "@material-ui/core";

import moment from "moment";
import { withRouter } from "react-router";
import _ from "lodash";
import axios from "axios";
import { ProjectModel } from "../models";

export const MapMarker = ({
  setHoverProject,
  project,
  history,
  getProjectDetail,
  loginWithNLProjectPortal,
  activeUser,
  setspinEnabled,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const elements = useSelector((state) => state.authReducer.minerals_list);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setspinEnabled(false);
  };
  const [trend, setTrend] = useState({
    ratio: "1.4%",
    trend: "up",
  });

  const handlepopClose = () => {
    setAnchorEl(null);
    handleClosePop();
  };
  const handleClosePop = () => {
    setAnchorEl(null);
    setspinEnabled(true);
  };
  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const goToDetail = () => {
    if (activeUser) {
      getProjectDetail({ id: project.id, history });
    } else {
      loginWithNLProjectPortal({
        path: `#/project-detail/${project.id}`,
        history,
      });
    }
  };
  const getProjectInfo = async (id) => {
    const data = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/projects/${id}`
    );
    if (data) {
      setProjectData(new ProjectModel(data.data.data));
      console.log(projectData);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="marker">
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="marker-popover">
          <div
            style={{ padding: "10px", cursor: "pointer" }}
            onClick={() => goToDetail()}
          >
            {projectData?.report_date && (
              <div style={{ width: "100%", marginBottom: "10px" }}>
                <Subtitle2 color="grey">
                  {moment(projectData?.report_date).format("DD MMM YYYY")}
                </Subtitle2>
              </div>
            )}
            <Subtitle1 color="grey">
              {project.name || project.projectName}
            </Subtitle1>
          </div>
          <div
            className="flex-boy-row"
            style={{ width: "100%", padding: "10px", boxSizing: "border-box" }}
          >
            {projectData && projectData.investors
              ? projectData.investors.map((investor) => {
                  return (
                    <Subtitle1
                      color="#454555"
                      style={{ textTransform: "capitalize" }}
                    >
                      {investor.ticker
                        ? ` ${investor.ticker}.${investor.exchange}`
                        : investor.name}
                    </Subtitle1>
                  );
                })
              : null}
          </div>

          {projectData ? (
            <div
              className="flex-boy-row"
              style={{ marginTop: "10px", padding: "10px" }}
            >
              <div
                className="flex-boy-row"
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div className="flex-boy-row">
                  {projectData.minerals
                    .filter(
                      (item) => item !== "Not Available" && item !== "Combined"
                    )
                    .map((min, index) => {
                      return (
                        <Hexagon
                          color="#454555"
                          key={index}
                          width={20}
                          style={{ fontSize: "0.6rem" }}
                        >
                          {elements.find(
                            (item) =>
                              item.name.toLowerCase() === min.toLowerCase()
                          )
                            ? elements
                                .find(
                                  (item) =>
                                    item.name.toLowerCase() ===
                                    min.toLowerCase()
                                )
                                .chemical_symbol.substring(0, 4)
                            : min.substring(0, 4)}
                        </Hexagon>
                      );
                    })}
                </div>
                <Subtitle2 color="grey">{projectData.stage}</Subtitle2>
              </div>
            </div>
          ) : null}
        </div>
      </Popover>
      <img
        onClick={(e) => {
          getProjectInfo(project.id);
          handleClick(e);
        }}
        style={{ height: "3vh" }}
        src={Logo}
        alt="marker"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  activeUser: state.authReducer.activeUser,
});

const mapDispatchToProps = (dispatch) => ({
  getProjectDetail: (data) => dispatch(getProjectDetail(data)),
  loginWithNLProjectPortal: (data) => dispatch(loginWithNLProjectPortal(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MapMarker));
