import React, { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { AddressElement } from "@stripe/react-stripe-js";
import { apiRequest } from "../../../store/Actions/AuthActions";
import _ from "lodash";
const UpdateBillingInformationForm = ({ customer, updateUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    const addressElement = elements.getElement("address");

    const { complete, value } = await addressElement.getValue();

    if (complete) {
      // Allow user to proceed to the next step
      // Optionally, use value to store the address details
      updateUser({
        ...customer,
        ...value,
        address: _.omitBy(value.address, _.isNil),
        description: customer.description || value.name,
        shipping: customer.shipping
          ? _.omitBy(customer.shipping, _.isNil)
          : { address: value.address, name: value.name, phone: value.phone },
      });
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      {customer && (
        <AddressElement
          options={{
            mode: "billing",
            defaultValues: {
              phone: customer?.phone,
              email: customer?.email,
              // address: customer?.address,
              name: customer?.name,
            },
            fields: {
              phone: "always",
              email: "always",
            },
          }}
        />
      )}
      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <button
        className="inline-flex text-center mt-8 items-center rounded-md border w-full border-transparent  bg-emerald-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
        type="submit"
        disabled={!stripe || isLoading}
      >
        <p className="text-center w-full">
          {isLoading ? "Loading..." : "Save"}
        </p>
      </button>
    </form>
  );
};

export default UpdateBillingInformationForm;
