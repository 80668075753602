import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Chip, Dialog, Divider } from "@material-ui/core";
import {
  Body1,
  Headline5,
  Headline6,
  Primary,
  Subtitle1,
  Headline4,
  GreyBox,
} from "..";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from "@material-ui/core/Popover";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ListIcon from "@material-ui/icons/List";
import {
  DeleteForever,
  DeleteOutline,
  DeleteOutlineTwoTone,
  CloseSharp,
} from "@material-ui/icons";
import Avatar from "@material-ui/core/Avatar";
import { apiRequest, getLists } from "../../store/Actions/AuthActions";
import ListPage from "./ListPage";
import { toast } from "react-toastify";
export const UserLists = ({ lists, history }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedList, setselectedList] = useState(null);
  const [listView, setlistView] = useState(false);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    fetchMyLists();
    return () => {};
  }, []);
  const fetchMyLists = async () => {
    const response = await apiRequest
      .get("/me/private-lists")
      .catch((error) => {});
    if (response) {
      setmyLists(response.data.data);
    }
  };
  const [myLists, setmyLists] = useState(lists);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [NotesOpen, setNotesOpen] = useState(false);
  const handleDeleteClose = () => {
    setConfirmDelete(false);
    setAnchorEl(null);
  };
  const [confirmDelete, setConfirmDelete] = useState(false);
  const deleteNote = async () => {
    const toastId = toast("Deleting List", { type: "info", autoClose: false });
    const data = await apiRequest
      .delete(`me/private-lists/${selectedList.id}`)
      .catch((error) => {
        toast.update(toastId, {
          theme: "light",
          render: error.response.data.message,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
        });
      });
    if (data) {
      toast.update(toastId, {
        theme: "light",
        render: "List Deleted",
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
      dispatch(getLists());
      fetchMyLists();
      handleDeleteClose();
    }
  };
  const handleClickOpen = () => {
    setNotesOpen(true);
  };

  const handleNotesClose = (value) => {
    setNotesOpen(false);
  };
  const fetchIcons = async () => {
    const response = await apiRequest
      .get("/config/emoticons")
      .catch((error) => {});
    if (response) {
      seticons(response.data);
    }
  };
  const [icons, seticons] = useState([]);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Dialog
        onClose={handleDeleteClose}
        aria-labelledby="simple-dialog-title"
        open={confirmDelete}
      >
        <div style={{ padding: "20px" }}>
          <Subtitle1>Are you sure you want to delete this List</Subtitle1>

          <div
            className="flex-boy-row"
            style={{ justifyContent: "flex-end", marginTop: "20px" }}
          >
            <GreyBox
              onClick={() => {
                setConfirmDelete(false);
                setAnchorEl(null);
              }}
              className="connect-button"
              style={{
                height: "20px",
                fontWeight: "bold",
                fontSize: "0.8rem",
                textTransform: "uppercase",
                color: Primary,
                backgroundColor: Primary,
                width: "75px",
                textAlign: "center",
                borderRadius: "5px",
                marginRight: "10px",
              }}
            >
              Cancel
            </GreyBox>
            <GreyBox
              onClick={() => {
                deleteNote();
              }}
              style={{
                height: "20px",
                fontWeight: "bold",
                fontSize: "0.8rem",
                textTransform: "uppercase",
                color: "white",
                backgroundColor: "#00AC69",
                marginRight: "10px",
                cursor: "pointer",
              }}
            >
              {" "}
              Delete
            </GreyBox>
          </div>
        </div>
      </Dialog>

      <div style={{ textAlign: "left", padding: "30px" }}>
        <div className="note-header bg-white py-5" style={{ top: -5 }}>
          <Headline4>Your Lists</Headline4>
          <div
            className="note-header flex-boy-row bg-white"
            style={{ marginBottom: "5px", marginTop: "20px" }}
          >
            <Headline6 style={{ flex: 1, color: "grey" }}>List Name</Headline6>
            <Headline6 style={{ flex: 1, color: "grey", marginRight: "20px" }}>
              Number of Prospects
            </Headline6>
            <Headline6 style={{ flex: 1, color: "grey" }}>Type</Headline6>

            <MoreVertIcon style={{ color: "white" }} />
          </div>
        </div>

        <Divider />
        <div>
          <Dialog
            fullWidth={true}
            maxWidth={"md"}
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={NotesOpen}
          >
            {/* <Notes handleClose={handleNotesClose} type={"project"} entity={project} /> */}
          </Dialog>

          {myLists.map((item) => (
            <div
              onClick={() => setselectedList(item)}
              className="notes-body flex-boy-row hover-icon "
              style={{
                paddingBottom: "15px",
                paddingTop: "15px",
                cursor: "pointer",
              }}
            >
              <div
                onClick={() => {
                  history.push(`/connections/lists/${item.id}`);
                }}
                className="flex-boy-row"
                style={{ flex: 1, justifyContent: "normal" }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={item.icon_url || require("../../assets/030-light.svg")}
                />

                <Subtitle1 style={{ marginLeft: "10px" }}>
                  {item.name}
                </Subtitle1>
              </div>

              <Subtitle1
                onClick={() => {
                  history.push(`/connections/lists/${item.id}`);
                }}
                style={{ flex: 1, marginRight: "20px" }}
              >
                {item.items_count}
              </Subtitle1>
              <Subtitle1
                onClick={() => {
                  history.push(`/connections/lists/${item.id}`);
                }}
                style={{ flex: 1 }}
              >
                {item.list_entries_type}
              </Subtitle1>

              <MoreVertIcon className="hover-icon" onClick={handleClick} />
              <Divider />
            </div>
          ))}

          <Divider />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div style={{ padding: "20px", cursor: "pointer" }}>
              <div
                onClick={() => {
                  history.push(`/connections/lists/${selectedList.id}`);
                }}
                className="flex-boy-row"
                style={{ justifyContent: "normal", marginBottom: "20px" }}
              >
                <EditOutlinedIcon style={{ marginRight: "10px" }} />
                <Headline6>Edit List</Headline6>
              </div>
              <div
                onClick={() => setConfirmDelete(true)}
                className="flex-boy-row"
                style={{ justifyContent: "normal" }}
              >
                <DeleteOutlineTwoTone style={{ marginRight: "10px" }} />
                <Headline6>Remove List</Headline6>
              </div>
            </div>
          </Popover>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  lists: state.authReducer.lists,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserLists);
