/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Subtitle1,
  Headline5,
  Subtitle2,
  Button,
  NotifyDiv,
} from "../../components";
import { TextField } from "@material-ui/core";
import Logo from "../../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon.png";
import { makeStyles } from "@material-ui/core/styles";
import {
  userLogin,
  linkedinLogin,
  appleLogin,
  loginWithNLPortal,
} from "../../store/Actions/AuthActions";
import { appEnvProduction } from "../../configure/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00AC69",
        border: "2px solid",
      },
      "&:hover fieldset": {
        borderColor: "#00AC69",
      },
    },
  },
}));

export const Login = ({
  history,
  userLogin,
  activeUser,
  linkedinLogin,
  appleLogin,
  loginWithNLPortal,
}) => {
  const classes = useStyles();
  useEffect(() => {
    switchAuth();
  }, [activeUser]);
  const switchAuth = () => {
    if (activeUser) {
      history.push("/browse");
    } else {
      loginWithNLPortal({ history });
    }
  };
  const [go, setContinue] = useState(true);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [errors, seterrors] = useState({
    email: null,
    password: null,
  });
  const change_text = (e) => {
    let new_data = { ...data };
    let new_errors = { ...errors };
    //

    let id = e.target.name;
    if (id === "email") {
      if (!/\S+@\S+\.\S+/.test(e.target.value)) {
        new_errors.email = "Invalid email address";
        seterrors(new_errors);
      } else {
        new_errors.email = null;
        seterrors(new_errors);
      }
      setContinue(true);
    }
    new_data[e.target.name] = e.target.value;
    setData(new_data);
  };

  const validate = () => {
    let new_data = { ...data };
    let new_errors = { ...errors };
    if (new_data.email === "") {
      new_errors.email = "Please enter Your email address";
    } else if (!/\S+@\S+\.\S+/.test(new_data.email)) {
      new_errors.email = "Invalid email address";
      seterrors(new_errors);
    } else if (new_data.password === "") {
      new_errors.password = "Please enter Your Password";
      seterrors(new_errors);
    } else {
      userLogin(new_data);
    }
  };
  return (
    <NotifyDiv>
      <Headline5 color="#00AC69">Welcome Back!</Headline5>
      <img style={{ height: "22vh" }} src={Logo} alt="logo" />
      <div>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            defaultValue={data.email}
            error={errors.email}
            helperText={errors.email}
            id="Email"
            name="email"
            color="primary"
            onChange={change_text}
            style={{ margin: 10, width: 300 }}
            label="Email Address"
            placeholder="Enter your Email Address"
            variant="outlined"
          />
          <TextField
            error={errors.password}
            helperText={errors.password}
            inputProps={{
              autoComplete: "new-password",
              form: {
                autocomplete: "off",
              },
            }}
            id="Password"
            defaultValue={data.password}
            name="password"
            onChange={change_text}
            style={{ margin: 10, width: 300, height: 25 }}
            label="Password"
            type="Password"
            placeholder="Enter your Password"
            variant="outlined"
          />
        </form>
      </div>
      <Button
        onClick={() => (go ? validate() : null)}
        style={{
          marginTop: "20px",
          width: "80%",
          backgroundColor: go ? "#00AC69" : "gainsboro",
        }}
      >
        Continue
      </Button>
      <Subtitle1 color="#00AC69">Or</Subtitle1>
      {appEnvProduction && (
        <Button
          onClick={() => loginWithNLPortal()}
          style={{
            margin: 0,
            width: "80%",
            backgroundColor: "white",
            color: "#00000099",
            border: "1px solid black",
          }}
        >
          Login with 43-101
        </Button>
      )}
      <Button
        onClick={() => linkedinLogin()}
        style={{
          margin: 0,
          width: "80%",
          backgroundColor: "white",
          color: "#00000099",
          border: "1px solid black",
        }}
      >
        Continue with LinkedIN
      </Button>
      <Button
        onClick={() => appleLogin()}
        style={{
          margin: 0,
          width: "80%",
          backgroundColor: "white",
          color: "#00000099",
          border: "1px solid black",
        }}
      >
        Continue with Apple
      </Button>
      <div
        style={{ width: "100%", cursor: "pointer" }}
        className="flex-boy-row"
      >
        <Subtitle1
          color="#00AC69"
          onClick={() => history.push("/auth/sign-up")}
        >
          No Account? Sign Up!
        </Subtitle1>
        <Subtitle1
          color="#00AC69"
          onClick={() => history.push("/auth/password-reset/password-email")}
        >
          Forgot Password
        </Subtitle1>
      </div>
    </NotifyDiv>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  activeUser: state.authReducer.activeUser,
});

const mapDispatchToProps = (dispatch) => ({
  userLogin: (data) => dispatch(userLogin(data)),
  linkedinLogin: () => dispatch(linkedinLogin()),
  appleLogin: () => dispatch(appleLogin()),
  loginWithNLPortal: (data) => dispatch(loginWithNLPortal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
