import React, { useEffect, useState, useRef, createRef } from "react";
import {
  ComponentContainer,
  Headline5,
  Primary,
  Headline6,
  Subtitle2,
} from "../../../../../components";
import Select from "react-select";
import { Exchanges } from "../../../../../configure/constants";
import AreaChart from "./AreaChart";
import Colunm from "./colunm";
import Costcolunm from "./Costcolumn";
import ColumnCombinedProjects from "./ColunmCombined";
import { connect, useSelector } from "react-redux";
import Sankey from "./Sankey";
import ColunmCombined from "./ColunmCombined";
import { apiRequest } from "../../../../../store/Actions/AuthActions";
import useOnScreen from "../../../../../configure/helpers";
import MineralDiv from "./MineralDivCosts";
import MineralDivProduction from "./MineralDivProduction";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import CsvDownload from "react-json-to-csv";
import { Tooltip } from "@material-ui/core";
const customStyles = {
  control: (base) => ({
    ...base,
    fontSize: "x-large",

    minHeight: 50,
  }),
};
const mineral_List = [
  {
    name: "Gold",
  },
];
const projectProduction = ({ project, mineral }) => {
  const years_list = () => {
    var max = new Date().getFullYear();
    var min = max - 9;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  };

  const production_basis_list = [
    { label: "Attributional basis", value: "attributional_basis" },
    { label: "100% basis", value: "full_basis" },
  ];
  const [production_basis, setproduction_basis] = useState("full_basis");

  const metric_type_list = [
    { label: "Co-Product", value: "Co-product" },
    { label: "By-product", value: "By-product" },
  ];

  const [metric_type, setmetric_type] = useState("By-product");
  const [prod_year, setProdyear] = useState(project.quarterly_prod_years[0]);
  const [cost_year, setCostyear] = useState(project.quarterly_prod_years[0]);

  const elements = useSelector((state) => state.authReducer.minerals_list);
  const elementsRef = useRef(project.minerals.map(() => createRef()));

  const [quarter, setquarter] = useState("Q1");
  const [costquarter, setcostquarter] = useState("Q1");
  const [showprodCharts, setshowProdCharts] = useState(false);
  const [showcostCharts, setshowCostCharts] = useState(false);
  const showHideCharts = () => {
    setshowProdCharts(!showprodCharts);
  };
  const showHideCostCharts = () => {
    setshowCostCharts(!showcostCharts);
  };

  const pdfExportComponent = React.useRef(null);
  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  const pdfExportComponentCost = React.useRef(null);
  const exportPDFWithComponentCost = () => {
    if (pdfExportComponentCost.current) {
      pdfExportComponentCost.current.save();
    }
  };

  return (
    <>
      {" "}
      {project.quarterly_prod_minerals.includes(mineral) ? (
        <>
          <ComponentContainer>
            <div style={{ width: "100%", height: "fit-content" }}>
              <div className="flex flex-row flex-wrap w-full justify-between items-center">
                <Headline5 className=" lg:w-1/4 md:w-full">
                  5.3 Quarterly Production
                </Headline5>

                <div className="flex-boy-row lg:w-1/4 md:w-full">
                  {/* <Tooltip
                    placement="top"
                    title={
                      "“100% basis is based on the total amount for the project regardless of company’s share.  Attributional basis is based on the current ownership that the company holds in the specific project.”"
                    }
                  >
                    <div
                      style={{
                        flex: 1,
                        boxSizing: "border-box",
                        padding: "10px",
                        paddingLeft: "0",
                        height: "80px",
                      }}
                    >
                      <Select
                        displayEmpty
                        styles={customStyles}
                        onChange={(e) => setproduction_basis(e.value)}
                        value={{
                          value: production_basis,
                          label: production_basis_list.find(
                            (item) => item.value === production_basis
                          ).label,
                        }}
                        placeholder={"Attributional basis"}
                        options={production_basis_list.map((tag, index) => ({
                          value: tag.value,
                          label: tag.label,
                        }))}
                      />
                    </div>
                  </Tooltip> */}

                  {/* <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 mb-2 mr-4 border border-grey shadow-sm text-sm font-medium rounded-md text-gainsboro bg-slate-100 focus:outline-none"
                    onClick={showHideCharts}
                  >
                    {showprodCharts ? "Hide Charts" : "Show Charts"}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-2 mr-1 h-4 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                      />
                    </svg>
                  </button> */}
                </div>
              </div>
              <PDFExport
                ref={pdfExportComponent}
                paperSize="auto"
                margin={40}
                fileName={`Report for ${project.projectName} for ${mineral}`}
                author="Prospector Inc"
              >
                <MineralDivProduction
                  project={project}
                  year={prod_year}
                  mineral={mineral}
                  quarter={quarter}
                  showCharts={showprodCharts}
                  type={"quarterly-production"}
                  production_basis={production_basis}
                  metric_name={"Cash costs"}
                />
              </PDFExport>
            </div>
          </ComponentContainer>
          <ComponentContainer>
            <div style={{ width: "100%", height: "fit-content" }}>
              <div className="flex flex-row flex-wrap w-full justify-between items-center">
                <Headline5 className=" lg:w-1/4 md:w-full">
                  5.4 Quarterly Production Costs{" "}
                </Headline5>

                <div className="flex-boy-row lg:w-1/4 md:w-full">
                  {/* <div className="flex flex-row cursor-pointer items-center justify-center h-[50px] w-[50px] m-2 mb-4 rounded border-solid border-2 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                      />
                    </svg>
                  </div>
                  <div
                    onClick={exportPDFWithComponentCost}
                    className="flex flex-row cursor-pointer items-center justify-center h-[50px] w-[50px] m-2 mb-4 rounded border-solid border-2 "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                      />
                    </svg>
                  </div> */}
                  <div
                    style={{
                      flex: 1,
                      boxSizing: "border-box",
                      padding: "10px",
                      paddingLeft: "0",
                      height: "80px",
                    }}
                  >
                    <Select
                      displayEmpty
                      styles={customStyles}
                      onChange={(e) => setmetric_type(e.value)}
                      value={{
                        value: metric_type,
                        label: metric_type_list.find(
                          (item) => item.value === metric_type
                        ).label,
                      }}
                      placeholder={"By-product"}
                      options={metric_type_list.map((tag, index) => ({
                        value: tag.value,
                        label: tag.label,
                      }))}
                    />
                  </div>
                </div>
              </div>
              <PDFExport
                ref={pdfExportComponentCost}
                paperSize="auto"
                margin={40}
                fileName={`Report for ${project.projectName} for ${mineral}`}
                author="Prospector Inc"
              >
                <MineralDiv
                  project={project}
                  year={cost_year}
                  mineral={mineral}
                  quarter={costquarter}
                  showCharts={showcostCharts}
                  type={"quarterly-production"}
                  production_basis={"full_basis"}
                  metric_name={"Cash costs"}
                  metric_type={metric_type}
                />
              </PDFExport>
            </div>
          </ComponentContainer>
        </>
      ) : (
        <div className="rounded bg-slate-300 my-4 p-4 align-bottom">
          <p className="text-lg font-bold text-slate-500">
            There is no quarterly production data for {mineral}{" "}
          </p>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  project: state.authReducer.selectedProject,
  project_reports_loading: state.authReducer.project_reports_loading,
  reports: state.authReducer.selectedproject_reports,
  activeUser: state.authReducer.activeUser,
  user_follow_list: state.authReducer.lists,
});

const mapDispatchToProps = (dispatch) => ({
  setSupscriptionPopup: (data) =>
    dispatch({ type: "SET_SUBSCRIPTION_POPUP", payload: data }),
  setSupscriptionMessage: (data) =>
    dispatch({ type: "SET_SUBSCRIPTION_MESSAGE", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(projectProduction);
