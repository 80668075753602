/* eslint-disable no-unused-vars */
import React from "react";

import {
  searchClient,
  elements,
  projectsSearchIndex,
  miningNewsSearchIndex,
  companiesSearchIndex,
} from "../../configure/constants";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Index,
  Configure,
} from "react-instantsearch-dom";
import { Headline6, Body2, Subtitle1, Subtitle2, Hexagon } from "../general";
import moment from "moment";
import { ProjectModel } from "../../models";

export const SearchComponent = ({
  activeUser,
  history,
  getProjectDetail,
  getCompanyDetail,
  selectedCompany,
  setSupscriptionPopup,
}) => {
  const [searchState, setSearchState] = React.useState({});
  const subscription = activeUser;
  const CompanyHit = ({ hit }) => {
    return (
      <div
        onClick={() =>
          window.open(
            `https://webapp.prospectormatch.com/#/company-detail/${hit.id}`,
            "_blank"
          )
        }
        className="flex-boy-row"
        style={{ width: "100%", cursor: "pointer" }}
      >
        <div className="company-ticker" style={{ marginBottom: "2px" }}>
          <Subtitle1 color="#00AC69">
            {hit.yahoo_ticker || hit.google_tiker}
          </Subtitle1>
          <Body2>{hit.name}</Body2>
        </div>
        <div
          className="flex-boy-row"
          style={{ justifyContent: "normal", flexWrap: "wrap" }}
        >
          {hit.minerals
            ? hit.minerals
                .filter(
                  (item) =>
                    item.name !== "Not Available" &&
                    item.name !== "Combined" &&
                    item.name !== "Metric Tonnes"
                )
                .map((min, index) => {
                  return (
                    <Hexagon
                      key={index}
                      width={28}
                      style={{ fontSize: "0.6rem" }}
                    >
                      {elements.find(
                        (item) => item.name.toLowerCase() === min.toLowerCase()
                      )
                        ? elements.find(
                            (item) =>
                              item.name.toLowerCase() === min.toLowerCase()
                          ).symbol
                        : min}
                    </Hexagon>
                  );
                })
            : null}
        </div>
      </div>
    );
  };
  const ProjectHit = ({ hit }) => {
    if (hit) {
      hit = new ProjectModel(hit);
      return (
        <div
          onClick={() =>
            window.open(
              `https://webapp.prospectormatch.com/#/project-detail/${hit.id}`,
              "_blank"
            )
          }
          className="flex-boy-row"
          style={{ width: "100%", cursor: "pointer" }}
        >
          <div style={{ flex: 1 }}>
            <Headline6>{hit.reportName}</Headline6>
            {/* <Subtitle2>{moment(hit.createdAt).format('DD MMM YYYY')}</Subtitle2> */}
          </div>

          <div
            className="flex-boy-row"
            style={{
              marginTop: "5px",
              height: "fit-content",
              flex: 1,
              justifyContent: "center",
            }}
          >
            <div
              className="hit-image flex-boy-row"
              style={{
                backgroundImage:
                  "url('../../assets/ProspectorDesignAssets/Climate Smart Mining.png')",
              }}
            >
              <img
                style={{ width: "30px" }}
                src={
                  hit.reportImageURL ||
                  require("../../assets/ProspectorDesignAssets/Climate Smart Mining.png")
                }
                alt="hit"
              />
            </div>

            <div>
              {hit.investors
                ? hit.investors.map((investor) => {
                    return (
                      <Subtitle1
                        color="#00AC69"
                        style={{ textTransform: "capitalize" }}
                      >
                        {investor.ticker
                          ? ` ${investor.ticker}.${investor.exchange}`
                          : investor.name}
                      </Subtitle1>
                    );
                  })
                : null}

              <div
                className="flex-boy-row"
                style={{ justifyContent: "normal" }}
              >
                {hit.minerals
                  .filter(
                    (item) =>
                      item.name !== "Not Available" && item.name !== "Combined"
                  )
                  .map((min, index) => {
                    return (
                      <Hexagon
                        key={index}
                        width={20}
                        style={{ fontSize: "0.6rem" }}
                      >
                        {elements.find(
                          (item) =>
                            item.name.toLowerCase() === min.toLowerCase()
                        )
                          ? elements.find(
                              (item) =>
                                item.name.toLowerCase() === min.toLowerCase()
                            ).symbol
                          : min}
                      </Hexagon>
                    );
                  })}
              </div>
            </div>
            {/* <div className="flex-boy-row" style={{marginTop:'5px',justifyContent:'normal',flexWrap:'wrap'}}>
                                      {states.map((status, index) => {
                                         return (
                                             <GreyBox style={{
                                                 margin: "2px",
                                                 width:'72px',
                                                 fontSize:'14px',
                                                 backgroundColor: hit.mineralEstimate.toString().includes(status) ?'#1A9BDB' : 'gainsboro',
                                                 color: hit.mineralEstimate.toString().includes(status)  ? 'white' : '#454555',
                                                 textTransform:'capitalize',
                                                 textAlign:'center'
                                             }} key={index}>{status}</GreyBox>
                                         )
                                     })}
                                     </div> */}
          </div>
          {hit.countries.map((flag, index) => {
            let flagSource;
            try {
              flagSource = require(`../../assets/ProspectorDesignAssets/Flags/${flag
                .toLowerCase()
                .replace(" ", "-")}.png`);
            } catch {
              flagSource = require(`../../assets/ProspectorDesignAssets/Flags/usa.png`);
            }
            return (
              <img
                key="flag"
                className="project-flag"
                style={{ height: "25px" }}
                src={flagSource}
                alt="region"
              />
            );
          })}
        </div>
      );
    }
  };
  const NewsHit = ({ hit }) => {
    if (hit) {
      return (
        <div
          onClick={() => checkSubscription(hit.url)}
          style={{ cursor: "pointer" }}
        >
          <Subtitle1 className="hit-summary">{hit.summary}</Subtitle1>
          <div style={{ width: "100%", marginTop: "5px" }}>
            <Subtitle1 color="grey">{hit.source}</Subtitle1>
            {/* <Subtitle1 color="#00AC69" style={{textTransform:'capitalize'}}>
                          {hit.project.companyData.mic}:{hit.project.companyData.ticker}
                        </Subtitle1> */}
          </div>
        </div>
      );
    }
  };

  const checkCompany = () => {
    if (selectedCompany) {
      history.push({
        pathname: "/company-detail",
      });
      clearSearch();
    } else {
      setTimeout(() => {
        checkCompany();
      }, 5000);
    }
  };

  const clearSearch = () => {
    setSearchState({ ...searchState, query: "" });
  };
  const checkSubscription = (url) => {
    window.open(url, "_blank");

    clearSearch();
  };

  const goToProject = async (project) => {
    await getProjectDetail({ id: project.objectID, history });
    clearSearch();
  };
  const goToCompany = async (project) => {
    await getCompanyDetail({ id: project.id, history });
    clearSearch();
  };

  // const CustomStateResults = connectStateResults(Results);

  const [query, setQuery] = React.useState("");
  const queryHook = (query, search) => {
    setQuery(query);
  };
  return (
    <div className="instant-search-iframe">
      <InstantSearch
        searchClient={searchClient}
        indexName={projectsSearchIndex}
        searchState={searchState}
        onSearchStateChange={setSearchState}
      >
        <Configure hitsPerPage={3} />
        <SearchBox
          queryHook={queryHook}
          autoFocus={false}
          translations={{
            submitTitle: "Submit your search query.",
            resetTitle: "Clear your search query.",
            placeholder: "Search for projects, companies and news here...",
          }}
        />
        <div
          style={{
            margin: "5px",
            backgroundColor:
              searchState && query && query !== ""
                ? "whitesmoke"
                : "transparent",
            border: searchState && query !== "" ? "gainsboro" : "transparent",
          }}
          className="right-panel"
        >
          {searchState && query ? (
            <Index indexName={companiesSearchIndex}>
              <Headline6 style={{ margin: "5px" }}>Companies</Headline6>
              <Hits hitComponent={CompanyHit} />
            </Index>
          ) : null}
          {searchState && query ? (
            <Index indexName={projectsSearchIndex}>
              <Headline6 style={{ margin: "5px" }}>Projects</Headline6>
              <Configure hitsPerPage={3} distinct />
              <Hits hitComponent={ProjectHit} />
            </Index>
          ) : null}
          {searchState && query ? (
            <Index indexName={miningNewsSearchIndex}>
              <Headline6 style={{ margin: "5px" }}>Mining News</Headline6>
              <Configure hitsPerPage={3} />
              <Hits hitComponent={NewsHit} />
            </Index>
          ) : null}
        </div>
      </InstantSearch>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedCompany: state.authReducer.selectedCompany,
  selectedProject: state.authReducer.selectedProject,
  activeUser: state.authReducer.activeUser,
});

const mapDispatchToProps = (dispatch) => ({});

export default SearchComponent;
