import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TailwindButton, TailwindButtonOutline } from "./general";
import CsvDownload from "react-json-to-csv";

export const ExportProjects = ({
  bulk_select_projects,
  handleClose,
  projects,
}) => {
  const [projectsJson, setprojectsJson] = useState(null);
  useEffect(() => {
    fetchList();
    return () => {};
  }, [bulk_select_projects]);
  const fetchList = () => {
    let projects_json = [...bulk_select_projects].map((item) => {
      let new_obj = projects.find((project) => project.id === item);
      let objHeaders = {
        "Project Name": new_obj.projectName,
        Minerals: new_obj.minerals?.toString(),
        Stage: new_obj.stage,
        "Mineral Estimate": new_obj.mineralEstimate?.toString(),
        Country: new_obj.countries?.toString(),
      };
      return objHeaders;
    });
    setprojectsJson(projects_json);
  };
  return (
    <div className="p-4 h-[200px] flex flex-col justify-between">
      <p className="align-middle text-xl font-bold">
        {" "}
        {bulk_select_projects.length
          ? `Would you like to Download CSV of ${
              bulk_select_projects.length > 25
                ? bulk_select_projects.slice(0, 25).length
                : bulk_select_projects.length
            } select Projects `
          : "Please select atleast one Project"}
      </p>
      {bulk_select_projects.length > 0 ? (
        <div className="flex flex-row w-full justify-between">
          <TailwindButtonOutline text="cancel" onClick={handleClose} />
          {projectsJson && (
            <CsvDownload
              data={projectsJson}
              filename="Prospector Projects Export.csv"
            >
              {" "}
              <TailwindButton onClick={handleClose} text="Download" />{" "}
            </CsvDownload>
          )}
        </div>
      ) : (
        <div className="flex flex-row w-full justify-center">
          <TailwindButtonOutline text="cancel" onClick={handleClose} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  bulk_select_projects: state.authReducer.bulk_select_projects,
  projects: state.authReducer.projects_search || [],
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ExportProjects);
