import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Headline5,
  Subtitle1,
  Subtitle2,
  Body1,
  Primary,
} from "../../../components";
import moment from "moment";
import { Divider } from "@material-ui/core";
import {
  getCommodityData,
  getCompanyNews,
  manageSub,
} from "../../../store/Actions/AuthActions";
import StockChart from "./StockChart";
import SyncfusionStock from "./SyncfusionStock";
import ReportCard from "../../../components/ReportCard";
import NewsFiling from "../../../components/NewsFiling";

export const StockPerformance = ({
  news_feed,
  activeUser,
  company,
  history,
}) => {
  const more_news = () => {
    getCompanyNews({
      searchTerm: company.name,
      datasource: "news",
      startNumber: news_feed?.next,
    });
  };
  const [allNews, setallNews] = useState(false);
  return (
    <>
      <Headline5 style={{ margin: "20px 0 20px 0" }}>
        Stock Performance and News
      </Headline5>

      <SyncfusionStock news_feed={news_feed} />
      <div className="news-section">
        {news_feed?.data.length
          ? allNews
            ? news_feed?.data.map((article, index) => (
                <NewsFiling type={article.document_type} filing={article} />
              ))
            : news_feed?.data

                .slice(0, 5)
                .map((article, index) => (
                  <NewsFiling type={article.document_type} filing={article} />
                ))
          : null}
        {news_feed?.data.length ? (
          <Subtitle1
            className="hover-underline"
            onClick={() => setallNews(!allNews)}
            style={{ marginTop: "20px", marginLeft: "10px", cursor: "pointer" }}
            color="##B00020"
          >
            {" "}
            More News
          </Subtitle1>
        ) : null}
        {/* {news_feed?.data.length && news_feed?.next !== "0" ? <Subtitle1 onClick={() => more_news()} style={{ margin: '20px 0', textAlign: 'left', cursor: 'pointer' }} color={Primary}>More</Subtitle1> : null} */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  company: state.authReducer.selectedCompany,
  news_feed: state.authReducer.selectedCompany_news,
  social_feed: state.authReducer.selectedCompany_social,
  user_follow_list: state.authReducer.lists,
  activeUser: state.authReducer.activeUser,
  statistics: state.authReducer.commodity_stock,
});

const mapDispatchToProps = (dispatch) => ({
  getCompanyNews: (data) => dispatch(getCompanyNews(data)),
  setCommodity: (data) => dispatch({ type: "SET_COMMODITY", payload: data }),
  getCommodityData: (data) => dispatch(getCommodityData(data)),
  setSubscription: (data) =>
    dispatch({ type: "SET_SUBSCRIPTION_STATUS", payload: data }),

  setCompany: (data) => dispatch({ type: "SET_COMPANY", payload: data }),
  manageSub: (data) => dispatch(manageSub(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockPerformance);
