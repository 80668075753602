import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import { Primary } from "../../../components";
import {
  filterCompanies,
  filterFilings,
  filterProjects,
  filterReports,
} from "../../../store/Actions/AuthActions";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid gainsboro",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 52,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    background: Primary,
    color: "white",
  },
  divider: {
    height: "100%",
  },
}));
export const SearchComponent = ({
  placeholder,
  type,
  report_filters,
  project_filters,
  company_filters,
  filings_filters,
  setCompanyFilters,
  setProjectFilters,
  setReportFilters,
  setReportPage,
  setCompanyPage,
  setProjectPage,
  setfilingpage,
  setFilingFilters,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [searchValue, setsearchValue] = useState("");
  const onFinish = (vals) => {
    console.log("😶‍🌫️", searchValue);
    if (type === "projects" || type === "company") {
      updateSingleFilterKey("searchTerm", searchValue);
    } else {
      console.log("weeh");
      updateSingleFilterKey("headline", searchValue);
    }
  };
  const handleInputChange = (e) => {
    setsearchValue(e.target.value);
  };

  useEffect(() => {
    default_value();
    return () => {};
  }, [type, filings_filters, project_filters, company_filters, report_filters]);

  const default_value = () => {
    if (type === "projects") {
      setsearchValue(project_filters.searchTerm);
      updateFilters(
        project_filters || {
          mineral: null,
          start_after: null,
          searchTerm: null,
          regions: null,
          exchanges: null,
          countries: null,
          stage: null,
          resourceType: null,
          averageGrade_min: 0,
          averageGrade_max: 0,
          oreTonnage_min: 0,
          oreTonnage_max: 0,
          containedMetal_min: 0,
          containedMetal_max: 0,
        }
      );
      // search_projects({loc:0,newFilters:filters})
    } else if (type === "company") {
      setsearchValue(company_filters.searchTerm);
      updateFilters(
        company_filters || {
          mineral: null,
          start_after: null,
          searchTerm: null,
          regions: null,
          exchanges: null,
          countries: null,
          stage: null,
          resourceType: null,
          averageGrade_min: 0,
          averageGrade_max: 0,
          oreTonnage_min: 0,
          oreTonnage_max: 0,
          containedMetal_min: 0,
          containedMetal_max: 0,
        }
      );
      // search_companies({loc:0,newFilters:filters})
    } else if (type === "news") {
      setsearchValue(filings_filters.headline);
      updateFilters(
        filings_filters || {
          headline: null,
          exchanges: null,
          exact_phrase: null,
          all_of: [],
          none_of: [],
          phrase: {
            word_count: null,
            searchTerm: null,
            near: null,
          },
          date_range: null,
          filing_company: null,
          filing_type: null,
        }
      );
    } else {
      setsearchValue(report_filters.headline);
      updateFilters(
        report_filters || {
          minerals: null,
          searchTerm: null,
          exclude_sections: [],
          include_sections: [],
          all_of: [],
          none_of: [],
          exact_phrase: null,
          phrase: {
            word_count: null,
            searchTerm: null,
            near: null,
          },
          exchange: null,
          regions: null,
          country: null,
          mineral_estimate: null,
          publication_date: null,
          from_date: null,
          to_date: null,
          days: null,
          author: null,
          search_text: null,
        }
      );
      // search_companies({loc:0,newFilters:filters})
    }
  };

  // filter mumbo jumbo
  const [filters, updateFilters] = useState({
    mineral: null,
    start_after: null,
    searchTerm: null,
    exchange: null,
    regions: null,
    countries: null,
    resourceType: null,
    averageGrade_min: 0,
    averageGrade_max: 0,
    oreTonnage_min: 0,
    oreTonnage_max: 0,
    containedMetal_min: 0,
    containedMetal_max: 0,
  });
  const setFilters = (filters) => {
    updateFilters(filters);
  };

  const search_companies = ({ loc, newFilters }) => {
    setCompanyPage(0);
    dispatch(filterCompanies());
  };
  const search_projects = ({ loc, newFilters }) => {
    setProjectPage(0);
    dispatch(filterProjects());
  };
  const search_reports = ({ loc, newFilters }) => {
    setReportPage(0);
    // dispatch(//filterReports());
  };

  const search_filings = () => {
    setfilingpage(0);
    dispatch(filterFilings());
  };
  const updateSingleFilterKey = (name, value) => {
    let newFilters = { ...filters };

    newFilters[name] = value;
    setFilters(newFilters);
    console.log(type, "🤯", newFilters);
    if (type === "projects") {
      setProjectFilters(newFilters);
      search_projects({ loc: 0, newFilters });
    } else if (type === "technical reports") {
      setReportFilters(newFilters);
      search_reports({ loc: 0, newFilters });
    } else if (type === "news") {
      setFilingFilters(newFilters);
      search_filings({ loc: 0, newFilters });
    } else {
      setCompanyFilters(newFilters);
      search_companies({ loc: 0, newFilters });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onFinish();
    }
  };
  // const debouncedChangeHandler = useCallback(
  //     _.debounce(function () {
  //         setProjectPage(0)
  //         filterCompanies()
  //     }, 1000)
  //     , []);
  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        defaultValue={searchValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <div
        style={{
          background: Primary,
          padding: "5px",
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
      >
        <IconButton
          onClick={onFinish}
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  project_filters: state.authReducer.projects_search_filters,
  report_filters: state.authReducer.report_search_filters,
  company_filters: state.authReducer.company_search_filters,
  filings_filters: state.authReducer.filings_filters,
});

const mapDispatchToProps = (dispatch) => ({
  setProjectFilters: (data) =>
    dispatch({ type: "SET_PROJECT_FILTERS", payload: data }),
  setCompanyFilters: (data) =>
    dispatch({ type: "SET_COMPANY_FILTERS", payload: data }),
  setReportFilters: (data) =>
    dispatch({ type: "SET_REPORTS_FILTERS", payload: data }),
  setFilingsFilters: (data) =>
    dispatch({ type: "SET_FILING_FILTERS", payload: data }),
  setCompanyPage: (data) =>
    dispatch({ type: "SET_COMPANIES_PAGE", payload: data }),
  setProjectPage: (data) =>
    dispatch({ type: "SET_PROJECTS_PAGE", payload: data }),
  setReportPage: (data) =>
    dispatch({ type: "SET_REPORTS_PAGE", payload: data }),
  setFilingFilters: (data) =>
    dispatch({ type: "SET_FILINGS_FILTERS", payload: data }),
  setfilingpage: (data) => dispatch({ type: "SET_FILING_PAGE", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent);
