import React from "react";
import { connect } from "react-redux";

export const TechnicalReports = (props) => {
  return <div>TechnicalReports</div>;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TechnicalReports);
