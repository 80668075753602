/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {
  Subtitle1,
  Headline5,
  Subtitle2,
  Headline6,
  Button,
  NotifyDiv,
  ProspectorDescription,
  Body1,
  Body2,
  ProspectorInterest,
} from "../../../components";
import { TextField } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Logo from "../../../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon.png";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { updateSignUpDetails } from "../../../store/Actions/AuthActions";
const interests = {
  countries: ["canada", "united-states"],
  regions: ["asia", "australia", "latin america", "north america", "Africa"],
  minerals: ["Au", "Ag", "Cu"],
};
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00AC69",
        border: "2px solid",
      },
      "&:hover fieldset": {
        borderColor: "#00AC69",
      },
    },
  },
}));

export const Page3 = ({ swapAuthPage, updateSignUpDetails }) => {
  const classes = useStyles();
  const [selectedInterests, setSelected] = useState([]);
  const select = (region) => {
    let array = [...selectedInterests];
    const exists = array.includes(region);

    if (exists) {
      setSelected(
        array.filter((c) => {
          return c !== region;
        })
      );
    } else {
      const result = array;
      result.push(region);

      setSelected(result);
    }
  };
  const validate = () => {
    updateSignUpDetails({ interestRegions: selectedInterests });
    swapAuthPage("Page7");
  };
  return (
    <Fragment>
      <Headline6 color="#00AC69">
        Are there geographic regions that are of greatest interest to you?
      </Headline6>
      <div className="circle-progress">
        <div className="progress">
          <CircularProgress
            size="100%"
            thickness={2}
            variant="determinate"
            value={50}
          />
        </div>

        <img src={Logo} alt="logo" />
      </div>
      <div className="flex-boy-row" style={{ flexWrap: "wrap" }}>
        {interests.regions.map((region) => {
          return (
            <ProspectorInterest
              key="region"
              onClick={() => {
                select(region);
              }}
              style={{
                textAlign: "left",
                padding: "5px",
                alignItems: "flex-start",
              }}
              color={
                selectedInterests.includes(region) ? "#00AC69" : "#00000061"
              }
              className="flex-boy-row"
            >
              <div
                style={{
                  textAlign: "left",
                  height: "100%",
                  textTransform: "capitalize",
                }}
                className="flex-boy-row"
              >
                <img
                  style={{ height: "5vh" }}
                  src={require(`../../../assets/ProspectorDesignAssets/Regions/${region.toLowerCase()} with padding.png`)}
                  alt="flag"
                />

                <Subtitle1 style={{ textAlign: "left" }}>{region}</Subtitle1>
              </div>
              {selectedInterests.includes(region) ? (
                <CheckCircleIcon fontSize="small" />
              ) : null}
            </ProspectorInterest>
          );
        })}
      </div>

      <div></div>
      <Button
        onClick={() => (selectedInterests.length ? validate() : null)}
        style={{
          width: "80%",
          backgroundColor: selectedInterests.length ? "#00AC69" : "gainsboro",
        }}
      >
        Continue
      </Button>
      <Subtitle1
        onClick={() => swapAuthPage("Page7")}
        style={{ cursor: "pointer" }}
        color="#00AC69"
      >
        Skip this step
      </Subtitle1>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  updateSignUpDetails: (data) => dispatch(updateSignUpDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Page3);
