/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {
  Subtitle1,
  Headline5,
  Subtitle2,
  Headline6,
  Button,
  NotifyDiv,
} from "../../../components";
import { TextField } from "@material-ui/core";
import Logo from "../../../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon.png";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import { updateSignUpDetails } from "../../../store/Actions/AuthActions";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00AC69",
        border: "2px solid",
      },
      "&:hover fieldset": {
        borderColor: "#00AC69",
      },
    },
  },
}));

export const Page2 = ({ swapAuthPage }) => {
  const classes = useStyles();
  const [confirmation_code, setData] = useState("1234");

  const change_text = (e) => {
    setData(e.target.value);
  };

  const validate = () => {
    swapAuthPage("Page3");
  };
  return (
    <Fragment>
      <Headline6 color="#00AC69">
        Validation email has been sent to you. Please enter code to continue.
      </Headline6>
      <div className="circle-progress">
        <div className="progress">
          <CircularProgress
            size="100%"
            thickness={2}
            variant="determinate"
            value={25}
          />
        </div>

        <img src={Logo} alt="logo" />
      </div>

      <div>
        <form className={classes.root} noValidate autoComplete="off">
          {/* <TextField onChange={change_text} id="confirmation_code" color='primary' style={{ margin: 10,width:300 }} label="Confirmation Code" placeholder="Enter Confirmation Code" variant="outlined" /> */}

          <Button
            onClick={() => (confirmation_code ? validate() : null)}
            style={{
              width: "80%",
              backgroundColor: confirmation_code ? "#00AC69" : "gainsboro",
            }}
          >
            Continue
          </Button>
        </form>
      </div>
      <Button
        onClick={() => (confirmation_code ? validate() : null)}
        style={{
          width: "80%",
          backgroundColor: confirmation_code ? "#00AC69" : "gainsboro",
        }}
      >
        Continue
      </Button>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updateSignUpDetails: (data) => dispatch(updateSignUpDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Page2);
