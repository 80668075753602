import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCommpanyStockData } from "../../../store/Actions/AuthActions";
import {
  Headline5,
  Subtitle1,
  Subtitle2,
  Body1,
  Primary,
} from "../../../components";
import _ from "lodash";
import moment from "moment";
import {
  StockChartComponent,
  StockChartSeriesCollectionDirective,
  StockChartSeriesDirective,
  Inject,
  Crosshair,
  DateTime,
  Tooltip,
  RangeTooltip,
  ColumnSeries,
  LineSeries,
  SplineSeries,
  CandleSeries,
  HiloOpenCloseSeries,
  HiloSeries,
  RangeAreaSeries,
  Trendlines,
  StockChartRowsDirective,
  StockChartRowDirective,
  StockChartAxesDirective,
  StockChartAxisDirective,
  StockChartTrendlineModel,
} from "@syncfusion/ej2-react-charts";
import {
  EmaIndicator,
  RsiIndicator,
  BollingerBands,
  TmaIndicator,
  MomentumIndicator,
  SmaIndicator,
  AtrIndicator,
  AccumulationDistributionIndicator,
  MacdIndicator,
  StochasticIndicator,
  Export,
} from "@syncfusion/ej2-react-charts";
import { chartData } from "./indicator-data";

const SAMPLE_CSS = `
    .control-fluid {
        padding: 0px !important;
        font-family: "Gotham-Bold";
    }
        .charts {
            align :center
        }`;
export let tooltipRender = (args) => {
  if (args.text.split("<br/>")[4]) {
    let target = parseInt(
      args.text.split("<br/>")[4].split("<b>")[1].split("</b>")[0]
    );
    let value = (target / 100000000).toFixed(1) + "B";
    args.text = args.text.replace(
      args.text.split("<br/>")[4].split("<b>")[1].split("</b>")[0],
      value
    );
  }
};

export const StockChart = ({
  company,
  stockData,
  commodity,
  news_feed,
  history,
  getCommodityData,
}) => {
  const [chartdata, setchartdata] = useState(null);

  useEffect(() => {
    // onFetchData()
    setchartdata(null);
    setTimeout(() => {
      generateChartData();
    }, 100);

    return () => {};
  }, [stockData]);
  useEffect(() => {
    selectComodity();
    return () => {};
  }, [company]);

  const selectComodity = () => {
    if (company.yahoo_ticker) {
      let data = {
        id: company.id,
        interval: "1d",
        identifier: company.yahoo_ticker,
        range: "1y",
      };

      // getCommodityData(data);
    }
  };
  const generateChartData = () => {
    let data =
      stockData.data && Object.values(stockData.data).length
        ? [
            ...Object.values(stockData.data)?.map((item) => {
              return {
                x: moment(item.endDateTime).format("DD-MMM-YYYY"),
                ...item,
              };
            }),
          ]
        : [];
    let trendState =
      data.length &&
      data.length > 1 &&
      data[data.length - 1].close - data[0].close >= 0
        ? "#00AC6A"
        : "#B00020";

    setchartdata(data);
  };

  const onFetchData = () => {};

  const axisLabelRender = (args) => {
    let text = parseInt(args.text);
    if (args.axis.name === "primaryYAxis") {
      args.text = text / 100000000 + "B";
    }
  };
  const load = (args) => {
    let selectedTheme = "Material";

    args.stockChart.theme = selectedTheme.charAt(0).toUpperCase();
  };

  const periodselector = [
    { text: "1M", interval: 1, intervalType: "Months" },
    { text: "3M", interval: 3, intervalType: "Months" },
    { text: "6M", interval: 6, intervalType: "Months" },
    { text: "YTD" },
    { text: "1Y", interval: 1, intervalType: "Years", selected: true },
    { text: "2Y", interval: 2, intervalType: "Years" },
    { text: "All" },
  ];
  try {
    return (
      <div className="control-pane">
        <style>{SAMPLE_CSS}</style>
        <div className="control-section">
          {chartdata && (
            <StockChartComponent
              indicatorType={[]}
              id="stockchartpane"
              titleStyle={{ fontFamily: "Gotham-Bold" }}
              tooltip={{
                enable: true,
                textStyle: { fontFamily: "Gotham-Bold" },
              }}
              primaryYAxis={{
                lineStyle: { color: "transparent" },
                majorTickLines: { color: "transparent", width: 0 },
              }}
              primaryXAxis={{
                crosshairTooltip: { enable: true },
                majorGridLines: { width: 0 },
                valueType: "DateTime",
              }}
              chartArea={{ border: { width: 0 } }}
              tooltipRender={tooltipRender}
              axisLabelRender={axisLabelRender}
              crosshair={{ enable: true }}
              load={load}
              title={`${company.name} historical`}
              periods={periodselector}
              // stockEvents={news_feed && news_feed.data && news_feed.data.length ? news_feed.data.map(item => {

              //     return { date: new Date(item.date), text: "", description: item.summary, textStyle: { color: 'white', fontFamily: "Gotham-Bold" }, background: '#1A9BDB', border: { color: '#1A9BDB' } }
              // }) : []}
            >
              <Inject
                services={[
                  DateTime,
                  Crosshair,
                  Tooltip,
                  RangeTooltip,
                  ColumnSeries,
                  LineSeries,
                  SplineSeries,
                  CandleSeries,
                  HiloOpenCloseSeries,
                  HiloSeries,
                  RangeAreaSeries,
                  Trendlines,
                  Export,
                ]}
              />

              <StockChartRowsDirective>
                <StockChartRowDirective height={"30%"}></StockChartRowDirective>
                <StockChartRowDirective height={"70%"}></StockChartRowDirective>
              </StockChartRowsDirective>
              <StockChartAxesDirective>
                <StockChartAxisDirective
                  name="yAxis1"
                  rowIndex={1}
                  labelIntersectAction={"Hide"}
                  labelPosition={"inside"}
                  tickPosition={"Inside"}
                  opposedPosition={false}
                  lineStyle={{ color: "transparent" }}
                  font={{ color: "transparent" }}
                  majorTickLines={{ color: "transparent" }}
                ></StockChartAxisDirective>
                <StockChartAxisDirective
                  name="yAxis2"
                  rowIndex={0}
                  labelIntersectAction={"Hide"}
                  labelPosition={"outside"}
                  tickPosition={"Inside"}
                  opposedPosition={true}
                  lineStyle={{ color: "transparent" }}
                  majorTickLines={{ color: "transparent" }}
                ></StockChartAxisDirective>
              </StockChartAxesDirective>
              <StockChartSeriesCollectionDirective>
                <StockChartSeriesDirective
                  tooltip={{ textStyle: { fontFamily: "Gotham-Bold" } }}
                  dataSource={chartdata}
                  trendlines={{ fill: "grey" }}
                  fill="#1A9BDB"
                  xName="x"
                  yName="close"
                  type="Spline"
                  yAxisName="yAxis1"
                ></StockChartSeriesDirective>
                <StockChartSeriesDirective
                  dataSource={chartdata}
                  fill={Primary}
                  pointColorMapping="blue"
                  xName="x"
                  yName="volume"
                  type="Column"
                  enableTooltip={false}
                  yAxisName="yAxis2"
                ></StockChartSeriesDirective>
              </StockChartSeriesCollectionDirective>
            </StockChartComponent>
          )}
        </div>
      </div>
    );
  } catch (error) {
    return (
      <div>
        <Headline5>OOPS!</Headline5>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  company: state.authReducer.selectedCompany,
  stockData: state.authReducer.commodity_stock,
  commodity: state.authReducer.selectedCommodity,
});

const mapDispatchToProps = (dispatch) => ({
  getCommodityData: (data) => dispatch(getCommpanyStockData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockChart);
