/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import {
  Subtitle1,
  Headline5,
  Subtitle2,
  Headline6,
  Button,
  NotifyDiv,
} from "../../../components";
import { TextField } from "@material-ui/core";
import Logo from "../../../assets/ProspectorDesignAssets/Prospector_3D Logo_Icon.png";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CancelIcon from "@material-ui/icons/Cancel";
import { updateSignUpDetails } from "../../../store/Actions/AuthActions";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#00AC69",
        border: "2px solid",
      },
      "&:hover fieldset": {
        borderColor: "#00AC69",
      },
    },
  },
}));

export const Page5 = ({ swapAuthPage, updateSignUpDetails }) => {
  const classes = useStyles();
  const [bio, setData] = useState("");
  const change_text = (e) => {
    setData(e.target.value);
  };

  const validate = () => {
    updateSignUpDetails({ bio });
    swapAuthPage("Page6");
  };
  return (
    <Fragment>
      <Headline6 color="#00AC69">
        Can you tell us a little about yourself and your interest in mining?
      </Headline6>
      <div className="circle-progress">
        <div className="progress">
          <CircularProgress
            size="100%"
            thickness={2}
            variant="determinate"
            value={50}
          />
        </div>

        <img src={Logo} alt="logo" />
      </div>

      <div>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            onChange={change_text}
            multiline
            id="position"
            rows={10}
            color="primary"
            style={{ margin: 10, width: 300 }}
            label="Profile Description"
            placeholder="Describe your professional experience and interests in mining industry."
            variant="outlined"
          />
        </form>
      </div>
      <Button
        onClick={() => (bio ? validate() : null)}
        style={{ width: "80%", backgroundColor: bio ? "#00AC69" : "gainsboro" }}
      >
        Continue
      </Button>
      <Subtitle1
        onClick={() => swapAuthPage("Page6")}
        style={{ cursor: "pointer" }}
        color="#00AC69"
      >
        Skip this step
      </Subtitle1>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updateSignUpDetails: (data) => dispatch(updateSignUpDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Page5);
