import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { loginWithNLPortal } from "../store/Actions/AuthActions";

export const PrivateRoute = ({
  firebase,
  component: Component,
  activeUser,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        activeUser ? <Component {...props} /> : <Redirect to="/auth/sign-in" />
      }
    />
  );
};

const mapStateToProps = (state) => ({
  firebase: state.firebase.auth,
  activeUser: state.authReducer.activeUser,
});
const mapDispatchToProps = (dispatch) => ({
  loginWithNLPortal: () => dispatch(loginWithNLPortal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
