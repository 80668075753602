import algoliasearch from "algoliasearch/lite";
export const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_APP_SECRET
);

export const projectsSearchIndex = process.env.REACT_APP_ALGOLIA_PROJECTS_INDEX;
export const companiesSearchIndex =
  process.env.REACT_APP_ALGOLIA_COMPANIES_INDEX;
export const miningNewsSearchIndex = process.env.REACT_APP_ALGOLIA_NEWS_INDEX;
export const appEnvProduction =
  process.env.REACT_APP_DEVELOPMENT_ENVIRONMENT === "false";

export const regions = {
  Oceania: require("../assets/ProspectorDesignAssets/Regions/australia.svg"),
  Australia: require("../assets/ProspectorDesignAssets/Regions/australia.svg"),
  Europe: require("../assets/ProspectorDesignAssets/Regions/europe.svg"),
  "North America": require("../assets/ProspectorDesignAssets/Regions/north america.svg"),
  // latin_america:require('../assets/ProspectorDesignAssets/Regions/latin america.svg'),
  "Latin America": require("../assets/ProspectorDesignAssets/Regions/latin america.svg"),
  Asia: require("../assets/ProspectorDesignAssets/Regions/asia.svg"),
  Africa: require("../assets/ProspectorDesignAssets/Regions/africa.svg"),
};
export const resourceTypes = [
  "inferred",
  "indicated",
  "measured",
  "probable",
  "proven",
  "not available",
];
export const elements = [
  {
    name: "Hydrogen",
    symbol: "H",
  },
  {
    name: "Helium",
    symbol: "He",
  },
  {
    name: "Lithium",
    symbol: "Li",
  },
  {
    name: "Beryllium",
    symbol: "Be",
  },
  {
    name: "Boron",
    symbol: "B",
  },
  {
    name: "Carbon",
    symbol: "C",
  },
  {
    name: "Nitrogen",
    symbol: "N",
  },
  {
    name: "Oxygen",
    symbol: "O",
  },
  {
    name: "Fluorine",
    symbol: "F",
  },
  {
    name: "Neon",
    symbol: "Ne",
  },
  {
    name: "Sodium",
    symbol: "Na",
  },
  {
    name: "Magnesium",
    symbol: "Mg",
  },
  {
    name: "Aluminium",
    symbol: "Al",
  },
  {
    name: "Silicon",
    symbol: "Si",
  },
  {
    name: "Phosphorus",
    symbol: "P",
  },
  {
    name: "Sulfur",
    symbol: "S",
  },
  {
    name: "Chlorine",
    symbol: "Cl",
  },
  {
    name: "Argon",
    symbol: "Ar",
  },
  {
    name: "Potassium",
    symbol: "K",
  },
  {
    name: "Calcium",
    symbol: "Ca",
  },
  {
    name: "Scandium",
    symbol: "Sc",
  },
  {
    name: "Titanium",
    symbol: "Ti",
  },
  {
    name: "Vanadium",
    symbol: "V",
  },
  {
    name: "Chromium",
    symbol: "Cr",
  },
  {
    name: "Manganese",
    symbol: "Mn",
  },
  {
    name: "Iron",
    symbol: "Fe",
  },
  {
    name: "Cobalt",
    symbol: "Co",
  },
  {
    name: "Nickel",
    symbol: "Ni",
  },
  {
    name: "Copper",
    symbol: "Cu",
  },
  {
    name: "Zinc",
    symbol: "Zn",
  },
  {
    name: "Gallium",
    symbol: "Ga",
  },
  {
    name: "Germanium",
    symbol: "Ge",
  },
  {
    name: "Arsenic",
    symbol: "As",
  },
  {
    name: "Selenium",
    symbol: "Se",
  },
  {
    name: "Bromine",
    symbol: "Br",
  },
  {
    name: "Krypton",
    symbol: "Kr",
  },
  {
    name: "Rubidium",
    symbol: "Rb",
  },
  {
    name: "Strontium",
    symbol: "Sr",
  },
  {
    name: "Yttrium",
    symbol: "Y",
  },
  {
    name: "Zirconium",
    symbol: "Zr",
  },
  {
    name: "Niobium",
    symbol: "Nb",
  },
  {
    name: "Molybdenum",
    symbol: "Mo",
  },
  {
    name: "Technetium",
    symbol: "Tc",
  },
  {
    name: "Ruthenium",
    symbol: "Ru",
  },
  {
    name: "Rhodium",
    symbol: "Rh",
  },
  {
    name: "Palladium",
    symbol: "Pd",
  },
  {
    name: "Silver",
    symbol: "Ag",
  },
  {
    name: "Cadmium",
    symbol: "Cd",
  },
  {
    name: "Indium",
    symbol: "In",
  },
  {
    name: "Tin",
    symbol: "Sn",
  },
  {
    name: "Antimony",
    symbol: "Sb",
  },
  {
    name: "Tellurium",
    symbol: "Te",
  },
  {
    name: "Iodine",
    symbol: "I",
  },
  {
    name: "Xenon",
    symbol: "Xe",
  },
  {
    name: "Cesium",
    symbol: "Cs",
  },
  {
    name: "Barium",
    symbol: "Ba",
  },
  {
    name: "Lanthanum",
    symbol: "La",
  },
  {
    name: "Cerium",
    symbol: "Ce",
  },
  {
    name: "Praseodymium",
    symbol: "Pr",
  },
  {
    name: "Neodymium",
    symbol: "Nd",
  },
  {
    name: "Promethium",
    symbol: "Pm",
  },
  {
    name: "Samarium",
    symbol: "Sm",
  },
  {
    name: "Europium",
    symbol: "Eu",
  },
  {
    name: "Gadolinium",
    symbol: "Gd",
  },
  {
    name: "Terbium",
    symbol: "Tb",
  },
  {
    name: "Dysprosium",
    symbol: "Dy",
  },
  {
    name: "Holmium",
    symbol: "Ho",
  },
  {
    name: "Erbium",
    symbol: "Er",
  },
  {
    name: "Thulium",
    symbol: "Tm",
  },
  {
    name: "Ytterbium",
    symbol: "Yb",
  },
  {
    name: "Lutetium",
    symbol: "Lu",
  },
  {
    name: "Hafnium",
    symbol: "Hf",
  },
  {
    name: "Tantalum",
    symbol: "Ta",
  },
  {
    name: "Tungsten",
    symbol: "W",
  },
  {
    name: "Rhenium",
    symbol: "Re",
  },
  {
    name: "Osmium",
    symbol: "Os",
  },
  {
    name: "Iridium",
    symbol: "Ir",
  },
  {
    name: "Platinum",
    symbol: "Pt",
  },
  {
    name: "Gold",
    symbol: "Au",
  },
  {
    name: "Mercury",
    symbol: "Hg",
  },
  {
    name: "Thallium",
    symbol: "Tl",
  },
  {
    name: "Lead",
    symbol: "Pb",
  },
  {
    name: "Bismuth",
    symbol: "Bi",
  },
  {
    name: "Polonium",
    symbol: "Po",
  },
  {
    name: "Astatine",
    symbol: "At",
  },
  {
    name: "Radon",
    symbol: "Rn",
  },
  {
    name: "Francium",
    symbol: "Fr",
  },
  {
    name: "Radium",
    symbol: "Ra",
  },
  {
    name: "Actinium",
    symbol: "Ac",
  },
  {
    name: "Thorium",
    symbol: "Th",
  },
  {
    name: "Protactinium",
    symbol: "Pa",
  },
  {
    name: "Uranium",
    symbol: "U",
  },
  {
    name: "Neptunium",
    symbol: "Np",
  },
  {
    name: "Plutonium",
    symbol: "Pu",
  },
  {
    name: "Americium",
    symbol: "Am",
  },
  {
    name: "Curium",
    symbol: "Cm",
  },
  {
    name: "Berkelium",
    symbol: "Bk",
  },
  {
    name: "Californium",
    symbol: "Cf",
  },
  {
    name: "Einsteinium",
    symbol: "Es",
  },
  {
    name: "Fermium",
    symbol: "Fm",
  },
  {
    name: "Mendelevium",
    symbol: "Md",
  },
  {
    name: "Nobelium",
    symbol: "No",
  },
  {
    name: "Lawrencium",
    symbol: "Lr",
  },
  {
    name: "Rutherfordium",
    symbol: "Rf",
  },
  {
    name: "Dubnium",
    symbol: "Db",
  },
  {
    name: "Seaborgium",
    symbol: "Sg",
  },
  {
    name: "Bohrium",
    symbol: "Bh",
  },
  {
    name: "Hassium",
    symbol: "Hs",
  },
  {
    name: "Meitnerium",
    symbol: "Mt",
  },
  {
    name: "Darmstadtium",
    symbol: "Ds",
  },
  {
    name: "Roentgenium",
    symbol: "Rg",
  },
  {
    name: "Copernicium",
    symbol: "Cn",
  },
  {
    name: "Nihonium",
    symbol: "Nh",
  },
  {
    name: "Flerovium",
    symbol: "Fl",
  },
  {
    name: "Moscovium",
    symbol: "Mc",
  },
  {
    name: "Livermorium",
    symbol: "Lv",
  },
  {
    name: "Tennessine",
    symbol: "Ts",
  },
  {
    name: "Oganesson",
    symbol: "Og",
  },
  {
    name: "Ununennium",
    symbol: "Uue",
  },
];
export const Exchanges = [
  "CSE",
  "OTC",
  "NYSE - A",
  "Nasdaq",
  "TSX",
  "TSXV",
  "ASX",
  "JSE",
  "LSE",
  "AIM",
];

export const Period_range = {
  "1W": 7,
  ALL: 30000,
  "1D": 1,
  "1M": 30,
  "3M": 90,
  "6M": 180,
  "2Y": 730,
  "3Y": 1095,
  "5Y": 1780,
  "1Y": 400,
};

export const resTypes = [
  {
    name: "Indicated Mineral Resource",
    value: 85,
    indicator: "indicated",
  },
  {
    name: "Inferred Mineral Resource",
    value: 84,
    indicator: "inferred",
  },
  {
    name: "Measured Mineral Resource",
    value: 86,
    indicator: "measured",
  },
  {
    name: "Not Mentioned",
    value: 89,
    indicator: "not mentioned",
  },
  {
    name: "Probable Mineral Reserve",
    value: 87,
    indicator: "probable",
  },

  {
    name: "Proven Mineral Reserve",
    value: 88,
    indicator: "proven",
  },
];

export const METRIC_GROUPS = {
  MiningProcessingMethods: [
    "Mining LOM - Mining Method(s)",
    "Processing LOM - Processing Method(s)",
  ],
  MiningMetrics: [
    "Mining LOM - Mine Life",
    "Mining LOM - Reported Mining Rate (capacity) - daily or annual",
    "Mining LOM - Mined Ore",
    "Mining LOM - Mined Waste",
    "Mining LOM - Avg Mined grade",
    "Mining LOM - Avg Strip Ratio (open pit)",
    "Mining LOM - Mining Dilution (underground)",
  ],
  ProcessingMetrics: [
    "Processing LOM - Avg Throughput Rate (capacity) - daily or annual",
    "Processing LOM - Processed Ore",
    "Processing LOM - Avg Processed grade",
    "Processing LOM - Total Contained Metal",
    "Processing LOM - Recoveries",
    "Processing LOM - Total Production",
    "Processing LOM - Payabilities (Concentrate)",
  ],
  UnitOperatingCosts: [
    "Cash Flow - Unit Operating Costs (Mining)",
    "Cash Flow - Unit Operating Costs (Processing)",
    "Cash Flow - Unit Operating Costs (G&A)",
    "Cash Flow - Unit Operating Costs (Treatment & Refining Charges)",
    "Cash Flow - Unit Operating Costs (By-product credits)",
  ],
  ProductionCosts: [
    "Cash Flow - Cash Costs (Total Cash Costs)",
    "Cash Flow - All-in Costs",
  ],
  CapitalExpenditures: [
    "Capex - Sustaining Capex",
    "Capex - Initial Capex (Pre production)",
  ],
  Royalties: ["Royalties"],
};
export const interest_mapping = {
  "Battery Metals": {
    days: null,
    all_of: [],
    author: null,
    phrase: { near: null, searchTerm: null, word_count: null },
    region: null,
    country: null,
    none_of: [],
    to_date: null,
    exchange: null,
    minerals: ["Cobalt", "Lithium", "Uranium", "Nickel"],
    from_date: null,
    searchTerm: null,
    search_text: null,
    exact_phrase: null,
    exclude_sections: [],
    include_sections: [],
    mineral_estimate: [],
    publication_date: null,
  },

  "Base Metals": {
    days: null,
    all_of: [],
    author: null,
    phrase: { near: null, searchTerm: null, word_count: null },
    region: null,
    country: null,
    none_of: [],
    to_date: null,
    exchange: null,
    minerals: [
      "Copper",
      "Zinc",
      "Nickel",
      "Lead",
      "Iron",
      "Tungsten",
      "Molybdenum",
      "Aluminum",
    ],
    from_date: null,
    searchTerm: null,
    search_text: null,
    exact_phrase: null,
    exclude_sections: [],
    include_sections: [],
    mineral_estimate: [],
    publication_date: null,
  },

  "Precious Metals Projects": {
    days: null,
    all_of: [],
    author: null,
    phrase: { near: null, searchTerm: null, word_count: null },
    region: null,
    country: null,
    none_of: [],
    to_date: null,
    exchange: null,
    minerals: ["Gold", "Silver", "Platinum", "Palladium"],
    from_date: null,
    searchTerm: null,
    search_text: null,
    exact_phrase: null,
    exclude_sections: [],
    include_sections: [],
    mineral_estimate: [],
    publication_date: null,
  },

  "Projects with Resources": {
    days: null,
    all_of: [],
    author: null,
    phrase: { near: null, searchTerm: null, word_count: null },
    region: null,
    country: null,
    none_of: [],
    to_date: null,
    exchange: null,
    minerals: null,
    from_date: null,
    searchTerm: null,
    search_text: null,
    exact_phrase: null,
    exclude_sections: [],
    include_sections: [],
    mineral_estimate: [
      "Indicated Mineral Resource",
      "Measured Mineral Resource",
      "Inferred Mineral Resource",
      "Probable Mineral Reserve",
      "Proven Mineral Reserve",
    ],
    publication_date: null,
  },

  "Pre-Resource Projects": {
    days: null,
    all_of: [],
    author: null,
    phrase: { near: null, searchTerm: null, word_count: null },
    region: null,
    country: null,
    none_of: [],
    to_date: null,
    exchange: null,
    minerals: null,
    from_date: null,
    searchTerm: null,
    search_text: null,
    exact_phrase: null,
    exclude_sections: [],
    include_sections: [],
    mineral_estimate: ["Not Mentioned"],
    publication_date: null,
  },
};

export const metal_prices = {
  Au: 1800,
  AuEq: 1800,
  AgEq: 18,
  Ag: 18,
  Cu: 4,
  Pb: 1.1,
  Zn: 0.5,
  CuEq: 4,
  Mo: 10,
  Coal: 120,
  Sn: 1.5,
  TREO: 1500,
  ZrO2: 29,
  Li2O: 9,
  Ni: 11,
  Nb2O5: 20,
  Sc: 4500,
  TiO2: 100,
  K2O: 400,
  Fe: 65000,
  Sb: 4.5,
  Ge: 900,
  Co: 35,
  LCE: 9,
  U3O8: 40,
  SnEq: 1.5,
  Ta2O5: 70,
  Mn: 15000,
  LHM: 9,
  Pt: 950,
  Pd: 1400,
  Rh: 10000,
  "4E": 1200,
  Diamond: 11500,
  ZnEq: 0.5,
  MHP: 18000,
  V2O5: 10,
  Graphite: 1200,
  P2O5: 45,
  MnO: 15000,
  HPMSM: 1000,
  SOP: 500,
  Li: 9,
  WO3: 300000,
  LiOH: 9,
  NH4: 0.75,
  KCl: 500,
  NiEq: 11,
  Bi: 0.4,
  BaSO4: 28,
  Fe3O4: 65000,
  FeNb: 65000,
  Be: 375,
  I: 2,
  NO3: 370,
  Rb: 3000,
  Cs: 100,
  K: 400,
  H3BO3: 0.3,
  SC6: 2.3,
  SiO4: 900,
  Feldspar: 100,
  Lepidolite: 9,
  HM: 1000,
  NdPr: 4,
  Nb: 21,
  Ta: 80,
  Li2SO4: 9,
  ZrSiO4: 0.27,
  Dy: 250,
  SiO2: 900,
  Kaolinite: 150,
};
export const filingTypes = [
  "Annual Update",
  "Quarterly Update",
  "Mineral Resource Update",
  "Drilling Results",
  "Technical Report",
  "Press Release",
];
