import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts-fix";
import FusionCharts from "fusioncharts";
import { Primary } from "../../../components";

export const WaterfallChart = ({ chart_data, setchartView, isBaseCase }) => {
  charts(FusionCharts);
  let {
    CAPEX,
    NPV,
    taxes,
    OPEX,
    gross_profit,
    net_profit,
    metal_sales,
    royalties,
  } = chart_data;
  const [dataSource, setdataSource] = useState();
  useEffect(() => {
    setData();

    return () => {};
  }, [chart_data, isBaseCase]);
  const setData = () => {
    setdataSource({
      chart: {
        caption: "Calculated Values",
        subcaption1: "Last month",
        numberprefix: "$",
        // sumlabel: "Total {br} Profit",
        showvalues: "1",
        bgAlpha: "0",
        borderAlpha: "0",
        showYAxisValues: "0",
        showAlternateHGridColor: "0",
        showZeroPlaneValue: "1",
        zeroPlaneColor: "#ffffff",
        zeroPlaneAlpha: "0",
        labelDisplay: "ROTATE",
        placeValuesInside: "1",
        // divLineAlpha: "60",
        usePlotGradientColor: "0",
        showPlotBorder: "0",
        showCanvasBorder: "0",
        valueBgAlpha: "0",
        canvasbgAlpha: "0",
        positiveColor: "#B3E6D2",
        negativeColor: "#FFB8B8",
        canvasbgcolor: "0",
        bgcolor: "#ffffff",
        numDivLines: "0",
        plottooltext: "$label is <b>$datavalue</b>",
        showSumAtEnd: "0",
        rotateValues: "1",
        labelFontBold: "1",
        // palettecolors: "#B3E6D2,#FFB8B8, #199BDB",
      },
      data: [
        {
          label: "Metal sales",
          value: metal_sales,
          // color: "#B3E6D2",
        },
        {
          label: "OPEX",
          value: OPEX * -1,
          // color: "#FFB8B8",
        },
        {
          label: "CAPEX",
          value: CAPEX,
          // color: "#FFB8B8",
        },
        {
          label: "Gross Profit",
          value: gross_profit,
          // color: "#B3E6D2",
        },
        {
          label: "Taxes",
          value: taxes * -1,
          // color: "#FFB8B8",
        },
        {
          label: "Net Profit",
          value: net_profit,
          // color: "#B3E6D2",
        },
        {
          label: "Royalties",
          value: royalties * -1,
          // color: "#FFB8B8",
        },
        {
          label: "NPV",
          value: NPV || 0,
          color: isBaseCase ? Primary : "#16A3E3",
        },
      ],
    });
  };
  return (
    <div className="w-full">
      <img
        style={{ width: "40px" }}
        className=" float-right mr-2 cursor-pointer"
        onClick={() => setchartView("gauge")}
        alt="Remy Sharp"
        src={require("../../../assets/gauge.png")}
      />

      <div
        className="w-full"
        style={{
          height: "320px",
        }}
      >
        <ReactFusioncharts
          type="waterfall2d"
          width="100%"
          className="h-full"
          height="100%"
          dataFormat="JSON"
          dataSource={dataSource}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WaterfallChart);
