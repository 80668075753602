/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  MatchCard,
  Hexagon,
  ButtonCircle,
  Body2,
  Subtitle1,
  Headline6,
  Subtitle2,
  Headline4,
  Headline5,
} from "../general";
import { Check, Close } from "@material-ui/icons";
import Profile from "../../assets/ProspectorDesignAssets/Companies/istockphoto-1154642632-612x612.jpg";

import { CSSTransition } from "react-transition-group";
import Avatar from "react-avatar";
const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
};

export const InvestorMatchCard = ({ rotate, zIndex, pop, company }) => {
  const [inProp, setInProp] = useState(true);
  const elements = useSelector((state) => state.authReducer.minerals_list);

  const [classname, setClass] = useState("mycard");
  useEffect(() => {
    const timer = setTimeout(() => {
      setInProp(true);
    }, 1000 / zIndex);
    return () => clearTimeout(timer);
  }, []);
  const yay = () => {
    setClass("mycardLeft");
    setTimeout(() => {
      setInProp(false);
      setTimeout(() => {
        pop("liked", company.id);
      }, 500);
    }, 200);
  };
  const nope = () => {
    setClass("mycardRight");
    setTimeout(() => {
      setInProp(false);
    }, 200);
    setTimeout(() => {
      pop("rejected", company.id);
    }, 500);
  };
  const dummy_investor = {
    name: "Miranda Werstiuk",
    description:
      "Dynamic, driven and focused corporate finance and investment banking professional with 25 years global experience in the resource finance sector. Outgoing and vibrant individual, with exceptional interpersonal communication skills. Detail-oriented and analytical, with highly developed research and information aggregation skills. Ability to take the initiative and multi-task within a fast paced, constantly changing environment. Extensive experience in cultivating and maintaining high-profile long-term relationships with corporate issuers (private and public companies) and investors (high-net worth individuals, private equity, institutions and investment funds). Understanding of the key importance of looking at the development impact on a macro-level (i.e. need for an established regulatory framework, policy implementation and/or change) and the need for maintaining a social license to operate (i.e. socio-economic, environmental, community and population impacts) within jurisdictions, gained through extensive review of resource opportunities globally. Supervisory, mentoring and teaching roles within and external to the corporate environment. Ability to embody corporate values and philosophies in a public-facing role. Significant experience with site visit and field work globally. To date, involved in transactions with junior, mid-tier and senior exploration, development and producing natural resources, as well as technology companies, totaling over C$165 million.",
    company: "OCIM Precious Metals",
    position: "Director Corporate Development",
    interests: {
      countries: ["canada", "united-states"],
      regions: ["asia", "australia", "latin america", "north america"],
      minerals: ["Au", "Ag", "Cu"],
    },
  };
  return (
    <CSSTransition
      in={inProp}
      timeout={300}
      classNames={classname || "mycard"}
      unmountOnExit
    >
      <MatchCard rotate={rotate} zIndex={zIndex}>
        {/* <Hexagon width={30}>
                <span>AU</span>
            </Hexagon> */}
        <div className="card-body">
          <div className="card-body-header">
            <div className="card-header-image">
              {company.profileUrl ? (
                <img src={company.profile_url} alt="profile" />
              ) : (
                <Avatar
                  name={company.displayName || company.email}
                  round
                  size={120}
                />
              )}
              {company.companyData ? (
                <div className="card-body-company">
                  {company.companyData.name}
                </div>
              ) : null}
            </div>
            <div className="card-body-header-details">
              <Headline5>{company.name || company.email}</Headline5>
              <Subtitle1 style={{ marginTop: "10px" }}>
                {company.companyData
                  ? company.companyData.name
                  : "No Company info"}
              </Subtitle1>
              <Subtitle2 style={{ marginTop: "10px" }}>
                {company.current_company_position}
              </Subtitle2>
            </div>
          </div>
          <div className="card-body-description">
            <p>{company.bio}</p>
          </div>
          <div className="card-body-interests">
            <Subtitle1>Interests</Subtitle1>
            <div className="interest-list">
              {/* {dummy_investor.interests.countries.map((country,index) => {
                            return (
                               <img  key={index} src={require(`../../assets/ProspectorDesignAssets/Flags/${country}.png`)} alt="flag"/>
                            )
                        })} */}
              {company.interests && company.interests.regions
                ? company.interests.regions.map((region, index) => {
                    return (
                      <img
                        key={index}
                        src={require(`../../assets/ProspectorDesignAssets/Regions/${region.toLowerCase()} with padding.png`)}
                        alt="flag"
                      />
                    );
                  })
                : null}
              {company.interests && company.interests.minerals
                ? [...new Set(company.interensts.minerals)].map(
                    (min, index) => {
                      return (
                        <Hexagon key={index} width={28}>
                          {elements.find((item) => item.name === min) ? (
                            elements
                              .find((item) => item.name === min)
                              .chemical_symbol.substring(0, 4)
                          ) : (
                            <span>&#11030;</span>
                          )}
                        </Hexagon>
                      );
                    }
                  )
                : null}
            </div>
          </div>
        </div>
        <div className="card-actions">
          <ButtonCircle inputColor="#CF6679" onClick={() => nope()}>
            <Close style={{ fontSize: 40 }} />
          </ButtonCircle>
          <ButtonCircle inputColor="#00C8057A" onClick={() => yay()}>
            <Check style={{ fontSize: 40 }} />
          </ButtonCircle>
        </div>
      </MatchCard>
    </CSSTransition>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InvestorMatchCard);
