import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_ZH } from "./zh";
import { TRANSLATIONS_EN } from "./en";
import { TRANSLATIONS_ES } from "./es";
import { TRANSLATIONS_DE } from "./de";
import { TRANSLATIONS_FR } from "./fr";
import { TRANSLATIONS_AR } from "./arab";
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      zh: {
        translation: TRANSLATIONS_ZH,
      },
      es: {
        translation: TRANSLATIONS_ES,
      },
      de: {
        translation: TRANSLATIONS_DE,
      },
      fr: {
        translation: TRANSLATIONS_FR,
      },
      ar: {
        translation: TRANSLATIONS_AR,
      },
    },
  });

i18n.changeLanguage("zh");
